import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "semantic-ui-react";
import { uniqBy } from "lodash";
import CheckboxTree, { Node } from "react-checkbox-tree";
import { useLazyQuery } from "@apollo/client";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { QUERY, Variables, Result, Role } from "../api/UserRoles";
import { QUERY as ROLES_BY_ID_QUERY } from "../api/UserRolesByIds";
import { Variables as RolesByIDVars, Result as RolesByIDRes } from "../api/UserRolesByIds";
import { generateUUID } from "../utils/Numbers";
import { nodesFromEdges } from "../types/relay";
import { readableTypeAndKind } from "../types/onboard";
import { RoleIcon } from "../types/badges";
import { ErrorMessages } from "./elements/ErrorMessages";
import { extractErrorMessages } from "../types";

const roleIconStyle: CSSProperties = {
  width: "20px",
  height: "20px",
  verticalAlign: "middle",
  display: "inline-block",
};
const SEARCH_MIN_LENGTH = 3;

interface State {
  readonly search: string;
  readonly active: boolean;
}
const buildInitialState = (): State => ({ search: "", active: false });

interface UserWithRoles {
  readonly id: string;
  readonly fullName: string;
  readonly roles: ReadonlyArray<Role>;
}

interface Props {
  readonly label?: string;
  readonly placeholder?: string;
  readonly collapsedText?: string;
  readonly checked?: string[];
  readonly onFilter: (checked: string[]) => void;
}

export const NestedRoles = (props: Props) => {
  const { label, placeholder, collapsedText, checked, onFilter } = props;
  const [{ active, search }, setState] = useState(() => buildInitialState());
  const uuid = useMemo(() => generateUUID(), []);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [getUsersById, payload] = useLazyQuery<RolesByIDRes, RolesByIDVars>(ROLES_BY_ID_QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const { data: dataById, error: errorById, loading: loadingById } = payload;
  const [getUsers, { data, error, loading }] = useLazyQuery<Result, Variables>(QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const [storedDataById, setStoredDataById] = useState<RolesByIDRes | undefined>();
  const [storedSearchData, setStoredSearchData] = useState<Result | undefined>();

  // Handles clicks and key presses that would close the dropdown.
  useEffect(() => {
    const clickListener: EventListener = (ev) => {
      let element = ev.target as HTMLElement | null | undefined;
      do {
        if (element?.id.includes(uuid)) {
          // This is a click inside. Do nothing, just return.
          return setState((s) => (s.active ? s : { ...s, active: true }));
        } else if (element?.id === "root") {
          break;
        }
        // Go up the DOM
        element = element?.parentElement;
      } while (element);

      // This was a click outside, make the component inactive.
      setState((s) => ({ ...s, active: false }));
    };

    const escapeListener = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        setState((s) => ({ ...s, active: false }));
      }
    };

    document.addEventListener("click", clickListener);
    document.addEventListener("keydown", escapeListener);

    return () => {
      document.removeEventListener("click", clickListener);
      document.removeEventListener("keydown", escapeListener);
    };
  }, [uuid]);

  // This hook performs some "cleanup" everytime `active` value changes.
  useEffect(() => {
    if (!active) {
      setState(buildInitialState());
    } else {
      dropdownRef.current?.scrollTo(0, 0);
    }
  }, [active]);

  // These two hooks allow us to have a kind of optimistic UI, by displaying the cached results while
  // querying more recent results.
  useEffect(() => {
    if (!dataById || loadingById) {
      return;
    }
    setStoredDataById(dataById);
  }, [dataById, loadingById]);

  useEffect(() => {
    if (!data || loading) {
      return;
    }
    setStoredSearchData(data);
  }, [data, loading]);

  // Fetches the checked values everytime they change.
  useEffect(() => {
    if (!checked) {
      return;
    }
    getUsersById({ variables: { roleIds: checked } });
  }, [checked, getUsersById]);

  const checkedUsersWithRoles: ReadonlyArray<UserWithRoles> = useMemo(() => {
    if (!storedDataById) {
      return [];
    }

    // Maps the query results into something usable for the UI (transforms connections into arrays)
    return storedDataById.nodes.map((n) => ({
      ...n.user,
      roles: nodesFromEdges(n.user.roles.edges),
    }));
  }, [storedDataById]);

  const searchedUsersWithRoles: ReadonlyArray<UserWithRoles> = useMemo(() => {
    if (!storedSearchData || !storedSearchData.users || search.length < SEARCH_MIN_LENGTH) {
      return [];
    }

    const { edges } = storedSearchData.users;
    return nodesFromEdges(edges).map((u) => ({ ...u, roles: nodesFromEdges(u.roles.edges) }));
  }, [storedSearchData, search]);

  const { nodes, expanded } = useMemo(() => {
    const noDups = uniqBy([...searchedUsersWithRoles, ...checkedUsersWithRoles], (u) => u.id);

    const tmpNodes: Node[] = noDups.map((u) => ({
      value: u.id,
      label: u.fullName,
      children: u.roles.map(({ id, fullName, kind, type }) => ({
        value: id,
        label: (
          <>
            {`${fullName}, ${readableTypeAndKind(type, kind)}`}{" "}
            <RoleIcon kind={kind} type={type} style={roleIconStyle} />
          </>
        ),
      })),
    }));
    const tmpExpanded: string[] = noDups.map(({ id }) => id);

    return { nodes: tmpNodes, expanded: tmpExpanded };
  }, [checkedUsersWithRoles, searchedUsersWithRoles]);

  const debouncedQuery = useMemo(() => AwesomeDebouncePromise(getUsers, 420), [getUsers]);

  const onFocus = useCallback(() => setState((s) => ({ ...s, active: true })), []);

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      const newSearch = target.value;
      setState((s) => ({ ...s, search: newSearch }));

      if (newSearch.length < SEARCH_MIN_LENGTH) {
        return;
      }

      debouncedQuery({ variables: { fulltext: newSearch } });
    },
    [debouncedQuery]
  );

  const onCheck = useCallback(
    (c: string[]) => {
      setState((s) => ({ ...s, checked: c }));
      onFilter(c);
    },
    [onFilter]
  );

  const errors = useMemo(() => {
    const tmpErrors: string[] = [];
    if (error) {
      tmpErrors.push(...extractErrorMessages(error));
    }
    if (errorById) {
      tmpErrors.push(...extractErrorMessages(errorById));
    }
    return tmpErrors;
  }, [error, errorById]);

  const isUnfocusedWithValues = !active && checked && checked.length > 0;
  const showNoResultsMessage =
    data?.users?.edges &&
    data.users.edges.length === 0 &&
    search.length >= SEARCH_MIN_LENGTH &&
    !loading;

  return (
    <div className={`CustomCheckboxTree${active ? " active" : ""}`}>
      {label && <label className="CustomCheckboxTree-Label">{label}</label>}
      <div id={uuid} className="CustomCheckboxTree-Dropdown-wrapper">
        <Input
          className={isUnfocusedWithValues ? "blue-input" : "white-input"}
          icon="search"
          iconPosition="left"
          loading={loading}
          placeholder={placeholder}
          onFocus={onFocus}
          input={
            isUnfocusedWithValues ? (
              <button className="CustomCheckboxTree-CustomInput">
                {collapsedText || "Selected"} <div>{checked?.length}</div>
              </button>
            ) : (
              <input autoFocus={active} id={uuid} value={search} onChange={onSearchChange} />
            )
          }
        />
        <div ref={dropdownRef} className="CustomCheckboxTree-Dropdown" id={uuid}>
          {showNoResultsMessage && <p>No members found matching this criteria..</p>}
          <CheckboxTree
            id={uuid}
            nodes={nodes}
            checkModel="leaf"
            checked={checked}
            expanded={expanded}
            onCheck={onCheck}
            expandDisabled={true}
            icons={checkboxTreeIcons}
          />
        </div>
      </div>
      <ErrorMessages errors={errors} />
    </div>
  );
};

const checkboxTreeIcons = {
  expandOpen: null,
  parentClose: null,
  parentOpen: null,
  leaf: null,
  check: <i className="checkbox-checked" />,
  uncheck: <i className="checkbox-unchecked" />,
  halfCheck: <i className="checkbox-half-checked" />,
};
