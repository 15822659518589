import { gql } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { AdminOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation ApproveOnboard($input: ApproveOnboardInput!) {
    payload: approveOnboard(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

interface ApproveOnboardInput {
  readonly onboardId: string;
}
export interface Variables {
  readonly input: ApproveOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: AdminOnboard };
}
