import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { CBCExpertVoteSchema, CBCExpertVoteChoice } from "./_types";

export const schema: JSONSchemaType<CBCExpertVoteSchema> = {
  type: "object",
  properties: {
    voteChoice: {
      type: "string",
      enum: Object.values(CBCExpertVoteChoice),
    },
    reasonForDiscussion: {
      type: "string",
      maxLength: 200,
    },
  },
  required: ["voteChoice"],
  if: { properties: { voteChoice: { type: "string", enum: [CBCExpertVoteChoice.ForDiscussion] } } },
  then: { required: ["reasonForDiscussion"] },
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
