import { gql } from "@apollo/client";

export const SUBSCRIPTION = gql`
  subscription GlobalMessages($authorisation: SubscriptionAuth!) {
    globalMessages(authorisation: $authorisation)
  }
`;

export interface Variables {
  readonly authorisation: {
    readonly bearerToken: string;
    readonly roleId?: string;
  };
}

export interface GlobalMessages {
  readonly globalMessages: {
    readonly refresh?: boolean;
  };
}
