import { assertUnreachable } from "../../types";
import { Action, ActionTypes, Show, Configure } from "./actions";

export interface State {
  readonly visible: boolean;
  readonly isHideable: boolean;
  readonly isDimmable: boolean;
  readonly content?: JSX.Element;
}

export const initialState: State = { visible: false, isHideable: false, isDimmable: false };

export const reducer = (state: State, action: Action): State => {
  const { type } = action;

  switch (type) {
    case ActionTypes.Hide: {
      return { ...state, visible: false, content: undefined };
    }
    case ActionTypes.Show: {
      const { content } = action as Show;
      return { ...state, visible: true, content };
    }
    case ActionTypes.Configure: {
      const { isDimmable, isHideable } = action as Configure;
      return { ...state, isDimmable, isHideable };
    }
  }
  assertUnreachable(type);
};

export const isVisible = ({ visible }: State) => visible;
