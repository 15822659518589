import { bidSubject } from "./BidSeal";
import { BidEvaluationRequestSealFields } from "./_fragments/BidEvaluationRequestFields";

export const bidEvaluationRequestEnvelope = (
  chainId: number,
  fields: BidEvaluationRequestSealFields,
  transmittedAt: Date
) => ({
  chain_id: chainId.toString(),
  header: "Bid Evaluation Request",
  meta: { previous_seal_signature: null },
  subject: bidEvaluationRequestSubject(fields, transmittedAt),
});

const bidEvaluationRequestSubject = (
  fields: BidEvaluationRequestSealFields,
  transmittedAt: Date
): BidEvaluationRequestSealFields => ({
  ...fields,
  transmittedAt: transmittedAt.toISOString().split(".")[0] + "Z",
  bid: bidSubject(fields.bid, new Date(fields.bid.transmittedAt)),
});
