import { StartupInviteType } from "../../schemas/invites/StartupInviteSchema";
import { Migratable, Migrated, MigrateFunction } from "./../_helpers";
import { runMigrationDictionary } from "./../_helpers";

export class StartupInviteMigrator<T extends Migratable<StartupInviteType>> {
  private instance: T;
  constructor(instance: T) {
    this.instance = instance;
  }

  migrate: MigrateFunction<T> = () => {
    const { invitationForm } = this.instance;

    if (!invitationForm || !invitationForm.schemaVersion || !invitationForm.data) {
      return this.instance as Migrated<T>;
    }

    const migratedForm = runMigrationDictionary<StartupInviteType>(
      invitationForm,
      STARTUP_INVITE_MIGRATION_DICTIONARY
    );

    return { ...this.instance, migratedForm } as Migrated<T>;
  };
}

// Note: the latest version should never be included here.
const STARTUP_INVITE_MIGRATION_DICTIONARY = {};
