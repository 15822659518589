import { Typed } from "./common";
import { SetFlagsRequest } from "../contracts/generated/Access";

export interface Enrollment {
  readonly roleId: string;
  readonly chainId: number;
  readonly roleChainData: SetFlagsRequest;
}

export interface EnrollmentError extends Typed<EnrollmentErrorType> {
  readonly message?: string;
}

export enum EnrollmentErrorType {
  IdentityMismatch,
  PartiallyVerified,
  FailedContractCall,
}
