import { CSSProperties, MouseEventHandler, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { MyTickets } from "./MyTickets";
import { useOverlayApi } from "../../../contexts/Overlay";
import { Enrolled } from "../../../contexts/Session/state";
import { NewQuestions } from "./NewQuestions";
import { Shortcuts as S } from "../../../routing";
import { isEnum } from "../../../types";

const tabVarName = S.mySprint.queryVarNames.tab;
export enum Tabs {
  MyTickets = "my-tickets",
  NewQuestions = "new-questions",
}
export enum TabTitles {
  MyTickets = "My Tickets",
  NewQuestions = "New Questions",
}

const IconStyle: CSSProperties = { margin: "0px 0px 0px 10px" };

interface Props {
  readonly sessionState: Enrolled;
}

export const MySprint = ({ sessionState }: Props) => {
  const breadcrumbApi = useBreadcrumbApi();
  const overlayApi = useOverlayApi();
  const history = useHistory();
  const location = useLocation();
  const activeTab =
    (new URLSearchParams(location.search).get(tabVarName) as Tabs) || Tabs.MyTickets;

  // Add breadcrumbs.
  useEffect(() => {
    const onTabChange: MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
      if (isEnum(currentTarget.id, Tabs) && currentTarget.id !== activeTab) {
        const params = new URLSearchParams({ [tabVarName]: currentTarget.id });
        history.push({ search: params.toString() });
      }
    };

    const onCreateTicket = () => history.push({ pathname: S.draftTicket.path });

    const myTicketsClassname = `panel${activeTab === Tabs.MyTickets ? " active" : ""}`;
    // const newQuestionsClassname = `panel${activeTab === Tabs.NewQuestions ? " active" : ""}`;
    const breadcrumbFooter = (
      <div className="Breadcrumb-children-wrapper">
        <div className="Panes">
          <div className={myTicketsClassname} id={Tabs.MyTickets} onClick={onTabChange}>
            {TabTitles.MyTickets}
          </div>
          {/* <div className={newQuestionsClassname} id={Tabs.NewQuestions} onClick={onTabChange}>
            {TabTitles.NewQuestions}
          </div> */}
        </div>
        <div className="Actions" key="actions">
          <Button color="blue" onClick={onCreateTicket}>
            Create ticket
            <Icon name="plus" style={IconStyle} />
          </Button>
        </div>
      </div>
    );

    breadcrumbApi.addFooter(breadcrumbFooter);
    return () => {
      breadcrumbApi.removeFooter();
    };
  }, [activeTab, breadcrumbApi, history, overlayApi]);

  // Scroll to top
  useEffect(() => {
    const ele = document.getElementById("PageContentId");
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }, [activeTab]);

  return (
    <div className="WrapperSection">
      {activeTab === Tabs.MyTickets && <MyTickets sessionState={sessionState} />}
      {activeTab === Tabs.NewQuestions && <NewQuestions sessionState={sessionState} />}
    </div>
  );
};
