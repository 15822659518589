import { CSSProperties, useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Button, Loader, Segment } from "semantic-ui-react";
import { Result, QUERY, Variables } from "../../../api/onboards/CBCMemberExpertOnboards";
import { Filters as QueryFilters } from "../../../api/onboards/CBCMemberExpertOnboards";
import { Enrolled } from "../../../contexts/Session/state";
import { extractErrorMessages, Maybe } from "../../../types";
import { OnboardState } from "../../../types/onboard";
import { nodesFromEdges } from "../../../types/relay";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { bridge as filterSchema } from "../../../schemas/cbc/CBCExpertFiltersSchema";
import { CBCExpertFiltersSchema } from "../../../schemas/cbc/_types";
import { CustomDropdownSelectionField } from "../../../schemas/CustomDropdownSelectionField";
import { AjvError } from "../../../utils/Ajv";
import { CustomTextField } from "../../../schemas/CustomTextField";
import { PaginationLinks } from "../../elements/PaginationLinks";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { AdminApplicationCard } from "../Administration/Applications/AdminApplicationCard";
import { typeOpts } from "../Administration/AdminApplicationsTab";

const RESULTS_PER_PAGE = 10;
const flexStyle: CSSProperties = { display: "flex" };

interface Props {
  readonly sessionState: Enrolled;
}
const defaultOnboardState = [
  OnboardState.AwaitingSeconders,
  OnboardState.InMutualAssessment,
  OnboardState.InCommitteeReview,
  // OnboardState.AwaitingSignature, // TODO: ask backend to enable this state
  OnboardState.Closed,
];
const defaultModel: CBCExpertFiltersSchema = { fullName: "", state: [], type: [] };

export const CBCExpertApplications = ({ sessionState }: Props) => {
  const roleId = sessionState.roleId;
  const { data, loading, error, refetch } = useQuery<Result, Variables>(QUERY, {
    variables: { first: RESULTS_PER_PAGE, roleId, state: defaultOnboardState },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const pageInfo = useMemo(() => data?.onboards?.pageInfo, [data]);

  const previousOnClick = useCallback(() => {
    if (!pageInfo || !pageInfo.hasPreviousPage || !pageInfo.startCursor) {
      return;
    }
    const before = pageInfo.startCursor;
    refetch({ before, after: undefined, first: undefined, last: RESULTS_PER_PAGE });
  }, [pageInfo, refetch]);

  const nextOnClick = useCallback(() => {
    if (!pageInfo || !pageInfo.hasNextPage || !pageInfo.endCursor) {
      return;
    }
    const after = pageInfo.endCursor;
    refetch({ after, before: undefined, first: RESULTS_PER_PAGE, last: undefined });
  }, [pageInfo, refetch]);

  const onValidate = useCallback((_: Partial<CBCExpertFiltersSchema>, e: Maybe<AjvError>) => {
    if (e) {
      console.warn(e);
    }
    return e;
  }, []);

  const onSubmit = useCallback(
    (model: CBCExpertFiltersSchema) => {
      const modelSanitized = sanitizeModel(model);
      refetch({
        roleId,
        fullName: modelSanitized.fullName,
        state: modelSanitized.state,
        type: modelSanitized.type,
        first: RESULTS_PER_PAGE,
        last: undefined,
        before: undefined,
        after: undefined,
      });
    },
    [refetch, roleId]
  );

  const applicationCards = useMemo(() => {
    if (loading) {
      return <Loader active inline="centered" />;
    } else if (error) {
      return <ErrorMessages errors={extractErrorMessages(error)} />;
    } else if (!data || !data.onboards || !data.onboards.edges || data.onboards.edges.length <= 0) {
      return <Segment textAlign="center">No applications found...</Segment>;
    }

    const onboards = nodesFromEdges(data.onboards.edges);
    return (
      <div className="AdminApplications-wrapper">
        {onboards.map((o) => (
          <AdminApplicationCard key={o.id} onboard={o} isAdminView={false} />
        ))}
      </div>
    );
  }, [data, error, loading]);

  return (
    <>
      <div style={flexStyle}>
        <div className="NomineeVoting-title">All expert applications</div>
        <PaginationLinks
          pageInfo={pageInfo}
          previousOnClick={previousOnClick}
          nextOnClick={nextOnClick}
          loading={loading}
        />
      </div>

      <div className="CBCExpertApplications">
        <AutoForm
          className="CBCExpertApplications-filters"
          schema={filterSchema}
          model={defaultModel}
          onValidate={onValidate}
          onSubmit={onSubmit}
          showInlineError
        >
          <div className="CBCExpertApplications-filters-inputs">
            <CustomTextField name="fullName" label={"Name"} showtextcount={false} />
            <CustomDropdownSelectionField
              name="type"
              dropdownOptions={typeOpts}
              multiple
              placeholder="Any"
            />
            <CustomDropdownSelectionField
              name="state"
              dropdownOptions={stateOpts}
              multiple
              placeholder="Any"
            />
          </div>
          <div>
            <Button color="blue" type="submit" disabled={loading} loading={loading}>
              Apply
            </Button>
          </div>
        </AutoForm>
        {applicationCards}
      </div>
    </>
  );
};

const sanitizeModel = (model: CBCExpertFiltersSchema) => {
  let sanitizedModel = model as QueryFilters;
  if (model?.state?.length === 0) {
    sanitizedModel = { ...sanitizedModel, state: defaultOnboardState };
  }
  if (model?.type?.length === 0) {
    sanitizedModel = { ...sanitizedModel, type: undefined };
  }
  if (model.fullName === "") {
    sanitizedModel = { ...sanitizedModel, fullName: undefined };
  }
  return sanitizedModel;
};

const stateOpts = [
  { text: "Awaiting Seconders", value: OnboardState.AwaitingSeconders },
  { text: "In Mutual Assessment", value: OnboardState.InMutualAssessment },
  { text: "CBC Voting", value: OnboardState.InCommitteeReview },
  { text: "Closed", value: OnboardState.Closed },
];
