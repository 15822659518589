import { gql } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { ReceivedOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation FinaliseOnboard($input: FinaliseOnboardInput!) {
    payload: finaliseOnboard(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

interface FinaliseOnboardInput {
  readonly ethAddress: string;
  readonly ethSignature: string;
  readonly onboardId: string;
  readonly transmittedAt: Date;
}
export interface Variables {
  readonly input: FinaliseOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: ReceivedOnboard };
}
