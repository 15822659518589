import { CSSProperties, useCallback } from "react";
import { Button, Image } from "semantic-ui-react";
import { useForm } from "uniforms";
import blueArrow from "../../assets/blue-chevron.svg";
import { PageTwoFormType } from "../../components/pages/NominateExpert";

const buttonStyle: CSSProperties = { display: "flex" };
const arrowStyle: CSSProperties = {
  alignSelf: "flex-end",
  marginRight: "16px",
  transform: "rotate(180deg)",
};

interface Props {
  readonly onBack: (model: PageTwoFormType) => void;
}

export const BackButton = ({ onBack }: Props) => {
  const { model } = useForm();

  const onClick = useCallback(() => onBack(model), [model, onBack]);

  return (
    <Button type="submit" basic color="blue" style={buttonStyle} onClick={onClick}>
      <Image src={blueArrow} style={arrowStyle} /> Back
    </Button>
  );
};
