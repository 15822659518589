import { useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { QUERY, Result, Variables } from "../../../../api/tickets/GetTicketDetailsInfo";
import { Enrolled, isEnrolled } from "../../../../contexts/Session/state";
import { Shortcuts as S } from "../../../../routing";
import { extractErrorMessages, isEnum, noResultErrorFor } from "../../../../types";
import { TicketStatus } from "../../../../types/ticket";
import { LoaderWithMargin } from "../../../Loader";
import { ErrorMessages } from "../../ErrorMessages";
import { TicketDetailsWrapper } from "./details";
import { TicketWorkplace } from "./TicketWorkplace";
import { nodesFromEdges } from "../../../../types/relay";
import { TicketEvaluation } from "./TicketEvaluation";

const viewVarName = S.ticket.queryVarNames.view;

export enum Tabs {
  TicketDetails = "ticket-details",
  Workspace = "workspace",
  Evaluation = "evaluation",
}
interface Props {
  readonly sessionState: Enrolled;
}

export const TicketDashboard = ({ sessionState }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ticketId = searchParams.get(S.ticket.queryVarNames.id) || "";
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { id: ticketId, roleId: sessionState.roleId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const showWorkspaceTab =
    data?.payload?.status === TicketStatus.Executing ||
    data?.payload?.status === TicketStatus.Delivery_signaled ||
    data?.payload?.status === TicketStatus.Delivery_approved ||
    data?.payload?.status === TicketStatus.Delivery_disproved;
  const showEvaluationTab = nodesFromEdges(data?.payload?.feedbackEvaluations?.edges).length > 0;

  const selectedTab = useMemo(() => {
    const tab = new URLSearchParams(location.search).get(viewVarName) || Tabs.TicketDetails;
    // Replaces bad tab names on the url.
    return isEnum(tab, Tabs) ? tab : undefined;
  }, [location]);

  const setTab = useCallback(
    (tab: Tabs) => {
      if (tab === selectedTab) {
        return;
      }

      const newSearch = new URLSearchParams(location.search);
      newSearch.set(viewVarName, tab);
      history.replace({ search: newSearch.toString() });
    },
    [selectedTab, history, location]
  );

  const onWorkspaceClick = useCallback(() => setTab(Tabs.Workspace), [setTab]);
  const onTicketDetailsClick = useCallback(() => setTab(Tabs.TicketDetails), [setTab]);
  const onEvaluationClick = useCallback(() => setTab(Tabs.Evaluation), [setTab]);

  // Sets the URL view paramether if the user is viewing a tab they shouldn't
  useEffect(() => {
    if (!data || !data.payload || !data.payload.state) {
      return;
    } else if (
      !isEnum(selectedTab, Tabs) ||
      (!showWorkspaceTab && selectedTab === Tabs.Workspace) ||
      (!showEvaluationTab && selectedTab === Tabs.Evaluation)
    ) {
      setTab(Tabs.TicketDetails);
    }
  }, [selectedTab, data, showWorkspaceTab, showEvaluationTab, setTab]);

  const workplaceClassName =
    "PageContentTabs-tab" + (selectedTab === Tabs.Workspace ? " selected" : "");
  const ticketDetailsClassName =
    "PageContentTabs-tab" + (selectedTab === Tabs.TicketDetails ? " selected" : "");
  const evaluationClassName =
    "PageContentTabs-tab" + (selectedTab === Tabs.Evaluation ? " selected" : "");

  if (!isEnrolled(sessionState)) {
    return <>No session found</>;
  } else if (loading && !data) {
    return (
      <Grid columns="16">
        <LoaderWithMargin />
      </Grid>
    );
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.payload || !data.payload.state) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("TicketDetails"))} />;
  }

  return (
    <>
      <div className="PageContentTabs">
        {showWorkspaceTab && (
          <div className={workplaceClassName} onClick={onWorkspaceClick}>
            Workspace
          </div>
        )}
        <div className={ticketDetailsClassName} onClick={onTicketDetailsClick}>
          Ticket Details
        </div>
        {showEvaluationTab && (
          <div className={evaluationClassName} onClick={onEvaluationClick}>
            Evaluation
          </div>
        )}
      </div>
      {selectedTab === Tabs.TicketDetails && (
        <TicketDetailsWrapper sessionState={sessionState} ticket={data.payload} />
      )}
      {selectedTab === Tabs.Workspace && (
        <TicketWorkplace sessionState={sessionState} ticket={data.payload} />
      )}
      {selectedTab === Tabs.Evaluation && (
        <TicketEvaluation sessionState={sessionState} ticket={data.payload} />
      )}
    </>
  );
};
