import { useCallback, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
import { Enrolled } from "../../../../contexts/Session/state";
import { ReviewCompletionForm } from "../../../../schemas/ticketDashboard/ReviewCompletionForm";

interface Props {
  readonly ticket: Ticket;
  readonly sessionState: Enrolled;
}

export const ModalReviewTicketCompletion = ({ ticket, sessionState }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { winningBid, deliverySignaledAt } = ticket;

  const closeModal = useCallback(() => setIsOpen(false), []);
  const openModal = useCallback(() => setIsOpen(true), []);

  if (!deliverySignaledAt || !winningBid) {
    return null;
  }

  return (
    <Modal
      onClose={closeModal}
      className="ModalReviewTicketCompletion CustomClose"
      size="small"
      closeIcon
      open={isOpen}
      trigger={
        <Button onClick={openModal} color="blue">
          Review Completion
        </Button>
      }
    >
      <div className="ModalReviewTicketCompletion-title">Review Completion</div>
      <ReviewCompletionForm
        ticket={ticket}
        deliveredAt={deliverySignaledAt}
        expertName={winningBid.owner.fullName}
        sessionState={sessionState}
        closeModal={closeModal}
        openModal={openModal}
      />
    </Modal>
  );
};
