import { gql } from "@apollo/client";
import { Connection } from "../../types/relay";
import { DiscusableWithSealAndThread } from "./DiscussionThreadsOnIsDiscussable";

export const QUERY = gql`
  query DiscussionThreadsForRole($roleId: ID!, $since: DateTime) {
    node: node(id: $roleId) {
      ... on Role {
        id
        tickets(first: 10) {
          edges {
            node {
              id
              seal {
                message
              }
              thread: discussionThreads(topic: "general", first: 10, since: $since) {
                edges {
                  node {
                    id
                    isLight
                    private
                    author {
                      ...Author
                    }

                    firstComment {
                      ... on DiscussionComment {
                        id
                        title
                        body
                        insertedAt
                        transmittedAt
                        author {
                          ...Author
                        }
                        attachments(first: 2) {
                          ...AttachmentsConnection
                        }
                      }
                    }

                    insertedAt
                    updatedAt
                    commentCount
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                  hasPreviousPage
                  startCursor
                }
              }
            }
          }
        }
      }
    }
  }
  fragment Author on Role {
    id
    fullName
    avatarUrl
  }
  fragment AttachmentsConnection on AttachmentConnection {
    edges {
      node {
        id
        downloadUrl
        document {
          id
          uploadedFile {
            id
            filename
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly roleId: string;
  readonly since?: string;
}

export interface Result {
  readonly node?: { readonly tickets?: Connection<DiscusableWithSealAndThread> };
}
