import { useEffect, useMemo } from "react";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { PublicOnboardViewedByMember } from "../../../types/onboard";
import { generateUUID } from "../../../utils/Numbers";
import { StartupOnboardCard } from "./StartupOnboardCard";
import userPlaceholder from "../../../assets/starup-square-grey-icon.svg";
import blueChevron from "../../../assets/blue-chevron.svg";
import { Shortcuts as S } from "../../../routing";
import { assertUnreachable } from "../../../types";

export const beingReviewedDescription =
  "These applications are pending review by an Industry Committee. If approved, members will be invited to vote and provide feedback.";
export const preSelectionDescription =
  "Participate in the community-driven evaluation of incoming deals. Your feedback helps our review committees prioritize deals for the next stage of evaluation, General Assessment.";
export const generalAssessmentDescription =
  "These applications are undergoing due dilligence by the appropriate review committee. To get involved in our review committees, email startups@consilienceventures.com";
export const unsuccessfulDescription = "Unsuccessful startup applicants.";

export enum RowName {
  BeingReviewed = "BeingReviewed",
  Preselection = "Preselection",
  GeneralAssessment = "GeneralAssessment",
  Unsuccessful = "Unsuccessful",
}

interface Props {
  readonly onboards: ReadonlyArray<PublicOnboardViewedByMember>;
  readonly rowName: RowName;
}

export const StartupOnboardsRowList = ({ onboards, rowName }: Props) => {
  const firstCardId = useMemo(() => generateUUID(), []);
  const containerId = useMemo(() => generateUUID(), []);
  const isUnsuccessful = rowName === RowName.Unsuccessful;

  // Trick to display cards in first row only
  useEffect(() => {
    const listContainer = document.getElementById(containerId);
    const cardElement = document.getElementById(firstCardId);
    if (onboards.length <= 0 || !listContainer || !cardElement) {
      return;
    }

    listContainer.style.height = `${cardElement.offsetHeight + 10}px`;
  }, [onboards, firstCardId, containerId]);

  const { step, title, viewAllLink, description, emptyListTitleText } = useMemo(() => {
    switch (rowName) {
      case RowName.BeingReviewed:
        return {
          step: "STEP 1",
          title: "Being reviewed",
          viewAllLink: S.startupDealflowBeingReviewed.path,
          description: beingReviewedDescription,
          emptyListTitleText: "No startups being reviewed",
        };
      case RowName.Preselection:
        return {
          step: "STEP 2",
          title: "Pre-selection",
          viewAllLink: S.startupDealflowPreSelection.path,
          description: preSelectionDescription,
          emptyListTitleText: "No startups in Pre-selection",
        };
      case RowName.GeneralAssessment:
        return {
          step: "STEP 3",
          title: "General Assessment",
          viewAllLink: S.startupDealflowGeneralAssessment.path,
          description: generalAssessmentDescription,
          emptyListTitleText: "No startups in General Assessment",
        };
      case RowName.Unsuccessful:
        return {
          step: undefined,
          title: "Unsuccesful",
          viewAllLink: S.startupDealflowUnsuccessful.path,
          description: unsuccessfulDescription,
          emptyListTitleText: "No startups",
        };
    }

    assertUnreachable(rowName);
  }, [rowName]);

  const startupsList = useMemo(() => {
    if (onboards.length <= 0) {
      return (
        <div className="StartupDealflow-empty-list">
          <Image src={userPlaceholder} />
          <span className="StartupDealflow-empty-list-title">{emptyListTitleText}</span>
          <br />
        </div>
      );
    } else {
      return (
        <div className="StartupDealflow-step-right-list-row" id={containerId}>
          {onboards.map((o, idx) => (
            <div id={idx === 0 ? firstCardId : undefined} key={o.id}>
              <StartupOnboardCard onboard={o} />
            </div>
          ))}
        </div>
      );
    }
  }, [emptyListTitleText, onboards, containerId, firstCardId]);

  const circleClassName = isUnsuccessful
    ? `StartupDealflow-step-left-circle unsuccessful`
    : `StartupDealflow-step-left-circle`;
  const barClassName = isUnsuccessful
    ? `StartupDealflow-step-left-bar unsuccessful`
    : `StartupDealflow-step-left-bar`;
  const titleClassName = isUnsuccessful
    ? `StartupDealflow-step-right-wrap-label unsuccessful`
    : `StartupDealflow-step-right-wrap-label secondary`;

  return (
    <>
      <div className="StartupDealflow-step">
        <div className="StartupDealflow-step-left">
          <div className={circleClassName} />
          <div className={barClassName} />
        </div>
        <div className="StartupDealflow-step-right">
          <div className="StartupDealflow-step-right-wrap">
            <div>
              {step && <span className="StartupDealflow-step-right-wrap-label">{step}</span>}
              <span className={titleClassName}>{title}</span>
            </div>
            {onboards.length > 0 && (
              <b>
                <Link to={viewAllLink} className="StartupDealflow-link">
                  View all <Image src={blueChevron} />
                </Link>
              </b>
            )}
          </div>
          <span className="StartupDealflow-step-right-description">{description}</span>
          {startupsList}
        </div>
      </div>
    </>
  );
};
