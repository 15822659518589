import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import { BoolField, RadioField } from "uniforms-semantic";
import { bridge } from "./schema";
import { CustomLongTextField } from "../CustomLongTextField";
import { readableYesNoAnswer } from "../_types";
import { Shortcuts as S } from "../../routing";
import { QUERY, Result, Variables } from "../../api/tickets/TicketWithBids";
import { MUTATION as IssueMut, Result as IssueRes } from "../../api/tickets/IssueDraftTicketBid";
import { Variables as IssueVars } from "../../api/tickets/IssueDraftTicketBid";
import { MUTATION as AmendMut, Result as AmendRes } from "../../api/tickets/AmendDraftTicketBid";
import { Variables as AmendVars } from "../../api/tickets/AmendDraftTicketBid";
import { MUTATION as PublishMut } from "../../api/tickets/PublishDraftTicketBid";
import { Result as PublishRes } from "../../api/tickets/PublishDraftTicketBid";
import { Variables as PublishVars } from "../../api/tickets/PublishDraftTicketBid";
import { Enrolled } from "../../contexts/Session/state";
import { SaveButton } from "../tickets/components/SaveButton";
import { Context, DeepPartial } from "uniforms";
import { BidSchema } from "./_types";
import { extractErrorMessages, isDefined } from "../../types";
import { ApiError, Maybe, noResultErrorFor } from "../../types";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { CustomMultipleUploadField } from "../CustomMultipleUploadField";
import { AjvError, appendCustomAjvError } from "../../utils/Ajv";
import { selectedRole } from "../../contexts/Session/helpers";
import { nodesFromEdges } from "../../types/relay";
import { LoaderWithMargin } from "../../components/Loader";
import { ErrorMessages } from "../../components/elements/ErrorMessages";
import { TicketState } from "../../types/ticket";
import { TicketBidState, TickettingBid } from "../../types/bid";
import { useBreadcrumbApi } from "../../contexts/Breadcrumb";
import { readableTicketPaymentMethods, TicketPaymentMethods } from "../tickets/_enums";
import { CustomNumField } from "../tickets/components/CustomNumField";
import { DisplayIf } from "../DisplayIf";
import { EstimatedCostField } from "./EstimatedCostField";
import { revertFromChainBigInt } from "../../contexts/Chain/helpers";
import { CustomErrorAggregator } from "../CustomErrorAggregator";
import { CreateTicketErrors } from "../tickets/TicketForm";
import { TicketSummary } from "../../components/elements/tickets/TicketSummary";
import { CheckboxListField } from "../tickets/components/CheckboxListField";
import { CustomTextField } from "../CustomTextField";
import { communicationMethodOptions } from "../preferences/TicketPreferencesForm";
import { CommunicationMethods } from "../preferences/_enums";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { QUERY as BidSealQuery, SealQueryResult, bidEnvelope } from "../../api/seals/BidSeal";
import { digestFormData } from "../../types/OnboardForm";
import { Crumb } from "../../contexts/Breadcrumb/state";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { computeCostFields } from "../tickets/_types";

const spanStyle: CSSProperties = { color: "red" };
const headerStyle: CSSProperties = { marginBottom: "0px" };

interface Props {
  readonly sessionState: Enrolled;
}

export const BidForm = ({ sessionState }: Props) => {
  // Hooks.
  const history = useHistory();
  const chainApi = useChainApi();
  const chainState = useChainState();
  const breadcrumbApi = useBreadcrumbApi();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const ticketId =
    new URLSearchParams(history.location.search).get(S.submitBid.queryVarNames.id) || "";

  // Queries and mutations.
  const [issueBid, { loading: issueMutLoading }] = useMutation<IssueRes, IssueVars>(IssueMut);
  const [amendBid, { loading: amendBidLoading }] = useMutation<AmendRes, AmendVars>(AmendMut);
  const [publishBid, { loading: publishBidLoading }] = useMutation<PublishRes, PublishVars>(
    PublishMut
  );
  const role = selectedRole(sessionState.user.roles, sessionState.roleId);
  const { loading, error, data } = useQuery<Result, Variables>(QUERY, {
    variables: { first: 1, ticketId, roleId: role.id },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  // Refs and state.
  const currentModelRef = useRef<DeepPartial<BidSchema>>({});
  const hasPendingSubmitRef = useRef<boolean>(false);
  const [needsScroll, setNeedsScroll] = useState(false);
  const [bid, setBid] = useState<Omit<TickettingBid, "ticket"> | undefined>();
  const { maxRawCost } = computeCostFields(data?.ticket?.ticketForm?.data);
  const maxTotalCost = isDefined(maxRawCost) ? parseFloat(revertFromChainBigInt(maxRawCost)) : 0;
  const canSubmitBid = useMemo(() => data?.ticket?.state === TicketState.In_bidding, [data]);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const isLoading =
    loading || issueMutLoading || amendBidLoading || publishBidLoading || sealFieldsLoading;

  useEffect(() => {
    if (data?.ticket?.ticketForm?.data) {
      const title = data.ticket.ticketForm.data.title;
      const customTitle = `Bid on ${title}`;
      breadcrumbApi.addCustomTitle(customTitle);
      breadcrumbApi.addBody(
        <>
          <h2 style={canSubmitBid ? undefined : headerStyle}>{customTitle}</h2>
          {!canSubmitBid && (
            <span style={spanStyle}>
              The ticket for this bid has been unpublished. No further action can take place for
              this bid until the ticket is published again.
            </span>
          )}
        </>
      );

      const search = new URLSearchParams({ [S.ticket.queryVarNames.id]: ticketId }).toString();
      const crumb: Crumb = { path: S.ticket.path, title, search };
      breadcrumbApi.addCrumb(crumb);

      return () => {
        breadcrumbApi.removeCustomTitle();
        breadcrumbApi.removeBody();
      };
    }
  }, [data, breadcrumbApi, canSubmitBid, ticketId]);

  // TODO: this logic must be changed we want to issue a bid only if the user presses the save or submit button.
  useEffect(() => {
    if (loading) {
      return;
    }

    const nodes = nodesFromEdges(data?.ticket?.bidsConnection?.edges);
    if (nodes.length > 0) {
      const myBid = nodes[0];
      // If there's already a submitted bid for this user redirect to the bid details page.
      if (myBid.state === TicketBidState.Submitted) {
        const search = new URLSearchParams({ [S.bid.queryVarNames.id]: myBid.id }).toString();
        return history.replace({ pathname: S.bid.path, search });
      }
      setBid(myBid);
    }
    // Issue a bid if there was none yet.
    else {
      issueBid({ variables: { input: { ticketId, ownerId: role.id } } })
        .then((res) => {
          if (!res.data || !res.data.payload) {
            return Promise.reject(new Error("Missing payload."));
          }
          setBid(res.data.payload.bid);
        })
        .catch((e: ApiError) => {
          toast.error("Something went wrong.");
          console.warn(e);
        });
    }
  }, [history, loading, data, issueBid, role.id, ticketId]);

  useEffect(() => {
    if (hasPendingSubmitRef.current && needsScroll) {
      const firstError = document.getElementsByClassName("ui red basic pointing label")[0];
      if (firstError && firstError.parentElement) {
        firstError.parentElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }

      hasPendingSubmitRef.current = false;
      setNeedsScroll(false);
    }
  }, [needsScroll]);

  const setCurrentModelRef = useCallback(
    (m: DeepPartial<BidSchema>) => (currentModelRef.current = m),
    []
  );

  const paymentMethodCondition = useCallback(
    (c: Context<BidSchema>) => c.model.paymentMethod === TicketPaymentMethods.FiatAndCVDS,
    []
  );

  const hasCommunicationMethodOther = useCallback(({ model }: Context<BidSchema>) => {
    return !(model.preferences?.communicationMethods?.indexOf(CommunicationMethods.Other) === -1);
  }, []);

  const onCancel = useCallback(() => {
    toast.success("The bid/bid changes were cancelled.");
    const search = new URLSearchParams({ [S.ticket.queryVarNames.id]: ticketId }).toString();
    history.push({ pathname: S.ticket.path, search });
  }, [history, ticketId]);

  const onSave = useCallback(() => {
    if (!bid || !bid.draft) {
      return;
    }
    const form = JSON.stringify(currentModelRef.current);
    const draftId = bid.draft.id;

    amendBid({ variables: { input: { draftId, form } } })
      .then((res) => {
        if (!res.data || !res.data.payload) {
          return Promise.reject(new Error("Missing payload."));
        }
        const draft = res.data.payload.draftBid;
        setBid((s) => (s ? { ...s, draft } : s));
        toast.success("You bid was successfuly saved.");
      })
      .catch((e: ApiError) => {
        toast.error("Something went wrong.");
        console.warn(e);
      });
  }, [bid, amendBid]);

  const onValidate = useCallback(
    (model: Partial<BidSchema>, mError: Maybe<AjvError>) => {
      let e = mError;
      const { estimatedCost, fiatPercentage, cvdsPercentage, paymentMethod } = model || {};

      if (estimatedCost) {
        const hasInvalidEstimatedCost = estimatedCost > maxTotalCost;
        e = appendCustomAjvError(e, hasInvalidEstimatedCost, "/estimatedCost", "");
      }

      if (isDefined(fiatPercentage) && isDefined(cvdsPercentage)) {
        const invalidSum =
          paymentMethod === TicketPaymentMethods.FiatAndCVDS &&
          fiatPercentage !== null &&
          cvdsPercentage !== null &&
          fiatPercentage + cvdsPercentage !== 100;
        e = appendCustomAjvError(e, invalidSum, "/fiatPercentage", CreateTicketErrors.Percentage);
        e = appendCustomAjvError(e, invalidSum, "/cvdsPercentage", CreateTicketErrors.Percentage);
      }

      const hasErrors = isDefined(e) && e.details.length > 0;
      if (hasPendingSubmitRef.current && hasErrors) {
        setNeedsScroll(true);
        console.warn("model", model);
        console.warn("errors", e?.details);
      }

      return e;
    },
    [maxTotalCost]
  );

  const onSubmitBttnClick = useCallback(() => (hasPendingSubmitRef.current = true), []);

  const onSubmit = useCallback(async () => {
    const form = JSON.stringify(currentModelRef.current);

    if (!bid || !bid.draft || !canSubmitBid) {
      return;
    }

    const draftId = bid.draft.id;
    const transmittedAt = new Date();
    const { chainId } = sessionState;
    await amendBid({ variables: { input: { draftId, form } } }).catch((err: ApiError) =>
      console.warn(err.message)
    );

    setSealFieldsLoading(true);
    const sealData = await sealFieldsQuery(BidSealQuery, { id: bid.id });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const fields = { ...sealData.fields, form: { data: currentModelRef.current } };
    const envelope = bidEnvelope(chainId, fields, transmittedAt, "publish", bid.seal?.signature);
    const plainText = digestFormData(envelope, true);

    chainApi
      .sign(chainState, plainText)
      .then((ethSignature) => {
        return publishBid({
          variables: { input: { draftId, ethSignature, transmittedAt } },
        });
      })
      .then(() => {
        toast.success("Bid successfully published.");
        const search = new URLSearchParams({ [S.ticket.queryVarNames.id]: ticketId }).toString();
        history.push({ pathname: S.ticket.path, search });
      })
      .catch((e: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (e.message.includes("User denied message signature")) {
          return;
        }
        toast.error("Something went wrong.");
        console.warn(e);
      });
  }, [
    sealFieldsQuery,
    amendBid,
    publishBid,
    sessionState,
    chainApi,
    chainState,
    bid,
    history,
    ticketId,
    canSubmitBid,
  ]);

  if (loading || issueMutLoading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!bid || !data?.ticket || !data.ticket.ticketForm) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Bid"))} />;
  }

  const { owner } = data.ticket;

  return (
    <Grid columns="16">
      <Grid.Column width="10">
        <Segment>
          <AutoForm
            className="Bid-form"
            schema={bridge}
            model={bid.draft?.form?.data}
            showInlineError
            onValidate={onValidate}
            onSubmit={onSubmit}
          >
            <RadioField
              name="understandsRequirements"
              label="I understand the requirements of this ticket and have clarified any major open questions with the Ticket Owner."
              errorMessage="This field is mandatory."
              transform={readableYesNoAnswer}
            />
            <RadioField
              name="agreesToFramework"
              label="I agree to the framework of this Ticket (approximate timelines, estimated payments, deliverable framework in general)."
              errorMessage="This field is mandatory."
              transform={readableYesNoAnswer}
            />
            <CustomLongTextField
              name="overview"
              label="Provide an overview of your proposed approach for delivery."
              errorMessage="This field is mandatory and should be less than 300 characters."
            />
            <CustomLongTextField
              name="interestReason"
              label="Why are you interested in this ticket?"
              errorMessage="This field should be less than 300 characters."
            />
            <CustomLongTextField
              name="competencies"
              label="Outline three competencies with a brief explanation / example that you demonstrate which would bring value to the ticket execution."
              errorMessage="This field is mandatory and should be less than 500 characters."
            />
            <CustomLongTextField
              name="experiences"
              label="Outline 1-3 experiences which are relevant for the requirements of the ticket."
              errorMessage="This field should be less than 1000 characters."
            />
            <CustomLongTextField
              name="keepUpStrategy"
              label="Describe how you keep up to date with industry / skill best practices."
              errorMessage="This field should be less than 300 characters."
            />
            <CustomLongTextField
              name="relevantTools"
              label="List any tools, knowledge and accreditations which would be relevant for the delivery of this ticket."
              errorMessage="This field should be less than 300 characters."
            />
            <CustomLongTextField
              name="deliverableChanges"
              label={`Would you propose any changes / additions to the deliverables defined by ${owner.fullName}?`}
              errorMessage="This field should be less than 500 characters."
            />
            <CustomLongTextField
              name="KPIChanges"
              label={`Would you propose any changes / additions to the KPIs defined by ${owner.fullName}?`}
              errorMessage="This field should be less than 500 characters."
            />
            <CustomLongTextField
              name="engagementStrategy"
              label="Describe how you would engage with your client to ensure that the work you are doing is meeting their requirements."
              errorMessage="This field should be less than 500 characters."
            />
            <CustomLongTextField
              name="performanceMonitoring"
              label="Describe how you would monitor your performance against the ticket contract and how you would communicate performance against deliverables, in particular any difficulties that may arise during the contract."
              errorMessage="This field should be less than 500 characters."
            />
            <CustomLongTextField
              name="ticketSuccess"
              label="How will you measure ticket success with the client at the end of a ticket?"
              errorMessage="This field is mandatory and should be less than 1000 characters."
            />
            <CustomLongTextField
              name="ticketRisks"
              label="Describe any major delivery risks and your proposed mitigations."
              errorMessage="This field should be less than 500 characters."
            />
            <CustomLongTextField
              name="backgroundInformation"
              label="Identify any background information,  policies, procedures, tools, etc that you would expect the client to provide you before starting."
              errorMessage="This field should be less than 300 characters."
            />
            <section>
              <CustomLongTextField
                name="organisationSetup"
                label="Provide details of the organisational set up and roles and responsibilities of your proposed team."
                errorMessage="This field should be less than 500 characters."
              />
              <CustomMultipleUploadField
                name="teamCVs"
                label="Provide the CVs of others who are likely to work on this ticket (max 10 documents of type .bmp, .gif, .jpeg, .jpg, .png, .tiff, .pdf)."
                acceptedFiles={[".pdf", ".bmp", ".gif", ".jpeg", ".jpg", ".png", ".tiff"]}
              />
              <CustomLongTextField
                name="teamManagementStrategy"
                label="Describe how you would manage your team during an engagement, in particular how you would communicate the client's requirements."
                errorMessage="This field should be less than 500 characters."
              />
            </section>
            <CustomLongTextField
              name="previousLessons"
              label="Share what you have identified, analysed and implemented in terms of lessons learned from previous tickets."
              errorMessage="This field should be less than 500 characters."
            />
            <CustomLongTextField
              name="additionalInfo"
              label="Is there anything else you would like to add?"
              errorMessage="This field should be less than 500 characters."
            />
            <CustomMultipleUploadField
              name="additionalDocuments"
              label="Provide additional documents or further information (max 3 documents of any type)"
              acceptedFiles={[".pdf"]}
            />

            <Header size="huge">Payment</Header>
            <CustomNumField
              name="daysOfWork"
              label="How many days of work do you estimate this ticket will take to deliver?"
              placeholder="Enter number"
              errorMessage="This field is mandatory. Delivery for a ticket can last up to 360 days."
            />

            <EstimatedCostField
              name="estimatedCost"
              label="Provide total estimate in units of CVDS."
              placeholder="Enter number"
              errorMessage="This field is mandatory and should be less than the maximum total cost of this ticket."
            />

            <RadioField
              name="paymentMethod"
              label="In what form would you like to receive payment?"
              errorMessage="This field is mandatory."
              transform={readableTicketPaymentMethods}
            />
            <DisplayIf condition={paymentMethodCondition}>
              <section>
                <p>
                  <b>How would you like to split your payment?</b>
                </p>
                <CustomNumField
                  name="fiatPercentage"
                  label="Fiat %"
                  placeholder="Enter number"
                  errorMessage="This field is mandatory, fiat % and CVDS % should sum to 100%."
                />
                <CustomNumField
                  name="cvdsPercentage"
                  label="CVDS %"
                  placeholder="Enter number"
                  errorMessage="This field is mandatory, fiat % and CVDS % should sum to 100%."
                />
              </section>
            </DisplayIf>
            <Header size="huge">Collaboration</Header>
            <CheckboxListField
              name="preferences.communicationMethods"
              label="What's your preferred method of communication?"
              errorMessage="This field is mandatory."
              options={communicationMethodOptions}
            />
            <DisplayIf condition={hasCommunicationMethodOther}>
              <CustomTextField
                name="preferences.communicationMethodOther"
                label="Please specify other methods"
                errorMessage="This field is mandatory, please specify other methods."
              />
            </DisplayIf>
            <CustomLongTextField
              name="preferences.knowledgeAreas"
              label={`Are there any additional notes you have for ${data.ticket.owner.fullName}?`}
            />
            <br />
            <BoolField
              name="termsAgreement"
              label="I/We hereby agree that as a member of Consilience Ventures, I/we will be bound by the CV Membership Terms and Conditions (which I have read and understood) as amended from time to time and which are available on this website."
              errorMessage="This field is mandatory."
            />
            <BoolField
              name="consentToMattermostAlerts"
              label="I consent to receive Mattermost alerts related to my bid."
              errorMessage="This field is mandatory."
            />
            <BoolField
              name="consentToShareEmail"
              label="I consent to share my primary email address with the Ticket Owner."
              errorMessage="This field is mandatory."
            />

            <CustomErrorAggregator
              name="computedFields"
              hideIfFormHasOtherErrors={true}
              errorMessage="A technical issue has occured. Please save your changes, and contact help@consilienceventures.com with a copy of the url for guidance on how to submit your application."
            />

            <Button basic color="teal" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <SignatureButton
              floated="right"
              type="submit"
              onClick={onSubmitBttnClick}
              loading={isLoading}
              disabled={!canSubmitBid}
              content="Submit"
            />

            {data?.ticket?.state === TicketState.In_bidding && (
              <SaveButton
                type="button"
                floated="right"
                color="grey"
                onClick={onSave}
                onFormChange={setCurrentModelRef}
                loading={isLoading}
                content="Save"
              />
            )}
          </AutoForm>
        </Segment>
      </Grid.Column>
      <Grid.Column width="6" floated="left">
        <TicketSummary ticket={data.ticket} />
      </Grid.Column>
    </Grid>
  );
};
