import { Route, RouteProps } from "react-router-dom";
// Contexts and helpers.
import { useSessionState } from "../../../contexts/Session";
// Components.
import { hasAnyRole } from "../../../contexts/Session/state";
import { isEnrolled, Enrolled, isAtLeastPresent } from "../../../contexts/Session/state";
import { State as SessionState } from "../../../contexts/Session/state";
import { State as ChainState } from "../../../contexts/Chain/state";
import { needsChainWall, useChainState } from "../../../contexts/Chain";
import { AppWall } from "../../walls";
import { hasMattermostAccount } from "../../../types/frontend_only/sessionUser";
import { hasPrimaryLinkedEmail } from "../../../types/frontend_only/sessionUser";

enum ErrorType {
  NotLoggedIn,
  NotEnrolled,
}

interface WallProps {
  readonly error: ErrorType;
}
const Wall = ({ error }: WallProps) => {
  let message;
  switch (error) {
    case ErrorType.NotLoggedIn:
      message = "You must be logged in to access this page.";
      break;
    case ErrorType.NotEnrolled:
      message = "This page requires that you select a chain identity matching one of your roles.";
      break;
    default:
      throw new Error("Unknown session wall error");
  }
  return (
    <>
      <h1>Session Error</h1>
      <p>{message}</p>
    </>
  );
};

const appNeedsLoginWall = (sessionState: SessionState) =>
  !isAtLeastPresent(sessionState) || !hasPrimaryLinkedEmail(sessionState.user);

export const appNeedsMattermostWall = (sessionState: SessionState) =>
  hasAnyRole(sessionState) && !hasMattermostAccount(sessionState.user);

const appNeedsEnrolledWall = (sessionState: SessionState, chainState: ChainState) =>
  appNeedsLoginWall(sessionState) ||
  needsChainWall(chainState) ||
  appNeedsMattermostWall(sessionState);

export const LogedInRoute = (props: RouteProps) =>
  appNeedsLoginWall(useSessionState()) ? <AppWall /> : <Route {...props} />;

export const EnrolledRoute = (props: RouteProps) =>
  appNeedsEnrolledWall(useSessionState(), useChainState()) ? <AppWall /> : <Route {...props} />;

interface EnrolledProps {
  children: (s: Enrolled) => JSX.Element;
}
export const EnrolledOrWall = ({ children }: EnrolledProps): JSX.Element | null => {
  const s = useSessionState();
  return isEnrolled(s) ? children(s) : <Wall error={ErrorType.NotEnrolled} />;
};
