import { gql } from "@apollo/client";
import { TicketSchema } from "../../schemas/tickets/_types";
import { JsonDocument } from "../../types/jsonDocument";
import { Connection, ConnectionVariables } from "../../types/relay";
import { TicketState, TicketStatus } from "../../types/ticket";
import { TickettingBid, TicketBidState, TicketBidStatus } from "../../types/bid";
import { OwnerWithAvatarField } from "./Tickets";

export const QUERY = gql`
  query TicketWithBids(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $roleId: ID
    $state: [TicketBidState!]
    $status: [TicketBidStatus!]
    $ticketId: ID!
  ) {
    ticket: node(id: $ticketId) {
      id
      ... on TickettingTicket {
        id
        state
        status
        updatedAt
        transmittedAt
        ticketForm(migrate: true) {
          id
          data
          schemaVersion
        }
        owner {
          id
          fullName
          avatarUrl
        }
        insertedAt
        bidsConnection: tickettingBids(
          after: $after
          before: $before
          first: $first
          last: $last
          roleId: $roleId
          state: $state
          status: $status
          ticketId: $ticketId
        ) {
          edges {
            node {
              id
              state
              status
              publishedAt
              voidedExpertDeclinedOfferAt
              voidedOfferTimeoutAt
              selectedWinnerAt
              newQuestionCount
              seal {
                signature
              }
              owner {
                id
                fullName
              }
              form(migrate: true) {
                id
                data
                schemaVersion
              }
              draft {
                id
                insertedAt
                form {
                  id
                  data
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
    }
  }
`;

export interface Variables extends ConnectionVariables {
  readonly ticketId: string;
  readonly roleId?: string;
  readonly state?: ReadonlyArray<TicketBidState>;
  readonly status?: ReadonlyArray<TicketBidStatus>;
}

interface BidWithNewQuestions extends TickettingBid {
  readonly newQuestionCount: number;
}

export interface Result {
  readonly ticket?: {
    readonly id: string;
    readonly state: TicketState;
    readonly status: TicketStatus;
    readonly updatedAt: string;
    readonly transmittedAt?: string;
    readonly owner: OwnerWithAvatarField;
    readonly insertedAt: string;
    readonly ticketForm?: JsonDocument<TicketSchema>;
    readonly bidsConnection?: Connection<BidWithNewQuestions>;
  };
}
