import { Card } from "semantic-ui-react";
import { metamaskURL } from "../NoMetamaskWall";

export const UnknownErrorTutorial = () => (
  <Card className="Wall xl">
    <div className="WallContent xl">
      <h1 className="WallContent-title">Ethereum Confirmation</h1>
      <p>
        This step is for setting up an Ethereum wallet, which is necessary to interact with our
        token, the CVDS. We can see that your wallet is not currently connected to our chain.
      </p>
      <p>
        Metamask is our recommended Ethereum wallet. If you have Metamask installed, please refresh
        the page.
      </p>
      <p>
        If you do not have Metamask installed, please go to{" "}
        <a href={metamaskURL} target="_blank" rel="noopener noreferrer">
          metamask.io
        </a>{" "}
        and install the browser extension. If you require further guidance on this step please see{" "}
        <a
          href="https://www.youtube.com/watch?v=mCHprQlQR0s"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
      <p>
        If you are experiencing difficulty with this step, please contact{" "}
        <a href="mailto:help@consilienceventures.com">help@consilienceventures.com</a>.
      </p>
    </div>
  </Card>
);
