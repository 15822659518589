import { gql } from "@apollo/client";
import { digestObj } from "../../types/OnboardForm";
import { onboardSubject } from "./OnboardSeal";
import { MUTUAL_ASSESSMENT_SEAL_FIELDS } from "./_fragments/MutualAssessmentSealFields";
import { MutualAssessmentSealFields } from "./_fragments/MutualAssessmentSealFields";

export const QUERY = gql`
  query MutualAssessmentSeal($id: ID!) {
    fields: node(id: $id) {
      ...MutualAssessmentSealFields
    }
  }
  ${MUTUAL_ASSESSMENT_SEAL_FIELDS}
`;

export interface SealQueryResult {
  readonly fields: MutualAssessmentSealFields;
}

export const mutualAssessmentEnvelope = (
  chainId: number,
  fields: MutualAssessmentSealFields,
  transmittedAt: Date
) => ({
  chain_id: chainId.toString(),
  header: "Mutual Assessment",
  meta: { previous_seal_signature: null },
  subject: mutualAssessmentSubject(fields, transmittedAt),
});

const mutualAssessmentSubject = (
  fields: MutualAssessmentSealFields,
  transmittedAt: Date
): MutualAssessmentSealFields => ({
  ...fields,
  form: digestObj(fields.form, false) as any,
  transmittedAt: transmittedAt.toISOString().split(".")[0] + "Z",
  onboard: onboardSubject(fields.onboard, new Date(fields.onboard.insertedAt)),
});
