import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const QUERY = gql`
  query DocumentById($id: ID!) {
    node(id: $id) {
      ... on Document {
        id
        hash
        downloadUrl
        uploadedFile {
          filename
        }
      }
    }
  }
`;

export interface Variables {
  readonly id: string;
}

export interface DocumentWithDownloadUrl extends Identifiable {
  readonly hash: string;
  readonly downloadUrl?: string;
  readonly uploadedFile: { readonly filename: string };
}

export interface Result {
  readonly node?: DocumentWithDownloadUrl;
}
