import { gql } from "@apollo/client";
import { EntityType, MutualAssessment, OnboardState, OnboardStatus } from "../../types/onboard";
import { PublicOnboardViewedByMember } from "../../types/onboard";
import { Connection, ConnectionVariables } from "../../types/relay";
import { PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT } from "../_fragments/PublicOnboardViewedByMember";

export const QUERY = gql`
  query CBCMemberExpertOnboards(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $fullName: String
    $state: [OnboardState!]
    $status: [OnboardStatus!]
    $type: [EntityType!]
    $roleId: ID!
  ) {
    onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      fullName: $fullName
      state: $state
      status: $status
      type: $type
    ) {
      edges {
        node {
          ... on Onboard {
            extraFields {
              ... on Expert {
                mutualAssessments {
                  id
                  state
                  transmittedAt
                  form {
                    id
                    data
                  }
                  seconder {
                    id
                    fullName
                    avatarUrl
                  }
                }
              }
            }
          }
          ...PublicOnboardViewedByMember
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT}
`;

export interface Filters {
  readonly fullName?: string;
  readonly state?: ReadonlyArray<OnboardState>;
  readonly type?: ReadonlyArray<EntityType>;
}

export interface Variables extends Filters, ConnectionVariables {
  readonly roleId: string;
  readonly status?: ReadonlyArray<OnboardStatus>;
}

export interface Result {
  readonly onboards?: Connection<CBCMemberExpertOnboard>;
}

export interface CBCMemberExpertOnboard extends Omit<PublicOnboardViewedByMember, "extraFields"> {
  readonly extraFields: {
    readonly seconderCandidacy: undefined;
    readonly volunteerSeconderCandidacies: undefined;
    readonly mutualAssessments?: ReadonlyArray<MutualAssessment>;
  };
}
