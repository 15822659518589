import { gql } from "@apollo/client";
import { LINKEDEMAIL_FRAGMENT, LINKEDEMAIL_CONNECTION_FRAGMENT } from "./LinkedEmail";
import { EXTSESSION_FRAGMENT } from "./ExtSession";
import { PROFILE_FRAGMENT } from "./Profile";
import { ROLE_CONNECTION_FRAGMENT } from "./Role";

export const SESSION_USER_FRAGMENT = gql`
  fragment SessionUser on User {
    id
    avatarUrl
    fullName
    isAdmin
    mmUserId
    primaryLinkedEmail {
      ...LinkedEmail
    }
    linkedEmails(first: 10) {
      ...LinkedEmailConnection
    }
    linkedinSession {
      ...ExtSession
    }
    profile {
      ...Profile
    }
    myApplications: onboards(
      first: 10
      as: RECIPIENT
      state: [PENDING, IN_COMPLETION, IN_PRESELECTION, UNDER_REVIEW, CLOSED]
    ) {
      edges {
        node {
          id
          sender {
            id
            avatarUrl
            fullName
          }
        }
      }
    }
    roles(first: 10) {
      ...RoleConnection
    }
  }
  ${LINKEDEMAIL_FRAGMENT}
  ${LINKEDEMAIL_CONNECTION_FRAGMENT}
  ${EXTSESSION_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ROLE_CONNECTION_FRAGMENT}
`;

export const SESSION_USER_CONNECTION_FRAGMENT = gql`
  fragment SessionUserConnection on UserConnection {
    edges {
      cursor
      node {
        ...SessionUser
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${SESSION_USER_FRAGMENT}
`;
