import { Divider, Grid } from "semantic-ui-react";
import { MutualAssessment } from "../../../types/onboard";
import { SeconderCandidacy } from "../../../types/seconderCandidacy";
import { ExpertOnboardEvaluationDetails } from "../ExpertOnboarding/ExpertOnboardEvaluationDetails";
import { SeconderRow } from "./SeconderRow";

interface Props {
  readonly candidacy: SeconderCandidacy;
  readonly assignedSecondersCount: number;
  readonly mutualAssessment: MutualAssessment;
  readonly isAdminView: boolean;
}

export const SeconderEvaluationTab = (props: Props) => {
  const { mutualAssessment, candidacy, assignedSecondersCount, isAdminView } = props;
  return (
    <Grid className="AdminExpertDetailsTab">
      <Grid.Column tablet={16} computer={12}>
        <div className="WrapperSection">
          <Grid.Row>
            <Grid.Column className="AdminExpertDetailsTab-title">Seconder</Grid.Column>
            <Divider />
          </Grid.Row>
          <Grid.Row>
            <SeconderRow
              seconderCandidacy={candidacy}
              assignedSecondersCount={assignedSecondersCount}
              isAdminView={isAdminView}
            />
            <Divider />
          </Grid.Row>
          <Grid.Row className="AdminExpertDetailsTab-title">Seconder evaluation</Grid.Row>
          {!mutualAssessment.form && (
            <Grid.Row className="AdminExpertDetailsTab-info">
              The evaluation hasn't been submited yet
            </Grid.Row>
          )}
          {mutualAssessment.form && (
            <ExpertOnboardEvaluationDetails
              mutualAssessment={mutualAssessment}
              isAdminView={isAdminView}
            />
          )}
        </div>
      </Grid.Column>
    </Grid>
  );
};
