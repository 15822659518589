import { gql } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { AdminOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation MoveOnboardToUnderReview($input: MoveOnboardToUnderReviewInput!) {
    payload: moveOnboardToUnderReview(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

interface MoveOnboardToUnderReviewInput {
  readonly onboardId: string;
}
export interface Variables {
  readonly input: MoveOnboardToUnderReviewInput;
}

export interface Result {
  readonly payload?: { readonly onboard: AdminOnboard };
}
