import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";
import { Shortcuts as S } from "../../routing";
import { MUTATION, Result, Variables } from "../../api/accounts/ConfirmEmail";
import { QUERY } from "../../api/accounts/Me";
import { useSessionApi, useSessionState } from "../../contexts/Session";
import { isAtLeastPresent } from "../../contexts/Session/state";

export const VerifyEmail = ({ history, location }: RouteComponentProps) => {
  const sessionState = useSessionState();
  const sessionApi = useSessionApi();
  const [isFirstRun, setIsFirstRun] = useState(true);
  const token = new URLSearchParams(location.search).get(S.verifyEmail.queryVarNames.token);
  const [confirmEmail] = useMutation<Result, Variables>(MUTATION, {
    refetchQueries: [{ query: QUERY }],
  });

  if (!token) {
    history.replace("/");
  } else if (isFirstRun) {
    setIsFirstRun(false);
    confirmEmail({ variables: { input: { token } } })
      .then((res) => {
        if (!res.data?.payload?.linkedEmail) {
          return Promise.reject();
        } else if (isAtLeastPresent(sessionState)) {
          const { linkedEmail } = res.data.payload;
          const { linkedEmails } = sessionState.user;
          const updatedLinkedEmails = linkedEmails.map((e) =>
            e.address === linkedEmail.address ? linkedEmail : e
          );
          sessionApi.setFishyUser({ ...sessionState.user, linkedEmails: updatedLinkedEmails });
        }

        toast.success("Email verified.");
      })
      .catch(() => toast.error("Failed to verify the email."))
      .finally(() => history.replace(S.myArea.path));
  }

  return <Loader active inline="centered" />;
};
