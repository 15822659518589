import { useCallback, useEffect } from "react";
import { Button, Image } from "semantic-ui-react";
import metamaskIcon from "../../assets/metamask-icon.svg";

export const metamaskURL = "https://metamask.io/download";

export const NoMetamaskWall = () => {
  useEffect(() => {
    const ele = document.getElementById("PageContentId");
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }, []);

  const onDownloadMeta = useCallback(
    () => window.open(metamaskURL, "_blank", "noopener,noreferrer"),
    []
  );
  const handleRefresh = useCallback(() => window.location.reload(), []);

  return (
    <div className="NoMetamaskWall">
      <div className="NoMetamaskWall-title">Connect to Consilience Ventures' blockchain </div>
      <div className="NoMetamaskWall-sub-title-purple">
        Our recommended tool to securely connect to our blockchain through your web browser.
      </div>
      <div className="NoMetamaskWall-actions">
        <Button color="blue" onClick={onDownloadMeta}>
          Download Metamask
        </Button>
      </div>
      <div className="NoMetamaskWall-notes">
        Have you already installed it?{" "}
        <span className="NoMetamaskWall-link" onClick={handleRefresh}>
          &nbsp;Refresh this page
        </span>
      </div>
      <div className="NoMetamaskWall-information">
        <Image src={metamaskIcon} />
        <div className="NoMetamaskWall-information-label">Curious to know more? Read on!</div>
        <p>
          A blockchain wallet (which is what Metamask is) is a module that offers you a set of
          convenient functions. The most important one is allowing your web browser to communicate
          with various blockchain networks.
          <br />
          <br />
          On blockchain networks, your identity is known by others as an “Address”. At Consilience
          Ventures, we use blockchain technology in a hybrid fashion: transactions are stored on a
          blockchain, and our users interact with information on this blockchain by using a standard
          Ethereum wallet (e.g., your Metamask wallet), which is connected to our chain.
          <br />
          <br />
          Leveraging this technological stack offers advantages over a conventional set of
          credentials - As long as you keep your Metamask Seed Phrase safe, it's virtually
          impossible for anyone to gain access to your identity. Operations you perform while
          interacting with our app are signed using your identity, and this prevents anyone
          (including us) from tampering with the information you send. And of course, the CVDS you
          earn, through working with startups, through successful investor and startup referrals, or
          for helping build Consilience Ventures, are stored securely within your wallet.
        </p>
        <div className="NoMetamaskWall-information-label">Need help installing it?</div>
        <p>Follow our tutorial</p>
        <iframe
          className="NoMetamaskWall-iframe-video"
          height="190"
          src="https://www.youtube.com/embed/mCHprQlQR0s"
          title="Metamask installation guide"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <br />
      </div>
    </div>
  );
};
