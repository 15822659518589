import React, { useCallback, useState, ChangeEvent } from "react";
import { Icon, Input } from "semantic-ui-react";
import { connectField, HTMLFieldProps } from "uniforms";
import { InputFieldCharCount } from "../components/elements/InputFieldCharCount";
import { isEmpty } from "../types";

interface Props extends HTMLFieldProps<string, HTMLDivElement> {
  readonly [k: string]: unknown;
  readonly showtextcount?: string;
}

export const CustomPasswordField = connectField((props: Props) => {
  const { placeholder, onChange, showtextcount, label } = props;
  const { maxLength, required, error, errorMessage, showInlineError } = props;
  const pholder = maxLength && !placeholder ? `Enter up to ${maxLength} characters` : placeholder;
  const [showPwd, setShowPwd] = useState(false);

  // Custom on change to convert empty strings to undefined istead.
  const customOnChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      onChange(isEmpty(target.value) ? undefined : target.value),
    [onChange]
  );

  const onTogglePwd = useCallback(() => setShowPwd((s) => !s), []);

  const pwdConfFieldType = showPwd ? "text" : "password";
  const pwdConfIcon = <Icon link name={showPwd ? "eye" : "eye slash"} onClick={onTogglePwd} />;
  return (
    <div className={`${required ? "required " : ""}field`}>
      <label>{label}</label>
      <Input
        placeholder={pholder}
        maxLength={maxLength}
        onChange={customOnChange}
        type={pwdConfFieldType}
        icon={pwdConfIcon}
        iconPosition={"left"}
        fluid
      />
      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage}</div>
      )}
      {maxLength !== undefined && showtextcount === undefined && (
        <InputFieldCharCount maxChars={maxLength} currentChars={props.value?.length || 0} />
      )}
    </div>
  );
});
