import { CSSProperties, useMemo } from "react";
import { Image } from "semantic-ui-react";
import dateFormat from "dateformat";
import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
import approvedIcon from "../../../../assets/approved-icon.svg";
import { TicketStatus } from "../../../../types/ticket";
import { nodesFromEdges } from "../../../../types/relay";

const statusCircleStyle: CSSProperties = {
  marginTop: "3px",
};

interface Props {
  readonly ticket: Ticket;
}

const TICKET_DELIVERED_TEXT = "Ticket delivered";
const TICKET_DELIVERY_CONFIRMED_TEXT = "Delivery confirmed";
const TICKET_DELIVERY_UNCONFIRMED_TEXT = "Delivery incomplete";
const TICKET_EVALUATION_TEXT = "Evaluation submitted";

export const TicketDeliveredProgressSection = ({ ticket }: Props) => {
  const { deliverySignaledAt, winningBid, owner, status, deliveryApprovedAt } = ticket;
  const { deliveryDisprovedAt, feedbackEvaluations } = ticket;

  // There can only be one evaluation
  const evaluation = useMemo(() => {
    if (
      !feedbackEvaluations ||
      !feedbackEvaluations.edges ||
      feedbackEvaluations.edges.length <= 0
    ) {
      return undefined;
    }
    return nodesFromEdges(feedbackEvaluations?.edges)[0];
  }, [feedbackEvaluations]);

  return (
    <div className="WorkplaceProgressLabels">
      {evaluation && (
        <ProgressEntry
          approved={true}
          author={owner.fullName}
          date={evaluation.insertedAt}
          text={TICKET_EVALUATION_TEXT}
        />
      )}
      {status === TicketStatus.Delivery_approved && deliveryApprovedAt && (
        <ProgressEntry
          approved={true}
          author={owner.fullName}
          date={deliveryApprovedAt}
          text={TICKET_DELIVERY_CONFIRMED_TEXT}
        />
      )}
      {status === TicketStatus.Delivery_disproved && deliveryDisprovedAt && (
        <ProgressEntry
          approved={false}
          author={owner.fullName}
          date={deliveryDisprovedAt}
          text={TICKET_DELIVERY_UNCONFIRMED_TEXT}
        />
      )}
      {winningBid && deliverySignaledAt && (
        <ProgressEntry
          approved={true}
          author={winningBid.owner.fullName}
          date={deliverySignaledAt}
          text={TICKET_DELIVERED_TEXT}
        />
      )}
    </div>
  );
};

interface ProgressProps {
  readonly approved: boolean;
  readonly author: string;
  readonly date: string;
  readonly text: string;
}

const ProgressEntry = ({ approved, author, date, text }: ProgressProps) => {
  return (
    <div className="WorkplaceProgressLabels-entry">
      {approved && <Image src={approvedIcon} />}
      {!approved && <div className={`StatusCircle dark-red`} style={statusCircleStyle} />}
      <div>
        <div className="WorkplaceProgressLabels-entry-title">{text}</div>
        <>{author}</>
      </div>
      {dateFormat(date, "dd/mm/yy")}
    </div>
  );
};
