import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { StateTypes as ChainStateType } from "../../contexts/Chain/state";
import { valuesOfNumericEnum } from "../../types";

export enum FeedbackRate {
  Good,
  Neutral,
  Bad,
}

export interface FeedbackSchema {
  readonly rate: FeedbackRate;
  readonly comment: string;
  readonly currentUrl: string;
  readonly chainStateType: ChainStateType;
  readonly enrolmentError: string | null;
}

const schema: JSONSchemaType<FeedbackSchema> = {
  type: "object",
  properties: {
    rate: { type: "integer", enum: valuesOfNumericEnum(FeedbackRate) },
    comment: { type: "string", maxLength: 500 },
    currentUrl: { type: "string" },
    chainStateType: { type: "string", enum: Object.values(ChainStateType) },
    enrolmentError: { type: "string" },
  },
  required: ["rate", "comment", "currentUrl", "chainStateType"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
