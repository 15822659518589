import React, { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";
import { Header, Segment } from "semantic-ui-react";
import { toast } from "react-toastify";
import { extractErrorMessages } from "../../../types";
import { MUTATION, Variables, Result } from "../../../api/accounts/ResetPassword";
import { Shortcuts as S } from "../../../routing";
import { PasswordForm, Fields as PasswordFormFields } from "../../elements/PasswordForm";

const initialFields: PasswordFormFields = {
  password: "",
  passwordConfirmation: "",
};

export const ResetPassword = ({ history, location }: RouteComponentProps) => {
  const token = new URLSearchParams(location.search).get(S.resetPassword.queryVarNames.token);
  const [resetPassword, { loading }] = useMutation<Result, Variables>(MUTATION);
  const [fields, setFields] = useState(initialFields);
  const { password, passwordConfirmation } = fields;

  const onSubmit = useCallback(() => {
    if (!token) {
      return;
    }

    const variables: Variables = { input: { token, password, passwordConfirmation } };
    resetPassword({ variables })
      .then(() => {
        toast.success("Password reset successfully.");
        history.push({ pathname: S.myArea.path });
      })
      .catch((err) => {
        const errs = extractErrorMessages(err).filter(
          (e) => !e.includes("masterkey can't be blank")
        );
        setFields((s) => ({ ...s, remoteErrors: errs }));
      });
  }, [history, password, passwordConfirmation, token, resetPassword]);

  if (!token) {
    toast.error("Invalid url.");
    history.replace("/");
  }

  return (
    <Segment>
      <Header size="huge" textAlign="center" content="Reset Password" />
      <PasswordForm
        {...fields}
        passwordLabel="New Password"
        buttonLabel="Reset"
        loading={loading}
        onChange={setFields}
        onSubmit={onSubmit}
      />
    </Segment>
  );
};
