import { gql } from "@apollo/client";
import { Identifiable } from "../types";
import { EntityKind, EntityType } from "../types/onboard";

export const QUERY = gql`
  query RolesByIds($ids: [ID!]!) {
    roles: nodes(ids: $ids) {
      ... on Role {
        id
        type
        kind
        avatarUrl
        profile {
          ... on IndividualProfile {
            id
            fullName
          }
          ... on OrganisationProfile {
            id
            fullName
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly ids: ReadonlyArray<string>;
}

export interface Role extends Identifiable {
  readonly type: EntityType;
  readonly kind: EntityKind;
  readonly avatarUrl: string;
  readonly profile: { readonly fullName: string } & Identifiable;
}

export interface Result {
  readonly roles?: ReadonlyArray<Role | undefined>;
}
