import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../../utils/utils";
import { TermsAndConditions } from "../utils/_types";

export const schema: JSONSchemaType<TermsAndConditions> = {
  type: "object",
  properties: {
    agreesToTerms: { type: "boolean", enum: [true] },
    agreesToMemorandum: { type: "boolean", enum: [true] },
    providerData: {
      type: "object",
      properties: {
        agreesToQualifiedInvestor: { type: "boolean" },
      },
      required: [],
    },
  },
  required: ["agreesToTerms", "agreesToMemorandum"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
