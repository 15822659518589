import { CSSProperties } from "react";
import { JSONSchemaType } from "ajv";
import { Button, Grid, Modal } from "semantic-ui-react";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../../../utils/utils";
import { CustomLongTextField } from "../../../../schemas/CustomLongTextField";
import { AnyAutoForm as AutoForm } from "../../../../types/uniforms";

export interface FeedbackSchema {
  readonly feedback: string;
}

const headerStyle: CSSProperties = {
  fontFamily: "Lato",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "30px",
  lineHeight: "36px",
  letterSpacing: "-0.58px",
  color: "#5C5C5C",
  marginBottom: "13px",
};

const bttnRowStyle: CSSProperties = { display: "flex", justifyContent: "flex-end" };

interface Props {
  readonly expertName: string;
  readonly onSubmit: (schema: FeedbackSchema) => void;
  readonly onGeneralClose: () => void;
}

export const FeedbackModal = ({ expertName, onSubmit, onGeneralClose }: Props) => {
  return (
    <Modal
      className="CustomClose"
      onClose={onGeneralClose}
      open={true}
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <AutoForm showInlineError schema={bridge} model={{}} onSubmit={onSubmit}>
          <Grid>
            <Grid.Column>
              <Grid.Column>
                <div style={headerStyle}>Provide feedback</div>
              </Grid.Column>
              <Grid.Row>
                <CustomLongTextField
                  name="feedback"
                  label={`What changes should ${expertName} make to the plan?`}
                  placeholder="Enter maximum 400 characters..."
                  errorMessage="Required field"
                />
              </Grid.Row>
              <Grid.Row style={bttnRowStyle}>
                <Button basic color="blue" type="button" onClick={onGeneralClose}>
                  Back
                </Button>
                <Button primary color="blue" type="submit">
                  Submit
                </Button>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </AutoForm>
      </Modal.Content>
    </Modal>
  );
};

const schema: JSONSchemaType<FeedbackSchema> = {
  type: "object",
  properties: {
    feedback: { type: "string", maxLength: 400 },
  },
  required: ["feedback"],
};

const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
