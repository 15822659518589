export enum OnfidoCountries {
  Argentina = "ARG",
  Australia = "AUS",
  Austria = "AUT",
  Belgium = "BEL",
  Brazil = "BRA",
  Canada = "CAN",
  Croatia = "HRV",
  CzechRepublic = "CZE",
  Denmark = "DNK",
  Estonia = "EST",
  Finland = "FIN",
  France = "FRA",
  Germany = "DEU",
  HongKong = "HKG",
  Hungary = "HUN",
  Indonesia = "IDN",
  Ireland = "IRL",
  Italy = "ITA",
  Japan = "JPN",
  Latvia = "LVA",
  Lithuania = "LTU",
  Luxembourg = "LUX",
  Malta = "MLT",
  Mexico = "MEX",
  Netherlands = "NLD",
  NewZealand = "NZL",
  Philippines = "PHL",
  Poland = "POL",
  Portugal = "PRT",
  Romania = "ROU",
  Singapore = "SGP",
  Slovakia = "SVK",
  Slovenia = "SVN",
  Spain = "ESP",
  Sweden = "SWE",
  UnitedKingdom = "GBR",
  UnitedStatesofAmerica = "USA",
  VietNam = "VNM",
}
export enum USAStates {
  Alabama = "AL",
  Alaska = "AK",
  Arizona = "AZ",
  Arkansas = "AR",
  California = "CA",
  Colorado = "CO",
  Connecticut = "CT",
  Delaware = "DE",
  DistrictofColumbia = "DC",
  Florida = "FL",
  Georgia = "GA",
  Hawaii = "HI",
  Idaho = "ID",
  Illinois = "IL",
  Indiana = "IN",
  Iowa = "IA",
  Kansas = "KS",
  Kentucky = "KY",
  Louisiana = "LA",
  Maine = "ME",
  Maryland = "MD",
  Massachusetts = "MA",
  Michigan = "MI",
  Minnesota = "MN",
  Mississippi = "MS",
  Missouri = "MO",
  Montana = "MT",
  Nebraska = "NE",
  Nevada = "NV",
  NewHampshire = "NH",
  NewJersey = "NJ",
  NewMexico = "NM",
  NewYork = "NY",
  NorthCarolina = "NC",
  NorthDakota = "ND",
  Ohio = "OH",
  Oklahoma = "OK",
  Oregon = "OR",
  Pennsylvania = "PA",
  RhodeIsland = "RI",
  SouthCarolina = "SC",
  SouthDakota = "SD",
  Tennessee = "TN",
  Texas = "TX",
  Utah = "UT",
  Vermont = "VT",
  Virginia = "VA",
  Washington = "WA",
  WestVirginia = "WV",
  Wisconsin = "WI",
  Wyoming = "WY",
}

export const onfidoCountriesOptions = [
  {
    value: OnfidoCountries.Argentina,
    text: "Argentina",
  },
  {
    value: OnfidoCountries.Australia,
    text: "Australia",
  },
  {
    value: OnfidoCountries.Austria,
    text: "Austria",
  },
  {
    value: OnfidoCountries.Belgium,
    text: "Belgium",
  },
  {
    value: OnfidoCountries.Brazil,
    text: "Brazil",
  },
  {
    value: OnfidoCountries.Canada,
    text: "Canada",
  },
  {
    value: OnfidoCountries.Croatia,
    text: "Croatia",
  },
  {
    value: OnfidoCountries.CzechRepublic,
    text: "Czech Republic",
  },
  {
    value: OnfidoCountries.Denmark,
    text: "Denmark",
  },
  {
    value: OnfidoCountries.Estonia,
    text: "Estonia",
  },
  {
    value: OnfidoCountries.Finland,
    text: "Finland",
  },
  {
    value: OnfidoCountries.France,
    text: "France",
  },
  {
    value: OnfidoCountries.Germany,
    text: "Germany",
  },
  {
    value: OnfidoCountries.HongKong,
    text: "Hong Kong",
  },
  {
    value: OnfidoCountries.Hungary,
    text: "Hungary",
  },
  {
    value: OnfidoCountries.Indonesia,
    text: "Indonesia",
  },
  {
    value: OnfidoCountries.Ireland,
    text: "Ireland",
  },
  {
    value: OnfidoCountries.Italy,
    text: "Italy",
  },
  {
    value: OnfidoCountries.Japan,
    text: "Japan",
  },
  {
    value: OnfidoCountries.Latvia,
    text: "Latvia",
  },
  {
    value: OnfidoCountries.Lithuania,
    text: "Lithuania",
  },
  {
    value: OnfidoCountries.Luxembourg,
    text: "Luxembourg",
  },
  {
    value: OnfidoCountries.Malta,
    text: "Malta",
  },
  {
    value: OnfidoCountries.Mexico,
    text: "Mexico",
  },
  {
    value: OnfidoCountries.Netherlands,
    text: "Netherlands",
  },
  {
    value: OnfidoCountries.NewZealand,
    text: "New Zealand",
  },
  {
    value: OnfidoCountries.Philippines,
    text: "Philippines",
  },
  {
    value: OnfidoCountries.Poland,
    text: "Poland",
  },
  {
    value: OnfidoCountries.Portugal,
    text: "Portugal",
  },
  {
    value: OnfidoCountries.Romania,
    text: "Romania",
  },
  {
    value: OnfidoCountries.Singapore,
    text: "Singapore",
  },
  {
    value: OnfidoCountries.Slovakia,
    text: "Slovakia",
  },
  {
    value: OnfidoCountries.Slovenia,
    text: "Slovenia",
  },
  {
    value: OnfidoCountries.Spain,
    text: "Spain",
  },
  {
    value: OnfidoCountries.Sweden,
    text: "Sweden",
  },
  {
    value: OnfidoCountries.UnitedKingdom,
    text: "United Kingdom",
  },
  {
    value: OnfidoCountries.UnitedStatesofAmerica,
    text: "United States of America",
  },
  {
    value: OnfidoCountries.VietNam,
    text: "Viet Nam",
  },
];

export const usaStatesOptions = [
  {
    value: "AL",
    text: "Alabama",
  },
  {
    value: "AK",
    text: "Alaska",
  },
  {
    value: "AZ",
    text: "Arizona",
  },
  {
    value: "AR",
    text: "Arkansas",
  },
  {
    value: "CA",
    text: "California",
  },
  {
    value: "CO",
    text: "Colorado",
  },
  {
    value: "CT",
    text: "Connecticut",
  },
  {
    value: "DE",
    text: "Delaware",
  },
  {
    value: "DC",
    text: "District of Columbia",
  },
  {
    value: "FL",
    text: "Florida",
  },
  {
    value: "GA",
    text: "Georgia",
  },
  {
    value: "HI",
    text: "Hawaii",
  },
  {
    value: "ID",
    text: "Idaho",
  },
  {
    value: "IL",
    text: "Illinois",
  },
  {
    value: "IN",
    text: "Indiana",
  },
  {
    value: "IA",
    text: "Iowa",
  },
  {
    value: "KS",
    text: "Kansas",
  },
  {
    value: "KY",
    text: "Kentucky",
  },
  {
    value: "LA",
    text: "Louisiana",
  },
  {
    value: "ME",
    text: "Maine",
  },
  {
    value: "MD",
    text: "Maryland",
  },
  {
    value: "MA",
    text: "Massachusetts",
  },
  {
    value: "MI",
    text: "Michigan",
  },
  {
    value: "MN",
    text: "Minnesota",
  },
  {
    value: "MS",
    text: "Mississippi",
  },
  {
    value: "MO",
    text: "Missouri",
  },
  {
    value: "MT",
    text: "Montana",
  },
  {
    value: "NE",
    text: "Nebraska",
  },
  {
    value: "NV",
    text: "Nevada",
  },
  {
    value: "NH",
    text: "New Hampshire",
  },
  {
    value: "NJ",
    text: "New Jersey",
  },
  {
    value: "NM",
    text: "New Mexico",
  },
  {
    value: "NY",
    text: "New York",
  },
  {
    value: "NC",
    text: "North Carolina",
  },
  {
    value: "ND",
    text: "North Dakota",
  },
  {
    value: "OH",
    text: "Ohio",
  },
  {
    value: "OK",
    text: "Oklahoma",
  },
  {
    value: "OR",
    text: "Oregon",
  },
  {
    value: "PA",
    text: "Pennsylvania",
  },
  {
    value: "RI",
    text: "Rhode Island",
  },
  {
    value: "SC",
    text: "South Carolina",
  },
  {
    value: "SD",
    text: "South Dakota",
  },
  {
    value: "TN",
    text: "Tennessee",
  },
  {
    value: "TX",
    text: "Texas",
  },
  {
    value: "UT",
    text: "Utah",
  },
  {
    value: "VT",
    text: "Vermont",
  },
  {
    value: "VA",
    text: "Virginia",
  },
  {
    value: "WA",
    text: "Washington",
  },
  {
    value: "WV",
    text: "West Virginia",
  },
  {
    value: "WI",
    text: "Wisconsin",
  },
  {
    value: "WY",
    text: "Wyoming",
  },
];
