import { gql } from "@apollo/client";
import { BidSchema } from "../../schemas/bids/_types";
import { TickettingDraft } from "../../types/ticket";

export const MUTATION = gql`
  mutation AmendDraftTicketBid($input: AmendDraftTicketBidInput!) {
    payload: amendDraftTicketBid(input: $input) {
      draftBid {
        id
        insertedAt
        form {
          id
          data
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: AmendDraftTicketBidInput;
}

interface AmendDraftTicketBidInput {
  readonly draftId: string;
  readonly form: string;
}

export interface Result {
  readonly payload?: AmendDraftTicketBidPayload;
}

interface AmendDraftTicketBidPayload {
  readonly draftBid: TickettingDraft<Partial<BidSchema>>;
}
