import { CSSProperties } from "react";
import { Grid, List, Icon } from "semantic-ui-react";
import { utils } from "../../utils/utils";
import { FeedbackButton } from "./FeedbackButton";

const listStyle: CSSProperties = {
  wordBreak: "break-word",
};

const CURRENT_YEAR = new Date().getFullYear();

export const Footer = () => (
  <Grid className="Footer">
    <Grid.Column width={3}>
      <div className="Footer-title">Social</div>
      <List link inverted>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/11808422"
        >
          <Icon name="linkedin" />
          LinkedIn
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/consilienceventures/"
        >
          <Icon name="facebook" />
          Facebook
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/tableturn"
        >
          <Icon name="github" />
          Github
        </List.Item>
      </List>
    </Grid.Column>
    <Grid.Column width={4}>
      <div className="Footer-title">Legal Docs</div>
      <List link inverted style={listStyle}>
        <List.Item
          as="a"
          href={`${utils.apiHost}/static/files/cv-members-terms-and-conditions-191010.pdf`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </List.Item>
        <List.Item
          as="a"
          href={`${utils.apiHost}/static/files/cv-information-memorandum-191010.pdf`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          Information Memorandum
        </List.Item>
      </List>
    </Grid.Column>
    <Grid.Column width={5}>
      <div className="Footer-title">Other</div>
      <List link inverted>
        <List.Item>
          Everything on this page is property of Consilience Ventures and / or Consilience Group,
          Copyright 2018-{CURRENT_YEAR}.
        </List.Item>
      </List>
    </Grid.Column>
    <Grid.Column className="Footer-feedback" width={4}>
      <FeedbackButton />
    </Grid.Column>
  </Grid>
);
