import { ApolloClient, ApolloLink, InMemoryCache, from, split } from "@apollo/client";
import { createLink } from "apollo-v3-absinthe-upload-link";
import { relayStylePagination, getMainDefinition } from "@apollo/client/utilities";
import { getLocalStorageItem, LS_KEYS } from "./contexts/Session/state";
import { utils } from "./utils/utils";
import possibleTypes from "./possibleTypes.json";
import { wsLink } from "./PhoenixWebSocket";

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        tickettingBids: relayStylePagination(),
        tickets: relayStylePagination(),
        startups: relayStylePagination(),
        experts: relayStylePagination(),
      },
    },
    Proposal: {
      fields: {
        ballots: relayStylePagination(),
      },
    },
    Role: {
      fields: {
        tickets: relayStylePagination(),
      },
    },
    DiscussionThread: {
      fields: {
        comments: relayStylePagination(),
      },
    },
    TickettingTicket: {
      fields: {
        planFeedback: relayStylePagination(),
      },
    },
  },
});

const httpLink = createLink({ uri: `${utils.apiHost}/graphiql` });

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

const authLink = new ApolloLink((operation, forward) => {
  const token = getLocalStorageItem(LS_KEYS.Token);
  const cvRoleId = getLocalStorageItem(LS_KEYS.CvRoleId);

  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    let customHeaders = { ...headers };

    if (token) {
      customHeaders = { ...customHeaders, authorization: `Bearer ${token}` };
    }
    if (cvRoleId) {
      customHeaders = { ...customHeaders, "CV-Role-ID": cvRoleId };
    }

    return { headers: customHeaders };
  });

  return forward(operation);
});

export const createApolloClient = () =>
  new ApolloClient({ cache, defaultOptions: {}, link: from([authLink, splitLink]) });
