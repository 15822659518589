import { CSSProperties } from "react";
import { Grid } from "semantic-ui-react";
import dateFormat from "dateformat";
import { revertFromChainBigInt } from "../../../../contexts/Chain/helpers";
import { TicketAnswer } from "../../../../schemas/tickets/_enums";
import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
import { TicketStatus } from "../../../../types/ticket";
import { TextClamp } from "../../TextClamp";

const flexStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
};
const statusCircleStyle: CSSProperties = {
  marginLeft: "8px",
};

const hiddenColStyle: CSSProperties = { flexGrow: 4 };
const executionCostStyle: CSSProperties = { minWidth: "158px" };
const workdaysStyle: CSSProperties = { minWidth: "118px" };

interface Props {
  readonly ticket: Ticket;
}

export const TicketDashboardBreadcrumb = ({ ticket }: Props) => {
  const deliverables = ticket.deliverables;
  const { status } = ticket;
  const { daysOfWork, assurance } = ticket.ticketForm?.data || {};
  const { estimatedCostAsBigInt } = ticket.winningBid?.form?.data.computedFields || {};
  const hasAssurance = assurance === TicketAnswer.Yes;

  return (
    <>
      <h2>{`${ticket.ticketForm?.data.title || "Ticket"} Dashboard`}</h2>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Grid>
              <Grid.Row columns="16">
                <Grid.Column className="TktDashCrumbs-collum">
                  <span className="TktDashCrumbs-collum-title">Started On</span>

                  <div className="TktDashCrumbs-collum-content">
                    {dateFormat(ticket.awardedAt, "dd/mm/yy")}
                  </div>
                </Grid.Column>
                <Grid.Column className="TktDashCrumbs-collum">
                  <span className="TktDashCrumbs-collum-title">Expected completion</span>
                  <div className="TktDashCrumbs-collum-content">
                    {deliverables && dateFormat(deliverables.at(-1)?.dueDate, "dd/mm/yy")}
                  </div>
                </Grid.Column>
                <Grid.Column className="TktDashCrumbs-collum" style={workdaysStyle}>
                  <span className="TktDashCrumbs-collum-title">Work days</span>
                  <div className="TktDashCrumbs-collum-content">{daysOfWork || "N/A"}</div>
                </Grid.Column>
                {hasAssurance && (
                  <Grid.Column className="TktDashCrumbs-collum" style={executionCostStyle}>
                    <span className="TktDashCrumbs-collum-title">Quality control</span>
                    <div className="TktDashCrumbs-collum-content">5% of execution cost</div>
                  </Grid.Column>
                )}
                <Grid.Column className="TktDashCrumbs-collum">
                  <span className="TktDashCrumbs-collum-title">Delivery cost</span>
                  <div className="TktDashCrumbs-collum-content">
                    {estimatedCostAsBigInt ? revertFromChainBigInt(estimatedCostAsBigInt) : "N/A"}
                    &nbsp; CVDS
                  </div>
                </Grid.Column>
                <Grid.Column className="TktDashCrumbs-collum" style={hiddenColStyle} />
                <Grid.Column className="TktDashCrumbs-collum">
                  <div>
                    <span className="TktDashCrumbs-collum-title">Status</span>
                    <div className="TktDashCrumbs-collum-content">{buildTicketStatus(status)}</div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

const buildTicketStatus = (status: TicketStatus) => {
  const inProgress =
    status !== TicketStatus.Delivery_signaled &&
    status !== TicketStatus.Delivery_disproved &&
    status !== TicketStatus.Delivery_approved;
  const incomplete = status === TicketStatus.Delivery_disproved;
  const labels = () => {
    if (inProgress) {
      return ["In progress", "orange"];
    } else if (incomplete) {
      return ["Incomplete", "dark-red"];
    } else {
      return ["Delivered", "green"];
    }
  };

  return (
    <div style={flexStyle}>
      <TextClamp text={labels()[0]} />
      <div className={`StatusCircle ${labels()[1]}`} style={statusCircleStyle} />
    </div>
  );
};
