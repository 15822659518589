import { Action } from "./actions";
import { GenericState } from "../Generic/state";
import { genericReducer } from "../Generic/reducer";

interface CommonSettings {
  readonly api_url: string;
  readonly frontend_url: string;
}

interface CoreSettings {
  readonly application_ttl: number;
  readonly chain_poll_frequency: number;
  readonly invitation_ttl: number;
  readonly keypair_bit_size: number;
  readonly linked_email_ttl: number;
  readonly linked_email_verification_email_ttl: number;
  readonly onboard_issuance_email_debouce: number;
  readonly provider_preselection_ttl: number;
  readonly required_seconder_count: number;
  readonly session_ttl: number;
  readonly signature_max_drift: number;
  readonly startup_preselection_ttl: number;
  readonly ticket_bid_evaluation_request_after_rejection_ttl: number;
  readonly ticket_bidding_ttl: number;
}

interface EthSettings {
  readonly chain_id: string;
}

// Actual state.
export interface Settings {
  readonly common: CommonSettings;
  readonly core: CoreSettings;
  readonly eth: EthSettings;
}

export type State = GenericState<Settings>;

export const reducer = (state: State, action: Action) => genericReducer(state, action);
