import { gql } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { AdminOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation RejectOnboard($input: RejectOnboardInput!) {
    payload: rejectOnboard(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

interface RejectOnboardInput {
  readonly onboardId: string;
}
export interface Variables {
  readonly input: RejectOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: AdminOnboard };
}
