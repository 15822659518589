import { CSSProperties } from "react";

interface Props {
  readonly totalEntities: number;
  readonly greenEntities: number;
  readonly yellowEntities: number;
}

export const GradientProgressBar = ({ greenEntities, totalEntities, yellowEntities }: Props) => {
  const greenPerc = (greenEntities / totalEntities) * 100;
  const yellowPerc = (yellowEntities / totalEntities) * 100;
  const yellowCap = greenPerc + yellowPerc;

  const gradientStyle: CSSProperties = {
    background: `
    linear-gradient(to right,
    #3AC458 ${greenPerc}%, #F4CF0D ${greenPerc}% ${yellowCap}%, #E87B17 ${yellowCap}% 100%)`,
    transition: "all 1s linear",
  };

  return <div className="GradientProgressBar" style={gradientStyle} />;
};
