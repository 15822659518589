import { useCallback, useState } from "react";
import { ApiError, extractErrorMessages, Maybe } from "../../types";
import { AjvError } from "../../utils/Ajv";
import { EvaluateCompletionSchema } from "./_types";
import { bridge } from "./EvaluateCompletionSchema";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { Enrolled } from "../../contexts/Session/state";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";
import { EvaluationScoreOptions } from "../_types";
import { CustomLongTextField } from "../CustomLongTextField";
import { Ticket } from "../../api/tickets/GetTicketDetailsInfo";
import { useMutation } from "@apollo/client";
import { MUTATION, Result, Variables } from "../../api/tickets/EvaluateTicketDelivery";
import { QUERY as RefetchTicketEvals } from "../../api/tickets/RefetchTicketEvals";
import { toast } from "react-toastify";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { QUERY, SealQueryResult } from "../../api/seals/TicketSeal";
import { FeedbackEvaluationSealFields } from "../../api/seals/_fragments/FeedbackEvaluationSealFields";
import { feedbackEvaluationEnvelope } from "../../api/seals/FeedbackEvaluationSeal";
import { digestFormData } from "../../types/OnboardForm";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { useOverlayApi } from "../../contexts/Overlay";

interface Props {
  readonly sessionState: Enrolled;
  readonly ticket: Ticket;
}

export const EvaluateCompletionForm = ({ ticket, sessionState }: Props) => {
  const chainApi = useChainApi();
  const chainState = useChainState();
  const overlayApi = useOverlayApi();
  const onValidate = useCallback((_: EvaluateCompletionSchema, e: Maybe<AjvError>) => e, []);
  const [evaluateTicketDelivery, { loading }] = useMutation<Result, Variables>(MUTATION, {
    refetchQueries: [{ query: RefetchTicketEvals, variables: { id: ticket.id } }],
  });
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);

  const onSubmit = useCallback(
    async (model: EvaluateCompletionSchema) => {
      const transmittedAt = new Date();
      const { chainId } = sessionState;

      setSealFieldsLoading(true);
      const sealData = await sealFieldsQuery(QUERY, { id: ticket.id });
      setSealFieldsLoading(false);

      if (!sealData) {
        return toast.error("Failed to fetch the data for the signature.");
      }
      const tAt = transmittedAt.toLocaleString();
      const form = { data: model };

      const fields: FeedbackEvaluationSealFields = {
        transmittedAt: tAt,
        scope: "ticketting_ticket_evaluation",
        form,
        ticket: { ...sealData.fields },
      };
      const envelope = feedbackEvaluationEnvelope(chainId, fields, transmittedAt);
      const text = digestFormData(envelope, true);
      chainApi
        .sign(chainState, text)
        .then((ethSignature) => {
          return evaluateTicketDelivery({
            variables: {
              input: {
                form: JSON.stringify(model),
                ticketId: ticket.id,
                ethSignature,
                transmittedAt,
              },
            },
          });
        })
        .then(() => {
          overlayApi.hide();
          toast.success("Ticket successfully evaluated.");
        })
        .catch((err: ApiError) => {
          // Discard error message thrown when the user clicks cancel on the MM popup.
          if (err.message.includes("User denied message signature")) {
            return;
          }
          overlayApi.hide();
          toast.error(extractErrorMessages(err).join(", "));
        });
    },
    [
      chainApi,
      chainState,
      overlayApi,
      evaluateTicketDelivery,
      sealFieldsQuery,
      sessionState,
      ticket,
    ]
  );

  const isLoading = loading || sealFieldsLoading;

  return (
    <AutoForm schema={bridge} showInlineError onValidate={onValidate} onSubmit={onSubmit}>
      <div className="EvaluateCompletionOverlay-subtitle">Deliverables </div>
      <CustomDropdownSelectionField
        name="deliverablesQuality"
        label={"Well prepared and of high quality"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomDropdownSelectionField
        name="functionalRequirements"
        label={"Met all functional requirements"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomDropdownSelectionField
        name="nonFunctionalRequirements"
        label={"Addressed all non-functional requirements"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomDropdownSelectionField
        name="agreedTimelines"
        label={"Delivered within agreed timelines"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomLongTextField
        name="deliverablesAdditionalInfo"
        label="Additional comments (optional)"
      />
      <br />
      <div className="EvaluateCompletionOverlay-subtitle">
        {ticket.winningBid?.owner.fullName} performance{" "}
      </div>
      <CustomDropdownSelectionField
        name="communication"
        label={"Fluid, clear communication"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomDropdownSelectionField
        name="responsiveness"
        label={"General responsiveness"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomDropdownSelectionField
        name="customerCentricity"
        label={"Customer-centricity"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomDropdownSelectionField
        name="problemSolving"
        label={"Problem solving/ingenuity"}
        placeholder="-"
        search={false}
        multiple={false}
        compact={true}
        dropdownOptions={EvaluationScoreOptions}
        errorMessage="This field is mandatory."
        labelOnLeft={true}
      />
      <CustomLongTextField name="expertAdditionalInfo" label="Additional comments (optional)" />
      <SignatureButton content="Submit" loading={isLoading} disabled={isLoading} />
    </AutoForm>
  );
};
