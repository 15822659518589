import { useCallback, useState, useEffect, PropsWithChildren, useMemo, ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Image, Modal, ModalProps } from "semantic-ui-react";
import blueArrow from "../../assets/left-blue-arrow.svg";
import { isDefined } from "../../types";
import { Context } from "./Context";
import { useOverlayState } from "../Overlay";
import { isVisible } from "../Overlay/state";

interface Props extends ModalProps {
  readonly modalContent: ReactNode;
  readonly galeryLenght: number;
}

const urlParam = "gIdx";

export const GalleryModalProvider = (props: PropsWithChildren<Props>) => {
  const location = useLocation();
  const history = useHistory();
  const overlayState = useOverlayState();
  const { galeryLenght, children, modalContent } = props;
  const UrlIndex = new URLSearchParams(location.search).get(urlParam) || undefined;
  const [currentIdx, setCurrentIdx] = useState(() =>
    UrlIndex && !isNaN(+UrlIndex) ? +UrlIndex : 0
  );
  const [isOpen, setIsOpen] = useState(!!UrlIndex && !isNaN(+UrlIndex));

  const closeModal = useCallback(() => {
    setIsOpen(false);
    const newSearch = new URLSearchParams(location.search);
    newSearch.delete(urlParam);
    history.replace({ search: newSearch.toString() });
  }, [history, location]);

  const showModal = useCallback(
    (delIdx: string) => {
      setIsOpen(true);
      setCurrentIdx(+delIdx);
      const newSearch = new URLSearchParams(location.search);
      newSearch.set(urlParam, delIdx);
      history.replace({ search: newSearch.toString() });
    },
    [history, location.search]
  );

  const handleRightModalArrow = useCallback(() => {
    const nextDelIdx = currentIdx + 1;
    setCurrentIdx(nextDelIdx);
    const newSearch = new URLSearchParams(location.search);
    newSearch.set(urlParam, nextDelIdx.toString());
    history.replace({ search: newSearch.toString() });
  }, [currentIdx, history, location]);

  const handleLeftModalArrow = useCallback(() => {
    const nextDelIdx = currentIdx - 1;
    setCurrentIdx(nextDelIdx);
    const newSearch = new URLSearchParams(location.search);
    newSearch.set(urlParam, nextDelIdx.toString());
    history.replace({ search: newSearch.toString() });
  }, [currentIdx, history, location]);

  useEffect(() => {
    if (currentIdx >= galeryLenght) {
      setIsOpen(false);
      setCurrentIdx(0);
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete(urlParam);
      history.replace({ search: newSearch.toString() });
    }
  }, [currentIdx, galeryLenght, history, location]);

  const providerValue = useMemo(
    () => ({ currentIndex: currentIdx, showModal, closeModal }),
    [currentIdx, showModal, closeModal]
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const el: HTMLDivElement = document.getElementsByClassName(
      "ui page dimmer"
    )[0] as HTMLDivElement;
    let overlayWidth = 0;
    if (isVisible(overlayState)) {
      overlayWidth = document.getElementById("AppOverlay")?.clientWidth || 0;
    }
    el.style.width = `calc(100% - ${overlayWidth}px)`;
  }, [overlayState, isOpen]);

  if (!isDefined(currentIdx)) {
    return null;
  }

  return (
    <Context.Provider value={providerValue}>
      <Modal
        open={isOpen}
        className="GalleryModal CustomClose"
        closeIcon
        onClose={closeModal}
        closeOnDimmerClick={false}
      >
        {currentIdx > 0 && (
          <div className="GalleryModal-left-arrow" onClick={handleLeftModalArrow}>
            <Image src={blueArrow} />
          </div>
        )}
        {/* Modal content */}
        {modalContent}
        {currentIdx < galeryLenght - 1 && (
          <div className="GalleryModal-right-arrow" onClick={handleRightModalArrow}>
            <Image src={blueArrow} />
          </div>
        )}
      </Modal>
      {/* Children */}
      {children}
    </Context.Provider>
  );
};
