import { gql } from "@apollo/client";
import { PublicOnboardViewedByMember } from "../../types/onboard";
import { PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT } from "../_fragments/PublicOnboardViewedByMember";

export const QUERY = gql`
  query PreselectionOnboard($onboardId: ID!, $roleId: ID!) {
    onboard: node(id: $onboardId) {
      ... on Onboard {
        ...PublicOnboardViewedByMember
      }
    }
  }
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT}
`;

export interface Variables {
  readonly onboardId: string;
  readonly roleId: string;
}

export interface Result {
  readonly onboard?: PublicOnboardViewedByMember;
}
