import { gql } from "@apollo/client";
import { digestObj } from "../../types/OnboardForm";
import { TicketPublishType } from "../../types/ticket";
import { TICKET_SEAL_FIELDS, TicketSealFields } from "./_fragments/TicketSealFields";

export const QUERY = gql`
  query TicketSeal($id: ID!) {
    fields: node(id: $id) {
      ...TicketSealFields
    }
  }
  ${TICKET_SEAL_FIELDS}
`;

export interface SealQueryResult {
  readonly fields: TicketSealFields;
}

export const ticketEnvelope = (
  chainId: number,
  fields: TicketSealFields,
  transmittedAt: Date,
  action: "publish" | "unpublish" | "publish_plan" | "signal_ticket_delivery",
  prevSignature: string | null = null
) => ({
  chain_id: chainId.toString(),
  header: "Ticketting Ticket",
  meta: {
    action,
    previous_seal_signature: prevSignature,
  },
  subject: ticketSubject(fields, transmittedAt),
});

export const ticketSubject = (fields: TicketSealFields, transmittedAt: Date): TicketSealFields => ({
  ...fields,
  deliverables: fields.deliverables?.map((d) => ({ ...d, form: digestObj(d.form) as any })),
  ticketForm: digestObj(fields.ticketForm, false) as any,
  transmittedAt: transmittedAt.toISOString().split(".")[0] + "Z",
  publishType: TicketPublishType.PublishPublicly,
  planForm: digestObj(fields.planForm, false) as any,
});
