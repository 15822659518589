import { useCallback } from "react";
import { DocumentNode, OperationDefinitionNode } from "graphql";
import { useSessionState } from "../contexts/Session";
import { utils } from "../utils/utils";
import { isAtLeastPresent, isEnrolled } from "../contexts/Session/state";

interface Variables {
  readonly id: string;
}

type SealFieldsQuery<T> = (query: DocumentNode, variables: Variables) => Promise<T | null>;

interface JsonResult<T> {
  readonly data?: null | T;
  readonly errors?: null | ReadonlyArray<{ readonly message?: string }>;
}

/**
 * This hook handles the logic to fetch fields for seals. The Promise returned will never throw,
 * errors are caught, printed to the console and `null` will be returned instead.
 *
 * `T` is the type of the response if all goes well.
 *
 * @param query should be the ´RootQueryType.node(id: ID!):Node´, otherwise the server will respond
 * with an error because the query is malformed since the passed variables were of
 * type `{ readonly id: string; }`
 */
export const useSealFieldsQuery = <T>(): SealFieldsQuery<T> => {
  const sessionState = useSessionState();
  const token = isAtLeastPresent(sessionState) ? sessionState.token : null;
  const roleId = isEnrolled(sessionState) ? sessionState.roleId : null;

  return useCallback(
    (query, variables) => {
      const result = fetch(`${utils.apiHost}/graphiql`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "content-type": "application/json",
          Origin: window.location.origin,
          "Sec-Fetch-Site": "same-site",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Dest": "empty",
          Referer: window.location.origin,
          "Access-Control-Allow-Origin": "*",
          ...(token ? { authorization: `Bearer ${token}` } : {}),
          ...(roleId ? { "CV-Role-ID": roleId } : {}),
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: buildFetchBody(query, variables),
      })
        .then(async (res) => {
          if (res.status !== 200) {
            return Promise.reject(Error("There was a problem. Status Code: " + res.status));
          }

          const jsonRes: JsonResult<T> = await res.json();
          if (jsonRes.errors) {
            return Promise.reject(Error(jsonRes.errors.map((e) => e.message).join(", ")));
          } else if (!jsonRes.data) {
            return Promise.reject("Missing data on response.");
          }

          return Promise.resolve(jsonRes.data);
        })
        .then((data: T) => data)
        .catch((err) => {
          console.warn(err);
          return Promise.resolve(null);
        });

      return result;
    },
    [roleId, token]
  );
};

const buildFetchBody = (doc: DocumentNode, variables: Variables): string => {
  const operationName = (
    doc.definitions.find((d) => d.kind === "OperationDefinition") as
      | OperationDefinitionNode
      | undefined
  )?.name?.value;

  const query = doc.loc?.source.body;
  return JSON.stringify({ operationName, variables, query });
};
