import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import { utils } from "./utils/utils";

const trimmedApiHost = utils.apiHost.replace("https://", "").replace("http://", "");
const endpoint = utils.isDevelopmentEnv() ? "ws://localhost:4000/ws" : `wss://${trimmedApiHost}/ws`;

const phoenixSocket = new PhoenixSocket(endpoint);

// Wrap the Phoenix socket in an AbsintheSocket.
const absintheSocket = AbsintheSocket.create(phoenixSocket);

// Create an Apollo link from the AbsintheSocket instance.
export const wsLink = createAbsintheSocketLink(absintheSocket);
