import { CBCExpertVoteChoice, readableCBCExpertVoteChoice } from "../../../schemas/cbc/_types";
import { PreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";
import { readablePreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";
import { assertUnreachable } from "../../../types";
import { ProposalTallyItem } from "../../../types/proposal";

const greenColor = "#00A100";
const redColor = "#BA1010";
const grayColor = "#BFBFBF";
const purpleColor = "#56488C";

export interface VoteBarOption {
  readonly label: string;
  readonly count: number;
  readonly color: string;
}

export const cbcVoteTallyToVoteBarData = (
  tally: ReadonlyArray<ProposalTallyItem<CBCExpertVoteChoice>> | undefined
): ReadonlyArray<VoteBarOption> => {
  const voteBarData: VoteBarOption[] = [];
  if (!tally) {
    return voteBarData;
  }
  tally.forEach(({ choice, count }) => {
    switch (choice) {
      case CBCExpertVoteChoice.Yes:
        return voteBarData.push({
          label: readableCBCExpertVoteChoice(choice),
          count,
          color: greenColor, // Green
        });
      case CBCExpertVoteChoice.No:
        return voteBarData.push({
          label: readableCBCExpertVoteChoice(choice),
          count,
          color: redColor, // Red
        });
      case CBCExpertVoteChoice.Abstain:
        return voteBarData.push({
          label: readableCBCExpertVoteChoice(choice),
          count,
          color: grayColor, // Gray
        });
      case CBCExpertVoteChoice.ForDiscussion:
        return voteBarData.push({
          label: readableCBCExpertVoteChoice(choice),
          count,
          color: purpleColor, // Purple
        });
    }
    assertUnreachable(choice);
  });
  return voteBarData.reverse();
};

export const preselectionTallyToVoteBarData = (
  tally: ReadonlyArray<ProposalTallyItem<PreselectionProposalChoice>> | undefined
): ReadonlyArray<VoteBarOption> => {
  const voteBarData: VoteBarOption[] = [];
  if (!tally) {
    return voteBarData;
  }
  tally.forEach(({ choice, count }) => {
    switch (choice) {
      case PreselectionProposalChoice.Yes:
        return voteBarData.push({
          label: readablePreselectionProposalChoice(choice),
          count,
          color: greenColor, // Green
        });
      case PreselectionProposalChoice.No:
        return voteBarData.push({
          label: readablePreselectionProposalChoice(choice),
          count,
          color: redColor, // Red
        });
      case PreselectionProposalChoice.Neutral:
        return voteBarData.push({
          label: readablePreselectionProposalChoice(choice),
          count,
          color: grayColor, // Gray
        });
    }
    assertUnreachable(choice);
  });
  return voteBarData.reverse();
};
