import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { Grid, Form, Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { isString, isEmpty, noResultErrorFor, ApiError } from "../../../../types";
import { extractErrorMessages } from "../../../../types";
import { MUTATION, Variables, Result } from "../../../../api/accounts/UpdateIndividualProfile";
import { ImageUploader } from "../../../elements/ImageUploader";
import { IndividualProfile } from "../../../../types/profile";
import { utils } from "../../../../utils/utils";

enum FormIds {
  FirstName = "@Profile/UserSection/FirstName",
  LastName = "@Profile/UserSection/LastName",
  Headline = "@Profile/UserSection/Headline",
  LinkedIn = "@Profile/UserSection/LinkedIn",
  AvatarUrl = "@Profile/UserSection/AvatarUrl",
}

interface State {
  readonly file?: File;
  readonly profile: IndividualProfile;
  readonly avatarUrl?: string;
}

const createInitialState = (profile: IndividualProfile, avatarUrl?: string): State => ({
  profile,
  avatarUrl,
});

interface Props {
  readonly subjectId: string;
  readonly profile: IndividualProfile;
  readonly avatarURL?: string;
  readonly isUserProfile?: boolean;
}

export const IndividualProfileForm = (props: Props) => {
  const { subjectId: id, profile: currProfile, isUserProfile, avatarURL: currAvatarUrl } = props;
  const [state, setState] = useState(createInitialState(currProfile, currAvatarUrl));
  const { profile, file, avatarUrl } = state;
  const [linkedinProfileUrlError, setLinkedinProfileUrlError] = useState<string>();
  const { firstName, lastName, headline, linkedinProfileUrl } = profile;
  const hasChanges =
    currProfile.firstName !== firstName ||
    currProfile.lastName !== lastName ||
    currProfile.headline !== headline ||
    currProfile.linkedinProfileUrl !== linkedinProfileUrl ||
    (isUserProfile && currAvatarUrl !== avatarUrl);

  const [updateProfile, { loading }] = useMutation<Result, Variables>(MUTATION);

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, profile: { ...s.profile, [name]: value } }));
  }, []);

  const onLinkedinProfileUrlChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setLinkedinProfileUrlError(undefined);
      setState((s) => ({ ...s, profile: { ...s.profile, linkedinProfileUrl: value } }));
    },
    []
  );

  const fileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? event.target.files[0] : undefined;

    if (!newFile) {
      return;
    } else if (!utils.isValidLogoType(newFile.type)) {
      return toast.error("Invalid file format. Accepted file types: PNG, JPEG and JPG.");
    } else if (newFile.size > utils.LOGO_MAX_SIZE) {
      return toast.error("File exceeds the size limit (1MB).");
    }

    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onloadend = () => {
      const result = reader.result;
      if (isString(result)) {
        setState((s) => ({
          ...s,
          file: newFile,
          profile: { ...s.profile, avatarUrl: result },
          avatarUrl: result,
        }));
      }
    };
  }, []);

  const save = useCallback(() => {
    if (linkedinProfileUrl && !utils.LINKEDIN_REG_EXP.test(linkedinProfileUrl)) {
      setLinkedinProfileUrlError("Invalid URL.");
      return;
    }

    const avatar = file ? { upload: file } : undefined;
    const variables: Variables = {
      input: {
        subjectId: id,
        profile: { firstName, lastName, headline, avatar, linkedinProfileUrl },
      },
    };

    updateProfile({ variables })
      .then((res) => {
        if (!res.data || !res.data.payload || !res.data.payload.subject.profile) {
          return Promise.reject(noResultErrorFor("Update User Profile"));
        }
        const { profile: newProfile, avatarUrl: newAvatarUrl } = res.data.payload.subject;

        setState(createInitialState(newProfile, newAvatarUrl));
        toast.success("Changes saved.");
      })
      .catch((e: ApiError) => toast.error(extractErrorMessages(e).join()));
  }, [headline, file, firstName, id, lastName, linkedinProfileUrl, updateProfile]);

  const firstNameError = isEmpty(firstName) ? "Required field." : undefined;
  const lastNameError = isEmpty(lastName) ? "Required field." : undefined;
  const hasErrors = firstNameError !== undefined || lastNameError !== undefined;

  return (
    <Grid columns="16">
      <Grid.Row>
        {/* If it's a user profile we only allow to edit the avatarUrl,firstName and lastName */}
        {isUserProfile && (
          <>
            <Grid.Column width="4">
              <ImageUploader
                image={avatarUrl}
                accept={utils.VALID_LOGO_TYPES.join(", ")}
                onFileChange={fileChange}
              />
            </Grid.Column>
            <Grid.Column width="12">
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    id={FormIds.FirstName}
                    fluid
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName || ""}
                    onChange={onInputChange}
                    maxLength={255}
                    error={firstNameError}
                  />
                  <Form.Input
                    id={FormIds.LastName}
                    fluid
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    value={lastName || ""}
                    onChange={onInputChange}
                    maxLength={255}
                    error={lastNameError}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
          </>
        )}
        {/* If it's not a user profile we only allow to edit the headline and the linkedinUrl */}
        {!isUserProfile && (
          <Grid.Column width="16">
            <Form>
              <Form.Input
                id={FormIds.Headline}
                label="Headline"
                name="headline"
                placeholder="Describe yourself in a short sentence"
                value={headline || ""}
                onChange={onInputChange}
                maxLength={50}
              />
              <Form.Input
                id={FormIds.LinkedIn}
                name="linkedinProfileUrl"
                label="LinkedIn profile URL (must begin with https://):"
                placeholder="https://www.linkedin.com/in/jane-doe"
                value={linkedinProfileUrl || ""}
                onChange={onLinkedinProfileUrlChange}
                maxLength={100}
                error={linkedinProfileUrlError}
              />
            </Form>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row columns="1">
        <Grid.Column textAlign="right">
          <Button
            data-testid="saveButton"
            primary
            disabled={!hasChanges || hasErrors || loading}
            loading={loading}
            onClick={save}
          >
            Save Information
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
