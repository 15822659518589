import React, { CSSProperties, memo } from "react";
import { Image } from "semantic-ui-react";
import dateFormat from "dateformat";
import yourReplyIcon from "../../../../assets/your-reply-icon.svg";
import { DiscussionComment } from "../../../../api/tickets/DiscussionThreadsOnIsDiscussable";
import { nodesFromEdges } from "../../../../types/relay";
import { useSessionState } from "../../../../contexts/Session";
import { isEnrolled } from "../../../../contexts/Session/state";
import { DocumentBadge } from "../../DocumentBadge";
import { paragraphToHtml } from "../../../../utils/applications/Formatting";

const replyIconStyle: CSSProperties = { width: "20px", height: "20px" };
const replyImgStyle: CSSProperties = { width: "35px", height: "35px" };

interface Props {
  readonly comment: DiscussionComment;
}

export const Comment = memo(({ comment }: Props) => {
  const sessionState = useSessionState();
  const { insertedAt, body, author } = comment;
  const attachments = nodesFromEdges(comment.attachments.edges);
  const isAuthor = isEnrolled(sessionState) ? sessionState.roleId === author.id : false;
  const { avatarUrl } = author;

  return (
    <>
      <div className="Replies-header">
        <div>
          <Image floated="left" src={avatarUrl} circular style={replyImgStyle} />
          <span className={`header-name${isAuthor ? " green" : ""}`}>
            {isAuthor ? "YOUR RESPONSE" : author.fullName}
          </span>
          {isAuthor && (
            <Image size="mini" src={yourReplyIcon} verticalAlign="top" style={replyIconStyle} />
          )}
          &nbsp;
          <span className="header-date">{dateFormat(insertedAt, "HH:MM:ss | dd/mm/yy")}</span>
        </div>
      </div>
      <div className="Replies-reply">{paragraphToHtml(body)}</div>
      {attachments.length > 0 && (
        <div className="Replies-attachments DocumentBadgeGroup">
          {attachments.map(({ document: { id: docId, uploadedFile } }) => (
            <DocumentBadge key={docId} documentId={docId} documentName={uploadedFile.filename} />
          ))}
        </div>
      )}
    </>
  );
});
