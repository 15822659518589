import { gql, ApolloClient } from "@apollo/client";
import { SessionUser } from "../../types/frontend_only/sessionUser";

export const MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    payload: resetPassword(input: $input) {
      user {
        id
      }
    }
  }
`;

interface ResetPasswordInput {
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly token: string;
}

export interface Variables {
  readonly input: ResetPasswordInput;
}

export interface Result {
  readonly payload?: { readonly user: SessionUser };
}

export const perform = (client: ApolloClient<any>, input: ResetPasswordInput) =>
  client.mutate<Result, Variables>({ mutation: MUTATION, variables: { input } });
