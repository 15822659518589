import React from "react";
import { useForm } from "uniforms";
import { TicketSchema } from "../_types";

export const Title = () => {
  const {
    model: { title },
  } = useForm<TicketSchema>();

  return <>{title && <div className="ComponentHeader">{title}</div>}</>;
};
