import { useCallback, MouseEventHandler } from "react";
import { Image } from "semantic-ui-react";
import classNames from "classnames";
import { connectField, HTMLFieldProps } from "uniforms";
import { FeedbackRate } from "./FeedbackSchema";
import { isNumericEnum } from "../../types";
import iconSmile from "../../assets/icon-smile.svg";
import iconSmileOutline from "../../assets/icon-smile-outline.svg";
import iconMeh from "../../assets/icon-meh.svg";
import iconMehOutline from "../../assets/icon-meh-outline.svg";
import iconFrown from "../../assets/icon-frown.svg";
import iconFrownOutline from "../../assets/icon-frown-outline.svg";

interface Props extends HTMLFieldProps<FeedbackRate, HTMLDivElement> {}

export const CustomRateField = connectField((props: Props) => {
  const { onChange, value, className, disabled, error, required, label, errorMessage } = props;

  const customOnChange: MouseEventHandler = useCallback(
    ({ currentTarget: { id } }) => {
      const parsedId = parseInt(id, 10);
      if (isNumericEnum(parsedId, FeedbackRate)) {
        onChange(parsedId);
      }
    },
    [onChange]
  );

  const goodIcon = value === FeedbackRate.Good ? iconSmile : iconSmileOutline;
  const neutralIcon = value === FeedbackRate.Neutral ? iconMeh : iconMehOutline;
  const badIcon = value === FeedbackRate.Bad ? iconFrown : iconFrownOutline;

  return (
    <div className={classNames(className, { disabled, error, required }, "field CustomRateField")}>
      {label && <label className="CustomRateField-label">{label}</label>}
      <div className="CustomRateField-iconGroup">
        <Image src={goodIcon} id={FeedbackRate.Good} onClick={customOnChange} />
        <Image src={neutralIcon} id={FeedbackRate.Neutral} onClick={customOnChange} />
        <Image src={badIcon} id={FeedbackRate.Bad} onClick={customOnChange} />
      </div>
      {!!error && (
        <div className="CustomRateField-error">
          <div className="ui red basic pointing label">{errorMessage}</div>
        </div>
      )}
    </div>
  );
});
