import { useCallback, useEffect, useState } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { JSONSchemaType } from "ajv";
import { useForm } from "uniforms";
import { Button, ButtonProps, Divider, Image, Modal } from "semantic-ui-react";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { globalDefinitions } from "../../schemas/_definitions";
import { utils } from "../../utils/utils";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { CustomLongTextField } from "../../schemas/CustomLongTextField";
import { MUTATION, Variables, Result } from "../../api/SuggestLabels";
import { ErrorMessages } from "./ErrorMessages";
import { extractErrorMessages } from "../../types";
import { TagNamespace } from "../../types/labelQuery";
import approvedIcon from "../../assets/approved-icon.svg";

const initialModel: SuggestTagSchema = {
  suggestion: `Example: Parent tag: "Web, Mobile and Software Development"\nNew child tags:\n  - "AR / VR Development"\n  - "Automation QA"\n  - "Back-End Development"`,
};

interface Props {
  readonly namespace: TagNamespace;
}

export const SuggestLabelsModal = ({ namespace }: Props) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [hasSuggested, setHasSuggested] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>();
  const [suggestLabels, { loading }] = useMutation<Result, Variables>(MUTATION);

  useEffect(() => {
    // Clear state when the modal is closed;
    if (!open) {
      setError(undefined);
      setHasSuggested(false);
    }
  }, [open]);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const onSubmit = useCallback(
    (model: SuggestTagSchema) => {
      const url = `${location.pathname}${location.search}`;
      const suggestedLabels = model.suggestion;
      suggestLabels({ variables: { input: { url, namespace, suggestedLabels } } })
        .then((res) => {
          if (!res.data?.payload?.success) {
            throw new Error("Response didn't return success.");
          }
          setHasSuggested(true);
        })
        .catch((e) => {
          console.error(e);
          setError(e);
        });
    },
    [namespace, location, suggestLabels]
  );

  const onTriggerClick: React.MouseEventHandler<HTMLDivElement> = useCallback((ev) => {
    ev.stopPropagation();
  }, []);

  return (
    <div onClick={onTriggerClick} onChange={onTriggerClick}>
      <Modal
        className="SuggestLabelsModal CustomClose"
        size={hasSuggested ? "tiny" : "small"}
        closeIcon={!hasSuggested}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        trigger={
          <div className="SuggestLabelsModal-Trigger">
            <Divider className="SuggestLabelsModal-Trigger-divider" />
            <div className="SuggestLabelsModal-Trigger-text">Suggest tags</div>
          </div>
        }
      >
        {!hasSuggested && (
          <>
            <div className="SuggestLabelsModal-Header">Suggest new tags</div>
            <AutoForm schema={bridge} model={initialModel} onSubmit={onSubmit}>
              <CustomLongTextField
                name="suggestion"
                label="How should the suggested tag(s) appear in the list?"
              />
              <ErrorMessages errors={error ? extractErrorMessages(error) : error} />
              <Button
                basic
                color="blue"
                type="button"
                loading={loading}
                disabled={loading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <CustomSubmitButton type="submit" primary loading={loading} disabled={loading}>
                Submit
              </CustomSubmitButton>
            </AutoForm>
          </>
        )}
        {hasSuggested && (
          <div className="SuggestLabelsModal-Success">
            <Image src={approvedIcon} className="SuggestLabelsModal-Success-icon" />
            <div className="SuggestLabelsModal-Success-title">Suggestion received</div>
            <div className="SuggestLabelsModal-Success-paragraph">
              Thank you. Our team considers each suggestion carefully and will soon follow up with
              you regarding the outcome.
            </div>
            <Button
              className="SuggestLabelsModal-Success-button"
              type="button"
              primary
              onClick={onClose}
            >
              Continue
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

interface SuggestTagSchema {
  readonly suggestion: string;
}

const schema: JSONSchemaType<SuggestTagSchema> = {
  type: "object",
  properties: {
    suggestion: { ...globalDefinitions.nonEmptyString, maxLength: 1000 },
  },
  required: ["suggestion"],
};

const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));

const CustomSubmitButton = ({ children, ...props }: ButtonProps) => {
  const { model } = useForm<SuggestTagSchema>();
  const { loading } = props;
  const disabled = loading || model.suggestion === initialModel.suggestion;

  return (
    <Button {...props} disabled={disabled}>
      {children}
    </Button>
  );
};
