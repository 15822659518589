import { useMemo, useCallback } from "react";
import classNames from "classnames";
import { Label, Image } from "semantic-ui-react";
import { connectField, HTMLFieldProps } from "uniforms";
import { TagTree, TagTreeHelper } from "../types/tags";
import { ArrayErrorsField } from "./ArrayErrorsField";
import { NestedTags } from "../components/NestedTags";
import closeIcon from "../assets/close-icon-white.svg";
import { TagNamespace } from "../types/labelQuery";

interface Props extends HTMLFieldProps<string[], HTMLElement> {
  readonly [k: string]: unknown;
  readonly options: Readonly<TagTree>;
  readonly namespace: TagNamespace;
  readonly collapsedText?: string;
}
export const NestedTagsField = connectField((props: Props) => {
  const { label, value, onChange, error, showInlineError, errorMessage, namespace } = props;
  const { className, disabled, required, options, placeholder, collapsedText } = props;

  const handleChange = useCallback((v: string[]) => onChange(v), [onChange]);

  const valueToLabelMapper = useCallback(
    (v) => TagTreeHelper.findByLabel(options, v) || v,
    [options]
  );

  const checkedLabels = useMemo(() => {
    return value?.map((c) => {
      const node = TagTreeHelper.findByLabel(options, c);
      if (!node) {
        return null;
      }

      const branchToRemove = TagTreeHelper.flatten(TagTreeHelper.getTreeBranch(options, c));

      const onRemove = () => onChange(value.filter((el) => !branchToRemove.includes(el)));
      return (
        <Label key={c}>
          {node.description}
          <Image src={closeIcon} onClick={onRemove} />
        </Label>
      );
    });
  }, [value, options, onChange]);

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <NestedTags
        checked={value}
        tags={options}
        placeholder={placeholder}
        collapsedText={collapsedText}
        onFilter={handleChange}
        namespace={namespace}
      />
      {checkedLabels && checkedLabels.length > 0 && (
        <Label.Group className="CustomCheckboxTree-LabelGroup">{checkedLabels}</Label.Group>
      )}

      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage?.toString()}</div>
      )}
      <ArrayErrorsField name="" valueToLabelMapper={valueToLabelMapper} />
    </div>
  );
});
