import { gql, ApolloClient } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { ReceivedOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation AmendOnboard($input: AmendOnboardInput!) {
    payload: amendOnboard(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

interface AmendOnboardInput {
  readonly onboardId: string;
  readonly data: string;
  readonly schemaVersion: string;
}

export interface Variables {
  readonly input: AmendOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: ReceivedOnboard };
}

export const perform = (client: ApolloClient<any>, input: AmendOnboardInput) =>
  client.mutate<Result>({ mutation: MUTATION, variables: { input } });
