import { gql, ApolloClient } from "@apollo/client";

export const MUTATION = gql`
  mutation InitiatePasswordReset($input: InitiatePasswordResetInput!) {
    payload: initiatePasswordReset(input: $input) {
      success
    }
  }
`;

interface InitiatePasswordResetInput {
  readonly captcha: string;
  readonly emailAddress: string;
}

interface Variables {
  readonly input: InitiatePasswordResetInput;
}

export interface Result {
  readonly payload?: { readonly success: boolean };
}

export const perform = (client: ApolloClient<any>, input: InitiatePasswordResetInput) =>
  client.mutate<Result, Variables>({ mutation: MUTATION, variables: { input } });
