import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, ModalHeader, ModalContent, Popup, Image } from "semantic-ui-react";
import { useMutation, useLazyQuery } from "@apollo/client";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { QUERY, Result, Variables } from "../../../api/network/getTagsByRoleId";
import { MUTATION as unfollowMutation } from "../../../api/followers/unfollow";
import { Result as unfollowRes } from "../../../api/followers/unfollow";
import { Variables as unfollowVars } from "../../../api/followers/unfollow";
import { MUTATION as followMutation } from "../../../api/followers/follow";
import { Result as followRes } from "../../../api/followers/follow";
import { Variables as followVars } from "../../../api/followers/follow";
import followStar from "../../../assets/follow-star.svg";
import unfollowStar from "../../../assets/unfollow-star.svg";
import { Expert } from "../../../api/network/experts";
import { sortedTags } from "../../../types/labelQuery";
import { LoaderWithMargin } from "../../Loader";

interface Props {
  readonly expert: Expert;
  readonly showModal: boolean;
  readonly closeModal: () => void;
}

export const ExpertModal = ({ expert, showModal, closeModal }: Props) => {
  const { canInvest, fullName, insertedAt, followedBy } = expert;
  const { id, avatarUrl, profile } = expert;

  // Queries and mutations.
  const [getTags, { data, loading, error, called }] = useLazyQuery<Result, Variables>(QUERY, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const [follow] = useMutation<followRes, followVars>(followMutation);
  const [unfollow] = useMutation<unfollowRes, unfollowVars>(unfollowMutation);
  const [followCD, setFollowCD] = useState<boolean>(false);

  useEffect(() => {
    // Runs the query to get the tags once only, after the first time showModal is true.
    if (called || loading || data || error || !showModal) {
      return;
    }
    getTags({ variables: { id } });
  }, [called, loading, data, error, showModal, id, getTags]);

  useEffect(() => {
    if (followCD) {
      setTimeout(() => {
        setFollowCD(false);
      }, 600);
    }
  }, [followCD]);

  const handleFollow = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (followCD) {
        return;
      }

      setFollowCD(true);
      const mutation = followedBy ? unfollow : follow;
      const toastMessage = `You have ${followedBy ? "unfollowed" : "followed"} ${fullName}.`;

      mutation({
        variables: {
          input: { followableId: id },
        },
      })
        .then(() => toast.success(toastMessage))
        .catch((err) => {
          toast.error("Something went wrong.");
          console.warn(err);
        });
    },
    [follow, unfollow, followedBy, followCD, fullName, id]
  );

  const tags = useMemo(() => {
    if (!data || !data.payload?.tags) {
      return;
    }
    return sortedTags(data.payload.tags);
  }, [data]);

  return (
    <Modal
      open={showModal}
      size="tiny"
      className="CustomClose"
      closeIcon
      closeOnDimmerClick={false}
      onClose={closeModal}
    >
      <ModalHeader />
      <ModalContent>
        <div className="NetworkModal">
          <div className="NetworkModal-header">
            <Image className="AvatarPicture55" floated="left" src={avatarUrl} circular />
            <div className="NetworkModal-header-labels">
              {canInvest && (
                <div className="NetworkModal-header-labels-investlabel">Ready to invest</div>
              )}
              <div className="NetworkModal-header-labels-star">
                <Popup
                  content={"Follow"}
                  trigger={
                    <Image
                      className="top-section-follow"
                      src={followedBy ? unfollowStar : followStar}
                      alt="star"
                      verticalAlign="middle"
                      onClick={handleFollow}
                      disabled={followCD}
                    />
                  }
                  position="top center"
                />
              </div>
            </div>
          </div>
          <div className="NetworkModal-name">{fullName}</div>
          <div className="NetworkModal-description">
            Joined on {dateFormat(insertedAt, "dd/mm/yy")}
          </div>
          <div className="NetworkModal-description">{profile.headline}</div>
          {profile.linkedinProfileUrl && (
            <div className="NetworkCard-links">
              <a
                className="NetworkCard-links-link"
                href={profile.linkedinProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </div>
          )}
        </div>
      </ModalContent>
      <div className="NetworkModal-tags-section">
        {loading && !data && <LoaderWithMargin />}
        {tags && (
          <>
            {tags.skills.length > 0 && (
              <>
                <div className="NetworkModal-tags-section-title">
                  {tags.skills.length === 1 ? "Skill" : "Skills"}
                </div>
                <div className="NetworkModal-tags-section-list">
                  {tags.skills.map((s) => (
                    <div key={`skill-${s.description}`} className="NetworkModal-tags-section-tag">
                      {s.description}
                    </div>
                  ))}
                </div>
              </>
            )}
            {tags.sectors.length > 0 && (
              <>
                <div className="NetworkModal-tags-section-title">
                  {tags.sectors.length === 1 ? "Sector" : "Sectors"}
                </div>
                <div className="NetworkModal-tags-section-list">
                  {tags.sectors.map((s) => (
                    <div key={`sector-${s.description}`} className="NetworkModal-tags-section-tag">
                      {s.description}
                    </div>
                  ))}
                </div>
              </>
            )}
            {tags.occuptions.length > 0 && (
              <>
                <div className="NetworkModal-tags-section-title">
                  {tags.occuptions.length === 1 ? "Occupation" : "Occupations"}
                </div>
                <div className="NetworkModal-tags-section-list">
                  {tags.occuptions.map((o) => (
                    <div
                      key={`occupation-${o.description}`}
                      className="NetworkModal-tags-section-tag"
                    >
                      {o.description}
                    </div>
                  ))}
                </div>
              </>
            )}
            {tags.maturities.length > 0 && (
              <>
                <div className="NetworkModal-tags-section-title">
                  {tags.maturities.length === 1 ? "Experience type" : "Experience types"}
                </div>
                <div className="NetworkModal-tags-section-list">
                  {tags.maturities.map((m) => (
                    <div
                      key={`maturity-${m.description}`}
                      className="NetworkModal-tags-section-tag"
                    >
                      {m.description}
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
