import { useMutation } from "@apollo/client";
import dateFormat from "dateformat";
import { CSSProperties, useCallback, useMemo, useState } from "react";
import { Button, Checkbox, Grid, Image, Modal } from "semantic-ui-react";
import { Migrated } from "../../../migrations/_helpers";
import { BusinessCases } from "../../../schemas/invites/_types";
import { OnboardState, readableOnboardStatusAndColor } from "../../../types/onboard";
import { PublicOnboardViewedByMember, readableType } from "../../../types/onboard";
import { MUTATION, Result, Variables } from "../../../api/onboards/ApplyAsSeconder";
import ribbonIcon from "../../../assets/purple-ribbon.svg";
import { TextClamp } from "../../elements/TextClamp";
import { ApiError, extractErrorMessages, isDefined } from "../../../types";
import { toast } from "react-toastify";
import { Enrolled } from "../../../contexts/Session/state";
import { useSettingsState } from "../../../contexts/Settings";
import { isSuccessState } from "../../../contexts/Generic";
import { CBCMemberExpertOnboard } from "../../../api/onboards/CBCMemberExpertOnboard";

const displayEndStyle: CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  alignSelf: "end",
};
const flexSpaceBetween: CSSProperties = { display: "flex", justifyContent: "space-between" };
const flexStyle: CSSProperties = { display: "flex", alignItems: "center", gap: "8px" };

export enum RouteType {
  Default = "DEFAULT",
  Cbc = "CBC",
}

interface Props {
  readonly onboard: Migrated<PublicOnboardViewedByMember | CBCMemberExpertOnboard>;
  readonly sessionState: Enrolled;
  readonly route: RouteType;
}

export const ExpertDetailsBreadcrumb = ({ onboard, sessionState, route }: Props) => {
  const settingsState = useSettingsState();
  const { fullName, migratedForm, type, submittedAt } = onboard;
  const { id: onboardId, extraFields, state } = onboard;
  const { seconderCandidacy, assignedSeconderCandidacies } = extraFields;
  const { roleId } = sessionState;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cbTraining, setCbTraining] = useState(false);

  const isAssigned = isDefined(
    assignedSeconderCandidacies?.find(({ seconder }) => seconder.id === roleId)
  );

  const { status, color } = useMemo(() => readableOnboardStatusAndColor(onboard), [onboard]);

  const requiredSeconderCount = useMemo(() => {
    if (!isSuccessState(settingsState)) {
      return 2;
    }

    return settingsState.result.core.required_seconder_count;
  }, [settingsState]);

  const [applyAsSeconder, { loading }] = useMutation<Result, Variables>(MUTATION);

  const secondersMissing = requiredSeconderCount - (assignedSeconderCandidacies?.length || 0);
  const canSecond = !seconderCandidacy && state === OnboardState.AwaitingSeconders;

  const changeCheckbox = useCallback(() => setCbTraining(!cbTraining), [cbTraining]);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => {
    setCbTraining(false);
    setIsModalOpen(false);
  }, []);

  const handleSecond = useCallback(() => {
    applyAsSeconder({ variables: { input: { onboardId, requestedTraining: cbTraining }, roleId } })
      .then(() => {
        setCbTraining(false);
        setIsModalOpen(false);
      })
      .catch((err: ApiError) => toast.error(extractErrorMessages(err).join(", ")));
  }, [applyAsSeconder, cbTraining, onboardId, roleId]);

  const businessCasesLabel = useMemo(() => {
    if (!migratedForm?.data.businessCase) {
      return;
    }
    switch (migratedForm.data.businessCase) {
      case BusinessCases.CoInvestor:
        return " | Co-Investor";
      case BusinessCases.EarlyProfessional:
        return " | Early Professional";
      case BusinessCases.Introducer:
        return " | Introducer";
      case BusinessCases.ProminentSkills:
        return " | Professional";
    }
  }, [migratedForm]);

  if (!migratedForm) {
    return null;
  }

  return (
    <div className="OnboardHeader">
      <Grid columns="16">
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid.Row style={flexSpaceBetween}>
              <div className="OnboardHeader-description-title">
                <TextClamp text={fullName || "Unknown"} />
              </div>
              {route !== RouteType.Cbc && (
                <div style={flexSpaceBetween}>
                  <span className="OnboardHeader-description-small-label">Status</span>
                  &nbsp;&nbsp;&nbsp;
                  <b>
                    <div style={flexStyle}>
                      <TextClamp text={status} maxLines={2} />
                      <div className={`StatusCircle ${color}`} />
                    </div>
                  </b>
                </div>
              )}
            </Grid.Row>

            <Grid.Row className="OnboardHeader-description">
              <Grid>
                <Grid.Column width={10}>
                  <Grid>
                    <Grid.Column computer="5" tablet="5" mobile="5">
                      <Grid.Row>
                        <div className="OnboardHeader-description-small-label">Expert Type</div>
                      </Grid.Row>
                      <Grid.Row>
                        <TextClamp
                          text={`${readableType(type)} ${businessCasesLabel}`}
                          maxLines={2}
                        />
                      </Grid.Row>
                    </Grid.Column>
                    {submittedAt && (
                      <Grid.Column computer="4" tablet="4" mobile="5">
                        <Grid.Row>
                          <div className="OnboardHeader-description-small-label">
                            Date submitted
                          </div>
                        </Grid.Row>
                        <Grid.Row>{dateFormat(submittedAt, "dd/mm/yy")}</Grid.Row>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid.Column>
                {state === OnboardState.AwaitingSeconders && (
                  <Grid.Column width={6} style={displayEndStyle}>
                    {canSecond && (
                      <Modal
                        closeOnDimmerClick={false}
                        size="small"
                        open={isModalOpen}
                        trigger={
                          <Button
                            color="blue"
                            onClick={openModal}
                            loading={loading}
                            disabled={loading}
                          >
                            Volunteer to second
                          </Button>
                        }
                      >
                        <div className="ExpertDetailsVolunteerModal">
                          <div className="ExpertDetailsVolunteerModal-title">
                            You are volunteering to be this nominee's Seconder
                          </div>
                          <Checkbox
                            checked={cbTraining}
                            onChange={changeCheckbox}
                            label="I would like additional training on being a seconder"
                          />
                          <div className="ExpertDetailsVolunteerModal-bttns-wrapper">
                            <Button
                              color="blue"
                              basic
                              onClick={closeModal}
                              loading={loading}
                              disabled={loading}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="blue"
                              onClick={handleSecond}
                              loading={loading}
                              disabled={loading}
                            >
                              Yes, I'll second
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                    <div className="OnboardHeader-volunter">
                      {!isAssigned && seconderCandidacy && (
                        <>
                          <span className="OnboardHeader-volunter-label-sub">
                            {secondersMissing} {secondersMissing > 1 ? "Seconders" : "Seconder"}{" "}
                            missing
                          </span>
                          <span className="OnboardHeader-volunter-label-sub">
                            Awaiting Assignment
                          </span>
                        </>
                      )}
                      {isAssigned && (
                        <>
                          <div className="OnboardHeader-assigned-label">
                            Seconder <Image src={ribbonIcon} />
                          </div>
                          <div className="OnboardHeader-assigned-label-sub">
                            {secondersMissing} {secondersMissing > 1 ? "Seconders" : "Seconder"}{" "}
                            missing
                          </div>
                        </>
                      )}
                    </div>
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
