import { CSSProperties, useMemo, useCallback, useEffect, MouseEventHandler, useState } from "react";
import { Grid, Icon, Image, Segment } from "semantic-ui-react";
import { useQuery, useSubscription } from "@apollo/client";
import { toast } from "react-toastify";
import { PreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";
import { readablePreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";
import { PublicOnboardViewedByMember } from "../../../types/onboard";
import { KnownProposalKinds, preselectionVotesCountByChoice } from "../../../types/proposal";
import { CommunitySentiment } from "./CommunitySentiment";
import { leftColumnSize, rightColumnSize } from "./StartupDetailsTab";
import { QUERY, Variables, Result } from "../../../api/proposals/Ballots";
import { SUBSCRIPTION, NewProposalBallot } from "../../../api/_subscriptions/NewProposalBallot";
import { Variables as SubscriptionVars } from "../../../api/_subscriptions/NewProposalBallot";
import { LoaderWithMargin } from "../../Loader";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { assertUnreachable, extractErrorMessages, isEnum } from "../../../types";
import { nodesFromEdges } from "../../../types/relay";
import { VoteCollapsible } from "./VoteCollapsible";
import { PageContentInfinityScroll } from "../../InfinityScrollWrapper";
import infoIcon from "../../../assets/icon-information.svg";
import clockIcon from "../../../assets/icon-clock.svg";
import { PreselectionRepliesModal } from "./PreselectionRepliesModal";
import { Shortcuts as S } from "../../../routing";
import { useHistory, useLocation } from "react-router-dom";
import { BallotSortFields, SortDirection } from "../../../types/ballotsSort";
import { useSessionState } from "../../../contexts/Session";
import { isAtLeastPresent, isEnrolled } from "../../../contexts/Session/state";
import { PreselectionVoteMigrator } from "../../../migrations/PreselectionVote";

const first: number = 10;

const neutralIconStyle: CSSProperties = { transform: "rotate(90deg) scaleY(-1)" };

export enum VoteTabs {
  Yes = "yes",
  No = "no",
  Abstain = "abstain",
}

const voteTabVarName = S.startup.queryVarNames.voteTab;

interface Props {
  readonly onboard: PublicOnboardViewedByMember;
  readonly view: "startup-pipeline" | "my-application";
}

export const PreselectionDiscussionTab = ({ onboard, view }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const sessionState = useSessionState();
  // const roleId = isEnrolled(sessionState) ? sessionState.roleId : undefined;
  const { form, proposal } = onboard;
  const [tabLoading, setTabLoading] = useState(false);

  const selectedVoteTab = useMemo(() => {
    const voteTab = new URLSearchParams(location.search).get(voteTabVarName) || VoteTabs.Yes;
    return isEnum(voteTab, VoteTabs) ? voteTab : VoteTabs.Yes;
  }, [location]);

  const { data: subData } = useSubscription<NewProposalBallot, SubscriptionVars>(SUBSCRIPTION, {
    variables: {
      id: proposal?.id || "",
      authorisation: {
        bearerToken: isAtLeastPresent(sessionState) ? sessionState.token : "",
        roleId: isEnrolled(sessionState) ? sessionState.roleId : undefined,
      },
    },
  });

  const { data, loading, error, fetchMore, refetch } = useQuery<
    Result<PreselectionProposalChoice>,
    Variables
  >(QUERY, {
    variables: {
      onboardId: onboard.id,
      kind: KnownProposalKinds.Preselection,
      first,
      choice: voteTabToChoice(selectedVoteTab),
      sortBy: isEnrolled(sessionState)
        ? {
            direction: SortDirection.DESC,
            field: BallotSortFields.ROLE_ID,
            match: { id: sessionState.roleId },
          }
        : undefined,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const edgeLength = data?.node?.proposal?.ballots?.edges?.length || first;
  // Everytime subscription data arrives, if the choice of the new ballot is the same as the current selected
  // tab, refetch the previous query with the same amount of results that were already loaded plus one.
  useEffect(() => {
    if (!subData || subData.newProposalBallot.choice !== voteTabToChoice(selectedVoteTab)) {
      return;
    }

    refetch({ first: edgeLength + 1 }).catch((err) =>
      toast.error(extractErrorMessages(err).join(","))
    );
  }, [subData, selectedVoteTab, edgeLength, refetch]);

  useEffect(() => {
    setTabLoading(true);
    refetch()
      .catch((err) => toast.error(extractErrorMessages(err).join(", ")))
      .finally(() => setTabLoading(false));
  }, [selectedVoteTab, refetch]);

  useEffect(() => {
    if (onboard === undefined || !onboard.proposal) {
      return;
    }
    const newSearch = new URLSearchParams(location.search);
    if (!newSearch.get(voteTabVarName)) {
      newSearch.append(voteTabVarName, selectedVoteTab);
      history.replace({ search: newSearch.toString() });
    }
  }, [onboard, selectedVoteTab, history, location]);

  const onTabClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      if (loading) {
        return;
      }

      const newTab = ev.currentTarget.id;
      if (isEnum(newTab, VoteTabs)) {
        const newSearch = new URLSearchParams(location.search);
        newSearch.set(voteTabVarName, newTab);
        return history.replace({ search: newSearch.toString() });
      }
    },
    [loading, history, location]
  );

  const ballots = useMemo(() => {
    // Converts ballot edges to nodes and migrates each ballot.
    return nodesFromEdges(data?.node?.proposal?.ballots?.edges).map((b) => {
      const { migrate } = new PreselectionVoteMigrator(b);
      return migrate();
    });
  }, [data]);

  const onNext = useCallback(() => {
    const pageInfo = data?.node?.proposal?.ballots?.pageInfo;
    if (fetchMore && pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          onboardId: onboard.id,
          kind: KnownProposalKinds.Preselection,
          first,
          after: pageInfo.endCursor,
          choice: voteTabToChoice(selectedVoteTab),
        },
      });
    }
  }, [onboard, data, selectedVoteTab, fetchMore]);

  if (!form || !form.data.startupData) {
    return null;
  } else if (loading && !data) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  }

  const yesVoteTabClassname = `BallotsTabSelector-header-column${
    selectedVoteTab === VoteTabs.Yes ? " selected" : ""
  }`;
  const noVoteTabClassname = `BallotsTabSelector-header-column${
    selectedVoteTab === VoteTabs.No ? " selected" : ""
  }`;
  const abstainVoteTabClassname = `BallotsTabSelector-header-column${
    selectedVoteTab === VoteTabs.Abstain ? " selected" : ""
  }`;

  const { upvotesCount, downvotesCount, neutralsCount } = proposal?.tally
    ? preselectionVotesCountByChoice(proposal.tally)
    : { upvotesCount: 0, downvotesCount: 0, neutralsCount: 0 };

  return (
    <Grid>
      <PreselectionRepliesModal />
      <Grid.Column width={proposal?.hasVoted ? leftColumnSize : "16"}>
        <Segment className={`BallotsTabSelector${loading ? " is-loading" : ""}`}>
          {view === "startup-pipeline" && (
            <div className="BallotsTabSelector-pageTitle">
              Should {onboard.fullName} proceed to General Assessment?
            </div>
          )}
          {view === "my-application" && (
            <>
              <div className="BallotsTabSelector-disclaimer">
                <Image src={infoIcon} /> Note that the feedback provided by members is not always
                representative of the community's opinion at large.
              </div>
              <div className="BallotsTabSelector-pageTitle small-margin">
                We asked our community...
              </div>
              <div className="BallotsTabSelector-pageSubtitle small-margin">
                Should {onboard.fullName} proceed to General Assessment?
              </div>
            </>
          )}
          {/* Header */}
          <div className="BallotsTabSelector-header">
            <div id={VoteTabs.Yes} className={yesVoteTabClassname} onClick={onTabClick}>
              <div className="BallotsTabSelector-vote-counter">
                {voteParagraphFor(upvotesCount)}
              </div>
              <div className="BallotsTabSelector-vote-yes">
                {readablePreselectionProposalChoice(PreselectionProposalChoice.Yes)}
                <Icon name="thumbs up" />
              </div>
            </div>
            <div id={VoteTabs.No} className={noVoteTabClassname} onClick={onTabClick}>
              <div className="BallotsTabSelector-vote-counter">
                {voteParagraphFor(downvotesCount)}
              </div>
              <div className="BallotsTabSelector-vote-no">
                {readablePreselectionProposalChoice(PreselectionProposalChoice.No)}
                <Icon name="thumbs down" />
              </div>
            </div>
            <div id={VoteTabs.Abstain} className={abstainVoteTabClassname} onClick={onTabClick}>
              <div className="BallotsTabSelector-vote-counter">
                {voteParagraphFor(neutralsCount)}
              </div>
              <div className="BallotsTabSelector-vote-neutral">
                {readablePreselectionProposalChoice(PreselectionProposalChoice.Neutral)}
                <Icon name="thumbs down" style={neutralIconStyle} />
              </div>
            </div>
          </div>

          {/* Body */}
          <Grid className="BallotsTabSelector-body">
            {tabLoading && <LoaderWithMargin />}
            {!tabLoading && (
              <>
                {ballots.length <= 0 && (
                  <div className="BallotsTabSelector">
                    <div className="BallotsTabSelector-no-votes">
                      <Image src={clockIcon} />
                      <div className="BallotsTabSelector-no-votes-description">
                        There aren't any votes yet, check back later!
                      </div>
                    </div>
                  </div>
                )}
                {ballots.length > 0 && (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <div className="BallotsTabSelector-body-title">Individual votes</div>
                        <p>
                          The decision to progress this applicant to General Assessment is made by
                          the Industry Committee based on community feedback.
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <PageContentInfinityScroll
                      dataLength={data?.node?.proposal?.ballots?.edges?.length || 0}
                      next={onNext}
                      hasMore={!!data?.node?.proposal?.ballots?.pageInfo?.hasNextPage}
                      loader={loading ? <LoaderWithMargin /> : null}
                    >
                      <Grid width="16" className="BallotsTabSelector-body-grid">
                        <Grid.Row className="BallotsTabSelector-body-first-row">
                          <Grid.Column width="1">DETAILS</Grid.Column>
                          <Grid.Column width="4">MEMBER</Grid.Column>
                          <Grid.Column width="2">DATE</Grid.Column>
                          <Grid.Column width="7">ADDITIONAL COMMENTS</Grid.Column>
                          <Grid.Column width="2">REPLIES</Grid.Column>
                        </Grid.Row>
                        {ballots.map((b) => (
                          <VoteCollapsible
                            key={b.id}
                            startupName={onboard.fullName || "this startup"}
                            ballot={b}
                            // TODO: temporary until the backend exposes a way to tell if it's my ballot.
                            isOwner={false}
                            // isOwner={isDefined(roleId) ? roleId === b.role?.id : false}
                          />
                        ))}
                      </Grid>
                    </PageContentInfinityScroll>
                  </>
                )}
              </>
            )}
          </Grid>
        </Segment>
      </Grid.Column>
      {proposal?.hasVoted && (
        <Grid.Column width={rightColumnSize}>
          <CommunitySentiment onboard={onboard} proposal={proposal} />
        </Grid.Column>
      )}
    </Grid>
  );
};

const voteParagraphFor = (voteCount: number) => {
  return (
    <>
      {voteCount} {voteCount === 1 ? "vote" : "votes"}
    </>
  );
};

const voteTabToChoice = (voteTab: VoteTabs) => {
  switch (voteTab) {
    case VoteTabs.Yes:
      return PreselectionProposalChoice.Yes;
    case VoteTabs.No:
      return PreselectionProposalChoice.No;
    case VoteTabs.Abstain:
      return PreselectionProposalChoice.Neutral;
  }
  return assertUnreachable(voteTab);
};
