import { MouseEventHandler, useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { Enrolled } from "../../../contexts/Session/state";
import { Shortcuts as S } from "../../../routing";
import { isEnum } from "../../../types";
import { MyBids } from "./MyBids";
import { MyProjects } from "./MyProjects";

const tabVarName = S.myWork.queryVarNames.tab;
export enum Tabs {
  MyProjects = "my-projects",
  Bids = "bids",
}
export enum TabTitles {
  MyProjects = "My Projects",
  Bids = "Bids",
}

interface Props {
  readonly sessionState: Enrolled;
}

export const MyWork = ({ sessionState }: Props) => {
  const breadcrumbApi = useBreadcrumbApi();
  const history = useHistory();
  const location = useLocation();
  const activeTab = (new URLSearchParams(location.search).get(tabVarName) as Tabs) || Tabs.Bids;

  const onTabChange: MouseEventHandler<HTMLDivElement> = useCallback(
    ({ currentTarget }) => {
      if (isEnum(currentTarget.id, Tabs) && currentTarget.id !== activeTab) {
        const params = new URLSearchParams({ [tabVarName]: currentTarget.id });
        history.push({ search: params.toString() });
      }
    },
    [history, activeTab]
  );

  const breadcrumbFooter = useMemo(() => {
    return (
      <div className="Breadcrumb-children-wrapper">
        <div className="Panes">
          <div
            className={`panel${activeTab === Tabs.MyProjects ? " active" : ""}`}
            id={Tabs.MyProjects}
            onClick={onTabChange}
          >
            {TabTitles.MyProjects}
          </div>
          <div
            className={`panel${activeTab === Tabs.Bids ? " active" : ""}`}
            id={Tabs.Bids}
            onClick={onTabChange}
          >
            {TabTitles.Bids}
          </div>
        </div>
      </div>
    );
  }, [activeTab, onTabChange]);

  useEffect(() => {
    breadcrumbApi.addFooter(breadcrumbFooter);
    return () => {
      breadcrumbApi.removeFooter();
    };
  }, [breadcrumbApi, breadcrumbFooter]);

  useEffect(() => {
    const ele = document.getElementById("PageContentId");
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }, [activeTab]);

  return (
    <div className="WrapperSection">
      {activeTab === Tabs.MyProjects && <MyProjects sessionState={sessionState} />}
      {activeTab === Tabs.Bids && <MyBids sessionState={sessionState} />}
    </div>
  );
};
