import { Dispatch } from "react";
import { Action, ActionTypes } from "./actions";
import { Crumb } from "./state";

export class Actioner {
  private readonly dispatch: Dispatch<Action>;

  constructor(dispatch: Dispatch<Action>) {
    this.dispatch = dispatch;
  }

  addHeader = (header: JSX.Element) => this.dispatch({ type: ActionTypes.AddHeader, header });
  removeHeader = () => this.dispatch({ type: ActionTypes.RemoveHeader });

  addBody = (body: JSX.Element) => this.dispatch({ type: ActionTypes.AddBody, body });
  removeBody = () => this.dispatch({ type: ActionTypes.RemoveBody });

  addFooter = (footer: JSX.Element) => this.dispatch({ type: ActionTypes.AddFooter, footer });
  removeFooter = () => this.dispatch({ type: ActionTypes.RemoveFooter });

  addCustomTitle = (customTitle: string) =>
    this.dispatch({ type: ActionTypes.AddCustomTitle, customTitle });
  removeCustomTitle = () => this.dispatch({ type: ActionTypes.RemoveCustomTitle });

  addCrumb = (crumb: Crumb) => this.dispatch({ type: ActionTypes.AddCrumb, crumb });

  addCrumbsExtra = (extra: JSX.Element) =>
    this.dispatch({ type: ActionTypes.AddCrumbsExtra, extra });
  removeCrumbsExtra = () => this.dispatch({ type: ActionTypes.RemoveCrumbsExtra });

  showBreadcrumbs = (showBreadcrumbs: boolean) =>
    this.dispatch({ type: ActionTypes.ToggleCrumb, showBreadcrumbs });
}
