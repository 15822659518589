import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { FundraisingInfo } from "../../../types/OnboardForm";
import { utils } from "../../../utils/utils";
import { PreselectionForm } from "../utils/_types";
import { globalDefinitions } from "../../_definitions";
import { EntityType } from "../../../types/onboard";

export const schema: JSONSchemaType<PreselectionForm> = {
  description: "The Startup Pre-selection form.",
  type: "object",
  properties: {
    startupData: {
      type: "object",
      properties: {
        entityType: {
          type: "string",
          const: EntityType.Organisation,
        },
        name: {
          type: "string",
          maxLength: 50,
          pattern: "(.|s)*\\S(.|s)*",
        },
        headquarters: {
          type: "array",
          items: globalDefinitions.countries,
          minItems: 1,
          maxItems: 3,
        },
        summary: {
          type: "string",
          maxLength: 2500,
          pattern: "(.|s)*\\S(.|s)*",
        },
        vision: {
          type: "string",
          maxLength: 2500,
          pattern: "(.|s)*\\S(.|s)*",
        },
        team: {
          type: "string",
          maxLength: 2500,
          pattern: "(.|s)*\\S(.|s)*",
        },
        usp: {
          type: "string",
          maxLength: 2500,
          pattern: "(.|s)*\\S(.|s)*",
        },
        roadmap: {
          type: "string",
          maxLength: 2500,
          pattern: "(.|s)*\\S(.|s)*",
        },
        pitchHighlights: {
          type: "array",
          items: {
            type: "string",
            pattern: "(.|s)*\\S(.|s)*",
            maxLength: 200,
          },
          minItems: 1,
          maxItems: 3,
        },
        pitchDeck: globalDefinitions.document,
        maturity: globalDefinitions.maturity,
        sectors: {
          type: "array",
          items: globalDefinitions.sector,
          minItems: 1,
        },
        logo: globalDefinitions.document,
        fundraisingInfo: {
          type: "string",
          enum: Object.values(FundraisingInfo),
        },
        valuation: {
          type: "number",
          minimum: 0,
        },
        hasFixedValuation: {
          type: "boolean",
        },
        companiesHouse: globalDefinitions.httpsUrl,
        equityOffered: {
          type: "number",
          minimum: 0,
          maximum: 100,
        },
        amountRaised: {
          type: "number",
          minimum: 0,
        },
        hasLeadInvestor: {
          type: "boolean",
          default: false,
        },
      },
      required: [
        "entityType",
        "name",
        "summary",
        "headquarters",
        "vision",
        "team",
        "usp",
        "roadmap",
        "pitchHighlights",
        "maturity",
        "sectors",
        "fundraisingInfo",
        "valuation",
        "hasFixedValuation",
        "companiesHouse",
      ],
      allOf: [
        {
          if: {
            properties: {
              fundraisingInfo: {
                type: "string",
                enum: [FundraisingInfo.RAISING, FundraisingInfo.BRIDGE_ROUND],
              },
            },
            required: ["fundraisingInfo"],
          },
          then: {
            required: ["equityOffered", "amountRaised"],
          },
        },
        {
          if: {
            properties: {
              fundraisingInfo: {
                type: "string",
                enum: [FundraisingInfo.RAISING],
              },
            },
            required: ["fundraisingInfo"],
          },
          then: {
            required: ["hasLeadInvestor"],
          },
        },
      ],
    },
    links: {
      type: "object",
      properties: {
        facebook: globalDefinitions.httpsUrl,
        instagram: globalDefinitions.httpsUrl,
        linkedin: globalDefinitions.httpsUrl,
        twitter: globalDefinitions.httpsUrl,
        website: globalDefinitions.httpsUrl,
      },
      required: [],
    },
  },
  required: ["startupData"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator<PreselectionForm>(schema));
