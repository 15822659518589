import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Grid } from "semantic-ui-react";
import { Enrolled } from "../../../../contexts/Session/state";
import { DeliverableDetails } from "./DeliverableDetails";
import { Shortcuts as S } from "../../../../routing";
import { QUERY, Result, Variables } from "../../../../api/tickets/DeliverablesOnTicket";
import { MUTATION as RejectPlanMutation } from "../../../../api/tickets/RejectPlan";
import { Variables as RejectPlanVars } from "../../../../api/tickets/RejectPlan";
import { Result as RejectPlanResult } from "../../../../api/tickets/RejectPlan";
import { MUTATION as AcceptPlanMutation } from "../../../../api/tickets/AcceptTicketPlan";
import { Variables as AcceptPlanVars } from "../../../../api/tickets/AcceptTicketPlan";
import { Result as AcceptPlanResult } from "../../../../api/tickets/AcceptTicketPlan";
import { LoaderWithMargin } from "../../../Loader";
import { ErrorMessages } from "../../ErrorMessages";
import { ApiError, extractErrorMessages, noResultErrorFor } from "../../../../types";
import { PreferencesModal } from "./PreferencesModal";
import { useChainApi, useChainState } from "../../../../contexts/Chain";
import { useSettingsState } from "../../../../contexts/Settings";
import { isSuccessState } from "../../../../contexts/Generic";
import { selectedRole } from "../../../../contexts/Session/helpers";
import { SuccessModal } from "./SuccessModal";
import { TicketPreferencesSchema } from "../../../../schemas/preferences/_types";
import { CommunicationMethods } from "../../../../schemas/preferences/_enums";
import { revertFromChainBigInt } from "../../../../contexts/Chain/helpers";
import { useBreadcrumbApi } from "../../../../contexts/Breadcrumb";
import { Crumb } from "../../../../contexts/Breadcrumb/state";
import { CSSProperties } from "react";
import { TicketState } from "../../../../types/ticket";
import { FeedbackSchema, FeedbackModal } from "./FeedbackModal";
import { PlanFeedbackCard } from "../../../../schemas/deliverablesPlan/PlanFeedbackCard";

const bttnGroupStyle: CSSProperties = { display: "flex", justifyContent: "end" };
const columnWidthStyle: CSSProperties = { maxWidth: "1000px" };

enum Modals {
  None,
  ProvideFeedback,
  Preferences,
  OnProvideFeedbackSuccess,
}

interface Props {
  readonly sessionState: Enrolled;
}

export const PublishedDeliverablesPlan = ({ sessionState }: Props) => {
  // Hooks.
  const history = useHistory();
  const chainState = useChainState();
  const chainApi = useChainApi();
  const settings = useSettingsState();
  const breadcrumbApi = useBreadcrumbApi();
  const ticketId =
    new URLSearchParams(history.location.search).get(S.reviewPlan.queryVarNames.id) || "";

  // Queries and mutations.
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { id: ticketId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const [rejectPlan] = useMutation<RejectPlanResult, RejectPlanVars>(RejectPlanMutation);
  const [acceptPlan] = useMutation<AcceptPlanResult, AcceptPlanVars>(AcceptPlanMutation);

  // State and refs.
  const [activeModal, setActiveModal] = useState(Modals.None);
  const modelRef = useRef<Partial<TicketPreferencesSchema>>({});

  const deliverables = useMemo(() => {
    if (!data?.payload?.planForm?.data) {
      return [];
    }
    return data.payload.planForm.data.deliverables;
  }, [data]);
  const { estimatedCostAsBigInt } = data?.payload?.winningBid?.form?.data.computedFields || {};
  const totalCost = estimatedCostAsBigInt
    ? parseFloat(revertFromChainBigInt(estimatedCostAsBigInt))
    : undefined;
  const isOwner = data?.payload?.owner.id === sessionState.roleId;
  const isWinningBidder = data?.payload?.winningBid?.owner.id === sessionState.roleId;

  useEffect(() => {
    if (!data || !data.payload || !data.payload.ticketForm || !data.payload.winningBid) {
      return;
    }
    const title = data.payload.ticketForm.data.title;
    const search = new URLSearchParams({ [S.ticket.queryVarNames.id]: ticketId }).toString();

    const crumb: Crumb = { path: S.ticket.path, title, search };
    breadcrumbApi.addCrumb(crumb);

    if (isOwner) {
      breadcrumbApi.addBody(
        <>
          <h2>{S.reviewPlan.title}</h2>
          <p>
            Review the delivery plan proposed by {data.payload.winningBid.owner.fullName}. Once
            approved, you'll be able to view progress and receive updates on the schedule defined in
            this plan.
          </p>
        </>
      );
    } else if (isWinningBidder) {
      const bidderTitle = "View delivery plan";
      breadcrumbApi.addCustomTitle(bidderTitle);
      breadcrumbApi.addBody(<h2>{bidderTitle}</h2>);
      const winningBidderLeafCrumb: Crumb = { path: S.reviewPlan.path, title: bidderTitle };
      breadcrumbApi.addCrumb(winningBidderLeafCrumb);
    }

    return () => breadcrumbApi.removeBody();
  }, [data, ticketId, isOwner, isWinningBidder, breadcrumbApi]);

  const onGeneralClose = useCallback(() => setActiveModal(Modals.None), []);

  const onAcceptPlan = useCallback(() => setActiveModal(Modals.Preferences), []);

  const onProvideFeedback = useCallback(() => setActiveModal(Modals.ProvideFeedback), []);

  const onSubmitFeedback = useCallback(
    ({ feedback }: FeedbackSchema) => {
      if (!data || !data.payload) {
        return;
      }
      const ownerId = data.payload.owner.id;
      rejectPlan({ variables: { input: { ticketId, ownerId, feedback } } })
        .then(() => setActiveModal(Modals.OnProvideFeedbackSuccess))
        .catch((e) => {
          toast.error("Something went wrong.");
          console.warn(e);
        });
    },
    [data, ticketId, rejectPlan]
  );

  const onSubmitPreferences = useCallback(
    (model: Partial<TicketPreferencesSchema>) => {
      if (!data?.payload?.winningBid?.id) {
        return toast.error("No winningBid Id");
      } else if (!isSuccessState(settings)) {
        return null;
      }
      setActiveModal(Modals.None);

      const transmittedAt = new Date();
      const chainId = parseInt(settings.result.eth.chain_id, 10);
      const owner =
        selectedRole(sessionState.user.roles, sessionState.roleId).fullName || "Unknown";
      const digest = preferencesDigest(owner, chainId, transmittedAt);

      const { knowledgeAreas, communicationMethodOther } = model;
      const c = model.communicationMethods;

      chainApi
        .sign(chainState, digest)
        .then(async () => {
          const vars: AcceptPlanVars = {
            input: {
              ticketId,
              areasOfKnowledge: knowledgeAreas,
              collaborationPreferenceOther: communicationMethodOther || undefined,
              collaborationPreferenceEmail: !!c?.includes(CommunicationMethods.Email),
              collaborationPreferenceMattermost: !!c?.includes(CommunicationMethods.Mattermost),
              collaborationPreferencePhoneCall: !!c?.includes(CommunicationMethods.PhoneCall),
              collaborationPreferenceVideoCall: !!c?.includes(CommunicationMethods.VideoCall),
              collaborationPreferenceWhatsappOrText: !!c?.includes(CommunicationMethods.Whatsapp),
            },
          };
          return acceptPlan({ variables: vars });
        })
        .then(() => {
          setActiveModal(Modals.None);
          toast.success("Delivery plan accepted.");
        })
        .catch((e: ApiError) => {
          if (e.message.includes("User denied message signature")) {
            return setActiveModal(Modals.Preferences);
          }
          toast.error("Something went wrong.");
          console.warn(e);
        });
    },
    [chainApi, chainState, data, settings, sessionState, ticketId, acceptPlan]
  );

  useEffect(() => {
    const state = data?.payload?.state;
    const redirectUser =
      state &&
      state !== TicketState.Execution &&
      state !== TicketState.Winner_plan_submitted &&
      state !== TicketState.Completed;
    if (redirectUser) {
      const search = new URLSearchParams({ [S.ticket.queryVarNames.id]: ticketId }).toString();
      history.replace(`${S.ticket.path}?${search}`);
    }
  }, [data?.payload?.state, history, ticketId]);

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.payload || !data.payload.planForm) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Ticket"))} />;
  }

  const expertName = data.payload.winningBid?.owner.fullName || "unknow";
  const rightColWidth = 4;
  const leftColWidth = isWinningBidder ? 12 : 16;

  return (
    <Grid>
      <Grid.Column style={columnWidthStyle} width={leftColWidth}>
        <div className="WrapperSection">
          {activeModal === Modals.Preferences && (
            <PreferencesModal
              ref={modelRef}
              expertName={expertName}
              onGeneralClose={onGeneralClose}
              onSubmitPreferences={onSubmitPreferences}
            />
          )}
          {activeModal === Modals.ProvideFeedback && (
            <FeedbackModal
              expertName={expertName}
              onSubmit={onSubmitFeedback}
              onGeneralClose={onGeneralClose}
            />
          )}
          {activeModal === Modals.OnProvideFeedbackSuccess && (
            <SuccessModal expertName={expertName} onGeneralClose={onGeneralClose} />
          )}

          {deliverables.map((d, i) => (
            <DeliverableDetails
              key={`deliverable-${i}`}
              deliverable={d}
              totalCost={totalCost}
              withDivider={i > 0}
            />
          ))}
          <br />
          {isOwner && data.payload.state === TicketState.Winner_plan_submitted && (
            <div style={bttnGroupStyle}>
              <Button basic color="blue" onClick={onProvideFeedback}>
                <b>Provide feedback</b>
              </Button>
              <Button color="blue" onClick={onAcceptPlan}>
                <b>Accept</b>
              </Button>
            </div>
          )}
        </div>
      </Grid.Column>
      {isWinningBidder && (
        <Grid.Column width={rightColWidth}>
          <PlanFeedbackCard ticketId={ticketId} />
        </Grid.Column>
      )}
    </Grid>
  );
};

export const preferencesDigest = (owner: string, chainId: number, signedAt: Date): string =>
  `-- Preferences Digest ${chainId}\n` +
  `  Ticket Owner: ${owner}\n` +
  `  Signed At: ${signedAt.toISOString().split(".")[0] + "Z"}`;
