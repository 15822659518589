import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
// Our stuff.
import * as serviceWorker from "./serviceWorker";
import { createApolloClient } from "./createApolloClient";
import { App } from "./components/App";
import { SessionProvider } from "./contexts/Session/Provider";
import { CategoriesProvider } from "./contexts/Categories/Provider";
import { ChainProvider } from "./contexts/Chain/Provider";
import { BreadcrumbProvider } from "./contexts/Breadcrumb/Provider";
import { SettingsProvider } from "./contexts/Settings/Provider";
import { OverlayProvider } from "./contexts/Overlay/Provider";
import { ConfirmationModalProvider } from "./contexts/ConfirmationModal/Provider";
import ScrollToTop from "./components/elements/ScrollToTop";
import "./styles/index.scss";
import { PreApplicationModal } from "./components/elements/PreApplicationModal";
import { PageContentProvider } from "./contexts/PageContent/Provider";

const client = createApolloClient();
const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ApolloProvider client={client}>
        <SettingsProvider>
          <ChainProvider>
            <SessionProvider>
              <CategoriesProvider>
                <BrowserRouter>
                  <ConfirmationModalProvider>
                    <BreadcrumbProvider>
                      <OverlayProvider>
                        <PageContentProvider>
                          <ScrollToTop>
                            <PreApplicationModal />
                            <App />
                          </ScrollToTop>
                        </PageContentProvider>
                      </OverlayProvider>
                    </BreadcrumbProvider>
                  </ConfirmationModalProvider>
                </BrowserRouter>
              </CategoriesProvider>
            </SessionProvider>
          </ChainProvider>
        </SettingsProvider>
      </ApolloProvider>
    </AppContainer>,
    document.getElementById("root")
  );
};
// Force one render.
render();
// If we are in dev mode with hot reloading, apply it.
if (module.hot) {
  module.hot.accept("./components/App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
