import { Typed } from "../../types";

// Action types.
export enum GenericActionTypes {
  Fetch = "@generic/Fetch",
  SetResult = "@generic/SetResult",
  SetError = "@generic/SetError",
}
// Action instances.
export interface GenericFetch extends Typed<GenericActionTypes.Fetch> {}
export interface GenericSetResult<T> extends Typed<GenericActionTypes.SetResult> {
  readonly result: T;
}
export interface GenericSetError extends Typed<GenericActionTypes.SetError> {
  readonly errors: ReadonlyArray<string>;
}

export type GenericAction<T> = GenericFetch | GenericSetResult<T> | GenericSetError;
