import { DeepPartial } from "uniforms";
import { bridge, OrganisationBaseInviteType } from "./OrganisationBaseInviteSchema";
import { CustomTextField } from "../CustomTextField";
import { EntityKind, EntityType } from "../../types/onboard";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { ContinueButton } from "./ContinueButton";
import { Divider, Grid } from "semantic-ui-react";
import { CustomBoolRadioField } from "../CustomBoolRadioField";
import { readableBusinessCasesLong, readableExpectedToParticipate } from "./_types";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";
import { CountriesList } from "../../utils/CountriesList";
import { CustomLongTextField } from "../CustomLongTextField";
import { CustomRadioField } from "../CustomRadioField";
import { useScrollToTopApi } from "../../components/elements/ScrollToTop";

const defaultModel: DeepPartial<OrganisationBaseInviteType> = {
  type: EntityType.Organisation,
  kind: EntityKind.Provider,
};

interface Props {
  readonly initialModel?: DeepPartial<OrganisationBaseInviteType>;
  readonly onSubmit: (model: OrganisationBaseInviteType) => void;
}

export const OrganisationBaseInviteForm = ({ initialModel, onSubmit }: Props) => {
  const { scrollToTopError } = useScrollToTopApi();

  return (
    <AutoForm
      schema={bridge}
      showInlineError
      onSubmit={onSubmit}
      model={initialModel || defaultModel}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <CustomTextField
              name="recipientEmail"
              label="Email address"
              placeholder="Enter email address"
              errorMessage="This field is mandatory."
              showtextcount="false"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <CustomTextField
              name="name"
              label="Organisation name"
              placeholder="Enter organisation name"
              errorMessage="This field is mandatory."
              showtextcount="false"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <CustomBoolRadioField
              name="expectedToParticipate"
              label="Considering CV's values (listed on the right), do you think this person will participate and engage in the community aspect of CV and perhaps help with committees?"
              transform={readableExpectedToParticipate}
              horizontal
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <CustomDropdownSelectionField
              name="experienceGeography"
              label="Which geographies do they have the most experience in?"
              dropdownOptions={CountriesList}
              placeholder="Search location..."
              errorMessage="This field must have between up to 10 values."
              multiple
              search
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <CustomLongTextField
              name="exampleOfValue"
              label="How have they been helpful/valuable to you or someone you know of?"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <CustomRadioField
              name="businessCase"
              label="Why would the nominee make a great addition to the ecosystem?"
              extraLabel="You'll see additional questions based on your answer here. Updating this answer will reset your responses on the next page."
              transform={readableBusinessCasesLong}
              errorMessage="This field is mandatory."
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ContinueButton onClick={scrollToTopError} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AutoForm>
  );
};
