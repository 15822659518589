import { CSSProperties } from "react";
import { connectField } from "uniforms";
import { ListItemFieldProps } from "uniforms-semantic";

const fullWidth: CSSProperties = { width: "100%" };

export const CustomListItemField = connectField<ListItemFieldProps>(
  ({ children }) => {
    return (
      <div className="item">
        <div className="middle aligned content" style={fullWidth}>
          {children}
        </div>
      </div>
    );
  },
  { initialValue: false }
);
