interface Arguments {
  readonly messageRef: React.MutableRefObject<string | undefined>;
  readonly hasChangesRef: React.MutableRefObject<boolean | undefined>;
  readonly promiseRef: React.MutableRefObject<(() => Promise<unknown>) | undefined>;
}

export class Actioner {
  private readonly args: Arguments;

  constructor(args: Arguments) {
    this.args = args;
  }

  setHasChanges = (hasChanges: boolean) => (this.args.hasChangesRef.current = hasChanges);

  setContinuePromise = (promise: () => Promise<unknown>) =>
    (this.args.promiseRef.current = promise);

  removeContinuePromise = () => () => (this.args.promiseRef.current = undefined);

  addMessage = (message: string) => (this.args.messageRef.current = message);
}
