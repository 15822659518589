import { CSSProperties, useMemo } from "react";
import moment, { now } from "moment";
import { Grid } from "semantic-ui-react";
import { Proposal } from "../../../types/proposal";
import { OnboardState, PublicOnboard } from "../../../types/onboard";
import { getPreselectionClock } from "../StartupDealflow/StartupOnboardCard";
import { PreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";
import { VoteBars } from "../../elements/votes/VoteBars";
import { preselectionTallyToVoteBarData } from "../../elements/votes/utils";

const daysLeftRowStyle: CSSProperties = { display: "flex", margin: "8px 0 24px 0" };
const marginBottomStyle: CSSProperties = { marginBottom: "8px" };
const communitySentimentStyle: CSSProperties = {
  fontWeight: "bold",
  fontSize: "20px",
  lineHeight: "28px",
  color: "#000000",
};

interface Props {
  readonly onboard: PublicOnboard;
  readonly proposal: Proposal<PreselectionProposalChoice>;
}

export const CommunitySentiment = ({ onboard, proposal }: Props) => {
  const daysLeftInPreselection = moment(onboard.preselectionEndsAt).diff(moment(now()), "days");

  const votes = useMemo(() => preselectionTallyToVoteBarData(proposal.tally), [proposal]);

  return (
    <Grid>
      <Grid.Column>
        <Grid.Row>
          <div style={communitySentimentStyle}>Community sentiment</div>
        </Grid.Row>
        {onboard.state === OnboardState.InPreselection && (
          <Grid.Row style={daysLeftRowStyle}>
            {getPreselectionClock(daysLeftInPreselection)}&nbsp;
            <b>
              {daysLeftInPreselection} {daysLeftInPreselection === 1 ? "day left" : "days left"}
            </b>
            &nbsp; in pre-selection
          </Grid.Row>
        )}
        <Grid.Row style={marginBottomStyle}>
          <p>Should {onboard.fullName} proceed to General Assessment?</p>
        </Grid.Row>
        <Grid.Row>
          <VoteBars votes={votes} />
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};
