import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Container, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Shortcuts as S } from "../../../../../routing";
import { TicketState } from "../../../../../types/ticket";
import { TicketBidState, TickettingBid } from "../../../../../types/bid";
import { MUTATION, Variables, Result } from "../../../../../api/tickets/RevertTicketToDraft";
import { MUTATION as unfollowMutation } from "../../../../../api/followers/unfollow";
import { Result as unfollowRes } from "../../../../../api/followers/unfollow";
import { Variables as unfollowVars } from "../../../../../api/followers/unfollow";
import { MUTATION as followMutation } from "../../../../../api/followers/follow";
import { Result as followRes } from "../../../../../api/followers/follow";
import { Variables as followVars } from "../../../../../api/followers/follow";
import { ApiError, isDefined } from "../../../../../types";
import { isSuccessState } from "../../../../../contexts/Generic";
import { useChainApi, useChainState } from "../../../../../contexts/Chain";
import { useSettingsState } from "../../../../../contexts/Settings";
import { Ticket } from "../../../../../api/tickets/GetTicketDetailsInfo";
import { useSealFieldsQuery } from "../../../../../hooks/useSealFieldsQuery";
import { QUERY, ticketEnvelope, SealQueryResult } from "../../../../../api/seals/TicketSeal";
import { digestFormData } from "../../../../../types/OnboardForm";

const bttnWrapperStyle: CSSProperties = { display: "flex", justifyContent: "space-between" };
const noMarginRightStyle: CSSProperties = { marginRight: 0 };

interface Props {
  readonly isOwner: boolean;
  readonly ticket: Ticket;
  readonly ticketTitle: string;
  readonly myBid?: TickettingBid;
}

export const ActionButtons = (props: Props) => {
  const { isOwner, ticket, myBid, ticketTitle } = props;
  const { state, followedBy, id: ticketId, ticketForm, submittedBidBy, seal } = ticket;
  // Hooks.
  const chainState = useChainState();
  const chainApi = useChainApi();
  const settings = useSettingsState();
  const history = useHistory();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  // State.
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [followCD, setFollowCD] = useState<boolean>(false);
  // Mutations.
  const [revertToDraft] = useMutation<Result, Variables>(MUTATION);
  const [follow] = useMutation<followRes, followVars>(followMutation);
  const [unfollow] = useMutation<unfollowRes, unfollowVars>(unfollowMutation);

  const handleBack = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);
  const canUnpublish = useMemo(
    () => isOwner && (state === TicketState.In_bidding || state === TicketState.Winner_selection),
    [isOwner, state]
  );

  useEffect(() => {
    if (followCD) {
      setTimeout(() => {
        setFollowCD(false);
      }, 600);
    }
  }, [followCD]);

  const handleFollow = useCallback(() => {
    if (followCD) {
      return;
    }

    setFollowCD(true);
    let mutation;
    if (followedBy) {
      mutation = unfollow({ variables: { input: { followableId: ticketId } } });
    } else {
      mutation = follow({ variables: { input: { followableId: ticketId } } });
    }

    mutation
      .then(() => {
        const msg = `You have ${followedBy ? "unfollowed" : "followed"} the ticket ` + ticketTitle;
        toast.success(msg);
      })
      .catch((e) => {
        toast.error("Something went wrong.");
        console.warn(e);
      });
  }, [follow, unfollow, followedBy, followCD, ticketId, ticketTitle]);

  const onUnpublish = useCallback(async () => {
    if (!isSuccessState(settings) || ticketForm === undefined) {
      return null;
    }
    setOpen(false);
    const transmittedAt = new Date();
    const chainId = parseInt(settings.result.eth.chain_id, 10);

    setLoading(true);
    const sealData = await sealFieldsQuery(QUERY, { id: ticketId });
    setLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const { fields: f } = sealData;
    const envelope = ticketEnvelope(chainId, f, transmittedAt, "unpublish", seal?.signature);
    const text = digestFormData(envelope, true);

    chainApi
      .sign(chainState, text)
      .then((ethSignature) =>
        revertToDraft({
          variables: { input: { ethSignature, ticketId, transmittedAt } },
        })
      )
      .then(() => {
        toast.success("You have succesfuly unpublished the ticket.");
        history.push({ pathname: S.mySprint.path });
      })
      .catch((e: ApiError) => {
        if (e.message.includes("User denied message signature")) {
          setOpen(true);
          return;
        }
        toast.error("Something went wrong.");
        console.warn(e);
      });
  }, [
    history,
    ticketId,
    chainApi,
    chainState,
    settings,
    ticketForm,
    seal,
    sealFieldsQuery,
    revertToDraft,
  ]);

  const bidButton = useMemo(() => {
    let bidBttnText;
    let onBidBttnClick = () => {
      const s = new URLSearchParams({ [S.submitBid.queryVarNames.id]: ticketId });
      history.push({ pathname: S.submitBid.path, search: s.toString() });
    };

    if (!myBid) {
      bidBttnText = "Submit bid";
    } else {
      if (myBid.state === TicketBidState.Submitted || myBid.state === TicketBidState.Closed) {
        bidBttnText = "View my bid";
        onBidBttnClick = () => {
          const s = new URLSearchParams({ [S.bid.queryVarNames.id]: myBid.id });
          history.push({ pathname: S.bid.path, search: s.toString() });
        };
      } else {
        bidBttnText = "Edit bid";
      }
    }

    return (
      <Button fluid primary style={noMarginRightStyle} onClick={onBidBttnClick}>
        {bidBttnText}
      </Button>
    );
  }, [myBid, history, ticketId]);

  return (
    <>
      {canUnpublish && (
        <Modal
          className="CustomClose"
          size="tiny"
          open={open}
          onClose={handleBack}
          onOpen={handleOpen}
          closeOnDimmerClick={false}
          closeIcon
          trigger={
            <Button primary className="ActionBttn-widder" loading={loading}>
              <b>Unpublish</b>
            </Button>
          }
        >
          <Modal.Header>Before you continue...</Modal.Header>
          <Modal.Content>
            Are you sure you want to unpublish this ticket? <br />
            <br />
            By doing so, your ticket will no longer be visible on the Marketplace and will not be
            able to receive new bids. You have the option to republish your ticket once it's been
            unpublished.
            <br />
            <br />
            <Container>
              <Button onClick={handleBack}>Back</Button>
              <Button primary onClick={onUnpublish} floated="right">
                Yes
              </Button>
            </Container>
          </Modal.Content>
        </Modal>
      )}

      {!isOwner && (state === TicketState.In_bidding || !isDefined(submittedBidBy)) && (
        <div style={bttnWrapperStyle}>
          {!isDefined(submittedBidBy) && (
            <Button basic fluid color="teal" onClick={handleFollow} disabled={followCD}>
              {followedBy ? <b>Unfollow</b> : <b>Follow</b>}
            </Button>
          )}
          {state === TicketState.In_bidding && bidButton}
        </div>
      )}
    </>
  );
};
