import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { EntityKind, EntityType } from "../../types/onboard";
import { OnboardState, OnboardStatus, PublicOnboardViewedByMember } from "../../types/onboard";
import { PUBLIC_ONBOARD_VIEWED_BY_MEMBER_CONNECTION_FRAGMENT } from "../_fragments/PublicOnboardViewedByMember";

export const QUERY = gql`
  query ViewAllOnboards(
    $roleId: ID!
    $seconderId: ID
    $after: String
    $before: String
    $first: Int
    $last: Int
    $state: [OnboardState!]
    $status: [OnboardStatus!]
    $type: [EntityType!]
    $kind: [EntityKind!]
  ) {
    onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: $state
      seconderId: $seconderId
      status: $status
      type: $type
      kind: $kind
      sortBy: { direction: DESC, field: SUBMITTED_AT }
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
  }
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_CONNECTION_FRAGMENT}
`;

export interface Variables extends ConnectionVariables {
  readonly roleId: string;
  readonly seconderId?: string;
  readonly state?: ReadonlyArray<OnboardState>;
  readonly status?: ReadonlyArray<OnboardStatus>;
  readonly type?: ReadonlyArray<EntityType>;
  readonly kind?: ReadonlyArray<EntityKind>;
}

export interface Result {
  readonly onboards?: Connection<PublicOnboardViewedByMember>;
}
