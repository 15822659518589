import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketPublishType } from "../../types/ticket";

export const MUTATION = gql`
  mutation PublishDraftTicket($input: PublishDraftTicketInput!) {
    payload: publishDraftTicket(input: $input) {
      ticket {
        id
      }
    }
  }
`;

export interface Variables {
  readonly input: PublishDraftTicketInput;
}

interface PublishDraftTicketInput {
  readonly draftId: string;
  readonly ethSignature: string;
  readonly ticketPublishType: TicketPublishType;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: PublishDraftTicketPayload;
}

interface PublishDraftTicketPayload {
  readonly ticket: Identifiable;
}
