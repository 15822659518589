import { gql } from "@apollo/client";
import { SENT_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { SentOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation CancelOnboard($input: CancelOnboardInput!) {
    payload: cancelOnboard(input: $input) {
      onboard {
        ...SentOnboard
      }
    }
  }
  ${SENT_ONBOARD_FRAGMENT}
`;

interface CancelOnboardInput {
  readonly onboardId: string;
}
export interface Variables {
  readonly input: CancelOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: SentOnboard };
}
