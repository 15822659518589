import { gql } from "@apollo/client";
import { TicketSchema } from "../../schemas/tickets/_types";
import { TickettingDraft } from "../../types/ticket";

export const MUTATION = gql`
  mutation AmendDraftTicket($input: AmendDraftTicketInput!) {
    payload: amendDraftTicket(input: $input) {
      draftTicket {
        id
        updatedAt
        insertedAt
        form {
          id
          data
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: AmendDraftTicketInput;
}

interface AmendDraftTicketInput {
  readonly draftId: string;
  readonly form: string;
}

export interface Result {
  readonly payload?: AmendDraftTicketPayload;
}

interface AmendDraftTicketPayload {
  readonly draftTicket: TickettingDraft<TicketSchema>;
}
