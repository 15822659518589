import { Typed } from "../../types";
import { Crumb } from "./state";

// Action types.
export enum ActionTypes {
  AddHeader = "@breadcrumbs/AddHeader",
  RemoveHeader = "@breadcrumbs/RemoveHeader",
  AddBody = "@breadcrumbs/AddBody",
  RemoveBody = "@breadcrumbs/RemoveBody",
  AddFooter = "@breadcrumbs/AddFooter",
  RemoveFooter = "@breadcrumbs/RemoveFooter",
  AddCustomTitle = "@breadcrumbs/AddCustomTitle",
  RemoveCustomTitle = "@breadcrumbs/RemoveCustomTitle",
  AddCrumb = "@breadcrumbs/AddCrumb",
  AddCrumbsExtra = "@breadcrumbs/AddCrumbExtra",
  RemoveCrumbsExtra = "@breadcrumbs/RemoveCrumbExtra",
  ToggleCrumb = "@breadcrumbs/ToggleCrumb",
}

// Action instances.
export interface AddHeader extends Typed<ActionTypes.AddHeader> {
  readonly header: JSX.Element;
}
export interface RemoveHeader extends Typed<ActionTypes.RemoveHeader> {}
export interface AddBody extends Typed<ActionTypes.AddBody> {
  readonly body: JSX.Element;
}
export interface RemoveBody extends Typed<ActionTypes.RemoveBody> {}
export interface AddFooter extends Typed<ActionTypes.AddFooter> {
  readonly footer: JSX.Element;
}
export interface RemoveFooter extends Typed<ActionTypes.RemoveFooter> {}
export interface AddCustomTitle extends Typed<ActionTypes.AddCustomTitle> {
  readonly customTitle: string;
}
export interface RemoveCustomTitle extends Typed<ActionTypes.RemoveCustomTitle> {}
export interface AddCrumb extends Typed<ActionTypes.AddCrumb> {
  readonly crumb: Crumb;
}
export interface AddCrumbsExtra extends Typed<ActionTypes.AddCrumbsExtra> {
  readonly extra: JSX.Element;
}

export interface RemoveCrumbsExtra extends Typed<ActionTypes.RemoveCrumbsExtra> {}
export interface ToggleCrumb extends Typed<ActionTypes.ToggleCrumb> {
  readonly showBreadcrumbs: boolean;
}

export type Action =
  | AddHeader
  | RemoveHeader
  | AddBody
  | RemoveBody
  | AddFooter
  | RemoveFooter
  | AddCustomTitle
  | RemoveCustomTitle
  | AddCrumb
  | AddCrumbsExtra
  | RemoveCrumbsExtra
  | ToggleCrumb;
