import { providers } from "ethersv5";
import { Typed } from "../../types";
import { ChainStateError } from "./state";

// Action types.
export enum ActionTypes {
  SetBusy = "@chain/SetBusy",
  SetLastError = "@chain/SetLastError",
  SetConnected = "@chain/SetConnected",
  SetAuthorized = "@chain/SetAuthorized",
  SetFishy = "@chain/SetFishy",
  SetLinked = "@chain/SetLinked",
  Clear = "@chain/Clear",
}
// Action instances.
export interface SetBusy extends Typed<ActionTypes.SetBusy> {
  readonly flag: boolean;
}
export interface SetLastError extends Typed<ActionTypes.SetLastError> {
  readonly lastError: ChainStateError;
}
export interface SetConnected extends Typed<ActionTypes.SetConnected> {
  readonly chainId: number;
  readonly provider: providers.Web3Provider;
  readonly signer: providers.JsonRpcSigner;
}
export interface SetAuthorized extends Typed<ActionTypes.SetAuthorized> {}
export interface SetFishy extends Typed<ActionTypes.SetFishy> {
  readonly account: string;
}
export interface SetLinked extends Typed<ActionTypes.SetLinked> {
  readonly account: string;
}
export interface Clear extends Typed<ActionTypes.Clear> {
  readonly lastError: ChainStateError;
}

export type Action =
  | SetBusy
  | SetLastError
  | SetConnected
  | SetAuthorized
  | SetFishy
  | SetLinked
  | Clear;
