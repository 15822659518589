import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { Grid } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Context } from "uniforms";
import { ballotEnvelope, SealQueryResult } from "../../api/seals/BallotSeal";
import { AdminExpertOnboard } from "../../api/admin/AdminExpertOnboard";
import { QUERY as BallotSealQuery } from "../../api/seals/BallotSeal";
import { QUERY } from "./../../api/onboards/PreselectionOnboard";
import { BallotSealFields } from "../../api/seals/_fragments/BallotSealFields";
import { MUTATION, Variables, Result } from "./../../api/proposals/CBCMemberVote";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { digestFormData } from "../../types/OnboardForm";
import { Maybe } from "../../types";
import { readableCBCExpertVoteChoice, sanitizeCBCExpertVoteData } from "./_types";
import { CBCExpertVoteChoice, CBCExpertVoteSchema } from "./_types";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { AjvError } from "../../utils/Ajv";
import { Enrolled } from "../../contexts/Session/state";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { Migrated } from "../../migrations/_helpers";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { useScrollToTopApi } from "../../components/elements/ScrollToTop";
import { CustomLongTextField } from "../CustomLongTextField";
import { CustomHorizontalRadioRaterField } from "../CustomHorizontalRadioRaterField";
import { bridge } from "./CBCExpertVoteSchema";
import { DisplayIf } from "../DisplayIf";
import { CBCMemberExpertOnboard } from "../../api/onboards/CBCMemberExpertOnboard";

interface Props {
  readonly onboard: Migrated<AdminExpertOnboard | CBCMemberExpertOnboard>;
  readonly sessionState: Enrolled;
}

export const CBCExpertVoteForm = ({ onboard, sessionState }: Props) => {
  const proposalId = onboard.committeeProposal?.id;
  const { scrollToTopError } = useScrollToTopApi();
  const chainApi = useChainApi();
  const chainState = useChainState();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const [vote, { loading }] = useMutation<Result, Variables>(MUTATION);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);

  const isLoading = sealFieldsLoading || loading;

  const onValidate = useCallback((_: CBCExpertVoteSchema, e: Maybe<AjvError>) => {
    console.warn(e);
    return e;
  }, []);

  const onSubmit = useCallback(
    async (model: CBCExpertVoteSchema) => {
      if (!proposalId) {
        return toast.error("No proposal was found");
      }
      setSealFieldsLoading(true);
      const sealData = await sealFieldsQuery(BallotSealQuery, { id: proposalId });
      setSealFieldsLoading(false);

      if (!sealData) {
        return toast.error("Failed to fetch the data for the signature.");
      }

      const transmittedAt = new Date();

      const { chainId } = sessionState;
      const sanitisedModel = sanitizeCBCExpertVoteData(model);
      const form = { data: sanitisedModel };
      const tAt = transmittedAt.toLocaleString();

      const fields: BallotSealFields = { form, transmittedAt: tAt, proposal: sealData.fields };

      const envelope = ballotEnvelope(chainId, fields, transmittedAt);
      const plainText = digestFormData(envelope, true);

      chainApi
        .sign(chainState, plainText)
        .then(async (ethSignature) => {
          const f = JSON.stringify(sanitisedModel);
          await vote({
            variables: { input: { ethSignature, proposalId, transmittedAt, form: f } },
            awaitRefetchQueries: true,
            refetchQueries: [
              { query: QUERY, variables: { onboardId: onboard.id, roleId: sessionState.roleId } },
            ],
          });
          toast.success("Thank you for voting on this expert.");
        })
        .catch((e: Error) => {
          // Discard error message thrown when the user clicks cancel on the MM popup.
          if (e.message.includes("User denied message signature")) {
            return;
          }
          toast.error(`Something went wrong. ${e.message}`);
        });
    },
    [onboard, sessionState, chainApi, chainState, proposalId, sealFieldsQuery, vote]
  );

  const forDiscussionCondition = useCallback(
    (c: Context<CBCExpertVoteSchema>) => c.model.voteChoice === CBCExpertVoteChoice.ForDiscussion,
    []
  );

  return (
    <Grid className="ExpertDetailsProfileTab">
      <Grid.Column computer={12} tablet={16}>
        <div className="WrapperSection">
          <div className="CBCExpertVoteForm">
            <div className="CBCExpertVoteForm-title">
              Do you think this Expert should be part of the Community?
            </div>
            <div className="CBCExpertVoteForm-description">
              Please cast your vote based on the nominee’s appplication details and Seconder
              evaluations. If further discussion is needed to help reach an outcome for this
              nominee, a CG Manager will reach out via Mattermost.
            </div>
            <AutoForm schema={bridge} showInlineError onValidate={onValidate} onSubmit={onSubmit}>
              <CustomHorizontalRadioRaterField
                name="voteChoice"
                label=""
                isFlat
                transform={readableCBCExpertVoteChoice}
              />
              <DisplayIf condition={forDiscussionCondition}>
                <CustomLongTextField
                  name="reasonForDiscussion"
                  label="Why do you think that is for discussion?"
                  errorMessage="This field is mandatory."
                  required
                />
              </DisplayIf>
              <br />
              <SignatureButton
                content="Sign and Vote"
                labelPosition="right"
                onClick={scrollToTopError}
                loading={isLoading}
              />
            </AutoForm>
          </div>
        </div>
      </Grid.Column>
    </Grid>
  );
};
