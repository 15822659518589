import { Segment, Header, Image } from "semantic-ui-react";
import { useSessionState } from "../../../../../../contexts/Session";
import { ReceivedOnboard, isStartupOnboard, OnboardStatus } from "../../../../../../types/onboard";
import { appNeedsMattermostWall } from "../../../../../elements/walls/session";
import { MattermostWall } from "../../../../../walls/MattermostWall";
import whiteSmileyFace from "../../../../../../assets/white-smiley-face.svg";
import { EthereumWall } from "../../../../../walls/EthereumWall";
import { needsChainWall, useChainState } from "../../../../../../contexts/Chain";
import { isLinked } from "../../../../../../contexts/Chain/state";

interface Props {
  readonly onboard: ReceivedOnboard;
  readonly isActor: boolean;
}
export const Closed = ({ onboard, isActor }: Props) => {
  const chainState = useChainState();
  const sessionState = useSessionState();
  const accepted = onboard.status === OnboardStatus.Finalised;
  const declined = onboard.status === OnboardStatus.Declined;

  if (appNeedsMattermostWall(sessionState)) {
    return <MattermostWall />;
  } else if (needsChainWall(chainState) || !isLinked(chainState)) {
    return <EthereumWall />;
  } else if (!accepted) {
    const body = isStartupOnboard(onboard) ? (
      <p>
        We are sorry to say that your startup application didn't pass the review process. You will
        not be able to move forward to the evaluation process. You can still participate in the
        deals selection process for other startups and individuals.
      </p>
    ) : (
      <p>You have not been accepted as an investor/expert to the ecosystem.</p>
    );

    return (
      <Segment>
        <Header as="h2">{declined ? "You declined this invite" : "You didn't make it"}</Header>
        {!declined && body}
      </Segment>
    );
  }

  return (
    <div className="OnboardWelcome">
      <div className="OnboardWelcome-body">
        <Image className="OnboardWelcome-body-icon" src={whiteSmileyFace} />

        <div className="OnboardWelcome-body-title">
          Welcome {onboard.fullName}!
          <p>Congratulations on becoming a member of Consilience Ventures</p>
        </div>

        <p className="OnboardWelcome-body-paragraph">
          {!isActor && (
            <>
              We'll need to manually validate your identity on our blockchain before you can access
              the rest of the platform. This shouldn't take more than 1-2 days, but if it does,
              please contact help@consilienceventures.com
            </>
          )}
          {isActor && (
            <>
              Your blockchain identity has been validated. You have an identity on our blockchain
              which allows you to vote on pre-selection applications, publish tickets, send and
              receive CVDS and other sensitive activities within the platform.
            </>
          )}
        </p>
      </div>
      {isActor && (
        <div className="OnboardWelcome-tips">
          <span className="OnboardWelcome-tips-title">Some tips</span>
          <ul>
            <li>
              You can invite new members and start-ups you think would make a great addition to the
              network by visiting My Area in the top right.
            </li>
            <li>
              Help other members find you more easily by editing your profile informtion. You can do
              this by visiting My Area in the top right.
            </li>
            <li>
              The initial evaluation of startup applicants is community-driven. We encourage you to
              provide your feedback by visiting the Pre-selection left menu entry. Your votes and
              comments help inform our Review Committees when prioritising deals for further due
              diligence during General Assessment.
            </li>
            <li>
              We encourage you to get involved in our startup-specific Review Committees, which
              carry out in-depth due diligence on start-up applicants in General Assessment. To view
              opportunities to get involved, filter for applications that are `In General
              Assessment` in the Pre-selection left menu entry.
            </li>
            <li>
              We hold members-only townhall meetings every 8 - 12 weeks with an update on the
              network, portfolio, and other updates. Watch out for announcements on MatterMost and
              in our newsletters.
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
