import { gql } from "@apollo/client";
import { SESSION_USER_FRAGMENT } from "./User";

export const SESSION_FRAGMENT = gql`
  fragment Session on Session {
    id
    bearerToken
    user {
      ...SessionUser
    }
  }
  ${SESSION_USER_FRAGMENT}
`;
