import { useState, ChangeEvent, useMemo } from "react";
import { Form, Label, Button, Message, Segment, Grid } from "semantic-ui-react";
import { ContractReceipt } from "ethersv5";
import { Role } from "../../../../types/role";
import { Queryable, setBusy } from "../../../../types";
import { isNumeric } from "../../../../types";
import { revertDecimate, decimateTokenValue } from "../../../../contexts/Chain/helpers";
import { utils } from "../../../../utils/utils";
import { Linked } from "../../../../contexts/Chain/state";
import { FixedPointAmount } from "../../../../utils/FixedPointAmount";
import { AddressBookFormDropdown } from "../../../elements/AddressBookFormDropdown";
import { useCancelablePromises } from "../../../../hooks/useCancelablePromise";
import { Enrolled } from "../../../../contexts/Session/state";

enum FormIds {
  Amount = "@Wallet/Amount",
  From = "@Wallet/From",
  Reference = "@Wallet/Reference",
  Recipient = "@Wallet/Recipient",
}

interface State extends Queryable {
  readonly decimals: number;
  readonly amount: string;
  readonly selectedRole?: Role;
  readonly balance?: bigint;
  readonly lastReceipt?: ContractReceipt;
  readonly allowance?: bigint;
  readonly reference?: string;
}

const refereceMaxLenght: number = 24;
const initialAmount = "0.000000";
const initialState: State = { decimals: 6, amount: initialAmount };
const defaults = { busy: false, errors: undefined, lastReceipt: undefined };

interface Props {
  readonly ethAddress: string;
  readonly chainState: Linked;
  readonly sessionState: Enrolled;
}

export const Transfer = ({ ethAddress, chainState, sessionState }: Props) => {
  const { makeCancelable } = useCancelablePromises([ethAddress]);
  const [state, setState] = useState(initialState);
  const { decimals, balance, allowance, amount, selectedRole, lastReceipt, errors, busy } = state;
  const { reference } = state;

  const {
    account: meAddress,
    contracts: { token },
  } = useMemo(() => chainState, [chainState]);

  const isFromMe = meAddress.toString() === ethAddress;
  const title = `Transfer${
    !isFromMe ? ` (Allowance of ${decimateTokenValue(allowance, decimals, "N/A")})` : ""
  }`;

  useMemo(async () => {
    // Get all numbers in one go, and convert them to proper types.
    const [d, b, a] = await Promise.all([
      token.decimals(),
      token.balanceOf(ethAddress),
      token.allowance(ethAddress, meAddress.toString()),
    ]);

    setState((s) => ({
      ...s,
      ...defaults,
      decimals: d,
      balance: b.toBigInt(),
      allowance: a.toBigInt(),
    }));
  }, [ethAddress, meAddress, token]);

  const decimatedBalance = decimateTokenValue(balance, decimals, "N/A");

  const onReferenceChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (value === "") {
      return setState((s) => ({ ...s, reference: undefined }));
    } else if (value.length > refereceMaxLenght) {
      return;
    } else {
      return setState((s) => ({ ...s, reference: value }));
    }
  };

  const onAmountChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (!balance || (value !== "" && !isNumeric(value))) {
      return;
    } else if (revertDecimate(value, decimals, 0) <= balance) {
      setState((s) => ({ ...s, ...defaults, amount: value }));
    }
  };

  const onBlur = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (!balance || value === "" || (value !== "" && !isNumeric(value))) {
      return;
    } else {
      const d = decimals;
      const formatedAmount = FixedPointAmount.fromGuaranteedFractional(value, d).toFractional(d, d);
      setState((s) => ({ ...s, ...defaults, amount: formatedAmount }));
    }
  };

  const onSelectedRoleChange = (role?: Role) => setState((s) => ({ ...s, selectedRole: role }));

  const maxLiquidFunds = () => {
    const a = decimateTokenValue(isFromMe ? balance : allowance, decimals, "");
    setState((s) => ({ ...s, ...defaults, amount: a }));
  };

  const amountIsZeroOrLess =
    !amount || BigInt(revertDecimate(amount, decimals, "N/A")) <= BigInt(0);

  const initiateTransfer = () => {
    if (!selectedRole || amountIsZeroOrLess) {
      return;
    } else if (!selectedRole.ethAddress) {
      throw new Error("Impossible state: Role object without an ethAddress.");
    }

    setState(setBusy());

    const recipient = selectedRole.ethAddress;
    const amountString = revertDecimate(amount, decimals, "N/A").toString();
    const method = isFromMe
      ? () => token.transferWithReference(recipient, amountString, reference || "")
      : () => token.transferFromWithReference(ethAddress, recipient, amountString, reference || "");

    makeCancelable(method())
      .then((res) => makeCancelable(res.wait()))
      .then((r) => {
        setState((s) => ({
          ...s,
          amount: initialAmount,
          reference: undefined,
          busy: false,
          errors: undefined,
          lastReceipt: r,
        }));
      })
      .catch((e: Error) => {
        // Ignore the error thrown when the user rejects the transaction.
        if (e.message.includes("User denied transaction signature")) {
          return;
        }
        // We don't display the errors because they come directly from MM and aren't user friendly,
        // so we just set `errors` to an empty array to display the "We had problems" message.
        setState((s) => ({ ...s, errors: [] }));
        console.error(e);
      })
      .finally(() => setState((s) => ({ ...s, busy: false })));
  };

  if (!isFromMe && (!allowance || allowance === BigInt(0))) {
    return null;
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <Segment>
          <h2>{title}</h2>
          <Form error success warning onSubmit={initiateTransfer} className="Wallet-transfer">
            <AddressBookFormDropdown
              id={FormIds.From}
              className="Wallet-transfer-field"
              selection
              label="From:"
              defaultSearch={ethAddress}
              viewOnly={true}
              onSelectedRoleChange={utils.identity}
              sessionState={sessionState}
            />
            <AddressBookFormDropdown
              id={FormIds.Recipient}
              className="Wallet-transfer-field"
              selection
              label="Recipient:"
              clearable
              placeholder={"Search user"}
              onSelectedRoleChange={onSelectedRoleChange}
              sessionState={sessionState}
            />
            <Form.Input
              id={FormIds.Reference}
              className="Wallet-transfer-field"
              type="text"
              label="Reference:"
              value={reference || ""}
              onChange={onReferenceChange}
            />
            <Form.Input
              id={FormIds.Amount}
              className="Wallet-transfer-field"
              type="text"
              label="Amount:"
              labelPosition="right"
            >
              <input
                type="number"
                min="0"
                max={decimatedBalance}
                step="any"
                value={amount || ""}
                onChange={onAmountChange}
                onBlur={onBlur}
              />
              <Label as={Button} onClick={maxLiquidFunds} type="button">
                MAX
              </Label>
            </Form.Input>
            {busy && (
              <Message
                warning
                header="Check Metamask for any pending notifications!"
                content="After you confirm this transaction it might take up to 15 seconds to process it, please don't refresh the
            page."
              />
            )}
            {errors && <Message error header="We had problems processing your transfer." />}
            {lastReceipt && (
              <Message
                success
                header="Your transfer was successful!"
                content={`It was mined in block ${lastReceipt.blockNumber}`}
              />
            )}
            <Button
              fluid
              primary
              type="submit"
              loading={busy}
              disabled={busy || amountIsZeroOrLess || !selectedRole}
              content="Initiate Transfer"
            />
          </Form>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};
