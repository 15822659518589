import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { EntityKind, EntityType } from "../../types/onboard";
import { OnboardStatus, PublicOnboardViewedByMember } from "../../types/onboard";
import { PUBLIC_ONBOARD_VIEWED_BY_MEMBER_CONNECTION_FRAGMENT } from "../_fragments/PublicOnboardViewedByMember";

export const QUERY = gql`
  query StartupDealflow(
    $roleId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $type: [EntityType!]
    $kind: [EntityKind!]
  ) {
    beingReviewed: onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [AWAITING_ENABLE_VOTING]
      type: $type
      kind: $kind
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
    preSelection: onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [IN_PRESELECTION, TIMED_OUT]
      type: $type
      kind: $kind
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
    generalAssessment: onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [UNDER_REVIEW]
      type: $type
      kind: $kind
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
    unsuccessful: onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [CLOSED]
      status: [REJECTED]
      type: $type
      kind: $kind
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
  }
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_CONNECTION_FRAGMENT}
`;

export interface Variables extends ConnectionVariables {
  readonly roleId: string;
  readonly status?: ReadonlyArray<OnboardStatus>;
  readonly type?: ReadonlyArray<EntityType>;
  readonly kind?: ReadonlyArray<EntityKind>;
}

export interface Result {
  readonly beingReviewed?: Connection<PublicOnboardViewedByMember>;
  readonly preSelection?: Connection<PublicOnboardViewedByMember>;
  readonly generalAssessment?: Connection<PublicOnboardViewedByMember>;
  readonly unsuccessful?: Connection<PublicOnboardViewedByMember>;
}
