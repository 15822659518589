import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { ExpertTagsData } from "./_types";
import { globalDefinitions } from "../_definitions";

export const schema: JSONSchemaType<ExpertTagsData> = {
  type: "object",
  properties: {
    maturities: {
      type: "array",
      items: globalDefinitions.maturity,
      minItems: 1,
    },
    sectors: {
      type: "array",
      items: globalDefinitions.sector,
      minItems: 1,
    },
    occupations: {
      type: "array",
      items: globalDefinitions.occupation,
      minItems: 1,
    },
    skills: {
      type: "array",
      items: globalDefinitions.skills,
      minItems: 1,
      maxItems: 30,
    },
  },
  required: ["maturities", "occupations", "sectors", "skills"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
