import { Segment } from "semantic-ui-react";
import { SentOnboard } from "../../../types/onboard";
import { SentInviteCard } from "./SentInviteCard";

interface Props {
  readonly onboards: ReadonlyArray<SentOnboard>;
}

export const SentInvites = ({ onboards }: Props) => {
  if (onboards && onboards.length <= 0) {
    return <Segment>You did not invite anyone yet to the platform!</Segment>;
  }
  return (
    <>
      {onboards.map((o) => (
        <SentInviteCard onboard={o} key={o.id} />
      ))}
    </>
  );
};
