import { useState } from "react";
import { isEqual } from "lodash";
import classNames from "classnames";
import { connectField, HTMLFieldProps } from "uniforms";
import { ArrayErrorsField } from "../../ArrayErrorsField";
import { NestedRoles } from "../../../components/NestedRoles";

interface Props extends HTMLFieldProps<string[], HTMLElement> {}
export const Visibility = connectField((props: Props) => {
  const { value, label, className, disabled, required, onChange } = props;
  const { error, errorMessage, showInlineError } = props;
  const [memoizedValue, setMemoizedValue] = useState(value);

  if (!isEqual(memoizedValue, value)) {
    setMemoizedValue(value);
  }

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <NestedRoles
        placeholder="Search..."
        collapsedText="Members"
        checked={memoizedValue}
        onFilter={onChange}
      />

      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage?.toString()}</div>
      )}
      <ArrayErrorsField name="" />
    </div>
  );
});
