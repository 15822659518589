import { JSONSchemaType } from "ajv";
import { FormDocument } from "./_types";

// Types extracted from ajv to help reference external schemas.
interface KnownRecord extends Record<string, Known> {}
export declare type Known =
  | KnownRecord
  | [Known, ...Known[]]
  | Known[]
  | number
  | string
  | boolean
  | null;

interface GlobalDefinitions {
  readonly nonEmptyString: JSONSchemaType<string>;
  readonly ethAddress: JSONSchemaType<string>;
  readonly httpsUrl: JSONSchemaType<string>;
  readonly email: JSONSchemaType<string>;
  readonly uuid: JSONSchemaType<string>;
  readonly document: JSONSchemaType<FormDocument>;
  readonly occupation: JSONSchemaType<string>;
  readonly sector: JSONSchemaType<string>;
  readonly maturity: JSONSchemaType<string>;
  readonly skills: JSONSchemaType<string>;
  readonly countries: JSONSchemaType<string>;
}

export const globalDefinitions: GlobalDefinitions = {
  nonEmptyString: {
    type: "string",
    pattern: "(.|s)*\\S(.|s)*",
  },
  ethAddress: { type: "string", pattern: "^0x[a-fA-F0-9]{40}$" },
  httpsUrl: {
    type: "string",
    pattern: "^https:\\/\\/[^s/$.?#].[^s]*",
    maxLength: 300,
  },
  email: {
    type: "string",
    pattern: "^[\\w.+-]+@[\\w.+-]+\\.[a-zA-Z]+$",
  },
  uuid: {
    type: "string",
    pattern: "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}",
  },
  document: {
    type: "object",
    description: "#/definitions/document",
    properties: {
      id: {
        type: "string",
      },
      hash: {
        type: "string",
      },
      filename: { type: "string" },
    },
    required: ["id", "hash", "filename"],
  },
  maturity: {
    type: "string",
    enum: ["10", "20", "30"],
  },
  occupation: {
    type: "string",
    enum: [
      "01",
      "0101",
      "010101",
      "010102",
      "010103",
      "010104",
      "010105",
      "010106",
      "010107",
      "010108",
      "010109",
      "010110",
      "0102",
      "010201",
      "010202",
      "010203",
      "0103",
      "010301",
      "010302",
      "010303",
      "010304",
      "010305",
      "010306",
      "010307",
      "010308",
      "0104",
      "010401",
      "010402",
      "010403",
      "010404",
      "0105",
      "010501",
      "010502",
      "010503",
      "02",
      "0201",
      "0202",
      "0203",
      "03",
      "0301",
      "0302",
      "0303",
      "0304",
      "0305",
      "0306",
      "0307",
      "04",
      "0401",
      "0402",
      "0403",
      "0404",
      "05",
      "0501",
      "050101",
      "050102",
      "050103",
      "050104",
      "050105",
      "06",
      "0601",
      "0602",
      "0603",
      "0604",
      "0605",
      "07",
      "0701",
      "070101",
      "070102",
      "070103",
      "070104",
      "070105",
      "070106",
      "0702",
      "070201",
      "070202",
      "070203",
      "070204",
      "070205",
      "070206",
      "08",
      "0801",
      "080101",
      "080102",
      "080103",
      "0802",
      "080201",
      "080202",
      "080203",
      "09",
      "0901",
      "0902",
      "0903",
      "0904",
      "0905",
      "10",
      "1001",
      "1002",
      "1003",
    ],
  },
  sector: {
    type: "string",
    enum: [
      "01",
      "02",
      "03",
      "0301",
      "030101",
      "030102",
      "0302",
      "0303",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "1201",
      "120101",
      "120102",
      "120103",
      "1202",
      "120201",
      "120202",
      "120203",
      "120204",
      "120205",
      "120206",
      "1203",
      "1204",
      "1205",
      "120501",
      "120502",
      "1206",
      "1207",
      "120701",
      "120702",
      "120703",
      "1208",
      "1209",
      "120901",
      "120902",
      "120903",
      "120904",
      "120905",
      "120906",
      "120907",
      "120908",
      "120909",
      "1210",
      "13",
      "14",
    ],
  },
  skills: {
    type: "string",
    enum: [
      "01",
      "0101",
      "0102",
      "0103",
      "0104",
      "0105",
      "0106",
      "0107",
      "0108",
      "0109",
      "0110",
      "0111",
      "0112",
      "02",
      "0201",
      "0202",
      "0203",
      "0204",
      "0205",
      "0206",
      "0207",
      "0208",
      "0209",
      "0210",
      "0211",
      "0212",
      "0213",
      "0214",
      "0215",
      "0216",
      "0217",
      "03",
      "0301",
      "0302",
      "0303",
      "0304",
      "0305",
      "0306",
      "0307",
      "0308",
      "0309",
      "04",
      "0401",
      "0402",
      "0403",
      "0404",
      "0405",
      "0406",
      "0407",
      "0408",
    ],
  },
  countries: {
    type: "string",
    enum: [
      "AF",
      "AX",
      "AL",
      "DZ",
      "AS",
      "AD",
      "AO",
      "AI",
      "AQ",
      "AG",
      "AR",
      "AM",
      "AW",
      "AU",
      "AT",
      "AZ",
      "BS",
      "BH",
      "BD",
      "BB",
      "BY",
      "BE",
      "BZ",
      "BJ",
      "BM",
      "BT",
      "BO",
      "BQ",
      "BA",
      "BW",
      "BV",
      "BR",
      "IO",
      "BN",
      "BG",
      "BF",
      "BI",
      "KH",
      "CM",
      "CA",
      "CV",
      "KY",
      "CF",
      "TD",
      "CL",
      "CN",
      "CX",
      "CC",
      "CO",
      "KM",
      "CG",
      "CD",
      "CK",
      "CR",
      "CI",
      "HR",
      "CU",
      "CW",
      "CY",
      "CZ",
      "DK",
      "DJ",
      "DM",
      "DO",
      "EC",
      "EG",
      "SV",
      "GQ",
      "ER",
      "EE",
      "ET",
      "FK",
      "FO",
      "FJ",
      "FI",
      "FR",
      "GF",
      "PF",
      "TF",
      "GA",
      "GM",
      "GE",
      "DE",
      "GH",
      "GI",
      "GR",
      "GL",
      "GD",
      "GP",
      "GU",
      "GT",
      "GG",
      "GN",
      "GW",
      "GY",
      "HT",
      "HM",
      "VA",
      "HN",
      "HK",
      "HU",
      "IS",
      "IN",
      "ID",
      "IR",
      "IQ",
      "IE",
      "IM",
      "IL",
      "IT",
      "JM",
      "JP",
      "JE",
      "JO",
      "KZ",
      "KE",
      "KI",
      "KP",
      "KR",
      "KW",
      "KG",
      "LA",
      "LV",
      "LB",
      "LS",
      "LR",
      "LY",
      "LI",
      "LT",
      "LU",
      "MO",
      "MK",
      "MG",
      "MW",
      "MY",
      "MV",
      "ML",
      "MT",
      "MH",
      "MQ",
      "MR",
      "MU",
      "YT",
      "MX",
      "FM",
      "MD",
      "MC",
      "MN",
      "ME",
      "MS",
      "MA",
      "MZ",
      "MM",
      "NA",
      "NR",
      "NP",
      "NL",
      "NC",
      "NZ",
      "NI",
      "NE",
      "NG",
      "NU",
      "NF",
      "MP",
      "NO",
      "OM",
      "PK",
      "PW",
      "PS",
      "PA",
      "PG",
      "PY",
      "PE",
      "PH",
      "PN",
      "PL",
      "PT",
      "PR",
      "QA",
      "RE",
      "RO",
      "RU",
      "RW",
      "BL",
      "SH",
      "KN",
      "LC",
      "MF",
      "PM",
      "VC",
      "WS",
      "SM",
      "ST",
      "SA",
      "SN",
      "RS",
      "SC",
      "SL",
      "SG",
      "SX",
      "SK",
      "SI",
      "SB",
      "SO",
      "ZA",
      "GS",
      "SS",
      "ES",
      "LK",
      "SD",
      "SR",
      "SJ",
      "SZ",
      "SE",
      "CH",
      "SY",
      "TW",
      "TJ",
      "TZ",
      "TH",
      "TL",
      "TG",
      "TK",
      "TO",
      "TT",
      "TN",
      "TR",
      "TM",
      "TC",
      "TV",
      "UG",
      "UA",
      "AE",
      "GB",
      "US",
      "UM",
      "UY",
      "UZ",
      "VU",
      "VE",
      "VN",
      "VG",
      "VI",
      "WF",
      "EH",
      "YE",
      "ZM",
      "ZW",
    ],
  },
};
