import { useHistory, useLocation } from "react-router-dom";
import { FunctionTypes, roleHasFunction } from "../../../../contexts/Session/helpers";
import { Enrolled } from "../../../../contexts/Session/state";
import { Shortcuts as S } from "../../../../routing";
import { ExpertDetailsCBCMember } from "./ExpertDetailsCBCMember";
import { ExpertDetailsPublic } from "./ExpertDetailsPublic";

const cbcMemberPathName = S.nomineeVotingExpert.path;
const publicExpertPathName = S.expert.path;

interface Props {
  readonly sessionState: Enrolled;
}

export const ExpertDetails = ({ sessionState }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const isCBCMember = roleHasFunction(sessionState, FunctionTypes.CBCMember);
  const isAdmin = sessionState.user.isAdmin;
  const isPublicView = location.pathname === publicExpertPathName;
  const isCBCView = location.pathname === cbcMemberPathName;

  if (!isAdmin && !isCBCMember && isCBCView) {
    history.replace({ pathname: publicExpertPathName, search: location.search });
    return <>Invalid Permissions</>;
  }

  return (
    <>
      {isPublicView && <ExpertDetailsPublic sessionState={sessionState} />}
      {isCBCView && <ExpertDetailsCBCMember sessionState={sessionState} />}
    </>
  );
};
