import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { globalDefinitions } from "../_definitions";

const schema: JSONSchemaType<MattermostUserCreationType> = {
  $id: "https://schemas.consilienceventures.com/mattermost-register.v1.schema.json",
  type: "object",
  properties: {
    inviteToken: { ...globalDefinitions.nonEmptyString, maxLength: 1000 },
    username: { ...globalDefinitions.nonEmptyString, minLength: 3, maxLength: 22 },
    password: { ...globalDefinitions.nonEmptyString, minLength: 10, maxLength: 64 },
    passwordConfirmation: { ...globalDefinitions.nonEmptyString, minLength: 10, maxLength: 64 },
  },
  required: ["inviteToken", "username", "password", "passwordConfirmation"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));

export interface MattermostUserCreationType {
  readonly inviteToken: string;
  readonly username: string;
  readonly password: string;
  readonly passwordConfirmation: string;
}
