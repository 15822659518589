import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { Button, Popup, PopupContentProps } from "semantic-ui-react";
import { readableTicketImportance } from "../../../../schemas/tickets/_enums";
import { assertUnreachable, isDefined } from "../../../../types";
import { TicketState, TicketWithPlanFeedback } from "../../../../types/ticket";
import { Shortcuts as S } from "../../../../routing";
import { SemanticShorthandItem } from "semantic-ui-react/dist/commonjs/generic";
import { TextClamp } from "../../TextClamp";
import { Connection } from "../../../../types/relay";
import { utils } from "../../../../utils/utils";

interface Props {
  readonly ticket: TicketWithPlanFeedback;
  readonly isOwner: boolean;
  readonly isWinningBidder: boolean;
}

export const TicketCard = ({ ticket, isOwner, isWinningBidder }: Props) => {
  const history = useHistory();
  const { ticketForm: form, id, insertedAt, newQuestionCount } = ticket;

  const search = new URLSearchParams({ [S.reviewPlan.queryVarNames.id]: id }).toString();
  const linksTo = `${S.ticket.path}?${search}`;
  const onTitleClick = useCallback(() => history.push(linksTo), [linksTo, history]);

  const handleViewPlan = useCallback(() => {
    history.push({ pathname: S.reviewPlan.path, search });
  }, [history, search]);

  const handleCreatePlan = useCallback(() => {
    history.push({ pathname: S.submitPlan.path, search });
  }, [history, search]);

  return (
    <div>
      <div className="TicketsCard">
        <div className="top-section">
          <div className="top-section-title" onClick={onTitleClick}>
            <TextClamp text={form?.data.title || "Untitled"} maxLines={2} />
          </div>
          <div className="top-section-labels">
            <div className="top-section-labels-date">
              {dateFormat(insertedAt, "HH:MM | dd/mm/yy")}
            </div>
            {generateStateLabel(ticket, isOwner)}
            {isDefined(form) && isDefined(form.data.importance) && (
              <div>
                <Popup
                  position="top center"
                  size="small"
                  content="Level of priority"
                  trigger={
                    <div className="priority-label">
                      {readableTicketImportance(form.data.importance)}
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ActionSection">
        <div className="ActionSection-left-actions">
          {newQuestionCount > 0 && (
            <>
              <div className="ActionSection-action">
                New questions
                <div className="NewItensNotificationCircle">
                  {newQuestionCount > 9 ? "9+" : newQuestionCount}
                </div>
              </div>
              <div className="ActionSection-spliter" />
            </>
          )}
          <div className="ActionSection-action">
            <Link to={linksTo} className="ActionSection-details-link">
              View details
            </Link>
          </div>
        </div>
        <div className="ActionSection-right-actions">
          {isWinningBidder && ticket.state === TicketState.Winner_proposal && (
            <Button primary onClick={handleCreatePlan}>
              {getDraftPlanButtonText(ticket.planFeedback, ticket.draftPlan)}
            </Button>
          )}
          {(isOwner || isWinningBidder) && ticket.state === TicketState.Winner_plan_submitted && (
            <Button primary onClick={handleViewPlan}>
              {isWinningBidder ? "View" : "Review"} Plan
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const generateStateLabel = (
  { state, owner, winningBid, ticketForm }: TicketWithPlanFeedback,
  isOwner: boolean
): JSX.Element | null => {
  let label: SemanticShorthandItem<PopupContentProps>;
  let trigger: React.ReactNode;

  const winningBidderName = winningBid?.owner.fullName || "unknown";

  switch (state) {
    case TicketState.Completed:
      if (isOwner) {
        label = "Review and confirm the delivery of this ticket.";
        trigger = <div className="StatusLabel yellow">Review completion</div>;
      } else {
        label = `Pending approval of ticket delivery from ${owner.fullName}.`;
        trigger = <div className="StatusLabel green">Awaiting approval</div>;
      }
      return generateLabelPopup(label, trigger);

    case TicketState.Winner_plan_submitted:
      if (isOwner) {
        const possessiveName = utils.withPossessiveApostrophe(winningBidderName);
        label = `Review and approve ${possessiveName} delivery plan.`;
        trigger = <div className="StatusLabel yellow">Review plan</div>;
      } else {
        label = `Pending approval of your plan from ${owner.fullName}.`;
        trigger = <div className="StatusLabel green">Awaiting review</div>;
      }
      return generateLabelPopup(label, trigger);

    case TicketState.Winner_proposal:
      if (isOwner) {
        label = `Pending submission of the delivery plan by ${winningBidderName}.`;
        trigger = <div className="StatusLabel green">Awaiting plan</div>;
      } else {
        label = "Navigate to the ticket in My Projects to define and submit your delivery plan.";
        trigger = <div className="StatusLabel yellow">Planning</div>;
      }
      return generateLabelPopup(label, trigger);

    case TicketState.Awaiting_bidder_acknowledgement:
      if (isOwner) {
        label = `Your offer is pending acceptance from ${winningBidderName}.`;
        trigger = <div className="StatusLabel green">Offer pending</div>;
      } else {
        label = `You have received an offer to work on ${ticketForm?.data.title || "this ticket"}.`;
        trigger = <div className="StatusLabel yellow">Offer received</div>;
      }
      return generateLabelPopup(label, trigger);

    case TicketState.Winner_selection:
      label = "Select a winning bidder.";
      trigger = <div className="StatusLabel yellow">Winner selection</div>;
      return generateLabelPopup(label, trigger);

    case TicketState.In_bidding:
      label = "This ticket is published to the community in the marketplace.";
      trigger = <div className="StatusLabel green">Published</div>;
      return generateLabelPopup(label, trigger);

    case TicketState.Execution:
      label = "Ticket delivery is in progress.";
      trigger = <div className="StatusLabel green">In progress</div>;
      return generateLabelPopup(label, trigger);

    case TicketState.Closed:
      label = "This ticket has been successful delivered.";
      trigger = <div className="StatusLabel green">Delivered</div>;
      return generateLabelPopup(label, trigger);

    case TicketState.Awaiting_funds:
      label = "Awaiting transfer of funds.";
      trigger = <div className="StatusLabel yellow">Awaiting escrow</div>;
      return generateLabelPopup(label, trigger);

    case TicketState.Draft:
      return null;
    default:
      return assertUnreachable(state);
  }
};

const generateLabelPopup = (
  content: SemanticShorthandItem<PopupContentProps>,
  trigger: React.ReactNode
) => {
  return <Popup position="top center" size="small" content={content} trigger={trigger} />;
};

interface SimpleDraftPlan {
  readonly insertedAt: string;
  readonly updatedAt: string;
}
export const getDraftPlanButtonText = (
  planFeedback?: Connection<unknown>,
  draftPlan?: SimpleDraftPlan
): string => {
  if (planFeedback?.edges && planFeedback.edges.length > 0) {
    return "Revise Plan";
  } else if (draftPlan && draftPlan.insertedAt !== draftPlan.updatedAt) {
    return "Edit Plan";
  } else {
    return "Create Plan";
  }
};
