import { useEffect, memo, CSSProperties } from "react";
import { Card, Button, Image } from "semantic-ui-react";
import { toast } from "react-toastify";
import { decode } from "hi-base32";
import { useSessionApi } from "../../contexts/Session";
import { MUTATION, Variables, Result } from "../../api/accounts/CreateUser";
import { utils } from "../../utils/utils";
import cvLogo from "../../assets/logo.svg";
import { useMutation } from "@apollo/client";
import { extractErrorMessages, noResultErrorFor } from "../../types";
import { convertToSessionUser } from "../../types/frontend_only/sessionUser";

const OAUTH_EVENT = "message";
const messageStyle: CSSProperties = { textAlign: "justify", fontSize: "12px" };

export const openLinkedinPopup = () => {
  window.open(
    `${utils.apiHost}/auth/linkedin`,
    "LinkedInOAuth",
    "status=0,toolbar=0,width=640,height=480"
  );
};

export const LinkedinWall = memo(() => {
  const sessionApi = useSessionApi();
  const [createUser, { loading, called }] = useMutation<Result, Variables>(MUTATION);

  useEffect(() => {
    const onEvent = (e: MessageEvent) => {
      if (e.type !== OAUTH_EVENT || e.data.id !== "LinkedInOAuth") {
        return;
      } else if (!e.data || !e.data.search) {
        toast.error("Something went wrong.");
        console.warn(e);
      }

      const result = new URLSearchParams(e.data.search).get("result");

      if (!result) {
        toast.error("Something went wrong.");
        console.warn("Missing `result` param in the redirect url.");
        return;
      }

      try {
        const decodedRes = decode(result, true);
        const parsedRes = JSON.parse(decodedRes);
        if (parsedRes.linkedinSessionId && typeof parsedRes.linkedinSessionId === "string") {
          const variables: Variables = {
            input: { linkedinSessionId: parsedRes.linkedinSessionId },
          };
          if (called) {
            return;
          }

          createUser({ variables })
            .then((res) => {
              if (!res.data || !res.data.payload) {
                return Promise.reject(noResultErrorFor("Create User"));
              }
              sessionApi.setToken(res.data.payload.session.bearerToken);
              sessionApi.setPresent(convertToSessionUser(res.data.payload.session.user));
            })
            .catch((err) => {
              const errs = extractErrorMessages(err).filter(
                (er) => !er.includes("masterkey can't be blank")
              );
              toast.error("Something went wrong.");
              console.warn(errs);
            });
        } else if (parsedRes.bearerToken && typeof parsedRes.bearerToken === "string") {
          sessionApi.setToken(parsedRes.bearerToken);
        } else {
          toast.error("Something went wrong.");
          console.warn("No error and no known fields found inside LinkedIn redirect.");
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    };
    window.addEventListener(OAUTH_EVENT, onEvent);
    return () => window.removeEventListener(OAUTH_EVENT, onEvent);
  }, [sessionApi, called, createUser]);

  return (
    <Card className="Wall">
      <div className="WallContent">
        <Image className="WallContent-logo" src={cvLogo} alt="Consilience Ventures logo" />
        <h1 className="WallContent-title">Consilience Ventures</h1>
        <Button className="WallContent-button" onClick={openLinkedinPopup} loading={loading}>
          Access with LinkedIn
        </Button>
        <p />
        <div style={messageStyle}>
          Access with LinkedIn will allow Consilience Ventures to access your LinkedIn
          <i> Username</i> and<i> Photo</i> only. Consilience Ventures does not have access to your
          password or any other LinkedIn profile data.
        </div>
      </div>
    </Card>
  );
});
