import { useContext } from "react";
import { State, Present, isEnrolled, Enrolled, isAtLeastPresent } from "./state";
import { Actioner } from "./actions";
import { Context } from "./Context";
import { SessionUser } from "../../types/frontend_only/sessionUser";

// Context related helper functions.
export const useSessionApi = (): Actioner => useContext(Context).api;
export const useSessionState = (): State => useContext(Context).state;

export const requireAtleastPresent = (state: State): Present | Enrolled => {
  if (isAtLeastPresent(state)) {
    return state;
  } else {
    throw new Error("A call to requireAtleastPresent() cannot be fulfilled.");
  }
};

export const requireEnrolled = (state: State): Enrolled => {
  if (isEnrolled(state)) {
    return state;
  } else {
    throw new Error("A call to requireEnrolled() cannot be fulfilled.");
  }
};

export const requireAdminUser = (state: State): SessionUser => {
  const { user } = requireAtleastPresent(state);
  if (user.isAdmin) {
    return user;
  } else {
    throw new Error("A call to requireAdminUser() cannot be fulfilled.");
  }
};
