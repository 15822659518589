import { useCallback, CSSProperties, useState, MutableRefObject, useEffect } from "react";
import { RadioField } from "uniforms-semantic";
import { useMutation } from "@apollo/client";
import { Segment, Header, Grid, Modal, Divider, Image, Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Context, DeepPartial } from "uniforms";
import { useHistory, useLocation } from "react-router-dom";
import { bridge } from "./PreSelectionVoteSchema";
import { sanitizePreselectionVoteData } from "./_types";
import { PreselectionProposalChoice, PreselectionVoteSchema } from "./_types";
import { readableAbstainReason, readablePreselectionProposalChoice } from "./_types";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { MUTATION, Variables, Result } from "./../../api/proposals/PreselectionVote";
import { QUERY } from "./../../api/onboards/PreselectionOnboard";
import { useSessionState } from "../../contexts/Session";
import { isEnrolled } from "../../contexts/Session/state";
import { PublicOnboard } from "../../types/onboard";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { Proposal } from "../../types/proposal";
import { ballotEnvelope } from "../../api/seals/BallotSeal";
import { QUERY as BallotSealQuery, SealQueryResult } from "../../api/seals/BallotSeal";
import { BallotSealFields } from "../../api/seals/_fragments/BallotSealFields";
import { digestFormData } from "../../types/OnboardForm";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { PreselectionRadioField } from "../PreselectionRadioField";
import { FormHeader } from "./FormHeader";
import { DisplayIf } from "../DisplayIf";
import { ReviewCommitteeSection } from "./ReviewCommitteeSection";
import { CustomLongTextField } from "../CustomLongTextField";
import { DesirabilityForm } from "./DesirabilityForm";
import { FeasibilityForm } from "./FeasibilityForm";
import { ViabilityForm } from "./ViabilityForm";
import { Tabs } from "./../../components/pages/Startups/StartupDetails";
import { Shortcuts as S } from "../../routing";
import approvedIcon from "../../assets/approved-icon.svg";
import { Maybe } from "../../types";
import { AjvError } from "../../utils/Ajv";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";

const initialModel: DeepPartial<PreselectionVoteSchema> = {
  voteChoice: PreselectionProposalChoice.Yes,
};

const fitContentStyle: CSSProperties = { width: "fit-content" };
const greyTextStyle: CSSProperties = { color: "#777777" };

interface Props {
  readonly proposal: Proposal<PreselectionProposalChoice>;
  readonly onboard: PublicOnboard;
  readonly modelRef: MutableRefObject<DeepPartial<PreselectionVoteSchema> | undefined>;
}

export const PreSelectionVoteForm = ({ proposal, onboard, modelRef }: Props) => {
  const { id: proposalId } = proposal;
  const history = useHistory();
  const location = useLocation();
  const chainApi = useChainApi();
  const chainState = useChainState();
  const sessionState = useSessionState();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const [vote, { loading }] = useMutation<Result, Variables>(MUTATION);
  const [needsScroll, setNeedsScroll] = useState(false);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const isLoading = loading || sealFieldsLoading;

  useEffect(() => {
    if (needsScroll) {
      const firstError = document.getElementsByClassName("ui red basic pointing label")[0];
      if (firstError && firstError.parentElement) {
        firstError.parentElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
      setNeedsScroll(false);
    }
  }, [needsScroll]);

  const isYesNoSchemaCondition = useCallback(
    ({ model: { voteChoice } }: Context<PreselectionVoteSchema>) =>
      voteChoice === PreselectionProposalChoice.Yes || voteChoice === PreselectionProposalChoice.No,
    []
  );

  const isAbstainSchemaCondition = useCallback(
    ({ model: { voteChoice } }: Context<PreselectionVoteSchema>) =>
      voteChoice === PreselectionProposalChoice.Neutral,
    []
  );

  const onContinue = useCallback(() => {
    const newSearch = new URLSearchParams(location.search);
    newSearch.set(S.startup.queryVarNames.tab, Tabs.Discussion);
    return history.replace({ pathname: S.startup.path, search: newSearch.toString() });
  }, [location, history]);

  const onChangeModel = useCallback(
    (m: DeepPartial<PreselectionVoteSchema>) => {
      modelRef.current = m;
    },
    [modelRef]
  );

  const onValidate = useCallback((_: Partial<PreselectionVoteSchema>, mError: Maybe<AjvError>) => {
    if (mError) {
      setNeedsScroll(true);
      console.warn(mError);
    }
    return mError;
  }, []);

  const onSubmit = useCallback(
    async (model: PreselectionVoteSchema) => {
      if (!isEnrolled(sessionState)) {
        return;
      }
      setSealFieldsLoading(true);
      const sealData = await sealFieldsQuery(BallotSealQuery, { id: proposalId });
      setSealFieldsLoading(false);

      if (!sealData) {
        return toast.error("Failed to fetch the data for the signature.");
      }

      const transmittedAt = new Date();

      const { chainId } = sessionState;
      const sanitisedModel = sanitizePreselectionVoteData(model);
      const form = { data: sanitisedModel };
      const tAt = transmittedAt.toLocaleString();

      const fields: BallotSealFields = { form, transmittedAt: tAt, proposal: sealData.fields };

      const envelope = ballotEnvelope(chainId, fields, transmittedAt);
      const plainText = digestFormData(envelope, true);

      chainApi
        .sign(chainState, plainText)
        .then(async (ethSignature) => {
          const f = JSON.stringify(sanitisedModel);
          await vote({
            variables: { input: { ethSignature, proposalId, transmittedAt, form: f } },
            awaitRefetchQueries: true,
            refetchQueries: [
              { query: QUERY, variables: { onboardId: onboard.id, roleId: sessionState.roleId } },
            ],
          });

          if (model.isReviewCommitteeVolunteer) {
            setShowThankYouModal(true);
          } else {
            toast.success("You opinion was successfuly submited.");
            onContinue();
          }
        })
        .catch((e: Error) => {
          // Discard error message thrown when the user clicks cancel on the MM popup.
          if (e.message.includes("User denied message signature")) {
            return;
          }
          toast.error(`Something went wrong. ${e.message}`);
        });
    },
    [chainApi, chainState, onboard, proposalId, sessionState, vote, sealFieldsQuery, onContinue]
  );

  return (
    <AutoForm
      className="Preference-form"
      model={modelRef.current || initialModel}
      schema={bridge}
      onSubmit={onSubmit}
      onValidate={onValidate}
      onChangeModel={onChangeModel}
      showInlineError
    >
      <Segment>
        <Header as="h2">Do you think this startup should proceed to General Assessment?</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p>
                Your feedback informs the decision to progress {onboard.fullName || "this startup"}{" "}
                to General Assessment, the second stage of our due diligence process. During General
                Assessment, a Startup Review Committee comprising several CV Members will be formed
                to thoroughly evaluate the deal.
                <br />
                <span style={greyTextStyle}>
                  Your feedback will be anonymised and visible to all members.
                </span>
              </p>
            </Grid.Column>
          </Grid.Row>
          <PreselectionRadioField
            name="voteChoice"
            label={null}
            errorMessage="This field is mandatory."
            transform={readablePreselectionProposalChoice}
          />
        </Grid>
        <Grid className="PreSelectionVoteForm">
          <Grid.Row>
            <Grid.Column>
              <FormHeader />
            </Grid.Column>
          </Grid.Row>

          {/* YES / NO Fields */}
          <DisplayIf condition={isYesNoSchemaCondition}>
            <>
              <Grid.Row>
                <Grid.Column>
                  <div className="PreSelectionVoteForm-title">1. Evaluation</div>
                  <p>
                    The decision to progress this applicant to General Assessment is made by the
                    Industry Committee based on community feedback.
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer="14" mobile="16" tablet="16">
                  <DesirabilityForm onboard={onboard} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer="14" mobile="16" tablet="16">
                  <FeasibilityForm onboard={onboard} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer="14" mobile="16" tablet="16">
                  <ViabilityForm onboard={onboard} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row />
              <Grid.Row>
                <Grid.Column computer="14" mobile="16" tablet="16">
                  <div className="PreSelectionVoteForm-title">2. Additional Comments</div>
                  <br />
                  <CustomLongTextField
                    name="comment"
                    label="Rationale for your vote as well as any feedback / observations / comments for consideration by the Review Committee and community. Remember that founders will see this feedback, please be incisive, constructive and kind."
                    placeholder="Enter up to 1000 characters..."
                    errorMessage="This field is mandatory."
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          </DisplayIf>

          {/* Abstain Fields */}
          <DisplayIf condition={isAbstainSchemaCondition}>
            <>
              <Grid.Row>
                <Grid.Column computer="14" mobile="16" tablet="16">
                  <div className="PreSelectionVoteForm-title">1. Why are you abstaining?</div>
                  <RadioField
                    name="reason"
                    label={null}
                    errorMessage="This field is mandatory."
                    transform={readableAbstainReason}
                  />
                  <br />
                  <CustomLongTextField
                    name="comment"
                    label="Rationale for your vote as well as any feedback / observations / comments for consideration by the Review Committee and community. Remember that founders will see this feedback, please be incisive, constructive and kind."
                    placeholder="Enter up to 1000 characters..."
                    errorMessage="This field is mandatory."
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          </DisplayIf>

          {/* Review Committee Fields */}
          <ReviewCommitteeSection onboard={onboard} />
          <Grid.Row>
            <Grid.Column>
              <Modal
                className="PreSelectionVoteForm-success-modal"
                open={showThankYouModal}
                size="mini"
                closeOnDimmerClick={false}
                closeIcon={false}
                trigger={
                  <SignatureButton
                    type="submit"
                    loading={isLoading}
                    content="Sign and Vote"
                    style={fitContentStyle}
                  />
                }
              >
                <Grid centered>
                  <Grid.Column textAlign="center">
                    <Grid.Row>
                      <Image size="small" src={approvedIcon} verticalAlign="middle" />
                    </Grid.Row>
                    <Grid.Row>
                      <h3>Thank you!</h3>
                      <p>
                        A member of Consilience Group will be in touch should the application
                        progress to General Assessment.
                      </p>
                    </Grid.Row>
                    <Divider hidden />
                    <Grid.Row>
                      <Button color="blue" onClick={onContinue}>
                        Continue
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </Modal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </AutoForm>
  );
};
