export interface TagMapping {
  [k: string]: string;
}

export interface TagTree {
  readonly label: string;
  readonly description: string;
  readonly assignable: boolean;
  readonly children?: Readonly<TagTrees>;
}

export type TagTrees = TagTree[];

export class TagTreeHelper {
  static flatten = (tree: TagTree | undefined | null): ReadonlyArray<string> => {
    const flatTree: string[] = [];
    if (!tree) {
      return flatTree;
    } else if (tree.label !== "root") {
      flatTree.push(tree.label);
    }

    if (tree.children) {
      tree.children.forEach(c => flatTree.push(...TagTreeHelper.flatten(c)));
    }
    return flatTree;
  };

  static getTreeBranch = (tree: TagTree, label: string) => privateGetTreeBranch(tree, label);

  static findByLabel = (tree: TagTree, label: string) => privateTagTreeFind(tree, "label", label);

  static findByDescription = (tree: TagTree, description: string) =>
    privateTagTreeFind(tree, "description", description);
}

const privateGetTreeBranch = (tree: TagTree, label: string): TagTree | null => {
  if (tree.label === label) {
    return tree;
  } else if (!tree.children) {
    return null;
  }

  let result: TagTree | null = null;
  let iterator = 0;

  while (iterator < tree.children.length && !result) {
    const childBranch = privateGetTreeBranch(tree.children[iterator], label);
    if (childBranch) {
      result = { ...tree, children: [childBranch] };
    }

    iterator++;
  }

  return result;
};

const privateTagTreeFind = <K extends keyof TagTree>(
  tree: TagTree,
  fieldName: K,
  fieldValue: TagTree[K]
): TagTree | null => {
  if (tree[fieldName] === fieldValue) {
    return tree;
  } else if (!tree.children) {
    return null;
  }

  let result: TagTree | null = null;
  let iterator = 0;

  while (iterator < tree.children.length && !result) {
    result = privateTagTreeFind(tree.children[iterator], fieldName, fieldValue);
    iterator++;
  }

  return result;
};
