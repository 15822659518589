import { useCallback, CSSProperties, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Image, Grid } from "semantic-ui-react";
import blueChevron from "../../../assets/blue-chevron.svg";
import { OnboardState, OnboardStatus } from "../../../types/onboard";
import { PublicOnboard } from "../../../types/onboard";
import { TextClamp } from "../../elements/TextClamp";
import { Shortcuts as S } from "../../../routing";
import { formattedValuation } from "../../../utils/applications/Formatting";
import { useCategoriesState } from "../../../contexts/Categories";
import { isSuccessState } from "../../../contexts/Generic";
import { isDefined } from "../../../types";

const flexStyle: CSSProperties = { display: "flex", alignItems: "center" };
const statusCircleStyle: CSSProperties = { marginLeft: "8px" };
const breakWordStyle: CSSProperties = { wordBreak: "break-word" };
const alignSelfEndStyle: CSSProperties = { alignSelf: "end" };

interface Props {
  readonly onboard: PublicOnboard;
}

export const PreSelectionHeader = ({ onboard }: Props) => {
  const history = useHistory();
  const categoriesState = useCategoriesState();
  const { form, fullName } = onboard;

  const OnReceiveInvClick = useCallback(() => history.push(S.preselection.path), [history]);

  const valuation = form?.data.startupData?.valuation;
  const amountRaised = form?.data.startupData?.amountRaised;
  const sectors = form?.data.startupData?.sectors;
  const namedSectors = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return null;
    }
    const { sectorsMapping } = categoriesState.result;
    return sectors?.map((s) => sectorsMapping[s]);
  }, [sectors, categoriesState]);

  return (
    <div className="OnboardHeader">
      <div className="OnboardHeader-breadcrumb" onClick={OnReceiveInvClick}>
        <Image src={blueChevron} />
        Startup Pipeline
      </div>
      <Grid columns="16">
        <Grid.Row>
          <Grid.Column computer="14" tablet="13" mobile="12">
            <Grid.Row>
              <div className="OnboardHeader-description-title">
                <TextClamp text={fullName || "Unknown"} />
              </div>
            </Grid.Row>
            <Grid.Row className="OnboardHeader-description">
              <Grid>
                {isDefined(valuation) && (
                  <Grid.Column computer="3" tablet="4" mobile="5">
                    <Grid.Row>
                      <div className="OnboardHeader-description-small-label">Valuation</div>
                    </Grid.Row>
                    <Grid.Row style={breakWordStyle}>
                      {formattedValuation(valuation)} pre-money
                    </Grid.Row>
                  </Grid.Column>
                )}
                {isDefined(amountRaised) && (
                  <Grid.Column computer="2" tablet="4" mobile="5">
                    <Grid.Row>
                      <div className="OnboardHeader-description-small-label">Raising</div>
                    </Grid.Row>
                    <Grid.Row style={breakWordStyle}>
                      {amountRaised ? `${formattedValuation(amountRaised)}` : "n.a"}
                    </Grid.Row>
                  </Grid.Column>
                )}
                {namedSectors && (
                  <Grid.Column computer="10" tablet="4" mobile="5">
                    <Grid.Row>
                      <div className="OnboardHeader-description-small-label">Sectors</div>
                    </Grid.Row>
                    <Grid.Row style={breakWordStyle}>
                      <TextClamp text={namedSectors.join(", ")} />
                    </Grid.Row>
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column style={alignSelfEndStyle} computer="2" tablet="3" mobile="4">
            <Grid.Row className="OnboardHeader-description">
              <Grid>
                <Grid.Column width="16">
                  <Grid.Row>
                    <div className="OnboardHeader-description-small-label">Status</div>
                  </Grid.Row>
                  <Grid.Row>{generateStatusLabels(onboard)}</Grid.Row>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const generateStatusLabels = (onboard: PublicOnboard) => {
  const { state, status } = onboard;
  let label;
  let circleLabelJSX;
  if (state === OnboardState.InPreselection || state === OnboardState.TimedOut) {
    label = "In Pre-selection";
    circleLabelJSX = <div className="StatusCircle yellow" style={statusCircleStyle} />;
  } else if (state === OnboardState.UnderReview) {
    label = "In General Assessment";
    circleLabelJSX = <div className="StatusCircle green" style={statusCircleStyle} />;
  } else if (state === OnboardState.AwaitingEnableVoting) {
    label = "Being reviewed";
    circleLabelJSX = <div className="StatusCircle grey" style={statusCircleStyle} />;
  } else if (state === OnboardState.Closed) {
    if (status === OnboardStatus.Rejected) {
      label = "Rejected";
      circleLabelJSX = <div className="StatusCircle dark-red" style={statusCircleStyle} />;
    } else if (status === OnboardStatus.Approved || status === OnboardStatus.Finalised) {
      label = "Approved";
      circleLabelJSX = <div className="StatusCircle green" style={statusCircleStyle} />;
    } else {
      return null;
    }
  } else {
    return null;
  }

  return (
    <div style={flexStyle}>
      <TextClamp text={label} />
      {circleLabelJSX}
    </div>
  );
};
