import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { ExpertEvaluationSchema, ExpertOnboardEvaluationScore } from "./_types";
import { utils } from "../../utils/utils";

export const schema: JSONSchemaType<ExpertEvaluationSchema> = {
  type: "object",
  properties: {
    availableToParticipate: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    networkSynergies: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    meetPortfolioNeeds: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    tacitKnowledge: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    startupExperience: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    ecosystemBalance: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    priceRange: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    geographicSynergy: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    startupKnowledge: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    tokenEconomicsKnowledge: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    cvdsKnowledge: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    expertScale: {
      type: "string",
      enum: Object.values(ExpertOnboardEvaluationScore),
    },
    positiveStatements: {
      type: "array",
      items: { type: "string", maxLength: 100 },
      minItems: 0,
      maxItems: 3,
    },
    negativeStatements: {
      type: "array",
      items: { type: "string", maxLength: 100 },
      minItems: 0,
      maxItems: 3,
    },
    expectedContribution: { type: "string", maxLength: 400 },
    individualSkills: { type: "string", maxLength: 400 },
    personalOpinion: { type: "string", maxLength: 200 },
    additionalInfo: { type: "string", maxLength: 400 },
  },
  required: [
    "expectedContribution",
    "individualSkills",
    "availableToParticipate",
    "networkSynergies",
    "meetPortfolioNeeds",
    "tacitKnowledge",
    "startupExperience",
    "ecosystemBalance",
    "priceRange",
    "geographicSynergy",
    "startupKnowledge",
    "tokenEconomicsKnowledge",
    "cvdsKnowledge",
    "expertScale",
    "personalOpinion",
  ],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
