import React, { CSSProperties, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Image, Modal } from "semantic-ui-react";
import ApprovedIcon from "../../../../../../assets/approved-icon.svg";
import { Shortcuts as S } from "../../../../../../routing";

const modalContentStyle: CSSProperties = {
  textAlign: "center",
  paddingTop: "20px",
  paddingBottom: "30px",
};

interface Props {
  readonly onGeneralClose: () => void;
  readonly accepting: boolean;
}

export const ModalBidAcceptOrReject = ({ onGeneralClose, accepting }: Props) => {
  const history = useHistory();
  const handleClose = useCallback(() => history.push(S.mySprint.path), [history]);

  return (
    <Modal
      className="CustomClose"
      onClose={onGeneralClose}
      open={true}
      size="mini"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <div style={modalContentStyle}>
          <Image src={ApprovedIcon} verticalAlign="middle" />
          <h2>Bid {`${accepting ? "Accepted" : "Rejected"}`}</h2>
          <br />
          <Button basic color="blue" onClick={handleClose}>
            <b>Back to My Sprint</b>
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
