import { Image } from "semantic-ui-react";
import approvalIcon from "../../../../../../assets/approval-icon-purple.svg";

export const AwaitingVoteApproval = () => {
  return (
    <div className="AwaitingVoteApproval">
      <Image src={approvalIcon} />
      <div className="AwaitingVoteApproval-title">Thank you for submitting your application</div>
      <div className="AwaitingVoteApproval-text">
        The next step is a review of the information you've just submitted by the relevant Industry
        <br />
        Committee Lead. They'll be in touch to provide more information about Consilience
        <br /> Ventures and next steps.
      </div>
    </div>
  );
};
