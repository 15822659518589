import { SyntheticEvent, useState, useCallback, useMemo, CSSProperties } from "react";
import { useMutation } from "@apollo/client";
import { Button, Dropdown, DropdownProps, Icon, Image } from "semantic-ui-react";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import warningIcon from "../../../../assets/warning-triangle-icon.svg";
import { DeliverableType } from "../../../../api/tickets/GetWorkplaceDeliverables";
import { DeliverableUpdates } from "../../../../api/tickets/GetWorkplaceDeliverables";
import { YesNoAnswer } from "../../../../schemas/_types";
import { TicketDeliverableState } from "../../../../types/ticket";
import { ApiError, isEnum } from "../../../../types";
import { MUTATION, Result, Variables } from "../../../../api/tickets/UpdateDeliverableStatus";
import { useGalleryContext } from "../../../../contexts/GalleryModal";

const fontsize12: CSSProperties = {
  fontSize: "12px",
};

interface Props {
  readonly isTicketOwner: boolean;
  readonly ownerFullName: string;
  readonly deliverables: DeliverableType[];
}

export const TicketDeliverableModalContent = (props: Props) => {
  const { currentIndex, closeModal } = useGalleryContext();
  const { isTicketOwner, deliverables, ownerFullName } = props;
  const deliverable = useMemo(
    () => (deliverables ? deliverables[currentIndex] : undefined),
    [currentIndex, deliverables]
  );
  const [stateSelected, setStateSelected] = useState<TicketDeliverableState | undefined>(
    deliverable?.state
  );
  const [updateTicketDeliverable] = useMutation<Result, Variables>(MUTATION);

  const onSortChange = useCallback(
    (_: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      if (isEnum(value, TicketDeliverableState)) {
        setStateSelected(value);
      }
    },
    []
  );

  const onUpdateClick = useCallback(() => {
    if (!deliverable || !stateSelected || stateSelected === TicketDeliverableState.Unstarted) {
      return;
    }
    updateTicketDeliverable({
      variables: { input: { deliverableId: deliverable.id, progressStatus: stateSelected } },
    })
      .then(() => {
        toast.success("Deliverable updated!");
      })
      .catch((error: ApiError) => {
        toast.error("Something went wrong - " + error.message);
      })
      .finally(() => closeModal());
  }, [deliverable, stateSelected, updateTicketDeliverable, closeModal]);

  if (!deliverable) {
    return null;
  }

  const { form, state, progressUpdates } = deliverable;
  const isUnstarted = state === TicketDeliverableState.Unstarted;
  const displayWarning =
    stateSelected === TicketDeliverableState.Completed ||
    (stateSelected === TicketDeliverableState.In_progress &&
      state === TicketDeliverableState.Unstarted);

  return (
    <div className="TicketDeliverableModal">
      {generateTitle(deliverable)}
      {state !== TicketDeliverableState.Completed && !isTicketOwner && (
        <>
          <div className="TicketDeliverableModal-description">
            <div className="TicketDeliverableModal-description-title">Provide a status update</div>
            Even if the status hasn't changed, providing an update lets {ownerFullName} know the
            status of this deliverable.
          </div>

          <div className="TicketDeliverableModal-dropdown-container">
            <Dropdown
              options={isUnstarted ? STATUS_UPDATE_OPTIONS_UNSTARTED : STATUS_UPDATE_OPTIONS}
              selection
              value={stateSelected}
              onChange={onSortChange}
            />
            <Button color="blue" content={"Update"} onClick={onUpdateClick} />
            {displayWarning && (
              <div className="TicketDeliverableModal-dropdown-warning">
                <Image src={warningIcon} />
                You cannot undo this update
              </div>
            )}
          </div>
        </>
      )}
      {state === TicketDeliverableState.Completed && !isTicketOwner && (
        <div className="TicketDeliverableModal-description">
          This deliverable is complete. You can no longer provide status updates.
        </div>
      )}
      <div className="TicketDeliverableModal-line" />
      <div className="TicketDeliverableModal-bottom-wrapper">
        <div className="TicketDeliverableModal-details">
          <div className="TicketDeliverableModal-bottom-wrapper-title" style={fontsize12}>
            DETAILS
          </div>
          <div className="TicketDeliverableModal-bottom-wrapper-title">Due date</div>
          <div>{dateFormat(form.data.dueDate, "dd mmmm yyyy")}</div>
          <div className="TicketDeliverableModal-bottom-wrapper-title">Description</div>
          <div>{form.data.description}</div>
          <div className="TicketDeliverableModal-bottom-wrapper-title">
            Payment associated to this deliverable?
          </div>
          <div>{form.data.associatePayment === YesNoAnswer.Yes ? "Yes" : "No"}</div>
          {form.data.associatePayment === YesNoAnswer.Yes && (
            <>
              <div className="TicketDeliverableModal-bottom-wrapper-title">% of total payment</div>
              <div>{form.data.totalPayment}</div>
            </>
          )}
        </div>
        {progressUpdates.length > 0 && (
          <div>
            <div className="TicketDeliverableModal-bottom-wrapper-title" style={fontsize12}>
              STATUS HISTORY
            </div>
            {progressUpdates.map((p, i) => generateHistoryStatus(p))}
          </div>
        )}
      </div>
    </div>
  );
};

const generateTitle = ({ state, form }: DeliverableType) => {
  let circleLabelJSX;

  if (state === TicketDeliverableState.Completed) {
    circleLabelJSX = <div className="StatusCircle green" />;
  } else if (state === TicketDeliverableState.In_progress) {
    circleLabelJSX = <div className="StatusCircle yellow" />;
  } else if (state === TicketDeliverableState.Unstarted) {
    circleLabelJSX = <div className="StatusCircle orange" />;
  }

  return (
    <div className="TicketDeliverableModal-title">
      {circleLabelJSX}
      <div>{form.data.title}</div>
    </div>
  );
};

const generateHistoryStatus = (updateHistory: DeliverableUpdates) => {
  const { progressUpdateStatus, progressUpdateDate, id } = updateHistory;
  let statusLabelJSX;

  if (progressUpdateStatus === TicketDeliverableState.Completed) {
    statusLabelJSX = (
      <>
        <div className="StatusCircle green" />
        <b>Completed</b>
      </>
    );
  } else if (progressUpdateStatus === TicketDeliverableState.In_progress) {
    statusLabelJSX = (
      <>
        <div className="StatusCircle yellow" />
        <b>In progress</b>
      </>
    );
  } else if (progressUpdateStatus === TicketDeliverableState.Unstarted) {
    statusLabelJSX = (
      <>
        <div className="StatusCircle orange" />
        <b>Unstarted</b>
      </>
    );
  }

  return (
    <div className="TicketDeliverableModal-history-status" key={id}>
      {statusLabelJSX}
      <span style={fontsize12}>{dateFormat(progressUpdateDate, "HH:MM | dd/mm/yy")}</span>
    </div>
  );
};

const STATUS_UPDATE_OPTIONS = [
  {
    key: 1,
    text: (
      <div className="TicketDeliverableModal-dropdown-option">
        <Icon name="circle" size="small" color="yellow" />
        In Progress
      </div>
    ),
    value: TicketDeliverableState.In_progress,
  },
  {
    key: 2,
    text: (
      <div className="TicketDeliverableModal-dropdown-option">
        <Icon name="circle" size="small" color="green" />
        Completed
      </div>
    ),
    value: TicketDeliverableState.Completed,
  },
];

const STATUS_UPDATE_OPTIONS_UNSTARTED = [
  {
    key: 1,
    text: (
      <div className="TicketDeliverableModal-dropdown-option">
        <Icon name="circle" size="small" color="yellow" />
        In Progress
      </div>
    ),
    value: TicketDeliverableState.In_progress,
  },
];
