import React, { CSSProperties, useCallback } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Popup, PopupContentProps } from "semantic-ui-react";
import { OwnerField } from "../../../../types/ticket";
import { SimpleBid, TicketBidStatus } from "../../../../types/bid";
import dateFormat from "dateformat";
import { assertUnreachable } from "../../../../types";
import { JsonDocument } from "../../../../types/jsonDocument";
import { TicketSchema } from "../../../../schemas/tickets/_types";
import { Shortcuts as S } from "../../../../routing";
import { useSessionState } from "../../../../contexts/Session";
import { isEnrolled } from "../../../../contexts/Session/state";
import { SemanticShorthandItem } from "semantic-ui-react/dist/commonjs/generic";
import { TextClamp } from "../../TextClamp";
import { utils } from "../../../../utils/utils";

const LinkStyle: CSSProperties = {
  color: "#0085FF",
};

interface BidWithNewQuestions extends SimpleBid {
  readonly newQuestionCount: number;
}

interface Props {
  readonly ticketForm: JsonDocument<TicketSchema>;
  readonly bid: BidWithNewQuestions;
  readonly ticketOwner: OwnerField;
}

export const BidCard = ({ ticketForm, bid, ticketOwner }: Props) => {
  const history = useHistory();
  const sessionState = useSessionState();
  const roleId = isEnrolled(sessionState) ? sessionState.roleId : undefined;
  const { owner, status, newQuestionCount, publishedAt, id } = bid;
  const isOwner = owner.id === roleId;
  const isTicketOwner = roleId === ticketOwner.id;
  const title = isOwner ? `Bid on ${ticketForm.data.title}` : owner.fullName;

  const search = new URLSearchParams({ [S.bid.queryVarNames.id]: id }).toString();
  const linksTo = `${S.bid.path}?${search}`;

  const onReviewOffer = useCallback(() => history.push(linksTo), [linksTo, history]);

  return (
    <>
      <div className="BidCard">
        <div className="top-section">
          <div className="top-section-title" onClick={onReviewOffer}>
            <TextClamp text={title} maxLines={2} />
          </div>
          <div className="top-section-labels">
            <div className="top-section-labels-date">
              {dateFormat(publishedAt, "HH:MM | dd/mm/yy")}
            </div>
            {generateStatusLabel(bid, ticketOwner, isTicketOwner)}
          </div>
        </div>
      </div>
      <div className="ActionSection">
        <div className="ActionSection-left-actions">
          {newQuestionCount > 0 && (
            <>
              <div className="ActionSection-action">
                New questions
                <div className="NewItensNotificationCircle">
                  {newQuestionCount > 9 ? "9+" : newQuestionCount}
                </div>
              </div>
              <div className="ActionSection-spliter" />
            </>
          )}
          <div className="ActionSection-action">
            <Link to={linksTo} style={LinkStyle}>
              View details
            </Link>
          </div>
        </div>
        <div className="ActionSection-right-actions">
          {status === TicketBidStatus.SelectedWinner && !isTicketOwner && (
            <Button primary onClick={onReviewOffer}>
              Offer received
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const generateStatusLabel = (
  bid: BidWithNewQuestions,
  { fullName }: OwnerField,
  isTicketOwner: boolean
) => {
  const { status, voidedExpertDeclinedOfferAt: voidedByExpertAt } = bid;
  const { selectedWinnerAt, voidedOfferTimeoutAt: voidedAt } = bid;
  const { fullName: expertName } = bid.owner;

  let label: SemanticShorthandItem<PopupContentProps>;
  let trigger: React.ReactNode;
  switch (status) {
    case TicketBidStatus.Open:
      label = "This bid is being drafted.";
      trigger = <div className="StatusLabel gray">Draft</div>;
      return isTicketOwner ? trigger : generateLabelPopup(label, trigger);
    case TicketBidStatus.Published:
      label = `This bid has been submitted for consideration by ${fullName}.`;
      const color = isTicketOwner ? "yellow" : "green";
      const text = isTicketOwner ? "Pending" : "Submitted";
      trigger = <div className={`StatusLabel ${color}`}>{text}</div>;
      return isTicketOwner ? trigger : generateLabelPopup(label, trigger);
    case TicketBidStatus.Canceled:
      label = "The ticket for this bid has been unpublished.";
      trigger = <div className="StatusLabel red">Canceled</div>;
      return isTicketOwner ? trigger : generateLabelPopup(label, trigger);
    case TicketBidStatus.SelectedWinner:
      label = `Review the offer extended by ${fullName}.`;
      trigger = (
        <div className={`StatusLabel ${isTicketOwner ? "green" : "yellow"}`}>
          {isTicketOwner ? "Offer extended" : "Offer received"}
        </div>
      );
      return isTicketOwner ? trigger : generateLabelPopup(label, trigger);
    case TicketBidStatus.SelectedWinnerAcknowledged:
      label = "Navigate to the ticket in My Projects to define and submit your delivery plan.";
      trigger = <div className="StatusLabel green">Planning</div>;
      return isTicketOwner ? trigger : generateLabelPopup(label, trigger);
    case TicketBidStatus.Loser:
      label = `This bid was declined by ${fullName}.`;
      trigger = <div className="StatusLabel red">Declined</div>;
      return isTicketOwner ? trigger : generateLabelPopup(label, trigger);
    case TicketBidStatus.ConfirmedWinner:
      label = "This bid was approved and the ticket is now in progress.";
      trigger = <div className="StatusLabel green">Approved</div>;
      return isTicketOwner ? trigger : generateLabelPopup(label, trigger);
    case TicketBidStatus.VoidedTimedOut:
      label = isTicketOwner
        ? `Your offer made on ${formatDate(selectedWinnerAt)} expired on ${formatDate(
            voidedAt
          )}. This bid is considered voided.`
        : `The offer made by ${fullName} on ${formatDate(selectedWinnerAt)} expired on ${formatDate(
            voidedAt
          )}. This bid is considered voided.`;
      trigger = <div className="StatusLabel voided">Voided</div>;
      return generateLabelPopup(label, trigger);
    case TicketBidStatus.VoidedExpertDeclined:
      const possessiveName = utils.withPossessiveApostrophe(fullName);
      label = isTicketOwner
        ? `Your offer made on ${formatDate(
            selectedWinnerAt
          )} was declined by ${expertName} on ${formatDate(
            voidedByExpertAt
          )}. This bid is considered voided.`
        : `On ${formatDate(
            voidedByExpertAt
          )}, you declined ${possessiveName} offer. This bid is considered voided.`;
      trigger = <div className="StatusLabel voided">Voided</div>;
      return generateLabelPopup(label, trigger);
  }
  assertUnreachable(status);
};

const generateLabelPopup = (
  content: SemanticShorthandItem<PopupContentProps>,
  trigger: React.ReactNode
) => {
  return <Popup position="top center" size="small" content={content} trigger={trigger} />;
};

export const formatDate = (date: string | undefined) => dateFormat(date, "dd/mm/yy");
