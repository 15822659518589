import { gql } from "@apollo/client";
import { PreselectionVoteSchema } from "../../schemas/preselectionVote/_types";
import { Identifiable } from "../../types";
import { JsonDocumentVersioned } from "../../types/jsonDocument";
import { Connection, ConnectionVariables } from "../../types/relay";

export const QUERY = gql`
  query DiscussionThreadOnBallot(
    $ballotId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    node(id: $ballotId) {
      ... on Ballot {
        anonymizableProfile {
          ... on AnonymousProfile {
            id
            fullName
          }
          ... on IndividualProfile {
            id
            fullName
          }
          ... on OrganisationProfile {
            id
            fullName
          }
        }
        form(migrate: false) {
          id
          data
          schemaVersion
        }

        discussionThread {
          id
          isLight
          commentCount
          comments(first: $first, after: $after, last: $last, before: $before) {
            edges {
              node {
                ... on DiscussionLightComment {
                  id
                  insertedAt
                  body
                  anonymizableAvatarUrl
                  anonymizableProfile {
                    ... on AnonymousProfile {
                      id
                      fullName
                    }
                    ... on IndividualProfile {
                      id
                      fullName
                    }
                    ... on OrganisationProfile {
                      id
                      fullName
                    }
                  }
                }
              }
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
          }
        }
      }
    }
  }
`;

export interface Variables extends ConnectionVariables {
  readonly ballotId: string;
}

interface AnonymizableProfile extends Identifiable {
  readonly fullName: string;
}

export interface LightComment extends Identifiable {
  readonly insertedAt: string;
  readonly body: string;
  readonly anonymizableAvatarUrl: string;
  readonly anonymizableProfile: AnonymizableProfile;
}

interface DiscussionThread extends Identifiable {
  readonly isLight: boolean;
  readonly commentCount: number;
  readonly comments: Connection<LightComment>;
}

interface Ballot extends Identifiable {
  readonly anonymizableProfile: AnonymizableProfile;
  readonly form: JsonDocumentVersioned<PreselectionVoteSchema>;
  readonly discussionThread: DiscussionThread;
}

export interface Result {
  readonly node?: Ballot;
}
