import { CSSProperties } from "react";
import { useMemo } from "react";
import InfiniteScroll, { Props } from "react-infinite-scroll-component";

const style: CSSProperties = { overflow: "hidden" };

export const PageContentInfinityScroll = (props: Props) => {
  const elescrol = useMemo(() => document.getElementById("PageContentId"), []);

  return <InfiniteScroll {...props} scrollableTarget={elescrol} style={style} />;
};
