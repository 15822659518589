import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { globalDefinitions } from "../_definitions";
import { BusinessCases } from "./_types";

export interface IntroducerInviteType {
  readonly businessCase: BusinessCases.Introducer;
  readonly previousStakeholders: string | null; // max lenght 300
}

const schema: JSONSchemaType<IntroducerInviteType> = {
  type: "object",
  properties: {
    businessCase: { type: "string", enum: [BusinessCases.Introducer] },
    previousStakeholders: globalDefinitions.nonEmptyString,
  },
  required: ["businessCase"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
