import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Grid, Popup } from "semantic-ui-react";
import { useSessionApi, useSessionState } from "../../../contexts/Session";
import { isEnrolled } from "../../../contexts/Session/state";
import { Shortcuts as S } from "../../../routing";
import { Tabs as MyAreaTabs } from "../../pages/MyArea";

const offSet: [number, number?] = [0, 14];
const myAreaLink = S.myArea.path;
const tabVarNameMyArea = S.myArea.queryVarNames.tab;

interface Props {
  readonly trigger: JSX.Element;
}
export const MyAreaPopup = ({ trigger }: Props) => {
  const { pathname, search } = useLocation();
  const sessionState = useSessionState();
  const { clear } = useSessionApi();
  const location = `${pathname}${search}`;
  const profileLink = `${myAreaLink}?${tabVarNameMyArea}=${MyAreaTabs.Profile}`;
  const profileCname = location === profileLink ? "MyAreaPopup-link active" : "MyAreaPopup-link";
  const applicationsLink = `${myAreaLink}?${tabVarNameMyArea}=${MyAreaTabs.Applications}`;
  const applicationsCname =
    location === applicationsLink ? "MyAreaPopup-link active" : "MyAreaPopup-link";
  const sentInvitesLink = `${myAreaLink}?${tabVarNameMyArea}=${MyAreaTabs.Sent}`;
  const sentInvitesCname =
    location === sentInvitesLink ? "MyAreaPopup-link active" : "MyAreaPopup-link";

  return (
    <Popup
      className="MyAreaPopup"
      openOnTriggerClick
      closeOnPortalMouseLeave={false}
      closeOnTriggerMouseLeave={false}
      openOnTriggerMouseEnter={false}
      position="right center"
      offset={offSet}
      trigger={trigger}
    >
      <Grid>
        <Grid.Column>
          {isEnrolled(sessionState) && (
            <>
              <Link to={profileLink}>
                <Grid.Row className={profileCname}>Profile</Grid.Row>
              </Link>
              <Link to={applicationsLink}>
                <Grid.Row className={applicationsCname}>Applications</Grid.Row>
              </Link>
              <Link to={sentInvitesLink}>
                <Grid.Row className={sentInvitesCname}>Sent invites</Grid.Row>
              </Link>
            </>
          )}
          {!isEnrolled(sessionState) && (
            <>
              <Link to={applicationsLink}>
                <Grid.Row className={applicationsCname}>Applications</Grid.Row>
              </Link>
              <Link to={profileLink}>
                <Grid.Row className={profileCname}>Profile</Grid.Row>
              </Link>
            </>
          )}

          <Link to="/" onClick={clear}>
            <Grid.Row className="MyAreaPopup-link logout">Logout</Grid.Row>
          </Link>
        </Grid.Column>
      </Grid>
    </Popup>
  );
};
