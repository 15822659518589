import React, { useState, useCallback, CSSProperties } from "react";
import { useApolloClient } from "@apollo/client";
import { Card, Form, Button } from "semantic-ui-react";
import { Queryable, isEmpty, noResultErrorFor, extractErrorMessages } from "../../types";
import { useSessionApi } from "../../contexts/Session";
import { perform } from "../../api/accounts/LinkEmail";
import { FormFieldError } from "../elements/PasswordForm";
import { SessionUser } from "../../types/frontend_only/sessionUser";
import { ErrorMessages } from "../elements/ErrorMessages";

export const wallcontentStyle: CSSProperties = {
  width: "100%",
  textAlign: "left",
};

interface State extends Queryable {
  readonly email: string;
  readonly confirmEmail: string;
}

const initialState: State = { email: "", confirmEmail: "" };
type StateErrors = Record<keyof State, FormFieldError>;
const initialErrors: Partial<StateErrors> = {};

interface Props {
  readonly user: SessionUser;
}
export const LinkEmailWall = ({ user }: Props) => {
  const client = useApolloClient();
  const sessionApi = useSessionApi();
  const [{ email, confirmEmail, busy, errors: remoteErrors }, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState(({ errors: _, ...s }) => ({ ...s, [name]: value }));
    setErrors((s) => ({ ...s, [name]: undefined }));
  }, []);

  const onSubmit = () => {
    let hasErrors = false;

    if (isEmpty(email)) {
      setErrors((s) => ({ ...s, email: { content: "Required field" } }));
      hasErrors = true;
    }
    if (confirmEmail !== email) {
      setErrors((s) => ({ ...s, confirmEmail: { content: "Emails don't match" } }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setState((s) => ({ ...s, busy: true }));

    perform(client, { address: email })
      .then((res) => {
        if (!res.data || !res.data.payload) {
          return Promise.reject(noResultErrorFor("Link Email"));
        }
        const linkedEmails = [...user.linkedEmails, res.data.payload.linkedEmail];
        sessionApi.setPresent({ ...user, linkedEmails });
      })
      .catch((err) => setState((s) => ({ ...s, busy: false, errors: extractErrorMessages(err) })));
  };

  const onCopyAndPaste = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    return false;
  }, []);

  return (
    <Card className="Wall xl">
      <div className="WallContent xl" style={wallcontentStyle}>
        <h1 className="WallContent-subtitle">Connect using your email</h1>
        <br />
        <Form className="WallContent-form" noValidate>
          <Form.Input
            name="email"
            label="Input your email address"
            value={email}
            onChange={onChange}
            required
            error={errors.email}
          />
          <Form.Input
            name="confirmEmail"
            label="Confirm your email address"
            value={confirmEmail}
            onChange={onChange}
            onPaste={onCopyAndPaste}
            onCopy={onCopyAndPaste}
            required
            error={errors.confirmEmail}
          />
          <ErrorMessages errors={remoteErrors} />
          <Button onClick={onSubmit} disabled={busy} color="blue">
            Submit
          </Button>
          <br />
          <br />
        </Form>
      </div>
    </Card>
  );
};
