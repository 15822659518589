import { CSSProperties, useMemo } from "react";
import { DeepPartial } from "uniforms";
import { Button, Divider, Grid } from "semantic-ui-react";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { bridge } from "./EarlyProfessionalOrProminentSkillsInviteSchema";
import { EarlyProfessionalOrProminentSkillsInviteType } from "./EarlyProfessionalOrProminentSkillsInviteSchema";
import { BackButton } from "./BackButton";
import { PageTwoFormType } from "../../components/pages/NominateExpert";
import { NestedTagsField } from "../NestedTagsField";
import { TagNamespace } from "../../types/labelQuery";
import { useCategoriesState } from "../../contexts/Categories";
import { isSuccessState } from "../../contexts/Generic";

const buttonColumnStyle: CSSProperties = { display: "flex" };

interface Props {
  readonly initialModel: DeepPartial<EarlyProfessionalOrProminentSkillsInviteType>;
  readonly onBack: (model: PageTwoFormType) => void;
  readonly onSubmit: (model: EarlyProfessionalOrProminentSkillsInviteType) => void;
}

export const EarlyProfessionalOrProminentSkillsInviteForm = (props: Props) => {
  const categoriesState = useCategoriesState();
  const { initialModel, onBack, onSubmit } = props;

  const [occupationsTree, sectorsTree] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null];
    }

    return [categoriesState.result.occupationsTree, categoriesState.result.sectorsTree];
  }, [categoriesState]);

  return (
    <AutoForm schema={bridge} showInlineError onSubmit={onSubmit} model={initialModel}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <NestedTagsField
              name="occupations"
              label="What services do they provide?"
              placeholder="Select 1 or more occupation tags"
              collapsedText="Occupations"
              options={occupationsTree}
              namespace={TagNamespace.Occupations}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <NestedTagsField
              name="sectors"
              label="In what sectors do they have experience?"
              placeholder="Select 1 or more sector tags"
              collapsedText="Sectors"
              options={sectorsTree}
              namespace={TagNamespace.Sectors}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={buttonColumnStyle}>
            <BackButton onBack={onBack} />
            <Button type="submit" primary content="Submit" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AutoForm>
  );
};
