import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketBidStatus, TicketBidState } from "../../types/bid";

// Mutation to reject a bid, as the TO
export const MUTATION = gql`
  mutation rejectTicketBid($input: RejectTicketBidInput!) {
    payload: rejectTicketBid(input: $input) {
      bid {
        id
        status
        state
        selectedWinnerAt
      }
    }
  }
`;

export interface Variables {
  readonly input: RejectTicketBidInput;
}

interface RejectTicketBidInput {
  readonly bidId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: RejectTicketBidPayload;
}

interface RejectTicketBidPayload {
  readonly bid: {
    readonly status: TicketBidState;
    readonly state: TicketBidStatus;
    readonly selectedWinnerAt: string;
  } & Identifiable;
}
