import { QUERY, Result, Variables } from "../../../../api/tickets/DiscussionThreadsOnIsDiscussable";
import { useQuery } from "@apollo/client";
import { LoaderWithMargin } from "../../../Loader";
import { ErrorMessages } from "../../ErrorMessages";
import { extractErrorMessages, noResultErrorFor } from "../../../../types";
import { nodesFromEdges } from "../../../../types/relay";
import { Question } from "../qna/Question";
import { Enrolled } from "../../../../contexts/Session/state";

interface Props {
  readonly discussableId: string;
  readonly showPrivacyIcon: boolean;
  readonly sessionState: Enrolled;
}

export const DiscussableQuestions = ({ discussableId, sessionState, showPrivacyIcon }: Props) => {
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { discussableId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  if (loading && !data) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.node) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Ticket Questions"))} />;
  }

  const questions = nodesFromEdges(data.node.thread?.edges);

  if (questions.length === 0) {
    return null;
  }

  return (
    <div className="WrapperSection">
      <div className="ComponentHeader">Questions</div>
      {questions.map(q => (
        <Question
          key={q.id}
          question={q}
          discussableMessage={data.node?.seal?.message}
          showPrivacyIcon={showPrivacyIcon}
          sessionState={sessionState}
        />
      ))}
    </div>
  );
};
