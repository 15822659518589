import React, { CSSProperties, MouseEvent, useCallback, useMemo } from "react";
import { AccordionTitleProps, Menu, MenuItem } from "semantic-ui-react";
import { Accordion, AccordionContent, AccordionTitle, Button } from "semantic-ui-react";
import { DeliverableSchema } from "../../../../schemas/tickets/_types";
import { capitalize } from "../../../../types";
import { paragraphToHtml } from "../../../../utils/applications/Formatting";

const noMarginBottom: CSSProperties = { marginBottom: 0 };

export interface Props {
  readonly index: number;
  readonly activeIndex: number;
  readonly isEdit: boolean;
  readonly deliverable: DeliverableSchema;
  readonly selectDropdown: (ev: MouseEvent<HTMLDivElement>, p: AccordionTitleProps) => void;
  readonly deleteDropdown: (idx: number) => void;
  readonly hasErrors?: boolean;
}

const TODAY_AS_NUMBER = Date.parse(new Date().toString());

export const Deliverable = (props: Props) => {
  const { index, activeIndex, isEdit, deliverable, hasErrors } = props;
  const { selectDropdown, deleteDropdown } = props;

  const content = useMemo(() => {
    return (
      <div className={"Collapsible-Description"}>
        {paragraphToHtml(deliverable.description)}
        <br />
      </div>
    );
  }, [deliverable]);

  const onDelete = useCallback(() => deleteDropdown(index), [index, deleteDropdown]);

  return (
    <Accordion
      as={Menu}
      style={noMarginBottom}
      vertical
      fluid
      className={hasErrors ? "formError" : undefined}
    >
      <MenuItem>
        <AccordionTitle
          className={hasErrors ? "formError" : ""}
          active={activeIndex === index}
          index={index}
          onClick={selectDropdown}
          content={
            <div className="Collapsible-Grid">
              <div className="Collapsible-LeftColumn">
                <b>{capitalize(deliverable.title!)}</b>
              </div>

              <div className={"Collapsible-RightColumn"}>
                <div>
                  <b>Proposed deadline: {deliverable.dueDate}</b>
                </div>
              </div>
            </div>
          }
        />
        <AccordionContent
          active={activeIndex === index}
          className={"Collapsible-Content"}
          content={content}
        />
        {isEdit && (
          <AccordionContent
            active={activeIndex === index}
            className={"Collapsible-EditableContent"}
            content={
              <Button type="button" primary className={"Collapsible-DeleteBttn"} onClick={onDelete}>
                Delete
              </Button>
            }
          />
        )}
      </MenuItem>
    </Accordion>
  );
};

export const hasValidDate = (deliverable: DeliverableSchema) =>
  Date.parse(deliverable.dueDate!) - TODAY_AS_NUMBER > 0;
