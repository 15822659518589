import React, { useState, useCallback } from "react";
import { AccordionTitleProps } from "semantic-ui-react";
import { Deliverable as DeliverableComponent } from "./Deliverable";
import { DeliverableSchema } from "../../../../schemas/tickets/_types";
import { utils } from "../../../../utils/utils";

interface Props {
  readonly arrOptions: DeliverableSchema[];
}

export const ViewOnlyCollapsible = (props: Props) => {
  const { arrOptions } = props;
  const [activeIndex, setActiveIndex] = useState(-1);

  const selectCollapsible = useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>, { index }: AccordionTitleProps) => {
      const i = index ? +index : 0;
      setActiveIndex(activeIndex === i ? -1 : i);
    },
    [activeIndex]
  );

  return (
    <>
      {arrOptions.map((s, i) => (
        <DeliverableComponent
          key={i}
          index={i}
          activeIndex={activeIndex}
          isEdit={false}
          deliverable={s}
          selectDropdown={selectCollapsible}
          deleteDropdown={utils.identity}
        />
      ))}
    </>
  );
};
