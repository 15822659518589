import { PublicOnboard } from "../../types/onboard";
import { utils } from "../../utils/utils";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";
import { PreselectionEvaluationScoreOptions } from "./_types";

interface Props {
  readonly onboard: PublicOnboard;
}

export const FeasibilityForm = ({ onboard }: Props) => {
  const name = onboard.fullName || "this startup";
  const possessiveName = utils.withPossessiveApostrophe(name);

  return (
    <>
      <div className="VotingSection-title">
        <div className="VotingSection-title-text">FEASIBILITY</div>
      </div>
      <div className="VotingSection-grid">
        <div className="VotingSection-row">
          <div>
            <p>
              <b>
                How different is {possessiveName} technology stack vs. the market (or anything you
                have encountered)?
              </b>
            </p>
            <p className="VotingSection-scale">
              Scale: 1. Any competent team can build a tech stack that delivers similar business
              benefits. - 5. Impossible to reproduce or deliver the same benefit without it.
            </p>
          </div>
          <CustomDropdownSelectionField
            name="marketDifferentiation"
            label={null}
            placeholder="-"
            search={false}
            multiple={false}
            compact={true}
            dropdownOptions={PreselectionEvaluationScoreOptions}
            errorMessage="This field is mandatory."
          />
        </div>

        <div className="VotingSection-row">
          <div>
            <p>
              <b>Can {name} productise it / make it scalable?</b>
            </p>
            <p className="VotingSection-scale">
              Scale: 1. Scale not feasible with their technology stack. - 5. No technological limits
              to scale and customer acquisition.
            </p>
          </div>
          <CustomDropdownSelectionField
            name="scalability"
            label={null}
            placeholder="-"
            search={false}
            multiple={false}
            compact={true}
            dropdownOptions={PreselectionEvaluationScoreOptions}
            errorMessage="This field is mandatory."
          />
        </div>
      </div>
    </>
  );
};
