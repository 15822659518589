import { Dispatch } from "react";
import { Action, ActionTypes } from "./actions";

export class Actioner {
  private readonly dispatch: Dispatch<Action>;

  constructor(dispatch: Dispatch<Action>) {
    this.dispatch = dispatch;
  }

  hide = () => this.dispatch({ type: ActionTypes.Hide });

  show = (content: JSX.Element) => this.dispatch({ type: ActionTypes.Show, content });
}
