import { createRef, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Segment, Image, Icon, Grid, Dropdown, Divider } from "semantic-ui-react";
import { toast } from "react-toastify";
import { MUTATION, Variables, Result } from "../../../api/accounts/ResendEmailConfirmation";
import { noResultErrorFor, extractErrorMessages } from "../../../types";
import { Shortcuts as S } from "../../../routing";
import { nodesFromEdges } from "../../../types/relay";
import iconShowMore from "../../../assets/icon-show-more.svg";
import { GQLSessionUser } from "../../../types/frontend_only/sessionUser";
import { RoleIcon } from "../../../types/badges";

interface Props {
  readonly user: GQLSessionUser;
}
export const AdminUserCard = ({ user }: Props) => {
  const { id, primaryLinkedEmail, avatarUrl } = user;
  const linkRef = createRef<HTMLAnchorElement>();
  const [resendEmail] = useMutation<Result, Variables>(MUTATION);

  const hasVerifiedEmail = !!primaryLinkedEmail?.verifiedAt;

  const rolesRows = useMemo(() => {
    const roles = nodesFromEdges(user.roles?.edges);

    return roles.map((r) => {
      const { id: roleId, fullName, type, kind } = r;
      const params = new URLSearchParams();
      params.set(S.administrationUser.queryVarNames.id, id);
      params.set(S.administrationUser.queryVarNames.role, roleId);
      const roleUrl = `${S.administrationUser.path}?${params.toString()}`;

      return (
        <Grid.Row key={roleId} id={roleId} className="AdminUserCard-RoleRow">
          <Grid.Column width="16" className="AdminUserCard-RoleCol">
            <Link to={roleUrl}>
              <RoleIcon className="AdminUserCard-RoleCol-img" type={type} kind={kind} />
              <b>{fullName || "unknown"}</b>
            </Link>
          </Grid.Column>
        </Grid.Row>
      );
    });
  }, [id, user.roles]);

  const roleCount = rolesRows.length;

  const resendConfirmation = useCallback(() => {
    if (!primaryLinkedEmail || hasVerifiedEmail) {
      return;
    }

    resendEmail({ variables: { input: { linkedEmailId: primaryLinkedEmail.id } } })
      .then((res) => {
        if (!res.data || !res.data.payload || !res.data.payload.linkedEmail) {
          return Promise.reject(noResultErrorFor("Resend"));
        }
        toast.success("Email sent.");
      })
      .catch((err) => toast.error(extractErrorMessages(err).join(", ")));
  }, [hasVerifiedEmail, primaryLinkedEmail, resendEmail]);

  const userProfileUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.set(S.administrationUser.queryVarNames.id, id);
    return `${S.administrationUser.path}?${params.toString()}`;
  }, [id]);

  const onSendEmail = useCallback(() => linkRef.current?.click(), [linkRef]);

  const emailCol = `AdminUserCard-EmailCol ${!primaryLinkedEmail ? "unconfirmed" : "confirmed"}`;

  return (
    <Segment key={id} className="AdminUserCard">
      <Grid>
        <Grid.Row>
          <Grid.Column width="12" className="AdminUserCard-UserInfoCol">
            <Link to={userProfileUrl}>
              <Image src={avatarUrl} circular className="AdminUserCard-UserInfoCol-Avatar" />
              <div className="AdminUserCard-UserInfoCol-Details">
                <div className="AdminUserCard-UserInfoCol-Details-name">{user.fullName}</div>
                <div className="AdminUserCard-UserInfoCol-Details-roleCount">
                  {!roleCount ? "No roles" : roleCount === 1 ? "1 Role" : `${roleCount} Roles`}
                </div>
              </div>
            </Link>
          </Grid.Column>
          <Grid.Column width="3" className={emailCol}>
            {!!primaryLinkedEmail && <Icon name="check" />}
            {!!primaryLinkedEmail ? "Confirmed email" : "Email unconfirmed"}
          </Grid.Column>
          <Grid.Column width="1">
            <Dropdown floating icon={null} trigger={<Image src={iconShowMore} />}>
              <Dropdown.Menu direction="left">
                <Dropdown.Item>
                  <Link to={userProfileUrl}>
                    <div className="AdminUserCard-DropdownItem">Profile</div>
                  </Link>
                </Dropdown.Item>
                {!hasVerifiedEmail && (
                  <Dropdown.Item onClick={resendConfirmation}>
                    Resend Confirmation Email
                  </Dropdown.Item>
                )}
                {!!primaryLinkedEmail && (
                  <Dropdown.Item onClick={onSendEmail}>
                    <a href={`mailto:${primaryLinkedEmail.address}`} ref={linkRef}>
                      Send email
                    </a>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid.Row>
        {roleCount > 0 && (
          <>
            <Divider className="AdminUserCard-Divider" />
            {rolesRows}
          </>
        )}
      </Grid>
    </Segment>
  );
};
