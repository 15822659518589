import { gql, ApolloClient } from "@apollo/client";
import { Settings } from "../contexts/Settings/state";

export const QUERY = gql`
  query Settings {
    settings
  }
`;

export interface Result {
  readonly settings: Settings;
}

export const perform = (client: ApolloClient<any>) => client.query<Result>({ query: QUERY });
