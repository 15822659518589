import { GraphQLError } from "graphql";
import { ApolloError } from "@apollo/client";
import { Maybe, isString } from "./common";

export interface ApiError extends Readonly<ApolloError> {
  readonly graphQLErrors: ReadonlyArray<GraphQLErrorWithChangeset>;
}

interface GraphQLErrorWithChangeset extends Readonly<GraphQLError> {
  readonly changeset: Changeset | null;
}
interface Changeset {
  readonly action: string;
  readonly errors: ChangesetError;
}
interface ChangesetError {
  readonly [key: string]: ReadonlyArray<string>;
}

// Helper functions
export const extractErrorMessages = (err: Maybe<ApiError | Error>): ReadonlyArray<string> => {
  const messages: string[] = [];
  // Here we're verifying the presence of err because as it comes from an
  // untrusted scope, it might as well be null regardless of our explicit typing.
  if (err) {
    // As our error could be either an ApiError or directly an error, we have to handle
    // differentiation here.
    if ("graphQLErrors" in err && err.graphQLErrors) {
      err.graphQLErrors.forEach((gqlError) => {
        if (gqlError.changeset) {
          const csErrors = gqlError.changeset.errors;
          searchForNestedErrors(messages, "errors", csErrors);
        } else if (gqlError.message) {
          messages.push(gqlError.message);
        }
      });
      if (err.networkError) {
        messages.push(err.networkError.message);
      }
      if (messages.length === 0 && err.message) {
        messages.push(err.message);
      }
    }
    // Very likely that the error is of normal "Error" type but we're unsure.
    else if (err.message) {
      messages.push(err.message);
    }
  }
  return messages.length === 0 ? ["An unknown error has occured."] : messages;
};

const searchForNestedErrors = (errors: string[], key: string, obj: any) => {
  if (Array.isArray(obj)) {
    obj.forEach((message) => {
      if (isString(message)) {
        errors.push(`${key} ${message}`);
      }
    });
  } else if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach((k) => {
      searchForNestedErrors(errors, k, obj[k]);
    });
  }
};

export const noResultErrorFor = (queryName: string): Error =>
  new Error(`Missing results for ${queryName} query.`);
