import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketBidStatus, TicketBidState } from "../../types/bid";

// Mutation for declining to work on a bid, as a Bidder
export const MUTATION = gql`
  mutation DeclineOfferAndVoidBid($input: DeclineOfferAndVoidWinningTicketBidInput!) {
    payload: declineOfferAndVoidWinningTicketBid(input: $input) {
      bid {
        id
        status
        state
        selectedWinnerAt
        voidedExpertDeclinedOfferAt
      }
    }
  }
`;

export interface Variables {
  readonly input: DeclineOfferAndVoidWinningTicketBidInput;
}

interface DeclineOfferAndVoidWinningTicketBidInput {
  readonly bidId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: DeclineTicketBidPayload;
}

interface DeclineTicketBidPayload {
  readonly bid: {
    readonly status: TicketBidState;
    readonly state: TicketBidStatus;
    readonly selectedWinnerAt?: string;
    readonly voidedExpertDeclinedOfferAt?: string;
  } & Identifiable;
}
