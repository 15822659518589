import { DeepPartial } from "uniforms";
import { assertUnreachable, isEnum } from "../../types";

// Enums
export enum PreselectionProposalChoice {
  Yes = "YES",
  No = "NO",
  Neutral = "NEUTRAL",
}

export enum PreselectionEvaluationScore {
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
  Skip = "Skip",
}

export enum DueDiligenceAreas {
  Customer = "CUSTOMER",
  DistributionAndSales = "DISTRIBUTION_AND_SALES",
  Financial = "FINANCIAL",
  GoToMarket = "GO_TO_MARKET",
  Idea = "IDEA",
  Innovation = "INNOVATION",
  Product = "PRODUCT",
  TeamCulture = "TEAM_CULTURE",
  Technology = "TECHNOLOGY",
  Traction = "TRACTION",
}

export enum AbstainReason {
  MissingInformation = "MISSING_INFORMATION",
  NoStrongPosition = "NO_STRONG_POSITION",
  NotQualified = "NOT_QUALIFIED",
  ConflictOfInterest = "CONFLICT_OF_INTEREST",
  Other = "OTHER",
}

// Types.
export type PreselectionVoteSchema = ReviewCommitteeOptionsSchema & ChoiceSchema;

interface Base {
  readonly comment: string; // max 300 chars
  readonly voteChoice: PreselectionProposalChoice;
}

type ChoiceSchema = AbstainVoteSchema | YesNoVoteSchema;

export interface AbstainVoteSchema extends Base {
  readonly voteChoice: PreselectionProposalChoice.Neutral;
  readonly reason: AbstainReason;
}

export interface YesNoVoteSchema extends Base {
  readonly voteChoice: PreselectionProposalChoice.Yes | PreselectionProposalChoice.No;
  readonly criticalPainPoint: PreselectionEvaluationScore;
  readonly traction: PreselectionEvaluationScore;
  readonly marketDifferentiation: PreselectionEvaluationScore;
  readonly scalability: PreselectionEvaluationScore;
  readonly knowledgeOfCustomers: PreselectionEvaluationScore;
  readonly knowledgeOfFinancialMetrics: PreselectionEvaluationScore;
}

type ReviewCommitteeOptionsSchema = ParticipatingInRC | NotParticipatingInRC;

// Helper types and functions to help navigate the multiple form versions.
interface NotParticipatingInRC {
  readonly isReviewCommitteeVolunteer: false;
}

interface ParticipatingInRC {
  readonly isReviewCommitteeVolunteer: true;
  readonly dueDiligenceAreas: ReadonlyArray<DueDiligenceAreas>; // min one item
}

interface YesNoVoteSchemaWithExtraKeys extends YesNoVoteSchema {
  [key: string]: unknown;
}

interface AbstainVoteSchemaWithExtraKeys extends AbstainVoteSchema {
  [key: string]: unknown;
}

interface NotParticipatingInRCWithExtraKeys extends NotParticipatingInRC {
  [key: string]: unknown;
}

export const isYesNoVoteSchema = (
  data: DeepPartial<PreselectionVoteSchema>
): data is YesNoVoteSchemaWithExtraKeys =>
  data.voteChoice === PreselectionProposalChoice.Yes ||
  data.voteChoice === PreselectionProposalChoice.No;

export const isAbstainVoteSchema = (
  data: DeepPartial<PreselectionVoteSchema>
): data is AbstainVoteSchemaWithExtraKeys => data.voteChoice === PreselectionProposalChoice.Neutral;

export const isNotParticipatingInRC = (
  data: DeepPartial<PreselectionVoteSchema>
): data is NotParticipatingInRCWithExtraKeys => !data.isReviewCommitteeVolunteer;

// This function should be called before signing, it will remove extra keys that are stale
// on the model and don't match the current chouices anymore.
export const sanitizePreselectionVoteData = (
  data: PreselectionVoteSchema
): PreselectionVoteSchema => {
  let sanitisedData: PreselectionVoteSchema = data;
  if (isYesNoVoteSchema(sanitisedData)) {
    // Cleans up AbstainVoteSchema only fields : `reason`
    const { reason, ...rest } = sanitisedData;
    sanitisedData = rest;
  } else if (isAbstainVoteSchema(sanitisedData)) {
    // Cleans up YesNoVoteSchema only fields: `criticalPainPoint`, `traction`, `marketDifferentiation`,
    // `scalability`, `knowledgeOfCustomers`, `knowledgeOfFinancialMetrics`
    const {
      criticalPainPoint,
      traction,
      marketDifferentiation,
      scalability,
      knowledgeOfCustomers,
      knowledgeOfFinancialMetrics,
      ...rest
    } = sanitisedData;
    sanitisedData = rest;
  }

  if (isNotParticipatingInRC(sanitisedData)) {
    // Cleans up ParticipatingInRC only fields: `dueDiligenceAreas`
    const { dueDiligenceAreas, ...rest } = sanitisedData;
    sanitisedData = rest;
  }

  return sanitisedData;
};

// Helper functions to make enum types readable.
export const readablePreselectionProposalChoice = (choice: unknown) => {
  if (!isEnum(choice, PreselectionProposalChoice)) {
    return JSON.stringify(choice);
  }

  switch (choice) {
    case PreselectionProposalChoice.Yes:
      return "Yes";
    case PreselectionProposalChoice.No:
      return "No";
    case PreselectionProposalChoice.Neutral:
      return "Abstain";
  }
  assertUnreachable(choice);
};

export const readableScore = (score: PreselectionEvaluationScore) => {
  if (score === PreselectionEvaluationScore.Skip) {
    return "Skipped";
  }
  return score;
};

export const readableAbstainReason = (reason: unknown) => {
  if (!isEnum(reason, AbstainReason)) {
    return JSON.stringify(reason);
  }

  switch (reason) {
    case AbstainReason.MissingInformation:
      return "There isn't enough information for me to make an informed vote.";
    case AbstainReason.NoStrongPosition:
      return "I don't have a strong position on this application.";
    case AbstainReason.NotQualified:
      return "I don't feel qualified to take a position on this application.";
    case AbstainReason.ConflictOfInterest:
      return "I have a conflict of interest and therefore do not wish to vote.";
    case AbstainReason.Other:
      return "Other (Expand below)";
  }
  assertUnreachable(reason);
};

export const readableDueDiligenceAreas = (area: DueDiligenceAreas) => {
  switch (area) {
    case DueDiligenceAreas.Idea:
      return "Idea";
    case DueDiligenceAreas.Product:
      return "Product";
    case DueDiligenceAreas.GoToMarket:
      return "Go-to-market";
    case DueDiligenceAreas.Customer:
      return "Customer";
    case DueDiligenceAreas.DistributionAndSales:
      return "Distribution and Sales";
    case DueDiligenceAreas.Financial:
      return "Financial";
    case DueDiligenceAreas.Technology:
      return "Technology";
    case DueDiligenceAreas.Innovation:
      return "Innovation";
    case DueDiligenceAreas.Traction:
      return "Traction";
    case DueDiligenceAreas.TeamCulture:
      return "Team culture";
  }
  assertUnreachable(area);
};

export const readableisReviewCommitteeVolunteer = (v: unknown): string => {
  switch (v) {
    case true:
      return "Yes, I'd like to participate.";
    case false:
      return "No, thanks.";
    default:
      return JSON.stringify(v);
  }
};

export const DueDiligenceAreasList = Object.values(DueDiligenceAreas).map((a) => ({
  value: a,
  text: readableDueDiligenceAreas(a),
}));

export const PreselectionEvaluationScoreOptions = Object.values(PreselectionEvaluationScore).map(
  (v) => ({ value: v, text: v })
);
