import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { Shortcuts as S } from "../../../../routing";
import dateFormat from "dateformat";
import { TickettingBidWithTicket } from "../../../../api/tickets/MyBids";
import { TextClamp } from "../../TextClamp";

interface Props {
  readonly bid: TickettingBidWithTicket;
}

export const BidDraftCard = (props: Props) => {
  const history = useHistory();
  const { bid } = props;

  const onEdit = useCallback(() => {
    const id = bid.ticket.id;
    const search = new URLSearchParams({ [S.submitBid.queryVarNames.id]: id }).toString();
    history.push({ pathname: S.submitBid.path, search });
  }, [history, bid]);

  return (
    <div className="BidCard draft">
      <div className="top-section">
        <div className="top-section-title" onClick={onEdit}>
          <TextClamp text={bid.ticket.ticketForm.data.title} maxLines={2} />
        </div>
        <div className="top-section-labels">
          <div className="top-section-labels-date">
            {dateFormat(bid.publishedAt, "HH:MM | dd/mm/yy")}
          </div>
          <div className="StatusLabel gray">Draft</div>
          <div className="top-section-labels-edit" onClick={onEdit}>
            <Icon name="edit" />
            Edit
          </div>
        </div>
      </div>
    </div>
  );
};
