import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageContentInfinityScroll } from "../../InfinityScrollWrapper";
import { Enrolled } from "../../../contexts/Session/state";
import { TickettingTicket } from "../../../types/ticket";
import { TicketBidStatus } from "../../../types/bid";
import { QUERY, Result, Variables } from "../../../api/tickets/MyProjects";
import { LoaderWithMargin } from "../../Loader";
import { TicketCard } from "../../elements/tickets/ticket/TicketCard";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { nodesFromEdges } from "../../../types/relay";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { TabTitles } from "./index";

const initialTickets = undefined as ReadonlyArray<TickettingTicket> | undefined;
const first: number = 10;
const status: ReadonlyArray<TicketBidStatus> = [
  TicketBidStatus.SelectedWinnerAcknowledged,
  TicketBidStatus.ConfirmedWinner,
];
interface Props {
  readonly sessionState: Enrolled;
}

export const MyProjects = ({ sessionState: { roleId } }: Props) => {
  const breadcrumbApi = useBreadcrumbApi();
  const [ticketsQuery, ticketsData] = useLazyQuery<Result, Variables>(QUERY, {
    fetchPolicy: "network-only",
  });
  const { data, error: ticketsError } = ticketsData;
  const { edges, pageInfo } = data?.role?.bidConnection || {};
  const [tickets, setTickets] = useState(initialTickets);

  // Query tickets when the roleId changes.
  useEffect(() => {
    ticketsQuery({ variables: { first, roleId, status } });
  }, [roleId, ticketsQuery]);

  useEffect(() => {
    breadcrumbApi.addCustomTitle(TabTitles.MyProjects);

    return () => breadcrumbApi.removeCustomTitle();
  }, [breadcrumbApi]);

  // Convert edges to nodes and save them to state everytime query results change.
  useEffect(() => {
    if (!data || !data.role || !data.role.bidConnection) {
      return;
    }
    const t = nodesFromEdges(data.role.bidConnection.edges).map((bid) => bid.ticket);
    setTickets((s) => (s ? [...s, ...t] : t));
  }, [data]);

  const fetchMore = useCallback(() => {
    if (pageInfo?.hasNextPage) {
      ticketsQuery({
        variables: { first, after: pageInfo.endCursor, roleId, status },
      });
    }
  }, [ticketsQuery, pageInfo, roleId]);

  const ticketList = useMemo(() => {
    if (tickets === undefined || tickets?.length === 0) {
      return <>You are not currently working on any tickets.</>;
    }
    return tickets.map((t) => (
      <TicketCard key={t.id} ticket={t} isOwner={false} isWinningBidder={true} />
    ));
  }, [tickets]);

  if (ticketsError) {
    return <ErrorMessages errors={[ticketsError.message]} />;
  }

  return (
    <>
      <div className="ComponentHeader">Tickets</div>
      <PageContentInfinityScroll
        dataLength={edges?.length || 0}
        next={fetchMore}
        hasMore={!!pageInfo?.hasNextPage}
        loader={<LoaderWithMargin />}
      >
        {ticketList}
      </PageContentInfinityScroll>
    </>
  );
};
