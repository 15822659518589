import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { PageContentInfinityScroll } from "../../InfinityScrollWrapper";
import { Input } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { QUERY, Result, Variables, Filters } from "../../../api/network/experts";
import { LoaderWithMargin } from "../../Loader";
import { nodesFromEdges } from "../../../types/relay";
import { ExpertCard } from "./ExpertCard";
import { OptionsFilter, OptionsFilterOps } from "../../elements/OptionsFilter";
import { RoleSortFields, SortDirection } from "../../../types/roleSort";
import { NestedTags } from "../../NestedTags";
import { isSuccessState } from "../../../contexts/Generic";
import { useCategoriesState } from "../../../contexts/Categories";
import { TagNamespace } from "../../../types/labelQuery";

const first: number = 15;

const defaultSortOption: OptionsFilterOps = {
  label: "Alphabetical - Ascending",
  value: { direction: SortDirection.ASC, field: RoleSortFields.NAME },
};
const sortOptions: OptionsFilterOps[] = [
  defaultSortOption,
  {
    label: "Alphabetical - Descending",
    value: { direction: SortDirection.DESC, field: RoleSortFields.NAME },
  },
  {
    label: "Date joined - Ascending",
    value: { direction: SortDirection.ASC, field: RoleSortFields.INSERTED_AT },
  },
  {
    label: "Date joined - Descending",
    value: { direction: SortDirection.DESC, field: RoleSortFields.INSERTED_AT },
  },
];

const debounceTimeout = 200;

const initialFilters: Filters = { sortBy: defaultSortOption.value };

export const ExpertsTab = () => {
  const categoriesState = useCategoriesState();
  const [filters, setFilters] = useState(initialFilters);
  const [sortOption, setSortOption] = useState(defaultSortOption);

  const [debounceSearch] = useDebounce(filters.fulltext, debounceTimeout);

  const { data, loading, fetchMore } = useQuery<Result, Variables>(QUERY, {
    variables: { first, ...sanitizeFilters({ ...filters, fulltext: debounceSearch }) },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
  });
  const { edges, pageInfo } = data?.expertsConnection || {};

  const [occupations, maturities, sectors, skills] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null, null, null];
    }
    const { occupationsTree, maturitiesTree, sectorsTree, skillsTree } = categoriesState.result;

    return [occupationsTree, maturitiesTree, sectorsTree, skillsTree];
  }, [categoriesState]);

  const onMaturityChange = useCallback((m: string[]) => {
    setFilters((s) => ({ ...s, maturities: m }));
  }, []);
  const onOccupationChange = useCallback((o: string[]) => {
    setFilters((s) => ({ ...s, occupations: o }));
  }, []);
  const onSectorChange = useCallback((sct: string[]) => {
    setFilters((s) => ({ ...s, sectors: sct }));
  }, []);
  const onSkillChange = useCallback((sk: string[]) => {
    setFilters((s) => ({ ...s, skills: sk }));
  }, []);
  const onFulltextChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 50) {
      return;
    }
    setFilters((s) => ({ ...s, fulltext: target.value }));
  }, []);
  const onClearFilters = useCallback(() => setFilters(initialFilters), []);

  const onNext = useCallback(() => {
    if (fetchMore && pageInfo?.hasNextPage) {
      fetchMore({ variables: { first, after: pageInfo.endCursor, ...sanitizeFilters(filters) } });
    }
  }, [fetchMore, pageInfo, filters]);

  const onSortByChange = useCallback((option: OptionsFilterOps) => {
    setFilters((s) => ({ ...s, sortBy: option.value }));
    setSortOption(option);
  }, []);

  const experts = useMemo(() => {
    if (!data || !data.expertsConnection) {
      return;
    }
    return nodesFromEdges(data.expertsConnection.edges);
  }, [data]);

  const expertList = useMemo(() => {
    if (experts?.length === 0) {
      return <>No experts matched your search criteria.</>;
    }
    return experts?.map((e) => <ExpertCard key={e.id} expert={e} />);
  }, [experts]);

  return (
    <>
      <div className="PageFilters">
        <div className="PageFilters-left">
          {maturities && (
            <NestedTags
              collapsedText="Experience with"
              placeholder="Experience with"
              checked={filters.maturities}
              onFilter={onMaturityChange}
              tags={maturities}
              namespace={TagNamespace.Maturities}
            />
          )}
          {sectors && (
            <NestedTags
              collapsedText="Sectors"
              placeholder="Sectors"
              checked={filters.sectors}
              onFilter={onSectorChange}
              tags={sectors}
              namespace={TagNamespace.Sectors}
            />
          )}
          {occupations && (
            <NestedTags
              collapsedText="Occupations"
              placeholder="Occupations"
              checked={filters.occupations}
              onFilter={onOccupationChange}
              tags={occupations}
              namespace={TagNamespace.Occupations}
            />
          )}
          {skills && (
            <NestedTags
              collapsedText="Skills"
              placeholder="Skills"
              checked={filters.skills}
              onFilter={onSkillChange}
              tags={skills}
              namespace={TagNamespace.Skills}
            />
          )}
          <div className="PageFilters-clear-link" onClick={onClearFilters}>
            Clear
          </div>
        </div>
        <div className="PageFilters-right">
          <Input
            className="SearchInput"
            icon="search"
            iconPosition="left"
            placeholder="Search by name or headline"
            value={filters.fulltext || ""}
            onChange={onFulltextChange}
          />
        </div>
      </div>
      <OptionsFilter options={sortOptions} selectedOption={sortOption} onChange={onSortByChange} />
      {loading && !data && <LoaderWithMargin />}
      <PageContentInfinityScroll
        dataLength={edges?.length || 0}
        next={onNext}
        hasMore={!!pageInfo?.hasNextPage}
        loader={loading ? <LoaderWithMargin /> : null}
      >
        <div className="CardWrapper">{expertList}</div>
      </PageContentInfinityScroll>
    </>
  );
};

const sanitizeFilters = (f: Filters): Filters => ({
  ...f,
  fulltext: f.fulltext && f.fulltext.length >= 3 ? f.fulltext : undefined,
  sectors: f.sectors?.length === 0 ? undefined : f.sectors,
  occupations: f.occupations?.length === 0 ? undefined : f.occupations,
  skills: f.skills?.length === 0 ? undefined : f.skills,
  maturities: f.maturities?.length === 0 ? undefined : f.maturities,
});
