import { assertUnreachable } from "../../../types";
import { EntityType } from "../../../types/onboard";
import { FundraisingInfo } from "../../../types/OnboardForm";
import { FormDocument } from "../../_types";
import { OnfidoCountries, USAStates } from "./OnfidoCountries";

export interface POASchema {
  readonly dob: string;
  readonly street: string;
  readonly town: string;
  readonly postcode: string;
  readonly country: OnfidoCountries;
  readonly state: USAStates | null;
}

// STEP 1 for Providers
export interface TermsAndConditions {
  readonly agreesToTerms: boolean;
  readonly agreesToMemorandum: boolean;
  readonly providerData: IndividualTermsData;
}

export interface IndividualTermsData {
  readonly agreesToQualifiedInvestor: boolean | null;
}

// STEP 1 for Startups
export interface TermsAndConditionsStartup {
  readonly agreesToTerms: boolean;
  readonly agreesToMemorandum: boolean;
}

// STEP 2 for Individual Providers (see `InitialProfileType` in `InitialProfileSchema.ts`)
export enum StartupsWorkedWith {
  None = "NONE",
  OneToThree = "ONE_TO_THREE",
  FourToTen = "FOUR_TO_TEN",
  TenToFifty = "TEN_TO_FIFTY",
  MoreThanFifty = "MORE_THAN_FIFTY",
}

export const readableStartupsWorkedWith = (v: StartupsWorkedWith): string => {
  switch (v) {
    case StartupsWorkedWith.None:
      return "none";
    case StartupsWorkedWith.OneToThree:
      return "1-3";
    case StartupsWorkedWith.FourToTen:
      return "4-10";
    case StartupsWorkedWith.TenToFifty:
      return "10-50";
    case StartupsWorkedWith.MoreThanFifty:
      return ">50";
  }
  assertUnreachable(v);
};

export enum YearlyInvestment {
  LessThanFiftyK = "LESS_THAN_FIFTY_K",
  FiftyKToOneM = "FIFTY_K_TO_ONE_M",
  MoreThanOneM = "MORE_THAN_ONE_M",
}

export const readableYearlyInvestment = (v: YearlyInvestment): string => {
  switch (v) {
    case YearlyInvestment.LessThanFiftyK:
      return "<£50k";
    case YearlyInvestment.FiftyKToOneM:
      return "£50K - £1m";
    case YearlyInvestment.MoreThanOneM:
      return ">£1m";
  }
  assertUnreachable(v);
};

// STEP 2 for startups
export interface StartupData {
  readonly entityType: EntityType.Organisation;
  readonly name: string;
  readonly summary: string;
  readonly headquarters: string[];
  readonly vision: string;
  readonly team: string;
  readonly usp: string;
  readonly roadmap: string;
  readonly pitchHighlights: string[];
  readonly pitchDeck: FormDocument | null;
  readonly maturity: string;
  readonly sectors: string[];
  readonly logo: FormDocument | null;
  readonly fundraisingInfo: FundraisingInfo;
  readonly valuation: number;
  readonly hasFixedValuation: boolean;
  readonly companiesHouse: string;
  readonly equityOffered: number | null;
  readonly amountRaised: number | null;
  readonly hasLeadInvestor: boolean | null;
}

export interface StartupLinks {
  readonly facebook: string | null;
  readonly instagram: string | null;
  readonly linkedin: string | null;
  readonly twitter: string | null;
  readonly website: string | null;
}

export interface PreselectionForm {
  readonly startupData: StartupData;
  readonly links: StartupLinks | null;
}

// STEP 3 for all
export interface Ethereum {
  readonly ethAddress: string;
}
