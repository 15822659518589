import { gql, ApolloClient } from "@apollo/client";
import { GQLSession } from "../../types/session";
import { SESSION_FRAGMENT } from "../_fragments/Session";

export const MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    payload: createUser(input: $input) {
      session {
        ...Session
      }
    }
  }
  ${SESSION_FRAGMENT}
`;

interface CreateUserInput {
  readonly linkedinSessionId: string;
}

export interface Variables {
  readonly input: CreateUserInput;
}

export interface Result {
  readonly payload?: { readonly session: GQLSession };
}

export const perform = (client: ApolloClient<any>, input: CreateUserInput) =>
  client.mutate<Result, Variables>({ mutation: MUTATION, variables: { input } });
