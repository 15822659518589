import { useCallback, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
// Constants and helpers.
import { Shortcuts as S } from "../routing";
// Page blocks.
import { LeftMenu } from "./elements/LeftMenu";
import { Breadcrumbs } from "./elements/Breadcrumbs";
import { Footer } from "./elements/Footer";
import { LogedInRoute, EnrolledRoute, EnrolledOrWall } from "./elements/walls/session";
import { LinkedOrWall } from "./elements/walls/chain";
// Routable components.
import { ClaimInvite } from "./pages/ClaimInvite";
import { NominateExpert } from "./pages/NominateExpert";
import { InitiatePasswordReset } from "./pages/SignInUp/InitiatePasswordReset";
import { ResetPassword } from "./pages/SignInUp/ResetPassword";
import { Portfolio } from "./pages/Startups/Portfolio";
import { VerifyEmail } from "./pages/VerifyEmail";
import { MySprint } from "./pages/MySprint";
import { TicketForm } from "../schemas/tickets/TicketForm";
import { MyWork } from "./pages/MyWork";
import { changeLeftMenuState, isLeftMenuOpen, selectedRole } from "../contexts/Session/helpers";
import { isProviderRole, isStartupRole } from "../types/role";
import { Marketplace } from "./pages/Marketplace";
import { Wallet } from "./pages/Dashboard/wallet";
import { ApplicationPage } from "./pages/Dashboard/applications/application";
import { StartupDetails } from "./pages/Startups/StartupDetails";
import { TicketDashboard } from "./elements/tickets/ticket/TicketDashboard";
import { PublishedBidDetails } from "./elements/tickets/bid/PublishedBidDetails";
import { BidForm } from "../schemas/bids/BidForm";
import { Administration } from "./pages/Administration/index";
import { NomineeVoting } from "./pages/NomineeVoting/_index";
import { LinkedInCallback } from "./pages/LinkedInCallback";
import { PublishedDeliverablesPlan } from "./elements/tickets/plan/PublishedDeliverablesPlan";
import { DeliverablesPlanForm } from "../schemas/deliverablesPlan/DeliverablesPlanForm";
import { Network } from "./pages/Network";
import { MattermostUserCreationForm as CreateMattermost } from "../schemas/mattermost/MattermostUserCreationForm";
import { GlobalSubscriptionListener } from "./subscriptions/GlobalSubscriptionListener";
import { MyArea } from "./pages/MyArea";
import { isAtLeastPresent } from "../contexts/Session/state";
import { useSessionState } from "../contexts/Session";
import { CommunityResources } from "./pages/CommunityResources";
import { Invite } from "./pages/Invite";
import { InviteStartup } from "./pages/InviteStartup";
import { ExpertOnboarding } from "./pages/ExpertOnboarding";
import { ViewAllExpertOnboards } from "./pages/ExpertOnboarding/ViewAllExpertOnboards";
import { ExpertDetails } from "./pages/ExpertOnboarding/ExpertDetails/_index";
import { StartupDealflow } from "./pages/StartupDealflow";
import { ViewAllStartupOnboards } from "./pages/StartupDealflow/ViewAllStartupOnboards";
import { usePageContentProvider } from "../contexts/PageContent/Provider";

enum BodyState {
  Collapsed = "BodyWrapper--collapsed",
  Expanded = "BodyWrapper--expanded",
}

export const App = () => {
  const history = useHistory();
  const sessionState = useSessionState();
  const [state, setState] = useState(
    isLeftMenuOpen() ? [BodyState.Expanded] : [BodyState.Collapsed]
  );
  const { pageContentCName, pageContentId } = usePageContentProvider();
  const onChevronClick = useCallback(() => {
    changeLeftMenuState();
    setState((s) => [s.includes(BodyState.Expanded) ? BodyState.Collapsed : BodyState.Expanded]);
  }, []);

  return (
    <div
      className={
        isAtLeastPresent(sessionState) ? `BodyWrapper ${state.join(" ")}` : "BodyWrapper-NewUser"
      }
    >
      {isAtLeastPresent(sessionState) && <GlobalSubscriptionListener sessionState={sessionState} />}
      {isAtLeastPresent(sessionState) && <LeftMenu onChevronClick={onChevronClick} />}

      <Breadcrumbs />
      <div id={pageContentId} className={pageContentCName}>
        <Grid className="PageContent-app">
          <Grid.Row>
            <Grid.Column>
              <Switch>
                {/* Public routes. */}
                <Route
                  exact
                  path={S.initiatePasswordReset.path}
                  component={InitiatePasswordReset}
                />
                <Route exact path={S.resetPassword.path} component={ResetPassword} />
                <Route exact path={S.verifyEmail.path} component={VerifyEmail} />
                <Route exact path={S.linkedInCallback.path} component={LinkedInCallback} />

                <LogedInRoute
                  exact
                  path={S.mattermostUserCreation.path}
                  component={CreateMattermost}
                />
                <LogedInRoute exact path={S.claimInvite.path} component={ClaimInvite} />
                <LogedInRoute exact path={S.nominateExpert.path} component={NominateExpert} />
                <LogedInRoute exact path={S.inviteStartup.path} component={InviteStartup} />
                <LogedInRoute exact path={S.application.path} component={ApplicationPage} />
                <LogedInRoute exact path={S.myArea.path} component={MyArea} />

                {/* Routes that require authentication. */}
                <EnrolledRoute exact path={S.wallet.path}>
                  <EnrolledOrWall>
                    {(ss) => (
                      <LinkedOrWall>
                        {(cs) => <Wallet sessionState={ss} chainState={cs} />}
                      </LinkedOrWall>
                    )}
                  </EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.network.path}>
                  <EnrolledOrWall>{() => <Network />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.invite.path}>
                  <EnrolledOrWall>{() => <Invite />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.portfolio.path}>
                  <EnrolledOrWall>{() => <Portfolio />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.preselection.path}>
                  <EnrolledOrWall>{() => <Redirect to={S.startupDealflow.path} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.generalAssessment.path}>
                  <EnrolledOrWall>{() => <Redirect to={S.startupDealflow.path} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.startupDealflow.path}>
                  <EnrolledOrWall>{() => <StartupDealflow />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.startupDealflowBeingReviewed.path}>
                  <EnrolledOrWall>{() => <ViewAllStartupOnboards />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.startupDealflowPreSelection.path}>
                  <EnrolledOrWall>{() => <ViewAllStartupOnboards />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.startupDealflowGeneralAssessment.path}>
                  <EnrolledOrWall>{() => <ViewAllStartupOnboards />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.startupDealflowUnsuccessful.path}>
                  <EnrolledOrWall>{() => <ViewAllStartupOnboards />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.expertOnboarding.path}>
                  <EnrolledOrWall>{() => <ExpertOnboarding />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.expertOnboarding.children.awaitingSeconder.path}>
                  <EnrolledOrWall>{() => <ViewAllExpertOnboards />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.expertOnboarding.children.mutualAssessment.path}>
                  <EnrolledOrWall>{() => <ViewAllExpertOnboards />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.expertOnboarding.children.cbcVoting.path}>
                  <EnrolledOrWall>{() => <ViewAllExpertOnboards />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.startup.path}>
                  <EnrolledOrWall>{() => <StartupDetails />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.expert.path}>
                  <EnrolledOrWall>{(ss) => <ExpertDetails sessionState={ss} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.nomineeVotingExpert.path}>
                  <EnrolledOrWall>{(ss) => <ExpertDetails sessionState={ss} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.marketplace.path}>
                  <EnrolledOrWall>{(ss) => <Marketplace sessionState={ss} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.ticket.path}>
                  <EnrolledOrWall>{(ss) => <TicketDashboard sessionState={ss} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.submitPlan.path}>
                  <EnrolledOrWall>
                    {(ss) => <DeliverablesPlanForm sessionState={ss} />}
                  </EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.reviewPlan.path}>
                  <EnrolledOrWall>
                    {(ss) => <PublishedDeliverablesPlan sessionState={ss} />}
                  </EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.mySprint.path}>
                  <EnrolledOrWall>
                    {(ss) => {
                      if (!isStartupRole(selectedRole(ss.user.roles, ss.roleId))) {
                        history.replace(S.myWork.path);
                        return <></>;
                      }
                      return <MySprint sessionState={ss} />;
                    }}
                  </EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.draftTicket.path}>
                  <EnrolledOrWall>{(ss) => <TicketForm sessionState={ss} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.submitBid.path}>
                  <EnrolledOrWall>{(ss) => <BidForm sessionState={ss} />}</EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.bid.path}>
                  <EnrolledOrWall>
                    {(ss) => <PublishedBidDetails sessionState={ss} />}
                  </EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute exact path={S.myWork.path}>
                  <EnrolledOrWall>
                    {(ss) => {
                      if (!isProviderRole(selectedRole(ss.user.roles, ss.roleId))) {
                        history.replace(S.mySprint.path);
                        return <></>;
                      }
                      return <MyWork sessionState={ss} />;
                    }}
                  </EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute path={S.nomineeVoting.path}>
                  <EnrolledOrWall>
                    {(ss) => (
                      <LinkedOrWall>{() => <NomineeVoting sessionState={ss} />}</LinkedOrWall>
                    )}
                  </EnrolledOrWall>
                </EnrolledRoute>
                <EnrolledRoute path={S.administration.path}>
                  <EnrolledOrWall>
                    {() => <LinkedOrWall>{() => <Administration />}</LinkedOrWall>}
                  </EnrolledOrWall>
                </EnrolledRoute>

                <EnrolledRoute
                  exact
                  path={S.communityResources.path}
                  component={CommunityResources}
                />

                {/* Fallback. */}
                <Redirect from="*" to={S.myArea.path} />
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Footer />
      </div>
    </div>
  );
};
