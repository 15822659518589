import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { QUERY, Variables, Result } from "../../../../api/tickets/TicketById";
import { TicketState } from "../../../../types/ticket";
import { TicketCard } from "./TicketCard";
import { TicketDraftCard } from "./TicketDraftCard";

interface Props {
  readonly relatedTicketIds: ReadonlyArray<string>;
  readonly isOwner: boolean;
  readonly isWinningBidder: boolean;
}

// TODO: add a better loading state when the backend provides support to fetch related tickets in one go.
export const RelatedTickets = ({ relatedTicketIds, isOwner, isWinningBidder }: Props) => {
  const [hiddenTickets, setHiddenTickets] = useState<ReadonlyArray<string>>([]);

  const hideTicket = useCallback(
    (ticketId: string) => {
      if (!hiddenTickets.includes(ticketId)) {
        setHiddenTickets((s) => [...s, ticketId]);
      }
    },
    [hiddenTickets]
  );

  if (relatedTicketIds.length === hiddenTickets.length) {
    return null;
  }

  return (
    <div className="WrapperSection">
      <div className="ComponentHeader">Related Tickets</div>
      {relatedTicketIds.map((id) => (
        <RelatedTicket
          key={id}
          ticketId={id}
          isOwner={isOwner}
          hideTicket={hideTicket}
          isWinningBidder={isWinningBidder}
        />
      ))}
    </div>
  );
};

interface RelatedTicketProps {
  readonly ticketId: string;
  readonly isOwner: boolean;
  readonly isWinningBidder: boolean;
  readonly hideTicket: (ticketId: string) => void;
}
const RelatedTicket = (props: RelatedTicketProps) => {
  const { ticketId, isOwner, isWinningBidder, hideTicket } = props;
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { ticketId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const ticket = useMemo(() => data?.payload, [data]);
  const needsToBeHidden = useMemo(() => {
    if (!ticket) {
      return false;
    }
    const { state, draft } = ticket;

    // Hide related tickets that aren't past the `Awaiting_funds` if the viewer is not the owner.
    if (!isOwner && (state === TicketState.Draft || state === TicketState.Awaiting_funds)) {
      return true;
    } else if (state === TicketState.Draft && !draft) {
      // Hide related tickets that are `Draft` but dont have a draft form.
      return true;
    }
  }, [isOwner, ticket]);

  useEffect(() => {
    if (ticket && needsToBeHidden) {
      hideTicket(ticket.id);
      return;
    }
  }, [needsToBeHidden, ticket, hideTicket]);

  if (loading || error || !ticket || needsToBeHidden) {
    return null;
  } else if (ticket.state === TicketState.Draft && ticket.draft) {
    return <TicketDraftCard ticketId={ticket.id} draftTicket={ticket.draft} />;
  }

  return <TicketCard ticket={ticket} isOwner={isOwner} isWinningBidder={isWinningBidder} />;
};
