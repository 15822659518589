import { createRef, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import dateFormat from "dateformat";
import { Header, Grid, GridColumn, Ref } from "semantic-ui-react";
import { Ticket } from "../../../../../api/tickets/GetTicketDetailsInfo";
import { Shortcuts as S } from "../../../../../routing";
import { ViewOnlyCollapsible } from "../../collapsible";
import { ActionButtons } from "../actionButtons";
import { AskQuestion } from "../../AskQuestion";
import { ExpertAttributes } from "../../../../../schemas/tickets/_enums";
import { readableTicketAnswer } from "../../../../../schemas/tickets/_enums";
import { readableTicketEngagementLevel } from "../../../../../schemas/tickets/_enums";
import { readableTicketExecutionDate } from "../../../../../schemas/tickets/_enums";
import { readableTicketImportance } from "../../../../../schemas/tickets/_enums";
import { readableExpertAttributes } from "../../../../../schemas/tickets/_enums";
import { TicketSummary } from "../../TicketSummary";
import { TicketDenyList } from "../TicketDenyList";
import { ErrorMessages } from "../../../ErrorMessages";
import { extractErrorMessages, noResultErrorFor } from "../../../../../types";
import { paragraphToHtml } from "../../../../../utils/applications/Formatting";
import { Enrolled } from "../../../../../contexts/Session/state";
import { DiscussableQuestions } from "../DiscussableQuestions";
import { RelatedTickets } from "../RelatedTickets";
import { useBreadcrumbApi } from "../../../../../contexts/Breadcrumb";
import { isSuccessState } from "../../../../../contexts/Generic";
import { useCategoriesState } from "../../../../../contexts/Categories";
import { DocumentBadge } from "../../../DocumentBadge";
import { TicketStatus } from "../../../../../types/ticket";

export const refetchParam = "refetch";

interface Props {
  readonly sessionState: Enrolled;
  readonly ticket: Ticket;
}

export const TicketDetails = ({ sessionState, ticket }: Props) => {
  const roleId = sessionState.roleId;
  const location = useLocation();
  const breadcrumbApi = useBreadcrumbApi();
  const categoriesState = useCategoriesState();
  const ticketId = new URLSearchParams(location.search).get(S.ticket.queryVarNames.id) || "";

  const isOwner = ticket?.owner.id === roleId;
  const isWinningBidder = ticket?.winningBid?.owner.id === roleId;
  const contextRef = createRef<HTMLElement>();

  const [occupationLabels, skillLabels] = useMemo(() => {
    if (!ticket?.ticketForm?.data || !isSuccessState(categoriesState)) {
      return [[], []];
    }
    const { occupations: occ, skills: sks } = ticket.ticketForm.data;

    const tmpOccupationLabels = occ.reduce((acc, value) => {
      const label = categoriesState.result.occupationsMapping[value];
      return label ? [...acc, label] : acc;
    }, [] as string[]);

    const tmpSkillLabels = !sks
      ? []
      : sks.reduce((acc, value) => {
          const label = categoriesState.result.skillsMapping[value];
          return label ? [...acc, label] : acc;
        }, [] as string[]);

    return [tmpOccupationLabels, tmpSkillLabels];
  }, [ticket, categoriesState]);

  useEffect(() => {
    if (!ticket || !ticket.ticketForm) {
      return;
    }
    const { ticketForm, status: st } = ticket;
    const customTitle = `${ticketForm.data.title} - Details`;
    if (st === TicketStatus.Executing) {
      return;
    }
    const body = <h2>{ticketForm.data.title}</h2>;
    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(body);

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [ticket, breadcrumbApi, isOwner, sessionState]);

  if (!ticket.ticketForm) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Ticket"))} />;
  }

  const form = ticket.ticketForm.data;
  const { status, hydratedAt } = ticket;
  const { acceptanceCriteria, additionalInformation, deliverables, duration, scope } = form;
  const { engagementLevel, executionDate, expertAttributes, importance } = form;
  const { isOpenToRemoteWork, skillsAdditionalInfo } = form;
  const { relatedTickets, title, terms, additionalDocuments } = form;
  const ownerName = ticket.owner.fullName;

  const isExecuting = status === TicketStatus.Executing;

  return (
    <Grid columns="16">
      <GridColumn width={isExecuting ? 16 : 10}>
        <Ref innerRef={contextRef}>
          <>
            {relatedTickets && (
              <RelatedTickets
                relatedTicketIds={relatedTickets}
                isOwner={isOwner}
                isWinningBidder={isWinningBidder}
              />
            )}
            {!isOwner && (
              <DiscussableQuestions
                discussableId={ticketId}
                showPrivacyIcon={true}
                sessionState={sessionState}
              />
            )}
            <div className="WrapperSection">
              <div className="ComponentHeader">
                <span>Details</span>
                <span className="ComponentHeader-extra">
                  Published on {dateFormat(hydratedAt, 'dS mmmm yyyy "at" HH:MM')}
                </span>
              </div>
              <Header size="large">Description</Header>
              {paragraphToHtml(scope)}
              <Header size="large">Objectives</Header>
              <Popup
                position="bottom center"
                size="small"
                content="Expected start date"
                trigger={
                  <div className="Ticket-labels blue">
                    {readableTicketExecutionDate(executionDate)}
                  </div>
                }
              />
              <Popup
                position="bottom center"
                size="small"
                content="Level of priority"
                trigger={
                  <div className="Ticket-labels red">{readableTicketImportance(importance)}</div>
                }
              />
              <Header size="medium">Acceptance criteria</Header>
              {paragraphToHtml(acceptanceCriteria)}
              {!isExecuting && (
                <>
                  <Header size="large">Deliverables ({deliverables.length})</Header>
                  <ViewOnlyCollapsible arrOptions={deliverables} />
                </>
              )}
              <Header size="large">Audience</Header>
              {occupationLabels.length > 0 && (
                <>
                  <Header size="small">Occupations</Header>
                  {occupationLabels.map((s, i) => (
                    <div className="Ticket-labels blue" key={i}>
                      {s}
                    </div>
                  ))}
                </>
              )}
              <Header size="small">Engagement type</Header>
              <div className="Ticket-labels blue">
                {readableTicketEngagementLevel(engagementLevel)}
              </div>
              <Header size="small">Engagement length</Header>
              <div className="Ticket-labels blue">{readableTicketExecutionDate(duration)}</div>
              {skillLabels.length > 0 && (
                <>
                  <Header size="small">Technical skills</Header>
                  {skillLabels.map((t, i) => (
                    <div className="Ticket-labels blue" key={i}>
                      {t}
                    </div>
                  ))}
                </>
              )}
              <Header size="small">Expert attributes</Header>
              {expertAttributes.map((s, i) => (
                <div className="Ticket-labels blue" key={i}>
                  {readableExpertAttributes(s as ExpertAttributes)}
                </div>
              ))}
              <Header size="small">Remote work</Header>
              <div className="Ticket-labels blue">{readableTicketAnswer(isOpenToRemoteWork)}</div>
              {skillsAdditionalInfo && (
                <>
                  <Header size="small">Additional skills</Header>
                  {paragraphToHtml(skillsAdditionalInfo)}
                </>
              )}
              {terms && terms.length > 0 && (
                <>
                  <Header size="large">Terms</Header>
                  <div className="DocumentBadgeGroup">
                    {terms.map(({ id, filename }) => (
                      <DocumentBadge key={id} documentId={id} documentName={filename} />
                    ))}
                  </div>
                </>
              )}
              {additionalInformation && (
                <>
                  <Header size="large">Additional information</Header>
                  {paragraphToHtml(additionalInformation)}
                  <br />
                </>
              )}
              {additionalDocuments && additionalDocuments.length > 0 && (
                <>
                  <Header size="large">Additional documents</Header>
                  <div className="DocumentBadgeGroup">
                    {additionalDocuments.map(({ id, filename }) => (
                      <DocumentBadge key={id} documentId={id} documentName={filename} />
                    ))}
                  </div>
                </>
              )}
            </div>
          </>
        </Ref>
      </GridColumn>
      {!isExecuting && (
        <GridColumn width="6" floated="left">
          <div className="RightMenu-tickets">
            <ActionButtons
              isOwner={isOwner}
              ticket={ticket}
              ticketTitle={title}
              myBid={ticket.submittedBidBy}
            />
            <TicketSummary ticket={ticket} />
            {isOwner && <TicketDenyList deniedMembers={form.deniedMembers} />}
            {!isOwner && (
              <AskQuestion
                canChangeVisibility={true}
                description={`Ask ${ownerName} a question about this ticket.`}
                sessionState={sessionState}
                discussableId={ticketId}
                discussableMessage={ticket.seal?.message || ""}
              />
            )}
          </div>
        </GridColumn>
      )}
    </Grid>
  );
};
