import { gql } from "@apollo/client";
import { RECEIVED_ONBOARD_CONNECTION_FRAGMENT } from "../_fragments/Onboard";
import { SENT_ONBOARD_CONNECTION_FRAGMENT } from "../_fragments/Onboard";
import { Connection } from "../../types/relay";
import { ReceivedOnboard, SentOnboard, OnboardState } from "../../types/onboard";

export const QUERY = gql`
  query MyOnboards($states: [OnboardState!]) {
    payload: me {
      id
      sentOnboards: onboards(first: 1000, as: SENDER, state: $states) {
        ...SentOnboardConnection
      }
      receivedOnboards: onboards(first: 1000, as: RECIPIENT, state: $states) {
        ...ReceivedOnboardConnection
      }
    }
  }
  ${RECEIVED_ONBOARD_CONNECTION_FRAGMENT}
  ${SENT_ONBOARD_CONNECTION_FRAGMENT}
`;

export interface Variables {
  readonly states?: ReadonlyArray<OnboardState>;
}

interface MyOnboards {
  readonly sentOnboards?: Connection<SentOnboard>;
  readonly receivedOnboards?: Connection<ReceivedOnboard>;
}

export interface Result {
  readonly payload?: MyOnboards;
}
