import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { StartupTagsData } from "./_types";
import { globalDefinitions } from "../_definitions";

export const schema: JSONSchemaType<StartupTagsData> = {
  type: "object",
  properties: {
    maturity: globalDefinitions.maturity,
    sectors: {
      type: "array",
      items: globalDefinitions.sector,
      minItems: 1,
    },
  },
  required: ["maturity", "sectors"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
