import { useCallback, useMemo } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Button, Card, Image, Message } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { bridge as MattermostCreateTicketSchema } from "./MattermostUserCreationSchema";
import { MattermostUserCreationType } from "./MattermostUserCreationSchema";
import mmLogo from "../../assets/mattermost-logo.png";
import { ApiError, extractErrorMessages, isDefined, Maybe } from "../../types";
import { AjvError, appendCustomAjvError } from "../../utils/Ajv";
import { CustomTextField } from "../CustomTextField";
import { CustomPasswordField } from "../CustomPasswordField";
import { ErrorMessages } from "../../components/elements/ErrorMessages";
import { QUERY, Variables as QueryVars, Result as QueryRes } from "../../api/onboards/MyOnboards";
import { MUTATION, Result, Variables } from "../../api/accounts/CreateMattermostAccount";
import { Shortcuts as S } from "../../routing";
import { nodesFromEdges } from "../../types/relay";
import { OnboardStatus } from "../../types/onboard";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";

export const MattermostUserCreationForm = ({ location }: RouteComponentProps) => {
  const history = useHistory();
  const { data, loading: queryLoading } = useQuery<QueryRes, QueryVars>(QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const [createUser, { loading: mutLoading, error }] = useMutation<Result, Variables>(MUTATION);

  const loading = queryLoading || mutLoading;

  const invitationToken = useMemo(() => {
    const originalUrl = new URLSearchParams(location.search).get("original");
    return new URLSearchParams(originalUrl || "").get("t") || undefined;
  }, [location]);

  const model: Partial<MattermostUserCreationType> = useMemo(
    () => ({ inviteToken: invitationToken }),
    [invitationToken]
  );

  const onValidate = useCallback(
    (
      { password, passwordConfirmation }: Partial<MattermostUserCreationType>,
      mError: Maybe<AjvError>
    ) => {
      let e = mError;
      if (password && (password?.length < 8 || password?.length > 32)) {
        e = appendCustomAjvError(e, true, "/password", pwdLenghtError);
      }
      if (password !== passwordConfirmation) {
        e = appendCustomAjvError(e, true, "/password", pwdNotMatchError);
        e = appendCustomAjvError(e, true, "/passwordConfirmation", pwdNotMatchError);
      }
      return e;
    },
    []
  );
  const onSubmit = useCallback(
    (m: MattermostUserCreationType) => {
      const { inviteToken: t, password, passwordConfirmation, username } = m;
      createUser({
        variables: { input: { username, password, passwordConfirmation, invitationToken: t } },
      })
        .then(() => {
          const finalisedOnboard = nodesFromEdges(data?.payload?.receivedOnboards?.edges).find(
            (o) => o.status === OnboardStatus.Finalised
          );

          if (finalisedOnboard) {
            const { id } = finalisedOnboard;
            const search = new URLSearchParams({ [S.application.queryVarNames.id]: id }).toString();
            history.push({ pathname: S.application.path, search });
          } else {
            history.push({ pathname: S.myArea.path });
          }

          toast.success("Mattermost user was created.");
        })
        .catch((err: ApiError) => {
          console.log(err);
          toast.error("Something went wrong.");
        });
    },
    [data, history, createUser]
  );

  if (!isDefined(invitationToken)) {
    return <ErrorMessages errors={["No token was found."]} />;
  }

  return (
    <Card className="MattermostCreateUser">
      <div className="MattermostCreateUser-title">
        <div className="MattermostCreateUser-title-image">
          <Image src={mmLogo} />
        </div>
        <h1>Your Mattermost account details</h1>
      </div>
      <AutoForm
        className="MattermostCreateUser-body"
        schema={MattermostCreateTicketSchema}
        onValidate={onValidate}
        onSubmit={onSubmit}
        model={model}
        showInlineError
      >
        <CustomTextField
          name="username"
          label="Mattermost Username (tip: Including your first and last name helps other people connect with you!)"
          errorMessage="This field is mandatory."
          showtextcount="false"
        />
        <div className="MattermostCreateUser-body-field-label">
          You can use lowercase letters, numbers, periods, dashes, and underscores.
          <br />
        </div>
        <br />
        <CustomPasswordField name="password" label="Mattermost Password" showtextcount="false" />
        <CustomPasswordField
          name="passwordConfirmation"
          label="Confirm Password"
          showtextcount="false"
        />
        <Message>
          Your password must contain <b>between 10 and 64</b> characters made up of at least{" "}
          <b>one lowercase letter</b>, at least <b>one uppercase letter</b>, at least{" "}
          <b>one number</b>, and at least <b>one symbol</b> (e.g. "~!@#$%^&*()").
        </Message>
        {error && <ErrorMessages errors={extractErrorMessages(error)} />}
        <br />
        <Button color="blue" type="submit" loading={loading}>
          Create Mattermost account
        </Button>
      </AutoForm>
    </Card>
  );
};

const pwdLenghtError = "Password should have at least 8 characters and a maximum of 32";
const pwdNotMatchError = "Passwords don't match";
