import { keccak256 } from "js-sha3";
import { ContractContext } from "../contracts/generated/Access";

/**
 * Returns a resolved promise if `address` is an Actor or a rejected promise otherwise.
 */
export const requireIsActor = async (accessContract: ContractContext, address: string) => {
  return accessContract.isActor(address).then((res) => {
    if (!res) {
      return Promise.reject(new Error("This address is not an actor."));
    }
    return Promise.resolve(true);
  });
};

export const sha3 = (message: string) => {
  return `0x${keccak256.hex(message)}`;
};
