import classNames from "classnames";
import { isString } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Popup, StrictPopupProps } from "semantic-ui-react";

interface Props {
  [k: string]: unknown;
  readonly text: string;
  readonly maxLines?: number;
  readonly popupProps?: StrictPopupProps;
}

export const TextClamp = ({ text, maxLines = 1, popupProps, ...props }: Props) => {
  const [height, setHeight] = useState(0);
  const [clamped, setClamped] = useState(false);
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setHeight(ref.current!.scrollHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  useEffect(() => {
    const el = ref.current;
    if (el === null) {
      return;
    }
    // offset for bigger font size
    if (el.scrollHeight > el.clientHeight + 2) {
      setClamped(true);
      return;
    }
    setClamped(false);
  }, [height]);

  const className = isString(props.className)
    ? classNames(`ClampWrapper LineClamp-${maxLines}`, props.className)
    : `ClampWrapper LineClamp-${maxLines}`;

  return (
    <>
      {clamped && (
        <Popup
          wide={popupProps?.wide || true}
          position={popupProps?.position || "top center"}
          trigger={
            <div {...props} ref={ref} className={className}>
              {text}
            </div>
          }
          content={text}
        />
      )}
      {!clamped && (
        <div {...props} ref={ref} className={className}>
          {text}
        </div>
      )}
    </>
  );
};
