import { Button, Card, Icon } from "semantic-ui-react";
import { useChainState, requireNeedsWall, useChainApi } from "../../../contexts/Chain";
import { isNone } from "../../../contexts/Chain/state";

export const PromiseFailureTutorial = () => {
  const state = requireNeedsWall(useChainState());
  const api = useChainApi();
  const connect = () => api.authorize(state);
  const { busy } = state;

  const btnText = isNone(state) ? "Connect" : "Authorize";
  return (
    <Card className="Wall xl">
      <div className="WallContent xl">
        <h1 className="WallContent-title">Ethereum Confirmation</h1>
        <p>
          It appears that you canceled an authorization request from us. Please click the
          "Authorize" button again and follow the instructions.
        </p>
        <Button
          className="WallContent-extra"
          color="linkedin"
          onClick={connect}
          loading={busy}
          disabled={busy}
        >
          <Icon name="ethereum" />
          {btnText}
        </Button>
      </div>
    </Card>
  );
};
