export const decimateTokenValue = <T>(
  v: bigint | undefined,
  decimals: number,
  def: T,
  decimalPlaces: number = 2
): string | T => {
  if (v === undefined) {
    return def;
  }
  const pad = v.toString().padStart(decimals, "0");
  const dot = pad.length - decimals;
  let dotted = `${pad.substring(0, dot)}.${pad.substring(dot, pad.length)}`;
  if (dotted.substr(0, 1) === ".") {
    dotted = `0${dotted}`;
  }
  return Number(dotted).toFixed(decimalPlaces);
};

export const revertDecimate = <T>(v: string | undefined, decimals: number, def: T): bigint | T => {
  if (v === undefined) {
    return def;
  }
  try {
    const splitted = v.split(".");
    if (splitted.length > 2) {
      // Not a valid number.
      return def;
    } else if (splitted.length === 1) {
      return BigInt(splitted[0].padEnd(splitted[0].length + decimals, "0"));
    }

    const integerPart = splitted[0];
    let fractionalPart = splitted[1];

    if (fractionalPart.length < decimals) {
      fractionalPart = fractionalPart.padEnd(decimals, "0");
    } else if (fractionalPart.length > decimals) {
      fractionalPart = fractionalPart.substring(0, decimals);
    }
    return BigInt(integerPart.concat(fractionalPart));
  } catch {
    return def;
  }
};

// A "chain big int" is a number multiplied by 1 million and stored as a bigint.
// For example the number 3 would become BigInt(3,000,000).
export const convertToChainBigInt = (v: number): bigint | undefined => {
  const decimals = 6;
  return revertDecimate(v.toString(), decimals, undefined);
};

export const revertFromChainBigInt = (
  v: bigint | string,
  decimalPlaces?: number,
  fallback: string = "N/A"
): string => {
  try {
    const value = typeof v === "string" ? BigInt(v) : v;
    const decimals = 6;
    return decimateTokenValue(value, decimals, fallback, decimalPlaces);
  } catch {
    return fallback;
  }
};
