import { useMemo } from "react";
import classNames from "classnames";
import { connectField, HTMLFieldProps } from "uniforms";
import { DynamicInputFields } from "../components/elements/DynamicInputFields";
import { isDefined } from "../types";

interface Props extends HTMLFieldProps<readonly string[], HTMLDivElement> {}

export const CustomDynamicTextField = connectField((props: Props) => {
  const { onChange, value, max = 10, disabled, min = 1 } = props;
  const { field, label, required, error, errorMessage, className } = props;

  const currValue = useMemo(() => (isDefined(value) && value.length > 0 ? value : [""]), [value]);

  const buildCustomErrors = useMemo(() => {
    if (currValue.length < +min) {
      return undefined;
    }
    if (!!error) {
      return currValue.map((v) =>
        v.length === 0 || v === undefined ? "Empty fields are not allowed" : undefined
      );
    }
    return undefined;
  }, [currValue, min, error]);

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <DynamicInputFields
        required={required}
        max={+max}
        maxFieldLength={field?.items?.maxLength}
        values={currValue}
        onValuesChange={onChange}
        errors={buildCustomErrors}
      />
      <div>
        {!!error && currValue.length < +min && (
          <div className="ui red basic pointing label">{errorMessage}</div>
        )}
      </div>
    </div>
  );
});
