import { useCallback, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY, Result, Variables } from "../../../api/tickets/Tickets";
import { TicketState } from "../../../types/ticket";
import { nodesFromEdges } from "../../../types/relay";
import { isStartupRole } from "../../../types/role";
import { PageContentInfinityScroll } from "../../InfinityScrollWrapper";
import { LoaderWithMargin } from "../../Loader";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { MarketplaceCard } from "../../elements/tickets/ticket/MarketplaceCard";
import { Enrolled } from "../../../contexts/Session/state";
import { selectedRole } from "../../../contexts/Session/helpers";
import { Shortcuts as S } from "../../../routing";

const first: number = 10;

interface Props {
  readonly sessionState: Enrolled;
}

export const Marketplace = ({ sessionState: { roleId, user } }: Props) => {
  const history = useHistory();
  const queryData = useQuery<Result, Variables>(QUERY, {
    variables: { first, after: undefined, state: [TicketState.In_bidding], roleId },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
  });
  const { data, loading, error: ticketsError, fetchMore } = queryData;
  const { edges, pageInfo } = data?.connection || {};

  // Redirects non startup roles
  useEffect(() => {
    const isStartup = isStartupRole(selectedRole(user.roles, roleId));
    if (isStartup) {
      return history.replace({ pathname: S.portfolio.path });
    }
  }, [history, roleId, user]);

  // Convert edges to nodes and save them to state everytime query results change.
  const tickets = useMemo(() => {
    if (!data || !data.connection) {
      return;
    }
    return nodesFromEdges(data.connection.edges);
  }, [data]);

  const onNext = useCallback(() => {
    if (fetchMore && pageInfo?.hasNextPage) {
      fetchMore({
        variables: { first, after: pageInfo.endCursor, state: [TicketState.In_bidding] },
      });
    }
  }, [fetchMore, pageInfo]);

  const ticketList = useMemo(() => {
    if (tickets?.length === 0) {
      return <>There are currently no tickets on the marketplace.</>;
    }
    return tickets?.map((t) => <MarketplaceCard ticket={t} key={t.id} />);
  }, [tickets]);

  if (ticketsError) {
    return <ErrorMessages errors={[ticketsError.message]} />;
  }

  return (
    <div className="WrapperSection">
      <div className="sort-div">
        <div className="ComponentHeader">Tickets</div>
      </div>
      {loading && !data && <LoaderWithMargin />}
      <PageContentInfinityScroll
        dataLength={edges?.length || 0}
        next={onNext}
        hasMore={!!pageInfo?.hasNextPage}
        loader={loading ? <LoaderWithMargin /> : null}
      >
        <div className="TicketListWrapper">{ticketList}</div>
      </PageContentInfinityScroll>
    </div>
  );
};
