import { useCallback, useMemo, useRef } from "react";
import { useEffect, MouseEventHandler } from "react";
import moment, { now } from "moment";
import { DeepPartial } from "uniforms";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Loader, Label, Image } from "semantic-ui-react";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { Result, QUERY, Variables } from "../../../api/onboards/PreselectionOnboard";
import { requireEnrolled, useSessionState } from "../../../contexts/Session";
import { Shortcuts as S } from "../../../routing";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { PreSelectionHeader } from "./PreSelectionHeader";
import { StartupDetailsTab } from "./StartupDetailsTab";
import { isEnum } from "../../../types";
import whiteClock from "../../../assets/white-clock.svg";
import { PreSelectionVoteForm } from "../../../schemas/preselectionVote/PreSelectionVoteForm";
import { PreselectionVoteSchema } from "../../../schemas/preselectionVote/_types";
import { PreselectionDiscussionTab } from "./PreselectionDiscussionTab";
import { OnboardState } from "../../../types/onboard";
import { OnboardMigrator } from "../../../migrations/Onboards";
import { isAtLeastPresent } from "../../../contexts/Session/state";

export enum Tabs {
  Details = "details",
  VotingForm = "voting-form",
  Discussion = "discussion",
}

const idVarName = S.startup.queryVarNames.id;
const tabVarName = S.startup.queryVarNames.tab;
const voteTabVarName = S.startup.queryVarNames.voteTab;

export const StartupDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const sessionState = useSessionState();
  const { roleId } = requireEnrolled(sessionState);
  const breadcrumbApi = useBreadcrumbApi();
  const onboardId = new URLSearchParams(location.search).get(idVarName) || "";
  const voteModelRef = useRef<DeepPartial<PreselectionVoteSchema> | undefined>();
  const isAdmin = isAtLeastPresent(sessionState) && sessionState.user.isAdmin;

  const query = useQuery<Result, Variables>(QUERY, { variables: { onboardId, roleId } });
  const { data, loading, error } = query;

  const onboard = useMemo(() => {
    if (!data?.onboard) {
      return undefined;
    }

    const { migrate } = new OnboardMigrator(data.onboard);
    return migrate();
  }, [data]);

  useEffect(() => {
    if (!onboard) {
      return;
    }

    const customTitle = `Startup Pipeline - ${onboard.fullName}`;
    const body = <PreSelectionHeader onboard={onboard} />;

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(body);

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [breadcrumbApi, onboard]);

  const selectedTab = useMemo(() => {
    const tab = new URLSearchParams(location.search).get(tabVarName) || Tabs.Details;
    // Replaces bad tab names on the url.
    return isEnum(tab, Tabs) ? tab : undefined;
  }, [location]);

  useEffect(() => {
    // Replaces the tab in the url if a user is in a tab they are not suposed.
    if (onboard === undefined || !onboard.proposal) {
      return;
    } else if (
      !isEnum(selectedTab, Tabs) ||
      (!onboard.proposal.hasVoted && selectedTab === Tabs.Discussion && !isAdmin) ||
      (onboard.proposal.hasVoted &&
        selectedTab === Tabs.VotingForm &&
        // This prevents the user from being automatically redirected when he submits a vote.
        voteModelRef.current === undefined)
    ) {
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete(tabVarName);
      history.replace({ pathname: S.startup.path, search: newSearch.toString() });
      return;
    }
  }, [onboard, selectedTab, history, location, isAdmin]);

  const onTabClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      const newTab = ev.currentTarget.id;
      if (isEnum(newTab, Tabs) && newTab !== selectedTab) {
        const newSearch = new URLSearchParams(location.search);
        newSearch.delete(voteTabVarName);
        newSearch.set(tabVarName, newTab);
        return history.replace({ search: newSearch.toString() });
      }
    },
    [history, location, selectedTab]
  );

  const detailsTabClassname = `PageContentTabs-tab${
    selectedTab === Tabs.Details ? " selected" : ""
  }`;
  const votingTabClassname = `PageContentTabs-tab${
    selectedTab === Tabs.VotingForm ? " selected" : ""
  }`;
  const discussionTabClassname = `PageContentTabs-tab${
    selectedTab === Tabs.Discussion ? " selected" : ""
  }`;

  if (error) {
    return <ErrorMessages errors={[error.message]} />;
  } else if (!onboard || !onboard.form || !onboard.form.data.startupData) {
    return loading ? <Loader active inline="centered" /> : null;
  }

  return (
    <>
      <div className="PageContentTabs">
        <div id={Tabs.Details} className={detailsTabClassname} onClick={onTabClick}>
          Application
        </div>
        {!onboard.proposal?.hasVoted && onboard.state === OnboardState.InPreselection && (
          <div id={Tabs.VotingForm} className={votingTabClassname} onClick={onTabClick}>
            Voting Form
            <Label className="PageContentTabs-vote-label" pointing="left">
              Vote now!
              <Image src={whiteClock} />
              <span>
                {moment(onboard.preselectionEndsAt).diff(moment(now()), "days")} days left
              </span>
            </Label>
          </div>
        )}
        {(onboard.proposal?.hasVoted || isAdmin) && (
          <div id={Tabs.Discussion} className={discussionTabClassname} onClick={onTabClick}>
            Discussion
          </div>
        )}
      </div>
      {selectedTab === Tabs.Details && <StartupDetailsTab onboard={onboard} />}
      {onboard.proposal && selectedTab === Tabs.VotingForm && (
        <PreSelectionVoteForm
          proposal={onboard.proposal}
          onboard={onboard}
          modelRef={voteModelRef}
        />
      )}
      {selectedTab === Tabs.Discussion && (
        <PreselectionDiscussionTab onboard={onboard} view="startup-pipeline" />
      )}
    </>
  );
};
