import { Divider, Grid } from "semantic-ui-react";
import { AdminExpertOnboard } from "../../../api/admin/AdminExpertOnboard";
import { CBCMemberExpertOnboard } from "../../../api/onboards/CBCMemberExpertOnboard";
import { useCategoriesState } from "../../../contexts/Categories";
import { isSuccessState } from "../../../contexts/Generic";
import { Migrated } from "../../../migrations/_helpers";
import { readableCustomBoolRadioField } from "../../../schemas/CustomBoolRadioField";
import { readableStartupsWorkedWith } from "../../../schemas/onboards/utils/_types";
import { readableYearlyInvestment } from "../../../schemas/onboards/utils/_types";
import { isDefined } from "../../../types";
import { PublicOnboardViewedByMember } from "../../../types/onboard";
import { paragraphToHtml } from "../../../utils/applications/Formatting";
import { countryMapping } from "../../../utils/CountriesList";

interface Props {
  readonly onboard: Migrated<
    AdminExpertOnboard | PublicOnboardViewedByMember | CBCMemberExpertOnboard
  >;
  readonly showRightColumn?: boolean;
}

export const ExpertDetailsProfileTab = ({ onboard, showRightColumn = true }: Props) => {
  const categoriesState = useCategoriesState();
  const { fullName, migratedForm, invitationReason, sender } = onboard;

  if (!isSuccessState(categoriesState) || !migratedForm || !migratedForm.data) {
    return null;
  }
  const { experienceGeography, linkedIn, sectors, topSkills, motivation, engagementLevel } =
    migratedForm.data;
  const { openingNetwork, committeeInvolvement, startupsWorkedWith, typicalYearlyInvestment } =
    migratedForm.data;
  const { involvedInExit } = migratedForm.data;

  const { sectorsMapping, skillsMapping } = categoriesState.result;
  return (
    <Grid className="ExpertDetailsProfileTab">
      <Grid.Column computer={12} tablet={16}>
        <div className="WrapperSection">
          <Grid.Row>
            <Grid.Column>
              <div className="ExpertDetailsProfileTab-left-col">
                <div className="ExpertDetailsProfileTab-left-col-title">{fullName || "Expert"}</div>
                {sectors && (
                  <>
                    Sector:
                    <b> {sectors.map((s) => sectorsMapping[s]).join(", ")}</b>
                    <br />
                  </>
                )}
                {topSkills && (
                  <>
                    Skills:
                    <b> {topSkills.map((s) => skillsMapping[s]).join(", ")}</b>
                    <br />
                  </>
                )}
                {experienceGeography && (
                  <>
                    Geography: <b>{experienceGeography.map((c) => countryMapping(c)).join(", ")}</b>
                    <br />
                  </>
                )}
                {linkedIn && (
                  <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                    <b>Linkedin</b>
                  </a>
                )}
                {invitationReason && (
                  <span className="ExpertDetailsProfileTab-left-col-note">
                    <br />
                    {sender.fullName} invited {fullName} because "{invitationReason}"
                  </span>
                )}
                <Divider />
                {isDefined(motivation) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>
                      What resonated with you about CV that encouraged you to say yes to your
                      nomination?
                    </b>
                    <br />
                    {paragraphToHtml(motivation)}
                  </div>
                )}
                {isDefined(engagementLevel) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>
                      How much time are you willing to invest working with startups and/or the
                      community?
                    </b>
                    <br />
                    {engagementLevel}
                  </div>
                )}
                {isDefined(openingNetwork) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>
                      Are you comfortable opening up your network, where suitable, to support CVs
                      endeavours?
                    </b>
                    <br />
                    {readableCustomBoolRadioField(openingNetwork)}
                  </div>
                )}
                {isDefined(committeeInvolvement) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>Are you willing/have the time to get involved in one of the committees?</b>
                    <br />
                    {readableCustomBoolRadioField(committeeInvolvement)}
                  </div>
                )}
                {isDefined(topSkills) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>
                      In the context of working with startups, what are your most useful or unique
                      skills?
                    </b>
                    <br />
                    {topSkills.map((s, idx) => {
                      return (
                        <div key={`skillMap-${idx}`}>
                          <span>
                            {idx + 1}. {skillsMapping[s] || "Unknown"}
                          </span>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                )}
                {isDefined(startupsWorkedWith) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>How many startups or small businesses have you worked with?</b>
                    <br />
                    {readableStartupsWorkedWith(startupsWorkedWith)}
                  </div>
                )}
                {isDefined(typicalYearlyInvestment) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>
                      If you are an investor/facilitator, what is the typical fund size or
                      investment amount you find or invest?
                    </b>
                    <br />
                    {readableYearlyInvestment(typicalYearlyInvestment)}
                  </div>
                )}
                {isDefined(involvedInExit) && (
                  <div className="ExpertDetailsProfileTab-left-col-question">
                    <b>
                      Have you been involved in any sizeable exit(s) as an investor or in a
                      different capacity?
                    </b>
                    <br />
                    {readableCustomBoolRadioField(involvedInExit)}
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </div>
      </Grid.Column>
      {showRightColumn && (
        <Grid.Column computer={4} tablet={16}>
          <div className="ExpertDetailsProfileTab-right-col">
            <div className="ExpertDetailsProfileTab-right-col-title">Why become a Seconder?</div>
            <p>
              The Mutual assessment (MA) is a chance for the nominee to discuss any questions they
              may have about CV directly with existing members. At the same time, as a Seconder, you
              will evaluate your nominee to ensure alignment with the CV ecosystem in terms of
              culture, skill set, and experience with innovation.
              <br />
            </p>
            <p>
              During the Mutual Assessment, you'll discuss the nominee's specific areas of
              expertise, eliciting examples that help you understand the depth and breadth of their
              skillsets. Your evaluation of the nominee will be shared with members of the Community
              Building Committee to inform their voting on whether to admit the applicant.
              <br />
            </p>
          </div>
        </Grid.Column>
      )}
    </Grid>
  );
};
