import { CSSProperties, useCallback } from "react";
import { Context, DeepPartial } from "uniforms";
import { Button, Divider, Grid } from "semantic-ui-react";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { bridge, CoInvestorInviteType } from "./CoInvestorInviteSchema";
import { BusinessCases, FundSize, NumberOfInvestments } from "./_types";
import { readableFundSize, readableNumberOfInvestments } from "./_types";
import { BackButton } from "./BackButton";
import { PageTwoFormType } from "../../components/pages/NominateExpert";
import { CustomBoolRadioField, readableCustomBoolRadioField } from "../CustomBoolRadioField";
import { DisplayIf } from "../DisplayIf";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";

const buttonColumnStyle: CSSProperties = { display: "flex" };
const dropdownStyle: CSSProperties = { maxWidth: "142px" };

const defaultModel: DeepPartial<CoInvestorInviteType> = {
  businessCase: BusinessCases.CoInvestor,
};

interface Props {
  readonly initialModel?: DeepPartial<CoInvestorInviteType>;
  readonly onBack: (model: PageTwoFormType) => void;
  readonly onSubmit: (model: CoInvestorInviteType) => void;
}

export const CoInvestorInviteForm = ({ initialModel, onBack, onSubmit }: Props) => {
  const activelyInvestCondition = useCallback(
    (c: Context<CoInvestorInviteType>) => !!c.model.activelyInvest,
    []
  );

  return (
    <AutoForm
      schema={bridge}
      showInlineError
      onSubmit={onSubmit}
      model={initialModel || defaultModel}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <CustomBoolRadioField
              name="activelyInvest"
              label="Do they actively invest in start-ups?"
              horizontal
              transform={readableCustomBoolRadioField}
            />
          </Grid.Column>
        </Grid.Row>
        <DisplayIf condition={activelyInvestCondition}>
          <>
            <Grid.Row>
              <Grid.Column>
                <CustomDropdownSelectionField
                  name="numberOfInvestments"
                  label="How many do you know of?"
                  dropdownOptions={numberOfInvestmentsOptions}
                  dropdownStyle={dropdownStyle}
                  placeholder="Select element"
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column>
                <CustomDropdownSelectionField
                  name="fundSize"
                  label="What sort of fund size do you think they have access to?"
                  dropdownOptions={fundSizeOptions}
                  dropdownStyle={dropdownStyle}
                  placeholder="Select element"
                />
              </Grid.Column>
            </Grid.Row>
          </>
        </DisplayIf>
        <Grid.Row>
          <Grid.Column style={buttonColumnStyle}>
            <BackButton onBack={onBack} />
            <Button type="submit" primary content="Submit" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AutoForm>
  );
};

const numberOfInvestmentsOptions = Object.entries(NumberOfInvestments).map(([_, v]) => ({
  text: readableNumberOfInvestments(v),
  value: v,
}));

const fundSizeOptions = Object.entries(FundSize).map(([_, v]) => ({
  text: readableFundSize(v),
  value: v,
}));
