import { gql } from "@apollo/client";
import { PreselectionProposalChoice } from "../../schemas/preselectionVote/_types";
import { Identifiable } from "../../types";
import { ProposalTallyItem } from "../../types/proposal";

export const SUBSCRIPTION = gql`
  subscription NewProposalBallot($authorisation: SubscriptionAuth!, $id: ID!) {
    newProposalBallot(authorisation: $authorisation, id: $id) {
      id
      choice
      proposal {
        id
        tally {
          count
          choice
        }
      }
    }
  }
`;

export interface Variables {
  readonly authorisation: {
    readonly bearerToken: string;
    readonly roleId?: string;
  };
  readonly id: string;
}

export interface NewProposalBallot {
  readonly newProposalBallot: {
    readonly choice: PreselectionProposalChoice;
    readonly proposal: {
      readonly tally?: ReadonlyArray<ProposalTallyItem<PreselectionProposalChoice>>;
    } & Identifiable;
  } & Identifiable;
}
