import { EvaluationSchema } from "../schemas/bids/evaluation/_types";
import { BidSchema } from "../schemas/bids/_types";
import { Identifiable } from "./common";
import { JsonDocument } from "./jsonDocument";
import { TickettingDraft } from "./ticket";

export enum TicketBidState {
  Closed = "CLOSED",
  Editable = "EDITABLE",
  Submitted = "SUBMITTED",
}

export enum TicketBidStatus {
  Canceled = "CANCELED",
  ConfirmedWinner = "CONFIRMED_WINNER",
  Loser = "LOSER",
  Open = "OPEN",
  Published = "PUBLISHED",
  SelectedWinner = "SELECTED_WINNER",
  SelectedWinnerAcknowledged = "SELECTED_WINNER_ACKNOWLEDGED",
  VoidedExpertDeclined = "VOIDED_EXPERT_DECLINED_OFFER",
  VoidedTimedOut = "VOIDED_OFFER_TIMEOUT",
}

export interface SimpleBid extends Identifiable {
  readonly state: TicketBidState;
  readonly status: TicketBidStatus;
  readonly owner: { readonly fullName: string } & Identifiable;
  readonly publishedAt?: string;
  readonly voidedExpertDeclinedOfferAt?: string;
  readonly voidedOfferTimeoutAt?: string;
  readonly selectedWinnerAt?: string;
}

export interface TickettingBid extends SimpleBid {
  readonly form?: JsonDocument<BidSchema>;
  readonly draft?: TickettingDraft<Partial<BidSchema>>;
  readonly seal?: { readonly signature: string };
}

export interface TickettingBidEvaluation extends Identifiable {
  readonly form?: JsonDocument<EvaluationSchema>;
  readonly insertedAt: string;
  readonly owner: { readonly fullName: string } & Identifiable;
}
