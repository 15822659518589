import { useCallback, CSSProperties } from "react";
import { Grid } from "semantic-ui-react";
import { Context, useField } from "uniforms";
import { PublicOnboard } from "../../types/onboard";
import { CustomBoolRadioField } from "../CustomBoolRadioField";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";
import { DisplayIf } from "../DisplayIf";
import { DueDiligenceAreasList, PreselectionProposalChoice } from "./_types";
import { PreselectionVoteSchema, readableisReviewCommitteeVolunteer } from "./_types";

const paddingTopStyle: CSSProperties = { paddingTop: "7px" };
const noPaddingTopStyle: CSSProperties = { paddingTop: "0" };

interface Props {
  readonly onboard: PublicOnboard;
}

export const ReviewCommitteeSection = ({ onboard }: Props) => {
  const [{ value: voteChoice }] = useField<{}, PreselectionProposalChoice>("voteChoice", {});
  const sectionNumber = voteChoice === PreselectionProposalChoice.Neutral ? "2" : "3";

  const isReviewCommitteeVolunteer = useCallback(
    ({ model }: Context<PreselectionVoteSchema>) => !!model.isReviewCommitteeVolunteer,
    []
  );

  return (
    <>
      <Grid.Row />
      <div className="PreSelectionVoteForm-title">{sectionNumber}. Startup Review Committee</div>
      <Grid.Row style={paddingTopStyle}>
        <Grid.Column computer="14" mobile="16" tablet="16">
          <CustomBoolRadioField
            name="isReviewCommitteeVolunteer"
            label={`
            Would you like to participate in the Startup Review Committee for ${
              onboard.fullName || "this startup"
            }?`}
            errorMessage="This field is mandatory."
            transform={readableisReviewCommitteeVolunteer}
          />
        </Grid.Column>
      </Grid.Row>

      <DisplayIf condition={isReviewCommitteeVolunteer}>
        <Grid.Row style={noPaddingTopStyle}>
          <Grid.Column computer="14" mobile="16" tablet="16">
            <CustomDropdownSelectionField
              name="dueDiligenceAreas"
              required
              label={`If ${
                onboard.fullName || "this startup"
              } progresses to General Assessment for further due-diligence, which areas of due diligence would you like to focus on?`}
              dropdownOptions={DueDiligenceAreasList}
              placeholder="Select area(s)"
              noResultsMessage="All options selected."
              errorMessage="This field is mandatory."
              multiple
              search
            />
          </Grid.Column>
        </Grid.Row>
      </DisplayIf>
    </>
  );
};
