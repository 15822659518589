import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { connectField, HTMLFieldProps } from "uniforms";
import { UploadDocs } from "../components/elements/tickets/UploadDocs";
import { MUTATION, Variables, Result } from "../api/documents/CreateDocument";
import { QUERY } from "../api/documents/DocumentsByIds";
import { Variables as DocsByIdsVars } from "../api/documents/DocumentsByIds";
import { Result as DocsByIdsRes } from "../api/documents/DocumentsByIds";
import { Document } from "../types/document";
import { FormDocument } from "./_types";

const defaultMaxDocs = 2;

interface Props extends HTMLFieldProps<ReadonlyArray<FormDocument>, HTMLInputElement> {
  readonly [k: string]: unknown;
  readonly acceptedFiles: string[];
  readonly maxCount?: number;
}

export const CustomMultipleUploadField = connectField((props: Props) => {
  const { value, maxCount, onChange, acceptedFiles } = props;
  const { label, className, disabled, required } = props;
  const { showInlineError, error, errorMessage } = props;

  // Query and mutation.
  const { data } = useQuery<DocsByIdsRes, DocsByIdsVars>(QUERY, {
    variables: { ids: value?.map((d) => d.id) || [] },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [createDocument] = useMutation<Result, Variables>(MUTATION);

  const documents = useMemo(() => {
    if (!data?.nodes) {
      return undefined;
    }
    return data.nodes.reduce((acc, curr) => {
      if (!!curr) {
        return [...acc, curr];
      }
      return acc;
    }, [] as ReadonlyArray<Document>);
  }, [data]);

  const onFilesChange = useCallback(
    (files?: File[]) => {
      if (!files || files.length === 0) {
        return;
      }

      let isValid = true;

      files.forEach((f) => {
        let currIsValid = false;
        acceptedFiles?.forEach((t) => {
          currIsValid = currIsValid || f.type.includes(t.slice(1));
        });
        isValid = isValid && currIsValid;
      });

      if (!isValid) {
        toast.error("Invalid file type.");
        return onChange(undefined);
      }

      Promise.all(files.map((f) => createDocument({ variables: { input: { upload: f } } })))
        .then((results) => {
          const newDocs = results.reduce((acc, curr) => {
            if (!curr.data || !curr.data.payload) {
              return acc;
            }
            const { id, hash, uploadedFile } = curr.data.payload.document;
            return [...acc, { id, hash, filename: uploadedFile.filename }];
          }, [] as ReadonlyArray<FormDocument>);

          onChange(value?.concat(newDocs) || newDocs);
        })
        .catch((e) => {
          toast.error("Failed to upload attachment.");
          console.warn(e);
        });
    },
    [acceptedFiles, value, onChange, createDocument]
  );

  const onDeleteDocument = useCallback(
    ({ id }: Document) => {
      const newDocs = value?.filter((v) => v.id !== id);
      onChange(newDocs);
    },
    [value, onChange]
  );

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <UploadDocs
        documents={documents}
        maxDocs={maxCount || defaultMaxDocs}
        onFilesChange={onFilesChange}
        onDeleteDocument={onDeleteDocument}
        acceptedFiles={acceptedFiles}
      />
      {documents?.map(({ id }, idx) => (
        <ErrorHolder key={id} name={idx.toString()} />
      ))}
      <div>
        {!!(error && showInlineError) && (
          <div className="ui red basic pointing label">{errorMessage}</div>
        )}
      </div>
    </div>
  );
});

const ErrorHolder = connectField((props: HTMLFieldProps<unknown, unknown>) => {
  const { error, errorMessage, showInlineError } = props;

  return (
    <div>
      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage}</div>
      )}
    </div>
  );
});
