import chainManifests from "../chainManifests.json";

export enum ChainID {
  Local = "5777",
  Beta = "18021981",
  Develop = "18021981",
  Production = "18021982",
}

type KnownContract = "register" | "registry" | "access" | "transact" | "token";
export type Manifest = { [key in KnownContract]: string };

export const manifest = (id: ChainID): Manifest => {
  const data = chainManifests[id];
  return {
    register: data.register,
    registry: data.registry,
    access: data.access,
    transact: data.transact,
    token: data.token,
  };
};

export const isChainId = (id: string): id is ChainID =>
  Object.values(ChainID)
    .map((s) => `${s}`)
    .includes(id);
