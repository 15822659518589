import { gql } from "@apollo/client";
import { EvaluateCompletionSchema } from "../../schemas/ticketDashboard/_types";
import { Identifiable } from "../../types";
import { JsonDocument } from "../../types/jsonDocument";
import { Connection } from "../../types/relay";
import { TicketState } from "../../types/ticket";

export const QUERY = gql`
  query RefetchTicketEvals($id: ID!) {
    payload: node(id: $id) {
      ... on TickettingTicket {
        id
        feedbackEvaluations(scope: TICKETTING_TICKET_EVALUATION, first: 1) {
          edges {
            node {
              id
              insertedAt
              form(migrate: false) {
                data
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly id: string;
}

export interface Result {
  readonly payload?: Ticket;
}

export interface Ticket extends Identifiable {
  readonly state: TicketState;
  readonly feedbackEvaluations?: Connection<FeedbackEvaluation>;
}

interface FeedbackEvaluation extends Identifiable {
  readonly insertedAt: string;
  readonly form: JsonDocument<EvaluateCompletionSchema>;
}
