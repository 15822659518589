import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { CommunicationMethods } from "./_enums";
import { TicketPreferencesSchema } from "./_types";

export const schema: JSONSchemaType<TicketPreferencesSchema> = {
  type: "object",
  properties: {
    communicationMethods: {
      type: "array",
      items: { type: "string", enum: Object.values(CommunicationMethods) },
      minItems: 1,
    },
    communicationMethodOther: {
      type: "string",
      maxLength: 20,
    },
    knowledgeAreas: {
      type: "string",
      maxLength: 200,
      pattern: "(.|s)*\\S(.|s)*",
    },
  },
  required: ["communicationMethods"],
  if: {
    properties: {
      communicationMethods: {
        type: "array",
        items: { type: "string", enum: Object.values(CommunicationMethods) },
        contains: {
          const: CommunicationMethods.Other,
        },
      },
    },
  },
  then: {
    required: ["communicationMethodOther"],
  },
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
