import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Grid } from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";
import { noResultErrorFor } from "../../../../../types";
import { extractErrorMessages } from "../../../../../types";
import { Shortcuts as S } from "../../../../../routing";
import { OnboardHeader } from "./OnboardHeader";
import { QUERY, Result, Variables } from "../../../../../api/onboards/Onboard";
import { ErrorMessages } from "../../../../elements/ErrorMessages";
import { ReceivedOnboard } from "../../../../../types/onboard";
import { isProviderOnboard, isStartupOnboard, OnboardState } from "../../../../../types/onboard";
import { Stepper } from "../../../../elements/Stepper";
import { LoaderWithMargin } from "../../../../Loader";
import { StartupForm } from "../../../../../schemas/onboards/forms/StartupForm";
import { ProviderForm } from "../../../../../schemas/onboards/forms/ProviderForm";
import { Closed } from "./Panes/Closed";
import { Upvote } from "./Panes/Upvote";
import { useBreadcrumbApi } from "../../../../../contexts/Breadcrumb";
import { EthereumForm } from "../../../../../schemas/onboards/forms/EthereumForm";
import { useChainState } from "../../../../../contexts/Chain";
import { useCancelablePromises } from "../../../../../hooks/useCancelablePromise";
import { FlagsResponse } from "../../../../../contracts/generated/Access";
import { isInitialized } from "../../../../../contexts/Chain/state";
import { OnboardMigrator } from "../../../../../migrations/Onboards";
import { Migrated } from "../../../../../migrations/_helpers";

const idVarName = S.application.queryVarNames.id;

interface State {
  readonly loading: boolean;
  readonly error?: string;
  readonly flags?: FlagsResponse;
}

export interface OnboardFormProps {
  readonly onboard: Migrated<ReceivedOnboard>;
}

export const ApplicationPage = ({ location }: RouteComponentProps) => {
  const breadcrumbApi = useBreadcrumbApi();
  const id = new URLSearchParams(location.search).get(idVarName) || "";
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { onboardId: id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: false,
    nextFetchPolicy: "cache-only",
  });

  const chainState = useChainState();
  const { makeCancelable } = useCancelablePromises([data?.onboard?.ethAddress]);
  const [state, setState] = useState<State>({ loading: false });

  useEffect(() => {
    if (!isInitialized(chainState)) {
      return setState((s) => ({ ...s, error: "Coulnd't connect to your wallet." }));
    }

    if (!data?.onboard?.ethAddress) {
      return;
    }

    const { ethAddress } = data.onboard;

    const accessContract = chainState.contracts.access;
    setState((s) => ({ ...s, loading: true }));
    makeCancelable(accessContract.flags(ethAddress))
      .then((roleChainData) => setState((s) => ({ ...s, loading: false, flags: roleChainData })))
      .catch((e) => {
        const errorMsg = "Error while fetching chain data for this application.";
        setState((s) => ({ ...s, loading: false, error: errorMsg }));
        console.warn(e);
      });
  }, [data, chainState, makeCancelable]);

  const onboard = useMemo(() => {
    if (!data?.onboard) {
      return undefined;
    }

    const { migrate } = new OnboardMigrator(data.onboard);
    return migrate();
  }, [data]);

  useEffect(() => {
    if (!onboard) {
      return;
    }

    const customTitle = `Invite - ${onboard.fullName}`;
    const body = <OnboardHeader onboard={onboard} />;

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(body);

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [breadcrumbApi, onboard]);

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !onboard) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Application"))} />;
  }

  const isInCompletion =
    onboard.state === OnboardState.Pending || onboard.state === OnboardState.InCompletion;

  const isActor = !!state.flags?.isActor;

  return (
    <Grid>
      {/* Mobile only */}
      <Grid.Column width="16" only="mobile">
        <Stepper onboard={onboard} isActor={isActor} />
      </Grid.Column>

      <Grid.Column computer="13" tablet="12" mobile="16">
        {isInCompletion && isProviderOnboard(onboard) && <ProviderForm onboard={onboard} />}
        {isInCompletion && isStartupOnboard(onboard) && <StartupForm onboard={onboard} />}

        {(onboard.state === OnboardState.AwaitingEnableVoting ||
          onboard.state === OnboardState.InPreselection ||
          onboard.state === OnboardState.AwaitingSeconders ||
          onboard.state === OnboardState.TimedOut ||
          onboard.state === OnboardState.UnderReview ||
          onboard.state === OnboardState.InMutualAssessment ||
          onboard.state === OnboardState.InCommitteeReview) && <Upvote onboard={onboard} />}

        {onboard.state === OnboardState.AwaitingSignature && <EthereumForm onboard={onboard} />}

        {onboard.state === OnboardState.Closed && <Closed onboard={onboard} isActor={isActor} />}
      </Grid.Column>

      {/* Computer and tablet only */}
      <Grid.Column computer="3" tablet="4" only="computer tablet">
        <Stepper onboard={onboard} isActor={isActor} />
      </Grid.Column>
    </Grid>
  );
};
