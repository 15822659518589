import React from "react";
import { Placeholder } from "semantic-ui-react";
import { Role } from "../../../../api/RolesByIds";
import { TextClamp } from "../../TextClamp";
import expertIcon from "../../../../assets/expert-icon.png";
import startupIcon from "../../../../assets/rocket-icon.png";
import { EntityKind, readableTypeAndKind } from "../../../../types/onboard";

interface Props {
  readonly role?: Role;
  readonly loading?: boolean;
}

export const DeniedRoleBadge = ({ role, loading }: Props) => {
  if (!role && !loading) {
    return null;
  }
  const displayLoaders = !role && loading;

  return (
    <div className="DeniedRoleBadge">
      <div className="DeniedRoleBadge-left">
        {role && <img className="DeniedRoleBadge-img" src={role.avatarUrl} alt="role" />}
        {displayLoaders && (
          <Placeholder className="DeniedRoleBadge-img">
            <Placeholder.Image />
          </Placeholder>
        )}
      </div>
      <div className="DeniedRoleBadge-center">
        <div className="DeniedRoleBadge-name">
          {role && <TextClamp text={role.profile.fullName} maxLines={1} />}
          {displayLoaders && (
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          )}
        </div>
        <div className="DeniedRoleBadge-type">
          {role && (
            <>
              {readableTypeAndKind(role.type, role.kind)}
              <img
                src={role.kind === EntityKind.Startup ? startupIcon : expertIcon}
                alt="role icon"
              />
            </>
          )}
        </div>
      </div>
      <div className="DeniedRoleBadge-right" />
    </div>
  );
};
