import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TagNamespace } from "../../types/labelQuery";

export const QUERY = gql`
  query getTagsByRoleId($id: ID!) {
    payload: node(id: $id) {
      ... on Role {
        id
        tags(scope: DEFAULT) {
          id
          ns
          description
        }
      }
    }
  }
`;

export interface Variables {
  readonly id: string;
}

interface Tags extends Identifiable {
  readonly ns: TagNamespace;
  readonly description: string;
}

export interface Result {
  readonly payload?: TagsPayload;
}

interface TagsPayload extends Identifiable {
  readonly tags?: Tags[];
}
