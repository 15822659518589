import { ErrorObject } from "ajv";
import React from "react";
import { useForm } from "uniforms";
import { TicketSchema } from "../_types";
import { CreateTicketErrors } from "../TicketForm";

export const InsufficientFundsErrorField = () => {
  const { error } = useForm<TicketSchema>();
  const details: ErrorObject[] | undefined = error?.details;
  let hasInsufficientFunds = false;

  details?.forEach((e) => {
    if (e?.message === CreateTicketErrors.Funds) {
      hasInsufficientFunds = true;
    }
  });

  return (
    <>
      {!!hasInsufficientFunds && (
        <div className="ui red basic pointing label">
          You don't have enough CVDS to publish this ticket.
        </div>
      )}
    </>
  );
};
