import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import { usePageContentProvider } from "../../../contexts/PageContent/Provider";
import { FunctionTypes, roleHasFunction } from "../../../contexts/Session/helpers";
import { Enrolled } from "../../../contexts/Session/state";
import { Shortcuts as S } from "../../../routing";
import { CBCExpertApplications } from "./CBCExpertApplications";
import { PendingCBCMemberVoteList } from "./PendingCBCMemberVoteList";

const expertOnboardingPath = S.expertOnboarding.path;

interface Props {
  readonly sessionState: Enrolled;
}

export const NomineeVoting = ({ sessionState }: Props) => {
  const history = useHistory();
  const isCBCMember = roleHasFunction(sessionState, FunctionTypes.CBCMember);
  const isAdmin = sessionState.user.isAdmin;
  const { clearBackground, setCBCBackground } = usePageContentProvider();

  useEffect(() => {
    setCBCBackground();
    return () => clearBackground();
  }, [clearBackground, setCBCBackground]);

  if (!isAdmin && !isCBCMember) {
    history.replace({ pathname: expertOnboardingPath });
    return null;
  }

  return (
    <div className="NomineeVoting">
      <PendingCBCMemberVoteList sessionState={sessionState} />
      <br />
      <Divider />
      <br />
      <CBCExpertApplications sessionState={sessionState} />
    </div>
  );
};
