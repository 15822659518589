import { gql } from "@apollo/client";

export const INDIVIDUAL_PROFILE_FRAGMENT = gql`
  fragment IndividualProfile on IndividualProfile {
    id
    headline
    biography
    firstName
    fullName
    lastName
    linkedinProfileUrl
  }
`;

export const ORGANISATION_PROFILE_FRAGMENT = gql`
  fragment OrganisationProfile on OrganisationProfile {
    id
    headline
    description
    fullName
    name
    linkedinProfileUrl
  }
`;

export const PROFILE_FRAGMENT = gql`
  fragment Profile on Profile {
    ... on IndividualProfile {
      ...IndividualProfile
    }

    ... on OrganisationProfile {
      ...OrganisationProfile
    }
  }
  ${INDIVIDUAL_PROFILE_FRAGMENT}
  ${ORGANISATION_PROFILE_FRAGMENT}
`;
