import { gql } from "@apollo/client";

export const MUTATION = gql`
  mutation inviteUserToMattermost {
    payload: inviteUserToMattermost {
      success
    }
  }
`;

export interface Result {
  readonly payload?: InviteUserToMattermostPayload;
}

interface InviteUserToMattermostPayload {
  readonly success: boolean;
}
