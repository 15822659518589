import { useState, useMemo, useEffect, CSSProperties } from "react";
import { Form, Grid, Segment } from "semantic-ui-react";
import { ethers } from "ethersv5";
import { useHistory, useLocation } from "react-router-dom";
import { Enrolled } from "../../../../contexts/Session/state";
import { Linked } from "../../../../contexts/Chain/state";
import { Balances } from "./Balances";
import { Transfer } from "./Transfer";
import { AddressBookFormDropdown } from "../../../elements/AddressBookFormDropdown";
import { Transfers } from "./Transfers";
import { selectedRole } from "../../../../contexts/Session/helpers";
import { useBreadcrumbApi } from "../../../../contexts/Breadcrumb";
import { Shortcuts as S } from "../../../../routing";
import { ChainRoleManagement } from "../../Administration/Chain/ChainRoleManagement";
import { Role } from "../../../../types/role";

// Breadcrumb for governors needs to be wider for long role names. Breadcrumb line height must be fixed
const governorLeafStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "baseline",
};

enum FormIds {
  dropdownRole = "@Wallet/dropdownRole",
}

interface State {
  readonly busy: boolean;
  readonly error?: string;
}
const initialState: State = { busy: false };

interface Props {
  readonly sessionState: Enrolled;
  readonly chainState: Linked;
}

export const Wallet = ({ sessionState, chainState }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [{ busy, error }, setState] = useState(initialState);
  const { isGovernor } = sessionState.roleChainData;
  const breadcrumbApi = useBreadcrumbApi();
  const urlAddress = new URLSearchParams(location.search).get(S.wallet.queryVarNames.address) || "";
  const ethAddress = useMemo(() => {
    if (!urlAddress || urlAddress.trim().length === 0 || !ethers.utils.isAddress(urlAddress)) {
      history.push(S.myArea.path);
      return undefined;
    }

    return urlAddress;
  }, [history, urlAddress]);

  // The current selected role in MM.
  const me = useMemo(() => {
    const { roleId, user } = sessionState;
    return selectedRole(user.roles, roleId);
  }, [sessionState]);

  // Breadcrumb management.
  useEffect(() => {
    // Callback for when the dropdown role changes.
    const onDropdownRoleChange = (role?: Role) => {
      if (!role) {
        return;
      } else if (role.ethAddress !== ethAddress) {
        const search = new URLSearchParams({ [S.wallet.queryVarNames.address]: role.ethAddress });

        return history.replace({ search: search.toString() });
      }

      setState((s) => ({ ...s, dropdownRole: role }));
    };
    breadcrumbApi.addHeader(
      <div style={governorLeafStyle}>
        <div>Wallet of&nbsp;&nbsp;</div>
        <Form>
          <AddressBookFormDropdown
            key={ethAddress}
            id={FormIds.dropdownRole}
            viewOnly={!isGovernor}
            defaultSearch={ethAddress}
            selection
            clearable
            onSelectedRoleChange={onDropdownRoleChange}
            disabled={busy}
            error={error}
          />
        </Form>
      </div>
    );

    return () => breadcrumbApi.removeHeader();
  }, [history, breadcrumbApi, busy, ethAddress, error, isGovernor, me]);

  if (!ethAddress) {
    return null;
  }

  return (
    <div className="Wallet">
      <Grid>
        {isGovernor && (
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <ChainRoleManagement
                  key={ethAddress}
                  ethAddress={ethAddress}
                  chainState={chainState}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <Balances from={ethAddress} chainState={chainState} />
          </Grid.Column>
        </Grid.Row>
        <Transfer
          key={ethAddress}
          ethAddress={ethAddress}
          chainState={chainState}
          sessionState={sessionState}
        />
        <Grid.Row>
          <Grid.Column>
            <Transfers
              key={ethAddress}
              from={ethAddress}
              sessionState={sessionState}
              chainState={chainState}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
