import { gql } from "@apollo/client";
import { TicketSchema } from "../../schemas/tickets/_types";
import { Identifiable } from "../../types";
import { TickettingDraft } from "../../types/ticket";

export const MUTATION = gql`
  mutation RevertTicketToDraft($input: RevertTicketToDraftInput!) {
    payload: revertTicketToDraft(input: $input) {
      draft {
        id
        insertedAt
        form {
          data
        }
      }
      ticket {
        id
      }
    }
  }
`;

export interface Variables {
  readonly input: RevertTicketToDraftInput;
}

interface RevertTicketToDraftInput {
  readonly ethSignature: string;
  readonly ticketId: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: RevertTicketToDraftPayload;
}

interface RevertTicketToDraftPayload {
  readonly draft: TickettingDraft<TicketSchema>;
  readonly ticket: Identifiable;
}
