import React, { SyntheticEvent, useCallback } from "react";
import classNames from "classnames";
import { connectField, HTMLFieldProps } from "uniforms";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { isDate } from "moment";
import dateFormat from "dateformat";
import { SemanticDatepickerProps } from "react-semantic-ui-datepickers/dist/types";

const TODAY_AS_DATE = new Date();

interface Props extends HTMLFieldProps<string, HTMLElement> {
  readonly [k: string]: unknown;
  readonly format?: string;
  readonly minDateToday?: boolean;
  readonly maxDate?: Date;
}
export const DatePickerField = connectField((props: Props) => {
  const { label, value, onChange, error, errorMessage, showInlineError, maxDate } = props;
  const { className, disabled, required, format, placeholder, minDateToday } = props;

  const changeDueDate = useCallback(
    (_: SyntheticEvent<Element, Event> | undefined, { value: v }: SemanticDatepickerProps) => {
      if (v) {
        const dateOrString = isDate(v) ? dateFormat(v, "yyyy-mm-dd") : v.toLocaleString();
        onChange(dateOrString);
      } else {
        onChange(undefined);
      }
    },
    [onChange]
  );

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <SemanticDatepicker
        autoComplete="off"
        pointing="top left"
        size="small"
        minDate={minDateToday ? TODAY_AS_DATE : undefined}
        maxDate={maxDate}
        format={format}
        iconPosition="left"
        showToday={false}
        placeholder={placeholder}
        value={value ? new Date(value) : undefined}
        onChange={changeDueDate}
        className={!!error ? "modal-input error" : "modal-input"}
        error={!!error}
      />
      {!!(error && showInlineError) && (
        <div className="ui red basic left pointing label">{errorMessage}</div>
      )}
    </div>
  );
});
