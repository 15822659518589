import { gql } from "@apollo/client";
import { BidSchema } from "../../schemas/bids/_types";
import { EvaluateCompletionSchema } from "../../schemas/ticketDashboard/_types";
import { TicketSchema } from "../../schemas/tickets/_types";
import { Identifiable } from "../../types";
import { SimpleBid } from "../../types/bid";
import { JsonDocument } from "../../types/jsonDocument";
import { Connection } from "../../types/relay";
import { BaseTicketDeliverable, TicketCollaborationPreferences } from "../../types/ticket";
import { TicketPublishType, TicketState, TicketStatus } from "../../types/ticket";
import { OwnerWithAvatarField } from "./Tickets";

export const QUERY = gql`
  query GetTicketDetailsInfo($id: ID!, $roleId: ID!) {
    payload: node(id: $id) {
      ... on TickettingTicket {
        id
        state
        status
        awardedAt
        transmittedAt
        deliverySignaledAt
        deliveryApprovedAt
        deliveryDisprovedAt
        insertedAt
        hydratedAt
        publishType
        followedBy
        ticketForm(migrate: true) {
          data
          schemaVersion
        }
        owner {
          id
          fullName
          avatarUrl
        }
        winningBid {
          id
          owner {
            id
            fullName
            avatarUrl
          }
          form(migrate: true) {
            id
            data
            schemaVersion
          }
        }
        deliverables {
          id
          state
          dueDate
        }
        draftPlan {
          id
          insertedAt
          updatedAt
        }
        planFeedback(first: 1) {
          edges {
            node {
              id
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
        anyBid: tickettingBids(first: 1, state: [CLOSED, SUBMITTED]) {
          edges {
            node {
              id
            }
          }
        }
        ... on IsDiscussable {
          anyThread: discussionThreads(topic: "general", first: 1) {
            edges {
              node {
                id
              }
            }
          }
        }

        seal {
          message
          signature
        }
        submittedBidBy(bidderId: $roleId) {
          id
          state
          status
          owner {
            fullName
          }
          publishedAt
        }

        collaborationPreferenceEmail
        collaborationPreferenceMattermost
        collaborationPreferenceOther
        collaborationPreferencePhoneCall
        collaborationPreferenceVideoCall
        collaborationPreferenceWhatsappOrText
        feedbackEvaluations(scope: TICKETTING_TICKET_EVALUATION, first: 1) {
          edges {
            node {
              id
              insertedAt
              form(migrate: true) {
                data
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly id: string;
  readonly roleId: string;
}

export interface Result {
  readonly payload?: Ticket;
}

export interface WinningBid extends Identifiable {
  readonly owner: OwnerWithAvatarField;
  readonly form?: JsonDocument<BidSchema>;
}

export interface Ticket extends Identifiable, TicketCollaborationPreferences {
  readonly state: TicketState;
  readonly status: TicketStatus;
  readonly awardedAt?: string;
  readonly transmittedAt?: string;
  readonly deliverySignaledAt?: string;
  readonly deliveryApprovedAt?: string;
  readonly deliveryDisprovedAt?: string;
  readonly insertedAt: string;
  readonly hydratedAt?: string;
  readonly deliverables: BaseTicketDeliverable[];
  readonly ticketForm?: JsonDocument<TicketSchema>;
  readonly anyBid: Connection<Identifiable>;
  readonly anyThread: Connection<Identifiable>;
  readonly owner: OwnerWithAvatarField;
  readonly winningBid?: WinningBid;
  readonly draftPlan?: { readonly insertedAt: string; readonly updatedAt: string } & Identifiable;
  readonly planFeedback?: Connection<Identifiable>;
  readonly publishType?: TicketPublishType;
  readonly followedBy: boolean;
  readonly seal?: { readonly message: string; readonly signature: string };
  readonly submittedBidBy?: SimpleBid;
  readonly feedbackEvaluations?: Connection<FeedbackEvaluation>;
}

interface FeedbackEvaluation extends Identifiable {
  readonly insertedAt: string;
  readonly form: JsonDocument<EvaluateCompletionSchema>;
}
