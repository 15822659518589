import React, { useState, useCallback, FormEvent, CSSProperties } from "react";
import { CheckboxProps, Form, Grid, Segment } from "semantic-ui-react";
import { FormField, Button, Radio } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { TextAreaWithUploads, Fields as TextAreaFields } from "../TextAreaWithUploads";
import { Enrolled } from "../../../contexts/Session/state";
import { selectedRole } from "../../../contexts/Session/helpers";
import { useChainApi, useChainState } from "../../../contexts/Chain";
import { MUTATION, Result, Variables } from "../../../api/tickets/CreateDiscussionThread";
import { QUERY } from "../../../api/tickets/DiscussionThreadsOnIsDiscussable";
import { ApiError } from "../../../types";
import { commentPlainText } from "./qna/Question";

const alignRight: CSSProperties = { textAlign: "right" };

interface AskQuestionErrors {
  readonly text?: string;
  readonly isPrivate?: string;
}

interface Props {
  readonly discussableId: string;
  readonly description: string;
  readonly canChangeVisibility: boolean;
  readonly discussableMessage: string;
  readonly sessionState: Enrolled;
}

export const AskQuestion = (props: Props) => {
  const { sessionState } = props;
  const { discussableId, description, canChangeVisibility, discussableMessage } = props;
  const chainState = useChainState();
  const chainApi = useChainApi();
  const [isPrivate, setIsPrivate] = useState<boolean | undefined>(undefined);
  const [textAreafields, setTextAreaFields] = useState<TextAreaFields>({});
  const [errors, setErrors] = useState<AskQuestionErrors>();
  const [createQuestion] = useMutation<Result, Variables>(MUTATION, {
    refetchQueries: [{ query: QUERY, variables: { discussableId } }],
  });

  const onRadioChange = useCallback((_: FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
    setIsPrivate(value === "true");
    setErrors((s) => ({ ...s, isPrivate: undefined }));
  }, []);

  const onInputChange = useCallback((arg: React.SetStateAction<TextAreaFields>) => {
    setTextAreaFields(arg);
    setErrors((s) => ({ ...s, text: undefined }));
  }, []);

  const onSubmit = useCallback(() => {
    const e = validateInputs(canChangeVisibility, isPrivate, textAreafields.text);
    if (e) {
      return setErrors(e);
    } else if (!textAreafields.text) {
      return null;
    }

    const date = new Date();
    const transmittedAt = date.toISOString();
    const owner = selectedRole(sessionState.user.roles, sessionState.roleId).fullName || "Unknown";
    const { chainId } = sessionState;
    const title = `Question from ${owner}`;
    const body = textAreafields.text;
    const digest = commentPlainText(
      chainId,
      date,
      owner,
      title,
      body,
      discussableMessage,
      textAreafields?.documents || []
    );

    chainApi
      .sign(chainState, digest)
      .then(async (ethSignature) => {
        return createQuestion({
          variables: {
            input: {
              commentInput: {
                body,
                ethSignature,
                title,
                transmittedAt,
              },
              documents: textAreafields.documents?.map((d) => d.id),
              discussableId,
              private: canChangeVisibility ? !!isPrivate : true,
              topic: "general",
            },
          },
        }).then(() => {
          setIsPrivate(undefined);
          setTextAreaFields({});
          toast.success("Your question was submited.");
        });
      })
      .catch((err: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (err.message.includes("User denied message signature")) {
          return;
        }
        toast.error(err.message);
      });
  }, [
    textAreafields,
    isPrivate,
    canChangeVisibility,
    sessionState,
    chainApi,
    chainState,
    createQuestion,
    discussableId,
    discussableMessage,
  ]);

  return (
    <Segment className="AskAQuestion">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div className="AskAQuestion-header">Ask a Question</div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <div className="AskAQuestion-description">{description}</div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <TextAreaWithUploads
              fields={textAreafields}
              onChange={onInputChange}
              error={errors?.text}
              maxChars={1000}
              rows={7}
              placeholder="Enter maximum 1000 characters..."
            />
          </Grid.Column>
        </Grid.Row>
        {canChangeVisibility && (
          <Grid.Row>
            <Grid.Column>
              <Form>
                <FormField>
                  <label className="AskAQuestion-label">Who can view this question?</label>
                  {errors?.isPrivate && (
                    <div className="ui pointing above prompt label">{errors.isPrivate}</div>
                  )}
                </FormField>
                <FormField>
                  <Radio
                    label="Only the ticket owner"
                    name="readOwnerOnly"
                    value="true"
                    onChange={onRadioChange}
                    checked={isPrivate === true}
                  />
                </FormField>
                <FormField>
                  <Radio
                    label="Anyone who can see the ticket"
                    name="readOwnerOnly"
                    value="false"
                    onChange={onRadioChange}
                    checked={isPrivate === false}
                  />
                </FormField>
              </Form>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column style={alignRight}>
            <Button className="AskAQuestion-button" onClick={onSubmit}>
              Submit
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const validateInputs = (
  canChangeVisibility: boolean,
  isPrivate?: boolean,
  text?: string
): AskQuestionErrors | undefined => {
  let errors: AskQuestionErrors = {};
  if (canChangeVisibility && isPrivate === undefined) {
    errors = { ...errors, isPrivate: "Select the visibility for this question." };
  }
  if (text === undefined || text.length < 5) {
    errors = { ...errors, text: "Submit an answer with atleast 5 chars." };
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};
