export type Maybe<T> = T | null;

export interface Typed<T> {
  readonly type: T;
}

export interface Identifiable {
  readonly __typename: string;
  readonly id: string;
}

/**
 * From T, pick a set of properties whose values are of type TK.
 */
export type FilteredKeyOf<T, TK> = keyof Pick<
  T,
  { [K in keyof T]: T[K] extends TK ? K : never }[keyof T]
>;

export const hasOwnProperty = <K extends PropertyKey>(o: unknown, k: K): o is Record<K, unknown> =>
  typeof o === "object" && !!o && o.hasOwnProperty(k);

export const ensureKeyOf = <T, K extends keyof T>(_: T, k: K): K => k;

export const isKeyOfAtRuntime = <T>(maybeObjKey: any, objInstance: T): maybeObjKey is keyof T =>
  maybeObjKey in objInstance;

export const isEnum = <T>(value: unknown, e: T): value is T[keyof T] =>
  Object.values(e).includes(value as T[keyof T]);

export const isNumericEnum = <T extends { [k: string]: unknown }>(
  value: unknown,
  e: T
): value is T[keyof T] => typeof value === "number" && valuesOfNumericEnum(e).includes(value);

export const valuesOfNumericEnum = <E extends { [k: string]: unknown }>(e: E): number[] =>
  Object.keys(e).reduce((acc, key) => {
    const value = e[key];
    return typeof value === "number" ? [...acc, value] : acc;
  }, [] as number[]);

export const isDefined = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined;

/**
 * Exclude type R from properties in T.
 */
export type ExcludePropType<T, R> = {
  [k in keyof T]: Exclude<T[k], R>;
};

export const withoutNullValues = <T extends { [key: string]: any }>(
  value: T
): value is ExcludePropType<T, null> => {
  for (const key of Object.keys(value)) {
    if (value[key] === null) {
      return false;
    }
  }
  return true;
};

export const withDefault = <T>(maybe: Maybe<T>, fallback: T) => (maybe !== null ? maybe : fallback);

export const withDefaultInside = <O, K extends keyof O>(
  object: Maybe<O>,
  key: K,
  fallback: O[K]
): Readonly<O[K]> => (object && object[key] ? object[key] : fallback);

export const isEmpty = (what: Maybe<string> | undefined): what is null | undefined =>
  !what || what.trim().length === 0;

export const isString = (value: unknown): value is string => typeof value === "string";

export const isStringArray = (v: unknown): v is string[] => {
  if (Array.isArray(v)) {
    for (const el of v) {
      if (!isString(el)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const isEnumArray = <T>(v: unknown, e: T): v is T[keyof T][] => {
  if (Array.isArray(v)) {
    for (const el of v) {
      if (!isEnum(el, e)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const isStringIndexedObject = (
  v: unknown
): v is {
  [key: string]: unknown;
} => typeof v === "object" && isDefined(v) && !Object.keys(v).find((k) => typeof k !== "string");

export const isNumeric = (n: number | bigint | string): boolean => {
  const sN = n.toString();
  if ((sN.match(/\./g) || []).length > 1) {
    return false;
  } else if (!sN.match(/^-?\d+\.?\d*$/)) {
    return false;
  }
  return true;
};

export const isValidUrl = (url: string) => {
  try {
    return url.startsWith("https://") && !!new URL(url);
  } catch {
    return false;
  }
};

export const assertUnreachable = (v: never): never => {
  throw new Error(`Invalid type ${v}.`);
};

export const capitalize = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
