import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { requireEnrolled, useSessionState } from "../../../contexts/Session";
import { QUERY, Variables, Result } from "../../../api/onboards/ViewAllOnboards";
import { Shortcuts as S } from "../../../routing";
import { EntityKind, EntityType, OnboardState } from "../../../types/onboard";
import { useQuery } from "@apollo/client";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { extractErrorMessages } from "../../../types";
import { LoaderWithMargin } from "../../Loader";
import { ExpertOnboardCard } from "./ExpertOnboardCard";
import { nodesFromEdges } from "../../../types/relay";
import { PageContentInfinityScroll } from "../../InfinityScrollWrapper";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { ExpertOnboardingBreadcrumb } from "./ExpertOnboardingBreadcrumb";
import { Segment } from "semantic-ui-react";
import { secondedOnboardsStepStates, inCommitteeReviewStates, awatingSeconderStepStates } from ".";

const first = 12;

export const ViewAllExpertOnboards = () => {
  const location = useLocation();
  const sessionState = useSessionState();
  const breadcrumbApi = useBreadcrumbApi();

  const { roleId } = requireEnrolled(sessionState);

  const isAwaitingSeconders = location.pathname === S.expertOnboardingAwaitingSeconder.path;
  const isMutualAssessment = location.pathname === S.expertOnboardingMutualAssessment.path;

  const state: ReadonlyArray<OnboardState> = useMemo(() => {
    if (isAwaitingSeconders) {
      return awatingSeconderStepStates;
    }
    if (location.pathname === S.expertOnboardingMutualAssessment.path) {
      return secondedOnboardsStepStates;
    }
    if (location.pathname === S.expertOnboardingCBCVoting.path) {
      return inCommitteeReviewStates;
    } else {
      return [];
    }
  }, [location, isAwaitingSeconders]);

  const queryVars: Variables = useMemo(() => {
    return {
      roleId,
      first,
      seconderId: isMutualAssessment ? roleId : undefined,
      state,
      type: [EntityType.Individual, EntityType.Organisation],
      kind: [EntityKind.Provider],
    };
  }, [roleId, state, isMutualAssessment]);

  const queryData = useQuery<Result, Variables>(QUERY, {
    variables: queryVars,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-only",
  });
  const { data, loading, error, fetchMore } = queryData;
  const pageInfo = data?.onboards?.pageInfo;

  const cards = useMemo(
    () =>
      nodesFromEdges(data?.onboards?.edges).map((o) => (
        <ExpertOnboardCard key={o.id} onboard={o} />
      )),
    [data]
  );

  useEffect(() => {
    breadcrumbApi.showBreadcrumbs(false);
    breadcrumbApi.addBody(<ExpertOnboardingBreadcrumb />);

    return () => {
      breadcrumbApi.showBreadcrumbs(true);
      breadcrumbApi.removeBody();
    };
  }, [breadcrumbApi]);

  const onNext = useCallback(() => {
    if (!fetchMore || !pageInfo?.hasNextPage) {
      return;
    }
    fetchMore({
      variables: { ...queryVars, after: pageInfo.endCursor },
      updateQuery: (prev, { fetchMoreResult: fetchRes }) => {
        if (!fetchRes) {
          return prev;
        } else if (!fetchRes.onboards || !fetchRes.onboards.pageInfo) {
          return fetchRes;
        }
        const prevEdges = prev.onboards?.edges || [];
        const newEdges = fetchRes.onboards.edges || [];
        const newOnboards = fetchRes.onboards;
        return {
          ...fetchRes,
          onboards: { ...newOnboards, edges: [...prevEdges, ...newEdges] },
        };
      },
    });
  }, [fetchMore, pageInfo, queryVars]);

  if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (loading && !data) {
    return (
      <div>
        <LoaderWithMargin />
      </div>
    );
  } else if (!data || !data.onboards) {
    return <>No data</>;
  } else if (cards.length <= 0) {
    return <Segment>No experts</Segment>;
  }

  return (
    <PageContentInfinityScroll
      dataLength={cards.length}
      next={onNext}
      hasMore={!!pageInfo?.hasNextPage}
      loader={loading ? <LoaderWithMargin /> : null}
    >
      <div className="ExpertCardContainer">{cards}</div>
    </PageContentInfinityScroll>
  );
};
