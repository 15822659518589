import { memo } from "react";
import { Icon } from "semantic-ui-react";
import { ReceivedOnboard } from "../../../types/onboard";
import { stepDetails } from "../../pages/Dashboard/applications/application/Panes/types";

interface Props {
  readonly onboard: ReceivedOnboard;
  readonly isActor: boolean;
}

export const Stepper = memo(({ onboard, isActor }: Props) => (
  <div className="Stepper">
    <div className="Stepper-title">Application Progress</div>
    {stepDetails(onboard, isActor).map((details, i) => {
      const { title, active, disabled, completed, rejected } = details;
      let className = "";
      let icon: JSX.Element | null = null;
      if (completed) {
        className = "completed";
        icon = <Icon name="check circle" color="green" />;
      } else if (active) {
        className = "active";
        icon = <Icon name="circle" color="blue" />;
      } else if (disabled) {
        className = "disabled";
        icon = <Icon name="circle" color="grey" />;
      } else if (rejected) {
        className = "rejected";
        icon = <Icon name="times circle" color="red" />;
      } else {
        console.warn("Impossible stepper state detected", completed, active, disabled, rejected);
      }

      return (
        <div key={i} className={`Stepper-step ${className}`}>
          {icon}
          {title}
        </div>
      );
    })}
  </div>
));
