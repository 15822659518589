import { Image } from "semantic-ui-react";
import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
// Assets.
import rocketIcon from "../../../../assets/rocket-icon.png";
import expertIcon from "../../../../assets/expert-icon.png";
import { Enrolled } from "../../../../contexts/Session/state";

interface Props {
  readonly ticket: Ticket;
  readonly sessionState: Enrolled;
}

enum RoleLabel {
  Expert = "Expert",
  Startup = "Startup",
  Insurer = "Insurer",
}

export const TicketTeamSection = ({ ticket, sessionState }: Props) => {
  const roleId = sessionState.roleId;
  const { owner, winningBid } = ticket;

  return (
    <div>
      <div className="ComponentHeader">Team</div>
      <div className="TicketTeamSection">
        {owner.id === roleId && (
          <>
            <TeamSectionMember
              avatarUrl={winningBid?.owner.avatarUrl || expertIcon}
              name={winningBid?.owner.fullName || "Unknown"}
              role={RoleLabel.Expert}
              isUser={winningBid?.owner.id === roleId}
            />
            <TeamSectionMember
              avatarUrl={owner.avatarUrl || rocketIcon}
              name={owner.fullName || "Unknown"}
              role={RoleLabel.Startup}
              isUser={owner.id === roleId}
            />
          </>
        )}
        {winningBid?.owner.id === roleId && (
          <>
            <TeamSectionMember
              avatarUrl={owner.avatarUrl || rocketIcon}
              name={owner.fullName || "Unknown"}
              role={RoleLabel.Startup}
              isUser={owner.id === roleId}
            />
            <TeamSectionMember
              avatarUrl={winningBid?.owner.avatarUrl || expertIcon}
              name={winningBid?.owner.fullName || "Unknown"}
              role={RoleLabel.Expert}
              isUser={winningBid?.owner.id === roleId}
            />
          </>
        )}
      </div>
      <br />
      <br />
    </div>
  );
};

interface TeamSectionMemberProps {
  readonly avatarUrl: string;
  readonly name: string;
  readonly role: string;
  readonly isUser: boolean;
}

const TeamSectionMember = ({ avatarUrl, isUser, name, role }: TeamSectionMemberProps) => {
  return (
    <div className="TicketTeamSection-member">
      <Image src={avatarUrl} circular />
      <div className="TicketTeamSection-member-name">{name + (isUser ? " (You)" : "")}</div>
      <div className="TicketTeamSection-member-role">{role}</div>
    </div>
  );
};
