import { useCallback } from "react";
import { connectField, HTMLFieldProps } from "uniforms";
import { TextField } from "uniforms-semantic";
import { InputFieldCharCount } from "../components/elements/InputFieldCharCount";
import { isEmpty } from "../types";

interface Props extends HTMLFieldProps<string, HTMLDivElement> {
  readonly [k: string]: unknown;
  readonly showtextcount?: string;
}

export const CustomTextField = connectField(({ showtextcount, ...props }: Props) => {
  const { maxLength, placeholder, onChange } = props;
  const pholder = maxLength && !placeholder ? `Enter up to ${maxLength} characters` : placeholder;

  // Custom on change to convert empty strings to undefined istead.
  const customOnChange = useCallback(
    (v?: string) => onChange(isEmpty(v) ? undefined : v),
    [onChange]
  );

  return (
    <>
      <TextField
        {...props}
        name=""
        placeholder={pholder}
        maxLength={maxLength}
        onChange={customOnChange}
      />
      {maxLength !== undefined && showtextcount === undefined && (
        <InputFieldCharCount maxChars={maxLength} currentChars={props.value?.length || 0} />
      )}
    </>
  );
});
