import { Button, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import mmLogo from "../../../assets/mattermost-logo.png";
import nextcloudLogo from "../../../assets/nextcloud-logo.svg";
import invoiceIcon from "../../../assets/invoice-icon.svg";
import metamaskLogo from "../../../assets/metamask-icon.svg";
import cvLogoPurple from "../../../assets/cv-logo-purple.svg";
import { utils } from "../../../utils/utils";

const mattermostLinkTo = { pathname: "https://bit.ly/3FOlau0" };
const nextcloudLinkTo = { pathname: "https://bit.ly/3FLBfk5" };
const expertInvoiceLinkTo = { pathname: "https://bit.ly/3DKkaFF" };
const industrycommitteLinkTo = { pathname: "https://bit.ly/3p9OVzs" };
const metamaskLinkTo = { pathname: "https://bit.ly/2YXX5Ai" };
const faqsLinkTo = {
  pathname: "https://consilienceventures.myeu.cloud/index.php/s/YyNoAf8fZaXGfzQ",
};
const welcomePackLinkTo = {
  pathname: `${utils.apiHost}/static/files/welcome-pack-2021-11-latest.pdf`,
};

export const CommunityResources = () => {
  return (
    <div className="CommunityResources">
      <div className="CommunityResources-top-section">
        <div> Learn more about the resources our community uses to collaborate and connect.</div>
        <div className="CommunityResources-link">
          <Link to={faqsLinkTo} target="_blank">
            FAQs
          </Link>
        </div>
      </div>
      <br />
      <br />
      <div className="CommunityResources-cardContainer">
        <Card
          image={nextcloudLogo}
          title="Nextcloud"
          description="Access a repository of important Marketing, Legal, and Process documents created by CV
            members."
          linksTo={nextcloudLinkTo}
        />

        <Card
          image={mmLogo}
          title="Mattermost"
          description="The best way to stay in the loop on important CV announcements and news updates. You can
          also participate in investment decisions, and easily connect with other members."
          linksTo={mattermostLinkTo}
        />

        <Card
          image={metamaskLogo}
          title="Metamask"
          description="Our recommended tool to securely connect to our blockchain through your web browser."
          linksTo={metamaskLinkTo}
        />

        <Card
          image={cvLogoPurple}
          title="Welcome Pack"
          description="Learn more about Consilience Ventures and opportunities to get involved."
          buttonsTo={welcomePackLinkTo}
        />

        <Card
          image={invoiceIcon}
          title="Expert invoice form"
          description="Submit your invoices for work performed as part of a Startup Review Committee, Mutual
            Assessment, or any other work."
          buttonsTo={expertInvoiceLinkTo}
        />

        <Card
          image={invoiceIcon}
          title="Industry Committee invoice form"
          description="Submit your invoices for work performed as part of an Industry Committee."
          buttonsTo={industrycommitteLinkTo}
        />
      </div>
    </div>
  );
};

interface CardProps {
  readonly image: string;
  readonly title: string;
  readonly description: string;
  readonly linksTo?: { readonly pathname: string };
  readonly buttonsTo?: { readonly pathname: string };
}

const Card = ({ image, title, description, linksTo, buttonsTo }: CardProps) => (
  <div className="CommunityResources-card">
    <div className="CommunityResources-imageContainer">
      <div className="CommunityResources-card-image">
        <Image src={image} />
      </div>
    </div>
    <div className="CommunityResources-card-title">{title}</div>
    <div className="CommunityResources-card-description">{description}</div>
    {linksTo && (
      <Link to={linksTo} target="_blank">
        Website
      </Link>
    )}
    {buttonsTo && (
      <>
        <br />
        <Button as={Link} to={buttonsTo} target="_blank" color="purple" content="View" />
      </>
    )}
  </div>
);
