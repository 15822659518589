import { gql } from "@apollo/client";
import { TickettingBidEvaluation } from "../../types/bid";

export const MUTATION = gql`
  mutation IssueTicketBidEvaluation($input: IssueTicketBidEvaluationInput!) {
    payload: issueTicketBidEvaluation(input: $input) {
      publishBidEvaluation {
        id
        insertedAt
        owner {
          id
          fullName
        }
        form {
          id
          data
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: IssueTicketBidEvaluationInput;
}

interface IssueTicketBidEvaluationInput {
  readonly bidId: string;
  readonly ethSignature: string;
  readonly form?: string;
  readonly ownerId: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: IssueTicketBidEvaluationPayload;
}

interface IssueTicketBidEvaluationPayload {
  readonly bidEvaluation: TickettingBidEvaluation;
}
