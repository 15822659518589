import { gql } from "@apollo/client";
import { DOCUMENT_FRAGMENT } from "../_fragments/Document";
import { Document } from "../../types/document";

export const MUTATION = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    payload: createDocument(input: $input) {
      document {
        ...Document
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export interface Variables {
  readonly input: { readonly upload: File };
}

export interface Result {
  readonly payload?: { readonly document: Document };
}
