import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const QUERY = gql`
  query MyBallot($onboardId: ID!, $roleId: ID!, $kind: String!) {
    node(id: $onboardId) {
      ... on Onboard {
        id
        proposal(kind: $kind) {
          id
          ballot(roleId: $roleId) {
            id
            choice
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly onboardId: string;
  readonly kind: string;
  readonly roleId: string;
}

export interface Result<C> {
  readonly node?: {
    readonly proposal?: {
      readonly ballot?: {
        readonly choice: C;
      } & Identifiable;
    };
  };
}
