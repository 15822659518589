import { CSSProperties, useCallback, useEffect, useMemo } from "react";
import { connectField, HTMLFieldProps } from "uniforms";
import { TagNamespace } from "../types/labelQuery";
import { utils } from "../utils/utils";
import { CustomPriorityItemField, Option } from "./CustomPriorityItemField";

const extraLabelStyle: CSSProperties = {
  fontSize: "14px",
  lineHeight: "22px",
  color: "#4A4A4A",
  marginTop: "-5px",
  marginBottom: "18px",
};

interface Props extends HTMLFieldProps<(string | undefined)[], HTMLDivElement> {
  readonly [k: string]: unknown;
  readonly options: Option[];
  readonly tagNamespace: TagNamespace;
  readonly extraLabel?: string;
}

export const CustomPriorityListField = connectField<Props>(
  (props: Props) => {
    const { required, label, error, errorMessage, showInlineError, extraLabel, field } = props;
    const { value, options, tagNamespace, onChange } = props;

    const maxItems = useMemo(() => field.maxItems || 0, [field]);
    const templateArray: string[] = useMemo(() => {
      return new Array(maxItems).fill(undefined);
    }, [maxItems]);

    // Even tho the UI doesn't update on the first render, this hook ensures the value will have lenght equal to the expected maxItems.
    useEffect(() => {
      if (value?.length === maxItems) {
        return;
      }
      onChange(Array.from({ length: maxItems }, (_, i) => (value ? value[i] : undefined)));
    }, [value, maxItems, onChange]);

    const moveItemUp = useCallback(
      (fromIndex: number) => {
        if (fromIndex === 0) {
          // We don't move up the first index of the array.
          return;
        }

        const toIndex = fromIndex - 1;
        const newArray = utils.swapArrayElements(value || [], fromIndex, toIndex);
        onChange(newArray);
      },
      [value, onChange]
    );

    const moveItemDown = useCallback(
      (fromIndex: number) => {
        if (fromIndex === maxItems - 1) {
          // We don't move down the last index of the array.
          return;
        }

        const toIndex = fromIndex + 1;
        const newArray = utils.swapArrayElements(value || [], fromIndex, toIndex);
        onChange(newArray);
      },
      [value, maxItems, onChange]
    );

    return (
      <div className={`${required ? "required " : ""}field`}>
        <label>{label}</label>
        {extraLabel && <div style={extraLabelStyle}>{extraLabel}</div>}

        {templateArray.map((_, idx) => (
          <CustomPriorityItemField
            key={`priority-item-${idx}`}
            name={idx.toString()}
            options={options}
            tagNamespace={tagNamespace}
            maxItems={maxItems}
            moveItemUp={moveItemUp}
            moveItemDown={moveItemDown}
            errorMessage="This field is mandatory."
            parentError={error}
          />
        ))}

        {!!(error && showInlineError) && (
          <div className="ui red basic pointing label">{errorMessage}</div>
        )}
      </div>
    );
  },
  { kind: "node" }
);
