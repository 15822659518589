import { useMemo } from "react";
import { VoteIconWithCount } from "./VoteIconWithCount";
import { preselectionVotesCountByChoice } from "../../../types/proposal";
import { ProposalTallyItem } from "../../../types/proposal";
import { PreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";

interface Props {
  readonly tally?: ReadonlyArray<ProposalTallyItem<PreselectionProposalChoice>>;
  readonly size?: "big";
  readonly orientation?: "vertical" | "horizontal";
}
export const VoteCounters = ({ tally, size, orientation }: Props) => {
  const { upvotesCount, neutralsCount, downvotesCount } = useMemo(
    () => preselectionVotesCountByChoice(tally),
    [tally]
  );

  if (!tally) {
    return null;
  }

  return (
    <div className={`VoteCounters${orientation === "vertical" ? "-vertical" : "-horizontal"}`}>
      <VoteIconWithCount choice={PreselectionProposalChoice.Yes} count={upvotesCount} size={size} />
      <VoteIconWithCount
        choice={PreselectionProposalChoice.No}
        count={downvotesCount}
        size={size}
      />
      <VoteIconWithCount
        choice={PreselectionProposalChoice.Neutral}
        count={neutralsCount}
        size={size}
      />
    </div>
  );
};
