import { gql } from "@apollo/client";
import { digestFormData, digestObj } from "../../types/OnboardForm";
import { ONBOARD_SEAL_FIELDS, OnboardSealFields } from "./_fragments/OnboardSealFields";

export const QUERY = gql`
  query OnboardSeal($id: ID!) {
    fields: node(id: $id) {
      ...OnboardSealFields
    }
  }
  ${ONBOARD_SEAL_FIELDS}
`;

export interface SealQueryResult {
  readonly fields: OnboardSealFields;
}

export const onboardDigest = (
  { fullName, recipientFullName, form }: OnboardSealFields,
  chainId: number
): string =>
  `-- Onboard Digest ${chainId}\n` +
  `  Name: ${fullName}\n` +
  `  Applicant Name: ${recipientFullName}\n` +
  `\n` +
  `-- Json Document Digest ${chainId}\n` +
  `  Data: ${digestFormData(form.data)}`;

export const onboardSubject = (fields: OnboardSealFields, insertedAt: Date): OnboardSealFields => ({
  ...fields,
  form: digestObj(fields.form, false) as any,
  insertedAt: insertedAt.toISOString().split(".")[0] + "Z",
});
