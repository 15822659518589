import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { Connection, ConnectionVariables } from "../../types/relay";
import { TicketWithPlanFeedback } from "../../types/ticket";
import { TicketBidStatus } from "../../types/bid";
import { PAGEINFO_FRAGMENT } from "../_fragments/PageInfo";

export const QUERY = gql`
  query MyProjects(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $roleId: ID!
    $status: [TicketBidStatus!]
  ) {
    role: node(id: $roleId) {
      ... on Role {
        id
        bidConnection: tickettingBids(
          after: $after
          before: $before
          first: $first
          last: $last
          roleId: $roleId
          status: $status
        ) {
          edges {
            node {
              id
              ticket {
                id
                insertedAt
                state
                status
                newQuestionCount
                owner {
                  id
                  fullName
                }
                ticketForm(migrate: true) {
                  id
                  data
                  schemaVersion
                }
                draftPlan {
                  id
                  insertedAt
                  updatedAt
                }
                planFeedback(first: 1) {
                  edges {
                    node {
                      id
                      feedback
                      insertedAt
                      owner {
                        id
                        fullName
                        avatarUrl
                      }
                    }
                  }
                  pageInfo {
                    ...PageInfo
                  }
                }
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PAGEINFO_FRAGMENT}
`;

export interface Variables extends ConnectionVariables {
  readonly roleId: string;
  readonly status?: ReadonlyArray<TicketBidStatus>;
}

interface Bid extends Identifiable {
  readonly ticket: TicketWithPlanFeedback;
}

interface RoleNode extends Identifiable {
  readonly bidConnection?: Connection<Bid>;
}

export interface Result {
  readonly role?: RoleNode;
}
