import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { OnboardState, OnboardStatus } from "../../types/onboard";
import { SeconderCandidaciesStates } from "../../types/seconderCandidacy";

export const MUTATION = gql`
  mutation AssignAsOnboardSeconder($input: AssignAsOnboardSeconderInput!) {
    assignAsOnboardSeconder(input: $input) {
      seconderCandidacy {
        id
        state
        updatedAt
        assignedAt

        onboard {
          id
          state
          status
        }
      }
    }
  }
`;

interface AssignAsOnboardSeconderInput {
  readonly seconderCandidacyId: string;
}

export interface Variables {
  readonly input: AssignAsOnboardSeconderInput;
}

export interface Result {
  readonly seconderCandidacy: {
    readonly state: SeconderCandidaciesStates;
    readonly updatedAt: string;
    readonly assignedAt?: string;
    readonly onboard: {
      readonly state: OnboardState;
      readonly status: OnboardStatus;
    } & Identifiable;
  } & Identifiable;
}
