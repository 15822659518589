import { gql } from "@apollo/client";

export const UPLOADED_FILE_FRAGMENT = gql`
  fragment UploadedFile on UploadedFile {
    id
    contentType
    filename
    hash
    size
  }
`;
