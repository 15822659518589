import { gql } from "@apollo/client";

export const ROLE_SEAL_FIELDS = gql`
  fragment RoleSealFields on Role {
    fullName
  }
`;

export interface RoleSealFields {
  readonly fullName: string;
}
