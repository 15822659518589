import React, { useCallback } from "react";
import { Button, Container, Modal } from "semantic-ui-react";
import { SignatureButton } from "../../../../SignatureButton";

interface Props {
  readonly bidOwner: string;
  readonly onGeneralClose: () => void;
  readonly sign: () => void;
}

export const ModalRejectBid = ({ bidOwner, onGeneralClose, sign }: Props) => {
  const handleAccept = useCallback(() => sign(), [sign]);

  return (
    <Modal
      className="CustomClose"
      onClose={onGeneralClose}
      open={true}
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>You are declining this bid</Modal.Header>
      <Modal.Content>
        Note that {bidOwner} will be notified immediately and no further action will be available
        for this bid.
        <br />
        <br />
        <Container>
          <Button onClick={onGeneralClose}>Cancel</Button>
          <SignatureButton primary onClick={handleAccept} floated="right" content="Agree" />
        </Container>
      </Modal.Content>
    </Modal>
  );
};
