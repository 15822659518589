import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Divider, Grid, Image, Label, Loader, Segment } from "semantic-ui-react";
import { QUERY, Variables, Result } from "../../../api/admin/UserProfile";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { OnboardMigrator } from "../../../migrations/Onboards";
import { Shortcuts as S } from "../../../routing";
import { extractErrorMessages } from "../../../types";
import { TagNamespace } from "../../../types/labelQuery";
import { nodesFromEdges } from "../../../types/relay";
import { isIndividualRole, isProviderRole, readableRoleType } from "../../../types/role";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { OnePagerButton } from "../../elements/OnePagerButton";
import { TextClamp } from "../../elements/TextClamp";

export const AdminUserDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const userId = search.get(S.administrationUser.queryVarNames.id) || "";
  const roleId = search.get(S.administrationUser.queryVarNames.role) || "";
  const breadcrumbApi = useBreadcrumbApi();
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, { variables: { userId } });

  useEffect(() => {
    if (!data || !data.user) {
      return;
    }
    const { fullName: userFullName, avatarUrl, insertedAt } = data.user;

    breadcrumbApi.addCustomTitle(`Profile - ${userFullName}`);
    breadcrumbApi.showBreadcrumbs(true);
    breadcrumbApi.addBody(
      <>
        <Divider />
        <div className="AdminUserProfileHeader">
          <Image src={avatarUrl} circular className="AdminUserProfileHeader-image" />
          <div>
            <div className="AdminUserProfileHeader-title">
              <TextClamp text={userFullName} />
            </div>
            <Grid>
              <Grid.Column width="16">
                <Grid.Row>
                  <div className="AdminUserProfileHeader-label">Started on</div>
                </Grid.Row>
                <Grid.Row className="AdminUserProfileHeader-value">{insertedAt}</Grid.Row>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </>
    );

    return () => {
      breadcrumbApi.removeBody();
      breadcrumbApi.removeCustomTitle();
    };
  }, [data, breadcrumbApi]);

  const onRoleClick = useCallback(
    ({ currentTarget: { id } }: React.MouseEvent) => {
      const params = new URLSearchParams(location.search);
      params.set(S.administrationUser.queryVarNames.role, id);
      history.replace(`${S.administrationUser.path}?${params.toString()}`);
    },
    [history, location]
  );

  const roles = useMemo(() => nodesFromEdges(data?.user?.roles?.edges), [data]);
  const selectedRole = useMemo(() => {
    const role = roles.find((r) => r.id === roleId);
    if (!role && roles.length === 0) {
      return undefined;
    }
    return role || roles[0];
  }, [roleId, roles]);

  const { sectors, occupations, maturities, skills } = useMemo(() => {
    const { tags } = selectedRole || {};
    return {
      sectors: tags?.filter((t) => t.ns === TagNamespace.Sectors).map((t) => t.description),
      occupations: tags?.filter((t) => t.ns === TagNamespace.Occupations).map((t) => t.description),
      maturities: tags?.filter((t) => t.ns === TagNamespace.Maturities).map((t) => t.description),
      skills: tags?.filter((t) => t.ns === TagNamespace.Skills).map((t) => t.description),
    };
  }, [selectedRole]);

  if (loading) {
    return <Loader active inline="centered" />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data?.user) {
    return <Segment textAlign="center">No user found...</Segment>;
  } else if (!selectedRole) {
    return <Segment textAlign="center">This user has no roles yet</Segment>;
  }

  const { fullName, profile, onboard } = selectedRole;
  let documentId: string | undefined;
  let documentName: string;

  if (isProviderRole(selectedRole)) {
    documentName = isIndividualRole(selectedRole) ? "Curriculum Vitae" : "One-Pager";
  } else {
    const { migratedForm } = new OnboardMigrator(onboard).migrate();
    documentId = migratedForm?.data.startupData?.pitchDeck?.id;
    documentName = "Pitch Deck";
  }

  return (
    <div className="AdminUserProfile">
      <div className="AdminUserProfile-RolesMenu">
        {roles.map((r) => {
          const isActive = selectedRole.id === r.id;
          const cName = `AdminUserProfile-RolesMenu-role${isActive ? " active" : ""}`;

          return (
            <div key={r.id} id={r.id} className={cName} onClick={onRoleClick}>
              <Image className="AdminUserProfile-RolesMenu-role-img" src={r.avatarUrl} circular />
              <TextClamp className="AdminUserProfile-RolesMenu-role-text" text={r.fullName} />
            </div>
          );
        })}
      </div>
      <Segment>
        <div className="AdminUserProfile-roleName">
          {fullName}{" "}
          {!!profile.linkedinProfileUrl && (
            <a href={profile.linkedinProfileUrl} target="_blank" rel="noopener noreferrer">
              Linkedin
            </a>
          )}
        </div>
        <div className="AdminUserProfile-roleTypeAndKind">{readableRoleType(selectedRole)}</div>
        <div className="AdminUserProfile-roleHeadline">{profile.headline}</div>

        {documentId && (
          <div>
            <b>{documentName}: </b>
            <OnePagerButton nodeId={documentId} />
          </div>
        )}

        {sectors && sectors.length > 0 && (
          <>
            <div className="AdminUserProfile-sectionTitle">Sectors</div>
            <Divider className="AdminUserProfile-divider" />
            <Label.Group>
              {sectors.map((s) => (
                <Label key={s} content={s} />
              ))}
            </Label.Group>
          </>
        )}

        {occupations && occupations.length > 0 && (
          <>
            <div className="AdminUserProfile-sectionTitle">Occupations</div>
            <Divider className="AdminUserProfile-divider" />
            <Label.Group>
              {occupations.map((s) => (
                <Label key={s} content={s} />
              ))}
            </Label.Group>
          </>
        )}

        {skills && skills.length > 0 && (
          <>
            <div className="AdminUserProfile-sectionTitle">Skills</div>
            <Divider className="AdminUserProfile-divider" />
            <Label.Group>
              {skills.map((s) => (
                <Label key={s} content={s} />
              ))}
            </Label.Group>
          </>
        )}

        {maturities && maturities.length > 0 && (
          <>
            <div className="AdminUserProfile-sectionTitle">Experience types</div>
            <Divider className="AdminUserProfile-divider" />
            <Label.Group>
              {maturities.map((s) => (
                <Label key={s} content={s} />
              ))}
            </Label.Group>
          </>
        )}
      </Segment>
    </div>
  );
};
