import { MouseEventHandler, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { Shortcuts as S } from "../../../routing";
import { isEnum } from "../../../types";
import { ExpertsTab } from "./ExpertsTab";
import { StartupsTab } from "./StartupsTab";

export interface CardLinks {
  readonly tag: string;
  readonly url: string;
}

const tabVarName = S.network.queryVarNames.tab;
export enum Tabs {
  Startups = "startups",
  Experts = "experts",
}
export enum TabTitles {
  Startups = "Startups",
  Experts = "Experts",
}

export const Network = () => {
  const breadcrumbApi = useBreadcrumbApi();
  const history = useHistory();
  const location = useLocation();

  const activeTab = (new URLSearchParams(location.search).get(tabVarName) as Tabs) || Tabs.Startups;

  // Add breadcrumbs.
  useEffect(() => {
    const onTabChange: MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
      if (isEnum(currentTarget.id, Tabs) && currentTarget.id !== activeTab) {
        const params = new URLSearchParams({ [tabVarName]: currentTarget.id });
        history.push({ search: params.toString() });
      }
    };

    const startupsClassname = `panel${activeTab === Tabs.Startups ? " active" : ""}`;
    const expertClassname = `panel${activeTab === Tabs.Experts ? " active" : ""}`;

    const customTitle = `Network - ${activeTab === Tabs.Startups ? "Startups" : "Experts"}`;

    const breadcrumbFooter = (
      <div className="Breadcrumb-children-wrapper">
        <div className="Panes">
          <div className={startupsClassname} id={Tabs.Startups} onClick={onTabChange}>
            {TabTitles.Startups}
          </div>
          <div className={expertClassname} id={Tabs.Experts} onClick={onTabChange}>
            {TabTitles.Experts}
          </div>
        </div>
      </div>
    );

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addFooter(breadcrumbFooter);
    return () => {
      breadcrumbApi.removeFooter();
    };
  }, [activeTab, breadcrumbApi, history]);

  // Scroll to top
  useEffect(() => {
    const ele = document.getElementById("PageContentId");
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }, [activeTab]);

  return (
    <>
      {activeTab === Tabs.Startups && <StartupsTab />}
      {activeTab === Tabs.Experts && <ExpertsTab />}
    </>
  );
};
