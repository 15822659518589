import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { ApplyLabelsOptions, TagNamespace } from "../../types/labelQuery";
import { NamespacedLabel, LabelingOperation, TagBagScope } from "../../types/labelQuery";

export const MUTATION = gql`
  mutation ApplyLabels($input: ApplyLabelsInput!) {
    payload: applyLabels(input: $input) {
      role: taggable {
        ... on Role {
          id
          tags(scope: DEFAULT) {
            id
            assignable
            description
            label
            ns
          }
        }
      }
    }
  }
`;

interface ApplyLabelsInput {
  readonly taggableId: string;
  readonly labels: ReadonlyArray<NamespacedLabel>;
  readonly op: LabelingOperation;
  readonly scope: TagBagScope;
  readonly options?: ApplyLabelsOptions;
}

export interface Variables {
  readonly input: ApplyLabelsInput;
}

export interface Result {
  readonly payload?: { readonly role: Identifiable & { readonly tags: ReadonlyArray<Tag> } };
}

interface Tag extends Identifiable {
  readonly assignable: boolean;
  readonly description: string;
  readonly label: string;
  readonly ns: TagNamespace;
}
