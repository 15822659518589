import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { ShortTicketSchema } from "./_types";
import { TicketExecutionDate } from "./_enums";
import { TicketImportance } from "./_enums";
import { valuesOfNumericEnum } from "../../types";

export const schema: JSONSchemaType<ShortTicketSchema> = {
  $id: "https://schemas.consilienceventures.com/tickets/create-ticket.v1.schema.json",
  type: "object",
  additionalProperties: false,
  properties: {
    title: {
      type: "string",
      maxLength: 100,
      pattern: "(.|s)*\\S(.|s)*",
    },
    executionDate: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketExecutionDate),
    },
    importance: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketImportance),
    },
  },
  required: ["title", "executionDate", "importance"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
