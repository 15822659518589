import { PropsWithChildren, useReducer, useMemo, useCallback, CSSProperties } from "react";
import { initialState, reducer } from "./state";
import { Actioner } from "./actioner";
import { Context } from "./Context";
import { Image, Sidebar } from "semantic-ui-react";
import closeIcon from "../../assets/close-icon-blue.svg";

const pushableStyle: CSSProperties = {
  overflow: "hidden",
};

export const OverlayProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useMemo(() => new Actioner(dispatch), [dispatch]);
  const { visible, isHideable, isDimmable, content } = state;

  const onHide = useCallback(() => api.hide(), [api]);
  const providerValue = useMemo(() => ({ api, state }), [api, state]);

  return (
    <Context.Provider value={providerValue}>
      <Sidebar.Pushable style={pushableStyle}>
        <Sidebar
          id="AppOverlay"
          className="AppOverlay"
          animation="overlay"
          direction="right"
          onHide={isHideable ? onHide : undefined}
          visible={visible}
        >
          <div className="AppOverlay-closeIcon">
            <Image src={closeIcon} alt="close" onClick={onHide} />
          </div>
          {content}
        </Sidebar>
        <Sidebar.Pusher dimmed={isDimmable && visible}>{children}</Sidebar.Pusher>
      </Sidebar.Pushable>
    </Context.Provider>
  );
};
