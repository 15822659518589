import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button, Divider, Grid, Image, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { DeepPartial } from "uniforms";
import { useBreadcrumbApi } from "../../contexts/Breadcrumb";
import startupIcon from "../../assets/startup-square-icon.svg";
import { MUTATION, Variables, Result } from "../../api/onboards/IssueOnboard";
import { Shortcuts as S } from "../../routing";
import { Tabs } from "./MyArea";
import { ApiError, extractErrorMessages } from "../../types";
import { EntityKind, EntityType, SentOnboard } from "../../types/onboard";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { bridge, StartupInviteType } from "../../schemas/invites/StartupInviteSchema";
import { CustomTextField } from "../../schemas/CustomTextField";
import { CustomLongTextField } from "../../schemas/CustomLongTextField";
import { utils } from "../../utils/utils";

const initialModel: DeepPartial<StartupInviteType> = {
  type: EntityType.Organisation,
  kind: EntityKind.Startup,
};

export const InviteStartup = () => {
  const history = useHistory();
  const breadcrumbApi = useBreadcrumbApi();
  const [submitResult, setSubmitResult] = useState<SentOnboard>();

  const [createInvite, { loading }] = useMutation<Result, Variables>(MUTATION);

  const onSubmit = useCallback(
    (model: StartupInviteType) => {
      createInvite({ variables: { input: { invitationForm: JSON.stringify(model) } } })
        .then((res) => setSubmitResult(res.data?.payload?.onboard))
        .catch((e: ApiError) => {
          toast.error(extractErrorMessages(e).join(", "));
        });
    },
    [createInvite]
  );

  useEffect(() => {
    breadcrumbApi.addFooter(
      <div className="StartupInvite-breadcrumb-footer">
        After sending, the applicant will have 3 weeks to submit their Initial Profile
      </div>
    );

    return () => breadcrumbApi.removeFooter();
  }, [breadcrumbApi]);

  const onSuccessModalClose = useCallback(() => {
    const search = new URLSearchParams();
    search.set(S.myArea.queryVarNames.tab, Tabs.Sent);
    history.push({ pathname: S.myArea.path, search: search.toString() });
  }, [history]);

  return (
    <Grid className="StartupInvite">
      <Grid.Row columns="2">
        <Grid.Column width="10">
          <div className="StartupInvite-Form">
            <Grid>
              <Grid.Row>
                <Grid.Column className="StartupInvite-Form-Header">
                  <div className="StartupInvite-Form-Header-title">Invitation form</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <AutoForm
                    schema={bridge}
                    showInlineError
                    onSubmit={onSubmit}
                    model={initialModel}
                  >
                    <CustomTextField
                      name="name"
                      label="Startup name"
                      placeholder="Enter organisation name"
                      errorMessage="This field is mandatory and should be between 2 and 50 characters."
                      showtextcount="false"
                    />
                    <CustomTextField
                      name="recipientEmail"
                      label="Email address"
                      placeholder="Enter email address"
                      errorMessage="Invalid email address."
                      showtextcount="false"
                    />
                    <Divider />
                    <br />
                    <CustomLongTextField
                      name="invitationReason"
                      label="Why would this startup make a great addition to the portfolio?"
                      errorMessage="This field is mandatory and should be between 4 and 250 characters."
                      showtextcount="false"
                    />
                    <span className="StartupInvite-note">
                      We'll share this with the community so they know why you recommended this
                      startup.
                    </span>
                    <br />
                    <br />
                    <Button type="submit" content="Submit" color="blue" loading={loading} />
                  </AutoForm>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
        <Grid.Column width="1" />
        <Grid.Column width="5">
          <div className="StartupInvite-RightCol-header">
            <Image src={startupIcon} />
            <div>Invitation criteria for startups</div>
          </div>
          <p className="StartupInvite-RightCol-paragraph">
            We're empowering a generation of deep tech entrepreneurs by introducing them to a
            curated community united by a collective purpose and a shared currency.
          </p>
          <div className="StartupInvite-RightCol-title">Key Sectors:</div>
          <p className="StartupInvite-RightCol-paragraph">
            - AI/ML & Deep Learning <br />- Quantum Computing <br />- Computer Vision <br />- AR/VR{" "}
            <br />- Blockchain infrastructure (no tokens, however
          </p>
          <div className="StartupInvite-RightCol-title">Management team:</div>
          <p className="StartupInvite-RightCol-paragraph">
            - 2-3 diverse founders, with at least one technical founder, who can demonstrate a clear
            path to how they intend to grow the business/IP.
            <br />- A working prototype or have at least one Proof of Concept.
            <br />- Customer focused; care about their customers and are genuinely interested in
            making their life easier. They must be able to articulate the opportunity in the niche
            in the market they are pursuing and must be able to explain with passion and precision
            <br />- The buying motives of prospective customers and
            <br />- how their product or service solves their problem or removes their limitations.
          </p>
          <div className="StartupInvite-RightCol-title">The Return Multiple:</div>
          <p className="StartupInvite-RightCol-paragraph">
            We look for companies where we can help the management team generate returns of 5-10x
            minimum on our investment in 3 to 5 years.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Modal
        className="CustomClose StartupInvite-modal"
        closeIcon
        open={!!submitResult}
        onClose={onSuccessModalClose}
        size="small"
      >
        <div className="StartupInvite-modal-title">Your invite has been sent</div>
        <p className="StartupInvite-modal-paragraph">
          We've sent an invitation to the founders at {submitResult?.fullName}. Upon submitting
          their application, {submitResult?.fullName} will be able to receive feedback from our
          community of leading industry experts. Should they proceed to General Assessment, a
          Startup Review Committee will be created for deeper evaluation of{" "}
          {utils.withPossessiveApostrophe(submitResult?.fullName || "")} key challenges and
          bottlenecks to growth.
        </p>
        <Button
          className="NominateExpert-modal-button"
          primary
          content="Continue"
          onClick={onSuccessModalClose}
        />
      </Modal>
    </Grid>
  );
};
