import { gql } from "@apollo/client";
import { BALLOT_FRAGMENT } from "../_fragments/Ballot";
import { BallotWithFullProposal } from "../../types/ballot";
import { PreselectionProposalChoice } from "../../schemas/preselectionVote/_types";
import { PreselectionVoteSchema } from "../../schemas/preselectionVote/_types";

export const MUTATION = gql`
  mutation PreselectionVote($input: VoteInput!) {
    payload: vote(input: $input) {
      ballot {
        ...Ballot
      }
    }
  }
  ${BALLOT_FRAGMENT}
`;

interface VoteInput {
  readonly transmittedAt: Date;
  readonly ethSignature: string;
  readonly proposalId: string;
  readonly form: string;
}

export interface Variables {
  readonly input: VoteInput;
}

export interface Result {
  readonly payload?: {
    readonly ballot: BallotWithFullProposal<PreselectionProposalChoice, PreselectionVoteSchema>;
  };
}
