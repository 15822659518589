import { Action, ActionTypes } from "./actions";
import { assertUnreachable } from "../../types";

export interface Crumb {
  readonly path: string;
  readonly title?: string;
  readonly search?: string;
}

export interface State {
  readonly crumbs: ReadonlyMap<string, Crumb>;
  readonly crumbsExtra?: JSX.Element;
  readonly customTitle?: string;
  readonly header?: JSX.Element;
  readonly body?: JSX.Element;
  readonly footer?: JSX.Element;
  readonly showBreadcrumbs?: boolean;
}

export const initialState: State = { crumbs: new Map(), showBreadcrumbs: true };

export const reducer = (state: State, action: Action): State => {
  // Every path starts with '/' so `split("/").lenght` will always be atleast 2.

  switch (action.type) {
    case ActionTypes.AddHeader: {
      const { header } = action;
      return { ...state, header };
    }
    case ActionTypes.RemoveHeader: {
      return { ...state, header: undefined };
    }
    case ActionTypes.AddBody: {
      const { body } = action;
      return { ...state, body };
    }
    case ActionTypes.RemoveBody: {
      return { ...state, body: undefined };
    }
    case ActionTypes.AddFooter: {
      const { footer } = action;
      return { ...state, footer };
    }
    case ActionTypes.RemoveFooter: {
      return { ...state, footer: undefined };
    }
    case ActionTypes.AddCustomTitle: {
      const { customTitle } = action;
      return { ...state, customTitle };
    }
    case ActionTypes.RemoveCustomTitle: {
      return { ...state, customTitle: undefined };
    }
    case ActionTypes.AddCrumb: {
      const { crumb } = action;
      const crumbs = new Map(state.crumbs.entries());
      crumbs.set(crumb.path, crumb);
      return { ...state, crumbs };
    }
    case ActionTypes.AddCrumbsExtra: {
      const { extra } = action;
      return { ...state, crumbsExtra: extra };
    }
    case ActionTypes.RemoveCrumbsExtra: {
      return { ...state, crumbsExtra: undefined };
    }
    case ActionTypes.ToggleCrumb: {
      const { showBreadcrumbs } = action;
      return { ...state, showBreadcrumbs };
    }
    default:
      console.warn(`Unknown action type ${action}`);
      return assertUnreachable(action);
  }
};
