import { gql } from "@apollo/client";

export const SUBSCRIPTION = gql`
  subscription UserMessages($authorisation: SubscriptionAuth!) {
    userMessages(authorisation: $authorisation)
  }
`;

export interface Variables {
  readonly authorisation: {
    readonly bearerToken: string;
    readonly roleId?: string;
  };
}

export interface UserMessages {
  readonly userMessages: {
    readonly refresh?: boolean;
  };
}
