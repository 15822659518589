import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { valuesOfNumericEnum } from "../../types";
import { utils } from "../../utils/utils";
import { globalDefinitions } from "../_definitions";
import { EvaluationScore } from "../_types";
import { EvaluateCompletionSchema } from "./_types";

export const schema: JSONSchemaType<EvaluateCompletionSchema> = {
  type: "object",
  properties: {
    deliverablesQuality: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    functionalRequirements: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    nonFunctionalRequirements: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    agreedTimelines: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    deliverablesAdditionalInfo: {
      type: "string",
      maxLength: 1000,
    },
    deliverablesAdditionalDocuments: {
      type: "array",
      items: globalDefinitions.document,
      maxItems: 3,
    },
    communication: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    responsiveness: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    customerCentricity: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    problemSolving: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationScore),
    },
    expertAdditionalInfo: {
      type: "string",
      maxLength: 1000,
    },
    expertAdditionalDocuments: {
      type: "array",
      items: globalDefinitions.document,
      maxItems: 3,
    },
  },
  required: [
    "deliverablesQuality",
    "functionalRequirements",
    "nonFunctionalRequirements",
    "agreedTimelines",
    "communication",
    "responsiveness",
    "customerCentricity",
    "problemSolving",
  ],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
