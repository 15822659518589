import { useState, useMemo, CSSProperties, useCallback, createContext } from "react";
import { useQuery } from "@apollo/client";
import { Location } from "history";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Grid, Image } from "semantic-ui-react";
import { Loader, Segment } from "semantic-ui-react";
import { isEnum, isDefined } from "../../../types";
import { extractErrorMessages } from "../../../types";
import { nodesFromEdges } from "../../../types/relay";
import { Shortcuts as S } from "../../../routing";
import { PaginationLinks } from "../../elements/PaginationLinks";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { AdminApplicationCard } from "./Applications/AdminApplicationCard";
import { QUERY, Result, Variables } from "../../../api/admin/AdminOnboards";
import { Filters as QueryFilters } from "../../../api/admin/AdminOnboards";
import { EntityKind, OnboardState, readableType } from "../../../types/onboard";
import { OnboardStatus, EntityType } from "../../../types/onboard";
import { Tabs } from "./index";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { bridge as filterSchema } from "../../../schemas/admin/AdminApplicationsFilterSchema";
import { CustomDropdownSelectionField } from "../../../schemas/CustomDropdownSelectionField";
import { CustomTextField } from "../../../schemas/CustomTextField";
import { isArray } from "lodash";
import showMoreIcon from "../../../assets/load-more-icon.svg";
import { ClearModelButton } from "../../../schemas/ClearModelButton";

const RESULTS_PER_PAGE = 9;
const bttnStyle: CSSProperties = { textAlign: "right", width: "100%" };
const showMoreStyle: CSSProperties = { transform: "rotate(180deg)" };

// TODO: temporary fix until Profile ids are exposed on the backend.
interface ContextType {
  readonly refetch: () => void;
}
export const Context = createContext<ContextType>({} as ContextType);

interface Props {
  readonly kind: EntityKind;
}

export const AdminApplicationsTab = ({ kind }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [hideFilters, setHideFilters] = useState(true);
  const filters = useMemo(() => filtersFromUrl(location), [location]);

  const { data, loading, error, refetch } = useQuery<Result, Variables>(QUERY, {
    variables: { ...filters, kind: [kind] },
    notifyOnNetworkStatusChange: true,
    // TODO: temporary fix until Profile ids are exposed on the backend.
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
  const pageInfo = data?.onboards?.pageInfo;

  const contextValue = useMemo(() => ({ refetch }), [refetch]);

  const handleHideFilters = useCallback(() => setHideFilters((s) => !s), []);

  const onSubmit = useCallback(
    (model: QueryFilters) => {
      filtersToURL(kind, model, location, history);

      let modelSanitized: QueryFilters = {};
      Object.entries(model).forEach(([k, v]) => {
        if (isArray(v) && v.length <= 0) {
          return;
        }
        modelSanitized = { ...modelSanitized, [k]: v };
      });
      refetch({
        fullName: modelSanitized.fullName,
        recipientId: modelSanitized.recipientId,
        senderId: modelSanitized.senderId,
        state: modelSanitized.state,
        status: modelSanitized.status,
        type: modelSanitized.type,
        first: RESULTS_PER_PAGE,
        last: undefined,
        before: undefined,
        after: undefined,
      });
    },
    [kind, location, history, refetch]
  );

  const previousOnClick = useCallback(() => {
    if (!pageInfo || !pageInfo.hasPreviousPage || !pageInfo.startCursor) {
      return;
    }
    const before = pageInfo.startCursor;
    const params = new URLSearchParams(location.search);
    params.delete("after");
    params.delete("first");
    params.set("before", before);
    params.set("last", RESULTS_PER_PAGE.toString());
    history.push({ pathname: location.pathname, search: params.toString() });
    refetch(filtersFromUrl(location));
  }, [history, location, pageInfo, refetch]);

  const nextOnClick = useCallback(() => {
    if (!pageInfo || !pageInfo.hasNextPage || !pageInfo.endCursor) {
      return;
    }
    const after = pageInfo.endCursor;
    const params = new URLSearchParams(location.search);
    params.delete("before");
    params.delete("last");
    params.set("after", after);
    params.set("first", RESULTS_PER_PAGE.toString());
    history.push({ pathname: location.pathname, search: params.toString() });
    refetch(filtersFromUrl(location));
  }, [history, location, pageInfo, refetch]);

  const applicationCards = useMemo(() => {
    if (loading) {
      return <Loader active inline="centered" />;
    } else if (error) {
      return <ErrorMessages errors={extractErrorMessages(error)} />;
    } else if (!data || !data.onboards || !data.onboards.edges || data.onboards.edges.length <= 0) {
      return <Segment textAlign="center">No applications found...</Segment>;
    }

    const onboards = nodesFromEdges(data.onboards.edges);
    return (
      <div className="AdminApplications-wrapper">
        {onboards.map((o) => (
          <AdminApplicationCard key={o.id} onboard={o} isAdminView />
        ))}
      </div>
    );
  }, [data, error, loading]);

  return (
    <Context.Provider value={contextValue}>
      <Grid className="AdminApplications">
        <Grid.Row>
          <Grid.Column>
            <AutoForm
              schema={filterSchema}
              model={filters}
              className="AdminApplicationsFilters"
              onSubmit={onSubmit}
              showInlineError
            >
              <div className="AdminApplicationsFilters-top">
                <div className="AdminApplicationsFilters-top-left">
                  <CustomTextField name="fullName" showtextcount={false} />
                  <CustomDropdownSelectionField
                    name="status"
                    dropdownOptions={statusOpts}
                    multiple
                    placeholder="Any"
                  />
                </div>
                <div className="AdminApplicationsFilters-top-right">
                  <div>
                    <ClearModelButton content="Clear filters" asLink loading={loading} />
                  </div>
                  <div>
                    <Button color="blue" type="submit" disabled={loading} loading={loading}>
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <div className={`AdminApplicationsFilters-bottom${hideFilters ? "" : " visible"}`}>
                <CustomTextField name="senderId" showtextcount={false} />
                <CustomTextField name="recipientId" showtextcount={false} />
                <CustomDropdownSelectionField
                  name="type"
                  dropdownOptions={typeOpts}
                  multiple
                  placeholder="Any"
                />
                <CustomDropdownSelectionField
                  name="state"
                  dropdownOptions={stateOpts}
                  multiple
                  placeholder="Any"
                />
              </div>
              <span onClick={handleHideFilters} className="FiltersLink">
                {hideFilters ? "More filters" : "Less filters"}
                <Image src={showMoreIcon} style={hideFilters ? {} : showMoreStyle} />
              </span>
            </AutoForm>
          </Grid.Column>
        </Grid.Row>
        {pageInfo && !loading && (
          <Grid.Row>
            <Grid.Column>
              <PaginationLinks
                style={bttnStyle}
                pageInfo={pageInfo}
                previousOnClick={previousOnClick}
                nextOnClick={nextOnClick}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>{applicationCards}</Grid.Column>
        </Grid.Row>
      </Grid>
    </Context.Provider>
  );
};

const filtersToURL = (kind: EntityKind, model: QueryFilters, location: Location, history: any) => {
  const params = new URLSearchParams();
  const tab = kind === EntityKind.Provider ? Tabs.Experts : Tabs.Startups;
  params.set(S.administration.queryVarNames.tab, tab);

  Object.entries(model).forEach(([key, value]) => {
    if (isDefined(value)) {
      if (isArray(value) && value.length <= 0) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    }
  });

  history.replace({ pathname: location.pathname, search: params.toString() });
};

const filtersFromUrl = (location: any) => {
  const params = new URLSearchParams(location.search);
  let filterArr: Variables = {};

  Array.from(params.entries()).forEach(([key, value]) => {
    switch (key) {
      case "state":
        filterArr = {
          ...filterArr,
          state: value.split(",").reduce((acc, curr) => {
            return isEnum(curr, OnboardState) ? [...acc, curr] : acc;
          }, [] as OnboardState[]),
        };
        break;
      case "status":
        filterArr = {
          ...filterArr,
          status: value.split(",").reduce((acc, curr) => {
            return isEnum(curr, OnboardStatus) ? [...acc, curr] : acc;
          }, [] as OnboardStatus[]),
        };
        break;
      case "type":
        filterArr = {
          ...filterArr,
          type: value.split(",").reduce((acc, curr) => {
            return isEnum(curr, EntityType) ? [...acc, curr] : acc;
          }, [] as EntityType[]),
        };
        break;
      case "fullName":
        filterArr = { ...filterArr, fullName: value };
        break;
      case "recipientId":
        filterArr = { ...filterArr, recipientId: value };
        break;
      case "senderId":
        filterArr = { ...filterArr, senderId: value };
        break;
      case "first":
        const first = Number.isInteger(value) ? parseInt(value, 10) : undefined;
        filterArr = { ...filterArr, first };
        break;
      case "after":
        filterArr = { ...filterArr, after: value };
        break;

      case "last":
        const last = Number.isInteger(value) ? parseInt(value, 10) : undefined;
        filterArr = { ...filterArr, last };
        break;
      case "before":
        filterArr = { ...filterArr, before: value };
        break;
    }
  });

  // Checks for impossible combinations and tries to correct them.
  if (!!filterArr.after && !filterArr.first) {
    filterArr = { ...filterArr, first: RESULTS_PER_PAGE };
  } else if (!!filterArr.before && !filterArr.last) {
    filterArr = { ...filterArr, last: RESULTS_PER_PAGE };
  } else if (!filterArr.first && !filterArr.last) {
    filterArr = { ...filterArr, first: RESULTS_PER_PAGE };
  }

  return filterArr;
};

export const typeOpts = [
  { text: readableType(EntityType.Individual), value: EntityType.Individual },
  { text: readableType(EntityType.Organisation), value: EntityType.Organisation },
];
const stateOpts = [
  { text: "Pending", value: OnboardState.Pending },
  { text: "In Completion", value: OnboardState.InCompletion },
  { text: "Awaiting Seconders", value: OnboardState.AwaitingSeconders },
  { text: "Awaiting Enable Voting", value: OnboardState.AwaitingEnableVoting },
  { text: "In Preselection", value: OnboardState.InPreselection },
  { text: "Timed Out", value: OnboardState.TimedOut },
  { text: "In Mutual Assessment", value: OnboardState.InMutualAssessment },
  { text: "Under Review", value: OnboardState.UnderReview },
  { text: "In Committee Review", value: OnboardState.InCommitteeReview },
  { text: "Awaiting Signature", value: OnboardState.AwaitingSignature },
  { text: "Closed", value: OnboardState.Closed },
];

const statusOpts = [
  { text: "Open", value: OnboardStatus.Open },
  { text: "Approved", value: OnboardStatus.Approved },
  { text: "Finalised", value: OnboardStatus.Finalised },
  { text: "Rejected", value: OnboardStatus.Rejected },
  { text: "Abandoned", value: OnboardStatus.Abandoned },
  { text: "Expired", value: OnboardStatus.Expired },
  { text: "Canceled", value: OnboardStatus.Canceled },
  { text: "Declined", value: OnboardStatus.Declined },
];
