import { useEffect, CSSProperties, useCallback } from "react";
import { Button, Grid } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { Enrolled } from "../../../../contexts/Session/state";
import { TicketTeamSection } from "./TicketTeamSection";
import { TicketDeliverablesSection } from "./TicketDeliverablesSection";
import { Shortcuts as S } from "../../../../routing";
import { useBreadcrumbApi } from "../../../../contexts/Breadcrumb";
import { TicketDeliverableState, TicketState, TicketStatus } from "../../../../types/ticket";
import { TicketDashboardBreadcrumb } from "./TicketDashboardBreadcrumb";
import { GradientProgressBar } from "../../../GradientProgressBar";
import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
import { ModalMarkTicketDelivered } from "./ModalMarkTicketDelivered";
import { TicketDeliveredProgressSection } from "./TicketDeliveredProgressSection";
import { ModalReviewTicketCompletion } from "./ModalReviewTicketCompletion";
import { useOverlayApi } from "../../../../contexts/Overlay";
import { EvaluateCompletionOverlay } from "./EvaluateCompletionOverlay";

const ONE_DAY_MS = 86400000;

const marginStyle: CSSProperties = { paddingTop: "1rem" };
const threeDaysLeftStyle: CSSProperties = { color: "#E87B17" };
const overdueStyle: CSSProperties = { color: "#DE0E01" };

interface Props {
  readonly sessionState: Enrolled;
  readonly ticket: Ticket;
}

export const TicketWorkplace = ({ sessionState, ticket }: Props) => {
  const roleId = sessionState.roleId;
  const location = useLocation();
  const history = useHistory();
  const breadcrumbApi = useBreadcrumbApi();
  const overlayApi = useOverlayApi();
  const ticketId = new URLSearchParams(location.search).get(S.ticket.queryVarNames.id) || "";
  const { status, ticketForm, deliverables, id, owner, feedbackEvaluations } = ticket;
  const isOwner = owner.id === roleId;
  const isTicketDelivered =
    status === TicketStatus.Delivery_signaled ||
    status === TicketStatus.Delivery_approved ||
    status === TicketStatus.Delivery_disproved;
  const isTicketDeliveredConfirmed =
    status === TicketStatus.Delivery_approved || status === TicketStatus.Delivery_disproved;
  const approvedStatus =
    status === TicketStatus.Executing ||
    status === TicketStatus.Accepted ||
    status === TicketStatus.Delivery_signaled ||
    status === TicketStatus.Delivery_approved ||
    status === TicketStatus.Delivery_disproved;
  const hasEvaluation = (feedbackEvaluations?.edges?.length || 0) > 0;

  const handleEvaluate = useCallback(
    () =>
      overlayApi.show(<EvaluateCompletionOverlay sessionState={sessionState} ticket={ticket} />),
    [overlayApi, sessionState, ticket]
  );

  useEffect(() => {
    if (!ticketForm) {
      return;
    }

    let body;
    const customTitle = `${ticketForm.data.title} Dashboard`;

    if (approvedStatus) {
      body = <TicketDashboardBreadcrumb ticket={ticket} />;
    } else {
      body = <h2>{customTitle}</h2>;
    }

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(body);

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [status, ticketForm, ticket, breadcrumbApi, sessionState, approvedStatus]);

  useEffect(() => {
    if (!ticketForm) {
      return;
    }
    if (!approvedStatus) {
      const search = new URLSearchParams({
        [S.ticket.queryVarNames.id]: ticketId,
      }).toString();
      history.push({ pathname: S.ticket.path, search });
    }
  }, [status, ticketForm, ticket, history, ticketId, approvedStatus]);

  const totalDel = deliverables.length;
  const completedDel = deliverables.filter(
    (d) => d.state === TicketDeliverableState.Completed
  ).length;
  const inProgressDel = deliverables.filter(
    (d) => d.state === TicketDeliverableState.In_progress
  ).length;

  const hasCompletedDel = completedDel > 0;
  const hasInProgressDel = inProgressDel > 0;
  const hasUnstartedDel = (completedDel + inProgressDel) / totalDel !== 1;
  const hasCompletedAllDel = completedDel / totalDel === 1;
  const completedPercentage = (completedDel / totalDel) * 100;
  const inProgressPercentage = (inProgressDel / totalDel) * 100;
  const unstartedPercentage = ((totalDel - (completedDel + inProgressDel)) / totalDel) * 100;

  return (
    <Grid columns="2">
      <div style={marginStyle} />
      <Grid.Row>
        <Grid.Column width="12">
          <div className="WrapperSection">
            <TicketTeamSection sessionState={sessionState} ticket={ticket} />
            <TicketDeliverablesSection ticketId={id} isTicketOwner={isOwner} />
          </div>
        </Grid.Column>
        <Grid.Column width="4">
          <div className="ComponentHeader">Progress</div>
          {!isTicketDelivered && (
            <>
              <GradientProgressBar
                greenEntities={completedDel}
                yellowEntities={inProgressDel}
                totalEntities={totalDel}
              />
              <div className="WorkplaceProgressLabels">
                {hasCompletedDel && (
                  <span>{`${completedPercentage.toLocaleString()}% Completed`}</span>
                )}
                {hasInProgressDel && (
                  <span>{`${inProgressPercentage.toLocaleString()}% In progress`}</span>
                )}
                {hasUnstartedDel && (
                  <span>{`${unstartedPercentage.toLocaleString()}% Unstarted`}</span>
                )}
                <BuildRemainDaysLabel ticket={ticket} />
              </div>
            </>
          )}
          {isTicketDelivered && <TicketDeliveredProgressSection ticket={ticket} />}
          <br />
          {hasCompletedAllDel && !isOwner && !isTicketDelivered && (
            <>
              <ModalMarkTicketDelivered ticket={ticket} sessionState={sessionState} />
              <p className="WorkplaceProgressLabels-delivered-text">
                {owner.fullName} will be able to review completion of this ticket, and provide an
                evaluation of your delivery.
              </p>
            </>
          )}
          {status === TicketStatus.Delivery_signaled && isOwner && (
            <ModalReviewTicketCompletion ticket={ticket} sessionState={sessionState} />
          )}
          {isTicketDeliveredConfirmed && isOwner && !hasEvaluation && (
            <Button color="blue" content="Evaluate" onClick={handleEvaluate} />
          )}
          <br />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

interface BuildRemainDaysLabelProps {
  readonly ticket: Ticket;
}

const BuildRemainDaysLabel = ({ ticket: { state, deliverables } }: BuildRemainDaysLabelProps) => {
  if (state === TicketState.Completed) {
    return null;
  }
  const today = new Date().getTime();
  const dueDate = new Date(deliverables.at(-1)?.dueDate || today).getTime();

  if (dueDate - today < 0) {
    return <div style={overdueStyle}>Delivery is past the expected date of completion.</div>;
  } else if (dueDate - today < ONE_DAY_MS * 3) {
    return (
      <span>
        <b style={threeDaysLeftStyle}>{Math.ceil((dueDate - today) / ONE_DAY_MS)} days left</b>
        &nbsp;until expected completion
      </span>
    );
  } else if (dueDate - today > ONE_DAY_MS * 4) {
    return (
      <span>
        <b>{Math.ceil((dueDate - today) / ONE_DAY_MS)} days left</b>&nbsp;until expected completion
      </span>
    );
  } else {
    return null;
  }
};
