import React, { CSSProperties, FormEvent, useCallback } from "react";
import { CheckboxProps, Grid } from "semantic-ui-react";
import { connectField, HTMLFieldProps } from "uniforms";
import classNames from "classnames";
import { CheckboxField } from "./CheckboxField";

const checkboxStyle: CSSProperties = { marginBottom: "5px", width: "100%", lineHeight: "unset" };

export interface CheckboxListOption {
  readonly label: string;
  readonly value: string | number;
}

interface Props extends HTMLFieldProps<string[], HTMLElement> {
  readonly [k: string]: unknown;
  readonly options: ReadonlyArray<CheckboxListOption>;
  readonly maxCount?: number; // Inject by the form `maxItems` field.
}
export const CheckboxListField = connectField((props: Props) => {
  const { options, label, value, maxCount, onChange } = props;
  const { className, disabled, required, error, showInlineError, errorMessage } = props;

  const handleChange = useCallback(
    (_: FormEvent<HTMLInputElement>, { value: v, checked }: CheckboxProps) => {
      if (typeof v !== "string") {
        return;
      }

      if (value) {
        onChange(checked ? [...value, v] : value.filter(i => i !== v));
      } else {
        onChange(checked ? [v] : undefined);
      }
    },
    [onChange, value]
  );

  const nameFor = useCallback(
    (v: string | number) => (value ? value.findIndex(i => i === v) : "").toString(),
    [value]
  );
  const isChecked = useCallback((v: string | number) => !!value?.find(i => i === v), [value]);

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <Grid columns="2">
        <Grid.Column width="8">
          {options.map(({ label: l, value: v }, idx) =>
            idx >= Math.ceil(options.length / 2) ? null : (
              <Grid.Row key={v}>
                <CheckboxField
                  name={nameFor(v)}
                  label={l}
                  style={checkboxStyle}
                  value={v}
                  handleChange={handleChange}
                  checked={isChecked(v)}
                  disabled={value && maxCount ? value.length >= maxCount && !isChecked(v) : false}
                />
              </Grid.Row>
            )
          )}
        </Grid.Column>
        <Grid.Column width="8">
          {options.map(({ label: l, value: v }, idx) =>
            idx < Math.ceil(options.length / 2) ? null : (
              <Grid.Row key={v}>
                <CheckboxField
                  name={nameFor(v)}
                  label={l}
                  style={checkboxStyle}
                  value={v}
                  handleChange={handleChange}
                  checked={isChecked(v)}
                  disabled={value && maxCount ? value.length >= maxCount && !isChecked(v) : false}
                />
              </Grid.Row>
            )
          )}
        </Grid.Column>
      </Grid>
      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage}</div>
      )}
    </div>
  );
});
