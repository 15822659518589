import { Dispatch } from "react";
import { ethers, providers } from "ethersv5";
import { utils } from "../../utils/utils";
import { isInitialized, extractChainErrorMessage, ChainStateError, ErrorType } from "./state";
import { State, isConnected, isAuthorized, isLinked, reducer } from "./state";
import { Action, ActionTypes } from "./actions";

export class Actioner {
  private readonly dispatch: Dispatch<Action>;
  private readonly provider?: providers.Web3Provider;
  private readonly signer?: providers.JsonRpcSigner;

  constructor(dispatch: Dispatch<Action>) {
    this.dispatch = dispatch;
    const w: any = window as any;
    if (!w.ethereum || typeof w.ethereum.on !== "function") {
      this.clear({ type: ErrorType.NoEthereum });
    } else {
      this.provider = new ethers.providers.Web3Provider((window as any).ethereum, "any");
      this.signer = this.provider.getSigner();
    }
  }

  addOnAccountsChangedListener = () => {
    const w: any = window as any;
    if (!w.ethereum || typeof w.ethereum.on !== "function") {
      return this.clear({ type: ErrorType.NoEthereum });
    }
    // This needs to stay this way because this.provider doesn't support account changed events.
    w.ethereum.on("accountsChanged", this.setAccount);
  };

  onChainChanged = () => {
    if (!this.provider) {
      return;
    }

    this.provider.on("network", (_, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        window.location.reload();
      }
    });
  };

  connect = (state: State) => {
    if (state.busy) {
      const errorMessage = "connect() was called too fast.";
      return this.setLastError({ type: ErrorType.CalledWhileBusy, errorMessage });
    } else if (isInitialized(state)) {
      const errorMessage = "connect() was called while already initialized.";
      return this.setLastError({ type: ErrorType.SameStateTransition, errorMessage });
    } else if (!this.provider || !this.signer) {
      return;
    }
    const provider = this.provider;
    const signer = this.signer;

    this.setBusy();
    this.provider
      .getNetwork()
      .then(({ chainId }) => {
        if (`${chainId}` !== utils.chainNetId) {
          return this.clear({ type: ErrorType.InvalidNetwork });
        }
        const a: Action = { type: ActionTypes.SetConnected, chainId, provider, signer };
        this.dispatch(a);
        this.authorize(reducer(state, a));
      })
      .catch((error: Error) => {
        this.clear({ type: ErrorType.ProviderCall, errorMessage: extractChainErrorMessage(error) });
        this.setIddle();
      });
  };

  authorize = (state: State) => {
    if (state.busy || !isConnected(state)) {
      const errorMessage = "authorize() was called too fast.";
      return this.setLastError({ type: ErrorType.CalledWhileBusy, errorMessage });
    } else if (isAuthorized(state) || isLinked(state)) {
      const errorMessage = "authorize() was called while not needed.";
      return this.setLastError({ type: ErrorType.SameStateTransition, errorMessage });
    } else if (!this.provider) {
      return;
    }

    this.setBusy();
    this.provider
      .send("eth_requestAccounts", [])
      .then((accounts: ReadonlyArray<string>) => {
        this.dispatch({ type: ActionTypes.SetAuthorized });
        if (accounts && accounts.length > 0) {
          const account = ethers.utils.getAddress(accounts[0]);
          this.dispatch({ type: ActionTypes.SetLinked, account });
        }
      })
      .catch((error: Error) =>
        this.clear({ type: ErrorType.ProviderCall, errorMessage: extractChainErrorMessage(error) })
      )
      .finally(this.setIddle);
  };

  link = (state: State) => {
    if (state.busy) {
      const errorMessage = "link() was called too fast.";
      return this.setLastError({ type: ErrorType.CalledWhileBusy, errorMessage });
    } else if (!isAuthorized(state)) {
      const errorMessage = "link() was called from the wrong state.";
      return this.setLastError({ type: ErrorType.SameStateTransition, errorMessage });
    } else if (!this.provider) {
      return;
    }

    this.setBusy();
    this.provider
      .listAccounts()
      .then((accounts: ReadonlyArray<string>) => {
        if (accounts.length === 0) {
          return Promise.reject(new Error("Impossible Metamask state."));
        }
        this.dispatch({ type: ActionTypes.SetLinked, account: accounts[0] });
      })
      .catch((error: Error) =>
        this.clear({ type: ErrorType.ProviderCall, errorMessage: extractChainErrorMessage(error) })
      )
      .finally(this.setIddle);
  };

  sign = (state: State, data: string) => {
    if (isLinked(state) && this.signer) {
      this.setBusy();
      return this.signer
        .signMessage(data)
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err))
        .finally(this.setIddle);
    }
    return Promise.reject("The provider isn't ready for signing.");
  };

  getFractionalBalance = (
    state: State,
    onSuccess: (b: number) => void,
    onError: (e: Error) => void
  ): void => {
    if (!isLinked(state)) {
      return onError(new Error("Chain state must be atleast linked."));
    }

    state.contracts.token
      .balanceOf(state.account.toString())
      .then((res) => {
        const fractional = ethers.utils.formatUnits(res, 6);
        if (!fractional) {
          return;
        }
        const total = parseInt(fractional, 10);
        onSuccess(total);
      })
      .catch(onError)
      .finally(this.setIddle);
  };

  private clear = (lastError: ChainStateError): void =>
    this.dispatch({ type: ActionTypes.Clear, lastError });

  private setBusy = (): void => this.dispatch({ type: ActionTypes.SetBusy, flag: true });

  private setIddle = (): void => this.dispatch({ type: ActionTypes.SetBusy, flag: false });

  private setLastError = (lastError: ChainStateError): void =>
    this.dispatch({ type: ActionTypes.SetLastError, lastError });

  private setAccount = (accounts: ReadonlyArray<string>): void => {
    if (accounts.length > 0) {
      // We need to pipe the account address to this helper function because it's returned all lower-case from the listener.
      const account = ethers.utils.getAddress(accounts[0]);
      return this.dispatch({ type: ActionTypes.SetLinked, account });
    }
    const lastError = { type: ErrorType.ProviderCall, errorMessage: "Empty account list" };
    this.dispatch({ type: ActionTypes.Clear, lastError });
  };
}
