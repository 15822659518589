import React, { MouseEvent, ChangeEvent } from "react";
import { FormFieldProps, Form, Icon, Input, Popup } from "semantic-ui-react";
import { InputFieldCharCount } from "./InputFieldCharCount";

interface Props extends FormFieldProps {
  readonly max: number;
  readonly values: ReadonlyArray<string>;
  readonly onValuesChange: (newValues: ReadonlyArray<string>) => void;
  readonly maxFieldLength?: number;
  readonly errors?: ReadonlyArray<string | undefined>;
}

export const DynamicInputFields = (props: Props) => {
  const { max, values, onValuesChange, maxFieldLength, errors, label } = props;

  const minusClick = ({ currentTarget: { id } }: MouseEvent) => {
    const indexToRemove = Number(id);
    onValuesChange([...values.slice(0, indexToRemove), ...values.slice(indexToRemove + 1)]);
  };

  const plusClick = ({ currentTarget: { id } }: MouseEvent) => {
    const addAtIndex = Number(id) + 1;
    onValuesChange([...values.slice(0, addAtIndex), "", ...values.slice(addAtIndex)]);
  };

  const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    if (maxFieldLength && value.length > maxFieldLength) {
      return;
    }
    const indexToChange = Number(name);
    onValuesChange([...values.slice(0, indexToChange), value, ...values.slice(indexToChange + 1)]);
  };

  const renderInput = (value: string, index: number) => {
    const error = errors ? errors[index] : undefined;
    const minusDisabled = values.length <= 1;
    const plusDisabled = values.length >= max;

    const minusIcon = (
      <Popup
        trigger={<Icon id={index} name="minus" disabled={minusDisabled} onClick={minusClick} />}
        content={"Remove"}
        position="top center"
        disabled={minusDisabled}
      />
    );

    const plusIcon = (
      <Popup
        trigger={<Icon id={index} name="plus" disabled={plusDisabled} onClick={plusClick} />}
        content={"Add below"}
        position="top center"
        disabled={plusDisabled}
      />
    );

    let formInput = (
      <Form.Input
        key={index}
        error={error}
        input={
          <Input name={index} value={value} onChange={onChange} max={maxFieldLength}>
            <input />
            <div className="IconContainer">
              <div className="IconContainer-table">
                {minusIcon}
                {plusIcon}
              </div>
            </div>
          </Input>
        }
      />
    );

    // If a maxFieldLength was given wrap the form input field in a span
    // with the InputFieldCharCount component as the last child.
    if (maxFieldLength) {
      formInput = (
        <span key={index}>
          {formInput}
          <InputFieldCharCount currentChars={value.length} maxChars={maxFieldLength} showRedError />
        </span>
      );
    }

    return formInput;
  };

  return (
    <span className="DynamicInputFields">
      <Form.Field className="DynamicInputFields-label" label={label} />
      {values.map((value, index) => renderInput(value, index))}
    </span>
  );
};
