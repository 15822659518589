import { gql } from "@apollo/client";
import { TicketSchema } from "../../schemas/tickets/_types";
import { Connection, ConnectionVariables } from "../../types/relay";
import { TicketWithPlanFeedback } from "../../types/ticket";
import { TicketState, TicketStatus, TickettingDraft } from "../../types/ticket";
import { PAGEINFO_FRAGMENT } from "../_fragments/PageInfo";

export const QUERY = gql`
  query RoleTickets(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $as: TicketAs
    $roleId: ID!
    $state: [TicketState!]
    $status: [TicketStatus!]
    $orderFor: TicketOrderFor
  ) {
    role: node(id: $roleId) {
      ... on Role {
        connection: tickets(
          after: $after
          before: $before
          first: $first
          last: $last
          as: $as
          state: $state
          status: $status
          orderFor: $orderFor
        ) {
          edges {
            node {
              id
              insertedAt
              state
              status
              newQuestionCount
              owner {
                id
                fullName
              }
              draft {
                id
                insertedAt
                form {
                  id
                  data
                }
              }
              ticketForm(migrate: true) {
                id
                data
                schemaVersion
              }
              winningBid {
                id
                owner {
                  id
                  fullName
                }
              }
              draftPlan {
                id
                insertedAt
                updatedAt
              }
              planFeedback(first: 1) {
                edges {
                  node {
                    id
                    feedback
                    insertedAt
                    owner {
                      id
                      fullName
                      avatarUrl
                    }
                  }
                }
                pageInfo {
                  ...PageInfo
                }
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PAGEINFO_FRAGMENT}
`;

export enum TicketAs {
  Insurer = "INSURER",
  Owner = "OWNER",
  WorkProvider = "WORK_PROVIDER",
}

export enum TicketOrderFor {
  Bidder = "BIDDER",
  Owner = "OWNER",
}

export interface Variables extends ConnectionVariables {
  readonly as?: TicketAs;
  readonly roleId: string;
  readonly state?: ReadonlyArray<TicketState>;
  readonly status?: ReadonlyArray<TicketStatus>;
  readonly orderFor?: TicketOrderFor;
}

export interface TicketWithDraft extends TicketWithPlanFeedback {
  readonly draft?: TickettingDraft<TicketSchema>;
}

export interface Result {
  readonly role?: { readonly connection?: Connection<TicketWithDraft> };
}
