import { useState, useCallback, useMemo, CSSProperties } from "react";
import { Form, Button, Checkbox, CheckboxProps } from "semantic-ui-react";
import { toast } from "react-toastify";
import { SetFlagsRequest } from "../../../../contracts/generated/Access";
import { Linked } from "../../../../contexts/Chain/state";
import { useCancelablePromises } from "../../../../hooks/useCancelablePromise";

const chainFlagsStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
const flagCheckboxStyle: CSSProperties = { marginRight: "1em" };

// State.
interface State {
  readonly busy: boolean;
  readonly subjectRoleChainData?: SetFlagsRequest;
  readonly changes?: SetFlagsRequest;
}
const initialState: State = { busy: false };

// Props.
interface Props {
  readonly ethAddress: string;
  readonly chainState: Linked;
}

// Exported Component.
export const ChainRoleManagement = ({ ethAddress, chainState }: Props) => {
  const [{ subjectRoleChainData, changes, busy }, setState] = useState(initialState);
  const { makeCancelable } = useCancelablePromises([ethAddress]);

  // Memoized values.
  const accessContract = useMemo(() => chainState.contracts.access, [chainState]);

  useMemo(() => {
    makeCancelable(accessContract.flags(ethAddress))
      .then((roleChainData) => {
        setState((s) => ({ ...s, subjectRoleChainData: roleChainData, changes: roleChainData }));
      })
      .catch((e) => {
        setState((s) => ({ ...s, error: "Error while fetching chain data for this role." }));
        console.warn(e);
      });
  }, [ethAddress, accessContract, makeCancelable]);

  const isDisabled =
    busy ||
    subjectRoleChainData === undefined ||
    changes === undefined ||
    (subjectRoleChainData.isActor === changes.isActor &&
      subjectRoleChainData.isIssuer === changes.isIssuer &&
      subjectRoleChainData.isGovernor === changes.isGovernor &&
      subjectRoleChainData.isAutomaton === changes.isAutomaton);

  const onCheckboxChange = useCallback(
    (_, data: CheckboxProps) => {
      const flagName = data.name;
      if (changes && flagName) {
        setState((s) => ({ ...s, changes: { ...changes, [flagName]: data.checked } }));
      }
    },
    [changes]
  );

  const onSubmit = useCallback(() => {
    if (!subjectRoleChainData || !changes) {
      return;
    }

    makeCancelable(accessContract.setFlags(ethAddress, changes))
      .then(() => setState((s) => ({ ...s, subjectRoleChainData: changes, changes })))
      .catch((err: Error) => {
        // Revert the changes when errors occur.
        setState((s) => ({ ...s, changes: s.subjectRoleChainData }));
        if (err.message.includes("User denied transaction signature")) {
          return;
        }
        toast.error(`There was a problem setting the flags for ${ethAddress}.`);
      })
      .finally(() => setState((s) => ({ ...s, busy: false })));
  }, [ethAddress, subjectRoleChainData, changes, accessContract, makeCancelable]);

  return (
    <Form onSubmit={onSubmit}>
      {changes && (
        <Form.Field style={chainFlagsStyle}>
          <label>Flags:</label>
          <div>
            <Checkbox
              name="isActor"
              label="isActor"
              checked={changes.isActor}
              onChange={onCheckboxChange}
              style={flagCheckboxStyle}
            />
            <Checkbox
              name="isIssuer"
              label="isIssuer"
              checked={changes.isIssuer}
              onChange={onCheckboxChange}
              style={flagCheckboxStyle}
            />
            <Checkbox
              name="isGovernor"
              label="isGovernor"
              checked={changes.isGovernor}
              onChange={onCheckboxChange}
              style={flagCheckboxStyle}
            />
            <Checkbox
              name="isAutomaton"
              label="isAutomaton"
              checked={changes.isAutomaton}
              onChange={onCheckboxChange}
              style={flagCheckboxStyle}
            />
          </div>
        </Form.Field>
      )}
      <Button type="submit" primary disabled={isDisabled} loading={busy}>
        Update Flags
      </Button>
    </Form>
  );
};
