import { CSSProperties, useEffect, useMemo, useCallback } from "react";
import dateFormat from "dateformat";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Segment } from "semantic-ui-react";
import { Header, Form, FormField, Grid, GridColumn } from "semantic-ui-react";
import { ActionButtons } from "./actionButtons/index";
import { BidsSection } from "../BidsSection";
import { AskQuestion } from "../AskQuestion";
import { Evaluation } from "./Evaluation";
import { QUERY, Variables, Result } from "../../../../api/tickets/TickettingBid";
import { LoaderWithMargin } from "../../../Loader";
import { ErrorMessages } from "../../ErrorMessages";
import { extractErrorMessages, isDefined, noResultErrorFor } from "../../../../types";
import { Enrolled } from "../../../../contexts/Session/state";
import { Shortcuts as S } from "../../../../routing";
import { TicketState } from "../../../../types/ticket";
import { TicketBidStatus } from "../../../../types/bid";
import { Outcome } from "./Outcome";
import { paragraphToHtml } from "../../../../utils/applications/Formatting";
import { nodesFromEdges } from "../../../../types/relay";
import { useBreadcrumbApi } from "../../../../contexts/Breadcrumb";
import { DiscussableQuestions } from "../ticket/DiscussableQuestions";
import { TicketPaymentMethods } from "../../../../schemas/tickets/_enums";
import { DocumentBadge } from "../../DocumentBadge";
import { TicketSummary } from "../TicketSummary";

const evaluationSegmentStyle: CSSProperties = {
  margin: "0px",
  padding: "0px",
  boxShadow: "none",
  borderRadius: "5px",
};

interface Props {
  readonly sessionState: Enrolled;
}

export const PublishedBidDetails = ({ sessionState }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const breadcrumbApi = useBreadcrumbApi();
  const roleId = sessionState.roleId;
  const bidId = new URLSearchParams(location.search).get(S.bid.queryVarNames.id) || "";
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { bidId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const isOwner = useMemo(() => data?.bid?.owner.id === roleId, [data, roleId]);
  const isTicketOwner = useMemo(() => data?.bid?.ticket.owner.id === roleId, [data, roleId]);

  const handleBackTicket = useCallback(() => {
    if (!data?.bid?.ticket) {
      return;
    }
    const search = new URLSearchParams({
      [S.ticket.queryVarNames.id]: data.bid.ticket.id,
    }).toString();
    history.push({ pathname: S.ticket.path, search });
  }, [data, history]);

  useEffect(() => {
    if (data?.bid?.ticket?.ticketForm?.data.title) {
      const title = `Bid on ${data.bid.ticket.ticketForm.data.title}`;

      breadcrumbApi.addCustomTitle(title);
      breadcrumbApi.addBody(<h2>{title}</h2>);

      return () => {
        breadcrumbApi.removeCustomTitle();
        breadcrumbApi.removeBody();
      };
    }
  }, [data, breadcrumbApi]);

  const evaluation = useMemo(
    () => nodesFromEdges(data?.bid?.tickettingBidEvaluations?.edges),
    [data]
  );

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.bid?.form || !data.bid.ticket || !data.bid.ticket.ticketForm) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Ticket bids"))} />;
  }

  const { owner, state, id: ticketId } = data.bid.ticket;
  const { backgroundInformation, ticketRisks, relevantTools } = data.bid.form.data || {};
  const { deliverableChanges, KPIChanges, keepUpStrategy, overview } = data.bid.form.data || {};
  const { interestReason, competencies, experiences, teamCVs } = data.bid.form.data || {};
  const { engagementStrategy, performanceMonitoring, ticketSuccess } = data.bid.form.data || {};
  const { organisationSetup, teamManagementStrategy } = data.bid.form.data || {};
  const { previousLessons, additionalInfo, additionalDocuments } = data.bid.form.data || {};
  const { daysOfWork, estimatedCost, paymentMethod, cvdsPercentage, fiatPercentage } =
    data.bid.form.data || {};

  const displayAskAQuestion =
    isTicketOwner &&
    (data.bid.ticket.state === TicketState.In_bidding ||
      data.bid.ticket.state === TicketState.Winner_selection) &&
    data.bid.status !== TicketBidStatus.Loser &&
    data.bid.status !== TicketBidStatus.VoidedExpertDeclined &&
    data.bid.status !== TicketBidStatus.SelectedWinnerAcknowledged &&
    data.bid.status !== TicketBidStatus.SelectedWinner;

  return (
    <Grid>
      <GridColumn width="10">
        <DiscussableQuestions
          sessionState={sessionState}
          discussableId={bidId}
          showPrivacyIcon={false}
        />
        {isDefined(evaluation[0]) && (
          <div className="WrapperSection">
            <div className="ComponentHeader">Evaluation</div>
            <Segment style={evaluationSegmentStyle}>
              <Evaluation evaluation={evaluation[0]} />
            </Segment>
          </div>
        )}
        <div className="WrapperSection">
          <div className="ComponentHeader">Details</div>
          <Form>
            <Header size="medium">
              Submitted at {dateFormat(data.bid.publishedAt, `HH:MM "on" dd/mm/yy`)}
            </Header>
            <Header size="large">Intro</Header>
            <FormField>{paragraphToHtml(overview)}</FormField>
            <Header size="large">Experiences and Capabilities</Header>
            <Header size="medium">Interests</Header>
            <FormField>{paragraphToHtml(interestReason)}</FormField>
            <Header size="medium">Expert attributes</Header>
            <FormField>{paragraphToHtml(competencies)}</FormField>
            <Header size="medium">Professional experience</Header>
            <FormField>{paragraphToHtml(experiences)}</FormField>
            {keepUpStrategy && (
              <>
                <Header size="medium">Learning and development</Header>
                <FormField>{paragraphToHtml(keepUpStrategy)}</FormField>
              </>
            )}
            {relevantTools && (
              <>
                <Header size="medium">Certification and tools</Header>
                <FormField>{paragraphToHtml(relevantTools)}</FormField>
              </>
            )}
            <Header size="large">Strategy</Header>
            {deliverableChanges && (
              <>
                <Header size="medium">Deliverables</Header>
                <FormField>{paragraphToHtml(deliverableChanges)}</FormField>
              </>
            )}
            {KPIChanges && (
              <>
                <Header size="medium">KPIs</Header>
                <FormField>{paragraphToHtml(KPIChanges)}</FormField>
              </>
            )}
            <Header size="medium">Engagement style</Header>
            <FormField>{paragraphToHtml(engagementStrategy)}</FormField>
            <Header size="medium">Performance measurement</Header>
            <FormField>{paragraphToHtml(performanceMonitoring)}</FormField>
            <Header size="medium">Evaluation</Header>
            <FormField>{paragraphToHtml(ticketSuccess)}</FormField>
            {ticketRisks && (
              <>
                <Header size="medium">Risk Mitigation</Header>
                <FormField>{paragraphToHtml(ticketRisks)}</FormField>
              </>
            )}
            {backgroundInformation && (
              <>
                <Header size="medium">
                  Background information, policies, procedures and tools
                </Header>
                <FormField>{paragraphToHtml(backgroundInformation)}</FormField>
              </>
            )}
            {organisationSetup && (
              <>
                <Header size="medium">
                  Details of organisational setup, roles and responsibilities of team members
                </Header>
                <FormField>{paragraphToHtml(organisationSetup)}</FormField>
              </>
            )}
            {teamCVs && teamCVs.length > 0 && (
              <>
                <Header size="medium">CVs of everyone working on this ticket</Header>
                <div className="DocumentBadgeGroup">
                  {teamCVs.map(({ id, filename }) => (
                    <DocumentBadge key={id} documentId={id} documentName={filename} />
                  ))}
                </div>
              </>
            )}
            {teamManagementStrategy && (
              <>
                <Header size="medium">How would you manage your team?</Header>
                <FormField>{paragraphToHtml(teamManagementStrategy)}</FormField>
              </>
            )}
            {previousLessons && (
              <>
                <Header size="medium">What you have captured and learnt in previous tickets</Header>
                <FormField>{paragraphToHtml(previousLessons)}</FormField>
              </>
            )}
            {additionalInfo && (
              <>
                <Header size="medium">Anything else you would like to add</Header>
                <FormField>{paragraphToHtml(additionalInfo)}</FormField>
              </>
            )}
            {additionalDocuments && additionalDocuments.length > 0 && (
              <>
                <Header size="medium">Additional documents</Header>
                <div className="DocumentBadgeGroup">
                  {additionalDocuments.map(({ id, filename }) => (
                    <DocumentBadge key={id} documentId={id} documentName={filename} />
                  ))}
                </div>
              </>
            )}
            <Header size="large">Payment</Header>
            <FormField>
              <b>Estimated work days:</b> {`${daysOfWork} day${daysOfWork === 1 ? "" : "s"}`}
              <br />
              <b>Estimated total cost in units of CVDS:</b> {estimatedCost.toFixed(2)} CVDS
              <br />
              <b>Payment type: </b>
              {paymentMethod === TicketPaymentMethods.CVDS && "CVDS"}
              {paymentMethod === TicketPaymentMethods.FiatAndCVDS &&
                `${cvdsPercentage}% CVDS ${fiatPercentage}% Fiat`}
            </FormField>
          </Form>
        </div>
        {owner.id === roleId && (
          <BidsSection
            ticketId={ticketId}
            idToHide={bidId}
            title="Other bids for this ticket"
            noResultsMessage="There are no other bids yet for this ticket."
          />
        )}
      </GridColumn>
      <GridColumn width="6" floated="left">
        <div className="RightMenu-tickets">
          <ActionButtons
            isOwner={isOwner}
            isTicketOwner={isTicketOwner}
            ticketId={ticketId}
            ticketState={state}
            bid={data.bid}
            sessionState={sessionState}
          />
          <Outcome bid={data.bid} sessionState={sessionState} />
          {displayAskAQuestion && (
            <AskQuestion
              canChangeVisibility={false}
              description={`Ask ${data.bid.owner.fullName} a question about this bid.`}
              sessionState={sessionState}
              discussableId={bidId}
              discussableMessage={data.bid.seal?.message || ""}
            />
          )}
          <TicketSummary ticket={data.bid.ticket} />
          <div className="RightMenu-tickets-bottom-links">
            <div className="RightMenu-tickets-bottom-links-action" onClick={handleBackTicket}>
              View ticket details
            </div>
          </div>
        </div>
      </GridColumn>
    </Grid>
  );
};
