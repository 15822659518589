import { CSSProperties, useCallback, useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import repliesIcon from "../../../../assets/replies-icon.svg";
import loadMoreIcon from "../../../../assets/load-more-icon.svg";
import { Comment } from "./Comment";
import { QUERY, Variables, Result } from "../../../../api/tickets/CommentsOnThread";
import { MUTATION } from "../../../../api/UpdateLastViewedAt";
import { Variables as MutVariables, Result as MutResult } from "../../../../api/UpdateLastViewedAt";
import { LoaderWithMargin } from "../../../Loader";
import { ErrorMessages } from "../../ErrorMessages";
import { extractErrorMessages, noResultErrorFor } from "../../../../types";
import { nodesFromEdges } from "../../../../types/relay";
import { DocumentBadge } from "../../DocumentBadge";
import { Attachment } from "../../../../api/tickets/DiscussionThreadsOnIsDiscussable";

const wrapperStyle: CSSProperties = { display: "flex", justifyContent: "space-between" };

interface Props {
  readonly threadId: string;
  readonly totalReplies: number;
  readonly attachments: ReadonlyArray<Attachment>;
  readonly newCommentId?: string;
}

// Static query vars.
const excludeFirst = true;
const first = 3;

export const CommentsCollapsible = (props: Props) => {
  const { threadId, totalReplies, attachments, newCommentId } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [lastQueriedComment, setLastQueriedComment] = useState<string | undefined>();
  const [commentQuery, { data, loading, error, called, fetchMore }] = useLazyQuery<
    Result,
    Variables
  >(QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
  });
  const [updateLastViewedAt] = useMutation<MutResult, MutVariables>(MUTATION, {
    fetchPolicy: "network-only",
  });

  const hideComponent = attachments.length === 0 && totalReplies === 0;

  // For fetching the first time.
  useEffect(() => {
    commentQuery({ variables: { threadId, excludeFirst, first } });
  }, [threadId, commentQuery]);

  // After fetching the thread cooments the first time call UpdateLastViewedAt.
  useEffect(() => {
    if (!data || !called) {
      return;
    }
    updateLastViewedAt({ variables: { input: { threadId } } });
  }, [data, called, threadId, updateLastViewedAt]);

  // For fetching more comments everytime the parent comments submits a new comment.
  useEffect(() => {
    if (!newCommentId || !fetchMore || lastQueriedComment === newCommentId) {
      return;
    }
    const edgeCount = (data?.node?.comments?.edges?.length || 0) + 1;
    const vars: Variables = { threadId, excludeFirst, first: edgeCount };
    fetchMore({ variables: vars });
    setLastQueriedComment(newCommentId);
  }, [fetchMore, data, newCommentId, threadId, lastQueriedComment, totalReplies]);

  const toggleVisibility = useCallback(() => setIsVisible((s) => !s), []);

  const loadMore = useCallback(() => {
    const pInfo = data?.node?.comments?.pageInfo;
    if (!pInfo || !fetchMore) {
      return;
    }

    const vars: Variables = { threadId, excludeFirst, first, after: pInfo.endCursor };
    fetchMore({ variables: vars });
  }, [fetchMore, threadId, data]);

  if (loading && !data) {
    return <LoaderWithMargin />;
  } else if (error) {
    return isVisible ? <ErrorMessages errors={extractErrorMessages(error)} /> : null;
  } else if (!data || !data.node || !data.node.comments?.pageInfo) {
    return isVisible ? (
      <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Comments"))} />
    ) : null;
  } else if (hideComponent) {
    return null;
  }

  const { hasNextPage } = data.node.comments.pageInfo;
  const replies = nodesFromEdges(data.node.comments?.edges);

  return (
    <>
      <div style={wrapperStyle}>
        <div className="Question-body-attachments DocumentBadgeGroup">
          {attachments.map(({ document: { id: docId, uploadedFile } }) => (
            <DocumentBadge key={docId} documentId={docId} documentName={uploadedFile.filename} />
          ))}
        </div>

        {totalReplies > 0 && (
          <div className="Question-body-replies" onClick={toggleVisibility}>
            <span>
              {isVisible
                ? "Hide Replies"
                : `View ${totalReplies} ${totalReplies > 1 ? "replies" : "reply"}`}
            </span>
            {!isVisible && <Image src={repliesIcon} verticalAlign="top" />}
          </div>
        )}
      </div>
      {isVisible && <div className="Question-divider" />}
      <div className={isVisible ? "Replies" : "Replies-hidden"}>
        {replies.map((r) => (
          <Comment key={r.id} comment={r} />
        ))}
        {loading && (
          <>
            <LoaderWithMargin />
            <br />
          </>
        )}
      </div>
      {hasNextPage && isVisible && (
        <div className="Load-more">
          <div className="bttn" onClick={loadMore}>
            <span>Show more</span>&nbsp;&nbsp;
            <Image src={loadMoreIcon} verticalAlign="middle" />
          </div>
        </div>
      )}
    </>
  );
};
