import { useEffect } from "react";
import { Button, ButtonProps } from "semantic-ui-react";
import { DeepPartial, useForm } from "uniforms";

interface Props<T> extends ButtonProps {
  readonly onFormChange?: (model: DeepPartial<T>) => void;
}

export const SaveButton = <T extends object>({ onFormChange, children, ...props }: Props<T>) => {
  const { model } = useForm<T>();
  useEffect(() => onFormChange && onFormChange(model), [model, onFormChange]);

  return <Button {...props}>{children}</Button>;
};
