import { Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import { QUERY, Variables, Result } from "../../../../api/RolesByIds";
import { extractErrorMessages } from "../../../../types";
import { ErrorMessages } from "../../ErrorMessages";
import { DeniedRoleBadge } from "./DeniedRoleBadge";

interface Props {
  readonly deniedMembers: string[] | null;
}

export const TicketDenyList = ({ deniedMembers }: Props) => {
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { ids: deniedMembers || [] },
  });

  if (!deniedMembers || deniedMembers.length === 0) {
    return null;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  }

  return (
    <Segment className="TicketDenyList">
      <div className="TicketDenyList-header">Filtered viewers</div>
      <div className="TicketDenyList-subheader">
        These are people who cannot see or bid for this ticket.
      </div>
      <div className="TicketDenyList-roleBadges">
        {loading && deniedMembers.map(id => <DeniedRoleBadge key={id} loading={true} />)}
        {data && data.roles?.map(r => r && <DeniedRoleBadge key={r.id} role={r} />)}
      </div>
    </Segment>
  );
};
