import { gql } from "@apollo/client";

export const ONBOARD_SEAL_FIELDS = gql`
  fragment OnboardSealFields on Onboard {
    fullName
    recipientFullName
    insertedAt
    form(migrate: false) {
      data
    }
  }
`;

export interface OnboardSealFields {
  readonly fullName: string;
  readonly recipientFullName: string;
  readonly insertedAt: string;
  readonly form: { readonly data: unknown };
}
