import { gql } from "@apollo/client";
import { DeepPartial } from "uniforms";
import { Identifiable } from "../../types";
import { JsonDocumentVersioned } from "../../types/jsonDocument";
import { EntityType } from "../../types/onboard";
import { OnboardForm } from "../../types/OnboardForm";
import { Connection, ConnectionVariables } from "../../types/relay";

export const QUERY = gql`
  query CBCMemberNotVotedExpertOnboards(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $roleId: ID!
  ) {
    onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [IN_COMMITTEE_REVIEW]
      notVotedOnById: $roleId
    ) {
      edges {
        node {
          ... on Onboard {
            id
            fullName
            type
            submittedAt
            form {
              id
              data
              schemaVersion
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export interface Filters {
  readonly roleId: string;
}

export interface Variables extends Filters, ConnectionVariables {}

export interface Result {
  readonly onboards?: Connection<CBCMemberExpertNotVotedOnboard>;
}

export interface CBCMemberExpertNotVotedOnboard extends Identifiable {
  readonly fullName?: string;
  readonly type: EntityType;
  readonly submittedAt?: string;
  readonly form?: JsonDocumentVersioned<DeepPartial<OnboardForm>>;
}
