import moment, { MomentInput } from "moment";
import Ajv, { AnySchema, JSONSchemaType } from "ajv";
import AjvErrors from "ajv-errors";
import addFormats from "ajv-formats";
import { Manifest, manifest, ChainID, isChainId } from "../types/chainManifest";

export const requireFromEnv = (name: string) => {
  const varName = `REACT_APP_${name}`;
  const val = process.env[varName];
  if (val) {
    return val;
  }
  throw new Error(`Missing environment variable: ${varName}`);
};

type SwapArrayElementType = <T>(arr: T[], idxOne: number, idxTwo: number) => T[];

class Utils {
  readonly apiHost: string;
  readonly chainNetId: ChainID;
  readonly chainName: string;
  readonly chainRpcUrl: string;
  readonly chainGasOpts = { gasPrice: "20000000000", gas: 5000000 };
  readonly chainManifest: Manifest;
  readonly captchaKey: string;

  // The backend only accepts requests up to 16777216 bytes. We leave 200k margin.
  readonly REQUEST_MAX_SIZE = 16777216;
  readonly UPLOAD_MAX_SIZE = this.REQUEST_MAX_SIZE - 200000;
  readonly LOGO_MAX_SIZE = 1024000;
  readonly VALID_LOGO_TYPES = ["image/png", "image/jpeg", "image/jpg"];

  // eslint-disable-next-line no-useless-escape
  readonly LINKEDIN_REG_EXP = /^https:\/\/(?:[0-9a-z]+\.)*linkedin.com\/[\/0-9a-z-]+$/i;

  constructor() {
    this.apiHost = requireFromEnv("API_HOST");
    this.captchaKey = requireFromEnv("CAPTCHA_KEY");

    const chainId = requireFromEnv("CHAIN_NET_ID");
    this.chainName = requireFromEnv("CHAIN_NAME");
    this.chainRpcUrl = requireFromEnv("CHAIN_RPC_URL");

    if (!isChainId(chainId)) {
      throw new Error(`Bad chain ID: ${chainId}.`);
    }
    this.chainManifest = manifest((this.chainNetId = chainId));
  }

  isDevelopmentEnv = () => !process?.env?.NODE_ENV || process.env.NODE_ENV === "development";

  createValidator = <T>(schema: JSONSchemaType<T>, refs?: AnySchema[]) => {
    const ajv = AjvErrors(new Ajv({ allErrors: true, useDefaults: true }));
    addFormats(ajv);
    refs?.forEach((ref) => ajv.addSchema(ref));
    const validator = ajv.compile(schema);

    return (model: object) => {
      validator(model);
      return validator.errors?.length ? { details: validator.errors } : null;
    };
  };

  around = (date: MomentInput, from?: MomentInput): string => moment(date).from(from || Date.now());

  dateSince = (minutesAgo: number): string =>
    moment(Date.now()).subtract({ minutes: minutesAgo }).toISOString();

  readableDate = (date: string): string => moment(date).format("D MMM YYYY");

  uuidv4 = (shape: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx") =>
    shape.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });

  convertIDtoUUID = (s?: string): string | undefined => (!s ? undefined : atob(s).split(":")[1]);

  formatEmailInput = (value: string): string => value.toLowerCase().trim();

  identity = <T>(o: T): T => o;

  timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

  pathGoBackN = (path: string, goBackN: number = 1) => {
    const splittedPath = path.split("/");
    if (splittedPath.length - goBackN <= 1) {
      return "/";
    } else if (goBackN < 1) {
      return path;
    }

    return splittedPath.splice(0, splittedPath.length - goBackN).join("/");
  };

  isValidLogoType = (type: string) => this.VALID_LOGO_TYPES.indexOf(type) >= 0;

  /**
   * @param initialDate The starting date
   * @param offset A number in seconds to add to the initial date
   * @returns A Date object with the offset added
   */
  dateOffset = (initialDate: Date | string, offset: number) =>
    new Date(new Date(initialDate).valueOf() + offset * 1000);

  withPossessiveApostrophe = (str: string): string => {
    if (str.length === 0) {
      return str;
    } else if (str.slice(-1).toLowerCase() === "s") {
      return `${str}'`;
    } else {
      return `${str}'s`;
    }
  };

  swapArrayElements: SwapArrayElementType = (arr, idxOne, idxTwo) => {
    const min = Math.min(idxOne, idxTwo);
    const max = Math.max(idxOne, idxTwo);

    return [
      ...arr.slice(0, min),
      arr[max],
      ...arr.slice(min + 1, max),
      arr[min],
      ...arr.slice(max + 1),
    ];
  };

  /**
   * Returns the max Date in an array of dates.
   * @param dates array of Date objects or strings representing dates (eg: 2010-10-30)
   */
  getMaxDate = (dates: (string | Date)[]): Date | undefined => {
    if (dates.length <= 0) {
      return undefined;
    }
    let max = new Date(dates[0]);
    dates.forEach((d) => {
      const auxDate = new Date(d);
      if (max.getTime() < auxDate.getTime()) {
        max = auxDate;
      }
    });
    return max;
  };
}

export const utils = new Utils();
