import { useCallback, useMemo, CSSProperties, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { Context } from "uniforms";
import dateFormat from "dateformat";
import { ApiError, Maybe } from "../../types";
import { AjvError } from "../../utils/Ajv";
import { CustomLongTextField } from "../CustomLongTextField";
import { DisplayIf } from "../DisplayIf";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { QUERY, SealQueryResult } from "../../api/seals/TicketSeal";
import { MUTATION, Result, Variables } from "../../api/tickets/ConfirmTicketDelivery";
import { ReviewCompletionSchema } from "./_types";
import { readableReviewCompletionAnswer } from "./_types";
import { bridge } from "./ReviewCompletionSchema";
import { CustomBoolRadioField } from "../CustomBoolRadioField";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { Enrolled } from "../../contexts/Session/state";
import { Ticket } from "../../api/tickets/GetTicketDetailsInfo";
import { digestFormData } from "../../types/OnboardForm";
import { useMutation } from "@apollo/client";
import { feedbackEvaluationEnvelope } from "../../api/seals/FeedbackEvaluationSeal";
import { FeedbackEvaluationSealFields } from "../../api/seals/_fragments/FeedbackEvaluationSealFields";

const infoLabelStyle: CSSProperties = {
  fontSize: "12px",
  color: "#898A8D",
  marginBottom: "16px",
};

interface Props {
  readonly ticket: Ticket;
  readonly expertName: string;
  readonly deliveredAt: string;
  readonly sessionState: Enrolled;
  readonly closeModal: () => void;
  readonly openModal: () => void;
}

export const ReviewCompletionForm = (props: Props) => {
  const { ticket, expertName, deliveredAt, sessionState, closeModal, openModal } = props;
  const chainApi = useChainApi();
  const chainState = useChainState();
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const [confirmTicketDelivery] = useMutation<Result, Variables>(MUTATION);

  const onValidate = useCallback((_: ReviewCompletionSchema, e: Maybe<AjvError>) => e, []);

  const onSubmit = useCallback(
    async (model: ReviewCompletionSchema) => {
      const transmittedAt = new Date();
      const { chainId } = sessionState;

      setSealFieldsLoading(true);
      const sealData = await sealFieldsQuery(QUERY, { id: ticket.id });
      setSealFieldsLoading(false);

      if (!sealData) {
        return toast.error("Failed to fetch the data for the signature.");
      }
      const tAt = transmittedAt.toLocaleString();
      const form = { data: model };

      const fields: FeedbackEvaluationSealFields = {
        transmittedAt: tAt,
        scope: "ticketting_ticket_completion_review",
        form,
        ticket: { ...sealData.fields },
      };
      const envelope = feedbackEvaluationEnvelope(chainId, fields, transmittedAt);
      const text = digestFormData(envelope, true);
      closeModal();
      chainApi
        .sign(chainState, text)
        .then((ethSignature) => {
          return confirmTicketDelivery({
            variables: {
              input: {
                completionReviewForm: JSON.stringify(model),
                ticketId: ticket.id,
                ethSignature,
                transmittedAt,
              },
            },
          });
        })
        .then(() => {
          toast.success("Ticket successfully marked Delivered.");
        })
        .catch((err: ApiError) => {
          // Discard error message thrown when the user clicks cancel on the MM popup.
          if (err.message.includes("User denied message signature")) {
            openModal();
            return;
          }
          toast.error(err.message);
        });
    },
    [
      chainApi,
      chainState,
      sessionState,
      ticket,
      confirmTicketDelivery,
      closeModal,
      openModal,
      sealFieldsQuery,
    ]
  );

  const checkReviewCompletionAnswer = useCallback(
    ({ model }: Context<ReviewCompletionSchema>) => model.deliverySuccess === false,
    []
  );

  const readableReviewCompletion = useCallback(
    (v) => readableReviewCompletionAnswer(v, expertName),
    [expertName]
  );

  const radioFieldLabel = useMemo(
    () =>
      `On ${dateFormat(
        deliveredAt,
        "dd/mm/yy"
      )}, ${expertName} marked this ticket as delivered. Has the ticket been successfully completed, and are all deliverable requirements met?`,
    [deliveredAt, expertName]
  );

  return (
    <AutoForm schema={bridge} showInlineError onValidate={onValidate} onSubmit={onSubmit}>
      <CustomBoolRadioField
        name="deliverySuccess"
        label={radioFieldLabel}
        errorMessage="This field is mandatory"
        transform={readableReviewCompletion}
      />
      <DisplayIf condition={checkReviewCompletionAnswer}>
        <>
          <br />
          <CustomLongTextField
            name="additionalInformation"
            maxLength="1000"
            required
            label="Provide further information on why the delivery of this ticket has been unsuccessful."
            errorMessage="This field is mandatory"
          />
          <span style={infoLabelStyle}>
            Your feedback on this delivery will be shared with a member of Consilience Group, who
            will contact you to follow-up.
          </span>
        </>
      </DisplayIf>
      <div className="ModalReviewTicketCompletion-bttn-wrapper">
        <Button basic color="blue" onClick={closeModal} loading={sealFieldsLoading}>
          Cancel
        </Button>
        <SignatureButton content="Submit" loading={sealFieldsLoading} />
      </div>
    </AutoForm>
  );
};
