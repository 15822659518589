import { Identifiable } from "../../types/common";
import { LinkedEmail } from "../linkedEmail";
import { IndividualProfile } from "../profile";
import { ExtSession } from "../extSession";
import { Role } from "../role";
import { Connection, nodesFromEdges } from "../../types/relay";

interface Base<L, O, R> extends Identifiable {
  readonly avatarUrl: string;
  readonly fullName?: string;
  readonly isAdmin: boolean;
  readonly linkedEmails: L;
  readonly primaryLinkedEmail?: LinkedEmail;
  readonly linkedinSession: ExtSession;
  readonly profile: IndividualProfile;
  readonly myApplications: O;
  readonly roles: R;
  readonly mmUserId?: string;
}

interface SessionOnboard extends Identifiable {
  readonly sender: { readonly fullName: string; readonly avatarUrl: string };
}

export interface SessionUser
  extends Base<ReadonlyArray<LinkedEmail>, ReadonlyArray<SessionOnboard>, ReadonlyArray<Role>> {}
export interface GQLSessionUser
  extends Base<
    Connection<LinkedEmail> | undefined,
    Connection<SessionOnboard> | undefined,
    Connection<Role> | undefined
  > {}

export const hasAtleastOneLinkedEmail = (user: SessionUser) =>
  user.linkedEmails ? user.linkedEmails.length > 0 : false;

export const hasPrimaryLinkedEmail = (user: SessionUser): boolean => !!user.primaryLinkedEmail;

export const hasUnverifiedEmail = (user: SessionUser): boolean =>
  !!user.linkedEmails.find((e) => !e.verifiedAt);

export const hasMattermostAccount = (user: SessionUser): boolean => !!user.mmUserId;

export const convertToSessionUser = (user: GQLSessionUser): SessionUser => {
  const { linkedEmails, myApplications, roles, ...rest } = user;
  return {
    ...rest,
    linkedEmails: nodesFromEdges(linkedEmails?.edges),
    myApplications: nodesFromEdges(myApplications?.edges),
    roles: nodesFromEdges(roles?.edges),
  };
};
