import { isNumeric } from "../types";

interface Result<T extends boolean, U = undefined> {
  success: T;
  value: U;
}
interface Failure extends Result<false> {}
interface Success<T> extends Result<true, T> {}

const Failed: Failure = { success: false, value: undefined };
const Succeeded = <T>(value: T): Success<T> => ({ success: true, value });

const ensureBigInt = (value: string | number | bigint | undefined): Success<bigint> | Failure => {
  if (value === undefined || !isNumeric(value)) {
    return Failed;
  }
  return { success: true, value: BigInt(value.toString()) };
};

const ensureNumber = (value: string | number): Success<number> | Failure => {
  const nValue = Number(value.toString());
  const sValue = nValue.toString();
  if (sValue !== value.toString()) {
    return Failed;
  }
  return Succeeded(nValue);
};

export class FixedPointAmount {
  static fromIntegral = (amount: bigint | string): Success<FixedPointAmount> | Failure => {
    const res = ensureBigInt(amount);
    if (!res.success) {
      return Failed;
    }
    return Succeeded(FixedPointAmount.fromGuaranteedIntegral(res.value));
  };

  static fromGuaranteedIntegral = (amount: bigint): FixedPointAmount => {
    return new FixedPointAmount(amount);
  };

  static fromFractional = (
    amount: bigint | string,
    decimals: number | string
  ): Success<FixedPointAmount> | Failure => {
    const rAmount = ensureBigInt(amount);
    if (!rAmount.success) {
      return Failed;
    }
    const rDecimals = ensureNumber(decimals);
    if (!rDecimals.success) {
      return Failed;
    }
    return Succeeded(FixedPointAmount.fromGuaranteedFractional(amount, rDecimals.value));
  };

  static fromGuaranteedFractional = (
    amount: bigint | string,
    decimals: number
  ): FixedPointAmount => {
    if (!isNumeric(amount)) {
      throw new Error("Invalid fractional amount.");
    }

    const split = amount.toString().split(".");
    if (split.length === 1) {
      return new FixedPointAmount(BigInt(split[0].padEnd(split[0].length + decimals, "0")));
    }

    const integerPart = split[0];
    let fractionalPart;

    if (split[1].length < decimals) {
      fractionalPart = split[1].padEnd(decimals, "0");
    } else if (split[1].length > decimals) {
      fractionalPart = split[1].substring(0, decimals);
    } else {
      fractionalPart = split[1];
    }
    return new FixedPointAmount(BigInt(`${integerPart}${fractionalPart}`));
  };

  readonly amount: bigint;

  private constructor(amount: bigint) {
    this.amount = amount;
  }

  public toFractional = (decimals: number = 6, trunc: number = 2): string => {
    const pad = this.amount.toString().padStart(decimals, "0");
    const dot = pad.length - decimals;
    let dotted = `${pad.substring(0, dot)}.${pad.substring(dot, pad.length)}`;
    if (dotted.substr(0, 1) === ".") {
      dotted = `0${dotted}`;
    }
    return Number(dotted).toFixed(trunc);
  };
}
