import { gql } from "@apollo/client";
import { TicketSchema } from "../../schemas/tickets/_types";
import { Identifiable } from "../../types";
import { JsonDocument } from "../../types/jsonDocument";
import { Connection, ConnectionVariables } from "../../types/relay";
import { OwnerField } from "../../types/ticket";
import { TicketBidState, TicketBidStatus, TickettingBid } from "../../types/bid";

export const QUERY = gql`
  query MyBids(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $roleId: ID!
    $state: [TicketBidState!]
    $status: [TicketBidStatus!]
  ) {
    role: node(id: $roleId) {
      id
      ... on Role {
        bidConnection: tickettingBids(
          after: $after
          before: $before
          first: $first
          last: $last
          state: $state
          status: $status
        ) {
          edges {
            node {
              id
              state
              status
              publishedAt
              voidedExpertDeclinedOfferAt
              voidedOfferTimeoutAt
              selectedWinnerAt
              newQuestionCount
              owner {
                id
                fullName
              }
              form(migrate: true) {
                id
                data
                schemaVersion
              }
              draft {
                id
                insertedAt
                form {
                  id
                  data
                }
              }
              ticket {
                id
                ticketForm(migrate: true) {
                  id
                  data
                  schemaVersion
                }
                owner {
                  id
                  fullName
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
    }
  }
`;

export interface Variables extends ConnectionVariables {
  readonly roleId: string;
  readonly ticketId?: string;
  readonly state?: ReadonlyArray<TicketBidState>;
  readonly status?: ReadonlyArray<TicketBidStatus>;
}

export interface TickettingBidWithTicket extends TickettingBid {
  readonly newQuestionCount: number;
  readonly ticket: {
    readonly id: string;
    readonly owner: OwnerField;
    readonly ticketForm: JsonDocument<TicketSchema>;
  };
}

interface RoleNode extends Identifiable {
  readonly bidConnection?: Connection<TickettingBidWithTicket>;
}

export interface Result {
  readonly role?: RoleNode;
}
