import { Divider, Grid, GridColumn, GridRow, Header } from "semantic-ui-react";
import { PlannedDeliverable } from "../../../../schemas/deliverablesPlan/_types";
import dateFormat from "dateformat";
import { paragraphToHtml } from "../../../../utils/applications/Formatting";
import { isDefined } from "../../../../types";

interface Props {
  readonly deliverable: PlannedDeliverable;
  readonly totalCost?: number;
  readonly withDivider?: boolean;
}

export const DeliverableDetails = ({ deliverable, totalCost, withDivider }: Props) => {
  const { title, description, dueDate, associatePayment, totalPayment } = deliverable;

  return (
    <Grid className="Deliverable">
      <GridRow columns="1">
        <GridColumn>
          {withDivider && <Divider />}
          <Header className="Deliverable-title">{title}</Header>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="6" className="Deliverable-option">
          Title:
        </GridColumn>
        <GridColumn width="10">{title}</GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="6" className="Deliverable-option">
          Date:
        </GridColumn>
        <GridColumn width="10">{dateFormat(dueDate, `dd mmmm yyyy`)}</GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="6" className="Deliverable-option">
          Description:
        </GridColumn>
        <GridColumn width="10">{paragraphToHtml(description)}</GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width="6" className="Deliverable-option">
          Payment to this deliverable:
        </GridColumn>
        <GridColumn width="10">{!associatePayment ? "Yes" : "No"}</GridColumn>
      </GridRow>
      {isDefined(totalPayment) && isDefined(totalCost) && (
        <GridRow>
          <GridColumn width="6" className="Deliverable-option">
            % of total payment:
          </GridColumn>

          <GridColumn width="10">{`${totalPayment}% = ${(totalCost * (totalPayment / 100)).toFixed(
            2
          )} CVDS`}</GridColumn>
        </GridRow>
      )}
    </Grid>
  );
};
