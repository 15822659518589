import { useCallback, CSSProperties } from "react";
import { connectField, HTMLFieldProps } from "uniforms";
import { NumField } from "uniforms-semantic";
import { FormField, Image, Input } from "semantic-ui-react";
import { isDefined, isNumeric } from "../../../types";
import { MAX_FLOAT_2_DECIMAL_PLACES } from "../../../utils/Numbers";
import cvdsIcon from "../../../assets/cvds-icon.svg";
import classNames from "classnames";

const inputStyle: CSSProperties = { marginBottom: "0px" };
const labelStyle: CSSProperties = { marginBottom: ".28571429rem" };
const imageStyle: CSSProperties = { width: "30px", marginLeft: "-35px", marginTop: "3px" };

interface Props extends HTMLFieldProps<number, HTMLDivElement> {
  readonly [k: string]: unknown;
  readonly showCVDSIcon?: boolean;
}

export const CustomNumField = connectField((props: Props) => {
  const { onChange, max, min, showCVDSIcon = false, value, label } = props;
  const { error, errorMessage, className, disabled, required, placeholder } = props;

  const handleChange = useCallback(
    (v?: number | null) => {
      if (isDefined(v) && isNumeric(v)) {
        // limit the max value that typescript can handle without mantissa issues
        if (
          v > MAX_FLOAT_2_DECIMAL_PLACES ||
          (isDefined(min) && v < min) ||
          (isDefined(max) && v > max)
        ) {
          return;
        }
        return onChange(Math.round(v * 100) / 100);
      }
      onChange(undefined);
    },
    [min, max, onChange]
  );

  const handleInputChange = useCallback(
    ({ target: { valueAsNumber } }: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(valueAsNumber),
    [handleChange]
  );

  return (
    <>
      {showCVDSIcon && (
        <>
          <FormField label={label} style={labelStyle} required={required} />
          <Input
            className={classNames(className, { disabled, error, required }, "field HideNumArrows")}
            placeholder={placeholder}
            type="number"
            value={value || ""}
            max={max}
            min={min}
            onChange={handleInputChange}
            style={inputStyle}
            icon={<Image style={imageStyle} src={cvdsIcon} />}
            fluid
          />
          <div>{!!error && <div className="ui red basic pointing label">{errorMessage}</div>}</div>
        </>
      )}
      {!showCVDSIcon && (
        <NumField
          {...props}
          name=""
          onChange={handleChange}
          className={classNames(className, "HideNumArrows")}
        />
      )}
    </>
  );
});
