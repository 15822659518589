import { assertUnreachable } from "./common";

export enum TagNamespace {
  DocumentTypes = "DOCUMENT_TYPES",
  Maturities = "MATURITIES",
  Occupations = "OCCUPATIONS",
  Sectors = "SECTORS",
  Skills = "SKILLS",
}

export enum TagBagScope {
  Default = "DEFAULT",
}

export enum LabelingOperation {
  Add = "ADD",
  Remove = "REMOVE",
  Set = "SET",
}

export interface ApplyLabelsOptions {
  readonly createMissing: boolean;
}

export interface NamespacedLabel {
  readonly label: string;
  readonly ns: TagNamespace;
}

export interface LabelQuery {
  readonly labels: ReadonlyArray<NamespacedLabel>;
  readonly scope: TagBagScope;
}

export interface Tag {
  readonly assignable: boolean;
  readonly description: string;
  readonly id: string;
  readonly label: string;
  readonly ns: TagNamespace;
}

export interface LabelTag {
  readonly description: string;
  readonly ns: TagNamespace;
}

interface TagsArray {
  readonly skills: LabelTag[];
  readonly maturities: LabelTag[];
  readonly sectors: LabelTag[];
  readonly occuptions: LabelTag[];
}

export const sortedTags = (allTags: LabelTag[]) => {
  const skillTags: LabelTag[] = [];
  const maturitiesTags: LabelTag[] = [];
  const sectorsTags: LabelTag[] = [];
  const occupationsTags: LabelTag[] = [];

  allTags.forEach((t) => {
    switch (t.ns) {
      case TagNamespace.Maturities:
        return maturitiesTags.push(t);
      case TagNamespace.Occupations:
        return occupationsTags.push(t);
      case TagNamespace.Sectors:
        return sectorsTags.push(t);
      case TagNamespace.Skills:
        return skillTags.push(t);
      case TagNamespace.DocumentTypes:
        return null;
      default:
        return assertUnreachable(t.ns);
    }
  });
  const sortedAllTags: TagsArray = {
    maturities: maturitiesTags,
    occuptions: occupationsTags,
    sectors: sectorsTags,
    skills: skillTags,
  };

  return sortedAllTags;
};
