import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../../utils/utils";
import { BusinessCases } from "../../invites/_types";
import { globalDefinitions } from "../../_definitions";
import { StartupsWorkedWith, YearlyInvestment } from "../utils/_types";

export interface InitialProfileType {
  // Base fields.
  readonly businessCase: BusinessCases;
  readonly linkedIn: string; // Please provide a link to your LinkedIn profile
  readonly motivation: string; // What resonated with you about CV that encouraged you to say yes to your nomination?
  readonly engagementLevel: number; // How much time are you willing to invest working with startups and/or the community?
  readonly openingNetwork: boolean; // Are you comfortable opening up your network, where suitable, to support CVs endeavours?
  readonly committeeInvolvement: boolean; // Are you willing/have the time to get involved in one of the committees?
  readonly experienceGeography: string[]; //  In which countries have you done the most work?
  readonly topSkills: string[]; // In the context of working with startups, what are your most useful or unique skills?
  readonly startupsWorkedWith: StartupsWorkedWith; // How many startups have you worked with for a period of at least 6 months?

  // Required fields if `businessCase === BusinessCases.CoInvestor`
  readonly typicalYearlyInvestment: YearlyInvestment | null; // If you are a private investor, how much do you typically invest per year?
  readonly involvedInExit: boolean | null; // Have you been involved in any sizeable exit(s) as an investor or in a different capacity?
  readonly additionalInformation: string | null; // Please provide additional information about your involvement in those exits

  // Required fields if `businessCase === BusinessCases.Introducer`
  readonly happyToIntroduce: boolean | null; // Many of our start-ups are looking for advisory and technical expertise (API integrations, Data Science (AI/ML/NLP), Cybersecurity, Blockchain integration, etc). Do you have people within your network that may suit and you would be happy to introduce to us?

  // Required fields if `businessCase === BusinessCases.EarlyProfessional | BusinessCases.ProminentSkills`
  readonly occupations: string[]; // What are your primary occupation(s)?
  readonly sectors: string[]; // In what sectors do you have experience in?
}

export const schema: JSONSchemaType<InitialProfileType> = {
  type: "object",
  properties: {
    // Base properties.
    businessCase: { type: "string", enum: Object.values(BusinessCases) },
    linkedIn: globalDefinitions.httpsUrl,
    motivation: { ...globalDefinitions.nonEmptyString, maxLength: 300 },
    engagementLevel: {
      type: "number",
      minimum: 0,
      maximum: 31,
    },
    openingNetwork: {
      type: "boolean",
    },
    committeeInvolvement: {
      type: "boolean",
    },
    experienceGeography: {
      type: "array",
      items: globalDefinitions.countries,
      minItems: 1,
    },
    topSkills: {
      type: "array",
      items: { ...globalDefinitions.nonEmptyString },
      minItems: 5,
      maxItems: 5,
    },
    startupsWorkedWith: {
      type: "string",
      enum: Object.values(StartupsWorkedWith),
    },

    // Properties if `businessCase === BusinessCases.CoInvestor`
    typicalYearlyInvestment: { type: "string", enum: Object.values(YearlyInvestment) },
    involvedInExit: { type: "boolean" },
    additionalInformation: { ...globalDefinitions.nonEmptyString, maxLength: 300 },

    // Properties if `businessCase === BusinessCases.Introducer`
    happyToIntroduce: { type: "boolean" },

    // Properties if `businessCase === BusinessCases.EarlyProfessional | BusinessCases.ProminentSkills`
    occupations: { type: "array", items: globalDefinitions.occupation, minItems: 1 },
    sectors: { type: "array", items: globalDefinitions.sector, minItems: 1 },
  },
  required: [
    "linkedIn",
    "motivation",
    "engagementLevel",
    "openingNetwork",
    "committeeInvolvement",
    "experienceGeography",
    "topSkills",
    "startupsWorkedWith",
  ],
  allOf: [
    {
      if: { properties: { businessCase: { type: "string", enum: [BusinessCases.CoInvestor] } } },
      then: {
        required: ["involvedInExit"],
        if: { properties: { involvedInExit: { type: "boolean", enum: [true] } } },
        then: { required: ["additionalInformation"] },
      },
    },
    {
      if: { properties: { businessCase: { type: "string", enum: [BusinessCases.Introducer] } } },
      then: { required: ["happyToIntroduce"] },
    },
    {
      if: {
        properties: {
          businessCase: {
            type: "string",
            enum: [BusinessCases.EarlyProfessional, BusinessCases.ProminentSkills],
          },
        },
      },
      then: { required: ["occupations", "sectors"] },
    },
  ],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
