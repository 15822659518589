import { PreselectionProposalChoice } from "../schemas/preselectionVote/_types";
import { AbstainReason, PreselectionVoteSchema } from "../schemas/preselectionVote/_types";
import { DueDiligenceAreas, PreselectionEvaluationScore } from "../schemas/preselectionVote/_types";
import { runMigrationDictionary, versionBump, Document } from "./_helpers";
import { Migratable, Migrated, MigrateFunction } from "./_helpers";

export class PreselectionVoteMigrator<T extends Migratable<PreselectionVoteSchema>> {
  private instance: T;
  constructor(instance: T) {
    this.instance = instance;
  }

  migrate: MigrateFunction<T> = () => {
    const { form } = this.instance;

    if (!form || !form.schemaVersion || !form.data) {
      return this.instance as Migrated<T>;
    }

    const migratedForm = runMigrationDictionary<PreselectionVoteSchema>(
      form,
      PRESELECTION_VOTE_MIGRATION_DICTIONARY
    );

    return { ...this.instance, migratedForm } as Migrated<T>;
  };
}

interface PreselectionVoteSchemaV2AndV3 {
  readonly comment: string;
  readonly choice: PreselectionProposalChoice;
  readonly isParticipatingRewiewCommittee: boolean;
  readonly reason: AbstainReason;
  readonly criticalPainPoint: PreselectionEvaluationScore;
  readonly traction: PreselectionEvaluationScore;
  readonly marketDifferentiation: PreselectionEvaluationScore;
  readonly scalability: PreselectionEvaluationScore;
  readonly knowledgeOfCostumers: PreselectionEvaluationScore;
  readonly knowledgeOfFinancialMetrics: PreselectionEvaluationScore;
  readonly dueDiligenceAreas: ReadonlyArray<DueDiligenceAreas>;
}

const migrateToVersion2 = (
  form: Document<PreselectionVoteSchema>
): Document<PreselectionVoteSchema> => {
  const formData = form.data as { [key: string]: unknown };

  // Adds `isParticipatingRewiewCommittee` with default `false`.
  let migratedData = {
    ...form.data,
    isParticipatingRewiewCommittee: false,
  } as PreselectionVoteSchemaV2AndV3;

  // Makes `choice` uppercase. Adds default scores based on `choice`.
  if (formData.choice && typeof formData.choice === "string") {
    const choice = formData.choice.toUpperCase() as PreselectionProposalChoice;
    migratedData = { ...migratedData, choice };

    switch (choice) {
      case PreselectionProposalChoice.Yes:
      case PreselectionProposalChoice.No:
        migratedData = {
          ...migratedData,
          criticalPainPoint: PreselectionEvaluationScore.Skip,
          traction: PreselectionEvaluationScore.Skip,
          marketDifferentiation: PreselectionEvaluationScore.Skip,
          scalability: PreselectionEvaluationScore.Skip,
          knowledgeOfCostumers: PreselectionEvaluationScore.Skip,
          knowledgeOfFinancialMetrics: PreselectionEvaluationScore.Skip,
        };
        break;
      case PreselectionProposalChoice.Neutral:
        migratedData = { ...migratedData, reason: AbstainReason.NoStrongPosition };
        break;
    }
  }

  return { ...form, schemaVersion: "2", data: migratedData };
};

const migrateToVersion3 = (
  form: Document<PreselectionVoteSchema>
): Document<PreselectionVoteSchema> => {
  let migratedData = { ...form.data } as PreselectionVoteSchemaV2AndV3;

  const { choice } = form.data as Partial<PreselectionVoteSchemaV2AndV3>;
  if (choice === PreselectionProposalChoice.Yes || choice === PreselectionProposalChoice.No) {
    const data = form.data as any;
    migratedData = {
      ...migratedData,
      criticalPainPoint: data?.criticalPainPoint?.toString() as PreselectionEvaluationScore,
      traction: data?.traction?.toString() as PreselectionEvaluationScore,
      marketDifferentiation: data?.marketDifferentiation?.toString() as PreselectionEvaluationScore,
      scalability: data?.scalability?.toString() as PreselectionEvaluationScore,
      knowledgeOfCostumers: data?.knowledgeOfCostumers?.toString() as PreselectionEvaluationScore,
      knowledgeOfFinancialMetrics:
        data?.knowledgeOfFinancialMetrics?.toString() as PreselectionEvaluationScore,
    };
  }

  return { ...form, schemaVersion: "3", data: migratedData };
};

const migrateToVersion4 = (
  form: Document<PreselectionVoteSchema>
): Document<PreselectionVoteSchema> => {
  const { isParticipatingRewiewCommittee, choice, knowledgeOfCostumers, ...rest } =
    form.data as PreselectionVoteSchemaV2AndV3;

  return {
    ...form,
    schemaVersion: "4",
    data: {
      ...rest,
      isReviewCommitteeVolunteer: isParticipatingRewiewCommittee,
      voteChoice: choice,
      knowledgeOfCustomers: knowledgeOfCostumers,
    },
  };
};

// Note: the latest version should never be included here.
const PRESELECTION_VOTE_MIGRATION_DICTIONARY = {
  "0": versionBump,
  "1": migrateToVersion2,
  "2": migrateToVersion3,
  "3": migrateToVersion4,
};
