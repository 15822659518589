import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../../utils/utils";
import { TermsAndConditionsStartup } from "../utils/_types";

export const schema: JSONSchemaType<TermsAndConditionsStartup> = {
  type: "object",
  properties: {
    agreesToTerms: { type: "boolean", enum: [true] },
    agreesToMemorandum: { type: "boolean", enum: [true] },
  },
  required: ["agreesToTerms", "agreesToMemorandum"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
