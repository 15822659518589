import { useMemo } from "react";
import { Grid, GridColumn } from "semantic-ui-react";
import { readableExpectedToParticipate } from "../../../schemas/invites/_types";
import { readableNumberOfInvestments } from "../../../schemas/invites/_types";
import { readableBusinessCasesLong, readableFundSize } from "../../../schemas/invites/_types";
import { SentOrganisationOnboard } from "./_types";
import { countryMapping } from "../../../utils/CountriesList";
import { useCategoriesState } from "../../../contexts/Categories";
import { isSuccessState } from "../../../contexts/Generic";
import { readableCustomBoolRadioField } from "../../../schemas/CustomBoolRadioField";
import { isDefined } from "../../../types";
import { OrganisationInviteMigrator } from "../../../migrations/invites/OrganisationInvite";

interface Props {
  readonly onboard: SentOrganisationOnboard;
}

export const OrganisationInviteDetails = ({ onboard }: Props) => {
  const categoriesState = useCategoriesState();

  const [sectorOptions, occupationOptions] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null, null];
    }
    return [categoriesState.result.sectorsMapping, categoriesState.result.occupationsMapping];
  }, [categoriesState]);

  const formData = useMemo(() => {
    const { migrate } = new OrganisationInviteMigrator(onboard);
    return migrate().migratedForm?.data || {};
  }, [onboard]);

  const { sectors, fundSize, name, activelyInvest } = formData;
  const { businessCase, exampleOfValue, occupations, recipientEmail } = formData;
  const { experienceGeography, expectedToParticipate, numberOfInvestments, previousStakeholders } =
    formData;

  return (
    <Grid>
      <Grid.Row>
        <GridColumn mobile={16} tablet={16} computer={12}>
          <div className="WrapperSection InviteDetails">
            <div className="InviteDetails-title">Invitation Details</div>
            <div className="InviteDetails-sub-title">Detail</div>
            <span>Organisation</span>
            <br />
            {name && <span>{name}</span>}
            <br />
            {recipientEmail && <span>{recipientEmail}</span>}

            {isDefined(expectedToParticipate) && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  Do you think this person will participate and engage in the community aspect of CV
                  and perhaps help with committees?
                </div>
                <span>{readableExpectedToParticipate(expectedToParticipate)}</span>
              </>
            )}
            {experienceGeography && experienceGeography.length > 0 && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  Which geographies do they have the most experience in?
                </div>
                {experienceGeography.map((c, idx) => {
                  return (
                    <div key={`geoMap-${idx}`}>
                      <span>{countryMapping(c)}</span>
                      <br />
                    </div>
                  );
                })}
              </>
            )}
            {exampleOfValue && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  How have they been helpful/valuable to you or someone you know of?
                </div>
                <span>{exampleOfValue}</span>
              </>
            )}
            {businessCase && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  Why would {name || "this organisation"} make a great addition to the ecosystem?
                </div>
                <span>{readableBusinessCasesLong(businessCase)}</span>
              </>
            )}
            {sectors && sectors.length > 0 && sectorOptions && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  In what sectors do they have experience in?
                </div>
                {sectors.map((s, idx) => {
                  return (
                    <div key={`sectorMap-${idx}`}>
                      <span>- {sectorOptions[s] || "Unknown"}</span>
                      <br />
                    </div>
                  );
                })}
              </>
            )}
            {occupations && occupations.length > 0 && occupationOptions && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">What services do they provide?</div>
                {occupations.map((s, idx) => {
                  return (
                    <div key={`sectorMap-${idx}`}>
                      <span>- {occupationOptions[s] || "Unknown"}</span>
                      <br />
                    </div>
                  );
                })}
              </>
            )}
            {isDefined(activelyInvest) && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  What sort of fund size do you think they have access to?
                </div>
                <span>{readableCustomBoolRadioField(activelyInvest)}</span>
                <br />
                {numberOfInvestments && (
                  <>
                    <br />
                    <div className="InviteDetails-sub-title-light">How many do you know of?</div>
                    <span>{readableNumberOfInvestments(numberOfInvestments)}</span>
                  </>
                )}
              </>
            )}
            {fundSize && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  What sort of fund size do you think they have access to?
                </div>
                <span>{readableFundSize(fundSize)}</span>
              </>
            )}
            {previousStakeholders && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">
                  What type of stakeholders has the nominee worked with in the past?
                </div>
                <span>{previousStakeholders}</span>
              </>
            )}
          </div>
        </GridColumn>
      </Grid.Row>
    </Grid>
  );
};
