import { assertUnreachable } from "../../types";
import { TicketPreferencesSchema } from "./_types";

export enum CommunicationMethods {
  Email = "EMAIL",
  Mattermost = "MATTERMOST",
  Whatsapp = "WHATSAPP",
  VideoCall = "VIDEO_CALL",
  PhoneCall = "PHONE_CALL",
  Other = "OTHER",
}

export const readableCommunicationMethods = (
  attr: CommunicationMethods,
  preferences?: TicketPreferencesSchema
) => {
  switch (attr) {
    case CommunicationMethods.Email:
      return "Email";
    case CommunicationMethods.Mattermost:
      return "Mattermost";
    case CommunicationMethods.Other:
      return preferences?.communicationMethodOther || "Other:";
    case CommunicationMethods.PhoneCall:
      return "Phone call";
    case CommunicationMethods.VideoCall:
      return "Video call";
    case CommunicationMethods.Whatsapp:
      return "Whatsapp";
  }
  assertUnreachable(attr);
};
