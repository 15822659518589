import { useCallback, useMemo, useState } from "react";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { Button, Grid, Image, Modal } from "semantic-ui-react";
import { readableBusinessCase } from "../../../schemas/invites/_types";
import { readableType } from "../../../types/onboard";
import { SeconderCandidaciesStates, SeconderCandidacy } from "../../../types/seconderCandidacy";
import { MUTATION, Variables, Result } from "../../../api/admin/AssignAsOnboardSeconder";
import { QUERY } from "../../../api/admin/AdminExpertOnboard";
import { extractErrorMessages } from "../../../types";
import { useSessionState } from "../../../contexts/Session";
import { isEnrolled } from "../../../contexts/Session/state";
import { useSettingsState } from "../../../contexts/Settings";
import { isSuccessState } from "../../../contexts/Generic";
import { OnboardMigrator } from "../../../migrations/Onboards";

interface Props {
  readonly seconderCandidacy: SeconderCandidacy;
  readonly assignedSecondersCount: number;
  readonly isAdminView: boolean;
}

export const SeconderRow = ({ seconderCandidacy, assignedSecondersCount, isAdminView }: Props) => {
  const sessionState = useSessionState();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const settingsState = useSettingsState();
  const [assignAsOnboardSeconder, { loading }] = useMutation<Result, Variables>(MUTATION);

  const requiredSeconderCount = useMemo(() => {
    if (!isSuccessState(settingsState)) {
      return 2;
    }

    return settingsState.result.core.required_seconder_count;
  }, [settingsState]);

  const onModalOpen = useCallback(() => setIsConfirmationOpen(true), []);
  const onModalClose = useCallback(() => setIsConfirmationOpen(false), []);

  const onAssign = useCallback(() => {
    if (!isEnrolled(sessionState)) {
      return;
    }
    const seconderCandidacyId = seconderCandidacy.id;
    const onboardId = seconderCandidacy.onboard.id;
    const roleId = sessionState.roleId;

    assignAsOnboardSeconder({
      variables: { input: { seconderCandidacyId } },
      refetchQueries: [{ query: QUERY, variables: { onboardId, roleId } }],
    }).catch((err) => {
      setIsConfirmationOpen(false);
      toast.error(extractErrorMessages(err).join(", "));
    });
  }, [seconderCandidacy, sessionState, assignAsOnboardSeconder]);

  const businessCase = useMemo(() => {
    const { migratedForm } = new OnboardMigrator(seconderCandidacy.seconder.onboard).migrate();
    return migratedForm?.data.businessCase;
  }, [seconderCandidacy]);

  const type = seconderCandidacy.seconder.type;
  const fullName = seconderCandidacy.seconder.fullName;
  const avatarUrl = seconderCandidacy.seconder.avatarUrl;
  const email = seconderCandidacy.seconder.user.primaryLinkedEmail?.address;
  const canAssign =
    isAdminView &&
    seconderCandidacy.state === SeconderCandidaciesStates.Volunteered &&
    assignedSecondersCount < requiredSeconderCount;

  return (
    <Grid className="SeconderRow">
      <Grid.Row>
        <Grid.Column width="6" className="SeconderRow-ProfileCol">
          <Image className="SeconderRow-ProfileCol-avatar" circular src={avatarUrl} />
          <div className="SeconderRow-ProfileCol-Details">
            <div className="SeconderRow-ProfileCol-Details-fullName">{fullName}</div>
            <div className="SeconderRow-ProfileCol-Details-type">
              {readableType(type)}
              {businessCase ? ` | ${readableBusinessCase(businessCase)}` : ""}
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width="4" className="SeconderRow-col">
          {email}
        </Grid.Column>
        <Grid.Column width="3" className="SeconderRow-col">
          Volunteered on
          <br />
          {dateFormat(seconderCandidacy.insertedAt, "dd/mm/yy")}
        </Grid.Column>

        <Grid.Column width="3" className="SeconderRow-assignedOnCol">
          {canAssign && (
            <Modal
              className="SeconderRow-ConfirmationModal CustomClose"
              size="tiny"
              closeIcon
              closeOnDimmerClick={false}
              open={isConfirmationOpen}
              onClose={onModalClose}
              trigger={
                <Button onClick={onModalOpen} loading={loading} disabled={loading}>
                  Assign
                </Button>
              }
            >
              <div className="SeconderRow-ConfirmationModal-title">
                Do you want to assign {seconderCandidacy.seconder.fullName || "this expert"} as
                Seconder?
              </div>
              <div className="SeconderRow-ConfirmationModal-reminder">
                You can't undo this action
              </div>

              {assignedSecondersCount === requiredSeconderCount - 1 && (
                <div className="SeconderRow-ConfirmationModal-nextStep">
                  <b>Next Step</b> <br /> If you assign them, the application will progress to the
                  Mutual Assessment stage.
                </div>
              )}
              <div>
                <Button
                  basic
                  color="blue"
                  type="button"
                  onClick={onModalClose}
                  loading={loading}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  type="button"
                  content="Assign"
                  onClick={onAssign}
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </Modal>
          )}
          {seconderCandidacy.state === SeconderCandidaciesStates.Assigned && (
            <>
              Assigned on
              <br />
              {dateFormat(seconderCandidacy.assignedAt, "dd/mm/yy")}
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
