import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { globalDefinitions } from "../_definitions";
import { BusinessCases } from "./_types";

export interface EarlyProfessionalOrProminentSkillsInviteType {
  readonly businessCase: BusinessCases.EarlyProfessional | BusinessCases.ProminentSkills;
  readonly occupations: string[] | null; // Optional: min 1 item, max 10;
  readonly sectors: string[] | null; // Optional: min 1 item, max 10;
}

const schema: JSONSchemaType<EarlyProfessionalOrProminentSkillsInviteType> = {
  type: "object",
  properties: {
    businessCase: {
      type: "string",
      enum: [BusinessCases.EarlyProfessional, BusinessCases.ProminentSkills],
    },
    occupations: {
      type: "array",
      items: globalDefinitions.occupation,
      minItems: 1,
      maxItems: 10,
    },
    sectors: {
      type: "array",
      items: globalDefinitions.sector,
      minItems: 1,
      maxItems: 10,
    },
  },
  required: ["businessCase"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
