import { useSessionState } from "../../contexts/Session";
import { useChainState, needsChainWall } from "../../contexts/Chain";
import { isAtLeastPresent, isNone } from "../../contexts/Session/state";
import { LinkedinWall } from "./LinkedinWall";
import { LinkEmailWall } from "./LinkEmailWall";
import { VerifyEmailWall } from "./VerifyEmailWall";
import { EthereumWall } from "./EthereumWall";
import { MattermostWall } from "./MattermostWall";
import { hasPrimaryLinkedEmail } from "../../types/frontend_only/sessionUser";
import { appNeedsMattermostWall } from "../elements/walls/session";

export const AppWall = () => {
  const sessionState = useSessionState();
  const chainState = useChainState();

  if (isNone(sessionState)) {
    return <LinkedinWall />;
    // } else if (isLinkedinSessionId(sessionState)) {
    //   return <PasswordWall linkedinSessionId={sessionState.linkedinSessionId} />;
  } else if (isAtLeastPresent(sessionState)) {
    if (sessionState.user.linkedEmails.length === 0) {
      return <LinkEmailWall user={sessionState.user} />;
    } else if (!hasPrimaryLinkedEmail(sessionState.user)) {
      const email = sessionState.user.linkedEmails.find((e) => !e.verifiedAt);
      return email ? <VerifyEmailWall user={sessionState.user} email={email} /> : null;
    } else if (needsChainWall(chainState)) {
      return <EthereumWall />;
    } else if (appNeedsMattermostWall(sessionState)) {
      return <MattermostWall />;
    }
  }
  return null;
};
