import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation DeleteDraft($input: DeleteDraftTicketInput!) {
    payload: deleteDraftTicket(input: $input) {
      draft {
        id
      }
    }
  }
`;

export interface Variables {
  readonly input: DeleteDraftInput;
}

interface DeleteDraftInput {
  readonly draftId: string;
}

export interface Result {
  readonly payload?: DeleteDraftPayload;
}

interface DeleteDraftPayload {
  readonly draft: Identifiable;
}
