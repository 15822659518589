import { PropsWithChildren, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Icon, SemanticICONS } from "semantic-ui-react";
import AnimateHeight from "react-animate-height";
import { isEnrolled } from "../../../contexts/Session/state";
import { useSessionState } from "../../../contexts/Session";

export interface MenuEntry {
  readonly title: string;
  readonly icon?: string;
  readonly iconName?: SemanticICONS;
  readonly linksTo?: string;
  readonly subMenuItems?: ReadonlyArray<MenuEntry>;
}

interface Props {
  readonly item: MenuEntry;
  readonly isLeftCollapsed: boolean;
  readonly isExpanded?: boolean;
  readonly onExpand?: (menuTitle: string) => void;
  readonly closeMenu: () => void;
  readonly isClickable?: boolean;
}

export const MenuItem = (props: Props) => {
  const sessionState = useSessionState();
  const { pathname } = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const { isLeftCollapsed, isExpanded, onExpand, item, closeMenu, isClickable = true } = props;
  const { title, icon, iconName, linksTo, subMenuItems } = item;
  const active =
    (linksTo && pathname.includes(linksTo.split("?")[0])) ||
    (subMenuItems && subMenuItems.find((e) => e.linksTo && pathname.includes(e.linksTo)));
  const subMenuHeight = isExpanded ? "auto" : 0;

  const onMenuClick = () => (onExpand ? onExpand(title) : null);

  const onLinkClick = useCallback(
    () => (isTabletOrMobile && !isLeftCollapsed ? closeMenu() : undefined),
    [isTabletOrMobile, isLeftCollapsed, closeMenu]
  );

  const Wrapper = ({ children }: PropsWithChildren<{}>) =>
    linksTo ? (
      <Link className="MenuItem-link" to={linksTo} onClick={onLinkClick}>
        {children}
      </Link>
    ) : (
      <div className="MenuItem-link" onClick={onMenuClick}>
        {children}
      </div>
    );

  if (!isClickable) {
    if (isEnrolled(sessionState)) {
      return null;
    }
    return (
      <li className="MenuItem-disabled">
        <div className="MenuItem-disabled-icon-div">
          {icon && <img src={icon} alt="menu-icon" />}
          {iconName && <Icon name={iconName} />}
        </div>
        <span className="MenuItem-disabled-title">{title}</span>
        {subMenuItems && <Icon name={isExpanded ? "angle down" : "angle left"} />}
      </li>
    );
  }

  let subMenu = null;
  if (subMenuItems) {
    subMenu = (
      <div className={`SubMenuItem${isExpanded ? " SubMenuItem--expanded" : ""}`}>
        <ul>
          {subMenuItems.map((entry, index) => {
            const activeSubmenu = entry.linksTo && pathname.includes(entry.linksTo);
            return (
              <li key={index}>
                <Link to={entry.linksTo ? entry.linksTo : "#"} onClick={onLinkClick}>
                  <span
                    className={`SubMenuItem-title${
                      activeSubmenu ? " SubMenuItem-title--active" : ""
                    }`}
                  >
                    {entry.title}
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );

    // Add height animations only if the left menu is not collapsed.
    if (!isLeftCollapsed) {
      subMenu = (
        <AnimateHeight easing="ease-out" duration={200} height={subMenuHeight}>
          {subMenu}
        </AnimateHeight>
      );
    }
  }
  return (
    <li className={`MenuItem${active ? " MenuItem--active" : ""}`}>
      <Wrapper>
        <div className="MenuItem-link-icon">
          {icon && <img src={icon} alt="menu-icon" height={20} width={20} />}
          {iconName && <Icon name={iconName} />}
        </div>
        <span className="MenuItem-link-title">{title}</span>
        {subMenuItems && (
          <Icon className="MenuItem-link-arrow" name={isExpanded ? "angle down" : "angle left"} />
        )}
      </Wrapper>
      {subMenu}
    </li>
  );
};
