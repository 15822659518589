import { Identifiable } from ".";

export interface IndividualProfile extends Identifiable {
  readonly __typename: "IndividualProfile";
  readonly headline: string;
  readonly biography?: string;
  readonly firstName?: string;
  readonly fullName?: string;
  readonly lastName?: string;
  readonly linkedinProfileUrl?: string;
}

export interface OrganisationProfile extends Identifiable {
  readonly __typename: "OrganisationProfile";
  readonly headline: string;
  readonly description?: string;
  readonly fullName?: string;
  readonly name?: string;
  readonly linkedinProfileUrl?: string;
}

export type Profile = IndividualProfile | OrganisationProfile;

export const isIndividualProfile = (p: Profile): p is IndividualProfile =>
  p.__typename === "IndividualProfile";

export const isOrganisationProfile = (p: Profile): p is OrganisationProfile =>
  p.__typename === "OrganisationProfile";
