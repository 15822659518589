import { GenericState, GenericStateTypes } from "./state";
import { GenericAction, GenericActionTypes } from "./actions";

export const genericReducer = <T>(
  state: GenericState<T>,
  action: GenericAction<T>
): GenericState<T> => {
  switch (action.type) {
    case GenericActionTypes.Fetch:
      if (state.type === GenericStateTypes.Fetching) {
        break;
      }
      return { type: GenericStateTypes.Fetching };
    case GenericActionTypes.SetResult:
      if (state.type !== GenericStateTypes.Fetching) {
        break;
      }
      return { type: GenericStateTypes.Success, result: action.result };
    case GenericActionTypes.SetError:
      if (state.type !== GenericStateTypes.Fetching) {
        break;
      }
      return { type: GenericStateTypes.Failure, errors: action.errors };
    default: {
      console.warn("An unknown action was processed by the SessionContext reducer.", action);
      return state;
    }
  }

  console.error("Impossible state transition detected.", state, action);
  return state;
};
