import React, { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { perform } from "../../api/documents/DownloadUrl";
import { Queryable, setBusy, noResultErrorFor, isEmpty } from "../../types";

interface Props {
  readonly nodeId: string;
}
interface State extends Queryable {
  readonly downloadLink?: string;
}

export const OnePagerButton = ({ nodeId }: Props) => {
  const client = useApolloClient();
  const [state, setState] = useState({} as State);
  const { downloadLink } = state;

  useEffect(() => {
    setState(setBusy());
    perform(client, nodeId)
      .then(res => {
        if (!res.data.result || !res.data.result.downloadUrl) {
          return Promise.reject(noResultErrorFor("DocumentUri"));
        }
        const link = res.data.result.downloadUrl;
        setState(s => ({ ...s, downloadLink: link, busy: false }));
        return Promise.resolve();
      })
      .catch(console.warn);
  }, [client, nodeId]);

  if (!downloadLink || isEmpty(downloadLink)) {
    return null;
  }

  return (
    <a href={downloadLink} download target="_blank" rel="noopener noreferrer">
      Download
    </a>
  );
};
