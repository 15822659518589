import { useQuery } from "@apollo/client";
import { CSSProperties, MouseEventHandler, useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Icon, Image, Segment } from "semantic-ui-react";
import { AdminExpertOnboard } from "../../../api/admin/AdminExpertOnboard";
import dateFormat from "dateformat";
import { Enrolled } from "../../../contexts/Session/state";
import { Migrated } from "../../../migrations/_helpers";
import { Shortcuts as S } from "../../../routing";
import { QUERY, Variables, Result } from "../../../api/proposals/CBCMemberBallots";
import { QUERY as MyBallotQUERY } from "../../../api/proposals/MyBallot";
import { Variables as MyBallotVars } from "../../../api/proposals/MyBallot";
import { Result as MyBallotResult } from "../../../api/proposals/MyBallot";
import { CBCExpertVoteChoice, readableCBCExpertVoteChoice } from "../../../schemas/cbc/_types";
import { assertUnreachable, extractErrorMessages, isEnum } from "../../../types";
import { BallotSortFields, SortDirection } from "../../../types/ballotsSort";
import { cbcMemberVotesCountByChoice, KnownProposalKinds } from "../../../types/proposal";
import { LoaderWithMargin } from "../../Loader";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { nodesFromEdges } from "../../../types/relay";
import clockIcon from "../../../assets/icon-clock.svg";
import userIcon from "../../../assets/anonymous-user.svg";
import { paragraphToHtml } from "../../../utils/applications/Formatting";
import { CBCMemberExpertOnboard } from "../../../api/onboards/CBCMemberExpertOnboard";
import { VoteBars } from "../../elements/votes/VoteBars";
import { cbcVoteTallyToVoteBarData } from "../../elements/votes/utils";

const neutralIconStyle: CSSProperties = { transform: "rotate(90deg) scaleY(-1)" };
const first: number = 30;

export enum VoteTabs {
  Yes = "yes",
  No = "no",
  Abstain = "abstain",
  ForDiscussion = "for-discussion",
}
const voteTabVarName = S.nomineeVotingExpert.queryVarNames.voteTab;

interface Props {
  readonly onboard: Migrated<AdminExpertOnboard | CBCMemberExpertOnboard>;
  readonly sessionState: Enrolled;
}

export const ExpertDetailsCBCVotes = ({ onboard, sessionState }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const roleId = sessionState.roleId;

  const selectedVoteTab = useMemo(() => {
    const voteTab = new URLSearchParams(location.search).get(voteTabVarName) || VoteTabs.Yes;
    return isEnum(voteTab, VoteTabs) ? voteTab : VoteTabs.Yes;
  }, [location]);

  const { data: myBallot } = useQuery<MyBallotResult<CBCExpertVoteChoice>, MyBallotVars>(
    MyBallotQUERY,
    {
      variables: {
        onboardId: onboard.id,
        roleId: sessionState.roleId,
        kind: KnownProposalKinds.CommitteeReview,
      },
    }
  );

  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: {
      onboardId: onboard.id,
      first,
      choice: voteTabToChoice(selectedVoteTab),
      sortBy: sessionState
        ? {
            direction: SortDirection.DESC,
            field: BallotSortFields.ROLE_ID,
            match: { id: sessionState.roleId },
          }
        : undefined,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const onTabClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      if (loading) {
        return;
      }

      const newTab = ev.currentTarget.id;
      if (isEnum(newTab, VoteTabs)) {
        const newSearch = new URLSearchParams(location.search);
        newSearch.set(voteTabVarName, newTab);
        return history.replace({ search: newSearch.toString() });
      }
    },
    [loading, history, location]
  );

  const ballots = useMemo(
    () => nodesFromEdges(data?.node?.committeeProposal?.ballots?.edges),
    [data]
  );

  if (loading && !data) {
    return <LoaderWithMargin />;
  } else if (!data || !data.node) {
    return <Segment>No data found for CBC Members</Segment>;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  }

  const yesVoteTabClassname = `BallotsTabSelector-header-column${
    selectedVoteTab === VoteTabs.Yes ? " selected" : ""
  }`;
  const noVoteTabClassname = `BallotsTabSelector-header-column${
    selectedVoteTab === VoteTabs.No ? " selected" : ""
  }`;
  const abstainVoteTabClassname = `BallotsTabSelector-header-column${
    selectedVoteTab === VoteTabs.Abstain ? " selected" : ""
  }`;
  const forDiscussionVoteTabClassname = `BallotsTabSelector-header-column${
    selectedVoteTab === VoteTabs.ForDiscussion ? " selected" : ""
  }`;

  const myVote = myBallot?.node?.proposal?.ballot?.choice;

  const { committeeProposal } = data.node;
  const { upvotesCount, downvotesCount, neutralsCount, forDiscussionCount } =
    committeeProposal?.tally
      ? cbcMemberVotesCountByChoice(committeeProposal.tally)
      : { upvotesCount: 0, downvotesCount: 0, neutralsCount: 0, forDiscussionCount: 0 };

  return (
    <Grid className="ExpertDetailsCBCVotes">
      <Grid.Column width={"12"}>
        <Segment className={`BallotsTabSelector${loading ? " is-loading" : ""}`}>
          <div className="BallotsTabSelector-pageTitle">
            Do you think {onboard.fullName} should be part of the Community?
          </div>
          {/* Header */}
          <div className="BallotsTabSelector-header">
            <div id={VoteTabs.Yes} className={yesVoteTabClassname} onClick={onTabClick}>
              <div className="BallotsTabSelector-vote-counter">{upvotesCount}</div>
              <div className="BallotsTabSelector-vote-yes">
                {readableCBCExpertVoteChoice(CBCExpertVoteChoice.Yes)}
                <Icon name="thumbs up" />
              </div>
            </div>
            <div id={VoteTabs.No} className={noVoteTabClassname} onClick={onTabClick}>
              <div className="BallotsTabSelector-vote-counter">{downvotesCount}</div>
              <div className="BallotsTabSelector-vote-no">
                {readableCBCExpertVoteChoice(CBCExpertVoteChoice.No)}
                <Icon name="thumbs down" />
              </div>
            </div>
            <div
              id={VoteTabs.ForDiscussion}
              className={forDiscussionVoteTabClassname}
              onClick={onTabClick}
            >
              <div className="BallotsTabSelector-vote-counter">{forDiscussionCount}</div>
              <div className="BallotsTabSelector-vote-for-discussion">
                {readableCBCExpertVoteChoice(CBCExpertVoteChoice.ForDiscussion)}
                <Icon name="pointing up" />
              </div>
            </div>
            <div id={VoteTabs.Abstain} className={abstainVoteTabClassname} onClick={onTabClick}>
              <div className="BallotsTabSelector-vote-counter">{neutralsCount}</div>
              <div className="BallotsTabSelector-vote-neutral">
                {readableCBCExpertVoteChoice(CBCExpertVoteChoice.Abstain)}
                <Icon name="thumbs down" style={neutralIconStyle} />
              </div>
            </div>
          </div>
          {/* Body */}
          <Grid className="BallotsTabSelector-body">
            <>
              {ballots.length <= 0 && (
                <div className="BallotsTabSelector">
                  <div className="BallotsTabSelector-no-votes">
                    <Image src={clockIcon} />
                    <div className="BallotsTabSelector-no-votes-description">
                      There aren't any{" "}
                      {readableCBCExpertVoteChoice(voteTabToChoice(selectedVoteTab))} votes yet.
                    </div>
                  </div>
                </div>
              )}
              {loading && ballots.length > 0 && <LoaderWithMargin />}
              {!loading && ballots.length > 0 && (
                <>
                  <Grid.Row>
                    <Grid.Column>
                      <div className="BallotsTabSelector-body-title">Individual votes</div>
                      <p>
                        If the majority of the CBC Members select For Discussion, the application
                        will be stalled until a discussion takes place. In this case, you will
                        receive a message.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid width="16" className="BallotsTabSelector-body-grid">
                    <Grid.Row className="BallotsTabSelector-body-first-row">
                      <Grid.Column width="6">MEMBER</Grid.Column>
                      <Grid.Column width="10">VOTE</Grid.Column>
                    </Grid.Row>
                    {ballots.map(({ id, choice, role, insertedAt, form }) => (
                      <Grid.Row key={id} className="CBCExpertVote">
                        <Grid.Column width="6" className="CBCExpertVote-memberCol">
                          <div className="CBCExpertVote-memberCol-wrapper">
                            <Image src={role?.avatarUrl || userIcon} circular />
                            {role?.fullName} {roleId === role?.id ? "(YOU)" : ""}
                          </div>
                        </Grid.Column>
                        <Grid.Column width="10" className="CBCExpertVote-voteCol">
                          <div className="CBCExpertVote-voteCol-wrapper">
                            <span>
                              <b>{readableCBCExpertVoteChoice(choice)}</b>
                            </span>
                            <span className="CBCExpertVote-voteCol-date">
                              {dateFormat(insertedAt, `dd/mm/yy`)}
                            </span>
                          </div>
                          {choice === CBCExpertVoteChoice.ForDiscussion && (
                            <div className="CBCExpertVote-voteCol-description">
                              {paragraphToHtml(form.data.reasonForDiscussion)}
                            </div>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </>
              )}
            </>
          </Grid>
        </Segment>
      </Grid.Column>
      <Grid.Column width={4} className="ExpertDetailsCBCVotes-vote-summary">
        <h2>Vote Summary</h2>
        <span>Do you think this Expert should be part of the Community?</span>
        <br />
        <div className="ExpertDetailsCBCVotes-vote-summary-label">Your vote</div>
        {myVote && (
          <div className="ExpertDetailsCBCVotes-vote-summary-your-vote">
            {readableCBCExpertVoteChoice(myVote)}
          </div>
        )}
        <div className="ExpertDetailsCBCVotes-vote-summary-label">Overall</div>
        <VoteBars votes={cbcVoteTallyToVoteBarData(committeeProposal?.tally)} />
      </Grid.Column>
    </Grid>
  );
};

const voteTabToChoice = (voteTab: VoteTabs) => {
  switch (voteTab) {
    case VoteTabs.Yes:
      return CBCExpertVoteChoice.Yes;
    case VoteTabs.No:
      return CBCExpertVoteChoice.No;
    case VoteTabs.Abstain:
      return CBCExpertVoteChoice.Abstain;
    case VoteTabs.ForDiscussion:
      return CBCExpertVoteChoice.ForDiscussion;
  }
  return assertUnreachable(voteTab);
};
