import { PlanFeedback } from "../api/tickets/DeliverablesOnTicket";
import { TicketSchema } from "../schemas/tickets/_types";
import { Identifiable } from "./common";
import { JsonDocument } from "./jsonDocument";
import { Connection } from "./relay";

export enum StateTypes {
  Submit = "SUBMITED",
  Edit = "EDITING",
  Review = "REVIEWING",
}

export enum TicketPublishType {
  PublishPrivately = "PUBLISH_PRIVATELY",
  PublishPublicly = "PUBLISH_PUBLICLY",
}

export enum TicketState {
  Awaiting_bidder_acknowledgement = "AWAITING_BIDDER_ACKNOWLEDGEMENT",
  Awaiting_funds = "AWAITING_FUNDS",
  Closed = "CLOSED",
  Completed = "COMPLETED",
  Draft = "DRAFT",
  Execution = "EXECUTION",
  In_bidding = "IN_BIDDING",
  Winner_plan_submitted = "WINNER_PLAN_SUBMITTED",
  Winner_proposal = "WINNER_PROPOSAL",
  Winner_selection = "WINNER_SELECTION",
}

export enum TicketStatus {
  Accepted = "ACCEPTED",
  Canceled = "CANCELED",
  Delivery_signaled = "DELIVERY_SIGNALED",
  Delivery_approved = "DELIVERY_APPROVED",
  Delivery_disproved = "DELIVERY_DISPROVED",
  Executing = "EXECUTING",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum TicketDeliverableState {
  Completed = "COMPLETED",
  In_progress = "IN_PROGRESS",
  Unstarted = "UNSTARTED",
}

export interface OwnerField extends Identifiable {
  readonly fullName: string;
}

export interface BaseTicketDeliverable extends Identifiable {
  readonly state: TicketDeliverableState;
  readonly dueDate: string;
}

export interface TickettingTicket extends Identifiable {
  readonly insertedAt: string;
  readonly state: TicketState;
  readonly status: TicketStatus;
  readonly newQuestionCount: number;
  readonly ticketForm?: JsonDocument<TicketSchema>;
  readonly submittedBidBy?: Identifiable;
  readonly followedBy: boolean;
  readonly owner: OwnerField;
  readonly winningBid?: { readonly owner: OwnerField } & Identifiable;
}

export interface TicketWithPlanFeedback extends TickettingTicket {
  readonly draftPlan?: { readonly insertedAt: string; readonly updatedAt: string };
  readonly planFeedback?: Connection<PlanFeedback>;
}

export interface TickettingDraft<T> extends Identifiable {
  readonly insertedAt: string;
  readonly updatedAt: string;
  readonly form?: JsonDocument<T>;
}

export interface TicketCollaborationPreferences {
  readonly collaborationPreferenceOther?: string;
  readonly collaborationPreferenceEmail?: boolean;
  readonly collaborationPreferenceMattermost?: boolean;
  readonly collaborationPreferencePhoneCall?: boolean;
  readonly collaborationPreferenceVideoCall?: boolean;
  readonly collaborationPreferenceWhatsappOrText?: boolean;
}
