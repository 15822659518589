import { useMemo, useCallback, useState, CSSProperties, useRef } from "react";
import { DeepPartial } from "uniforms";
import { toast } from "react-toastify";
import { Button, Image, Modal } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import ReCAPTCHA from "react-google-recaptcha";
import { FeedbackSchema } from "./FeedbackSchema";
import { useChainState } from "../../contexts/Chain/index";
import { useSessionState } from "../../contexts/Session/index";
import { isPresent } from "../../contexts/Session/state";
import { bridge } from "./FeedbackSchema";
import { CustomLongTextField } from "../CustomLongTextField";
import { CustomRateField } from "./CustomRateField";
import { MUTATION, Variables, Result } from "../../api/CreateNewApplicationFeedback";
import { ApiError, assertUnreachable, EnrollmentErrorType } from "../../types";
import approvedIcon from "../../assets/approved-icon.svg";
import { utils } from "../../utils/utils";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";

const modalContentStyle: CSSProperties = {
  textAlign: "center",
  paddingTop: "45px",
};

const headerColor: CSSProperties = {
  color: "#5C5C5C",
};

const paragraphColor: CSSProperties = {
  color: "#898A8D",
};

const bttnContainerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

interface Props {
  readonly onModalClose: () => void;
}

export const FeedbackForm = ({ onModalClose }: Props) => {
  const { type: chainStateType } = useChainState();
  const sessionState = useSessionState();
  const currentUrl = window.location.href;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const enrolmentError =
    isPresent(sessionState) && sessionState.enrolmentError
      ? sessionTooltipFromEnrolmentError(sessionState.enrolmentError.type)
      : undefined;

  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [createNewApplicationFeedback] = useMutation<Result, Variables>(MUTATION);

  const initialModel = useMemo<DeepPartial<FeedbackSchema>>(
    () => ({ chainStateType, currentUrl, enrolmentError }),
    [chainStateType, currentUrl, enrolmentError]
  );

  const onSubmit = useCallback(
    async (model: FeedbackSchema) => {
      try {
        const captcha = utils.isDevelopmentEnv()
          ? "VALID_CAPTCHA"
          : await recaptchaRef.current!.executeAsync();
        if (!captcha) {
          throw new Error("Missing reCAPTCHA token. Please refresh our page and retry.");
        }
        createNewApplicationFeedback({
          variables: {
            input: {
              captcha,
              feedback: model.comment,
              score: model.rate,
              url: model.currentUrl,
              chainProviderStatus: model.chainStateType || enrolmentError,
            },
          },
        })
          .then(() => setWasSubmitted(true))
          .catch((err: ApiError) => {
            toast.error("Something went wrong when submitting your feedback." + err.message);
          });
      } catch (err) {
        toast.error("Something went wrong when submitting your feedback." + err);
        return onModalClose();
      }
    },
    [enrolmentError, createNewApplicationFeedback, onModalClose]
  );

  const onRecaptchaExpired = useCallback(() => {
    if (!recaptchaRef.current) {
      return toast.error("Failed to reset expired reCAPTCHA. Please refresh our page and retry.");
    }
    recaptchaRef.current.reset();
  }, []);

  if (wasSubmitted) {
    return (
      <Modal.Content>
        <div style={modalContentStyle}>
          <Image src={approvedIcon} verticalAlign="middle" />
          <h2 style={headerColor}>Thank you for your feedback</h2>
          <br />
          <p style={paragraphColor}>
            Your feedback is highly valued and is used to improve our product.
          </p>
        </div>
      </Modal.Content>
    );
  }

  return (
    <AutoForm
      className="FeedbackForm"
      schema={bridge}
      model={initialModel}
      showInlineError
      onSubmit={onSubmit}
    >
      <div className="FeedbackModal-title">Tell us what you think</div>
      <CustomRateField
        name="rate"
        label="How would you rate your experience?"
        errorMessage="Required field"
      />
      <CustomLongTextField
        name="comment"
        label={null}
        placeholder="Enter up to 500 characters..."
        errorMessage="Required field"
        showInlineError={false}
      />
      <br />
      <div style={bttnContainerStyle}>
        <ReCAPTCHA
          size="invisible"
          badge="inline"
          ref={recaptchaRef}
          sitekey={utils.captchaKey}
          onExpired={onRecaptchaExpired}
        />
        <div>
          <Button type="submit" primary floated="right">
            Submit
          </Button>
        </div>
      </div>
    </AutoForm>
  );
};

const sessionTooltipFromEnrolmentError = (type: EnrollmentErrorType): string => {
  let tooltip;
  switch (type) {
    case EnrollmentErrorType.IdentityMismatch:
      tooltip =
        "We don't know the account you've selected in Metamask." +
        " Using Metamask, select the account you've used while enrolling.";
      break;
    case EnrollmentErrorType.FailedContractCall:
      tooltip =
        "A technical problem occured while we tried to call our Smart Contracts." +
        " Please make sure that you have a stable connection to the Internet.";
      break;
    case EnrollmentErrorType.PartiallyVerified:
      tooltip =
        "It seems like although you're in our books, our chain" +
        " doesn't know about you yet. Contact our support team if" +
        " the problem persists.";
      break;
    default:
      return assertUnreachable(type);
  }
  return tooltip;
};
