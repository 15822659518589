import { CSSProperties, Fragment, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Breadcrumb } from "semantic-ui-react";
// Types and helpers.
import { useBreadcrumbState } from "../../contexts/Breadcrumb";
import { Shortcuts } from "../../routing";

const withFooterStyle: CSSProperties = { paddingBottom: "0px" };
const crumbsRowStyle: CSSProperties = { display: "flex", justifyContent: "space-between" };

export const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const { header, body, footer, customTitle, crumbs, crumbsExtra, showBreadcrumbs } =
    useBreadcrumbState();
  const leafTitle = useRef("");

  useEffect(() => {
    const documentTitle = (
      customTitle ||
      Object.values(Shortcuts).find((s) => s.path === pathname)?.title ||
      ""
    ).concat(" | Consilience Ventures");

    document.title = documentTitle;
  }, [pathname, customTitle]);

  const breadcrumbs = useMemo(() => {
    // Array of routes that can be generated from this pathname.
    // eg. for pathname = "/ticket/submit-bid" the route array would be:
    // ["/ticket","/ticket/submit-bid"]
    const routes = pathname
      .slice(1)
      .split("/")
      .reduce((acc, curr) => {
        const parentRoute = acc.length > 0 ? acc[acc.length - 1].split("?")[0] : "";
        let currRoute = `${parentRoute}/${curr}`;
        const search = crumbs.get(currRoute)?.search;
        currRoute = `${currRoute}${search ? `?${search}` : ""}`;

        return [...acc, currRoute];
      }, [] as string[]);

    return routes.map((route, idx) => {
      const isLast = idx === routes.length - 1;
      const routeWithoutSearch = route.split("?")[0];
      const crumb = crumbs.get(routeWithoutSearch);

      const title =
        crumb?.title ||
        Object.values(Shortcuts).find((s) => s.path === routeWithoutSearch)?.title ||
        routeWithoutSearch;
      leafTitle.current = title;

      return (
        <Fragment key={routeWithoutSearch}>
          <Breadcrumb.Section
            active={isLast}
            as={isLast ? null : Link}
            to={route}
            content={title}
          />
          {!isLast && <Breadcrumb.Divider icon="right angle" />}
        </Fragment>
      );
    });
  }, [pathname, crumbs]);

  return (
    <div className="Breadcrumb" style={footer ? withFooterStyle : undefined}>
      <ToastContainer
        limit={1}
        autoClose={8000}
        draggablePercent={60}
        closeOnClick={false}
        icon={false}
      />
      {showBreadcrumbs && breadcrumbs.length > 1 && (
        <div style={crumbsRowStyle}>
          <Breadcrumb data-testid="breadcrumb">{breadcrumbs}</Breadcrumb>
          {crumbsExtra}
        </div>
      )}
      {header && <span className="Breadcrumb-header">{header}</span>}
      <span className="Breadcrumb-body">{body || <h2>{leafTitle.current}</h2>}</span>
      {footer && <span className="Breadcrumb-footer">{footer}</span>}
    </div>
  );
};
