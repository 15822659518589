import { gql, ApolloClient } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { ReceivedOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation ClaimOnboard($input: ClaimOnboardInput!) {
    payload: claimOnboard(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

interface ClaimOnboardInput {
  readonly onboardId: string;
}
export interface Variables {
  readonly input: ClaimOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: ReceivedOnboard };
}

export const perform = (client: ApolloClient<any>, input: ClaimOnboardInput) =>
  client.mutate<Result>({ mutation: MUTATION, variables: { input } });
