import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { connectField, HTMLFieldProps } from "uniforms";
import classnames from "classnames";
import { isDefined } from "../types";

interface Props extends HTMLFieldProps<string[], HTMLElement> {
  readonly [k: string]: unknown;
  readonly valueToLabelMapper?: (v: string) => string;
}

export const ArrayErrorsField = connectField((props: Props) => {
  const { value, valueToLabelMapper, className } = props;
  const [arrayErrors, setArrayErrors] = useState<{ [key: string]: string }>({});

  return (
    <>
      {value?.map((v, i) => (
        <ArrayElement
          setArrayErrors={setArrayErrors}
          key={v}
          name={i.toString()}
          valueToLabelMapper={valueToLabelMapper}
        />
      ))}

      {Object.keys(arrayErrors).length > 0 && (
        <div className={classnames("ui", className, "error message")}>
          <ul className="list">
            {Object.values(arrayErrors).map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
});

interface ArrayElementProps extends HTMLFieldProps<string, HTMLElement> {
  readonly [k: string]: unknown;
  readonly setArrayErrors: Dispatch<SetStateAction<{ [key: string]: string }>>;
  readonly valueToLabelMapper?: (v: string) => string;
}

const ArrayElement = connectField((props: ArrayElementProps) => {
  const { value, error, errorMessage, setArrayErrors, valueToLabelMapper } = props;

  useEffect(
    () => () => {
      if (isDefined(value)) {
        setArrayErrors(({ [value]: _, ...s }) => s);
      }
    },
    [setArrayErrors, value]
  );

  useEffect(() => {
    if (isDefined(error) && isDefined(value)) {
      const label = valueToLabelMapper ? valueToLabelMapper(value) : value;
      return setArrayErrors(s => ({ ...s, [value]: `${label}: ${errorMessage}` }));
    } else if (!isDefined(error) && isDefined(value)) {
      setArrayErrors(({ [value]: _, ...s }) => s);
    }
  }, [value, error, errorMessage, valueToLabelMapper, setArrayErrors]);

  return null;
});
