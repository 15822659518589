import { gql } from "@apollo/client";
import { ROLE_SEAL_FIELDS, RoleSealFields } from "./RoleSealFields";
import { DELIVERABLE_SEAL_FIELDS, DeliverableSealFields } from "./DeliverableSealFields";

import { TicketPublishType } from "../../../types/ticket";

export const TICKET_SEAL_FIELDS = gql`
  fragment TicketSealFields on TickettingTicket {
    transmittedAt
    publishType
    ticketForm(migrate: false) {
      data
    }
    owner {
      ...RoleSealFields
    }
    planForm(migrate: false) {
      data
    }
    deliverables {
      ...DeliverableSealFields
    }
  }
  ${ROLE_SEAL_FIELDS}
  ${DELIVERABLE_SEAL_FIELDS}
`;

export interface TicketSealFields {
  readonly transmittedAt: string;
  readonly publishType: TicketPublishType;
  readonly ticketForm: { readonly data: unknown };
  readonly owner: RoleSealFields;
  readonly planForm: { readonly data: unknown };
  readonly deliverables?: ReadonlyArray<DeliverableSealFields>;
}
