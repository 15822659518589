import { Identifiable, capitalize } from "../types/common";
import { Tag } from "./labelQuery";
import { EntityKind, EntityType } from "./onboard";
import { Profile } from "./profile";

export interface Role extends Identifiable, WithTypeAndKind {
  readonly avatarUrl: string;
  readonly canInvest: boolean;
  readonly canInvestUntil?: string;
  readonly ethAddress: string;
  readonly extraAttributes?: RoleExtraAttributes;
  readonly fullName?: string;
  readonly onboard: Identifiable;
  readonly profile: Profile;
  readonly user: Identifiable;
  readonly tags: ReadonlyArray<Tag>;
}
// Fields come from backend json with these names
export interface RoleExtraAttributes {
  readonly cbc_member?: {};
  readonly cbc_member_at?: string;
}

// Helper functions.
export const isStartupRole = (role: WithTypeAndKind) => role.kind === EntityKind.Startup;
export const isProviderRole = (role: WithTypeAndKind) => role.kind === EntityKind.Provider;
export const isIndividualRole = (role: WithTypeAndKind) => role.type === EntityType.Individual;
export const isOrganisationRole = (role: WithTypeAndKind) => role.type === EntityType.Organisation;

interface WithTypeAndKind {
  readonly kind: EntityKind;
  readonly type: EntityType;
}

export const readableRoleType = (role: WithTypeAndKind): string =>
  `${capitalize(role.type)} ${capitalize(role.kind)}`;

export const hasEditableNameAndPicture = (role: Role) => isStartupRole(role);
