import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { useCallback, useContext, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "semantic-ui-react";
import { MUTATION as EnableMut } from "../../../../api/onboards/EnableVotingOnOnboard";
import { Variables as EnableVars } from "../../../../api/onboards/EnableVotingOnOnboard";
import { Result as EnableRes } from "../../../../api/onboards/EnableVotingOnOnboard";
import { MUTATION as UnderReviewMut } from "../../../../api/onboards/MoveOnboardToUnderReview";
import { Variables as UnderReviewVars } from "../../../../api/onboards/MoveOnboardToUnderReview";
import { Result as UnderReviewRes } from "../../../../api/onboards/MoveOnboardToUnderReview";
import { MUTATION as ApproveMut } from "../../../../api/onboards/ApproveOnboard";
import { Variables as ApproveVars } from "../../../../api/onboards/ApproveOnboard";
import { Result as ApproveRes } from "../../../../api/onboards/ApproveOnboard";
import { MUTATION as RejectMut } from "../../../../api/onboards/RejectOnboard";
import { Variables as RejectVars } from "../../../../api/onboards/RejectOnboard";
import { Result as RejectRes } from "../../../../api/onboards/RejectOnboard";
import { toast } from "react-toastify";
import { extractErrorMessages, noResultErrorFor } from "../../../../types";
import { AdminOnboardWithSeconderCandidacies } from "../../../../api/admin/AdminOnboards";
import { Context as AdminTabContext } from "../AdminApplicationsTab";
import { CBCMemberExpertOnboard } from "../../../../api/onboards/CBCMemberExpertOnboard";

export enum Actions {
  EnableVotingOnOnboard = "ENABLE_VOTING",
  MoveToUnderReview = "MOVE_TO_UNDER_REVIEW",
  ApproveOnboard = "APPROVE_ONBOARD",
  RejectOnboard = "REJECT_ONBOARD",
}

interface ModalProps {
  readonly onboard: AdminOnboardWithSeconderCandidacies | CBCMemberExpertOnboard;
  readonly open: boolean;
  readonly action?: Actions;
  readonly setAction: Dispatch<SetStateAction<Actions | undefined>>;
}

export const AdminApplicationCardModal = (props: PropsWithChildren<ModalProps>) => {
  const { onboard, action, children, open, setAction } = props;
  // Mutations.
  const [enableVotingOnOnboard, enableData] = useMutation<EnableRes, EnableVars>(EnableMut);
  const [moveToUnderReview, underReviewData] = useMutation<UnderReviewRes, UnderReviewVars>(
    UnderReviewMut
  );
  const [approveOnboard, approveData] = useMutation<ApproveRes, ApproveVars>(ApproveMut);
  const [rejectOnboard, rejectData] = useMutation<RejectRes, RejectVars>(RejectMut);

  const { refetch } = useContext(AdminTabContext);

  const isLoading =
    enableData.loading || underReviewData.loading || approveData.loading || rejectData.loading;

  const { title, description, noBttnLabel, yesBttnLabel, toastMessage, mutation } = useMemo(() => {
    switch (action) {
      case Actions.EnableVotingOnOnboard:
        return {
          title: "Are you sure you want to enable voting for this applicant?",
          description: "You can not undo this action.",
          noBttnLabel: "Cancel",
          yesBttnLabel: "Enable voting",
          toastMessage: "Voting enabled.",
          mutation: enableVotingOnOnboard,
        };
      case Actions.MoveToUnderReview:
        return {
          title: "Please confirm your action. You can not undo this process.",
          description: `Upgrade ${onboard.fullName} application to General Assessment`,
          noBttnLabel: "Cancel",
          yesBttnLabel: "Accept",
          toastMessage: "Moved to Under Review.",
          mutation: moveToUnderReview,
        };
      case Actions.ApproveOnboard:
        return {
          title: "Please confirm your action.",
          description: "You can not undo this process.",
          noBttnLabel: "Cancel",
          yesBttnLabel: "Accept as Member",
          toastMessage: "Application accepted.",
          mutation: approveOnboard,
        };
      case Actions.RejectOnboard:
        return {
          title: "Are you sure you want to reject this applicant?",
          description: "You can not undo this action.",
          noBttnLabel: "Cancel",
          yesBttnLabel: "Reject",
          toastMessage: "Application rejected.",
          mutation: rejectOnboard,
        };
      default:
        return {};
    }
  }, [action, onboard, enableVotingOnOnboard, moveToUnderReview, approveOnboard, rejectOnboard]);

  const onNoClick = useCallback(() => setAction(undefined), [setAction]);
  const onYesClick = useCallback(() => {
    if (mutation === undefined) {
      return;
    }

    mutation({ variables: { input: { onboardId: onboard.id } } })
      .then((res) => {
        // TODO: temporary fix until Profile ids are exposed on the backend.
        if (refetch) {
          refetch();
        }
        if (res?.data?.payload?.onboard) {
          return toast.success(toastMessage);
        }
        Promise.reject(noResultErrorFor("Mutation"));
      })
      .catch((err) => toast.error(extractErrorMessages(err).join(", ")))
      .finally(() => setAction(undefined));
  }, [onboard.id, toastMessage, mutation, setAction, refetch]);

  return (
    <Modal
      className="AdminApplications-modal"
      size="small"
      closeOnDimmerClick={false}
      open={open}
      trigger={children}
    >
      <div className="AdminApplications-modal-title">{title}</div>
      <br />
      <div className="AdminApplications-modal-description">{description}</div>
      <br />
      <div className="AdminApplications-modal-bttns">
        <Button basic color="blue" onClick={onNoClick} disabled={isLoading} loading={isLoading}>
          <b>{noBttnLabel}</b>
        </Button>
        <Button color="blue" onClick={onYesClick} disabled={isLoading} loading={isLoading}>
          {yesBttnLabel}
        </Button>
      </div>
    </Modal>
  );
};
