import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { EntityKind, EntityType } from "../../types/onboard";
import { OnboardStatus, PublicOnboardViewedByMember } from "../../types/onboard";
import { PUBLIC_ONBOARD_VIEWED_BY_MEMBER_CONNECTION_FRAGMENT } from "../_fragments/PublicOnboardViewedByMember";

export const QUERY = gql`
  query ExpertOnboarding(
    $roleId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $seconderId: ID!
    $status: [OnboardStatus!]
    $type: [EntityType!]
    $kind: [EntityKind!]
  ) {
    awaitingSeconders: onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [AWAITING_SECONDERS, TIMED_OUT]
      status: $status
      type: $type
      kind: $kind
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
    secondedOnboards: onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [IN_MUTUAL_ASSESSMENT]
      status: $status
      seconderId: $seconderId
      type: $type
      kind: $kind
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
    cbcVotingOnboards: onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      state: [IN_COMMITTEE_REVIEW]
      status: $status
      type: $type
      kind: $kind
    ) {
      ...PublicOnboardViewedByMemberConnection
    }
  }
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_CONNECTION_FRAGMENT}
`;

export interface Variables extends ConnectionVariables {
  readonly roleId: string;
  readonly seconderId?: string;
  readonly status?: ReadonlyArray<OnboardStatus>;
  readonly type?: ReadonlyArray<EntityType>;
  readonly kind?: ReadonlyArray<EntityKind>;
}

export interface Result {
  readonly awaitingSeconders?: Connection<PublicOnboardViewedByMember>;
  readonly secondedOnboards?: Connection<PublicOnboardViewedByMember>;
  readonly cbcVotingOnboards?: Connection<PublicOnboardViewedByMember>;
}
