import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { Filters, SortDirection, UserSortFields } from "../../api/admin/Users";

const schema: JSONSchemaType<Required<Filters>> = {
  type: "object",
  properties: {
    confirmed: { type: "boolean" },
    email: { type: "string", maxLength: 300 },
    enroled: { type: "boolean" },
    fulltext: { type: "string", maxLength: 50 },
    profile: { type: "string", maxLength: 300 },
    roleProfile: { type: "string", maxLength: 300 },
    sortBy: {
      type: "object",
      properties: {
        direction: { type: "string", enum: Object.values(SortDirection) },
        field: { type: "string", enum: Object.values(UserSortFields) },
      },
      required: ["direction", "field"],
    },
  },
  required: [],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
