import { gql } from "@apollo/client";
import { SENT_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { SentOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation IssueOnboard($input: IssueOnboardInput!) {
    payload: issueOnboard(input: $input) {
      onboard {
        ...SentOnboard
      }
    }
  }
  ${SENT_ONBOARD_FRAGMENT}
`;

export interface IssueOnboardInput {
  readonly invitationForm: string;
}

export interface Variables {
  readonly input: IssueOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: SentOnboard };
}
