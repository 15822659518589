import { gql, ApolloClient } from "@apollo/client";
import { LINKEDEMAIL_FRAGMENT } from "../_fragments/LinkedEmail";
import { LinkedEmail } from "../../types/linkedEmail";

export const MUTATION = gql`
  mutation LinkEmail($input: LinkEmailAddressInput!) {
    payload: linkEmailAddress(input: $input) {
      linkedEmail {
        ...LinkedEmail
      }
    }
  }
  ${LINKEDEMAIL_FRAGMENT}
`;

interface LinkEmailAddressInput {
  readonly address: string;
}

interface Variables {
  readonly input: LinkEmailAddressInput;
}

export interface Result {
  readonly payload?: { readonly linkedEmail: LinkedEmail };
}

export const perform = (client: ApolloClient<any>, input: LinkEmailAddressInput) =>
  client.mutate<Result, Variables>({ mutation: MUTATION, variables: { input } });
