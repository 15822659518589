import React, { DetailedHTMLProps, HTMLAttributes, useCallback } from "react";
import { toast } from "react-toastify";
import { Image } from "semantic-ui-react";
import { useApolloClient } from "@apollo/client";
import { QUERY, Result, Variables } from "../../api/documents/DocumentById";
import { ApiError, extractErrorMessages, noResultErrorFor } from "../../types";
import closeIcon from "../../assets/close-icon-white.svg";
import attachIcon from "../../assets/attach-icon.svg";

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  readonly documentId: string;
  readonly documentName: string;
  readonly onDelete?: () => void;
}

export const DocumentBadge = (props: Props) => {
  const { documentId, documentName, onDelete, ...divProps } = props;
  const client = useApolloClient();

  const onLinkClick: React.MouseEventHandler = useCallback(() => {
    const variables: Variables = { id: documentId };
    client
      .query<Result, Variables>({ query: QUERY, variables, fetchPolicy: "network-only" })
      .then((res) => {
        if (!res.data.node || !res.data.node.downloadUrl) {
          return Promise.reject(noResultErrorFor("Document"));
        }
        const a = document.createElement<"a">("a");
        a.href = res.data.node.downloadUrl;
        a.rel = "noopener noreferrer";
        a.download = documentName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((e: ApiError) => toast.error(extractErrorMessages(e).join(", ")));
  }, [client, documentId, documentName]);

  const handleDelete = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (!onDelete) {
        return;
      }
      onDelete();
    },
    [onDelete]
  );

  return (
    <div key={documentId} className="DocumentBadge" {...divProps} onClick={onLinkClick}>
      <Image src={attachIcon} className="DocumentBadge-icon" />
      <button className="DocumentBadge-filename" type="button" onClick={onLinkClick}>
        {documentName}
      </button>
      {onDelete && (
        <div className="DocumentBadge-close" onClick={handleDelete}>
          <Image src={closeIcon} verticalAlign="middle" onClick={handleDelete} />
        </div>
      )}
    </div>
  );
};
