export interface EvaluationSchema {
  readonly preparation: EvaluationAnswer;
  readonly functionalRequirements: EvaluationAnswer;
  readonly nonFunctionalRequirements: EvaluationAnswer;
  readonly pricingProposal: EvaluationAnswer;
  readonly interactionWithBidder: EvaluationAnswer;
  readonly responseStructure: EvaluationAnswer;
  readonly bidderCommunication: EvaluationAnswer;
}

export enum EvaluationAnswer {
  NotAtAll,
  Slightly,
  Reasonably,
  Very,
  Completely,
}

export const readableEvaluation = (v: unknown): string => {
  switch (v) {
    case EvaluationAnswer.NotAtAll:
      return "Not at all";
    case EvaluationAnswer.Slightly:
      return "Slightly";
    case EvaluationAnswer.Reasonably:
      return "Reasonably";
    case EvaluationAnswer.Very:
      return "Very";
    case EvaluationAnswer.Completely:
      return "Completely";
    default:
      return JSON.stringify(v);
  }
};
