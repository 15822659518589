import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketState, TicketStatus } from "../../types/ticket";

export const MUTATION = gql`
  mutation ConfirmTicketDelivery($input: ConfirmTicketDeliveryInput!) {
    payload: confirmTicketDelivery(input: $input) {
      ticket {
        id
        deliveryApprovedAt
        deliveryDisprovedAt
        state
        status
      }
    }
  }
`;

export interface Variables {
  readonly input: ConfirmTicketDeliveryInput;
}

interface ConfirmTicketDeliveryInput {
  readonly ticketId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
  readonly completionReviewForm: string;
}

export interface Result {
  readonly payload?: ConfirmTicketDeliveryPayload;
}

interface ConfirmTicketDeliveryPayload {
  readonly ticket: {
    readonly state: TicketState;
    readonly status: TicketStatus;
    readonly deliveryApprovedAt?: string;
    readonly deliveryDisprovedAt?: string;
  } & Identifiable;
}
