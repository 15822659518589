import React from "react";
import cerebreonLogo from "../../../assets/logo-cerebreon.png";
import dcLogo from "../../../assets/logo-digitalclipboard.png";
import simeLogo from "../../../assets/logo-sime.png";
import cvGroupLogo from "../../../assets/logo-cv-group.png";
import jivaLogo from "../../../assets/logo-jiva.png";
import gnBioLogo from "../../../assets/logo-qnbio.png";
import checkstepLogo from "../../../assets/logo-checkstep.svg";

interface CardElements {
  readonly logo: string;
  readonly name: string;
  readonly oneLiner: string;
  readonly website: string;
}

const currentPortfolio: ReadonlyArray<CardElements> = [
  {
    logo: cerebreonLogo,
    name: "Cerebreon",
    oneLiner: "Transforming the debt industry with data and deep learning",
    website: "https://cerebreon.com",
  },
  {
    logo: dcLogo,
    name: "Digital Clipboard",
    oneLiner: "Digital client onboarding for professional advisors",
    website: "https://www.digitalclipboard.com",
  },
  {
    logo: simeLogo,
    name: "Sime Clinical AI",
    oneLiner: "Unique data to life - saving solutions",
    website: "https://simedx.com",
  },
  {
    logo: cvGroupLogo,
    name: "Consilience Group",
    oneLiner: "Bringing innovation to venture capital",
    website: "https://www.consilienceventures.com",
  },
  {
    logo: checkstepLogo,
    name: "CheckStep",
    oneLiner:
      "CheckStep is a deep-tech company that understands who benefits from, believes in and refutes User-Generated Content (UGC) through AI and Crowdsourcing",
    website: "https://www.checkstep.com",
  },
  {
    logo: jivaLogo,
    name: "Jiva AI",
    oneLiner:
      "Jiva is a low-code platform enabling the creation of multimodal AI solutions for companies of all sizes.",
    website: "https://www.jiva.ai/",
  },
  {
    logo: gnBioLogo,
    name: "QV Bio",
    oneLiner: "Longer, better quality lives for brain tumour patients.",
    website: "https://www.qvbio.co.uk/",
  },
];

export const PortfolioCards = () => {
  return (
    <div className="StartupCardContainer">
      {currentPortfolio.map((el) => (
        <div key={el.name} className="PortfolioCard">
          <a href={el.website} target="_blank" rel="noopener noreferrer">
            <div className="PortfolioCard-top">
              <img src={el.logo} alt="logo" />
            </div>
            <div className="PortfolioCard-bottom">
              <div className="PortfolioCard-bottom-name">{el.name}</div>
              <div className="PortfolioCard-bottom-oneLiner">{el.oneLiner}</div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};
