import { CBCMemberExpertOnboardCard } from "./CBCMemberExpertOnboardCard";
import { Result, QUERY, Variables } from "../../../api/onboards/CBCMemberNotVotedExpertOnboards";
import { useQuery } from "@apollo/client";
import { Enrolled } from "../../../contexts/Session/state";
import { Loader, Segment } from "semantic-ui-react";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { extractErrorMessages } from "../../../types";
import { nodesFromEdges } from "../../../types/relay";
import { CSSProperties, useCallback, useMemo } from "react";
import { PaginationLinks } from "../../elements/PaginationLinks";

const RESULTS_PER_PAGE = 6;
const flexStyle: CSSProperties = { display: "flex" };

interface Props {
  readonly sessionState: Enrolled;
}

export const PendingCBCMemberVoteList = ({ sessionState }: Props) => {
  const roleId = sessionState.roleId;
  const { data, loading, error, refetch } = useQuery<Result, Variables>(QUERY, {
    variables: { first: RESULTS_PER_PAGE, roleId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const pageInfo = useMemo(() => data?.onboards?.pageInfo, [data]);

  const previousOnClick = useCallback(() => {
    if (!pageInfo || !pageInfo.hasPreviousPage || !pageInfo.startCursor) {
      return;
    }
    const before = pageInfo.startCursor;
    refetch({ before, after: undefined, first: undefined, last: RESULTS_PER_PAGE });
  }, [pageInfo, refetch]);

  const nextOnClick = useCallback(() => {
    if (!pageInfo || !pageInfo.hasNextPage || !pageInfo.endCursor) {
      return;
    }
    const after = pageInfo.endCursor;
    refetch({ after, before: undefined, first: RESULTS_PER_PAGE, last: undefined });
  }, [pageInfo, refetch]);

  const applicationCards = useMemo(() => {
    if (loading) {
      return <Loader active inline="centered" />;
    } else if (error) {
      return <ErrorMessages errors={extractErrorMessages(error)} />;
    } else if (!data || !data.onboards || !data.onboards.edges) {
      return <Segment textAlign="center">No applications found...</Segment>;
    }

    const onboards = nodesFromEdges(data.onboards.edges);
    return (
      <>
        {onboards.map((o) => (
          <CBCMemberExpertOnboardCard onboard={o} key={o.id} />
        ))}
      </>
    );
  }, [data, error, loading]);

  return (
    <>
      <div style={flexStyle}>
        <div className="NomineeVoting-title">Pending to Vote</div>
        <PaginationLinks
          pageInfo={pageInfo}
          previousOnClick={previousOnClick}
          nextOnClick={nextOnClick}
          loading={loading}
        />
      </div>
      <div className="PendingCBCMemberVoteList">{applicationCards}</div>
    </>
  );
};
