import React, { useCallback } from "react";
import { Button, Container, Modal } from "semantic-ui-react";
import { utils } from "../../../../../../utils/utils";
import { SignatureButton } from "../../../../SignatureButton";

interface Props {
  readonly onGeneralClose: () => void;
  readonly changeModal: (isBack: boolean) => void;
  readonly sign: () => void;
}

export const ModalTerms = ({ onGeneralClose, changeModal, sign }: Props) => {
  const handleBack = useCallback(() => {
    changeModal(true);
  }, [changeModal]);

  const handleAccept = useCallback(() => sign(), [sign]);

  return (
    <Modal
      className="CustomClose"
      onClose={onGeneralClose}
      open={true}
      size="mini"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>Members' terms</Modal.Header>
      <Modal.Content>
        Please view the terms of collaboration outlined in the &nbsp;
        <a
          href={`${utils.apiHost}/static/files/cv-members-terms-and-conditions-191010.pdf`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          members' terms
        </a>
        <br />
        <br />
        <Container>
          <Button onClick={handleBack}>Back</Button>
          <SignatureButton primary onClick={handleAccept} floated="right" content="Accept" />
        </Container>
      </Modal.Content>
    </Modal>
  );
};
