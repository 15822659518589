import { gql } from "@apollo/client";

export const ADMIN_SECONDER_CANDIDACY_FRAGMENT = gql`
  fragment AdminSeconderCandidacy on SeconderCandidacy {
    id
    state
    insertedAt
    updatedAt
    assignedAt
    onboard {
      id
      state
      status
    }
    seconder {
      id
      type
      kind
      fullName
      avatarUrl
      user {
        id
        primaryLinkedEmail {
          id
          address
        }
      }
      onboard {
        id
        type
        form {
          id
          data
          schemaVersion
        }
      }
    }
  }
`;
