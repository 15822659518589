import { CSSProperties, useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Context, DeepPartial, useForm } from "uniforms";
import { Button, ButtonProps, Grid, Segment } from "semantic-ui-react";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { MUTATION as SubmitMut } from "../../../api/onboards/SubmitOnboard";
import { Variables as SubmitVars, Result as SubmitRes } from "../../../api/onboards/SubmitOnboard";
import { MUTATION as AmendMut } from "../../../api/onboards/AmendOnboard";
import { Variables as AmendVars, Result as AmendRes } from "../../../api/onboards/AmendOnboard";
import { OnboardFormProps } from "../../../components/pages/Dashboard/applications/application";
import { bridge, InitialProfileType } from "../../../schemas/onboards/schemas/InitialProfileSchema";
import { noResultErrorFor } from "../../../types";
import { isSuccessState } from "../../../contexts/Generic";
import { useCategoriesState } from "../../../contexts/Categories";
import { CustomLongTextField } from "../../CustomLongTextField";
import { CustomNumField } from "../../tickets/components/CustomNumField";
import { CustomBoolRadioField, readableCustomBoolRadioField } from "../../CustomBoolRadioField";
import { CustomDropdownSelectionField } from "../../CustomDropdownSelectionField";
import { CountriesList } from "../../../utils/CountriesList";
import { CustomPriorityListField } from "../../CustomPriorityListField";
import { YearlyInvestment, StartupsWorkedWith, readableStartupsWorkedWith } from "../utils/_types";
import { readableYearlyInvestment } from "../utils/_types";
import { DisplayIf } from "../../DisplayIf";
import { BusinessCases } from "../../invites/_types";
import { TagNamespace } from "../../../types/labelQuery";
import { NestedTagsField } from "../../NestedTagsField";
import { useScrollToTopApi } from "../../../components/elements/ScrollToTop";
import { CustomTextField } from "../../CustomTextField";

const segmentStyle: CSSProperties = { padding: "24px" };

export const InitialProfileForm = ({ onboard }: OnboardFormProps) => {
  const categoriesState = useCategoriesState();
  const [submitOnboard] = useMutation<SubmitRes, SubmitVars>(SubmitMut);
  const [amendOnboard] = useMutation<AmendRes, AmendVars>(AmendMut);
  const schemaVersion = onboard.migratedForm?.schemaVersion;
  const { scrollToTop } = useScrollToTopApi();

  const skillOptions = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [];
    }
    return Object.entries(categoriesState.result.skillsMapping)
      .sort(([_, a], [__, b]) => a.localeCompare(b))
      .map(([key, text]) => ({ key, value: key, text }));
  }, [categoriesState]);

  const [occupationsTree, sectorsTree] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null];
    }

    return [categoriesState.result.occupationsTree, categoriesState.result.sectorsTree];
  }, [categoriesState]);

  const checkIfCoInvestor = useCallback(
    ({ model }: Context<InitialProfileType>) => model.businessCase === BusinessCases.CoInvestor,
    []
  );
  const checkIfIntroducer = useCallback(
    ({ model }: Context<InitialProfileType>) => model.businessCase === BusinessCases.Introducer,
    []
  );
  const checkIfEarlyProfessionalOrProminentSkills = useCallback(
    ({ model }: Context<InitialProfileType>) =>
      model.businessCase === BusinessCases.EarlyProfessional ||
      model.businessCase === BusinessCases.ProminentSkills,
    []
  );

  const checkInvolvedInExit = useCallback(
    ({ model }: Context<InitialProfileType>) => !!model.involvedInExit,
    []
  );

  const onSave = useCallback(
    (model: DeepPartial<InitialProfileType>) => {
      if (!schemaVersion) {
        return toast.error("Missing schema version.");
      }

      const data = JSON.stringify(model);
      amendOnboard({ variables: { input: { schemaVersion, onboardId: onboard.id, data } } })
        .then(() => toast.success("Changes saved."))
        .catch((err) => {
          console.warn(err);
          toast.error("Failed to save your onboard.");
        });
    },
    [onboard, schemaVersion, amendOnboard]
  );

  const onValidate = useCallback((_, e) => {
    if (!!e) {
      console.warn(e);
    }
    return e;
  }, []);

  const onSubmit = useCallback(
    (ev: InitialProfileType) => {
      if (!schemaVersion) {
        return toast.error("Missing schema version.");
      }

      const data = JSON.stringify(ev);
      amendOnboard({ variables: { input: { schemaVersion, onboardId: onboard.id, data } } })
        .then(() => submitOnboard({ variables: { input: { onboardId: onboard.id } } }))
        .then((res) => {
          if (!res?.data?.payload?.onboard) {
            return Promise.reject(noResultErrorFor("AmendOnboard"));
          }
          scrollToTop();
        })
        .catch((err) => {
          console.warn(err);
          toast.error("Failed to submit onboard.");
        });
    },
    [onboard, schemaVersion, amendOnboard, submitOnboard, scrollToTop]
  );

  return (
    <Segment style={segmentStyle}>
      <AutoForm
        schema={bridge}
        showInlineError
        model={onboard.migratedForm?.data}
        onSubmit={onSubmit}
        onValidate={onValidate}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width="10">
              <CustomTextField
                name="linkedIn"
                label="Please provide a link to your LinkedIn profile"
                errorMessage="This field is mandatory and must begin with https://"
                placeholder="https://www.linkedin.com/"
                showtextcount={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="15">
              <CustomLongTextField
                name="motivation"
                label="What resonated with you about CV that encouraged you to say yes to your nomination?"
                errorMessage="This field is mandatory."
                placeholder="Type your message..."
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10">
              <CustomNumField
                name="engagementLevel"
                label="How much time are you willing to invest working with startups and/or the community?"
                errorMessage="This field is mandatory."
                placeholder="0"
                className="EngagementLevelField"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10">
              <CustomBoolRadioField
                horizontal
                name="openingNetwork"
                label="Are you comfortable opening up your network, where suitable, to support CVs endeavours?"
                errorMessage="This field is mandatory."
                transform={readableCustomBoolRadioField}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10">
              <CustomBoolRadioField
                horizontal
                name="committeeInvolvement"
                label="Are you willing/have the time to get involved in one of the committees?"
                errorMessage="This field is mandatory."
                transform={readableCustomBoolRadioField}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10">
              <CustomDropdownSelectionField
                name="experienceGeography"
                label=" In which countries have you done the most work?"
                errorMessage="This field is mandatory."
                dropdownOptions={CountriesList}
                placeholder="Search location..."
                multiple
                search
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10">
              <CustomPriorityListField
                name="topSkills"
                label="In the context of working with startups, what are your most useful or unique skills?"
                errorMessage="This field is mandatory."
                extraLabel="Select and rank your top 5 skills"
                options={skillOptions}
                tagNamespace={TagNamespace.Skills}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10">
              <CustomDropdownSelectionField
                name="startupsWorkedWith"
                label="How many startups or small businesses have you worked with?"
                errorMessage="This field is mandatory."
                dropdownOptions={startupsWorkedWithOptions}
                placeholder="Select element"
              />
            </Grid.Column>
          </Grid.Row>

          <DisplayIf condition={checkIfCoInvestor}>
            <>
              <Grid.Row>
                <Grid.Column width="10">
                  <CustomDropdownSelectionField
                    name="typicalYearlyInvestment"
                    label="If you are a private investor, how much do you typically invest per year?"
                    dropdownOptions={typicalYearlyInvestmentOptions}
                    placeholder="Select element"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="10">
                  <CustomBoolRadioField
                    name="involvedInExit"
                    horizontal
                    label="Have you been involved in any sizeable exit(s) as an investor or in a different capacity?"
                    errorMessage="This field is mandatory."
                    transform={readableCustomBoolRadioField}
                  />
                </Grid.Column>
              </Grid.Row>

              <DisplayIf condition={checkInvolvedInExit}>
                <Grid.Row>
                  <Grid.Column width="10">
                    <CustomLongTextField
                      name="additionalInformation"
                      label="Please provide additional information about your involvement in those exits"
                      errorMessage="This field is mandatory."
                      placeholder="Type your message"
                    />
                  </Grid.Column>
                </Grid.Row>
              </DisplayIf>
            </>
          </DisplayIf>
          <DisplayIf condition={checkIfIntroducer}>
            <Grid.Row>
              <Grid.Column width="10">
                <CustomBoolRadioField
                  name="happyToIntroduce"
                  horizontal
                  label="Many of our start-ups are looking for advisory and technical expertise (API integrations, Data Science (AI/ML/NLP), Cybersecurity, Blockchain integration, etc). Do you have people within your network that may suit and you would be happy to introduce to us?"
                  errorMessage="This field is mandatory."
                  transform={readableCustomBoolRadioField}
                />
              </Grid.Column>
            </Grid.Row>
          </DisplayIf>
          <DisplayIf condition={checkIfEarlyProfessionalOrProminentSkills}>
            <>
              <Grid.Row>
                <Grid.Column width="10">
                  <NestedTagsField
                    name="occupations"
                    label="What are your primary occupation(s)?"
                    errorMessage="This field is mandatory."
                    placeholder="Select 1 or more occupation tags"
                    collapsedText="Occupations"
                    options={occupationsTree}
                    namespace={TagNamespace.Occupations}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="10">
                  <NestedTagsField
                    name="sectors"
                    label="In what sectors do you have experience in?"
                    errorMessage="This field is mandatory."
                    placeholder="Select 1 or more sector tags"
                    collapsedText="Sectors"
                    options={sectorsTree}
                    namespace={TagNamespace.Sectors}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          </DisplayIf>
          <Grid.Row>
            <Grid.Column>
              <SaveButton type="button" color="blue" basic onSave={onSave}>
                <b>Save</b>
              </SaveButton>
              <Button type="submit" content="Submit" color="blue" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AutoForm>
    </Segment>
  );
};

interface SaveProps extends ButtonProps {
  readonly onSave: (m: DeepPartial<InitialProfileType>) => void;
}
const SaveButton = ({ onSave, ...props }: SaveProps) => {
  const { model } = useForm<InitialProfileType>();
  const onClick = useCallback(() => onSave(model), [model, onSave]);

  return <Button {...props} onClick={onClick} />;
};

const startupsWorkedWithOptions = Object.entries(StartupsWorkedWith).map(([_, v]) => ({
  text: readableStartupsWorkedWith(v),
  value: v,
}));

const typicalYearlyInvestmentOptions = Object.entries(YearlyInvestment).map(([_, v]) => ({
  text: readableYearlyInvestment(v),
  value: v,
}));
