import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CheckboxTree, { Node } from "react-checkbox-tree";
import { Input } from "semantic-ui-react";
import { escapeRegExp } from "lodash";
import { TagTree, TagTrees } from "../types/tags";
import { generateUUID } from "../utils/Numbers";
import { SuggestLabelsModal } from "./../components/elements/SuggestLabelsModal";
import { TagNamespace } from "../types/labelQuery";

interface State {
  readonly search: string;
  readonly active: boolean;
}
const buildInitialState = (): State => ({ search: "", active: false });

interface Props {
  readonly label?: string;
  readonly placeholder?: string;
  readonly collapsedText?: string;
  readonly checked?: string[];
  readonly tags?: Readonly<TagTree>;
  readonly namespace: TagNamespace;
  readonly onFilter: (checked: string[]) => void;
}

export const NestedTags = (props: Props) => {
  const { label, placeholder, collapsedText, checked = [], tags, namespace, onFilter } = props;
  const [{ active, search }, setState] = useState(() => buildInitialState());
  const uuid = useMemo(() => generateUUID(), []);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Handles clicks and key presses that would close the dropdown.
  useEffect(() => {
    const clickListener: EventListener = (ev) => {
      let element = ev.target as HTMLElement | null | undefined;
      do {
        if (element?.id.includes(uuid)) {
          // This is a click inside. Do nothing, just return.
          return setState((s) => (s.active ? s : { ...s, active: true }));
        } else if (element?.id === "root") {
          break;
        }
        // Go up the DOM
        element = element?.parentElement;
      } while (element);

      // This was a click outside, make the component inactive.
      setState((s) => ({ ...s, active: false }));
    };

    const escapeListener = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        setState((s) => ({ ...s, active: false }));
      }
    };

    document.addEventListener("click", clickListener);
    document.addEventListener("keydown", escapeListener);

    return () => {
      document.removeEventListener("click", clickListener);
      document.removeEventListener("keydown", escapeListener);
    };
  }, [uuid]);

  // This hook performs some "cleanup" everytime `active` value changes.
  useEffect(() => {
    if (!active) {
      setState(buildInitialState());
    } else {
      dropdownRef.current?.scrollTo(0, 0);
    }
  }, [active]);

  const onFocus = useCallback(() => {
    // closes previous openedNestTags with values in them
    document.getElementById("root")?.click();
    setState((s) => ({ ...s, active: true }));
  }, []);

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
    setState((s) => ({ ...s, search: target.value }));
  }, []);

  const onCheck = useCallback(
    (c: string[]) => {
      setState((s) => ({ ...s, checked: c }));
      onFilter(c);
    },
    [onFilter]
  );

  const [nodes, expanded] = useMemo(() => {
    let filteredTagTrees: ReadonlyArray<TagTree> | undefined;
    if (!search) {
      filteredTagTrees = tags?.children;
    } else if (!!tags) {
      const regX = new RegExp("(" + escapeRegExp(search) + ")", "gi");

      filteredTagTrees = applyFilter(tags, checked, regX).children;
    }

    const tmpNodes = toNodes(uuid, filteredTagTrees) || [];
    const tmpExpanded = tmpNodes?.flatMap((n) => getAllNonLeafChildrenValues(n));

    return [tmpNodes, tmpExpanded];
  }, [checked, search, tags, uuid]);

  const isUnfocusedWithValues = !active && checked.length > 0;

  return (
    <div className={`CustomCheckboxTree${active ? " active" : ""}`}>
      {label && <label className="CustomCheckboxTree-Label">{label}</label>}
      <div id={uuid} className="CustomCheckboxTree-Dropdown-wrapper">
        <Input
          className={isUnfocusedWithValues ? "blue-input" : "white-input"}
          icon="search"
          iconPosition="left"
          placeholder={placeholder}
          onFocus={onFocus}
          input={
            isUnfocusedWithValues ? (
              <button className="CustomCheckboxTree-CustomInput">
                {collapsedText || "Selected"} <div>{checked.length}</div>
              </button>
            ) : (
              <input autoFocus={active} id={uuid} value={search} onChange={onSearchChange} />
            )
          }
        />
        <div ref={dropdownRef} className="CustomCheckboxTree-Dropdown" id={uuid}>
          {nodes.length === 0 && <p>No results were found matching this criteria...</p>}
          <CheckboxTree
            id={uuid}
            nodes={nodes}
            // Either use "all" or "leaf" depending on how the backend filters.
            checkModel="all"
            checked={checked}
            expanded={expanded}
            onCheck={onCheck}
            expandDisabled={true}
            icons={checkboxTreeIcons}
          />
          <SuggestLabelsModal namespace={namespace} />
        </div>
      </div>
    </div>
  );
};

const checkboxTreeIcons = {
  expandOpen: null,
  parentClose: null,
  parentOpen: null,
  leaf: null,
  check: <i className="checkbox-checked" />,
  uncheck: <i className="checkbox-unchecked" />,
  halfCheck: <i className="checkbox-half-checked" />,
};

const getAllNonLeafChildrenValues = (node: Node): string[] => {
  if (!node.children) {
    return [];
  }

  return [node.value, ...node.children.flatMap((n) => getAllNonLeafChildrenValues(n))];
};

export const applyFilter = (
  item: Readonly<TagTree>,
  checked: string[],
  filter: RegExp
): Readonly<TagTree> => {
  if (item.label !== "root" && (item.description.match(filter) || checked.includes(item.label))) {
    return item;
  }

  const children = (item.children || []).reduce((acc: TagTrees, n: TagTree) => {
    const filteredNode = applyFilter(n, checked, filter);
    return filteredNode.children ? [...acc, filteredNode] : acc;
  }, []);

  return { ...item, children: children.length > 0 ? children : undefined };
};

export const toNodes = (uuid: string, tagTrees?: Readonly<TagTrees>): Node[] | undefined => {
  return tagTrees && tagTrees.length > 0
    ? tagTrees.map((n) => ({
        children: toNodes(uuid, n.children),
        label: <span id={uuid}>{n.description}</span>,
        showCheckbox: n.assignable,
        value: n.label,
      }))
    : undefined;
};
