import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { JsonDocumentVersioned } from "../../types/jsonDocument";
import { OnboardForm } from "../../types/OnboardForm";
import { Connection, ConnectionVariables } from "../../types/relay";
import { RoleSort } from "../../types/roleSort";

export const QUERY = gql`
  query startups(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sortBy: [RoleSort!]
    $fulltext: String
    $sectors: [String!]
    $maturities: [String!]
  ) {
    startupsConnection: startups(
      after: $after
      before: $before
      first: $first
      last: $last
      sortBy: $sortBy
      fulltext: $fulltext
      sectors: $sectors
      maturities: $maturities
    ) {
      edges {
        node {
          id
          fullName
          avatarUrl
          canInvest
          followedBy
          insertedAt
          onboard {
            id
            form(migrate: false) {
              id
              data
              schemaVersion
            }
          }
          profile {
            ... on OrganisationProfile {
              id
              headline
              linkedinProfileUrl
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export interface Filters {
  readonly sortBy?: RoleSort;
  readonly fulltext?: string;
  readonly sectors?: string[];
  readonly maturities?: string[];
}

export interface Variables extends Filters, ConnectionVariables {}

export interface OnboardWithFormOnly extends Identifiable {
  readonly form?: JsonDocumentVersioned<OnboardForm>;
}

export interface Startup extends Identifiable {
  readonly fullName: string;
  readonly avatarUrl: string;
  readonly canInvest: boolean;
  readonly followedBy: boolean;
  readonly insertedAt: string;
  readonly onboard: OnboardWithFormOnly;
  readonly profile: {
    readonly headline: string;
    readonly linkedinProfileUrl?: string;
  } & Identifiable;
}

export interface Result {
  readonly startupsConnection?: Connection<Startup>;
}
