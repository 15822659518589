import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "./../../utils/utils";
import { TicketSchema } from "./_types";
import { TicketPaymentMethods, TicketAnswer } from "./_enums";
import { TicketEngagementLevel, TicketDuration } from "./_enums";
import { valuesOfNumericEnum } from "./../../types";
import { schema as DeliverableSchema } from "./TODeliverablesSchema";
import { schema as ShortTicketSchema } from "./ShortTicketSchema";
import { globalDefinitions } from "./../_definitions";

export const schema: JSONSchemaType<TicketSchema> = {
  type: "object",
  additionalProperties: false,
  properties: {
    title: ShortTicketSchema.properties!.title,
    executionDate: ShortTicketSchema.properties!.executionDate,
    importance: ShortTicketSchema.properties!.importance,
    acceptanceCriteria: {
      type: "string",
      maxLength: 1000,
      pattern: "(.|s)*\\S(.|s)*",
    },
    deliverables: {
      type: "array",
      items: DeliverableSchema,
      minItems: 1,
      maxItems: 30,
    },
    scope: {
      type: "string",
      maxLength: 1000,
      pattern: "(.|s)*\\S(.|s)*",
    },
    assurance: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketAnswer),
    },
    occupations: {
      type: "array",
      items: globalDefinitions.occupation,
      minItems: 1,
    },
    engagementLevel: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketEngagementLevel),
    },
    duration: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketDuration),
    },
    skills: {
      type: "array",
      items: globalDefinitions.skills,
    },
    expertAttributes: {
      type: "array",
      items: { type: "string" },
      minItems: 1,
      maxItems: 5,
    },
    isOpenToRemoteWork: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketAnswer),
    },
    skillsAdditionalInfo: {
      type: "string",
      maxLength: 300,
      pattern: "(.|s)*\\S(.|s)*",
    },
    paymentMethod: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketPaymentMethods),
    },
    fiatPercentage: {
      type: "number",
      minimum: 1,
      maximum: 99,
    },
    cvdsPercentage: {
      type: "number",
      minimum: 1,
      maximum: 99,
    },
    daysOfWork: {
      type: "number",
      minimum: 1,
      maximum: 360,
    },
    minRatePerDay: {
      type: "number",
      minimum: 0,
    },
    maxRatePerDay: {
      type: "number",
      minimum: 0,
    },
    relatedTickets: {
      type: "array",
      items: {
        type: "string",
      },
    },
    terms: {
      type: "array",
      items: globalDefinitions.document,
      maxItems: 2,
    },
    deniedMembers: {
      type: "array",
      default: [],
      items: { type: "string" },
    },
    additionalInformation: {
      type: "string",
      maxLength: 1000,
      pattern: "(.|s)*\\S(.|s)*",
    },
    additionalDocuments: {
      type: "array",
      items: globalDefinitions.document,
      maxItems: 3,
    },
    consentToMattermostAlerts: { type: "boolean", enum: [true] },
    consentToShareEmail: { type: "boolean", enum: [true] },
  },
  required: [
    "title",
    "executionDate",
    "importance",
    "acceptanceCriteria",
    "deliverables",
    "scope",
    "assurance",
    "occupations",
    "engagementLevel",
    "duration",
    "expertAttributes",
    "isOpenToRemoteWork",
    "paymentMethod",
    "daysOfWork",
    "consentToMattermostAlerts",
    "consentToShareEmail",
  ],
  if: {
    properties: {
      paymentMethod: {
        type: "integer",
        const: TicketPaymentMethods.FiatAndCVDS,
      },
    },
  },
  then: {
    required: ["fiatPercentage", "cvdsPercentage"],
  },
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
