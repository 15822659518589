import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { Connection, ConnectionVariables } from "../../types/relay";
import { TicketState, TicketStatus, TickettingTicket } from "../../types/ticket";

export const QUERY = gql`
  query Tickets(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $as: TicketAs
    $roleId: ID
    $state: [TicketState!]
    $status: [TicketStatus!]
  ) {
    connection: tickets(
      after: $after
      before: $before
      first: $first
      last: $last
      as: $as
      state: $state
      status: $status
    ) {
      edges {
        node {
          id
          insertedAt
          state
          status
          newQuestionCount
          ticketForm(migrate: true) {
            id
            data
            schemaVersion
          }
          followedBy
          submittedBidBy(bidderId: $roleId) {
            id
          }
          owner {
            id
            fullName
            avatarUrl
          }
          questionCount
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export enum TicketAs {
  Insurer = "INSURER",
  Owner = "OWNER",
  WorkProvider = "WORK_PROVIDER",
}

export interface Variables extends ConnectionVariables {
  readonly as?: TicketAs;
  readonly roleId?: string;
  readonly state?: ReadonlyArray<TicketState>;
  readonly status?: ReadonlyArray<TicketStatus>;
}

export interface Result {
  readonly connection?: Connection<MarketPlaceTicket>;
}

export interface MarketPlaceTicket extends TickettingTicket {
  readonly owner: OwnerWithAvatarField;
  readonly questionCount: number;
}

export interface OwnerWithAvatarField extends Identifiable {
  readonly fullName: string;
  readonly avatarUrl: string;
}
