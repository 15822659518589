import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { BusinessCases, FundSize, NumberOfInvestments } from "./_types";

export interface CoInvestorInviteType {
  readonly businessCase: BusinessCases.CoInvestor;
  readonly activelyInvest: boolean | null;
  readonly numberOfInvestments: NumberOfInvestments | null; // Always optional but only display if `activelyInvest` is true;
  readonly fundSize: FundSize | null; // Always optional but only display if `activelyInvest` is true;
}

const schema: JSONSchemaType<CoInvestorInviteType> = {
  type: "object",
  properties: {
    businessCase: { type: "string", enum: [BusinessCases.CoInvestor] },
    activelyInvest: { type: "boolean" },
    numberOfInvestments: { type: "string", enum: Object.values(NumberOfInvestments) },
    fundSize: { type: "string", enum: Object.values(FundSize) },
  },
  required: ["businessCase"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
