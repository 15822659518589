import { gql } from "@apollo/client";
import { DeepPartial } from "uniforms";
import { TicketSchema } from "../../schemas/tickets/_types";
import { DeliverablesPlanSchema } from "../../schemas/deliverablesPlan/_types";
import { Identifiable } from "../../types";
import { JsonDocumentVersioned } from "../../types/jsonDocument";
import { TicketState, TicketStatus } from "../../types/ticket";
import { Connection } from "../../types/relay";

export const QUERY = gql`
  query DraftDeliverablesOnTicket($id: ID!) {
    payload: node(id: $id) {
      ... on TickettingTicket {
        id
        state
        status
        owner {
          id
          fullName
        }
        ticketForm(migrate: true) {
          id
          data
        }
        draftPlan {
          id
          insertedAt
          updatedAt
          form {
            id
            data
            schemaVersion
          }
        }
        seal {
          signature
        }
        planFeedback(first: 1) {
          edges {
            node {
              id
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly id: string;
}

export interface Result {
  readonly payload?: TicketPayload;
}

interface DraftPlan extends Identifiable {
  readonly insertedAt: string;
  readonly updatedAt: string;
  readonly form: JsonDocumentVersioned<DeepPartial<DeliverablesPlanSchema>>;
}

interface TicketPayload extends Identifiable {
  readonly owner: { readonly fullName: string } & Identifiable;
  readonly state: TicketState;
  readonly status: TicketStatus;
  readonly ticketForm?: JsonDocumentVersioned<TicketSchema>;
  readonly draftPlan?: DraftPlan;
  readonly seal?: { readonly signature: string };
  readonly planFeedback?: Connection<Identifiable>;
}
