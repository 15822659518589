import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketState, TicketStatus } from "../../types/ticket";

export const MUTATION = gql`
  mutation SignalTicketDelivery($input: SignalTicketDeliveryInput!) {
    payload: signalTicketDelivery(input: $input) {
      ticket {
        id
        deliverySignaledAt
        state
        status
      }
    }
  }
`;

export interface Variables {
  readonly input: SignalTicketDeliveryInput;
}

interface SignalTicketDeliveryInput {
  readonly ticketId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: SignalTicketDeliveryPayload;
}

interface SignalTicketDeliveryPayload {
  readonly ticket: {
    readonly state: TicketState;
    readonly status: TicketStatus;
    readonly deliverySignaledAt?: string;
  } & Identifiable;
}
