import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { JSONSchemaType } from "ajv";
import { BaseForm } from "uniforms";
import { useHistory, useLocation } from "react-router-dom";
import * as H from "history";
import dateFormat from "dateformat";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Divider, Image, Modal } from "semantic-ui-react";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import InfiniteScroll from "react-infinite-scroll-component";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { utils } from "../../../utils/utils";
import { CustomLongTextField } from "../../../schemas/CustomLongTextField";
import { globalDefinitions } from "../../../schemas/_definitions";
import { QUERY, Variables, Result } from "../../../api/proposals/DiscussionThreadOnBallot";
import { MUTATION } from "../../../api/proposals/PostDiscussionLightComment";
import { Variables as MutVars } from "../../../api/proposals/PostDiscussionLightComment";
import { Result as MutRes } from "../../../api/proposals/PostDiscussionLightComment";
import { nodesFromEdges } from "../../../types/relay";
import { LoaderWithMargin } from "../../Loader";
import { extractErrorMessages } from "../../../types";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { PreselectionVoteMigrator } from "../../../migrations/PreselectionVote";

const first: number = 10;
export const ballotVarName = "ballotId";

export const PreselectionRepliesModal = () => {
  const history = useHistory();
  const location = useLocation();
  const ballotId = new URLSearchParams(location.search).get(ballotVarName);
  const formRef = useRef<BaseForm<unknown>>();

  const lazyQuery = useLazyQuery<Result, Variables>(QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
  });
  const [getDiscussionThread, { data, loading, error: queryError, fetchMore }] = lazyQuery;

  const [postComment] = useMutation<MutRes, MutVars>(MUTATION);
  const [error, setError] = useState<Error | undefined>();

  // Handles querying the discussion thread every time `ballotId` changes.
  useEffect(() => {
    if (ballotId) {
      getDiscussionThread({ variables: { ballotId, first } });
    }
  }, [ballotId, getDiscussionThread]);

  useEffect(() => {
    if (queryError) {
      setError(queryError);
    }
  }, [queryError]);

  const { comments, pageInfo, migratedBallot } = useMemo(() => {
    if (!data || !data.node) {
      return { comments: [], pageInfo: undefined, migratedBallot: undefined };
    }

    const { edges, pageInfo: pInfo } = data.node.discussionThread.comments;
    const { migrate } = new PreselectionVoteMigrator(data.node);

    return { comments: nodesFromEdges(edges), pageInfo: pInfo, migratedBallot: migrate() };
  }, [data]);

  // To prevent the modal click to trigger the expand/contract behaviour of the VoteCollapsible component.
  const onModalClick = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
  }, []);

  const onNextInfiniteScroll = useCallback(() => {
    if (!fetchMore) {
      return;
    }

    fetchMore({ variables: { first, after: pageInfo?.endCursor } }).catch((err) => setError(err));
  }, [pageInfo, fetchMore]);

  const onChangeModel = useCallback(() => {
    if (error) {
      setError(undefined);
    }
  }, [error]);

  const onCloseReplies = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(ballotVarName);
    history.replace({ search: searchParams.toString() });
  }, [history, location]);

  const forwardRef = useCallback((ref) => (formRef.current = ref), []);

  const onSubmitComment = useCallback(
    (model: ReplySchema) => {
      const threadId = migratedBallot?.discussionThread.id;
      if (!threadId) {
        return setError(new Error("Something went wrong. Missing thread id."));
      } else if (!ballotId) {
        return setError(new Error("Something went wrong. Missing ballot id."));
      }

      postComment({
        variables: { input: { threadId, commentInput: { body: model.comment, title: "Reply" } } },
        refetchQueries: [
          { query: QUERY, variables: { ballotId, first: Math.max(5, comments.length) } },
        ],
      })
        .then(() => formRef.current?.reset())
        .catch((err) => setError(err));
    },
    [ballotId, comments.length, migratedBallot, postComment]
  );

  return (
    <Modal className="CustomClose" onClose={onCloseReplies} open={!!ballotId} closeIcon={true}>
      <div className="PreselectionRepliesModal" onClick={onModalClick}>
        <div className="PreselectionRepliesModal-Header">
          Comments on{" "}
          {utils.withPossessiveApostrophe(
            migratedBallot?.anonymizableProfile.fullName || "Anonymous"
          )}{" "}
          vote
        </div>
        <Divider className="PreselectionRepliesModal-Divider" />
        <div id="PreselectionRepliesModal-Content" className="PreselectionRepliesModal-Content">
          <InfiniteScroll
            dataLength={comments.length}
            hasMore={!!pageInfo?.hasNextPage}
            next={onNextInfiniteScroll}
            loader={loading ? <LoaderWithMargin /> : null}
            scrollableTarget="PreselectionRepliesModal-Content"
          >
            <div className="PreselectionRepliesModal-Content-comment">
              <div className="PreselectionRepliesModal-Content-comment-title">
                ADDITIONAL COMMENTS
              </div>
              {migratedBallot?.migratedForm?.data.comment || "n/a"}
            </div>
            {loading && comments.length === 0 && <LoaderWithMargin />}
            {comments.map((c, idx) => (
              <div key={idx} className="PreselectionRepliesModal-Content-Reply">
                <Image
                  src={c.anonymizableAvatarUrl}
                  className="PreselectionRepliesModal-Content-Reply-avatar"
                />
                <div className="PreselectionRepliesModal-Content-Reply-rightCol">
                  <div>
                    <div className="PreselectionRepliesModal-Content-Reply-rightCol-name">
                      {c.anonymizableProfile.fullName}
                    </div>
                    <div className="PreselectionRepliesModal-Content-Reply-rightCol-date">
                      {dateFormat(c.insertedAt, `dd/mm/yy HH:MM"h"`)}
                    </div>
                  </div>
                  <div className="PreselectionRepliesModal-Content-Reply-rightCol-text">
                    {c.body}
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
        <div className="PreselectionRepliesModal-Extra">
          <AutoForm
            schema={bridge}
            model={{}}
            ref={forwardRef}
            onChangeModel={onChangeModel}
            onSubmit={onSubmitComment}
          >
            <CustomLongTextField name="comment" label={null} placeholder="Type your message..." />
            <ErrorMessages errors={error ? extractErrorMessages(error) : error} />
            <Button type="submit" primary>
              Submit
            </Button>
          </AutoForm>
        </div>
      </div>
    </Modal>
  );
};

interface ReplySchema {
  readonly comment: string;
}

const schema: JSONSchemaType<ReplySchema> = {
  type: "object",
  properties: {
    comment: { ...globalDefinitions.nonEmptyString, maxLength: 1000 },
  },
  required: ["comment"],
};

const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));

export const openModal = (ballotId: string, search: string, history: H.History<unknown>) => {
  const searchParams = new URLSearchParams(search);
  searchParams.append(ballotVarName, ballotId);
  history.replace({ search: searchParams.toString() });
};
