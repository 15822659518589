import { gql } from "@apollo/client";
import { PAGEINFO_FRAGMENT } from "./PageInfo";

export const LINKEDEMAIL_FRAGMENT = gql`
  fragment LinkedEmail on LinkedEmail {
    id
    address
    gravatarUrl
    verifiedAt
  }
`;

export const LINKEDEMAIL_CONNECTION_FRAGMENT = gql`
  fragment LinkedEmailConnection on LinkedEmailConnection {
    edges {
      cursor
      node {
        ...LinkedEmail
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${LINKEDEMAIL_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;
