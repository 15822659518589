import { gql } from "@apollo/client";
import { SESSION_USER_FRAGMENT } from "../_fragments/User";
import { GQLSessionUser } from "../../types/frontend_only/sessionUser";

export const QUERY = gql`
  query Me {
    user: me {
      ...SessionUser
    }
  }
  ${SESSION_USER_FRAGMENT}
`;

export interface Result {
  readonly user: GQLSessionUser;
}
