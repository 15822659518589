import { gql } from "@apollo/client";
import { Identifiable } from "../types";

export const MUTATION = gql`
  mutation UpdateLastViewedAt($input: UpdateLastViewedAtInput!) {
    payload: updateLastViewedAt(input: $input) {
      viewing {
        id
        lastViewedAt
      }
    }
  }
`;

interface UpdateLastViewedAtInput {
  readonly threadId: string;
}

export interface Variables {
  readonly input: UpdateLastViewedAtInput;
}

export interface Result {
  readonly payload?: { readonly viewing: { readonly lastViewedAt: string } & Identifiable };
}
