export interface ExpertEvaluationSchema {
  readonly expectedContribution: string;
  readonly individualSkills: string;
  readonly availableToParticipate: ExpertOnboardEvaluationScore;
  readonly networkSynergies: ExpertOnboardEvaluationScore;
  readonly meetPortfolioNeeds: ExpertOnboardEvaluationScore;
  readonly tacitKnowledge: ExpertOnboardEvaluationScore;
  readonly startupExperience: ExpertOnboardEvaluationScore;
  readonly ecosystemBalance: ExpertOnboardEvaluationScore;
  readonly priceRange: ExpertOnboardEvaluationScore;
  readonly geographicSynergy: ExpertOnboardEvaluationScore;
  readonly startupKnowledge: ExpertOnboardEvaluationScore;
  readonly tokenEconomicsKnowledge: ExpertOnboardEvaluationScore;
  readonly cvdsKnowledge: ExpertOnboardEvaluationScore;
  readonly expertScale: ExpertOnboardEvaluationScore;
  readonly personalOpinion: string;
  readonly additionalInfo: string;
  readonly positiveStatements: ReadonlyArray<string>;
  readonly negativeStatements: ReadonlyArray<string>;
}

export enum ExpertOnboardEvaluationScore {
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
}

export enum ExpertEvaluationSchemaLabels {
  expectedContribution = "How do they expect to contribute (which services will they provide to startups)?",
  individualSkills = "Please provide a brief description of the individual - skills, industries, experience, impression made, such that people voting on this individual are able to make a relatively informed decision.",
  availableToParticipate = "How much time are they willing to invest with the startups? Would they be willing to invest time into the community?",
  networkSynergies = "(Please don't ask this directly; simply assess it from your conversation) Are they likely to bring strong network synergies? (Do they have the kind of professional network that would be beneficial to the ecosystem in terms of skills, industry, geography?)",
  meetPortfolioNeeds = "Do they meet the current/ future needs of our portfolio (including Consilience Group)? (Using your knowledge of the portfolio and startups in the funnel; do your best to answer or leave blank.)",
  tacitKnowledge = "Are they likely to drive tacit knowledge in the ecosystem?",
  startupExperience = "(Please don't ask this directly; simply assess it from your conversation) - Is their branding/status in the startup world strong?",
  ecosystemBalance = "Do they help us achieve a good balance of Advisors vs 'Doers' vs Product Owners?",
  priceRange = "Is the range of pricing for their services reasonable for our startups?",
  geographicSynergy = "Will our startups be likely to capture geographic synergies from this expert?",
  startupKnowledge = "Do they have a great understanding of how to work with startups?",
  tokenEconomicsKnowledge = "Do they have a strong understanding of token-economics, and year 1 & 2 economics of the platform?",
  cvdsKnowledge = "How interested did they seem in owning CVDS as an investable asset?",
  expertScale = "Do they seem to be an Expert, based on your conversation - do they seem credible, knowledgeable, and able to speak about relevant experiences?",
  positiveStatements = "Please provide up to 3 'Pros' or positive statements about the individual and their potential fit as an expert within the CV network, such that people voting on this individual are able to make a relatively informed decision.",
  negativeStatements = "Please provide up to 3 'Cons' or negative statements about the individual and their potential fit as an expert within the CV network, such that people voting on this individual are able to make a relatively informed decision.",
  personalOpinion = "Based on your assessment would you like the applicant to be a part of the CV Expert Network ?",
  additionalInfo = "Is there anything else about this nominee you'd like to note?",
}
