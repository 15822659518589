import { Children, cloneElement, isValidElement } from "react";
import { Divider } from "semantic-ui-react";
import classnames from "classnames";
import { connectField, filterDOMProps } from "uniforms";
import { ListFieldProps } from "uniforms-semantic";
import { CustomListAddField } from "./CustomListAddField";

interface Props extends ListFieldProps {
  readonly [key: string]: unknown;
  readonly addIconText: string;
}

export const CustomListField = connectField<Props>((props) => {
  const {
    children,
    className,
    disabled,
    error,
    errorMessage,
    initialCount,
    itemProps,
    label,
    required,
    showInlineError,
    value,
    addIconText,
    minCount: _,
    ...otherProps
  } = props;

  return (
    <div className={classnames("ui", className, { disabled })} {...filterDOMProps(otherProps)}>
      {label && (
        <div className={classnames({ error, required }, "field item")}>
          <label className="left floated">{label}</label>
        </div>
      )}

      {label && <div className="ui fitted hidden clearing horizontal divider" />}

      {!!(error && showInlineError) && <div className="ui red basic label">{errorMessage}</div>}

      {value?.map((_, itemIndex) =>
        Children.map(children, (child, childIndex) =>
          isValidElement(child) ? (
            <>
              {cloneElement(child, {
                key: `${itemIndex}-${childIndex}`,
                name: child.props.name?.replace("$", "" + itemIndex),
                ...itemProps,
              })}
              <Divider />
            </>
          ) : (
            child
          )
        )
      )}
      <CustomListAddField initialCount={initialCount} name="$" iconText={addIconText} />
    </div>
  );
});
