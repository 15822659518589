import { createContext, PropsWithChildren, useCallback, useRef } from "react";
import { useContext, useMemo, useState } from "react";

const PAGE_CONTENT_ID = "PageContentId";

interface ContextType {
  readonly setCBCBackground: () => void;
  readonly clearBackground: () => void;
  readonly pageContentCName: string;
  readonly pageContentId: string;
}

const Context = createContext<ContextType>({} as ContextType);
export const usePageContentProvider = () => useContext(Context);

export const PageContentProvider = ({ children }: PropsWithChildren<{}>) => {
  const [pageContentCName, setPageContentCName] = useState("PageContent");
  const { current } = useRef(PAGE_CONTENT_ID);

  const setCBCBackground = useCallback(
    () => setPageContentCName("PageContent PageContent--cbcPage"),
    []
  );
  const clearBackground = useCallback(() => setPageContentCName("PageContent"), []);

  const contextValue = useMemo(
    () => ({ setCBCBackground, clearBackground, pageContentCName, pageContentId: current }),
    [setCBCBackground, clearBackground, pageContentCName, current]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
