import { useContext } from "react";
import { Actioner } from "./actioner";
import { State, None, Connected, isNone, isConnected } from "./state";
import { Authorized, Linked, isAuthorized, isLinked } from "./state";
import { Context } from "./Context";

// Context related helper functions.
export const useChainApi = (): Actioner => useContext(Context).api;
export const useChainState = (): State => useContext(Context).state;

export const needsChainWall = (s: State): s is None | Connected => isNone(s) || isConnected(s);
export const requireNeedsWall = (s: State): None | Connected => {
  if (needsChainWall(s)) {
    return s;
  } else {
    throw new Error("A call to requireNeedsWall() cannot be fulfilled.");
  }
};
export const requireAuthorizedAtleast = (s: State): Authorized | Linked => {
  if (isAuthorized(s) || isLinked(s)) {
    return s;
  } else {
    throw new Error("A call to requireAuthorizedAtleast() cannot be fulfilled.");
  }
};
export const requireLinkedState = (s: State): Linked => {
  if (isLinked(s)) {
    return s;
  } else {
    throw new Error("A call to requireLinkedState() cannot be fulfilled.");
  }
};
