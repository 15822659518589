import { CSSProperties } from "react";
import { PageInfo } from "../../types/relay";

interface Props {
  readonly pageInfo?: PageInfo;
  readonly style?: CSSProperties;
  readonly previousOnClick: () => void;
  readonly nextOnClick: () => void;
  readonly firstOnClick?: () => void;
  readonly lastOnClick?: () => void;
  readonly loading?: boolean;
}

export const PaginationLinks = (props: Props) => {
  const { pageInfo, style, previousOnClick, nextOnClick, firstOnClick, lastOnClick, loading } =
    props;
  const { hasPreviousPage, hasNextPage } = pageInfo || {};

  if (!hasPreviousPage && !hasNextPage) {
    return null;
  }

  const firstClassname = `PaginationLinks-First${hasPreviousPage ? "" : " disabled"}`;
  const prevClassname = `PaginationLinks-Prev${hasPreviousPage ? "" : " disabled"}`;
  const nextClassname = `PaginationLinks-Next${hasNextPage ? "" : " disabled"}`;
  const lastClassname = `PaginationLinks-Next${hasNextPage ? "" : " disabled"}`;

  return (
    <div className="PaginationLinks" style={style}>
      {firstOnClick && (
        <div className={firstClassname} onClick={loading ? undefined : firstOnClick}>
          First
        </div>
      )}
      <div className={prevClassname} onClick={loading ? undefined : previousOnClick}>
        {"< Prev"}
      </div>
      <div className={nextClassname} onClick={loading ? undefined : nextOnClick}>
        {"Next >"}
      </div>
      {lastOnClick && (
        <div className={lastClassname} onClick={loading ? undefined : nextOnClick}>
          Last
        </div>
      )}
    </div>
  );
};
