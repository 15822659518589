import { valuesOfNumericEnum } from "../types";

export interface FormDocument {
  readonly id: string;
  readonly hash: string;
  readonly filename: string;
}

export enum YesNoAnswer {
  Yes,
  No,
}

export enum EvaluationScore {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
}

export const readableYesNoAnswer = (v: unknown): string => {
  switch (v) {
    case YesNoAnswer.Yes:
      return "Yes";
    case YesNoAnswer.No:
      return "No";
    default:
      return JSON.stringify(v);
  }
};

export const EvaluationScoreOptions = valuesOfNumericEnum(EvaluationScore).map((v) => ({
  value: v,
  text: v,
}));
