import { useCallback, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
import { SignatureButton } from "../../SignatureButton";
import { Enrolled } from "../../../../contexts/Session/state";
import { QUERY, SealQueryResult, ticketEnvelope } from "../../../../api/seals/TicketSeal";
import { MUTATION, Result, Variables } from "../../../../api/tickets/SignalTicketDelivery";
import { useSealFieldsQuery } from "../../../../hooks/useSealFieldsQuery";
import { TicketSealFields } from "../../../../api/seals/_fragments/TicketSealFields";
import { digestFormData } from "../../../../types/OnboardForm";
import { useChainApi, useChainState } from "../../../../contexts/Chain";
import { useMutation } from "@apollo/client";
import { ApiError } from "../../../../types";

interface Props {
  readonly ticket: Ticket;
  readonly sessionState: Enrolled;
}

export const ModalMarkTicketDelivered = ({ ticket, sessionState }: Props) => {
  const chainApi = useChainApi();
  const chainState = useChainState();
  const [isOpen, setIsOpen] = useState(false);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const [signalDelivery] = useMutation<Result, Variables>(MUTATION);

  const { owner } = ticket;

  const generalClose = useCallback(() => setIsOpen(false), []);
  const handleOpen = useCallback(() => setIsOpen(true), []);

  const onSign = useCallback(async () => {
    const transmittedAt = new Date();
    const { chainId } = sessionState;

    setSealFieldsLoading(true);
    const sealData = await sealFieldsQuery(QUERY, { id: ticket.id });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const fields: TicketSealFields = {
      ...sealData.fields,
    };
    const prevSig = ticket.seal?.signature;
    const envelope = ticketEnvelope(
      chainId,
      fields,
      transmittedAt,
      "signal_ticket_delivery",
      prevSig
    );
    const text = digestFormData(envelope, true);
    generalClose();
    chainApi
      .sign(chainState, text)
      .then((ethSignature) => {
        return signalDelivery({
          variables: { input: { ticketId: ticket.id, ethSignature, transmittedAt } },
        });
      })
      .then(() => {
        toast.success("Ticket successfully marked Delivered.");
      })
      .catch((err: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (err.message.includes("User denied message signature")) {
          handleOpen();
          return;
        }
        toast.error(err.message);
      });
  }, [
    chainApi,
    chainState,
    sessionState,
    ticket,
    sealFieldsQuery,
    signalDelivery,
    generalClose,
    handleOpen,
  ]);
  return (
    <Modal
      onClose={generalClose}
      className="ModalMarkTicketDeliverable CustomClose"
      size="tiny"
      closeIcon
      open={isOpen}
      trigger={
        <Button onClick={handleOpen} color="blue">
          Mark ticket as delivered
        </Button>
      }
    >
      <div className="ModalMarkTicketDeliverable-title">Mark the ticket as delivered?</div>
      <p className="ModalMarkTicketDeliverable-description">
        This cannot be undone. {owner.fullName} will be notified and asked to provide an evaluation
        of the work delivered.
      </p>
      <div className="ModalMarkTicketDeliverable-bttn-wrapper">
        <Button basic color="blue" onClick={generalClose} loading={sealFieldsLoading}>
          Cancel
        </Button>
        <SignatureButton content="Mark as delivered" onClick={onSign} loading={sealFieldsLoading} />
      </div>
    </Modal>
  );
};
