import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { Connection } from "../../types/relay";
import { TicketState, TicketStatus } from "../../types/ticket";
import { PAGEINFO_FRAGMENT } from "../_fragments/PageInfo";
import { PlanFeedback } from "./DeliverablesOnTicket";

export const MUTATION = gql`
  mutation RejectPlan($input: WinnerPlanRejectedInput!) {
    payload: winnerPlanRejected(input: $input) {
      ticket {
        id
        state
        status
        planFeedback(first: 4) {
          edges {
            node {
              id
              feedback
              insertedAt
              owner {
                id
                fullName
                avatarUrl
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PAGEINFO_FRAGMENT}
`;

export interface Variables {
  readonly input: WinnerPlanRejectedInput;
}

interface WinnerPlanRejectedInput {
  readonly ticketId: string;
  readonly ownerId: string;
  readonly feedback: string;
}

export interface Result {
  readonly payload?: WinnerPlanRejectedPayload;
}

interface WinnerPlanRejectedPayload {
  readonly ticket: SimpleTickettingTicket;
}

interface SimpleTickettingTicket extends Identifiable {
  readonly state: TicketState;
  readonly status: TicketStatus;
  readonly planFeedback?: Connection<PlanFeedback>;
}
