import { gql } from "@apollo/client";

export const MUTATION = gql`
  mutation SuggestLabels($input: SuggestLabelsInput!) {
    payload: suggestLabels(input: $input) {
      success
    }
  }
`;

interface SuggestLabelsInput {
  readonly suggestedLabels: string;
  readonly namespace: string;
  readonly url: string;
}

export interface Variables {
  readonly input: SuggestLabelsInput;
}

export interface Result {
  readonly payload?: { readonly success: boolean };
}
