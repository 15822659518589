import { MultiSelect, SelectProps } from "react-multi-select-component";

interface Props extends Omit<SelectProps, "labelledBy"> {
  readonly label: string;
}

export const CheckboxFilter = (props: Props) => {
  const { label, options, value, onChange, disableSearch, overrideStrings } = props;
  return (
    <div className="CheckboxFilter">
      <div className="CheckboxFilter-label">{label}</div>
      <MultiSelect
        className="MultiSelect"
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="filter-select"
        disableSearch={disableSearch}
        overrideStrings={overrideStrings}
      />
    </div>
  );
};
