import { Icon, Image, ImageProps } from "semantic-ui-react";
import { EntityKind, EntityType } from "../types/onboard";
import { assertUnreachable } from "./common";
import expertCircularIcon from "../assets/expert-circular-icon.svg";
import organisationCircularIcon from "../assets/organisation-circular-icon.svg";
import startupSquareIcon from "../assets/startup-square-icon.svg";

// Types
enum IconName {
  Startup = "rocket",
  Investor = "dollar sign",
  Expert = "book",
}

export enum BadgeColor {
  Accepted = "green",
  Pending = "orange",
  NonExistent = "grey",
  Default = "black",
}
interface Badge {
  readonly roleType: EntityKind;
  readonly icon: (c?: BadgeColor) => JSX.Element;
}

export const StartupBadge: Badge = {
  roleType: EntityKind.Startup,
  icon: (c?) => <Icon name={IconName.Startup} color={c ? c : BadgeColor.Default} />,
};
export const ExpertBadge: Badge = {
  roleType: EntityKind.Provider,
  icon: (c?) => <Icon name={IconName.Expert} color={c ? c : BadgeColor.Default} />,
};
export const InvestorBadge: Badge = {
  roleType: EntityKind.Provider,
  icon: (c?) => <Icon name={IconName.Investor} color={c ? c : BadgeColor.Default} />,
};

// Helper functions
export const badgeFor = (type: EntityKind): Badge => {
  switch (type) {
    case EntityKind.Startup:
      return StartupBadge;
    case EntityKind.Provider:
      return ExpertBadge;
  }
  assertUnreachable(type);
};

export const colorFor = (kinds: ReadonlyArray<EntityKind> | undefined, badge: Badge) => {
  if (hasRoleOfType(kinds || [], badge.roleType)) {
    return BadgeColor.Accepted;
  }

  return BadgeColor.NonExistent;
};

export const iconNameFor = (kind: EntityKind) => {
  switch (kind) {
    case EntityKind.Startup:
      return IconName.Startup;
    case EntityKind.Provider:
      return IconName.Expert;
  }
  assertUnreachable(kind);
};

interface RoleIconProps extends ImageProps {
  readonly kind: EntityKind;
  readonly type: EntityType;
}

export const RoleIcon = ({ kind, type, ...imgProps }: RoleIconProps) => {
  const src =
    kind === EntityKind.Startup
      ? startupSquareIcon
      : type === EntityType.Individual
      ? expertCircularIcon
      : organisationCircularIcon;

  return <Image src={src} alt="member-logo" {...imgProps} />;
};

const hasRoleOfType = (kinds: ReadonlyArray<EntityKind>, type: EntityKind) =>
  kinds.filter((k) => k === type).length > 0;
