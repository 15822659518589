import { gql } from "@apollo/client";
import { DeepPartial } from "uniforms";
import { DeliverablesPlanSchema } from "../../schemas/deliverablesPlan/_types";
import { Identifiable } from "../../types";
import { JsonDocumentVersioned } from "../../types/jsonDocument";

export const MUTATION = gql`
  mutation PublishDraftTicketPlan($input: PublishDraftTicketPlanInput!) {
    payload: publishDraftTicketPlan(input: $input) {
      ticket {
        id
        draftPlan {
          id
          insertedAt
          updatedAt
          form {
            id
            data
          }
        }
        planForm(migrate: true) {
          id
          data
          schemaVersion
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: IssueDraftTicketDeliverableInput;
}

interface IssueDraftTicketDeliverableInput {
  readonly draftId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: IssueDraftTicketDeliverablePayload;
}

interface DraftPlan extends Identifiable {
  readonly insertedAt: string;
  readonly updatedAt: string;
  readonly form: JsonDocumentVersioned<DeepPartial<DeliverablesPlanSchema>>;
}

interface IssueDraftTicketDeliverablePayload {
  readonly ticket: {
    readonly draftPlan?: DraftPlan;
    readonly planForm?: JsonDocumentVersioned<DeliverablesPlanSchema>;
  } & Identifiable;
}
