import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { GQLSessionUser } from "../../types/frontend_only/sessionUser";
import { SESSION_USER_CONNECTION_FRAGMENT } from "../_fragments/User";

export const QUERY = gql`
  query AdminUsers(
    $after: String
    $before: String
    $confirmed: Boolean
    $email: String
    $enroled: Boolean
    $first: Int
    $fulltext: String
    $last: Int
    $profile: String
    $roleProfile: String
    $sortBy: [UserSort!]
  ) {
    users(
      after: $after
      before: $before
      confirmed: $confirmed
      email: $email
      enroled: $enroled
      first: $first
      fulltext: $fulltext
      last: $last
      profile: $profile
      roleProfile: $roleProfile
      sortBy: $sortBy
    ) {
      ...SessionUserConnection
    }
  }
  ${SESSION_USER_CONNECTION_FRAGMENT}
`;

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export enum UserSortFields {
  InsertedAt = "INSERTED_AT",
}

export interface UserSort {
  readonly direction: SortDirection;
  readonly field: UserSortFields;
}

export interface Filters {
  readonly confirmed?: boolean;
  readonly email?: string;
  readonly enroled?: boolean;
  readonly fulltext?: string;
  readonly profile?: string;
  readonly roleProfile?: string;
  readonly sortBy?: UserSort;
}

export interface Variables extends Filters, ConnectionVariables {}

export interface Result {
  readonly users?: Connection<GQLSessionUser>;
}
