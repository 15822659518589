import { useMemo, useReducer, useEffect as useEffectDI, PropsWithChildren, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { provideDependencies } from "react-magnetic-di";
import { Context } from "./Context";
import { GenericStateTypes } from "../Generic/state";
import { State, reducer, Categories } from "./state";
import { ApiError, extractErrorMessages } from "../../types";
import { GenericActionTypes } from "../Generic/actions";
import { perform } from "../../api/tags/AllTags";
import { useSessionState } from "../Session";
import { isAtLeastPresent } from "../Session/state";
import { TagTree } from "../../types/tags";

export const CategoriesProvider = ({ children }: PropsWithChildren<{}>) => {
  const client = useApolloClient();
  const sessionState = useSessionState();
  const { initialState, useEffect } = CategoriesProvider.dependencies();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [firstRun, setFirstRun] = useState(true);

  useEffect(() => {
    if (!firstRun || !isAtLeastPresent(sessionState) || sessionState.fishy) {
      return;
    }
    setFirstRun(false);
    dispatch({ type: GenericActionTypes.Fetch });

    perform(client)
      .then((res) => {
        const result: Categories = orderCategories(res.data);
        dispatch({ type: GenericActionTypes.SetResult, result });
      })
      .catch((err: ApiError) => {
        console.warn(err);
        dispatch({ type: GenericActionTypes.SetError, errors: extractErrorMessages(err) });
      });
  }, [client, dispatch, sessionState, firstRun]);

  const providerValue = useMemo(() => ({ state }), [state]);

  return <Context.Provider value={providerValue} children={children} />;
};

export interface Dependencies {
  readonly initialState: State;
  readonly useEffect: typeof useEffectDI;
}
CategoriesProvider.dependencies = provideDependencies<Dependencies>({
  initialState: { type: GenericStateTypes.None },
  useEffect: useEffectDI,
});

const orderCategories = (cat: Categories): Categories => {
  return {
    ...cat,
    maturitiesTree: orderTagTree(cat.maturitiesTree),
    occupationsTree: orderTagTree(cat.occupationsTree),
    sectorsTree: orderTagTree(cat.sectorsTree),
    skillsTree: orderTagTree(cat.skillsTree),
  };
};

const orderTagTree = (tree: Readonly<TagTree>): Readonly<TagTree> => {
  if (!tree.children) {
    return tree;
  }

  return {
    ...tree,
    children: [...tree.children]
      .sort((a, b) => (a.description > b.description ? 1 : -1))
      .map((c) => orderTagTree(c)),
  };
};
