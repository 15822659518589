import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { PlannedDeliverable, DeliverablesPlanSchema } from "./_types";
import { schema as deliverableSchema } from "../tickets/TODeliverablesSchema";
import { valuesOfNumericEnum } from "../../types";
import { YesNoAnswer } from "../_types";

const plannedDeliverableSchema: JSONSchemaType<PlannedDeliverable> = {
  type: "object",
  properties: {
    title: deliverableSchema.properties!.title,
    description: deliverableSchema.properties!.description,
    dueDate: deliverableSchema.properties!.dueDate,
    associatePayment: { type: "integer", enum: valuesOfNumericEnum(YesNoAnswer) },
    totalPayment: { type: "integer", minimum: 0, maximum: 100 },
  },
  required: [...deliverableSchema.required, "associatePayment"],
  if: {
    properties: {
      associatePayment: {
        type: "integer",
        const: YesNoAnswer.Yes,
      },
    },
  },
  then: {
    required: ["totalPayment"],
  },
};

export const schema: JSONSchemaType<DeliverablesPlanSchema> = {
  type: "object",
  properties: {
    deliverables: {
      type: "array",
      minItems: 1,
      items: plannedDeliverableSchema,
    },
  },
  required: ["deliverables"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
