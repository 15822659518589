import { useCallback, useState } from "react";
import { Icon, Modal } from "semantic-ui-react";
import { FeedbackForm } from "../../schemas/feedback/FeedbackForm";

export const FeedbackButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onFeedbackClick = useCallback(() => setIsOpen(true), []);
  const onModalClose = useCallback(() => setIsOpen(false), []);

  return (
    <Modal
      className="FeedbackModal CustomClose"
      open={isOpen}
      closeIcon
      closeOnDimmerClick
      onClose={onModalClose}
      trigger={
        <button className="FeedbackButton" onClick={onFeedbackClick}>
          Feedback <Icon name="comment alternate outline" />
        </button>
      }
    >
      <FeedbackForm onModalClose={onModalClose} />
    </Modal>
  );
};
