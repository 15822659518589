import { CSSProperties, useCallback, useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { Context } from "uniforms";
import { QUERY, Variables, Result, Filters } from "../../../api/admin/Users";
import { UserSortFields, SortDirection } from "../../../api/admin/Users";
import { bridge } from "../../../schemas/admin/AdminUsersFilterSchema";
import { CustomTextField } from "../../../schemas/CustomTextField";
import { CustomBoolRadioField } from "../../../schemas/CustomBoolRadioField";
import { ClearModelButton } from "../../../schemas/ClearModelButton";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { DisplayIf } from "../../../schemas/DisplayIf";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { extractErrorMessages } from "../../../types";
import { nodesFromEdges } from "../../../types/relay";
import { AdminUserCard } from "./AdminUserCard";
import { AdminUsersSortField } from "./AdminUsersSortField";
import { AdminUsersPaginationLinksField } from "./AdminUsersPaginationLinksField";

const first = 10;
const initialFilters: Filters = {
  sortBy: { field: UserSortFields.InsertedAt, direction: SortDirection.Desc },
};

const statusColumnStyle: CSSProperties = { display: "flex", flexDirection: "column" };
const dummyLabelStyle: CSSProperties = { height: "24px" };
const radioFieldStyle: CSSProperties = { marginBottom: 0, flexGrow: 1 };
const buttonColumnStyle: CSSProperties = {
  display: "flex",
};

// State.
export const AdminUsersTab = () => {
  const [users, { loading, data, error }] = useLazyQuery<Result, Variables>(QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    users({ variables: { first, ...initialFilters } });
  }, [users]);

  const clearFiltersCondition = useCallback(({ model }: Context<Filters>) => {
    return (
      model.confirmed !== undefined ||
      !!model.fulltext ||
      (model.sortBy !== undefined && model.sortBy.direction !== initialFilters.sortBy?.direction)
    );
  }, []);

  const onSubmit = useCallback(
    (filters: Filters) => users({ variables: { first, ...filters } }),
    [users]
  );

  const pageContent = useMemo(() => {
    if (loading) {
      return <Loader active inline="centered" />;
    } else if (error) {
      return <ErrorMessages errors={extractErrorMessages(error)} />;
    } else if (!data?.users || data.users.edges?.length === 0 || !data?.users?.pageInfo) {
      return <Segment textAlign="center">No users found...</Segment>;
    }
    return nodesFromEdges(data.users.edges).map((u) => <AdminUserCard key={u.id} user={u} />);
  }, [data, loading, error]);

  return (
    <AutoForm schema={bridge} model={initialFilters} showInlineError onSubmit={onSubmit}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Grid columns="16">
              <Grid.Row>
                <Grid.Column width="4">
                  <CustomTextField name="fulltext" label="Fulltext search" showtextcount={false} />
                </Grid.Column>
                <Grid.Column width="8" style={statusColumnStyle}>
                  <div style={dummyLabelStyle} />
                  <CustomBoolRadioField
                    style={radioFieldStyle}
                    name="confirmed"
                    label="Status"
                    inline
                    showUndefinedOption
                    transform={readableEmailStatus}
                  />
                </Grid.Column>
                <Grid.Column width="4">
                  <AdminUsersSortField
                    name="sortBy"
                    label="Sort by:"
                    placeholder="Select element"
                    required={false}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={buttonColumnStyle} width="16">
                  <Button type="submit" primary content="Apply Filters" />
                  <DisplayIf condition={clearFiltersCondition}>
                    <ClearModelButton content="Clear all filters" />
                  </DisplayIf>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <AdminUsersPaginationLinksField first={first} data={data} users={users} loading={loading} />
        <Grid.Row>
          <Grid.Column>{pageContent}</Grid.Column>
        </Grid.Row>
      </Grid>
    </AutoForm>
  );
};

const readableEmailStatus = (v: unknown): string => {
  switch (v) {
    case undefined:
      return "All";
    case true:
      return "Email confirmed";
    case false:
      return "Email unconfirmed";
    default:
      return JSON.stringify(v);
  }
};
