import { digestObj } from "../../types/OnboardForm";
import { ticketSubject } from "./TicketSeal";
import { FeedbackEvaluationSealFields } from "./_fragments/FeedbackEvaluationSealFields";

export const feedbackEvaluationEnvelope = (
  chainId: number,
  fields: FeedbackEvaluationSealFields,
  transmittedAt: Date
) => ({
  chain_id: chainId.toString(),
  header: "Feedback Evaluation",
  meta: { previous_seal_signature: null },
  subject: feedbackEvaluationSubject(fields, transmittedAt),
});

const feedbackEvaluationSubject = (
  fields: FeedbackEvaluationSealFields,
  transmittedAt: Date
): FeedbackEvaluationSealFields => ({
  ...fields,
  form: digestObj(fields.form, false) as any,
  ticket: ticketSubject(fields.ticket, new Date(fields.ticket.transmittedAt)),
  transmittedAt: transmittedAt.toISOString().split(".")[0] + "Z",
});
