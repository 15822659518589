import { DeepPartial } from "uniforms";
import { Button, Grid } from "semantic-ui-react";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { bridge, IntroducerInviteType } from "./IntroducerInviteSchema";
import { BusinessCases } from "./_types";
import { CustomLongTextField } from "../CustomLongTextField";
import { BackButton } from "./BackButton";
import { PageTwoFormType } from "../../components/pages/NominateExpert";
import { CSSProperties } from "react";

const buttonColumnStyle: CSSProperties = { display: "flex" };

const defaultModel: DeepPartial<IntroducerInviteType> = {
  businessCase: BusinessCases.Introducer,
};

interface Props {
  readonly initialModel?: DeepPartial<IntroducerInviteType>;
  readonly onBack: (model: PageTwoFormType) => void;
  readonly onSubmit: (model: IntroducerInviteType) => void;
}

export const IntroducerInviteForm = ({ initialModel, onBack, onSubmit }: Props) => (
  <AutoForm
    schema={bridge}
    showInlineError
    onSubmit={onSubmit}
    model={initialModel || defaultModel}
  >
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <CustomLongTextField
            name="previousStakeholders"
            label="What type of stakeholders has the nominee worked with in the past?"
            placeholder="X million raised for Y startups/funds, X companies sold, X M&A's done, etc"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={buttonColumnStyle}>
          <BackButton onBack={onBack} />
          <Button type="submit" primary content="Submit" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </AutoForm>
);
