import { useEffect, useMemo, useCallback } from "react";
import { isEmpty } from "lodash";
import { useLazyQuery } from "@apollo/client";
import { Option } from "react-multi-select-component";
import { useHistory, useLocation } from "react-router-dom";
import { PageContentInfinityScroll } from "../../InfinityScrollWrapper";
import { Enrolled } from "../../../contexts/Session/state";
import { BidCard } from "../../elements/tickets/bid/BidCard";
import { BidDraftCard } from "../../elements/tickets/bid/BidDraftCard";
import { QUERY, Result, Variables } from "../../../api/tickets/MyBids";
import { isEnumArray } from "../../../types";
import { nodesFromEdges } from "../../../types/relay";
import { TicketBidState, TicketBidStatus } from "../../../types/bid";
import { LoaderWithMargin } from "../../Loader";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { Shortcuts as S } from "../../../routing";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { TabTitles } from "./index";
import { CheckboxFilter } from "../../elements/CheckboxFilter";

const first: number = 10;

const filterVarName = S.myWork.queryVarNames.filter;
enum Filters {
  Draft = "draft",
  Submitted = "submitted",
  Accepted = "accepted",
  Declined = "declined",
}

const multiSelectStrings = {
  allItemsAreSelected: "All",
  selectSomeItems: "None",
  selectAll: "All",
};
const FILTER_OPTIONS: Option[] = [
  { key: Filters.Draft, label: "Draft", value: Filters.Draft },
  { key: Filters.Submitted, label: "Submitted", value: Filters.Submitted },
  { key: Filters.Accepted, label: "Accepted", value: Filters.Accepted },
  { key: Filters.Declined, label: "Declined", value: Filters.Declined },
];

interface Props {
  readonly sessionState: Enrolled;
}

export const MyBids = ({ sessionState: { roleId } }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const breadcrumbApi = useBreadcrumbApi();
  const [bidsQuery, bidsData] = useLazyQuery<Result, Variables>(QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { data, loading, error: bidsError, fetchMore } = bidsData;
  const { edges, pageInfo } = data?.role?.bidConnection || {};

  const activeFilters: Filters[] = useMemo(() => {
    const params = new URLSearchParams(location.search).get(filterVarName)?.split("-");
    if (isEnumArray(params, Filters)) {
      return params;
    }
    return [];
  }, [location]);

  const multiSelectValues = useMemo(() => filtertsToOptions(activeFilters), [activeFilters]);

  useEffect(() => {
    breadcrumbApi.addCustomTitle(TabTitles.Bids);

    return () => breadcrumbApi.removeCustomTitle();
  }, [breadcrumbApi]);

  useEffect(() => {
    if (!roleId) {
      return;
    }
    bidsQuery({ variables: { first, roleId, ...queryVarsFromFilters(activeFilters) } });
  }, [roleId, activeFilters, bidsQuery]);

  const onNext = useCallback(() => {
    if (roleId && pageInfo?.hasNextPage && fetchMore) {
      const after = pageInfo.endCursor;
      const v: Variables = { first, roleId, after, ...queryVarsFromFilters(activeFilters) };
      fetchMore({ variables: v });
    }
  }, [pageInfo, roleId, activeFilters, fetchMore]);

  const onFilterChange = useCallback(
    (opts: Option[]) => {
      const newFilters = optionsToFilters(opts);
      const valueAsParam = newFilters.join("-");
      const params = new URLSearchParams(location.search);

      if (isEmpty(valueAsParam)) {
        params.delete(filterVarName);
      } else {
        params.set(filterVarName, valueAsParam);
      }

      history.push({ search: params.toString() });

      bidsQuery({ variables: { first, roleId, ...queryVarsFromFilters(newFilters) } });
    },
    [roleId, history, location, bidsQuery]
  );

  const bidsList = useMemo(() => {
    if (!data || !data.role || !data.role.bidConnection) {
      return undefined;
    }
    const bids = nodesFromEdges(data.role.bidConnection.edges);

    if (bids === undefined || bids.length === 0) {
      return <>You currently have no bids.</>;
    }

    return bids.map((b) =>
      b.state === TicketBidState.Editable ? (
        <BidDraftCard key={b.id} bid={b} />
      ) : (
        <BidCard key={b.id} bid={b} ticketForm={b.ticket.ticketForm} ticketOwner={b.ticket.owner} />
      )
    );
  }, [data]);

  if (bidsError) {
    return <ErrorMessages errors={[bidsError.message]} />;
  }

  const loader = loading ? <LoaderWithMargin /> : null;

  return (
    <>
      <div className="sort-div">
        <div className="ComponentHeader">Bids</div>
        <CheckboxFilter
          label="Show:"
          options={FILTER_OPTIONS}
          value={multiSelectValues}
          onChange={onFilterChange}
          disableSearch
          overrideStrings={multiSelectStrings}
        />
      </div>
      <PageContentInfinityScroll
        dataLength={edges?.length || 0}
        next={onNext}
        hasMore={!!pageInfo?.hasNextPage}
        loader={loader}
      >
        {bidsList}
      </PageContentInfinityScroll>
    </>
  );
};

const queryVarsFromFilters = (filters: ReadonlyArray<Filters>): Partial<Variables> => {
  let vars: Partial<Variables> = {};
  if (filters.length === 0 || filters.length === FILTER_OPTIONS.length) {
    return vars;
  }
  if (filters.includes(Filters.Draft)) {
    vars = { ...vars, state: [TicketBidState.Editable], status: [TicketBidStatus.Open] };
  }
  if (filters.includes(Filters.Submitted)) {
    vars = {
      ...vars,
      state: [...(vars.state || []), TicketBidState.Submitted],
      status: [...(vars.status || []), TicketBidStatus.Published],
    };
  }
  if (filters.includes(Filters.Accepted)) {
    vars = {
      ...vars,
      state: [...(vars.state || []), TicketBidState.Closed],
      status: [
        ...(vars.status || []),
        TicketBidStatus.SelectedWinner,
        TicketBidStatus.SelectedWinnerAcknowledged,
        TicketBidStatus.ConfirmedWinner,
      ],
    };
  }
  if (filters.includes(Filters.Declined)) {
    vars = {
      ...vars,
      state: [...(vars.state || []), TicketBidState.Closed],
      status: [...(vars.status || []), TicketBidStatus.Loser],
    };
  }

  return vars;
};

const optionsToFilters = (opts: Option[]): Filters[] => opts.map((o) => o.value);

const filtertsToOptions = (filters: Filters[]): Option[] =>
  FILTER_OPTIONS.filter((o) => filters.includes(o.value));
