import classnames from "classnames";
import React, { CSSProperties } from "react";
import { useCallback } from "react";
import { Button, Icon } from "semantic-ui-react";
import { connectField, filterDOMProps, joinName, useField } from "uniforms";
import { ListDelFieldProps } from "uniforms-semantic";

const iconStyle: CSSProperties = {
  marginRight: "0px",
};

interface Props extends ListDelFieldProps {
  readonly [k: string]: unknown;
}

export const CustomListDelField = connectField<Props>(
  ({ disabled, name, readOnly, className, ...props }) => {
    const nameParts = joinName(null, name);
    const nameIndex = +nameParts[nameParts.length - 1];
    const parentName = joinName(nameParts.slice(0, -1));
    const parent = useField<{ minCount?: number }, unknown[]>(
      parentName,
      {},
      { absoluteName: true }
    )[0];

    const limitNotReached = !disabled && !(parent.minCount! >= parent.value!.length);

    const onAction = useCallback(
      (event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (limitNotReached && !readOnly && (!("key" in event) || event.key === "Enter")) {
          const value = parent.value!.slice();
          value.splice(nameIndex, 1);
          parent.onChange(value);
        }
      },
      [limitNotReached, nameIndex, parent, readOnly]
    );

    return (
      <Button
        {...filterDOMProps(props)}
        className={classnames(
          "ui",
          className,
          limitNotReached ? "link" : "disabled",
          "fitted close icon",
          "noShadowBttn"
        )}
        onClick={onAction}
        onKeyDown={onAction}
        role="button"
        tabIndex={0}
        color="red"
        basic
        type="button"
      >
        <b>Remove</b>&nbsp;&nbsp;&nbsp;
        <Icon name="minus circle" style={iconStyle} />
      </Button>
    );
  },
  {
    initialValue: false,
    kind: "leaf",
  }
);
