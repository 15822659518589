import { useCallback } from "react";
import { AutoFormState, useForm } from "uniforms";
import classNames from "classnames";
import { Button, ButtonProps } from "semantic-ui-react";

interface Props extends ButtonProps {
  readonly asLink?: boolean;
}

export const ClearModelButton = ({ asLink, onClick, ...btttnProps }: Props) => {
  const { formRef } = useForm();

  const onClickLocal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
      // Caution: potential unsafe, there's no uniforms documentation/issues to cover form clearing.
      formRef.setState((s) => ({ ...s, model: {} } as AutoFormState<unknown>));
      if (onClick) {
        onClick(event, data);
      }
    },
    [formRef, onClick]
  );

  return (
    <Button
      {...btttnProps}
      type="button"
      onClick={onClickLocal}
      className={classNames(btttnProps.className, asLink ? "ClearButtonLink" : "")}
    />
  );
};
