import { Identifiable } from ".";
import { JsonDocumentVersioned } from "./jsonDocument";
import { EntityKind, EntityType, OnboardState, OnboardStatus } from "./onboard";
import { OnboardForm } from "./OnboardForm";

export enum SeconderCandidaciesStates {
  Assigned = "ASSIGNED",
  Volunteered = "VOLUNTEERED",
}

interface User extends Identifiable {
  readonly primaryLinkedEmail?: {
    readonly address: string;
  } & Identifiable;
}

interface Onboard extends Identifiable {
  readonly type: EntityType;
  readonly form: JsonDocumentVersioned<OnboardForm>;
}

interface Role extends Identifiable {
  readonly type: EntityType;
  readonly kind: EntityKind;
  readonly fullName: string;
  readonly avatarUrl: string;
  readonly user: User;
  readonly onboard: Onboard;
}

export interface SeconderCandidacy extends Identifiable {
  readonly state: SeconderCandidaciesStates;
  readonly insertedAt: string;
  readonly updatedAt: string;
  readonly assignedAt: string;
  readonly onboard: {
    readonly state: OnboardState;
    readonly status: OnboardStatus;
  } & Identifiable;
  readonly seconder: Role;
}
