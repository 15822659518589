import { gql } from "@apollo/client";
import { OnboardSealFields, ONBOARD_SEAL_FIELDS } from "./OnboardSealFields";

export const PROPOSAL_SEAL_FIELDS = gql`
  fragment ProposalSealFields on Proposal {
    kind
    choices
    votable {
      ... on Onboard {
        ...OnboardSealFields
      }
    }
  }
  ${ONBOARD_SEAL_FIELDS}
`;

export interface ProposalSealFields {
  readonly kind: string;
  readonly choices: ReadonlyArray<string>;
  readonly votable: OnboardSealFields;
}
