import { gql } from "@apollo/client";

export const JSONDOCUMENT_FRAGMENT = gql`
  fragment JsonDocument on JsonDocument {
    id
    data
    schemaName
    schemaVersion
    validated
  }
`;
