import { Linked, isLinked } from "../../../contexts/Chain/state";
import { Authorized, isAuthorized, Connected, isConnected } from "../../../contexts/Chain/state";
import { useChainState } from "../../../contexts/Chain";
import { EnrolledOrWall, EnrolledRoute } from "./session";
import { RouteProps } from "react-router-dom";
import { Enrolled } from "../../../contexts/Session/state";

enum ErrorType {
  NotConnected,
  NotAuthorized,
  NotLinked,
  NotGovernor,
}

interface WallProps {
  readonly error: ErrorType;
}
const Wall = ({ error }: WallProps) => {
  let message;
  switch (error) {
    case ErrorType.NotConnected:
      message = "This page requires that your browser is connected to our chain.";
      break;
    case ErrorType.NotAuthorized:
      message = "This page requires an authorization from your browser to access the chain.";
      break;
    case ErrorType.NotLinked:
      message = "This page requires that your browser is linked to your chain identity.";
      break;
    case ErrorType.NotGovernor:
      message = "This page requires that you are a governor.";
      break;
    default:
      throw new Error("Unknown chain wall error");
  }
  return (
    <>
      <h1>Chain Error</h1>
      <p>{message}</p>
    </>
  );
};

interface ConnectedProps {
  children: (s: Connected) => JSX.Element;
}
export const ConnectedOrWall = ({ children }: ConnectedProps) => {
  const s = useChainState();
  return isConnected(s) ? children(s) : <Wall error={ErrorType.NotConnected} />;
};

interface AuthorizedProps {
  children: (s: Authorized) => JSX.Element;
}
export const AuthorizedOrWall = ({ children }: AuthorizedProps) => {
  const s = useChainState();
  return isAuthorized(s) ? children(s) : <Wall error={ErrorType.NotAuthorized} />;
};

interface LinkedProps {
  children: (s: Linked) => JSX.Element;
}
export const LinkedOrWall = ({ children }: LinkedProps) => {
  const s = useChainState();
  return isLinked(s) ? children(s) : <Wall error={ErrorType.NotLinked} />;
};

interface GovernorProps extends RouteProps {
  children: (cs: Linked, ss: Enrolled) => JSX.Element;
}
export const GovernorOrWall = ({ children, ...routeProps }: GovernorProps) => (
  <EnrolledRoute {...routeProps}>
    <EnrolledOrWall>
      {(ss) => (
        <LinkedOrWall>
          {(cs) =>
            ss.roleChainData.isGovernor ? children(cs, ss) : <Wall error={ErrorType.NotGovernor} />
          }
        </LinkedOrWall>
      )}
    </EnrolledOrWall>
  </EnrolledRoute>
);
