import React, { useMemo } from "react";
import { Popup, Label } from "semantic-ui-react";
import { utils } from "../../utils/utils";

const maxSize = { height: "100%", width: "100%" };

interface Props {
  readonly image: string | undefined;
  readonly accept?: string;
  readonly onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const ImageUploader = ({ image, accept, onFileChange }: Props) => {
  const UID = useMemo(() => utils.uuidv4(), []);
  return (
    <>
      <input
        type="file"
        accept={accept}
        id={UID}
        className="HiddenFileInput"
        onChange={onFileChange}
      />
      <div className="ui bordered image small">
        <label htmlFor={UID}>
          <Popup
            trigger={<Label as="a" color="blue" corner="right" icon="upload" />}
            content="Change Picture"
          />
        </label>
        <img src={image} style={maxSize} alt="thumbnail" />
      </div>
    </>
  );
};
