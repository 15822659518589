import { useCallback } from "react";
import { connectField, HTMLFieldProps } from "uniforms";
import { SortDirection, UserSort, UserSortFields } from "../../../api/admin/Users";
import { CustomDropdownSelectionField } from "../../../schemas/CustomDropdownSelectionField";
import { isEnum } from "../../../types";

const OLDEST_FIRST: UserSort = { field: UserSortFields.InsertedAt, direction: SortDirection.Asc };
const NEWEST_FIRST: UserSort = { field: UserSortFields.InsertedAt, direction: SortDirection.Desc };

enum SortValue {
  Newest = "NEWEST",
  Oldest = "OLDEST",
}

interface SortByOptions {
  readonly text: string;
  readonly value: SortValue;
}

interface Props extends HTMLFieldProps<UserSort, HTMLElement> {
  readonly [k: string]: unknown;
}

export const AdminUsersSortField = connectField(({ value, onChange, ...props }: Props) => {
  const onDropdownChange = useCallback(
    (v: unknown) => {
      if (isEnum(v, SortValue)) {
        onChange(sortValueToUserSort(v));
      }
    },
    [onChange]
  );

  return (
    <CustomDropdownSelectionField
      {...props}
      name=""
      value={userSortToSortValue(value)}
      onChange={onDropdownChange}
      dropdownOptions={sortByOptions}
    />
  );
});

const sortByOptions: SortByOptions[] = [
  {
    text: "Oldest first",
    value: SortValue.Oldest,
  },
  {
    text: "Newest first",
    value: SortValue.Newest,
  },
];

const sortValueToUserSort = (v: SortValue): UserSort => {
  switch (v) {
    case SortValue.Newest:
      return NEWEST_FIRST;
    case SortValue.Oldest:
      return OLDEST_FIRST;
  }
};

const userSortToSortValue = (v?: UserSort): SortValue => {
  switch (v) {
    case NEWEST_FIRST:
      return SortValue.Newest;
    case OLDEST_FIRST:
      return SortValue.Oldest;
    default:
      return SortValue.Newest;
  }
};
