import { gql } from "@apollo/client";
import { BALLOT_WITHOUT_DISSTHREAD_FRAGMENT } from "../_fragments/Ballot";
import { BallotWithFullProposal } from "../../types/ballot";
import { CBCExpertVoteChoice, CBCExpertVoteSchema } from "../../schemas/cbc/_types";

export const MUTATION = gql`
  mutation CBCMemberVote($input: VoteInput!) {
    payload: vote(input: $input) {
      ballot {
        ...BallotWithoutDissThread
      }
    }
  }
  ${BALLOT_WITHOUT_DISSTHREAD_FRAGMENT}
`;

interface VoteInput {
  readonly transmittedAt: Date;
  readonly ethSignature: string;
  readonly proposalId: string;
  readonly form: string;
}

export interface Variables {
  readonly input: VoteInput;
}

export interface Result {
  readonly payload?: {
    readonly ballot: BallotWithFullProposal<CBCExpertVoteChoice, CBCExpertVoteSchema>;
  };
}
