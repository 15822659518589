import React from "react";
import { connectField, HTMLFieldProps } from "uniforms";
import { Deliverable as DeliverableCollapsible } from "../../../components/elements/tickets/collapsible/Deliverable";
import { Props as DeliverableProps } from "../../../components/elements/tickets/collapsible/Deliverable";
import { DeliverableSchema } from "../_types";

interface Props
  extends Omit<DeliverableProps, "deliverable">,
    HTMLFieldProps<DeliverableSchema, HTMLElement> {
  readonly [k: string]: unknown;
}

export const DeliverableField = connectField((props: Props) => {
  const { index, activeIndex, isEdit, selectDropdown, deleteDropdown } = props;
  const { value, error, showInlineError, errorMessage } = props;

  if (!value) {
    return null;
  }

  return (
    <div>
      <DeliverableCollapsible
        index={index}
        activeIndex={activeIndex}
        isEdit={isEdit}
        deliverable={value}
        selectDropdown={selectDropdown}
        deleteDropdown={deleteDropdown}
        hasErrors={!!error}
      />
      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage}</div>
      )}
    </div>
  );
});
