import { gql } from "@apollo/client";
import { TicketSchema } from "../../schemas/tickets/_types";
import { TickettingDraft, TicketWithPlanFeedback } from "../../types/ticket";
import { PAGEINFO_FRAGMENT } from "../_fragments/PageInfo";
import { OwnerWithAvatarField } from "./Tickets";

export const QUERY = gql`
  query TicketById($ticketId: ID!) {
    payload: node(id: $ticketId) {
      ... on TickettingTicket {
        id
        insertedAt
        state
        status
        newQuestionCount
        hydratedAt
        seal {
          signature
        }
        ticketForm(migrate: true) {
          id
          data
          schemaVersion
        }
        updatedAt
        draft {
          id
          insertedAt
          updatedAt
          form {
            id
            data
          }
        }
        owner {
          id
          fullName
          avatarUrl
        }
        draftPlan {
          id
          insertedAt
          updatedAt
        }
        planFeedback(first: 1) {
          edges {
            node {
              id
              feedback
              insertedAt
              owner {
                id
                fullName
                avatarUrl
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PAGEINFO_FRAGMENT}
`;

export interface Variables {
  readonly ticketId: string;
}

export interface Result {
  readonly payload?: TicketPayload;
}

interface TicketPayload extends TicketWithPlanFeedback {
  readonly updatedAt: string;
  readonly hydratedAt?: string;
  readonly draft?: TickettingDraft<Partial<TicketSchema>>;
  readonly seal?: { readonly signature: string };
  readonly owner: OwnerWithAvatarField;
}
