import { MouseEventHandler, useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { Result, QUERY, Variables } from "../../../api/admin/AdminExpertOnboard";
import { Enrolled } from "../../../contexts/Session/state";
import { OnboardMigrator } from "../../../migrations/Onboards";
import { Shortcuts as S } from "../../../routing";
import { isEnum } from "../../../types";
import { MutualAssessment, OnboardState } from "../../../types/onboard";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { ExpertDetailsProfileTab } from "../ExpertOnboarding/ExpertDetailsProfileTab";
import { SeconderVolunteersTab } from "./SeconderVolunteersTab";
import { SeconderEvaluationTab } from "./SeconderEvaluationTab";
import { useAdminExpertDetailsBreadcrumb } from "./AdminExpertDetailsBreadcrumb";

const idVarName = S.administrationExpert.queryVarNames.id;
const tabVarName = S.administrationExpert.queryVarNames.tab;

export enum Tabs {
  Profile = "profile",
  Volunteers = "volunteers",
  EvaluationOne = "evaluation-one",
  EvaluationTwo = "evaluation-two",
}

interface Props {
  readonly sessionState: Enrolled;
}

export const AdminExpertDetail = ({ sessionState }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { roleId } = sessionState;
  const onboardId = new URLSearchParams(location.search).get(idVarName) || "";

  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { onboardId, roleId },
  });

  const onboard = useMemo(() => {
    if (!data?.onboard) {
      return undefined;
    }

    const { migrate } = new OnboardMigrator(data.onboard);
    return migrate();
  }, [data]);

  // Custom hook to inject the correct breadcrumb.
  useAdminExpertDetailsBreadcrumb(onboard);

  const { firstCandidacy, secondCandidacy, assignedSecondersCount, firstEval, secondEval } =
    useMemo(() => {
      if (
        !onboard?.extraFields.assignedSeconderCandidacies ||
        !onboard?.extraFields.mutualAssessments
      ) {
        return {
          firstCandidacy: undefined,
          secondCandidacy: undefined,
          assignedSecondersCount: 0,
          firstEval: undefined,
          secondEval: undefined,
        };
      }
      const evals = onboard.extraFields.mutualAssessments;
      const firstCand = onboard.extraFields.assignedSeconderCandidacies[0];
      const secondCand = onboard.extraFields.assignedSeconderCandidacies[1];
      const firstSeconderEval: MutualAssessment | undefined = evals.find(
        ({ seconder }) => seconder.id === firstCand.seconder.id
      );
      const secondSeconderEval: MutualAssessment | undefined = evals.find(
        ({ seconder }) => seconder.id === secondCand.seconder.id
      );

      return {
        firstCandidacy: firstCand,
        secondCandidacy: secondCand,
        assignedSecondersCount: onboard.extraFields.assignedSeconderCandidacies.length,
        firstEval: firstSeconderEval,
        secondEval: secondSeconderEval,
      };
    }, [onboard]);

  const selectedTab = useMemo(() => {
    const { state } = onboard || {};
    const tab = new URLSearchParams(location.search).get(tabVarName);

    if (isEnum(tab, Tabs)) {
      return tab;
    }
    let defaultTab: Tabs = Tabs.Profile;

    switch (state) {
      case OnboardState.AwaitingSeconders:
        defaultTab = Tabs.Volunteers;
        break;
      case OnboardState.InMutualAssessment:
        defaultTab = firstCandidacy ? Tabs.EvaluationOne : defaultTab;
        break;
    }

    return defaultTab;
  }, [onboard, firstCandidacy, location]);

  const onTabClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      const newTab = ev.currentTarget.id;
      if (isEnum(newTab, Tabs) && newTab !== selectedTab) {
        const newSearch = new URLSearchParams(location.search);
        newSearch.set(tabVarName, newTab);
        return history.replace({ search: newSearch.toString() });
      }
    },
    [history, location, selectedTab]
  );

  if (error) {
    return <ErrorMessages errors={[error.message]} />;
  } else if (!onboard || !onboard.migratedForm || !onboard.migratedForm.data) {
    return loading ? <Loader active inline="centered" /> : null;
  }

  const profileTabCname = `PageContentTabs-tab${selectedTab === Tabs.Profile ? " selected" : ""}`;
  const volunteersTabCname = `PageContentTabs-tab${
    selectedTab === Tabs.Volunteers ? " selected" : ""
  }`;
  const evaluationOneTabCname = `PageContentTabs-tab${
    selectedTab === Tabs.EvaluationOne ? " selected" : ""
  }`;
  const evaluationTwoTabCname = `PageContentTabs-tab${
    selectedTab === Tabs.EvaluationTwo ? " selected" : ""
  }`;

  const displayEvalTabsByOnboardState =
    onboard.state === OnboardState.InMutualAssessment ||
    onboard.state === OnboardState.InCommitteeReview;

  return (
    <>
      {/* TABS */}
      <div className="PageContentTabs">
        {onboard.state === OnboardState.AwaitingSeconders && (
          <div id={Tabs.Volunteers} className={volunteersTabCname} onClick={onTabClick}>
            Volunteers
          </div>
        )}

        {displayEvalTabsByOnboardState && firstCandidacy && (
          <div id={Tabs.EvaluationOne} className={evaluationOneTabCname} onClick={onTabClick}>
            Evaluation 1
          </div>
        )}

        {displayEvalTabsByOnboardState && secondCandidacy && (
          <div id={Tabs.EvaluationTwo} className={evaluationTwoTabCname} onClick={onTabClick}>
            Evaluation 2
          </div>
        )}

        <div id={Tabs.Profile} className={profileTabCname} onClick={onTabClick}>
          Profile
        </div>
      </div>

      {/* CONTENT */}
      {selectedTab === Tabs.Volunteers && <SeconderVolunteersTab onboard={onboard} />}
      {selectedTab === Tabs.EvaluationOne && firstCandidacy && firstEval && (
        <SeconderEvaluationTab
          candidacy={firstCandidacy}
          assignedSecondersCount={assignedSecondersCount}
          mutualAssessment={firstEval}
          isAdminView
        />
      )}
      {selectedTab === Tabs.EvaluationTwo && secondCandidacy && secondEval && (
        <SeconderEvaluationTab
          candidacy={secondCandidacy}
          assignedSecondersCount={assignedSecondersCount}
          mutualAssessment={secondEval}
          isAdminView
        />
      )}

      {selectedTab === Tabs.Profile && (
        <ExpertDetailsProfileTab onboard={onboard} showRightColumn={false} />
      )}
    </>
  );
};
