import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation AcknowledgeBid($input: AcknowledgeWinningTicketBidInput!) {
    payload: acknowledgeWinningTicketBid(input: $input) {
      bid {
        id
        status
        state
        ticket {
          id
          state
          status
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: AcknowledgeWinningTicketBidInput;
}

interface AcknowledgeWinningTicketBidInput {
  readonly bidId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: AcknowledgeWinningTicketBidPayload;
}

interface AcknowledgeWinningTicketBidPayload {
  readonly bid: Identifiable;
}
