import { gql } from "@apollo/client";

export const EXTSESSION_FRAGMENT = gql`
  fragment ExtSession on ExtSession {
    id
    provider
    token
    uid
  }
`;
