import { FormDocument, EvaluationScore } from "../_types";

export interface ReviewCompletionSchema {
  readonly deliverySuccess: boolean;
  readonly additionalInformation: string | null;
  readonly additionalDocuments: FormDocument[] | null;
}

export const readableReviewCompletionAnswer = (v: boolean, expertName: string): string => {
  if (v) {
    return `${expertName} has successfully delivered this ticket`;
  } else {
    return `${expertName} has not fulfilled the requirements for completion of this ticket`;
  }
};

export interface EvaluateCompletionSchema {
  readonly deliverablesQuality: EvaluationScore;
  readonly functionalRequirements: EvaluationScore;
  readonly nonFunctionalRequirements: EvaluationScore;
  readonly agreedTimelines: EvaluationScore;
  readonly deliverablesAdditionalInfo: string | null;
  readonly deliverablesAdditionalDocuments: FormDocument[] | null;
  readonly communication: EvaluationScore;
  readonly responsiveness: EvaluationScore;
  readonly customerCentricity: EvaluationScore;
  readonly problemSolving: EvaluationScore;
  readonly expertAdditionalInfo: string | null;
  readonly expertAdditionalDocuments: FormDocument[] | null;
}
