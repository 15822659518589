import { CSSProperties, useMemo, useState } from "react";
import { ethers } from "ethersv5";
import { useApolloClient } from "@apollo/client";
import { Label, Image, Icon, Popup } from "semantic-ui-react";
import { perform } from "../../api/Roles";
import { Maybe } from "../../types";
import { Role } from "../../types/role";
import logo from "../../assets/logo.svg";
import { RoleIcon } from "../../types/badges";

const labelStyle = { display: "inline-flex" };
const contentStyle: CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "80px",
};
const roleIconStyle: CSSProperties = {
  display: "inline-block",
  marginRight: "10px",
  width: "24px",
  height: "24px",
};

interface Props {
  readonly ethAddress: string;
}

interface State {
  readonly busy: boolean;
  readonly role: Maybe<Role>;
}
const initialState: State = { busy: false, role: null };

export const ReverseAddress = ({ ethAddress }: Props) => {
  const client = useApolloClient();
  const [state, setState] = useState(initialState);

  useMemo(() => {
    if (ethAddress === ethers.constants.AddressZero) {
      return null;
    }
    setState((s) => ({ ...s, busy: true }));

    perform(client, { first: 1, ethAddress })
      .then((res) => {
        const roles = res.data.roles;
        if (!roles || !roles.edges || roles.edges.length === 0) {
          return Promise.reject();
        }
        const role = roles.edges[0].node;
        setState((s) => ({ ...s, busy: false, role }));
      })
      .catch(() => setState((s) => ({ ...s, busy: false, role: null })));
  }, [client, ethAddress]);

  return render(state, ethAddress);
};

const render = ({ busy, role }: State, ethAddress: string) => {
  const ethStringAddress = ethAddress;
  let content: string;
  let pictureUrl: string | undefined;

  if (busy) {
    content = ethAddress.slice(0, 10);
  } else {
    if (role) {
      pictureUrl = role.avatarUrl;
      content = role.fullName || "Unknown";
    } else {
      content = ethStringAddress;
    }
  }

  const copyToClipboard = () => navigator.clipboard.writeText(ethStringAddress);
  const contentPopupText = (
    <label>
      {role && <RoleIcon type={role.type} kind={role.kind} style={roleIconStyle} />}
      {content}
    </label>
  );

  return (
    <Label image style={labelStyle}>
      <Image src={pictureUrl || logo} alt="Role icon" size="small" />
      <Popup trigger={<div style={contentStyle}>{content}</div>} content={contentPopupText} />
      <Popup
        trigger={
          <Label.Detail as="a" onClick={copyToClipboard}>
            <Icon name="clipboard" />
          </Label.Detail>
        }
        content="Copy address to clipboard."
      />
    </Label>
  );
};
