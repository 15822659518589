import { useCallback, useMemo, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Image } from "semantic-ui-react";
import { PublicOnboardViewedByMember } from "../../../types/onboard";
import { isIndividualOnboard, OnboardState } from "../../../types/onboard";
import { MutualAssessmentsState } from "../../../types/onboard";
import dateFormat from "dateformat";
import ribbonIcon from "../../../assets/purple-ribbon.svg";
import { countryMapping } from "../../../utils/CountriesList";
import { TextClamp } from "../../elements/TextClamp";
import { useCategoriesState } from "../../../contexts/Categories";
import { isSuccessState } from "../../../contexts/Generic";
import { Shortcuts as S } from "../../../routing";
import { OnboardMigrator } from "../../../migrations/Onboards";
import { useSettingsState } from "../../../contexts/Settings";
import { RoleIcon } from "../../../types/badges";
import { Migrated } from "../../../migrations/_helpers";
import { requireEnrolled, useSessionState } from "../../../contexts/Session";
import { isDefined } from "../../../types";

interface Props {
  readonly id?: string;
  readonly onboard: PublicOnboardViewedByMember;
}

export const ExpertOnboardCard = ({ onboard, id }: Props) => {
  const categoriesState = useCategoriesState();

  const search = new URLSearchParams({ [S.expert.queryVarNames.id]: onboard.id }).toString();
  const linksTo = `${S.expert.path}?${search}`;

  const { fullName, submittedAt, state, type, kind } = onboard;

  const isAwaitingSeconder = state === OnboardState.AwaitingSeconders;
  const isMutualAssessment = state === OnboardState.InMutualAssessment;
  const isCBCVoting = state === OnboardState.InCommitteeReview;

  const isIndividual = isIndividualOnboard(onboard);

  const migratedOnboard = useMemo(() => {
    const { migrate } = new OnboardMigrator(onboard);
    return migrate();
  }, [onboard]);

  const { migratedForm } = useMemo(() => migratedOnboard, [migratedOnboard]);

  const labelTitle = useMemo(() => {
    if (isAwaitingSeconder) {
      return "awaiting seconders";
    }
    if (isMutualAssessment) {
      return "In mutual Assessment";
    }
    if (isCBCVoting) {
      return "CBC Voting";
    }
  }, [isAwaitingSeconder, isMutualAssessment, isCBCVoting]);

  const cardClassName = useMemo(() => {
    if (isAwaitingSeconder) {
      return " Awaiting-seconders";
    } else if (isMutualAssessment) {
      return " Mutual-assessment";
    } else if (isCBCVoting) {
      return " Committee-review";
    } else {
      return "";
    }
  }, [isAwaitingSeconder, isMutualAssessment, isCBCVoting]);

  const handleLinkedinClick = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      window.open(migratedForm?.data.linkedIn, "_blank", "noopener noreferrer");
    },
    [migratedForm]
  );

  if (!isSuccessState(categoriesState) || !migratedForm || !migratedForm.data) {
    return null;
  }

  // TODO: Needs migration
  const { experienceGeography, linkedIn } = migratedForm.data;

  return (
    <div className={`ExpertOnboardCard${isCBCVoting ? " Committee-review" : ""}`} id={id}>
      <Link to={linksTo}>
        <div className={`ExpertOnboardCard-label${cardClassName}`}>{labelTitle}</div>
        <div className="ExpertOnboardCard-header">
          <RoleIcon type={type} kind={kind} circular />
          <div className="ExpertOnboardCard-header-title">
            <b>
              {fullName}
              <br />
            </b>
            {isIndividual ? "Individual" : "Organization"}
            <div className="ExpertOnboardCard-header-title-sub">
              Date submitted: {dateFormat(submittedAt, "dd/mm/yy")}
              <br />
              <TextClamp
                text={`Geography: ${experienceGeography?.map((c) => countryMapping(c)).join(", ")}`}
              />
            </div>
            {linkedIn && (
              <div className="ExpertOnboardCard-link" onClick={handleLinkedinClick}>
                Linkedin
              </div>
            )}
          </div>
        </div>
        <ExpertOnboardCardBody onboard={migratedOnboard} />
      </Link>
    </div>
  );
};

interface CardBodyProps {
  readonly onboard: Migrated<PublicOnboardViewedByMember>;
}

const ExpertOnboardCardBody = ({ onboard }: CardBodyProps) => {
  const settingsState = useSettingsState();
  const categoriesState = useCategoriesState();
  const sessionState = useSessionState();
  const { roleId } = requireEnrolled(sessionState);
  const { migratedForm, extraFields, state } = onboard;
  const { seconderCandidacy, assignedSeconderCandidacies, mutualAssessments } = extraFields;
  const { mutualAssessment } = extraFields;

  const isAssigned = isDefined(
    assignedSeconderCandidacies?.find(({ seconder }) => seconder.id === roleId)
  );

  const isAwaitingSeconder = state === OnboardState.AwaitingSeconders;
  const isMutualAssessment = state === OnboardState.InMutualAssessment;

  const requiredSeconderCount = useMemo(() => {
    if (!isSuccessState(settingsState)) {
      return 2;
    }

    return settingsState.result.core.required_seconder_count;
  }, [settingsState]);

  if (!isSuccessState(categoriesState) || !migratedForm || !migratedForm.data) {
    return null;
  }

  const secondersMissing = requiredSeconderCount - (assignedSeconderCandidacies?.length || 0);

  const { sectors, topSkills, motivation } = migratedForm.data;
  const { sectorsMapping, skillsMapping } = categoriesState.result;

  if (isAwaitingSeconder) {
    return (
      <div className="ExpertOnboardCard-body">
        {sectors && (
          <div className="ExpertOnboardCard-body-list">
            <b> Sector:</b>
            <TextClamp text={sectors.map((s) => sectorsMapping[s]).join(", ")} />
            <br />
          </div>
        )}
        {topSkills && (
          <div className="ExpertOnboardCard-body-list">
            <b>Skills: </b>
            <TextClamp text={topSkills.map((s) => skillsMapping[s]).join(", ")} />
            <br />
          </div>
        )}
        <div className="ExpertOnboardCard-body-description">
          {motivation && <TextClamp text={motivation} maxLines={4} />}
        </div>
        <br />
        {!seconderCandidacy && <Button color="purple">Volunteer</Button>}
        {seconderCandidacy && (
          <div className="ExpertOnboardCard-body-volunter">
            {!seconderCandidacy && (
              <div>
                <Button color="purple">Volunteer</Button>
                <span className="ExpertOnboardCard-body-volunter-missing">
                  {secondersMissing} {secondersMissing > 1 ? "Seconders" : "Seconder"} missing
                </span>
              </div>
            )}
            {!isAssigned && seconderCandidacy && (
              <>
                <span className="ExpertOnboardCard-body-volunter-label">
                  Volunteered <Icon name="thumbs up" />
                </span>
                <span className="ExpertOnboardCard-body-volunter-label-sub">
                  Awaiting Assignment
                </span>
              </>
            )}
            {isAssigned && (
              <>
                <span className="ExpertOnboardCard-body-volunter-label">
                  Seconder <Image src={ribbonIcon} />
                </span>
                <span className="ExpertOnboardCard-body-volunter-label-sub">
                  {secondersMissing} {secondersMissing > 1 ? "Seconders" : "Seconder"} missing
                </span>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
  if (isMutualAssessment) {
    return (
      <div className="ExpertOnboardCard-body">
        <div className="ExpertOnboardCard-body-evaluation-label">In evaluation by</div>

        {mutualAssessments?.map(({ seconder, state: maState }) => {
          const { fullName, id, avatarUrl } = seconder;
          return (
            <div className="ExpertOnboardCard-body-evaluation-seconder-row" key={id}>
              <Image src={avatarUrl} />
              <span className="ExpertOnboardCard-body-evaluation-seconder-row-user">
                <TextClamp text={fullName} />
              </span>
              {id === roleId && <span>(YOU)</span>}
              {maState === MutualAssessmentsState.Started && (
                <span className="ExpertOnboardCard-body-evaluation-seconder-row-await-label">
                  Waiting for evaluation
                </span>
              )}
              {maState === MutualAssessmentsState.Completed && (
                <span className="ExpertOnboardCard-body-evaluation-seconder-row-evaluated-label">
                  Evaluated!
                </span>
              )}
            </div>
          );
        })}
        {mutualAssessment?.state === MutualAssessmentsState.Started && (
          <Button color="purple">Evaluate</Button>
        )}
        {mutualAssessment?.state === MutualAssessmentsState.Completed && (
          <div className="ExpertOnboardCard-body-volunter-label-sub">Under Evaluation</div>
        )}
      </div>
    );
  }

  return null;
};
