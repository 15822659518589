import { useMemo } from "react";
import { Grid, GridColumn } from "semantic-ui-react";
import { SentStartupOnboard } from "./_types";
import { StartupInviteMigrator } from "../../../migrations/invites/StartupInvite";

interface Props {
  readonly onboard: SentStartupOnboard;
}

export const StartupInviteDetails = ({ onboard }: Props) => {
  const formData = useMemo(() => {
    const { migrate } = new StartupInviteMigrator(onboard);
    return migrate().migratedForm?.data || {};
  }, [onboard]);
  const { invitationReason, name, recipientEmail } = formData;

  return (
    <Grid>
      <Grid.Row>
        <GridColumn mobile={16} tablet={16} computer={12}>
          <div className="WrapperSection InviteDetails">
            <div className="InviteDetails-title">Invitation Details</div>
            <div className="InviteDetails-sub-title">Detail</div>
            <span>Startup</span>
            <br />
            {name && <span>{name}</span>}
            <br />
            {recipientEmail && <span>{recipientEmail}</span>}

            {invitationReason && (
              <>
                <div className="InviteDetails-separator" />
                <div className="InviteDetails-sub-title">Reason for invite</div>
                <span>{invitationReason}</span>
              </>
            )}
          </div>
        </GridColumn>
      </Grid.Row>
    </Grid>
  );
};
