import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { valuesOfNumericEnum } from "../../../types";
import { utils } from "../../../utils/utils";
import { EvaluationAnswer, EvaluationSchema } from "./_types";

export const schema: JSONSchemaType<EvaluationSchema> = {
  $id: "https://schemas.consilienceventures.com/bids/evaluation.v1.schema.json",
  title: "Submit a bid",
  type: "object",
  properties: {
    preparation: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationAnswer),
    },
    functionalRequirements: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationAnswer),
    },
    nonFunctionalRequirements: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationAnswer),
    },
    pricingProposal: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationAnswer),
    },
    interactionWithBidder: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationAnswer),
    },
    responseStructure: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationAnswer),
    },
    bidderCommunication: {
      type: "integer",
      enum: valuesOfNumericEnum(EvaluationAnswer),
    },
  },
  required: [
    "preparation",
    "functionalRequirements",
    "nonFunctionalRequirements",
    "pricingProposal",
    "interactionWithBidder",
    "responseStructure",
    "bidderCommunication",
  ],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
