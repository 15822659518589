import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../../utils/utils";
import { OnfidoCountries, USAStates } from "../utils/OnfidoCountries";
import { POASchema } from "../utils/_types";

export const schema: JSONSchemaType<POASchema> = {
  type: "object",
  properties: {
    street: {
      type: "string",
      maxLength: 100,
      pattern: "(.|s)*\\S(.|s)*",
    },
    town: {
      type: "string",
      maxLength: 100,
      pattern: "(.|s)*\\S(.|s)*",
    },
    postcode: {
      type: "string",
      maxLength: 100,
      pattern: "(.|s)*\\S(.|s)*",
    },
    country: {
      type: "string",
      enum: Object.values(OnfidoCountries),
    },
    state: {
      type: "string",
      enum: Object.values(USAStates),
    },
    dob: {
      type: "string",
      format: "date",
      pattern: "(.|s)*\\S(.|s)*",
    },
  },
  required: ["street", "dob", "country", "postcode", "town"],

  allOf: [
    {
      if: {
        properties: {
          country: {
            type: "string",
            const: OnfidoCountries.UnitedStatesofAmerica,
          },
        },
      },
      then: {
        required: ["state"],
      },
    },
    {
      if: {
        properties: {
          country: {
            type: "string",
            const: OnfidoCountries.UnitedKingdom,
          },
        },
      },
      then: {
        properties: {
          street: {
            type: "string",
            maxLength: 32,
            pattern: "(.|s)*\\S(.|s)*",
          },
          postcode: {
            type: "string",
            maxLength: 12,
            pattern: "^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$",
          },
        },
      },
    },
  ],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
