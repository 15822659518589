import { Segment } from "semantic-ui-react";
import { ReceivedOnboard } from "../../../types/onboard";
import { ReceivedInviteCard } from "./ReceivedInviteCard";

interface Props {
  readonly onboards: ReadonlyArray<ReceivedOnboard>;
}

export const ReceivedInvites = ({ onboards }: Props) => {
  if (onboards && onboards.length <= 0) {
    return (
      <Segment>
        If you've received an invitation by email, you must claim the invite by clicking the url
        within the email. Once you do, the invitation will appear here.
      </Segment>
    );
  }
  return (
    <>
      {onboards.map((o) => (
        <ReceivedInviteCard onboard={o} key={o.id} />
      ))}
    </>
  );
};
