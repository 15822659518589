import { gql } from "@apollo/client";
import { MutualAssessment } from "../../types/onboard";

export const MUTATION = gql`
  mutation SubmitMAEvaluation($input: SubmitMaEvaluationInput!) {
    payload: submitMaEvaluation(input: $input) {
      mutualAssessment {
        id
        seconder {
          id
          fullName
          avatarUrl
        }
        form {
          id
          schemaVersion
          data
        }
      }
    }
  }
`;

interface SubmitMaEvaluationInput {
  readonly ethSignature: string;
  readonly form: string;
  readonly mutualAssessmentId: string;
  readonly transmittedAt: Date;
}
export interface Variables {
  readonly input: SubmitMaEvaluationInput;
}

export interface Result {
  readonly payload?: { readonly mutualAssessment: MutualAssessment };
}
