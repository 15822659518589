import { PropsWithChildren, useMemo } from "react";
import { useRef, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Container, Button } from "semantic-ui-react";
import { Actioner } from "./actioner";
import { Context } from "./Context";

export const ConfirmationModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory();
  const messageRef = useRef<string>();
  const hasChangesRef = useRef<boolean>();
  const promiseRef = useRef<() => Promise<unknown>>();

  const api = useMemo(() => {
    return new Actioner({ messageRef, hasChangesRef, promiseRef });
  }, [messageRef, hasChangesRef, promiseRef]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const locationToChange = useRef("");
  const discardRef = useRef(false);

  const value = useMemo(() => ({ api }), [api]);
  useEffect(() => {
    const unblock = history.block((location): any => {
      locationToChange.current = location.pathname;
      if (hasChangesRef.current && !discardRef.current) {
        setShowModal(true);
        return false;
      }
      discardRef.current = false;
      return true;
    });
    return () => {
      unblock();
    };
  }, [hasChangesRef, history]);

  const handleDiscard = useCallback(() => {
    setShowModal(false);
    api.setHasChanges(false);
    discardRef.current = true;
    history.push({ pathname: locationToChange.current });
    toast.success("Your changes were discarded.");
  }, [api, history]);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleContinue = useCallback(() => {
    if (!promiseRef.current) {
      return;
    }
    setLoading(true);
    promiseRef
      .current()
      .then(() => {
        api.setHasChanges(false);
        history.push({ pathname: locationToChange.current });
      })
      .finally(() => {
        setLoading(false);
        setShowModal(false);
      });
  }, [promiseRef, history, api]);

  return (
    <Context.Provider value={value}>
      <Modal
        className="CustomClose"
        open={showModal}
        closeIcon
        onClose={handleClose}
        size="tiny"
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          {messageRef.current}
          <br />
          <br />
          <Container>
            <Button onClick={handleDiscard}>Discard</Button>
            <Button
              primary
              onClick={handleContinue}
              floated="right"
              content="Save"
              loading={loading}
            />
          </Container>
        </Modal.Content>
      </Modal>
      {children}
    </Context.Provider>
  );
};
