import { useCallback, useEffect, useRef, useState, lazy, Suspense } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { utils } from "../../../utils/utils";
import { MUTATION, Result, Variables } from "../../../api/onboards/RequestOnfidoSDKToken";
import { QUERY } from "../../../api/onboards/Onboard";
import { ApiError, extractErrorMessages } from "../../../types";
import { ReceivedOnboard } from "../../../types/onboard";
import { Migrated } from "../../../migrations/_helpers";
import { AddressForm } from "./AddressForm";
import { Grid } from "semantic-ui-react";
import { POASchema } from "../utils/_types";
import { LoaderWithMargin } from "../../../components/Loader";

const OnfidoForm = lazy(() => import("./OnfidoForm"));

const MAX_RETRIES = 5;
const RETRY_TIMMER = 3500;

export interface Props {
  readonly onboard: Migrated<ReceivedOnboard>;
}

export const KYCForm = ({ onboard }: Props) => {
  const [refreshOnboard] = useLazyQuery(QUERY);
  const [requestOnfidoToken, { called }] = useMutation<Result, Variables>(MUTATION);
  // needed because of reusing the same mutation, the UI gets messed up
  const [loading, setLoading] = useState(false);

  const tokenTimeoutCount = useRef(0);
  const [onfidoToken, setOnfidoToken] = useState<string | undefined>();

  const hasSubmittedPOA = !!onboard.onfidoApplicantId;

  useEffect(() => {
    if (hasSubmittedPOA && !called) {
      initialiseOnfido();
    }
  });

  const initialiseOnfido = useCallback(
    (form?: POASchema) => {
      const jsonForm = form ? JSON.stringify(form) : undefined;
      setLoading(true);
      requestOnfidoToken({
        variables: {
          input: {
            onboardId: onboard.id,
            applicantDob: form?.dob,
            applicantAddress: jsonForm,
          },
        },
      })
        .then(({ data }) => {
          if (!data?.payload.result) {
            throw new Error("Missing Onfido result");
          }
          setOnfidoToken(data.payload.result);
          refreshOnboard({ variables: { onboardId: onboard.id } }).then(() => {
            setLoading(false);
          });
        })
        .catch((err: ApiError) => {
          if (!hasRefetchErrorMessage(err)) {
            setLoading(false);
            toast.error(extractErrorMessages(err).join(", "));
          }
          // Tries a few times to get the token from onfido.
          if (tokenTimeoutCount.current < MAX_RETRIES) {
            tokenTimeoutCount.current += 1;
            utils.timer(RETRY_TIMMER).then(() => initialiseOnfido(form));
          } else {
            setLoading(false);
            toast.error("We couldn't retrieve the onfido information. Please try again later.");
          }
        });
    },
    [onboard, requestOnfidoToken, refreshOnboard]
  );

  if (hasSubmittedPOA && !onfidoToken) {
    return <LoaderWithMargin />;
  }

  return (
    <Grid>
      <div className="KYCForm">
        {!hasSubmittedPOA && (
          <Grid.Column tablet={16} computer={12}>
            <AddressForm submitForm={initialiseOnfido} loading={loading} />
          </Grid.Column>
        )}
        {hasSubmittedPOA && onfidoToken && (
          <Suspense fallback={<LoaderWithMargin />}>
            <Grid.Column width={16}>
              <OnfidoForm onboard={onboard} token={onfidoToken} />
            </Grid.Column>
          </Suspense>
        )}
      </div>
    </Grid>
  );
};

const hasRefetchErrorMessage = (err: ApiError) =>
  extractErrorMessages(err).findIndex((e) => e.includes("re-fetch in 5 seconds")) > -1;
