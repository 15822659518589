import { Icon, IconProps } from "semantic-ui-react";
import { PreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";

export const UpvoteIcon = (props: IconProps) => (
  <Icon {...props} className="VoteIcon Upvote" name="thumbs up" />
);

export const NeutralIcon = (props: IconProps) => (
  <Icon {...props} className="VoteIcon Neutral" name="thumbs up" />
);

export const DownvoteIcon = (props: IconProps) => (
  <Icon {...props} className="VoteIcon Downvote" name="thumbs down" />
);

interface Props {
  readonly choice: PreselectionProposalChoice;
  readonly count: number;
  readonly size?: "big";
}
export const VoteIconWithCount = ({ choice, count, size }: Props) => {
  let type: string;
  let icon: JSX.Element;

  switch (choice) {
    case PreselectionProposalChoice.Yes:
      type = "Upvote";
      icon = <UpvoteIcon size={size} />;
      break;
    case PreselectionProposalChoice.Neutral:
      type = "Neutral";
      icon = <NeutralIcon size={size} />;
      break;
    case PreselectionProposalChoice.No:
      type = "Downvote";
      icon = <DownvoteIcon size={size} />;
      break;
  }

  return (
    <div className={`VoteIconWithCount ${type} ${size}`}>
      {icon}
      <div className={`VoteIconWithCount-count ${type}`}>{count}</div>
    </div>
  );
};
