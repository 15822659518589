import { assertUnreachable, isEnum } from "../../types";

export enum BusinessCases {
  ProminentSkills = "PROMINENT_SKILLS",
  CoInvestor = "CO_INVESTOR",
  Introducer = "INTRODUCER",
  EarlyProfessional = "EARLY_PROFESSIONAL",
}

export enum NumberOfInvestments {
  ZeroToFive = "ZERO_TO_FIVE",
  FiveToTen = "FIVE_TO_TEN",
  TenToTwenty = "TEN_TO_TWENTY",
  OverTwenty = "OVER_TWENTY",
}

export enum FundSize {
  LessThanFiftyK = "LESS_THAN_FIFTY_K",
  FiftyToTwoHundredK = "FIFTY_TO_TWO_HUNDRED_K",
  TwoHundredKToOneM = "TWO_HUNDRED_K_TO_ONE_M",
  OneToTenM = "ONE_TO_TEN_M",
  OverTenM = "OVER_TEN_M",
}

export const readableExpectedToParticipate = (v: boolean): string => {
  if (v) {
    return "Yes";
  } else {
    return "No";
  }
};

export const readableBusinessCase = (v: unknown): string => {
  if (!isEnum(v, BusinessCases)) {
    return "Unknown";
  }

  switch (v) {
    case BusinessCases.CoInvestor:
      return "Co-Investor";
    case BusinessCases.EarlyProfessional:
      return "Early Professional";
    case BusinessCases.Introducer:
      return "Introducer";
    case BusinessCases.ProminentSkills:
      return "Professional";
  }
  assertUnreachable(v);
};

export const readableBusinessCasesLong = (v: unknown): string => {
  if (!isEnum(v, BusinessCases)) {
    return "Unknown";
  }

  switch (v) {
    case BusinessCases.CoInvestor:
      return "They may be interested in co-investing with CV.";
    case BusinessCases.EarlyProfessional:
      return "They are an early professional.";
    case BusinessCases.Introducer:
      return "They can introduce the ecosystem to individuals or organisations that are key for CV's growth.";
    case BusinessCases.ProminentSkills:
      return "They bring unique skills, experience and knowledge that can benefit our portfolio companies.";
  }
  assertUnreachable(v);
};

export const readableNumberOfInvestments = (v: NumberOfInvestments): string => {
  switch (v) {
    case NumberOfInvestments.ZeroToFive:
      return "0 - 5";
    case NumberOfInvestments.FiveToTen:
      return "5 - 10";
    case NumberOfInvestments.TenToTwenty:
      return "10 - 20";
    case NumberOfInvestments.OverTwenty:
      return "over 20";
  }
  assertUnreachable(v);
};

export const readableFundSize = (v: FundSize): string => {
  switch (v) {
    case FundSize.LessThanFiftyK:
      return "<£50K";
    case FundSize.FiftyToTwoHundredK:
      return "£50K - £200k";
    case FundSize.TwoHundredKToOneM:
      return "£200k - £1m";
    case FundSize.OneToTenM:
      return "£1m - £10m";
    case FundSize.OverTenM:
      return "> £10m";
  }
  assertUnreachable(v);
};
