import { gql } from "@apollo/client";
import { PAGEINFO_FRAGMENT } from "./PageInfo";
import { PUBLIC_ONBOARD_FRAGMENT } from "./Onboard";

export const PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT = gql`
  fragment PublicOnboardViewedByMember on Onboard {
    ...PublicOnboard
    proposal(kind: "preselection") {
      hasVoted(roleId: $roleId)
    }
    committeeProposal: proposal(kind: "committee_review") {
      id
      hasVoted(roleId: $roleId)
    }
    extraFields {
      ... on Expert {
        seconderCandidacy(seconderId: $roleId) {
          id
          insertedAt
          requestedTraining
        }
        assignedSeconderCandidacies: seconderCandidacies(state: ASSIGNED) {
          id
          seconder {
            id
            fullName
            avatarUrl
          }
        }
        mutualAssessment(seconderId: $roleId) {
          id
          state
          transmittedAt
          form {
            id
            data
            schemaVersion
          }
          seconder {
            id
            fullName
            avatarUrl
          }
        }
        mutualAssessments {
          id
          state
          seconder {
            id
            fullName
            avatarUrl
          }
        }
      }
    }
  }
  ${PUBLIC_ONBOARD_FRAGMENT}
`;

export const PUBLIC_ONBOARD_VIEWED_BY_MEMBER_CONNECTION_FRAGMENT = gql`
  fragment PublicOnboardViewedByMemberConnection on OnboardConnection {
    edges {
      cursor
      node {
        ...PublicOnboardViewedByMember
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;
