import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { BidCard } from "./bid/BidCard";
import { QUERY, Variables, Result } from "../../../api/tickets/TicketWithBids";
import { ErrorMessages } from "../ErrorMessages";
import { extractErrorMessages, noResultErrorFor } from "../../../types";
import { nodesFromEdges } from "../../../types/relay";
import { TicketBidState } from "../../../types/bid";
import { LoaderWithMargin } from "../../Loader";

const first = 20;

interface Props {
  readonly ticketId: string;
  readonly title?: string;
  readonly noResultsMessage?: string;
  readonly idToHide?: string;
}
export const BidsSection = ({ ticketId, title, idToHide, noResultsMessage }: Props) => {
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { ticketId, first, state: [TicketBidState.Submitted, TicketBidState.Closed] },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const { bids, ticketForm, owner } = useMemo(() => {
    return {
      bids: nodesFromEdges(data?.ticket?.bidsConnection?.edges).filter(b => b.id !== idToHide),
      ticketForm: data?.ticket?.ticketForm,
      owner: data?.ticket?.owner,
    };
  }, [data, idToHide]);

  if (loading && !data) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !owner || !ticketForm) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Ticket bids"))} />;
  }

  return (
    <div className="WrapperSection">
      {title && <div className="ComponentHeader">{title}</div>}
      {bids.length === 0 && <div>{noResultsMessage || "No results found."}</div>}
      {bids.map(b => (
        <BidCard key={b.id} bid={b} ticketForm={ticketForm} ticketOwner={owner} />
      ))}
    </div>
  );
};
