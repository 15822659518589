import { Identifiable } from "../types/common";
import { PreselectionProposalChoice } from "../schemas/preselectionVote/_types";
import { CBCExpertVoteChoice } from "../schemas/cbc/_types";

export interface Proposal<C> extends Identifiable {
  readonly votableId: Identifiable;
  readonly choices: ReadonlyArray<C>;
  readonly kind: string;
  readonly openUntil: string;
  readonly question: string;
  readonly sheepProtected: boolean;
  readonly tally?: ReadonlyArray<ProposalTallyItem<C>>;
}

export interface ProposalWithHasVoted<C> extends Proposal<C> {
  readonly hasVoted?: boolean;
}

export interface ProposalTallyItem<C> {
  readonly choice: C;
  readonly count: number;
}

export enum KnownProposalKinds {
  Preselection = "preselection",
  CommitteeReview = "committee_review",
}

const countChoice = <C>(choice: C, tallyItems?: ReadonlyArray<ProposalTallyItem<C>>): number =>
  tallyItems?.find(({ choice: c }) => c === choice)?.count || 0;

export const preselectionVotesCountByChoice = (
  tally?: ReadonlyArray<ProposalTallyItem<PreselectionProposalChoice>>
) => {
  const upvotesCount = countChoice(PreselectionProposalChoice.Yes, tally);
  const neutralsCount = countChoice(PreselectionProposalChoice.Neutral, tally);
  const downvotesCount = countChoice(PreselectionProposalChoice.No, tally);

  return { upvotesCount, neutralsCount, downvotesCount };
};

export const cbcMemberVotesCountByChoice = (
  tally?: ReadonlyArray<ProposalTallyItem<CBCExpertVoteChoice>>
) => {
  const upvotesCount = countChoice(CBCExpertVoteChoice.Yes, tally);
  const neutralsCount = countChoice(CBCExpertVoteChoice.Abstain, tally);
  const forDiscussionCount = countChoice(CBCExpertVoteChoice.ForDiscussion, tally);
  const downvotesCount = countChoice(CBCExpertVoteChoice.No, tally);

  return { upvotesCount, neutralsCount, downvotesCount, forDiscussionCount };
};
