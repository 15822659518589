import { Typed } from "../../types/common";
import { ActionTypes, Action } from "./actions";
import { Enrollment, EnrollmentError } from "../../types";
import { SetFlagsRequest } from "../../contracts/generated/Access";
import { EntityKind } from "../../types/onboard";
import { SessionUser } from "../../types/frontend_only/sessionUser";
import { selectedRole } from "./helpers";

// Some constants to identify stuff in localstorage.
export enum LS_KEYS {
  Version = "version",
  Token = "sessionToken",
  User = "user",
  CvRoleId = "cvRoleId",
  WelcomeUser = "welcomeUser",
  LeftMenu = "leftmenu",
}
export const LS_VERSION = "3.0";

export enum LeftMenuStates {
  Open = "OPEN",
  Closed = "CLOSED",
}

// State types.
export enum StateTypes {
  None = "$session/0/None",
  // LinkedinSessionId = "$session/1/LinkedinSessionId",
  Token = "$session/2/Token",
  Present = "$session/3/Present",
  Enrolled = "$session/4/Enrolled",
}
// Possible states.
export interface Base<T> extends Typed<T> {
  readonly loggingOut?: boolean;
  readonly chainLastCheckedAt?: string;
  readonly needsHydration: boolean;
}
export interface None extends Base<StateTypes.None> {}
// export interface LinkedinSessionId extends Base<StateTypes.LinkedinSessionId> {
//   readonly linkedinSessionId: string;
// }
export interface Token extends Base<StateTypes.Token> {
  readonly token: string;
}

interface BasicSessionData {
  readonly token: string;
  readonly user: SessionUser;
  readonly fishy: boolean;
}

export interface Present extends Base<StateTypes.Present>, BasicSessionData {
  readonly enrolling?: string;
  readonly enrolmentError?: EnrollmentError;
}

export interface Enrolled extends Base<StateTypes.Enrolled>, BasicSessionData, Enrollment {}

// Our state union, visible to everyone.
export type State = None | Token | Present | Enrolled; // | LinkedinSessionId;
export type AtLeastPresent = Present | Enrolled;

// Reducer
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // Dispatched when logging out or clearing the session after an error
    // and marks the state for cleanup.
    case ActionTypes.Clear: {
      clearLocalStorage();
      return { type: StateTypes.None, loggingOut: true, needsHydration: false };
    }
    // Dispatched once logging out finishes.
    case ActionTypes.SetCleanupDone: {
      const { loggingOut, ...newState } = state;
      return newState;
    }
    // When a linkedin session id is obtained, this action handles what to do with it.
    // case ActionTypes.SetLinkedinSessionId: {
    //   const { linkedinSessionId } = action;
    //   return { type: StateTypes.LinkedinSessionId, linkedinSessionId };
    // }
    // When a session token is obtained, this action handles what to do with it.
    case ActionTypes.SetToken: {
      const { token } = action;
      setLocalStorageItem(LS_KEYS.Token, token);
      return { type: StateTypes.Token, token, needsHydration: true };
    }
    // When a user is to be set in the current session, this action occurs.
    case ActionTypes.SetPresent: {
      const { user, fishy } = action;
      if (isToken(state) || isAtLeastPresent(state)) {
        const { token, needsHydration } = state;
        if (!fishy) {
          setLocalStorageItem(LS_KEYS.User, JSON.stringify(user));
        }
        // Build the state object.
        return presentStateBuilder(user, token, fishy, needsHydration);
      }
      break;
    }
    // Dispatched when Metamask selects a different account.
    case ActionTypes.Enrol: {
      if (isPresent(state)) {
        const { roleId } = action;
        return state.enrolling === roleId ? state : { ...state, enrolling: action.roleId };
      }
      break;
    }
    // Once a matching role is found and all role data is hydrated, this is called.
    case ActionTypes.SetEnrolled: {
      if (isPresent(state)) {
        const { roleId, chainId, roleChainData } = action;
        setLocalStorageItem(LS_KEYS.CvRoleId, roleId);
        return enrolledStateBuilder(state, roleId, chainId, roleChainData, false);
      }
      break;
    }
    case ActionTypes.UpdateUser: {
      const { user } = action;
      const fishy = false;
      const needsHydration = false;

      if (isAtLeastPresent(state)) {
        return { ...state, fishy, needsHydration, user: { ...state.user, ...user } };
      } else {
        const type = ActionTypes.SetPresent;
        return reducer(state, { type, fishy, needsHydration, user });
      }
    }
    // If we need to clear the enrolment state, this action is dispatched, with an optional error.
    case ActionTypes.ClearEnrolment: {
      if (isAtLeastPresent(state)) {
        const { enrolmentError, chainLastCheckedAt } = action;
        const { user, fishy } = state;
        const type = ActionTypes.SetPresent;
        const needsHydration = false;
        const newState = reducer(state, { type, user, fishy, needsHydration });
        if (isPresent(newState)) {
          localStorage.removeItem(LS_KEYS.CvRoleId);
          return enrolmentError ? { ...newState, enrolmentError, chainLastCheckedAt } : newState;
        }
      }
      break;
    }
    default: {
      console.warn("An unknown action was processed by the SessionContext reducer.", action);
      return state;
    }
  }

  console.error("Impossible state transition detected.", state, action);
  return state;
};

export const createInitialState = (): State => {
  const token = getLocalStorageItem(LS_KEYS.Token);

  if (!token) {
    return { type: StateTypes.None, needsHydration: false };
  }

  const lsUser = getLocalStorageItem(LS_KEYS.User);

  if (!lsUser) {
    return { type: StateTypes.Token, token, needsHydration: true };
  }

  const user: SessionUser | undefined = safeParseUser(lsUser);

  if (!user) {
    return { type: StateTypes.Token, token, needsHydration: true };
  }

  return presentStateBuilder(user, token, true, true);
};

// These are helper on exactly a state type.
export const isNone = (s: State): s is None => s.type === StateTypes.None;
// export const isLinkedinSessionId = (s: State): s is LinkedinSessionId =>
//   s.type === StateTypes.LinkedinSessionId;
export const isToken = (s: State): s is Token => s.type === StateTypes.Token;
export const isEnrolled = (s: State): s is Enrolled => s.type === StateTypes.Enrolled;
export const isPresent = (s: State): s is Present => s.type === StateTypes.Present;
// This gives a positive if the user isn't logged-in.
export const isMissing = (s: State): s is None | Token /* | LinkedinSessionId */ =>
  s.type < StateTypes.Present;
// This gives a positive when the state is at least present or more.
export const isAtLeastPresent = (s: State): s is AtLeastPresent => s.type >= StateTypes.Present;
// This tells whether or not the user in session s has been checked with the backend.
export const isFishy = (s: State): boolean => isAtLeastPresent(s) && s.fishy;
// This tells whether or not we need to re-hydrate the session user.
export const isHydrationRequired = (s: State): boolean => isToken(s) || isFishy(s);

export const hasAnyRole = (s: State): s is AtLeastPresent =>
  isAtLeastPresent(s) && s.user.roles.length > 0;

// Helpers on the type of selected role, if any.
export const isSelectedRoleOfType = (s: Enrolled, kind: EntityKind): boolean =>
  !!s.user.roles.find((r) => r.kind === kind);

export const isStartupRoleSelected = (s: Enrolled): boolean =>
  isSelectedRoleOfType(s, EntityKind.Startup);
export const isExpertRoleSelected = (s: Enrolled): boolean =>
  isSelectedRoleOfType(s, EntityKind.Provider);
export const isInvestorRoleSelected = (s: Enrolled): boolean =>
  selectedRole(s.user.roles, s.roleId).canInvest;

export const clearLocalStorage = () =>
  Object.values(LS_KEYS).forEach((k) => localStorage.removeItem(k));

export const hasValidLSVersion = (): boolean =>
  localStorage.getItem(LS_KEYS.Version) === LS_VERSION;

export const getLocalStorageItem = (key: LS_KEYS): null | string => {
  if (!hasValidLSVersion()) {
    clearLocalStorage();
    return null;
  }

  return localStorage.getItem(key);
};

export const setLocalStorageItem = (key: LS_KEYS, value: string) => {
  if (!hasValidLSVersion()) {
    clearLocalStorage();
  }

  localStorage.setItem(LS_KEYS.Version, LS_VERSION);
  localStorage.setItem(key, value);
};

const safeParseUser = (maybeUser: string): SessionUser | undefined => {
  let obj: any;

  try {
    obj = JSON.parse(maybeUser);
  } catch (error) {
    console.warn(error);
    return undefined;
  }

  if (!obj || typeof obj !== "object") {
    return undefined;
  }

  const requiredKeys: ReadonlyArray<keyof SessionUser> = [
    "isAdmin",
    "linkedEmails",
    "linkedinSession",
    "profile",
    "roles",
  ];

  const invalid = requiredKeys.findIndex((k) => !obj[k]);

  return invalid ? undefined : (obj as SessionUser);
};

const presentStateBuilder = (
  user: SessionUser,
  token: string,
  fishy: boolean,
  needsHydration: boolean
): Present => ({
  type: StateTypes.Present,
  user,
  token,
  fishy,
  needsHydration,
});

const enrolledStateBuilder = (
  { user, token, fishy }: Present,
  roleId: string,
  chainId: number,
  roleChainData: SetFlagsRequest,
  needsHydration: boolean
): Enrolled => ({
  ...presentStateBuilder(user, token, fishy, needsHydration),
  type: StateTypes.Enrolled,
  roleId,
  chainId,
  roleChainData,
});
