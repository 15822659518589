import { useCallback, useMemo } from "react";
import { useCategoriesState } from "../../contexts/Categories";
import { isSuccessState } from "../../contexts/Generic";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { NestedTagsField } from "../NestedTagsField";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";
import { bridge as StartupTagsSchema } from "./StartupTagsSchema";
import { LabelingOperation, NamespacedLabel } from "../../types/labelQuery";
import { TagBagScope, TagNamespace } from "../../types/labelQuery";
import { StartupTagsData } from "./_types";
import { generateMaturitiesOption } from "../onboards/forms/StartupProfileForm";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { ApiError, extractErrorMessages, noResultErrorFor } from "../../types";
import { useMutation } from "@apollo/client";
import { MUTATION as UpdateTagsMut, Result as UpdateTagsRes } from "../../api/tags/ApplyLabels";
import { Variables as UpdateTagsVars } from "../../api/tags/ApplyLabels";
import { Role } from "../../types/role";

interface Props {
  readonly maturity: string;
  readonly sectors: ReadonlyArray<string>;
  readonly role: Role;
}

export const StartupTagsForm = (props: Props) => {
  const { maturity, sectors, role } = props;
  const categoriesState = useCategoriesState();
  const [updateTags, { loading }] = useMutation<UpdateTagsRes, UpdateTagsVars>(UpdateTagsMut);

  const initialModel: StartupTagsData = useMemo(() => {
    return { maturity, sectors };
  }, [maturity, sectors]);

  const [maturitiesTree, sectorsTree] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null];
    }

    return [categoriesState.result.maturitiesTree, categoriesState.result.sectorsTree];
  }, [categoriesState]);

  const maturitiesOptions = useMemo(() => {
    return generateMaturitiesOption(maturitiesTree?.children);
  }, [maturitiesTree]);

  const onSubmit = useCallback(
    (model: StartupTagsData) => {
      const { maturity: mat, sectors: sect } = model;
      let labels: ReadonlyArray<NamespacedLabel> = [];
      labels = labels.concat(sect.map((label) => ({ ns: TagNamespace.Sectors, label })));
      labels = labels.concat([{ ns: TagNamespace.Maturities, label: mat }]);

      updateTags({
        variables: {
          input: {
            op: LabelingOperation.Set,
            taggableId: role.id,
            scope: TagBagScope.Default,
            labels,
          },
        },
      })
        .then(({ data }) => {
          if (!data || !data.payload || !data.payload.role) {
            return Promise.reject(noResultErrorFor("Tag Role"));
          }
          toast.success("Changes saved.");
        })
        .catch((err: ApiError) => toast.error(extractErrorMessages(err).join(", ")));
    },
    [role.id, updateTags]
  );

  return (
    <AutoForm schema={StartupTagsSchema} model={initialModel} onSubmit={onSubmit}>
      {maturitiesTree && (
        <CustomDropdownSelectionField
          name="maturity"
          label="How mature is your startup?"
          dropdownOptions={maturitiesOptions}
          placeholder="Select maturity level"
          errorMessage="This field is mandatory."
          showInlineError
        />
      )}
      <br />
      {sectorsTree && (
        <NestedTagsField
          name="sectors"
          label="In which sectors do you operate?"
          collapsedText="Sectors"
          placeholder="Search sectors"
          errorMessage="This field is mandatory."
          showInlineError
          options={sectorsTree}
          namespace={TagNamespace.Sectors}
        />
      )}
      <Button
        floated="right"
        content="Save Tags"
        color="blue"
        loading={loading}
        disabled={loading}
      />
      <br />
      <br />
    </AutoForm>
  );
};
