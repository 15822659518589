import { assertUnreachable, isDefined, isEnum } from "../../types";
import { EntityType, OnboardState } from "../../types/onboard";

export interface CBCExpertFiltersSchema {
  readonly fullName: string | null;
  readonly type: ReadonlyArray<EntityType> | null;
  readonly state: ReadonlyArray<OnboardState> | null;
}

export interface CBCExpertVoteSchema {
  readonly voteChoice: CBCExpertVoteChoice;
  readonly reasonForDiscussion: string | null;
}

export enum CBCExpertVoteChoice {
  Yes = "YES",
  No = "NO",
  ForDiscussion = "FOR_DISCUSSION",
  Abstain = "ABSTAIN",
}

export const sanitizeCBCExpertVoteData = (data: CBCExpertVoteSchema): CBCExpertVoteSchema => {
  if (
    data.voteChoice !== CBCExpertVoteChoice.ForDiscussion &&
    isDefined(data.reasonForDiscussion)
  ) {
    return { ...data, reasonForDiscussion: null };
  }
  return data;
};

export const readableCBCExpertVoteChoice = (v: unknown): string => {
  if (!isEnum(v, CBCExpertVoteChoice)) {
    return "Unknown";
  }

  switch (v) {
    case CBCExpertVoteChoice.Yes:
      return "Yes";
    case CBCExpertVoteChoice.No:
      return "No";
    case CBCExpertVoteChoice.ForDiscussion:
      return "For Discussion";
    case CBCExpertVoteChoice.Abstain:
      return "Abstain";
  }
  assertUnreachable(v);
};
