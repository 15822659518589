import dateFormat from "dateformat";
import { Grid } from "semantic-ui-react";
import { ExpertEvaluationSchemaLabels as QuestionLabels } from "../../../schemas/expertEvaluation/_types";
import { MutualAssessment } from "../../../types/onboard";
import { paragraphToHtml } from "../../../utils/applications/Formatting";

interface Props {
  readonly mutualAssessment: MutualAssessment;
  readonly isAdminView?: boolean;
}

export const ExpertOnboardEvaluationDetails = ({ mutualAssessment, isAdminView }: Props) => {
  return (
    <>
      {!isAdminView && (
        <Grid className="ExpertDetailsProfileTab">
          <Grid.Column computer={12} tablet={16}>
            <div className="WrapperSection">
              <div className="ExpertOnboardEvaluationDetails">
                <div className="ExpertOnboardEvaluationDetails-title">Evaluation form</div>
                <FormDetailsComponent mutualAssessment={mutualAssessment} />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      )}
      {isAdminView && (
        <div className="ExpertOnboardEvaluationDetails">
          <FormDetailsComponent mutualAssessment={mutualAssessment} />
        </div>
      )}
    </>
  );
};

interface DetailsProps {
  readonly mutualAssessment: MutualAssessment;
}

const FormDetailsComponent = ({ mutualAssessment }: DetailsProps) => {
  const { transmittedAt, form } = mutualAssessment;
  if (!form) {
    return null;
  }
  const { data } = form;
  const { expertScale, geographicSynergy, priceRange } = data;
  const { individualSkills, meetPortfolioNeeds, negativeStatements } = data;
  const { networkSynergies, personalOpinion, positiveStatements } = data;
  const { startupExperience, startupKnowledge, tokenEconomicsKnowledge } = data;
  const { additionalInfo, availableToParticipate, tacitKnowledge } = data;
  const { cvdsKnowledge, ecosystemBalance, expectedContribution } = data;
  return (
    <>
      <div className="ExpertOnboardEvaluationDetails-sub-title">
        Evaluation submitted on: {dateFormat(transmittedAt, "dd/mm/yy")}
        <div className="StatusCircle green" />
      </div>
      <br />
      {expectedContribution && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.expectedContribution}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            {paragraphToHtml(expectedContribution)}
          </div>
        </>
      )}
      <br />
      {availableToParticipate && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.availableToParticipate}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = No time to invest <br />5 = A few days a month with scope to increase as the
              platform grows in size
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">
              {availableToParticipate}
            </span>
          </div>
        </>
      )}
      <br />
      {networkSynergies && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.networkSynergies}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = They are a solo expert with few connections <br />5 = A super-connector
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{networkSynergies}</span>
          </div>
        </>
      )}
      <br />
      {meetPortfolioNeeds && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.meetPortfolioNeeds}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = They have experience in a very niche skill, and it's not clear what type/stage of
              startup might need this
              <br />
              5=They have deep expertise in a skill that is critical for nearly every startup
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{meetPortfolioNeeds}</span>
          </div>
        </>
      )}
      <br />
      {tacitKnowledge && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.tacitKnowledge}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = Their expertise is not particularly shareable or they don't seem willing to
              contribute
              <br />5 = They are already actively sharing knowledge within their networks and it is
              actionable
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{tacitKnowledge}</span>
          </div>
        </>
      )}
      <br />
      {startupExperience && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.startupExperience}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = They are not a referral and don't appear to be networked <br />5 = You 'knew' them
              before you met them OR they came highly recommended from several Members
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{startupExperience}</span>
          </div>
        </>
      )}
      <br />
      {ecosystemBalance && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.ecosystemBalance}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = An 'Advisor' or 'Doer' with very little actual experience with startups
              <br />5 = Someone who has significant experience being both an 'Advisor' and 'Doer'
              for startups
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{ecosystemBalance}</span>
          </div>
        </>
      )}
      <br />
      {priceRange && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">{QuestionLabels.priceRange}</div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = Unreasonably high prices <br />5 = Flexible and startup friendly pricing
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{priceRange}</span>
          </div>
        </>
      )}
      <br />
      {geographicSynergy && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.geographicSynergy}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = Limited to a region of a country <br />5 = Globally connected
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{geographicSynergy}</span>
          </div>
        </>
      )}
      <br />
      {startupKnowledge && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.startupKnowledge}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = Theoretical knowledge only OR no meaningful experience working with founders{" "}
              <br />5 = Extensive (years) of experience and demonstrated success working directly
              with startups
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{startupKnowledge}</span>
          </div>
        </>
      )}
      <br />
      {tokenEconomicsKnowledge && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.tokenEconomicsKnowledge}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = They are demonstrably afraid of / not interested in token-based compensation
              <br />5 = They ask informed questions
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">
              {tokenEconomicsKnowledge}
            </span>
          </div>
        </>
      )}
      <br />
      {cvdsKnowledge && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.cvdsKnowledge}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = They are demonstrably afraid of / not interested in token-based compensation{" "}
              <br />5 = They ask informed questions
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{cvdsKnowledge}</span>
          </div>
        </>
      )}
      <br />
      {expertScale && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.expertScale}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-label">
              1 = They do not appear legit; I have serious concerns about their experience,
              knowledge, and or capability <br />5 = They seem awesome, I'd be happy to work
              alongside them
            </div>
            <span className="ExpertOnboardEvaluationDetails-answer-rate">{expertScale}</span>
          </div>
        </>
      )}
      <br />
      {positiveStatements && positiveStatements.length > 0 && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.positiveStatements}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            {positiveStatements.map((p, idx) => {
              if (!p || p.length <= 0) {
                return null;
              }
              return (
                <li key={`pos-${idx}`}>
                  <span className="ExpertOnboardEvaluationDetails-answer-rate">{p}</span>
                </li>
              );
            })}
          </div>
        </>
      )}
      <br />
      {negativeStatements && negativeStatements.length > 0 && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.negativeStatements}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            <div className="ExpertOnboardEvaluationDetails-answer">
              {negativeStatements.map((n, idx) => {
                if (!n || n.length <= 0) {
                  return null;
                }
                return (
                  <li key={`neg-${idx}`}>
                    <span className="ExpertOnboardEvaluationDetails-answer-rate">{n}</span>
                  </li>
                );
              })}
            </div>
          </div>
        </>
      )}
      <br />
      {individualSkills && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.individualSkills}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            {paragraphToHtml(individualSkills)}
          </div>
        </>
      )}
      <br />
      {personalOpinion && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.personalOpinion}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            {paragraphToHtml(personalOpinion)}
          </div>
        </>
      )}
      <br />
      {additionalInfo && (
        <>
          <div className="ExpertOnboardEvaluationDetails-question">
            {QuestionLabels.additionalInfo}
          </div>
          <div className="ExpertOnboardEvaluationDetails-answer">
            {paragraphToHtml(additionalInfo)}
          </div>
        </>
      )}
    </>
  );
};
