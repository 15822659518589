import { useForm } from "uniforms";
import { ErrorObject } from "ajv";
import { TicketSummary } from "../../../components/elements/tickets/TicketSummary";
import { TicketForSummary } from "../../../components/elements/tickets/TicketSummary";
import { TicketSchema } from "../_types";
import { CreateTicketErrors } from "../TicketForm";
import { useMemo } from "react";

interface Props {
  readonly ticket: TicketForSummary;
}

export const TicketSummaryField = ({ ticket }: Props) => {
  const { model, error } = useForm<TicketSchema>();
  const details: ErrorObject[] | undefined = error?.details;
  let hasInsufficientFunds = false;

  details?.forEach((e) => {
    if (e?.message === CreateTicketErrors.Funds) {
      hasInsufficientFunds = true;
    }
  });

  // Updates the ticket with the latest model data.
  const ticketWithModel: TicketForSummary = useMemo(() => {
    return { ...ticket, draft: { ...ticket.draft, form: { ...ticket.draft?.form, data: model } } };
  }, [model, ticket]);

  return <TicketSummary ticket={ticketWithModel} hasInsufficientFunds={hasInsufficientFunds} />;
};
