import React, { CSSProperties, useCallback } from "react";
import { CheckboxProps, Radio } from "semantic-ui-react";
import classNames from "classnames";
import { connectField, HTMLFieldProps } from "uniforms";

interface Props extends HTMLFieldProps<boolean, HTMLDivElement> {}

const marginStyle: CSSProperties = {
  marginBottom: "8px",
};

export const CustomRadioToggleField = connectField((props: Props) => {
  const { onChange, className, disabled, error, required, checked, label, errorMessage } = props;

  const customOnChange = useCallback(
    (_: React.FormEvent<HTMLInputElement>, checkboxProps: CheckboxProps) =>
      onChange(checkboxProps.checked),
    [onChange]
  );

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label style={marginStyle}>{label}</label>}
      <Radio toggle checked={checked} onChange={customOnChange} />
      <div>{!!error && <div className="ui red basic pointing label">{errorMessage}</div>}</div>
    </div>
  );
});
