import { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION, GlobalMessages } from "../../api/_subscriptions/GlobalMessages";
import { Variables } from "../../api/_subscriptions/GlobalMessages";
import { AtLeastPresent, isEnrolled } from "../../contexts/Session/state";
import { UserSubscriptionListener } from "./UserSubscriptionListener";

interface Props {
  readonly sessionState: AtLeastPresent;
}

export const GlobalSubscriptionListener = ({ sessionState }: Props) => {
  const globalMessagesSub = useSubscription<GlobalMessages, Variables>(SUBSCRIPTION, {
    variables: {
      authorisation: {
        bearerToken: sessionState.token,
        roleId: isEnrolled(sessionState) ? sessionState.roleId : undefined,
      },
    },
  });

  useEffect(() => {
    const { data, loading, error } = globalMessagesSub;

    if (error) {
      return console.warn("GlobalMessages subscription error:", JSON.stringify(error));
    } else if (loading || !data) {
      return;
    }

    if (data.globalMessages.refresh) {
      return window.location.reload();
    }
  }, [globalMessagesSub]);

  return <UserSubscriptionListener sessionState={sessionState} />;
};
