import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Tab, TabProps } from "semantic-ui-react";
import { useSessionState, requireAtleastPresent } from "../../../../contexts/Session";
import { IndividualProfileForm } from "./IndividualProfileForm";
import { RoleSection } from "./RoleSection";
import { isEnrolled } from "../../../../contexts/Session/state";
import { TabPanes } from "../../../../types/semantic";
import { isEnum } from "../../../../types";

const menuProps = { secondary: true, pointing: true };

enum Tabs {
  Role,
  User,
}

export const Profile = () => {
  const history = useHistory();
  const sessionState = useSessionState();
  const { user } = requireAtleastPresent(sessionState);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Role);

  const panes: TabPanes = useMemo(() => {
    const p: TabPanes = [];

    if (isEnrolled(sessionState)) {
      p.push({
        menuItem: <Menu.Item key={Tabs.Role} content="Role" />,
        render: () => (
          <Tab.Pane>
            <RoleSection sessionState={sessionState} />
          </Tab.Pane>
        ),
      });
    }

    p.push({
      menuItem: <Menu.Item key={Tabs.User} content="User" />,
      render: () => (
        <Tab.Pane>
          <IndividualProfileForm
            subjectId={user.id}
            profile={user.profile}
            avatarURL={user.avatarUrl}
            isUserProfile={true}
          />
        </Tab.Pane>
      ),
    });

    return p;
  }, [sessionState, user]);

  const onTabChange = useCallback(
    (_: unknown, { activeIndex }: TabProps) => {
      if (!isEnum(activeIndex, Tabs)) {
        return history.replace({ search: undefined });
      } else if (activeIndex === activeTab) {
        return;
      }

      setActiveTab(activeIndex);
    },
    [history, activeTab]
  );

  return <Tab menu={menuProps} activeIndex={activeTab} panes={panes} onTabChange={onTabChange} />;
};
