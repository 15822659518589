import { useCallback, useMemo, useState } from "react";
import { Divider, Image, Popup } from "semantic-ui-react";
import { MenuEntry, MenuItem } from "./MenuItem";
import { Shortcuts as S } from "./../../../routing";
import { useSessionState } from "../../../contexts/Session";
import { isPresent, isEnrolled } from "../../../contexts/Session/state";
import { isAtLeastPresent, State as SessionState } from "../../../contexts/Session/state";
import { State as ChainState } from "../../../contexts/Chain/state";
import { isNone, isConnected, isAuthorized, ErrorType } from "../../../contexts/Chain/state";
import collapseIcon from "../../../assets/collapse-icon.svg";
import ticketsIcon from "../../../assets/tickets-icon.svg";
import resourcesIcon from "../../../assets/resources-icon.svg";
import walletIcon from "../../../assets/wallet-icon.svg";
import { FunctionTypes, isLeftMenuOpen } from "../../../contexts/Session/helpers";
import { roleHasFunction, selectedRole } from "../../../contexts/Session/helpers";
import { isProviderRole, isStartupRole } from "../../../types/role";
import cvLogo from "../../../assets/cv-logo-icon.svg";
import copyIcon from "../../../assets/copy-icon-grey.svg";
import { CreateInviteModal } from "../../CreateInviteModal";
import { RoleIcon } from "../../../types/badges";
import { readableTypeAndKind } from "../../../types/onboard";
import { assertUnreachable, EnrollmentErrorType } from "../../../types";
import { useChainState } from "../../../contexts/Chain";
import { MyAreaPopup } from "./MyAreaPopup";

const network: MenuEntry = {
  title: S.network.title,
  linksTo: S.network.path,
  iconName: "users",
};

const portfolio: MenuEntry = {
  title: "Portfolio",
  iconName: "briefcase",
  linksTo: S.portfolio.path,
};

const expertOnboarding: MenuEntry = {
  title: "Expert Onboarding",
  iconName: "cogs",
  linksTo: S.expertOnboarding.path,
};

const startupDealflow: MenuEntry = {
  title: "Startup Dealflow",
  iconName: "cogs",
  linksTo: S.startupDealflow.path,
};

const marketplace: MenuEntry = {
  title: "Marketplace",
  iconName: "shop",
  linksTo: S.marketplace.path,
};

const mySprint: MenuEntry = {
  title: "My Sprint",
  icon: ticketsIcon,
  linksTo: S.mySprint.path,
};
const myWork: MenuEntry = {
  title: "My Work",
  iconName: "dashboard",
  linksTo: S.myWork.path,
};

const communityResources: MenuEntry = {
  title: "Community Resources",
  icon: resourcesIcon,
  linksTo: S.communityResources.path,
};

const nomineeVoting: MenuEntry = {
  title: "Nominee Voting",
  iconName: "user",
  linksTo: S.nomineeVoting.path,
};

const administration: MenuEntry = {
  title: "Administration",
  iconName: "legal",
  linksTo: S.administration.path,
};

const buildWalletItem = (ethAddress: string): MenuEntry => {
  const search = new URLSearchParams({ [S.wallet.queryVarNames.address]: ethAddress }).toString();
  const linksTo = `${S.wallet.path}?${search}`;

  return { title: S.wallet.title, icon: walletIcon, linksTo };
};

// Component.
let initialActiveItem: string | null;
initialActiveItem = null;

interface Props {
  readonly onChevronClick: () => void;
}

export const LeftMenu = ({ onChevronClick }: Props) => {
  const chainState = useChainState();
  const sessionState = useSessionState();
  const [activeItem, setActiveItem] = useState(initialActiveItem);
  const isCBCMember =
    isAtLeastPresent(sessionState) && roleHasFunction(sessionState, FunctionTypes.CBCMember);
  const isAdmin = isAtLeastPresent(sessionState) && sessionState.user.isAdmin;
  const canInvite = isAtLeastPresent(sessionState) && sessionState.user.roles.length > 0;

  const isCollapsed = !isLeftMenuOpen();

  const { avatarUrl, fullName, role, roleDescription } = useMemo(() => {
    if (isEnrolled(sessionState)) {
      const sRole = selectedRole(sessionState.user.roles, sessionState.roleId);
      return {
        avatarUrl: sRole.avatarUrl,
        fullName: sRole.fullName,
        role: sRole,
        roleDescription: readableTypeAndKind(sRole.type, sRole.kind),
      };
    } else if (isPresent(sessionState)) {
      return { avatarUrl: sessionState.user.avatarUrl, fullName: sessionState.user.fullName };
    }

    return {};
  }, [sessionState]);

  const chainStatusFields = useMemo(() => {
    return getChainStatusFields(sessionState, chainState);
  }, [sessionState, chainState]);

  const walletItem = useMemo(() => {
    if (!isEnrolled(sessionState)) {
      return { title: S.wallet.title, icon: walletIcon, linksTo: undefined };
    }
    const { ethAddress } = selectedRole(sessionState.user.roles, sessionState.roleId);
    return buildWalletItem(ethAddress);
  }, [sessionState]);

  const onCollapsibleMenuClick = useCallback(
    (title: string) => setActiveItem((s) => (title === s ? null : title)),
    []
  );

  const onCopyEthAddress = useCallback(() => {
    if (!role) {
      return;
    }
    navigator.clipboard.writeText(role.ethAddress);
  }, [role]);

  const isMySprintClickable =
    isEnrolled(sessionState) &&
    isStartupRole(selectedRole(sessionState.user.roles, sessionState.roleId));

  const isMyWorkClickable =
    isEnrolled(sessionState) &&
    isProviderRole(selectedRole(sessionState.user.roles, sessionState.roleId));
  const isMarketplaceClickable =
    isEnrolled(sessionState) &&
    !isStartupRole(selectedRole(sessionState.user.roles, sessionState.roleId));
  const isWalletClickable = isEnrolled(sessionState);

  const showSpecialMenuItems = (isCBCMember || isAdmin) && !isCollapsed;

  return (
    <div className="LeftMenu">
      <div className="AppSection">
        <img className="AppSection-appIcon" src={cvLogo} alt="app-logo" />
        <img
          className="AppSection-collapseIcon"
          src={collapseIcon}
          alt="collapse-icon"
          onClick={onChevronClick}
        />
      </div>
      <div className="MenuItems-Wrapper">
        <ul className="MenuItems">
          <MenuItem
            item={portfolio}
            isExpanded={activeItem === portfolio.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
          />
          <MenuItem
            item={network}
            isExpanded={activeItem === network.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
          />
          <MenuItem
            item={startupDealflow}
            isExpanded={activeItem === startupDealflow.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
          />
          <MenuItem
            item={expertOnboarding}
            isExpanded={activeItem === expertOnboarding.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
          />
          <MenuItem
            item={marketplace}
            isExpanded={activeItem === marketplace.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
            isClickable={isMarketplaceClickable}
          />
          <MenuItem
            item={mySprint}
            isExpanded={activeItem === mySprint.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
            isClickable={isMySprintClickable}
          />
          <MenuItem
            item={myWork}
            isExpanded={activeItem === myWork.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
            isClickable={isMyWorkClickable}
          />

          <MenuItem
            item={walletItem}
            isExpanded={activeItem === walletItem.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
            isClickable={isWalletClickable}
          />
          <MenuItem
            item={communityResources}
            isExpanded={activeItem === communityResources.title}
            onExpand={onCollapsibleMenuClick}
            isLeftCollapsed={isCollapsed}
            closeMenu={onChevronClick}
          />
          {showSpecialMenuItems && <li className="MenuItem-label">My Activities</li>}
          {(isCBCMember || isAdmin) && (
            <MenuItem
              item={nomineeVoting}
              isExpanded={activeItem === nomineeVoting.title}
              onExpand={onCollapsibleMenuClick}
              isLeftCollapsed={isCollapsed}
              closeMenu={onChevronClick}
            />
          )}
          {isAdmin && (
            <MenuItem
              item={administration}
              isExpanded={activeItem === administration.title}
              onExpand={onCollapsibleMenuClick}
              isLeftCollapsed={isCollapsed}
              closeMenu={onChevronClick}
            />
          )}
        </ul>
        <Divider hidden />
        {canInvite && <CreateInviteModal />}
      </div>
      <div className="LeftMenu-FooterExpanded">
        <MyAreaPopup
          trigger={
            <div className="LeftMenu-FooterExpanded-Info">
              {avatarUrl && (
                <img
                  src={avatarUrl}
                  className="LeftMenu-FooterExpanded-Info-avatar"
                  alt={"avatar-icon"}
                />
              )}
              <div className="LeftMenu-FooterExpanded-Info-rightCol">
                <div className="LeftMenu-FooterExpanded-Info-rightCol-name">
                  {fullName || "Unknown"}
                  <div className="LeftMenu-FooterExpanded-Info-rightCol-role">
                    {role && <RoleIcon type={role.type} kind={role.kind} />}
                    {roleDescription}
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Divider />
        {role && (
          <div className="LeftMenu-FooterExpanded-Wallet">
            Wallet: {role.ethAddress.slice(0, 12)}...
            <Popup
              size="mini"
              openOnTriggerClick
              openOnTriggerMouseEnter={false}
              trigger={
                <img
                  className="LeftMenu-FooterExpanded-Wallet-copyIcon"
                  src={copyIcon}
                  onClick={onCopyEthAddress}
                  alt="wallet-copy-icon"
                />
              }
            >
              <span className="LeftMenu-FooterExpanded-Wallet-popup">Copied!</span>
            </Popup>
          </div>
        )}

        {chainStatusFields && (
          <Popup
            position="top right"
            closeOnPortalMouseLeave
            disabled={!chainStatusFields.popupContent}
            trigger={
              <div className={`LeftMenu-FooterExpanded-Status ${chainStatusFields.color}`}>
                <div className="LeftMenu-FooterExpanded-Status-icon" />
                {chainStatusFields.label}
              </div>
            }
            content={chainStatusFields.popupContent}
          />
        )}
      </div>

      <div className="LeftMenu-FooterCollapsed">
        <div className="LeftMenu-FooterCollapsed-Info">
          <MyAreaPopup
            trigger={<Image src={avatarUrl} className="LeftMenu-FooterCollapsed-Avatar" />}
          />
          {chainStatusFields && (
            <Popup
              position="top right"
              closeOnPortalMouseLeave
              disabled={!chainStatusFields.popupContent}
              trigger={
                <div className={`LeftMenu-FooterCollapsed-Status ${chainStatusFields.color}`}>
                  <div className="LeftMenu-FooterCollapsed-Status-icon" />
                </div>
              }
              content={chainStatusFields.popupContent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

interface ChainStatusFields {
  readonly color: "green" | "orange" | "red";
  readonly label: string;
  readonly popupContent?: string;
}

const getChainStatusFields = (
  sessionState: SessionState,
  chainState: ChainState
): ChainStatusFields | undefined => {
  if (!isAtLeastPresent(sessionState)) {
    return undefined;
  } else if (isPresent(sessionState)) {
    if (sessionState.user.roles.length === 0) {
      return {
        color: "red",
        label: "No role detected",
        popupContent:
          "You don't have a role yet. You can either obtain an invitation from a Consilience Ventures expert or contact us at help@consilienceventures.com.",
      };
    } else if (isNone(chainState)) {
      if (chainState.lastError?.type === ErrorType.InvalidNetwork) {
        return {
          color: "red",
          label: "Not connected to chain",
          popupContent:
            "Metamask doesn't seem to be connected to our chain. Please connect to Consilience Ventures' Private Chain.",
        };
      }

      return { color: "red", label: "Wallet not connected" };
    } else if (isConnected(chainState) || isAuthorized(chainState)) {
      return {
        color: "red",
        label: "Wallet not connected",
        popupContent: `Please login to Metamask and ensure that the current wallet address is "Connected".`,
      };
    } else if (sessionState.enrolmentError?.type === EnrollmentErrorType.FailedContractCall) {
      return {
        color: "orange",
        label: "Support required",
        popupContent:
          "A technical problem occured while we tried to call our Smart Contracts. Please make sure that you have a stable connection to the Internet.",
      };
    } else if (sessionState.enrolmentError?.type === EnrollmentErrorType.IdentityMismatch) {
      return {
        color: "orange",
        label: "Action required",
        popupContent:
          "We don't know the account you've selected in Metamask. Using Metamask, select the account you've used while enrolling.",
      };
    } else if (sessionState.enrolmentError?.type === EnrollmentErrorType.PartiallyVerified) {
      return {
        color: "orange",
        label: "Support required",
        popupContent:
          "Please wait 48h while a member of the team resolve this. It seems like although you're in our books, our chain doesn't know about you yet. Contact our support team if the problem persists.",
      };
    }

    return {
      color: "orange",
      label: "Support required",
      popupContent:
        "Please wait 48h while a member of the team resolve this. It seems like although you're in our books, our chain doesn't know about you yet. Contact our support team if the problem persists.",
    };
  } else if (isEnrolled(sessionState)) {
    return { color: "green", label: "Connected to chain" };
  }

  assertUnreachable(sessionState);
};
