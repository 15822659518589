import { PublicOnboard } from "../../types/onboard";
import { utils } from "../../utils/utils";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";
import { PreselectionEvaluationScoreOptions } from "./_types";

interface Props {
  readonly onboard: PublicOnboard;
}

export const DesirabilityForm = ({ onboard }: Props) => {
  const name = onboard.fullName || "this startup";
  const possessiveName = utils.withPossessiveApostrophe(name);

  return (
    <>
      <div className="VotingSection-title">
        <div className="VotingSection-title-text">DESIRABILITY</div>
      </div>
      <div className="VotingSection-grid">
        <div className="VotingSection-row">
          <div>
            <p>
              <b>Is {name} solving a critical pain-point?</b>
            </p>
            <p className="VotingSection-scale">
              Scale: 1. Pain-point difficult to understand, convoluted, little evidence. - 5. Plenty
              of market evidence and industry testimonials (preferably, collected by the team).
            </p>
          </div>
          <CustomDropdownSelectionField
            name="criticalPainPoint"
            label={null}
            placeholder="-"
            search={false}
            multiple={false}
            compact={true}
            dropdownOptions={PreselectionEvaluationScoreOptions}
            errorMessage="This field is mandatory."
          />
        </div>

        <div className="VotingSection-row">
          <div>
            <p>
              <b>How strong is {possessiveName} traction so far?</b>
            </p>
            <p className="VotingSection-scale">
              Scale: 1. No revenue, no traction, no POC customers. - 5. Three to five paying
              customer deals or POCs.
            </p>
          </div>
          <CustomDropdownSelectionField
            name="traction"
            label={null}
            placeholder="-"
            search={false}
            multiple={false}
            compact={true}
            dropdownOptions={PreselectionEvaluationScoreOptions}
            errorMessage="This field is mandatory."
          />
        </div>
      </div>
    </>
  );
};
