import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketState, TicketStatus } from "../../types/ticket";

export const MUTATION = gql`
  mutation AcceptTicketPlan($input: AcceptTicketPlanInput!) {
    payload: acceptTicketPlan(input: $input) {
      ticket {
        id
        state
        status
      }
    }
  }
`;

export interface Variables {
  readonly input: AcceptTicketPlanInput;
}

interface AcceptTicketPlanInput {
  readonly ticketId: string;
  readonly areasOfKnowledge?: string;
  readonly collaborationPreferenceOther?: string;
  readonly collaborationPreferenceEmail: boolean;
  readonly collaborationPreferenceMattermost: boolean;
  readonly collaborationPreferencePhoneCall: boolean;
  readonly collaborationPreferenceVideoCall: boolean;
  readonly collaborationPreferenceWhatsappOrText: boolean;
}

export interface Result {
  readonly payload?: AcceptTicketPlanPayload;
}

interface AcceptTicketPlanPayload {
  readonly ticket: { readonly state: TicketState; readonly status: TicketStatus } & Identifiable;
}
