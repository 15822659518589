import { gql } from "@apollo/client";
import { LINKEDEMAIL_FRAGMENT } from "../_fragments/LinkedEmail";
import { LinkedEmail } from "../../types/linkedEmail";

export const MUTATION = gql`
  mutation ResendEmailConfirmationCode($input: ResendEmailConfirmationCodeInput!) {
    payload: resendEmailConfirmationCode(input: $input) {
      linkedEmail {
        ...LinkedEmail
      }
    }
  }
  ${LINKEDEMAIL_FRAGMENT}
`;

interface ResendEmailConfirmationCodeInput {
  readonly linkedEmailId: string;
}

export interface Variables {
  readonly input: ResendEmailConfirmationCodeInput;
}

export interface Result {
  readonly payload?: { readonly linkedEmail: LinkedEmail };
}
