import { MouseEventHandler, useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Result, QUERY, Variables } from "../../../../api/onboards/PreselectionOnboard";
import { Enrolled } from "../../../../contexts/Session/state";
import { Shortcuts as S } from "../../../../routing";
import { OnboardMigrator } from "../../../../migrations/Onboards";
import { useBreadcrumbApi } from "../../../../contexts/Breadcrumb";
import { ExpertDetailsBreadcrumb, RouteType } from "../ExpertDetailsBreadcrumb";
import { ErrorMessages } from "../../../elements/ErrorMessages";
import { Label, Loader } from "semantic-ui-react";
import { isEnum } from "../../../../types";
import { ExpertDetailsProfileTab } from "../ExpertDetailsProfileTab";
import { MutualAssessmentsState, OnboardState } from "../../../../types/onboard";
import { ExpertOnboardEvaluationDetails } from "../ExpertOnboardEvaluationDetails";
import { ExpertEvaluationForm } from "../../../../schemas/expertEvaluation/ExpertEvaluationForm";
import { FunctionTypes, roleHasFunction } from "../../../../contexts/Session/helpers";

const idVarName = S.expert.queryVarNames.id;
const tabVarName = S.expert.queryVarNames.tab;
const voteTabVarName = S.expert.queryVarNames.voteTab;

export enum Tabs {
  Profile = "profile",
  SeconderForm = "seconder-form",
  Evaluation = "evaluation",
}

interface Props {
  readonly sessionState: Enrolled;
}

export const ExpertDetailsPublic = ({ sessionState }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const breadcrumbApi = useBreadcrumbApi();
  const onboardId = new URLSearchParams(location.search).get(idVarName) || "";
  const { roleId } = sessionState;
  const isCBCMember = roleHasFunction(sessionState, FunctionTypes.CBCMember);

  const selectedTab = useMemo(() => {
    const tab = new URLSearchParams(location.search).get(tabVarName) || Tabs.Profile;
    if (isEnum(tab, Tabs)) {
      return tab;
    } else {
      const newSearch = new URLSearchParams(location.search);
      newSearch.set(tabVarName, Tabs.Profile);
      newSearch.delete(voteTabVarName);
      history.replace({ search: newSearch.toString() });
      return Tabs.Profile;
    }
  }, [location, history]);

  const query = useQuery<Result, Variables>(QUERY, { variables: { onboardId, roleId } });
  const { data, loading, error, refetch } = query;

  const onboard = useMemo(() => {
    if (!data?.onboard) {
      return undefined;
    }

    const { migrate } = new OnboardMigrator(data.onboard);
    return migrate();
  }, [data]);

  useEffect(() => {
    const onboardState = onboard?.extraFields.mutualAssessment?.state;
    const newSearch = new URLSearchParams(location.search);
    if (selectedTab === Tabs.SeconderForm && onboardState === MutualAssessmentsState.Completed) {
      newSearch.set(tabVarName, Tabs.Evaluation);
      return history.replace({ search: newSearch.toString() });
    }
    if (selectedTab === Tabs.Evaluation && onboardState === MutualAssessmentsState.Started) {
      newSearch.set(tabVarName, Tabs.SeconderForm);
      return history.replace({ search: newSearch.toString() });
    }
    if (
      !onboard?.extraFields.mutualAssessment?.state &&
      (selectedTab === Tabs.SeconderForm || selectedTab === Tabs.Evaluation)
    ) {
      newSearch.set(tabVarName, Tabs.Profile);
      newSearch.delete(voteTabVarName);
      return history.replace({ search: newSearch.toString() });
    }
  }, [history, location, onboard, selectedTab, isCBCMember]);

  useEffect(() => {
    if (!onboard) {
      return;
    }

    const customTitle = `Expert Onboard Details - ${onboard.fullName}`;
    const body = (
      <ExpertDetailsBreadcrumb
        onboard={onboard}
        sessionState={sessionState}
        route={RouteType.Default}
      />
    );

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(body);

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [breadcrumbApi, onboard, sessionState]);

  const onTabClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      const newTab = ev.currentTarget.id;
      if (isEnum(newTab, Tabs) && newTab !== selectedTab) {
        const newSearch = new URLSearchParams(location.search);
        newSearch.set(tabVarName, newTab);
        return history.replace({ search: newSearch.toString() });
      }
    },
    [history, location, selectedTab]
  );

  if (error) {
    return <ErrorMessages errors={[error.message]} />;
  } else if (!onboard || !onboard.migratedForm || !onboard.migratedForm.data) {
    return loading ? <Loader active inline="centered" /> : null;
  }
  const { mutualAssessment } = onboard.extraFields;
  const profileTabCname = `PageContentTabs-tab${selectedTab === Tabs.Profile ? " selected" : ""}`;
  const seconderFormTabCname = `PageContentTabs-tab${
    selectedTab === Tabs.SeconderForm ? " selected" : ""
  }`;
  const evaluationTabCname = `PageContentTabs-tab${
    selectedTab === Tabs.Evaluation ? " selected" : ""
  }`;

  return (
    <>
      <div className="PageContentTabs">
        <div id={Tabs.Profile} className={profileTabCname} onClick={onTabClick}>
          Profile
        </div>
        {mutualAssessment?.state === MutualAssessmentsState.Started && (
          <div className="PageContentTabs-vote-wrapper">
            <div id={Tabs.SeconderForm} className={seconderFormTabCname} onClick={onTabClick}>
              Seconder Form
            </div>
            <Label className="PageContentTabs-vote-label" pointing="left">
              Evaluate now!
            </Label>
          </div>
        )}
        {mutualAssessment?.state === MutualAssessmentsState.Completed && (
          <div id={Tabs.Evaluation} className={evaluationTabCname} onClick={onTabClick}>
            Evaluation
          </div>
        )}
      </div>
      {selectedTab === Tabs.Profile && (
        <ExpertDetailsProfileTab
          onboard={onboard}
          showRightColumn={onboard.state === OnboardState.AwaitingSeconders}
        />
      )}
      {selectedTab === Tabs.SeconderForm && (
        <ExpertEvaluationForm
          onboard={onboard}
          sessionState={sessionState}
          refetchMainQuery={refetch}
          isMainLoading={loading}
        />
      )}
      {selectedTab === Tabs.Evaluation && mutualAssessment && (
        <ExpertOnboardEvaluationDetails mutualAssessment={mutualAssessment} />
      )}
    </>
  );
};
