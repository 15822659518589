import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { PAGEINFO_FRAGMENT } from "../_fragments/PageInfo";
import { Connection, ConnectionVariables } from "../../types/relay";
import { PlanFeedback } from "./DeliverablesOnTicket";

export const QUERY = gql`
  query DeliverablesPlanFeedback(
    $id: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    payload: node(id: $id) {
      ... on TickettingTicket {
        id
        planFeedback(first: $first, last: $last, after: $after, before: $before) {
          edges {
            node {
              id
              feedback
              insertedAt
              owner {
                id
                fullName
                avatarUrl
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PAGEINFO_FRAGMENT}
`;

export interface Variables extends ConnectionVariables {
  readonly id: string;
}

export interface Result {
  readonly payload?: { readonly planFeedback?: Connection<PlanFeedback> } & Identifiable;
}
