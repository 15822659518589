import { assertUnreachable } from "../../types";

export enum TicketExecutionDate {
  Immediately,
  OneToTwoWeeks,
  MoreThanTwoWeeks,
  DecideLater,
}

export enum ExpertAttributes {
  manageRisk = "MANAGE_RISK",
  manageClientInterface = "MANAGE_CLI_INTERFACE",
  influencer = "INFLUENCING_OTHERS",
  communication = "COMMUNICATION_PRESENTATION",
  facilitateTeamwork = "FACILITATE_TEAMWORK",
  partnerNetworks = "PARTNERSHIP_NETWORKS",
  opportRealisation = "OPORTUNITY_REALISATION",
  leadershipDevelop = "LEADERSHIP_DEVELOPMENT",
  applyKnowledge = "APPLYING_KNOWLEDGE",
  planManage = "PLANNING_MANAGEMENT",
  businessUnderstand = "BUSINESS_UNDERSTANDING",
  teamProbSolv = "TEAM_PROBLEM_SOLVING",
  organisationalChange = "UNDERSTANDING_ORG_CHANGE",
  creativeThink = "CREATIVE_THINKING",
  rootCauseAnalysis = "ROOT_CAUSE_ANALYSIS",
  toolMethodo = "TOOLS_METHODOLOGIES",
}

export const readableTicketExecutionDate = (v: unknown): string => {
  switch (v) {
    case TicketExecutionDate.Immediately:
      return "Immediately";
    case TicketExecutionDate.OneToTwoWeeks:
      return "In 1 to 2 weeks";
    case TicketExecutionDate.MoreThanTwoWeeks:
      return "More than 2 weeks from now";
    case TicketExecutionDate.DecideLater:
      return "I'll decide later";
    default:
      return JSON.stringify(v);
  }
};

export enum TicketImportance {
  Low,
  Medium,
  High,
  Critical,
}

export const readableTicketImportance = (v: unknown): string => {
  switch (v) {
    case TicketImportance.Critical:
      return "Critical";
    case TicketImportance.High:
      return "High";
    case TicketImportance.Medium:
      return "Medium";
    case TicketImportance.Low:
      return "Low";
    default:
      return JSON.stringify(v);
  }
};

export enum TicketAnswer {
  Yes,
  No,
}

export const readableTicketAnswer = (v: unknown): string => {
  switch (v) {
    case TicketAnswer.Yes:
      return "Yes";
    case TicketAnswer.No:
      return "No";
    default:
      return JSON.stringify(v);
  }
};

export enum TicketEngagementLevel {
  FullTime,
  PartTime,
  Hourly,
  DecideLater,
}

export const readableTicketEngagementLevel = (v: unknown): string => {
  switch (v) {
    case TicketEngagementLevel.FullTime:
      return "Full time (40 hrs/week)";
    case TicketEngagementLevel.PartTime:
      return "Part time (less than 40hrs/week)";
    case TicketEngagementLevel.Hourly:
      return "Hourly";
    case TicketEngagementLevel.DecideLater:
      return "I'll decide later";
    default:
      return JSON.stringify(v);
  }
};

export enum TicketDuration {
  ShortTerm,
  MediumTerm,
  LongTerm,
  MaybePermanent,
}

export const readableTicketDuration = (v: unknown): string => {
  switch (v) {
    case TicketDuration.ShortTerm:
      return "Short term (up to 1 month)";
    case TicketDuration.MediumTerm:
      return "Medium term (up to 3 months)";
    case TicketDuration.LongTerm:
      return "Longer term (up to 6 months)";
    case TicketDuration.MaybePermanent:
      return "Potential for permanent";
    default:
      return JSON.stringify(v);
  }
};

export enum TicketPaymentMethods {
  CVDS,
  FiatAndCVDS,
}

export const readableTicketPaymentMethods = (v: unknown): string => {
  switch (v) {
    case TicketPaymentMethods.CVDS:
      return "CVDS only";
    case TicketPaymentMethods.FiatAndCVDS:
      return "Fiat and CVDS";
    default:
      return JSON.stringify(v);
  }
};

export const readableExpertAttributes = (attr: ExpertAttributes) => {
  switch (attr) {
    case ExpertAttributes.applyKnowledge:
      return "Applying Knowledge";
    case ExpertAttributes.businessUnderstand:
      return "Business Understanding";
    case ExpertAttributes.communication:
      return "Communication & Presentation";
    case ExpertAttributes.creativeThink:
      return "Creative Thinking";
    case ExpertAttributes.facilitateTeamwork:
      return "Facilitating Teamwork";
    case ExpertAttributes.influencer:
      return "Influencing Others";
    case ExpertAttributes.leadershipDevelop:
      return "Leadership Development";
    case ExpertAttributes.manageClientInterface:
      return "Managing Client Interface";
    case ExpertAttributes.manageRisk:
      return "Managing Risk";
    case ExpertAttributes.opportRealisation:
      return "Opportunity Realisation";
    case ExpertAttributes.organisationalChange:
      return "Understanding Organisation Change";
    case ExpertAttributes.partnerNetworks:
      return "Partnerships and Networks";
    case ExpertAttributes.planManage:
      return "Planning & Management";
    case ExpertAttributes.rootCauseAnalysis:
      return "Root Cause Analysis";
    case ExpertAttributes.teamProbSolv:
      return "Team-Based Problem-Solving";
    case ExpertAttributes.toolMethodo:
      return "Tools & Methodologies";
  }
  assertUnreachable(attr);
};

export enum TicketSealingActions {
  publish = "Publish",
  unpublish = "Unpublish",
}
