import { CSSProperties } from "react";
import { Button, Divider, Grid, Icon, List } from "semantic-ui-react";
import { useCategoriesState } from "../../../contexts/Categories";
import { isSuccessState } from "../../../contexts/Generic";
import { OnboardState, PublicOnboardViewedByMember } from "../../../types/onboard";
import { buildOfferParagraph, paragraphToHtml } from "../../../utils/applications/Formatting";
import { countryMapping } from "../../../utils/CountriesList";
import { CommunitySentiment } from "./CommunitySentiment";
import defaultLogo from "../../../assets/rocket1.png";

export const leftColumnSize = "12";
export const rightColumnSize = "4";

const largeFontStyle: CSSProperties = { fontSize: "16px", marginBottom: "4px" };
const imgStyle: CSSProperties = { width: "70px", height: "70px" };
const spaceBetween: CSSProperties = { justifyContent: "space-between" };
const rowStyle: CSSProperties = { padding: "0px" };
const pitchDeckStyle: CSSProperties = { width: "200px" };

interface Props {
  readonly onboard: PublicOnboardViewedByMember;
}

export const StartupDetailsTab = ({ onboard }: Props) => {
  const categoriesState = useCategoriesState();

  if (!isSuccessState(categoriesState)) {
    return null;
  } else if (!onboard.form || !onboard.form.data.startupData) {
    return null;
  }

  const hasVoted = !!onboard?.proposal?.hasVoted;
  const logoEdges = onboard.logoAttachment?.edges;
  const logo = logoEdges && logoEdges[0] ? logoEdges[0].node?.downloadUrl : undefined;
  const pitchDeckEdges = onboard.pitchDeckAttachment?.edges;
  const pitchDeck =
    pitchDeckEdges && pitchDeckEdges[0] ? pitchDeckEdges[0].node?.downloadUrl : undefined;
  const { startupData } = onboard.form.data;
  const { website, linkedin, twitter, facebook, instagram } = onboard.form.data.links || {};
  const { summary, companiesHouse, name, headquarters: hqs } = startupData;
  const { pitchHighlights, vision, team, usp, roadmap } = startupData;
  const { sectorsMapping, maturitiesMapping } = categoriesState.result;
  const offerParagraph = buildOfferParagraph(startupData, maturitiesMapping, sectorsMapping, false);

  const awaitingEnableVoting = onboard.state === OnboardState.AwaitingEnableVoting;

  return (
    <Grid>
      <Grid.Column width={hasVoted ? leftColumnSize : 16}>
        {awaitingEnableVoting && (
          <div className="PendingReviewWrapper">
            <Icon size="large" name="info circle" />
            <div>
              This application is being reviewed by an Industry Committee Lead. If they approve this
              application, members will be invited to vote and provide feedback.
            </div>
          </div>
        )}
        <div className="WrapperSection">
          <Grid className="PreselectionDetails">
            <Grid.Row style={rowStyle}>
              <Grid.Column>
                <div className="PreselectionDetails-title">{name}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={spaceBetween}>
              <Grid.Column width="12">
                <div style={largeFontStyle}>{summary}</div>
                {hqs?.length && hqs.length > 0 && (
                  <div>
                    HQ: <b>{hqs?.map((hq) => countryMapping(hq)).join(", ")}</b>
                  </div>
                )}
                <div className="PreselectionSubmittedBy">
                  This form was submitted by {onboard.recipientFullName}
                </div>
                <div className="PreselectionChampion">
                  <b>{onboard.sender.fullName}</b> invited {name} because "
                  {onboard.invitationReason}"
                </div>
              </Grid.Column>
              <Grid.Column width="4" textAlign="right">
                <img style={imgStyle} src={logo || defaultLogo} alt="logo" />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <div className="PreselectionDetails-links-wrappers">
                <div className="PreselectionDetails-links-container">
                  {linkedin && (
                    <Grid.Column className="SocialIcons">
                      <a target="_blank" rel="noopener noreferrer" href={linkedin}>
                        <Icon.Group size="large">
                          <Icon size="large" name="circle outline" />
                          <Icon name="linkedin" />
                        </Icon.Group>
                      </a>
                    </Grid.Column>
                  )}
                  {twitter && (
                    <Grid.Column className="SocialIcons">
                      <a target="_blank" rel="noopener noreferrer" href={twitter}>
                        <Icon.Group size="large">
                          <Icon size="large" name="circle outline" />
                          <Icon name="twitter" />
                        </Icon.Group>
                      </a>
                    </Grid.Column>
                  )}
                  {facebook && (
                    <Grid.Column className="SocialIcons">
                      <a target="_blank" rel="noopener noreferrer" href={facebook}>
                        <Icon.Group size="large">
                          <Icon size="large" name="circle outline" />
                          <Icon name="facebook f" />
                        </Icon.Group>
                      </a>
                    </Grid.Column>
                  )}
                  {instagram && (
                    <Grid.Column className="SocialIcons">
                      <a target="_blank" rel="noopener noreferrer" href={instagram}>
                        <Icon.Group size="large">
                          <Icon size="large" name="circle outline" />
                          <Icon name="instagram" />
                        </Icon.Group>
                      </a>
                    </Grid.Column>
                  )}
                  {website && (
                    <Grid.Column width="2" verticalAlign="middle" textAlign="center">
                      <a target="_blank" rel="noopener noreferrer" href={website}>
                        <p>Website</p>
                      </a>
                    </Grid.Column>
                  )}
                  {companiesHouse && (
                    <Grid.Column width="3" verticalAlign="middle" textAlign="center">
                      <a target="_blank" rel="noopener noreferrer" href={companiesHouse}>
                        Companies House
                      </a>
                    </Grid.Column>
                  )}
                </div>
                {pitchDeck && (
                  <div>
                    <Grid.Column floated="right" verticalAlign="middle" style={pitchDeckStyle}>
                      <a href={pitchDeck} download target="_blank" rel="noopener noreferrer">
                        <Button fluid className="DealFlowButton">
                          Download pitchdeck
                        </Button>
                      </a>
                    </Grid.Column>
                  </div>
                )}
              </div>
            </Grid.Row>
            <Divider />
            <Grid.Row columns="equal">
              {pitchHighlights && (
                <Grid.Column>
                  <div className="PreselectionDetails-title">Highlights</div>
                  <List bulleted>
                    {pitchHighlights.map((h, idx) => (
                      <List.Item key={idx}>{h}</List.Item>
                    ))}
                  </List>
                </Grid.Column>
              )}
              <Grid.Column>
                <div className="PreselectionDetails-title">Offer</div>
                {offerParagraph}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="PreselectionDetails-title">Vision</div>
                {paragraphToHtml(vision)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="PreselectionDetails-title">Team</div>
                <p>{team}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="PreselectionDetails-title">Unique Selling Points</div>
                {paragraphToHtml(usp)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="PreselectionDetails-title">Key Challenges</div>
                {paragraphToHtml(roadmap)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Grid.Column>
      {hasVoted && (
        <Grid.Column width={rightColumnSize}>
          <CommunitySentiment onboard={onboard} proposal={onboard.proposal} />
        </Grid.Column>
      )}
    </Grid>
  );
};
