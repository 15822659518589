import { MouseEventHandler, useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { isEnrolled } from "../../../contexts/Session/state";
import { useSessionState } from "../../../contexts/Session";
import { useOverlayApi } from "../../../contexts/Overlay";
import { noResultErrorFor, extractErrorMessages, isEnum, assertUnreachable } from "../../../types";
import { QUERY, Variables, Result } from "../../../api/onboards/MyOnboards";
import { nodesFromEdges } from "../../../types/relay";
import { LoaderWithMargin } from "../../Loader";
import { Shortcuts as S } from "../../../routing";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { ReceivedInvites } from "./ReceivedInvites";
import { SentInvites } from "./SentInvites";
import { Profile } from "../Dashboard/profile";

const tabVarName = S.myArea.queryVarNames.tab;
export enum Tabs {
  Applications = "applications",
  Sent = "sent",
  Profile = "profile",
}
export const MyArea = ({ history, location }: RouteComponentProps) => {
  const breadcrumbApi = useBreadcrumbApi();
  const sessionState = useSessionState();
  const overlayApi = useOverlayApi();
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });

  const rec = nodesFromEdges(data?.payload?.receivedOnboards?.edges);
  const sent = nodesFromEdges(data?.payload?.sentOnboards?.edges);

  const selectedTab = useMemo(() => {
    const tab = new URLSearchParams(location.search).get(tabVarName) || Tabs.Applications;
    // Replaces bad tab names on the url.
    return isEnum(tab, Tabs) ? tab : undefined;
  }, [location]);

  // Replace wrong tab on the url with a default.
  useEffect(() => {
    if (isEnum(selectedTab, Tabs)) {
      return;
    }
    const newSearch = new URLSearchParams(location.search);
    const viewParam = S.myArea.queryVarNames.tab;
    newSearch.set(viewParam, Tabs.Applications);
    history.replace({ search: newSearch.toString() });
  }, [selectedTab, location, history]);

  // Add breadcrumbs.
  useEffect(() => {
    if (!isEnum(selectedTab, Tabs)) {
      return;
    }

    const customTitle = readableBrowserTabTitle(selectedTab);
    const body = (
      <div>
        <h2>My Area</h2>
      </div>
    );

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(body);

    const onTabChange: MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
      if (isEnum(currentTarget.id, Tabs) && currentTarget.id !== selectedTab) {
        const params = new URLSearchParams({ [tabVarName]: currentTarget.id });
        history.replace({ search: params.toString() });
      }
    };

    const applicationsClassname = `panel${selectedTab === Tabs.Applications ? " active" : ""}`;
    const sentInviteClassname = `panel${selectedTab === Tabs.Sent ? " active" : ""}`;
    const profileClassname = `panel${selectedTab === Tabs.Profile ? " active" : ""}`;

    const breadcrumbFooter = (
      <div className="Breadcrumb-children-wrapper">
        <div className="Panes">
          {isEnrolled(sessionState) && (
            <>
              <div className={profileClassname} id={Tabs.Profile} onClick={onTabChange}>
                {readableTabTitle(Tabs.Profile)}
              </div>
              <div className={applicationsClassname} id={Tabs.Applications} onClick={onTabChange}>
                {readableTabTitle(Tabs.Applications)}
              </div>
              <div className={sentInviteClassname} id={Tabs.Sent} onClick={onTabChange}>
                {readableTabTitle(Tabs.Sent)}
              </div>
            </>
          )}
          {!isEnrolled(sessionState) && (
            <>
              <div className={applicationsClassname} id={Tabs.Applications} onClick={onTabChange}>
                {readableTabTitle(Tabs.Applications)}
              </div>
              <div className={profileClassname} id={Tabs.Profile} onClick={onTabChange}>
                {readableTabTitle(Tabs.Profile)}
              </div>
            </>
          )}
        </div>
      </div>
    );

    if (breadcrumbFooter) {
      breadcrumbApi.addFooter(breadcrumbFooter);
    }
    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
      breadcrumbApi.removeFooter();
    };
  }, [selectedTab, breadcrumbApi, history, sent.length, sessionState, overlayApi]);

  if (loading && !data) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.payload) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Invites"))} />;
  }

  return (
    <>
      {selectedTab === Tabs.Applications && <ReceivedInvites onboards={rec} />}
      {selectedTab === Tabs.Sent && <SentInvites onboards={sent} />}
      {selectedTab === Tabs.Profile && <Profile />}
    </>
  );
};

const readableBrowserTabTitle = (tab: Tabs) => {
  switch (tab) {
    case Tabs.Profile:
      return "My Area - Profile";
    case Tabs.Applications:
      return "My Area - Applications";
    case Tabs.Sent:
      return "My Area - Sent invites";
  }
  assertUnreachable(tab);
};

const readableTabTitle = (tab: Tabs) => {
  switch (tab) {
    case Tabs.Applications:
      return "Applications";
    case Tabs.Sent:
      return "Sent Invites";
    case Tabs.Profile:
      return "Profile";
  }
  assertUnreachable(tab);
};
