import { IndividualSteps, OrganisationSteps } from "../../../types/OnboardForm";
import { TermsAndConditions } from "./TermsAndConditions";
import { OnboardFormProps } from "../../../components/pages/Dashboard/applications/application";
import { InitialProfileForm } from "./InitialProfileForm";
import { KYCForm } from "./KYCForm";
import { EntityType } from "../../../types/onboard";

export const ProviderForm = ({ onboard }: OnboardFormProps) => {
  if (!onboard.migratedForm || !onboard.migratedForm.data) {
    return <>No onboard form data available</>;
  }
  const { currentStep } = onboard.migratedForm.data;
  const isIndividualOnboard = onboard.type === EntityType.Individual;
  return (
    <>
      {(currentStep === undefined || currentStep === IndividualSteps.Terms) && (
        <TermsAndConditions onboard={onboard} />
      )}
      {currentStep === IndividualSteps.KYC && isIndividualOnboard && <KYCForm onboard={onboard} />}
      {currentStep === OrganisationSteps.ProfileData && !isIndividualOnboard && (
        <InitialProfileForm onboard={onboard} />
      )}
      {currentStep === IndividualSteps.ProfileData && <InitialProfileForm onboard={onboard} />}
    </>
  );
};
