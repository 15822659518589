import { useMemo } from "react";
import { Dropdown, Image } from "semantic-ui-react";
import approvedIconBlue from "../../assets/approved-icon-blue.svg";
import { RoleSort } from "../../types/roleSort";

export interface OptionsFilterOps {
  readonly label: string;
  readonly value: RoleSort;
}

interface Props {
  readonly options: OptionsFilterOps[];
  readonly selectedOption: OptionsFilterOps;
  readonly onChange: (option: OptionsFilterOps) => void;
}

export const OptionsFilter = (props: Props) => {
  const { options, selectedOption, onChange } = props;

  const dropdownItems = useMemo(
    () =>
      options.map(o => {
        const active = o.label === selectedOption.label;
        const handleClick = () => onChange(o);
        return (
          <Dropdown.Item
            id={o.label}
            key={"option-" + o.value.field + "-" + o.value.direction}
            onClick={handleClick}
          >
            <div className={active ? "OptionsFilter-option-active" : "OptionsFilter-option"}>
              {o.label}
              {active && <Image src={approvedIconBlue} />}
            </div>
          </Dropdown.Item>
        );
      }),
    [options, selectedOption, onChange]
  );

  return (
    <div className="OptionsFilter">
      <div className="OptionsFilter-label">Sort by: </div>
      <Dropdown text={selectedOption.label} labeled button>
        <Dropdown.Menu>
          <Dropdown.Header content="Sort by" />
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
