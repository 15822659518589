import React from "react";
import { Container } from "semantic-ui-react";

interface Props {
  readonly currentChars: number;
  readonly maxChars: number;
  readonly showRedError?: boolean;
}
export const InputFieldCharCount = ({ currentChars, maxChars, showRedError = false }: Props) => {
  let className;
  let text;

  if (currentChars <= maxChars) {
    className = "InputFieldCharCount";
    text = `${maxChars - currentChars} characters left`;
  } else {
    className = showRedError
      ? "InputFieldCharCount InputFieldCharCount--maxed"
      : "InputFieldCharCount";
    const charsOverLimit = currentChars - maxChars;
    text = `${charsOverLimit} ${charsOverLimit === 1 ? "character" : "characters"} over limit`;
  }

  return (
    <Container textAlign="right" className={className}>
      {text}
    </Container>
  );
};
