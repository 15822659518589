import { gql, ApolloClient } from "@apollo/client";
import { TagTree, TagMapping } from "../../types/tags";

export const QUERY = gql`
  query AllTags {
    maturitiesTree: tagsTree(ns: MATURITIES)
    maturitiesMapping: tagsMapping(ns: MATURITIES)
    occupationsTree: tagsTree(ns: OCCUPATIONS)
    occupationsMapping: tagsMapping(ns: OCCUPATIONS)
    sectorsTree: tagsTree(ns: SECTORS)
    sectorsMapping: tagsMapping(ns: SECTORS)
    skillsTree: tagsTree(ns: SKILLS)
    skillsMapping: tagsMapping(ns: SKILLS)
  }
`;

export interface Result {
  readonly maturitiesTree: Readonly<TagTree>;
  readonly maturitiesMapping: Readonly<TagMapping>;
  readonly occupationsTree: Readonly<TagTree>;
  readonly occupationsMapping: Readonly<TagMapping>;
  readonly sectorsTree: Readonly<TagTree>;
  readonly sectorsMapping: Readonly<TagMapping>;
  readonly skillsTree: Readonly<TagTree>;
  readonly skillsMapping: Readonly<TagMapping>;
}

export const perform = (client: ApolloClient<any>) => client.query<Result>({ query: QUERY });
