import { gql } from "@apollo/client";
import { OnboardSealFields, ONBOARD_SEAL_FIELDS } from "./OnboardSealFields";

export const MUTUAL_ASSESSMENT_SEAL_FIELDS = gql`
  fragment MutualAssessmentSealFields on MutualAssessment {
    form(migrate: false) {
      data
    }
    transmittedAt
    onboard {
      ...OnboardSealFields
    }
  }
  ${ONBOARD_SEAL_FIELDS}
`;

export interface MutualAssessmentSealFields {
  readonly form: { readonly data: unknown };
  readonly transmittedAt: string;
  readonly onboard: OnboardSealFields;
}
