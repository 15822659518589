import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { Filters } from "../../api/admin/AdminOnboards";
import { EntityType } from "../../types/onboard";
import { OnboardAs, OnboardState, OnboardStatus } from "../../types/onboard";

const schema: JSONSchemaType<Required<Omit<Filters, "kind">>> = {
  type: "object",
  properties: {
    recipientId: { type: "string", maxLength: 300 },
    senderId: { type: "string", maxLength: 300 },
    fullName: { type: "string", maxLength: 300 },
    as: { type: "string", enum: Object.values(OnboardAs) },
    state: { type: "array", items: { type: "string", enum: Object.values(OnboardState) } },
    status: { type: "array", items: { type: "string", enum: Object.values(OnboardStatus) } },
    type: { type: "array", items: { type: "string", enum: Object.values(EntityType) } },
  },
  required: [],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
