import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation EvaluateTicketDelivery($input: EvaluateTicketInput!) {
    payload: evaluateTicket(input: $input) {
      feedbackEvaluation {
        id
        insertedAt
      }
    }
  }
`;

export interface Variables {
  readonly input: EvaluateTicketInput;
}

interface EvaluateTicketInput {
  readonly ticketId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
  readonly form: string;
}

export interface Result {
  readonly payload?: EvaluateTicketPayload;
}

interface EvaluateTicketPayload {
  readonly feedbackEvaluation: {
    readonly insertedAt: string;
  } & Identifiable;
}
