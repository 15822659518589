import { useQuery } from "@apollo/client";
import { MouseEventHandler, useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { usePageContentProvider } from "../../../../contexts/PageContent/Provider";
import { Enrolled, isAtLeastPresent } from "../../../../contexts/Session/state";
import { Result, QUERY, Variables } from "../../../../api/onboards/CBCMemberExpertOnboard";
import { Shortcuts as S } from "../../../../routing";
import { CBCExpertVoteForm } from "../../../../schemas/cbc/CBCExpertVoteForm";
import { isEnum } from "../../../../types";
import { ExpertDetailsCBCVotes } from "../ExpertDetailsCBCVotes";
import { ExpertDetailsProfileTab } from "../ExpertDetailsProfileTab";
import { OnboardMigrator } from "../../../../migrations/Onboards";
import { MutualAssessment, OnboardState } from "../../../../types/onboard";
import { readableOnboardStatusAndColor } from "../../../../types/onboard";
import { ErrorMessages } from "../../../elements/ErrorMessages";
import { Divider, Loader, Label } from "semantic-ui-react";
import { ExpertDetailsBreadcrumb, RouteType } from "../ExpertDetailsBreadcrumb";
import { useBreadcrumbApi } from "../../../../contexts/Breadcrumb";
import { SeconderEvaluationTab } from "../../Administration/SeconderEvaluationTab";

const idVarName = S.expert.queryVarNames.id;
const tabVarName = S.expert.queryVarNames.tab;
const voteTabVarName = S.expert.queryVarNames.voteTab;

export enum Tabs {
  Profile = "profile",
  EvaluationOne = "evaluation-one",
  EvaluationTwo = "evaluation-two",
  YourVote = "your-vote",
  CBCVotes = "cbc-votes",
}

interface Props {
  readonly sessionState: Enrolled;
}

export const ExpertDetailsCBCMember = ({ sessionState }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const breadcrumbApi = useBreadcrumbApi();
  const onboardId = new URLSearchParams(location.search).get(idVarName) || "";
  const { roleId } = sessionState;
  const { clearBackground, setCBCBackground } = usePageContentProvider();
  const isAdmin = isAtLeastPresent(sessionState) && sessionState.user.isAdmin;

  const selectedTab = useMemo(() => {
    const tab = new URLSearchParams(location.search).get(tabVarName) || Tabs.Profile;
    if (isEnum(tab, Tabs)) {
      return tab;
    } else {
      const newSearch = new URLSearchParams(location.search);
      newSearch.set(tabVarName, Tabs.Profile);
      newSearch.delete(voteTabVarName);
      history.replace({ search: newSearch.toString() });
      return Tabs.Profile;
    }
  }, [location, history]);

  const query = useQuery<Result, Variables>(QUERY, { variables: { onboardId, roleId } });
  const { data, loading, error } = query;

  const onboard = useMemo(() => {
    if (!data?.onboard) {
      return undefined;
    }

    const { migrate } = new OnboardMigrator(data.onboard);
    return migrate();
  }, [data]);

  useEffect(() => {
    if (!onboard) {
      return;
    }
    const { status, color } = readableOnboardStatusAndColor(onboard);
    const customTitle = `Expert Onboard Details - ${onboard.fullName}`;
    breadcrumbApi.addHeader(<Divider clearing />);

    breadcrumbApi.addCrumbsExtra(
      <div className="AdminExpertDetailsBreadcrumb-Status">
        <span className="AdminExpertDetailsBreadcrumb-Status-label">Status</span> {status}
        <div className={`StatusCircle ${color}`} />
      </div>
    );

    const body = (
      <ExpertDetailsBreadcrumb
        onboard={onboard}
        sessionState={sessionState}
        route={RouteType.Cbc}
      />
    );

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(body);

    return () => {
      breadcrumbApi.removeCrumbsExtra();
      breadcrumbApi.removeHeader();
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [breadcrumbApi, onboard, sessionState]);

  const cbcMemberTabsByOnboardState =
    onboard?.state === OnboardState.InCommitteeReview ||
    onboard?.state === OnboardState.AwaitingSignature ||
    onboard?.state === OnboardState.Closed;

  useEffect(() => {
    if (!onboard) {
      return;
    }
    const newSearch = new URLSearchParams(location.search);
    if (
      !cbcMemberTabsByOnboardState &&
      (selectedTab === Tabs.YourVote || selectedTab === Tabs.CBCVotes)
    ) {
      newSearch.set(tabVarName, Tabs.Profile);
      return history.replace({ search: newSearch.toString() });
    }
    if (selectedTab === Tabs.YourVote && onboard?.state !== OnboardState.InCommitteeReview) {
      newSearch.set(tabVarName, Tabs.Profile);
      return history.replace({ search: newSearch.toString() });
    }
    if (selectedTab === Tabs.YourVote && onboard?.committeeProposal?.hasVoted) {
      newSearch.set(tabVarName, Tabs.CBCVotes);
      return history.replace({ search: newSearch.toString() });
    }
    if (selectedTab === Tabs.CBCVotes && !onboard?.committeeProposal?.hasVoted && !isAdmin) {
      newSearch.set(tabVarName, Tabs.YourVote);
      return history.replace({ search: newSearch.toString() });
    }
  }, [history, location, onboard, selectedTab, cbcMemberTabsByOnboardState, isAdmin]);

  useEffect(() => {
    setCBCBackground();
    return () => clearBackground();
  }, [clearBackground, setCBCBackground]);

  const onTabClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      const newTab = ev.currentTarget.id;
      if (isEnum(newTab, Tabs) && newTab !== selectedTab) {
        const newSearch = new URLSearchParams(location.search);
        newSearch.set(tabVarName, newTab);
        newSearch.delete(voteTabVarName);
        return history.replace({ search: newSearch.toString() });
      }
    },
    [history, location, selectedTab]
  );

  const { firstCandidacy, secondCandidacy, assignedSecondersCount, firstEval, secondEval } =
    useMemo(() => {
      if (
        !onboard?.extraFields.assignedSeconderCandidacies ||
        !onboard?.extraFields.mutualAssessments
      ) {
        return {
          firstCandidacy: undefined,
          secondCandidacy: undefined,
          assignedSecondersCount: 0,
          firstEval: undefined,
          secondEval: undefined,
        };
      }
      const evals = onboard.extraFields.mutualAssessments;
      const firstCand = onboard.extraFields.assignedSeconderCandidacies[0];
      const secondCand = onboard.extraFields.assignedSeconderCandidacies[1];
      const firstSeconderEval: MutualAssessment | undefined = evals.find(
        ({ seconder }) => seconder.id === firstCand.seconder.id
      );
      const secondSeconderEval: MutualAssessment | undefined = evals.find(
        ({ seconder }) => seconder.id === secondCand.seconder.id
      );

      return {
        firstCandidacy: firstCand,
        secondCandidacy: secondCand,
        assignedSecondersCount: onboard.extraFields.assignedSeconderCandidacies.length,
        firstEval: firstSeconderEval,
        secondEval: secondSeconderEval,
      };
    }, [onboard]);

  if (error) {
    return <ErrorMessages errors={[error.message]} />;
  } else if (!onboard || !onboard.migratedForm || !onboard.migratedForm.data) {
    return loading ? <Loader active inline="centered" /> : null;
  }

  const displayEvalTabsByOnboardState =
    onboard.state === OnboardState.InCommitteeReview ||
    onboard.state === OnboardState.InMutualAssessment ||
    onboard.state === OnboardState.AwaitingSignature;

  const profileTabCname = `PageContentTabs-tab${selectedTab === Tabs.Profile ? " selected" : ""}`;
  const evaluationOneTabCname = `PageContentTabs-tab${
    selectedTab === Tabs.EvaluationOne ? " selected" : ""
  }`;
  const evaluationTwoTabCname = `PageContentTabs-tab${
    selectedTab === Tabs.EvaluationTwo ? " selected" : ""
  }`;
  const yourVoteTabCname = `PageContentTabs-tab${selectedTab === Tabs.YourVote ? " selected" : ""}`;
  const cbcVotesTabCname = `PageContentTabs-tab${selectedTab === Tabs.CBCVotes ? " selected" : ""}`;

  return (
    <>
      <div className="PageContentTabs">
        <div id={Tabs.Profile} className={profileTabCname} onClick={onTabClick}>
          Profile
        </div>
        {displayEvalTabsByOnboardState && (
          <div id={Tabs.EvaluationOne} className={evaluationOneTabCname} onClick={onTabClick}>
            Evaluation 1
          </div>
        )}

        {displayEvalTabsByOnboardState && (
          <div id={Tabs.EvaluationTwo} className={evaluationTwoTabCname} onClick={onTabClick}>
            Evaluation 2
          </div>
        )}
        {cbcMemberTabsByOnboardState && (onboard.committeeProposal?.hasVoted || isAdmin) && (
          <div id={Tabs.CBCVotes} className={cbcVotesTabCname} onClick={onTabClick}>
            CBC Votes
          </div>
        )}
        {onboard.state === OnboardState.InCommitteeReview && !onboard.committeeProposal?.hasVoted && (
          <div id={Tabs.YourVote} className={yourVoteTabCname} onClick={onTabClick}>
            Your Vote
            <Label className="PageContentTabs-vote-label" pointing="left">
              Vote now!
            </Label>
          </div>
        )}
      </div>
      {selectedTab === Tabs.Profile && (
        <ExpertDetailsProfileTab
          onboard={onboard}
          showRightColumn={onboard.state === OnboardState.AwaitingSeconders}
        />
      )}
      {selectedTab === Tabs.EvaluationOne && firstCandidacy && firstEval && (
        <SeconderEvaluationTab
          candidacy={firstCandidacy}
          assignedSecondersCount={assignedSecondersCount}
          mutualAssessment={firstEval}
          isAdminView={false}
        />
      )}
      {selectedTab === Tabs.EvaluationTwo && secondCandidacy && secondEval && (
        <SeconderEvaluationTab
          candidacy={secondCandidacy}
          assignedSecondersCount={assignedSecondersCount}
          mutualAssessment={secondEval}
          isAdminView={false}
        />
      )}
      {selectedTab === Tabs.YourVote && (
        <CBCExpertVoteForm onboard={onboard} sessionState={sessionState} />
      )}
      {selectedTab === Tabs.CBCVotes && (
        <ExpertDetailsCBCVotes onboard={onboard} sessionState={sessionState} />
      )}
    </>
  );
};
