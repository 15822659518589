import { useCallback, useState, MouseEvent } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import followStar from "../../../../assets/follow-star.svg";
import unfollowStar from "../../../../assets/unfollow-star.svg";
import { Shortcuts as S } from "../../../../routing";
import { MarketPlaceTicket } from "../../../../api/tickets/Tickets";
import { TicketPaymentMethods } from "../../../../schemas/tickets/_enums";
import { Icon, Image, Popup } from "semantic-ui-react";
import { ApiError, assertUnreachable, extractErrorMessages, isDefined } from "../../../../types";
import { MUTATION as unfollowMutation } from "../../../../api/followers/unfollow";
import { Result as unfollowRes } from "../../../../api/followers/unfollow";
import { Variables as unfollowVars } from "../../../../api/followers/unfollow";
import { MUTATION as followMutation } from "../../../../api/followers/follow";
import { Result as followRes } from "../../../../api/followers/follow";
import { Variables as followVars } from "../../../../api/followers/follow";
import { TextClamp } from "../../TextClamp";
import { utils } from "../../../../utils/utils";

interface Props {
  readonly ticket: MarketPlaceTicket;
}

export const MarketplaceCard = ({ ticket }: Props) => {
  const history = useHistory();
  const { id, insertedAt, followedBy, submittedBidBy, questionCount } = ticket;
  const { ticketForm: form, owner } = ticket;
  const [follow] = useMutation<followRes, followVars>(followMutation);
  const [unfollow] = useMutation<unfollowRes, unfollowVars>(unfollowMutation);
  const [followCD, setFollowCD] = useState<boolean>(false);
  const { getMaxDate } = utils;

  const search = new URLSearchParams({ [S.ticket.queryVarNames.id]: id }).toString();
  const linksTo = `${S.ticket.path}?${search}`;
  const onCardClick = useCallback(() => history.push(linksTo), [linksTo, history]);

  const cantUnfollow = isDefined(submittedBidBy);

  const handleFollow = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (followCD || cantUnfollow) {
        return;
      }

      const mutation = followedBy ? unfollow : follow;
      const toastMessage = followedBy
        ? "You have unfollowed the ticket " + form?.data.title
        : "You have followed the ticket " + form?.data.title;

      setFollowCD(true);
      mutation({ variables: { input: { followableId: id } } })
        .then(() => toast.success(toastMessage))
        .catch((err: ApiError) => toast.error(extractErrorMessages(err).join(", ")))
        .finally(() => setFollowCD(false));
    },
    [follow, unfollow, followedBy, cantUnfollow, form, id, followCD]
  );

  return (
    <div className="MarketplaceCard" onClick={onCardClick}>
      <div className="MarketplaceCard-wrapper">
        <div className="MarketplaceCard-top-section">
          <Image className="AvatarPicture79" floated="left" src={owner.avatarUrl} circular />
          <div className="MarketplaceCard-title">
            <TextClamp text={form?.data.title || "Untitled"} maxLines={2} />
            <span className="MarketplaceCard-sub-title">Ticket by {owner.fullName}</span>
          </div>
        </div>
        <Popup
          content={followedBy ? "Unfollow" : "Follow"}
          disabled={cantUnfollow}
          trigger={
            <Image
              className="MarketplaceCard-follow-icon"
              src={followedBy ? unfollowStar : followStar}
              alt="star"
              verticalAlign="middle"
              onClick={handleFollow}
              disabled={cantUnfollow ? true : followCD}
            />
          }
          position="top center"
        />
      </div>
      <div className="MarketplaceCard-wrapper">
        <div className="MarketplaceCard-description">
          {isDefined(form) && isDefined(form.data.paymentMethod) && (
            <div>
              Payment Type &nbsp;{generateTypePaymentLabel(form.data.paymentMethod)} &nbsp;|
            </div>
          )}
          <div>
            &nbsp; Expected completion &nbsp;
            {isDefined(form) && isDefined(form.data.deliverables) && (
              <b>
                {dateFormat(getMaxDate(form.data.deliverables.map((d) => d.dueDate)), "dd/mm/yy")}
              </b>
            )}
          </div>
        </div>
      </div>
      <div className="MarketplaceCard-divider" />
      <div className="MarketplaceCard-bottom-container">
        <div className="MarketplaceCard-description">
          Published on {dateFormat(insertedAt, "dd/mm/yy")}
        </div>
        {(submittedBidBy || questionCount > 0) && (
          <div className="MarketplaceCard-bottom-container-info">
            {submittedBidBy && (
              <>
                Bid <Icon name="hand paper" />
              </>
            )}
            {submittedBidBy && questionCount > 0 && <>&nbsp;|&nbsp;&nbsp;</>}
            {questionCount > 0 && (
              <>{`${questionCount}  ${questionCount > 1 ? "Questions" : "Question"}`}</>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const generateTypePaymentLabel = (payment: TicketPaymentMethods) => {
  switch (payment) {
    case TicketPaymentMethods.CVDS:
      return <b>CVDS</b>;

    case TicketPaymentMethods.FiatAndCVDS:
      return <b>CVDS + Fiat</b>;
  }
  assertUnreachable(payment);
};
