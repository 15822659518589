import { gql } from "@apollo/client";
import { LightComment } from "./DiscussionThreadOnBallot";

export const MUTATION = gql`
  mutation PostDiscussionLightComment($input: PostDiscussionLightCommentInput!) {
    payload: postDiscussionLightComment(input: $input) {
      comment {
        ... on DiscussionLightComment {
          id
          insertedAt
          body
          anonymizableAvatarUrl
          anonymizableProfile {
            ... on IndividualProfile {
              id
              fullName
            }
            ... on OrganisationProfile {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

interface PostDiscussionLightCommentInput {
  readonly threadId: string;
  readonly commentInput: DiscussionLightCommentInput;
}

interface DiscussionLightCommentInput {
  readonly body: string;
  readonly title: string;
}

export interface Variables {
  readonly input: PostDiscussionLightCommentInput;
}

export interface Result {
  readonly payload?: { readonly comment: LightComment };
}
