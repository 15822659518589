import { useCallback, CSSProperties } from "react";
import { BoolField } from "uniforms-semantic";
import { Button, Image } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import { perform as amendOnboard } from "../../../api/onboards/AmendOnboard";
import blueArrow from "../../../assets/blue-arrow.svg";
import { noResultErrorFor } from "../../../types";
import { IndividualSteps } from "../../../types/OnboardForm";
import { utils } from "../../../utils/utils";
import { bridge as TermsSchemaBridge } from "../schemas/TermsAndConditions";
import { OnboardFormProps } from "../../../components/pages/Dashboard/applications/application";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { useScrollToTopApi } from "../../../components/elements/ScrollToTop";

const justifiedText: CSSProperties = { textAlign: "justify" };

export const TermsAndConditions = ({ onboard }: OnboardFormProps) => {
  const client = useApolloClient();
  const onLinkClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);
  const { scrollToTop } = useScrollToTopApi();

  const onSubmit = useCallback(
    (ev) => {
      const schemaVersion = onboard.migratedForm?.schemaVersion;
      if (!schemaVersion) {
        return toast.error("Missing schema version.");
      }

      const data = JSON.stringify({ ...ev, currentStep: IndividualSteps.KYC });
      amendOnboard(client, { onboardId: onboard.id, data, schemaVersion })
        .then((res) => {
          if (!res?.data?.payload?.onboard) {
            return Promise.reject(noResultErrorFor("AmendOnboard"));
          }
          scrollToTop();
        })
        .catch((err) => {
          console.warn(err);
          toast.error("Failed to amend onboard.");
        });
    },
    [client, onboard, scrollToTop]
  );

  return (
    <div className="WhyJoinForm">
      <div className="WhyJoinForm-top-container">
        <Image src={blueArrow} />
        <div className="WhyJoinForm-title">Why join our global network?</div>
        <div className="WhyJoinForm-title-sub-text">
          Our ecosystem enables you to be rewarded for your work in proportion to your customer's
          success - without the challenges of sweat equity.
        </div>
        <br />
        <div>
          <b>Experts Benefits</b>
        </div>
        <br />
        <div className="WhyJoinForm-lists">
          <div className="WhyJoinForm-lists-list">
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>
                Work with startups vetted for growth potential, and get a slice of the upside by
                earning CVDS.
              </div>
            </div>
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>Minimise capital-at-risk. Invest your time rather than your savings.</div>
            </div>
          </div>
          <div className="WhyJoinForm-lists-list">
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>
                Expand your network and view opportunities to collaborate with top VCs, Angels,
                Engineers, and Scientists from around the world.
              </div>
            </div>
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>Get paid to help build our network and for completing ecosystem tasks.</div>
            </div>
          </div>
        </div>
      </div>
      <AutoForm
        schema={TermsSchemaBridge}
        model={onboard.migratedForm?.data}
        showInlineError
        onSubmit={onSubmit}
        className="WhyJoinForm-bottom-container"
      >
        <div className="WhyJoinForm-title">Terms and Conditions</div>
        <br />
        <BoolField
          name="agreesToTerms"
          label={
            <p style={justifiedText}>
              I/We hereby agree that my/our application for membership of the Consilience Ventures
              Platform is made on the basis of and, if accepted, I/we will be bound by the{" "}
              <a
                href={`${utils.apiHost}/static/files/cv-members-terms-and-conditions-191010.pdf`}
                download
                target="_blank"
                onClick={onLinkClick}
                rel="noopener noreferrer"
              >
                CV Membership Terms and Conditions
              </a>{" "}
              (which I have read and understood) as amended from time to time and which are
              available on this website.
            </p>
          }
          errorMessage="This field is mandatory"
        />
        <BoolField
          name="agreesToMemorandum"
          label={
            <p style={justifiedText}>
              I/We also confirm that I/we have read and understood the{" "}
              <a
                href={`${utils.apiHost}/static/files/cv-information-memorandum-191010.pdf`}
                download
                target="_blank"
                rel="noopener noreferrer"
                onClick={onLinkClick}
              >
                CV Information Memorandum
              </a>{" "}
              dated 18 September 2019 and that any CVDS I/we may acquire are governed by the terms
              of a Deed Poll issued by Consilience Ventures Limited dated 18 September 2019 (as
              amended from time to time and which is summarized in the Information Memorandum and
              available from Consilience Ventures Limited on request).
            </p>
          }
          errorMessage="This field is mandatory"
        />
        <BoolField
          name="providerData.agreesToQualifiedInvestor"
          label={
            <p style={justifiedText}>
              I/We confirm that if I/we are applying for membership as an Investor (whether or not
              also applying in some other capacity (i.e. as an Expert or Start-Up) I/we are a
              Qualified Investor as defined in the Consilience Ventures Information Memorandum.
            </p>
          }
        />
        <div className="OnboardForm-bttn-wrapper">
          <Button type="submit" content="Continue" color="blue" />
        </div>
      </AutoForm>
    </div>
  );
};
