import { CSSProperties, DetailedHTMLProps, HTMLAttributes, useMemo } from "react";
import { Popup } from "semantic-ui-react";
import { useCategoriesState } from "../../contexts/Categories";
import { isSuccessState } from "../../contexts/Generic";
import { assertUnreachable } from "../../types";
import { TagNamespace } from "../../types/labelQuery";

const popupTriggerStyle: CSSProperties = {
  color: "#1585fd",
  fontWeight: "bold",
};

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  readonly tags?: ReadonlyArray<string>;
  readonly cap: number;
  readonly namespace: TagNamespace;
}

export const TagDisplayer = ({ tags, cap, namespace, ...divProps }: Props) => {
  const categoriesState = useCategoriesState();

  const tagDescriptions = useMemo(() => {
    if (!isSuccessState(categoriesState) || !tags) {
      return [];
    }
    switch (namespace) {
      case TagNamespace.Maturities:
        return tags.map((t) => categoriesState.result.maturitiesMapping[t]);
      case TagNamespace.Occupations:
        return tags.map((t) => categoriesState.result.occupationsMapping[t]);
      case TagNamespace.Sectors:
        return tags.map((t) => categoriesState.result.sectorsMapping[t]);
      case TagNamespace.Skills:
        return tags.map((t) => categoriesState.result.skillsMapping[t]);
      case TagNamespace.DocumentTypes:
        return [];
    }
    assertUnreachable(namespace);
  }, [tags, namespace, categoriesState]);

  return (
    <div {...divProps}>
      {tagDescriptions.length === 0 && <>n/a</>}
      {tagDescriptions.slice(0, cap).join(", ")}
      {tagDescriptions.length > cap && (
        <Popup
          trigger={<span style={popupTriggerStyle}>, +{tagDescriptions.length - cap}</span>}
          content={
            <ul>
              {tagDescriptions.map((t, idx) => (
                <li key={`${t}-${idx}`}>{t}</li>
              ))}
            </ul>
          }
        />
      )}
    </div>
  );
};
