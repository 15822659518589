import { useState, useCallback, CSSProperties } from "react";
import { useMutation } from "@apollo/client";
import { Card, Form, Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Queryable, isEmpty, noResultErrorFor, extractErrorMessages } from "../../types";
import { FormFieldError } from "../elements/PasswordForm";
import { useSessionApi } from "../../contexts/Session";
import { MUTATION as ConfirmMut } from "../../api/accounts/ConfirmEmail";
import { Result as ConfirmRes } from "../../api/accounts/ConfirmEmail";
import { Variables as ConfirmVars } from "../../api/accounts/ConfirmEmail";
import { MUTATION as ResendMut } from "../../api/accounts/ResendEmailConfirmation";
import { Result as ResendRes } from "../../api/accounts/ResendEmailConfirmation";
import { Variables as ResendVars } from "../../api/accounts/ResendEmailConfirmation";
import { QUERY } from "../../api/accounts/Me";
import { SessionUser } from "../../types/frontend_only/sessionUser";
import { ErrorMessages } from "../elements/ErrorMessages";
import { LinkedEmail } from "../../types/linkedEmail";
import { wallcontentStyle } from "./LinkEmailWall";

const inputStyle: CSSProperties = { paddingRight: "8px" };
const helpDivStyle: CSSProperties = { fontSize: "12px" };
const linkStyle: CSSProperties = { color: "#0085ff" };

interface State extends Queryable {
  readonly verificationCode: string;
}

const initialState: State = { verificationCode: "" };
type StateErrors = Record<keyof State, FormFieldError>;
const initialErrors: Partial<StateErrors> = {};

interface Props {
  readonly user: SessionUser;
  readonly email: LinkedEmail;
}

export const VerifyEmailWall = ({ user, email }: Props) => {
  const sessionApi = useSessionApi();
  const [{ verificationCode, busy, errors: remoteErrors }, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [confirmEmail] = useMutation<ConfirmRes, ConfirmVars>(ConfirmMut, {
    refetchQueries: [{ query: QUERY }],
  });
  const [resendEmail] = useMutation<ResendRes, ResendVars>(ResendMut);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState(({ errors: _, ...s }) => ({ ...s, [name]: value }));
    setErrors((s) => ({ ...s, [name]: undefined }));
  }, []);

  const onSubmit = () => {
    let hasErrors = false;

    if (isEmpty(verificationCode)) {
      setErrors((s) => ({ ...s, verificationCode: { content: "Required field" } }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setState((s) => ({ ...s, busy: true }));

    confirmEmail({ variables: { input: { token: verificationCode } } })
      .then((res) => {
        if (!res.data || !res.data.payload) {
          return Promise.reject(noResultErrorFor("Confirm Email"));
        }
        const primaryLinkedEmail = user.primaryLinkedEmail || res.data.payload.linkedEmail;
        sessionApi.setPresent({ ...user, primaryLinkedEmail });
      })
      .catch((err) => setState((s) => ({ ...s, busy: false, errors: extractErrorMessages(err) })));
  };

  const onResendVerification = useCallback(() => {
    resendEmail({ variables: { input: { linkedEmailId: email.id } } })
      .then((_) => toast.success(`A validation link was sent to ${email.address}.`))
      .catch((err) => toast.error(`Error: ${extractErrorMessages(err)}`));
  }, [email.id, email.address, resendEmail]);

  return (
    <Card className="Wall xl">
      <div className="WallContent xl" style={wallcontentStyle}>
        <h1 className="WallContent-subtitle">Verify email</h1>
        <p>
          We have sent a verification code to {<b>{email.address}</b>}. The email should contain a
          14 character verification code. If you haven't received an email, request another one
          below.
        </p>
        <Form className="WallContent-form" noValidate>
          <div className="Wall-email-token-input">
            <Form.Input
              name="verificationCode"
              label="Enter verification code"
              placeholder="ex: j4qP3L4dJnHNQ3"
              value={verificationCode}
              onChange={onChange}
              error={errors.verificationCode !== undefined}
              style={inputStyle}
            />
            <Button onClick={onSubmit} disabled={busy} color="blue">
              Submit
            </Button>
          </div>
          <ErrorMessages errors={remoteErrors} />
        </Form>
        <div className="Wall-link" onClick={onResendVerification}>
          Resend verification email
        </div>
        <br />
        <br />
        <div style={helpDivStyle}>
          If your email address is incorrect, contact{" "}
          <a style={linkStyle} href="mailto:help@consilienceventures.com">
            help@consilienceventures.com
          </a>
          .
        </div>
      </div>
    </Card>
  );
};
