import { gql, ApolloClient } from "@apollo/client";
import { ConnectionVariables, Connection } from "../types/relay";
import { Role } from "../types/role";
import { EntityKind, EntityType } from "../types/onboard";
import { PROFILE_FRAGMENT } from "./_fragments/Profile";

export const QUERY = gql`
  query Roles(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $fullName: String
    $ethAddress: EthAddress
    $type: [EntityType!]
    $kind: [EntityKind!]
  ) {
    roles: roles(
      after: $after
      before: $before
      first: $first
      last: $last
      fullName: $fullName
      ethAddress: $ethAddress
      type: $type
      kind: $kind
    ) {
      edges {
        cursor
        node {
          id
          canInvest
          canInvestUntil
          ethAddress
          fullName
          kind
          avatarUrl
          onboard {
            id
          }
          profile {
            ...Profile
          }
          type
          user {
            id
          }
          tags(scope: DEFAULT) {
            id
            assignable
            description
            label
            ns
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;

export interface RolesFilter {
  readonly ethAddress?: string;
  readonly fullName?: string;
  readonly type?: ReadonlyArray<EntityType>;
  readonly kind?: ReadonlyArray<EntityKind>;
}

export interface Variables extends ConnectionVariables, RolesFilter {}

export interface Result {
  readonly roles?: Connection<Omit<Role, "extraAttributes">>;
}

export const perform = (c: ApolloClient<any>, variables: Variables) =>
  c.query<Result>({ query: QUERY, variables });
