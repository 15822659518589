import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { QUERY, Variables, Result } from "../../../api/onboards/StartupDealflow";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { requireEnrolled, useSessionState } from "../../../contexts/Session";
import { Shortcuts as S } from "../../../routing";
import { extractErrorMessages } from "../../../types";
import { EntityKind, EntityType } from "../../../types/onboard";
import { nodesFromEdges } from "../../../types/relay";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { LoaderWithMargin } from "../../Loader";
import { StartupDealflowBreadcrumb } from "./StartupDealflowBreadcrumb";
import { RowName, StartupOnboardsRowList } from "./StartupOnboardsRowList";
import { Tabs } from "../Network";

const first = 12;

const search = new URLSearchParams({ [S.network.queryVarNames.tab]: Tabs.Startups }).toString();
const linksTo = `${S.network.path}?${search}`;

export const StartupDealflow = () => {
  const sessionState = useSessionState();
  const { roleId } = requireEnrolled(sessionState);
  const breadcrumbApi = useBreadcrumbApi();

  const queryVars: Variables = useMemo(() => {
    return {
      roleId,
      first,
      type: [EntityType.Organisation],
      kind: [EntityKind.Startup],
    };
  }, [roleId]);
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: queryVars,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    breadcrumbApi.addBody(<StartupDealflowBreadcrumb />);

    return () => breadcrumbApi.removeBody();
  }, [breadcrumbApi]);

  const {
    beingReviewedOnboards,
    preSelectionOnboards,
    generalAssessmentOnboards,
    unsuccessfulOnboards,
  } = useMemo(() => {
    return {
      beingReviewedOnboards: nodesFromEdges(data?.beingReviewed?.edges),
      preSelectionOnboards: nodesFromEdges(data?.preSelection?.edges),
      generalAssessmentOnboards: nodesFromEdges(data?.generalAssessment?.edges),
      unsuccessfulOnboards: nodesFromEdges(data?.unsuccessful?.edges),
    };
  }, [data]);

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  }

  return (
    <div className="StartupDealflow">
      <StartupOnboardsRowList onboards={beingReviewedOnboards} rowName={RowName.BeingReviewed} />
      <StartupOnboardsRowList onboards={preSelectionOnboards} rowName={RowName.Preselection} />
      <StartupOnboardsRowList
        onboards={generalAssessmentOnboards}
        rowName={RowName.GeneralAssessment}
      />
      <div className="StartupDealflow-step-right">
        <span className="StartupDealflow-step-right-wrap-label secondary">Accepted</span>
        <br />
        <span className="StartupDealflow-step-right-description">
          You can find all accepted startups in the Network section of the app.
        </span>
        <br />
        <br />
        <Button as={Link} to={linksTo} basic color="purple">
          Startup Network <Icon name="arrow right" />
        </Button>
        <br />
        <br /> <br />
        <br />
      </div>
      <StartupOnboardsRowList onboards={unsuccessfulOnboards} rowName={RowName.Unsuccessful} />
    </div>
  );
};
