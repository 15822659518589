import { isEnum } from "../../types";
import { Role } from "../../types/role";
import { getLocalStorageItem, LeftMenuStates, LS_KEYS, setLocalStorageItem } from "./state";
import { assertUnreachable } from "../../types";
import { State, isEnrolled } from "./state";

export enum FunctionTypes {
  CBCMember = "CBC_MEMBERS",
}

export const selectedRole = (roles: ReadonlyArray<Role>, roleId: string): Role => {
  const role = roles.find((r) => r.id === roleId);
  if (!role) {
    throw new Error("Impossible state detected.");
  }
  return role;
};

export const cleanLeftMenuState = () => {
  const leftMenuState = getLocalStorageItem(LS_KEYS.LeftMenu);
  if (isEnum(leftMenuState, LeftMenuStates)) {
    return;
  }
  setLocalStorageItem(LS_KEYS.LeftMenu, LeftMenuStates.Open);
};

export const changeLeftMenuState = () => {
  if (isLeftMenuOpen()) {
    setLocalStorageItem(LS_KEYS.LeftMenu, LeftMenuStates.Closed);
  } else {
    setLocalStorageItem(LS_KEYS.LeftMenu, LeftMenuStates.Open);
  }
};

export const isLeftMenuOpen = () => getLocalStorageItem(LS_KEYS.LeftMenu) === LeftMenuStates.Open;
export const roleHasFunction = (sessionState: State, functionType: FunctionTypes) => {
  if (!isEnrolled(sessionState)) {
    return false;
  }
  const role = selectedRole(sessionState.user.roles, sessionState.roleId);
  switch (functionType) {
    case FunctionTypes.CBCMember:
      return !!role.extraAttributes?.cbc_member_at;
  }
  assertUnreachable(functionType);
};
