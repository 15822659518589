import { gql } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { ReceivedOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation OpenOnboard($input: OpenOnboardInput!) {
    payload: openOnboard(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

export interface Variables {
  readonly input: OpenOnboardInput;
}

export interface OpenOnboardInput {
  readonly onboardId: string;
  readonly accept: boolean;
}

export interface Result {
  readonly payload?: { readonly onboard: ReceivedOnboard };
}
