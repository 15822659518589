import { Button } from "semantic-ui-react";
import { useSwitchNetwork } from "../../hooks/useSwitchNetwork";

export const InvalidNetworkWall = () => {
  const { isLoading, onSwitchNetwork } = useSwitchNetwork();

  return (
    <div className="InvalidNetworkWall">
      <div className="InvalidNetworkWall-sub-title-purple">
        Please click on the below button to connect your wallet to the Consilience Ventures Private
        Chain
      </div>
      <div className="InvalidNetworkWall-switchNetwork">
        <Button primary onClick={onSwitchNetwork} loading={isLoading}>
          Connect to Chain
        </Button>
      </div>
    </div>
  );
};
