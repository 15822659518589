import { useState, useReducer, useMemo, useEffect, PropsWithChildren } from "react";
import { provideDependencies } from "react-magnetic-di";
import { Image, Modal } from "semantic-ui-react";
// Our stuff.
import { Actioner } from "./actioner";
import { Context } from "./Context";
import { createInitialState, reducer, State, StateTypes } from "./state";
import signatureIcon from "../../assets/signature-icon.svg";

export const ChainProvider = ({ children }: PropsWithChildren<{}>) => {
  const { initialState, isDone } = ChainProvider.dependencies();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [done, setDone] = useState(isDone);
  const api = useMemo(() => new Actioner(dispatch), [dispatch]);
  const isOpen = state.type === StateTypes.Linked && state.busy;

  useEffect(() => {
    if (!done) {
      setDone(true);
      api.addOnAccountsChangedListener();
      api.onChainChanged();
      api.connect(state);
    }
  }, [api, done, state]);

  const providerValue = useMemo(() => ({ api, state }), [api, state]);

  return (
    <Context.Provider value={providerValue}>
      <Modal className="SignaturePaywall" open={isOpen} trigger={children}>
        <Image className="SignaturePaywall-icon" src={signatureIcon} alt="pencil" />
        <h2>Please provide your digital signature</h2>
        <p>
          You can provide your digital signature through the in-browser pop-up enabled by your
          digital wallet of choice.
        </p>
        <a href="mailto:help@consilienceventures.com">Having trouble?</a>
        <div className="SignaturePaywall-progress" />
      </Modal>
    </Context.Provider>
  );
};

export interface Dependencies {
  readonly isDone: boolean;
  readonly initialState: State;
}

ChainProvider.dependencies = provideDependencies<Dependencies>({
  initialState: createInitialState("0"),
  isDone: false,
});
