import { useChainState } from "../../contexts/Chain";
import { InvalidNetworkWall } from "./InvalidNetworkWall";
import { NoMetamaskWall } from "./NoMetamaskWall";
import { PromiseFailureTutorial } from "./tutorials/PromiseFailure";
import { UnknownErrorTutorial } from "./tutorials/UnknownError";
import { ErrorType } from "../../contexts/Chain/state";
import { LoaderWithMargin } from "../Loader";

export const EthereumWall = () => {
  const state = useChainState();
  const { lastError, busy } = state;

  if (busy || !lastError) {
    return busy ? (
      <LoaderWithMargin content="If this operation is taking too long, check for notification(s) on your Metamask extension." />
    ) : null;
  }

  const { type, errorMessage } = lastError;
  if (type === ErrorType.InvalidNetwork) {
    return <InvalidNetworkWall />;
  } else if (type === ErrorType.NoEthereum) {
    return <NoMetamaskWall />;
  } else if (
    type === ErrorType.ProviderCall &&
    errorMessage?.startsWith("User rejected the request")
  ) {
    return <PromiseFailureTutorial />;
  } else {
    console.warn(lastError);
    return <UnknownErrorTutorial />;
  }
};
