import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { EntityType, OnboardState } from "../../types/onboard";
import { CBCExpertFiltersSchema } from "./_types";

const schema: JSONSchemaType<CBCExpertFiltersSchema> = {
  type: "object",
  properties: {
    fullName: { type: "string", maxLength: 300 },
    state: { type: "array", items: { type: "string", enum: Object.values(OnboardState) } },
    type: { type: "array", items: { type: "string", enum: Object.values(EntityType) } },
  },
  required: [],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
