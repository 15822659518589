import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { EntityKind, EntityType } from "../../types/onboard";
import { utils } from "../../utils/utils";
import { globalDefinitions } from "../_definitions";
import { BusinessCases } from "./_types";

export interface ExpertBaseInviteType {
  readonly type: EntityType.Individual;
  readonly kind: EntityKind.Provider;
  readonly firstName: string;
  readonly lastName: string;
  readonly recipientEmail: string;
  readonly expectedToParticipate: boolean | null;
  readonly experienceGeography: string[] | null;
  readonly exampleOfValue: string | null;
  readonly businessCase: BusinessCases;
}

const schema: JSONSchemaType<ExpertBaseInviteType> = {
  type: "object",
  properties: {
    type: { type: "string", enum: [EntityType.Individual] },
    kind: { type: "string", enum: [EntityKind.Provider] },
    firstName: { ...globalDefinitions.nonEmptyString, minLength: 2, maxLength: 50 },
    lastName: { ...globalDefinitions.nonEmptyString, minLength: 2, maxLength: 50 },
    recipientEmail: globalDefinitions.email,
    expectedToParticipate: { type: "boolean" },
    experienceGeography: {
      type: "array",
      items: globalDefinitions.countries,
      minItems: 1,
      maxItems: 10,
    },
    exampleOfValue: { ...globalDefinitions.nonEmptyString, maxLength: 500 },
    businessCase: { type: "string", enum: Object.values(BusinessCases) },
  },
  required: ["type", "kind", "firstName", "lastName", "recipientEmail", "businessCase"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
