import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { Connection, ConnectionVariables } from "../../types/relay";
import { RoleSort } from "../../types/roleSort";

export const QUERY = gql`
  query experts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sortBy: [RoleSort!]
    $fulltext: String
    $occupations: [String!]
    $readyToInvest: Boolean
    $sectors: [String!]
    $skills: [String!]
    $maturities: [String!]
  ) {
    expertsConnection: experts(
      after: $after
      before: $before
      first: $first
      last: $last
      sortBy: $sortBy
      fulltext: $fulltext
      occupations: $occupations
      readyToInvest: $readyToInvest
      sectors: $sectors
      skills: $skills
      maturities: $maturities
    ) {
      edges {
        node {
          id
          fullName
          avatarUrl
          canInvest
          followedBy
          insertedAt
          profile {
            ... on IndividualProfile {
              id
              headline
              linkedinProfileUrl
            }
            ... on OrganisationProfile {
              id
              headline
              linkedinProfileUrl
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export interface Filters {
  readonly sortBy?: RoleSort;
  readonly fulltext?: string;
  readonly readyToInvest?: boolean;
  readonly sectors?: string[];
  readonly skills?: string[];
  readonly occupations?: string[];
  readonly maturities?: string[];
}

export interface Variables extends Filters, ConnectionVariables {}

export interface Expert extends Identifiable {
  readonly fullName: string;
  readonly avatarUrl: string;
  readonly canInvest: boolean;
  readonly followedBy: boolean;
  readonly insertedAt: string;
  readonly profile: {
    readonly headline: string;
    readonly linkedinProfileUrl?: string;
  } & Identifiable;
}

export interface Result {
  readonly expertsConnection?: Connection<Expert>;
}
