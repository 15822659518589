import { Image } from "semantic-ui-react";
import { isProviderOnboard } from "../../../../../../types/onboard";
import { ReceivedOnboard } from "../../../../../../types/onboard";
import { StartupUpvote } from "./StartupUpvote";
import blueArrow from "../../../../../../assets/blue-arrow.svg";
import todorImg from "../../../../../../assets/todor-avatar.png";
import claireImg from "../../../../../../assets/claire-avatar.png";
import deepakImg from "../../../../../../assets/deepak-avatar.png";
import mathieuImg from "../../../../../../assets/mathieu-avatar.png";
import stephenImg from "../../../../../../assets/stephen-avatar.png";
import oksanaImg from "../../../../../../assets/oksana-avatar.png";

const deepakLinkedin = "https://www.linkedin.com/in/deepak-kotak/";
const claireLinkedin = "https://www.linkedin.com/in/claire-button-44248584/";
const mathieuLinkedin = "https://www.linkedin.com/in/mcharleux/";
const todorLinkedin = "https://www.linkedin.com/in/todor/";
const stephenLinkedin = "https://www.linkedin.com/in/stephenminger/";
const oksanaLinkedin = "https://www.linkedin.com/in/oksana-stowe/";

const deepakText =
  "Passionate about development, value creation and commercialisation of healthcare products and technologies. Experience across a range of life science domains in biotech, medtech, AI, digital and consumer health in a variety of executive and advisory roles. Good understanding of clinical medicine and many specialities; has been a consultant in critical care medicine and anaesthesia in UK teaching hospitals. ";
const claireText =
  "Following her PhD in Immunopharmacology and time as a Research Scientist, Claire moved into executive search in life sciences in 2008. She brings deep sector knowledge, coupled with a commercial understanding and global network to introduce my clients to high-demand leaders ahead of their competitors – enabling them to succeed in a dynamic, competitive sector.";
const stephenText =
  "Dr Stephen Minger was previously an Adviser to the Progress Educational Trust (PET), and is Director of Research and Development for Cell Technologies at General Electric Healthcare. He is also Stem Cell Expert and Member of the Gene Therapy Advisory Committee at the UK Government's Department of Health, Cofounder of the London Regenerative Medicine Network, and a Member of the Advisory Panel of the California Institute of Regenerative Medicine. ";
const mathieuText =
  "Mathieu Charleux has extensive experience (19+ years) in building significant international operations from the ground-up, including managing business development, staffing and execution, with a successful track record in building value for medical device start-ups and mid-size companies in an international environment.";
const todorText =
  "Technologist and entrepreneur with over two decades experience as software engineer and architect, currently applying AI and blockchain technologies in disruptive business models. I have 25+ years of experience as software engineer and architect. I'm running a boutique software consultancy company that works with bootstrapping founders on deep tech ventures. ";
const oksanaText =
  "Passionate about inclusion, purpose and positive impact investing. Partner at Redrice Ventures, early-stage investment fund focused on premium consumer brands and related technology companies. Prior to Redrice, I lead early-stage investing team at True and have been focused on consumer products and retail technology companies.";

interface Props {
  readonly onboard: ReceivedOnboard;
}

export const Upvote = ({ onboard }: Props) => {
  if (isProviderOnboard(onboard)) {
    return (
      <div className="Pre-Completed">
        <div className="Pre-Completed-top-container">
          <Image src={blueArrow} />
          <div className="Pre-Completed-title">What to expect</div>
          <div className="Pre-Completed-title-sub-text">
            We'll share your profile with our members in order to find 2 suitable individuals to
            conduct
            <br /> your Mutual Assessment. A member of our team will reach out to you via email once
            we
            <br /> are ready to host the call.
          </div>
          <br />

          <div className="Pre-Completed-text-lists">
            <div className="Pre-Completed-text-lists-list">
              <div className="Pre-Completed-text-lists-item">
                <div>
                  The Mutual assessment (MA) is a chance for you to discuss any questions you may
                  have about CV directly with existing members. At the same time, our members will
                  evaluate your application primarily to ensure alignment with the CV ecosystem in
                  terms of <b>culture</b>, <b>skill set</b>, and
                </div>
              </div>
            </div>
            <div className="Pre-Completed-text-lists-list">
              <div className="Pre-Completed-text-lists-item">
                <div>
                  <b>experience with innovation</b>. The MA should last for about 45-60 minutes.
                </div>
              </div>
              <div className="Pre-Completed-text-lists-item">
                <div>
                  At the end of the MA, our members will share their evaluation with our Community
                  Building Committee, who will vote regarding the final outcome of your application.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Pre-Completed-bottom-container">
          <div className="Pre-Completed-title">
            These are some of the community members who you may
            <br /> speak with
          </div>
          <br />
          <br />
          <div className="Pre-Completed-members-list">
            <MemberItem
              img={deepakImg}
              name="Deepak Kotak"
              linkedin={deepakLinkedin}
              text={deepakText}
            />
            <MemberItem
              img={claireImg}
              name="Claire Button"
              linkedin={claireLinkedin}
              text={claireText}
            />
            <MemberItem
              img={stephenImg}
              name="Stephen Minger"
              linkedin={stephenLinkedin}
              text={stephenText}
            />
            <MemberItem
              img={mathieuImg}
              name="Mathieu Charleux"
              linkedin={mathieuLinkedin}
              text={mathieuText}
            />
            <MemberItem
              img={todorImg}
              name="Todor Kolev"
              linkedin={todorLinkedin}
              text={todorText}
            />
            <MemberItem
              img={oksanaImg}
              name="Oksana Stowe"
              linkedin={oksanaLinkedin}
              text={oksanaText}
            />
          </div>
        </div>
      </div>
    );
  }

  return <StartupUpvote onboard={onboard} />;
};

interface MenberItemProps {
  readonly img: string;
  readonly name: string;
  readonly linkedin: string;
  readonly text: string;
}

const MemberItem = ({ img, name, linkedin, text }: MenberItemProps) => (
  <div className="Pre-Completed-members-list-item">
    <Image src={img} circular />
    <div className="Pre-Completed-members-list-item-title">{name}</div>
    <a href={linkedin}>Linkedin</a>
    <div>{text}</div>
  </div>
);
