import { Link } from "react-router-dom";
import { Shortcuts as S } from "../../../routing";
import { utils } from "../../../utils/utils";

export const StartupDealflowBreadcrumb = () => (
  <div className="StartupDealflow-breadcrumb">
    <h2>Startup Dealflow</h2>
    <span>
      Startup applicants go through two stages of due dilligence. Startup applicants which are
      successful are part of our portfolio.
    </span>
    <br />
    <div className="StartupDealflow-breadcrumb-links">
      <Link to={S.portfolio.path}>View Startup Portfolio</Link>
      <a
        href={`${utils.apiHost}/static/files/investment-thesis.pdf`}
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        Review our Investment Thesis
      </a>
    </div>
  </div>
);
