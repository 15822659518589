import React from "react";
import { Progress } from "semantic-ui-react";
import { MINIMUM_ENTROPY, shannonEntropy } from "../../utils/ShannonEntropy";

interface Props {
  readonly password: string;
}
export const PasswordProgressBar = ({ password }: Props) => {
  const strenghtPercentage = (shannonEntropy(password) * 100) / MINIMUM_ENTROPY;

  return (
    <Progress
      percent={strenghtPercentage}
      indicating
      content={`Password strength: ${strenghtLabelFromPercentage(strenghtPercentage)}`}
    />
  );
};

const strenghtLabelFromPercentage = (percentage: number): string => {
  if (percentage < 40) {
    return "Very weak";
  } else if (percentage < 60) {
    return "Weak";
  } else if (percentage < 80) {
    return "Reasonable";
  } else if (percentage < 100) {
    return "Strong";
  } else {
    return "Very strong";
  }
};
