import React, { useState, ChangeEvent, useRef, useCallback } from "react";
import { Form, Header, Message, Segment } from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { Queryable, isEmpty, ApiError, extractErrorMessages } from "../../../types";
import { utils } from "../../../utils/utils";
import { perform } from "../../../api/accounts/InitiatePasswordReset";

enum FormIds {
  Email = "@InitiatePasswordReset/Email",
}

interface State extends Queryable {
  readonly email: string;
}
const initialState: State = { email: "" };

export const InitiatePasswordReset = () => {
  const client = useApolloClient();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [{ email, busy }, setState] = useState(initialState);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!recaptchaRef.current) {
      return toast.error("Something went wrong with reCAPTCHA. Please refresh our page and retry.");
    }

    setState(s => ({ ...s, busy: true }));
    recaptchaRef.current.execute();
  };

  const onRecaptchaExpired = useCallback(() => {
    if (!recaptchaRef.current) {
      return toast.error("Failed to reset expired reCAPTCHA. Please refresh our page and retry.");
    }
    recaptchaRef.current.reset();
  }, []);

  const onRecaptchaChange = useCallback(
    (captcha: string | null) => {
      if (!captcha) {
        toast.error("Missing reCAPTCHA token. Please refresh our page and retry.");
        return setState(s => ({ ...s, busy: false }));
      }

      const c = utils.isDevelopmentEnv() ? "VALID_CAPTCHA" : captcha;
      perform(client, { emailAddress: email, captcha: c })
        .then(res => {
          setState(initialState);
          if (res.data?.payload?.success) {
            toast.success("Instructions sent.");
          } else {
            toast.error("Failed to initiate password reset.");
          }
        })
        .catch((error: ApiError) => {
          setState(s => ({ ...s, busy: false }));

          toast.error(extractErrorMessages(error).join());
        })
        // Must reset the captcha.
        .finally(() => onRecaptchaExpired());
    },
    [client, email, onRecaptchaExpired]
  );

  const onEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setState(s => ({ ...s, email: utils.formatEmailInput(value) }));

  return (
    <>
      <Segment attached>
        <Header textAlign="center" content="Initiate Password Reset" />
        <Form onSubmit={onSubmit}>
          <Form.Group inline>
            <Form.Input
              id={FormIds.Email}
              input="email"
              width="10"
              maxLength="255"
              label="Email"
              value={email}
              disabled={busy}
              onChange={onEmailChange}
            />
            <Form.Button
              primary
              type="submit"
              loading={busy}
              disabled={busy || isEmpty(email)}
              data-testid="resetBtn"
            >
              Send
            </Form.Button>
          </Form.Group>
        </Form>
        <ReCAPTCHA
          size="invisible"
          badge="inline"
          ref={recaptchaRef}
          sitekey={utils.captchaKey}
          onChange={onRecaptchaChange}
          onExpired={onRecaptchaExpired}
        />
      </Segment>
      <Message warning attached>
        We will send password reset instructions to the email address above. Make sure to check your
        spam folder too!
      </Message>
    </>
  );
};
