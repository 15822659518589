import { PropsWithChildren, useReducer, useMemo } from "react";
import { initialState, reducer } from "./state";
import { Actioner } from "./actioner";
import { Context } from "./Context";

export const BreadcrumbProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useMemo(() => new Actioner(dispatch), [dispatch]);
  const providerValue = useMemo(() => ({ api, state }), [api, state]);

  return <Context.Provider value={providerValue} children={children} />;
};
