import { useMemo, CSSProperties } from "react";
import { Divider, Popup, Icon, Segment } from "semantic-ui-react";
import { Card, CardContent, CardHeader, CardDescription, Image } from "semantic-ui-react";
import { DeepPartial } from "uniforms";
import dateFormat from "dateformat";
import { TicketAnswer, TicketPaymentMethods } from "../../../schemas/tickets/_enums";
import { computeCostFields, TicketSchema } from "../../../schemas/tickets/_types";
import { isDefined } from "../../../types";
import { revertFromChainBigInt } from "../../../contexts/Chain/helpers";
import { TicketState } from "../../../types/ticket";

const inlineStyle: CSSProperties = {
  display: "inline-flex",
};

export interface TicketForSummary {
  readonly state: TicketState;
  readonly insertedAt?: string;
  readonly transmittedAt?: string;
  readonly ticketForm?: {
    readonly data: TicketSchema | DeepPartial<TicketSchema>;
  };
  readonly draft?: {
    readonly insertedAt?: string;
    readonly updatedAt?: string;
    readonly form?: { readonly data: DeepPartial<TicketSchema> };
  };
  readonly owner: { readonly fullName: string; readonly avatarUrl: string };
}

interface Props {
  readonly ticket: TicketForSummary;
  readonly hasInsufficientFunds?: boolean;
}

export const TicketSummary = (props: Props) => {
  const { ticket, hasInsufficientFunds } = props;
  const { state, owner } = ticket;
  const data: TicketSchema | DeepPartial<TicketSchema> = useMemo(
    () => (state === TicketState.Draft ? ticket.draft?.form?.data : ticket.ticketForm?.data) || {},
    [state, ticket]
  );
  const { assurance: a, daysOfWork, maxRatePerDay, minRatePerDay, paymentMethod } = data;
  const { cvdsPercentage, fiatPercentage } = data;
  const computedFields = computeCostFields(data);
  const { minRawCost, maxRawCost, minAssuranceCost } = computedFields;
  const { maxAssuranceCost, minFinalCost, maxFinalCost } = computedFields;
  const hasAssurance = a === TicketAnswer.Yes;

  const savedOnText =
    state === TicketState.Draft && ticket.draft?.updatedAt
      ? `Saved on ${dateFormat(ticket.draft.updatedAt, "HH:MM | dd/mm/yy")}`
      : null;

  const createdOnText =
    state === TicketState.Draft && ticket.insertedAt
      ? `Created on ${dateFormat(ticket.insertedAt, "HH:MM | dd/mm/yy")}`
      : null;

  const publishedOnText =
    state !== TicketState.Draft && ticket.transmittedAt
      ? `Published on ${dateFormat(ticket.transmittedAt, "HH:MM | dd/mm/yy")}`
      : null;

  if (
    !daysOfWork ||
    !minRatePerDay ||
    !maxRatePerDay ||
    !minRawCost ||
    !maxRawCost ||
    !minFinalCost ||
    !maxFinalCost
  ) {
    return null;
  }

  return (
    <Card as={Segment} className="TicketSummary">
      <CardContent>
        <CardHeader className="TicketSummary-title">Ticket Summary</CardHeader>
        <br />
        <div>
          <CardDescription />
          <Image
            className="AvatarPicture"
            floated="left"
            size="mini"
            src={owner.avatarUrl}
            circular
          />
          <div className="TicketSummary-user">{owner.fullName || "Unknown User"}</div>
          {publishedOnText && (
            <CardDescription className="TicketSummary-date">{publishedOnText}</CardDescription>
          )}
          {createdOnText && (
            <CardDescription className="TicketSummary-date">{createdOnText}</CardDescription>
          )}
          {savedOnText && (
            <CardDescription className="TicketSummary-date">{savedOnText}</CardDescription>
          )}
        </div>
        <br />
        <p />
        {hasInsufficientFunds && (
          <div className="warning">
            In order to publish this ticket, you need to top up your wallet with more CVDS. Please
            contact{" "}
            <a href="mailto:finance@consilienceventures.com">finance@consilienceventures.com</a>
          </div>
        )}
        <CardDescription className="TicketSummary-description">
          This is the calculation of the total cost of your ticket based on what you have told us.
        </CardDescription>
        <p />
        <CardDescription className="TicketSummary-descriptions">
          <div>Work days</div>
          <div className="right-bold">&nbsp;{daysOfWork}</div>
        </CardDescription>
        <CardDescription className="TicketSummary-descriptions">
          <div>Cost per day</div>
          <div className="right-bold">
            &nbsp;
            {`*${minRatePerDay.toFixed(2)} - ${maxRatePerDay.toFixed(2)} CVDS`}
          </div>
        </CardDescription>
        <CardDescription className="TicketSummary-descriptions">
          <div>Min delivery cost</div>
          <div className="right-bold">&nbsp;{`*${revertFromChainBigInt(minRawCost, 0)} CVDS`}</div>
        </CardDescription>
        <CardDescription className="TicketSummary-descriptions">
          <div>Max delivery cost</div>
          <div className="right-bold">&nbsp;{`*${revertFromChainBigInt(maxRawCost, 0)} CVDS`}</div>
        </CardDescription>
        {hasAssurance && isDefined(minAssuranceCost) && isDefined(maxAssuranceCost) && (
          <CardDescription className="TicketSummary-descriptions">
            <div>
              Quality Control{" "}
              <Popup
                trigger={<Icon name="info circle" />}
                content="5% of total cost"
                size="small"
                position="right center"
              />
            </div>
            <div className="right-bold">
              &nbsp;
              {`*${revertFromChainBigInt(minAssuranceCost)}-${revertFromChainBigInt(
                maxAssuranceCost
              )} CVDS`}
            </div>
          </CardDescription>
        )}
        <Divider className="TicketSummary-ticket-divider" />
        <CardDescription className="TicketSummary-descriptions">
          <div>Min total ticket cost</div>
          <div className="right-bold">
            &nbsp;
            {`*${revertFromChainBigInt(minFinalCost)} CVDS`}
          </div>
        </CardDescription>
        <CardDescription className="TicketSummary-descriptions">
          <div>Max total ticket cost</div>
          <div className="right-bold">
            &nbsp;
            {`*${revertFromChainBigInt(maxFinalCost)} CVDS`}
          </div>
        </CardDescription>
      </CardContent>

      {paymentMethod === TicketPaymentMethods.FiatAndCVDS && (
        <div className="TicketSummary-cvds-price">
          <CardDescription className="TicketSummary-descriptions">
            <div style={inlineStyle}>
              <div>Cash %</div>
              <div className="right-bold">
                &nbsp;&nbsp;{fiatPercentage ? `${fiatPercentage}%` : ""}
              </div>
            </div>
            <div style={inlineStyle}>
              <div>CVDS %</div>
              <div className="right-bold">
                &nbsp;&nbsp;{cvdsPercentage ? `${cvdsPercentage}%` : ""}
              </div>
            </div>
          </CardDescription>
        </div>
      )}
    </Card>
  );
};
