import { CSSProperties } from "react";
import { Loader, LoaderProps } from "semantic-ui-react";

const loaderStyle: CSSProperties = { marginTop: "30px", minHeight: "40px", width: "100%" };

export const LoaderWithMargin = (props: LoaderProps) => {
  return (
    <div style={loaderStyle}>
      <Loader {...props} active inline="centered" />
    </div>
  );
};
