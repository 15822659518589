import { useEffect } from "react";
import { decode } from "hi-base32";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useSessionApi } from "../../contexts/Session";

export const LinkedInCallback = () => {
  const location = useLocation();
  const sessionApi = useSessionApi();
  const result = new URLSearchParams(location.search).get("result");

  useEffect(() => {
    if (!result) {
      toast.error("Something went wrong.");
      console.warn("Missing `result` param in the redirect url.");
    } else {
      try {
        const decodedRes = decode(result, true);
        const parsedRes = JSON.parse(decodedRes);
        if (parsedRes.bearerToken && typeof parsedRes.bearerToken === "string") {
          sessionApi.setToken(parsedRes.bearerToken);
        } else {
          toast.error("Something went wrong.");
          console.warn("No error and no known fields found inside LinkedIn redirect.");
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    }

    if (window && window.opener) {
      window.opener.postMessage({ id: "LinkedInOAuth", search: location.search }, "*");
      window.close();
    } else {
      toast.error("Something went wrong.");
      console.warn("Missing window or window.opener.", window);
    }
  }, [result, sessionApi, location]);

  return null;
};
