import { useCallback, useMemo, MouseEventHandler } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { StartupDetailsTab } from "../../../../Startups/StartupDetailsTab";
import { isEnum } from "../../../../../../types";
import { PreselectionDiscussionTab } from "../../../../Startups/PreselectionDiscussionTab";
import { OnboardState, ReceivedOnboard } from "../../../../../../types/onboard";
import { AwaitingVoteApproval } from "./AwaitingVoteApproval";

const tabVarName = "tab";

export enum Tabs {
  General = "general",
  Details = "details",
  Discussion = "discussion",
}

interface Props {
  readonly onboard: ReceivedOnboard;
}
export const StartupUpvote = ({ onboard }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { state } = onboard;

  const awaitingEnableVoting = useMemo(() => state === OnboardState.AwaitingEnableVoting, [state]);

  const selectedTab = useMemo(() => {
    const tab = new URLSearchParams(location.search).get(tabVarName);
    // Replaces bad tab names on the url.
    if (isEnum(tab, Tabs)) {
      return tab;
    } else if (awaitingEnableVoting) {
      return Tabs.General;
    } else {
      return Tabs.Details;
    }
  }, [location, awaitingEnableVoting]);

  const onTabClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      const newTab = ev.currentTarget.id;
      if (isEnum(newTab, Tabs)) {
        const newSearch = new URLSearchParams(location.search);
        newSearch.set(tabVarName, newTab);
        return history.replace({ search: newSearch.toString() });
      }
    },
    [history, location]
  );

  const generalTabClassname = `PageContentTabs-tab${
    selectedTab === Tabs.General ? " selected" : ""
  }`;
  const detailsTabClassname = `PageContentTabs-tab${
    selectedTab === Tabs.Details ? " selected" : ""
  }`;
  const discussionTabClassname = `PageContentTabs-tab${
    selectedTab === Tabs.Discussion ? " selected" : ""
  }`;

  return (
    <>
      <div className="PageContentTabs">
        {awaitingEnableVoting && (
          <div id={Tabs.General} className={generalTabClassname} onClick={onTabClick}>
            General
          </div>
        )}
        <div id={Tabs.Details} className={detailsTabClassname} onClick={onTabClick}>
          Application
        </div>
        {!awaitingEnableVoting && (
          <div id={Tabs.Discussion} className={discussionTabClassname} onClick={onTabClick}>
            Discussion
          </div>
        )}
      </div>
      <Grid>
        {/* DETAILS COLUMN */}
        <Grid.Column>
          {awaitingEnableVoting && selectedTab === Tabs.General && <AwaitingVoteApproval />}
          {selectedTab === Tabs.Details && <StartupDetailsTab onboard={onboard} />}
          {selectedTab === Tabs.Discussion && (
            <PreselectionDiscussionTab onboard={onboard} view="my-application" />
          )}
        </Grid.Column>
      </Grid>
    </>
  );
};
