import { gql, ApolloClient } from "@apollo/client";

export const QUERY = gql`
  query DownloadUrl($nodeId: ID!) {
    result: node(id: $nodeId) {
      ... on Document {
        downloadUrl
      }

      ... on Attachment {
        downloadUrl
      }
    }
  }
`;

interface Variables {
  readonly nodeId: string;
}

export interface Result {
  readonly result?: { readonly downloadUrl?: string };
}

export const perform = (client: ApolloClient<any>, nodeId: string) =>
  client.query<Result, Variables>({ query: QUERY, variables: { nodeId } });
