import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { RECEIVED_ONBOARD_CONNECTION_FRAGMENT } from "../_fragments/Onboard";
import { OnboardStatus, EntityType, GQLAdminOnboard } from "../../types/onboard";
import { EntityKind, OnboardAs, OnboardState } from "../../types/onboard";
import { LINKEDEMAIL_CONNECTION_FRAGMENT } from "../_fragments/LinkedEmail";
import { SeconderCandidacy } from "../../types/seconderCandidacy";
import { ADMIN_SECONDER_CANDIDACY_FRAGMENT } from "../_fragments/AdminSeconderCandidacy";

export const QUERY = gql`
  query AdminOnboards(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $as: OnboardAs
    $fullName: String
    $kind: [EntityKind!]
    $recipientId: ID
    $senderId: ID
    $state: [OnboardState!]
    $status: [OnboardStatus!]
    $tags: [LabelQuery!]
    $type: [EntityType!]
  ) {
    onboards(
      after: $after
      before: $before
      first: $first
      last: $last
      as: $as
      fullName: $fullName
      kind: $kind
      recipientId: $recipientId
      senderId: $senderId
      state: $state
      status: $status
      tags: $tags
      type: $type
    ) {
      ...ReceivedOnboardConnection
      edges {
        cursor
        node {
          recipient {
            id
            fullName
            isAdmin
            primaryLinkedEmail {
              ...LinkedEmail
            }
            linkedEmails(first: 10) {
              ...LinkedEmailConnection
            }
          }
          sender {
            id
            fullName
            isAdmin
            primaryLinkedEmail {
              ...LinkedEmail
            }
            linkedEmails(first: 10) {
              ...LinkedEmailConnection
            }
          }

          extraFields {
            ... on Expert {
              volunteerSeconderCandidacies: seconderCandidacies(state: VOLUNTEERED) {
                ...AdminSeconderCandidacy
              }
              assignedSeconderCandidacies: seconderCandidacies(state: ASSIGNED) {
                ...AdminSeconderCandidacy
              }
            }
          }
        }
      }
    }
  }
  ${RECEIVED_ONBOARD_CONNECTION_FRAGMENT}
  ${LINKEDEMAIL_CONNECTION_FRAGMENT}
  ${ADMIN_SECONDER_CANDIDACY_FRAGMENT}
`;

export interface Filters {
  readonly as?: OnboardAs;
  readonly fullName?: string;
  readonly kind?: ReadonlyArray<EntityKind>;
  readonly recipientId?: string;
  readonly senderId?: string;
  readonly state?: ReadonlyArray<OnboardState>;
  readonly status?: ReadonlyArray<OnboardStatus>;
  readonly type?: ReadonlyArray<EntityType>;
}

export interface Variables extends Filters, ConnectionVariables {}

export interface AdminOnboardWithSeconderCandidacies extends GQLAdminOnboard {
  readonly extraFields: {
    readonly volunteerSeconderCandidacies?: ReadonlyArray<SeconderCandidacy>;
    readonly assignedSeconderCandidacies?: ReadonlyArray<SeconderCandidacy>;
  };
}

export interface Result {
  readonly onboards?: Connection<AdminOnboardWithSeconderCandidacies>;
}
