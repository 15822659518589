import { gql } from "@apollo/client";

export const SEAL_FRAGMENT = gql`
  fragment Seal on Seal {
    address
    message
    publicKey
    receiptMessage
    receiptSignature
    receivedAt
    signature
  }
`;
