import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation Follow($input: FollowInput!) {
    payload: follow(input: $input) {
      follow {
        id
        followable {
          ... on TickettingTicket {
            id
            followedBy
          }
          ... on Role {
            id
            followedBy
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: FollowInput;
}

interface FollowInput {
  readonly comment?: string;
  readonly followableId: string;
}

export interface Result {
  readonly payload: FollowPayload;
}

interface FollowPayload {
  readonly follow: Identifiable;
  readonly followable: { readonly followedBy: boolean } & Identifiable;
}
