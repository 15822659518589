import React, { useCallback } from "react";
import { Button, Container, Modal } from "semantic-ui-react";

interface Props {
  readonly onGeneralClose: () => void;
  readonly changeModal: (isBack: boolean) => void;
  readonly hasEval: boolean;
}

export const ModalAcceptBid = ({ onGeneralClose, changeModal, hasEval }: Props) => {
  const handleClick = useCallback(() => {
    changeModal(false);
  }, [changeModal]);

  return (
    <Modal
      className="CustomClose"
      onClose={onGeneralClose}
      open={true}
      size="mini"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>Before you continue</Modal.Header>
      <Modal.Content>
        <p>By accepting this bid, you will decline all other bids on this ticket.</p>
        {!hasEval && (
          <p>
            You will now be prompted to provide an evaluation on this bid, followed by two signature
            requests. The first request is to sign for the bid evaluation, while the second is for
            the acceptance of this bid.
          </p>
        )}
        <Container textAlign="right">
          <Button primary onClick={handleClick}>
            Okay
          </Button>
        </Container>
      </Modal.Content>
    </Modal>
  );
};
