import React, { useRef } from "react";
import { useMutation } from "@apollo/client";
import { CSSProperties, SetStateAction, useCallback, useState } from "react";
import { FormField, FormGroup, Image } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import attachIcon from "../../assets/attach-icon.svg";
import { utils } from "../../utils/utils";
import { InputFieldCharCount } from "./InputFieldCharCount";
import { MUTATION, Variables, Result } from "../../api/documents/CreateDocument";
import { Document } from "../../types/document";
import { ApiError, extractErrorMessages, isDefined } from "../../types";
import { DocumentBadge } from "./DocumentBadge";

const formStyle: CSSProperties = { width: "100%" };

export interface Fields {
  readonly text?: string;
  readonly documents?: ReadonlyArray<Document>;
}

interface Props {
  readonly fields: Fields;
  readonly onChange: React.Dispatch<SetStateAction<Fields>>;
  readonly placeholder?: string;
  readonly maxChars?: number;
  readonly maxDocuments?: number;
  readonly rows?: number;
  readonly error?: string;
}

export const TextAreaWithUploads = (props: Props) => {
  const [focus, setFocus] = useState(false);
  const { fields, onChange, error, maxDocuments = 2, rows = 2 } = props;
  const { placeholder = "Type your message...", maxChars } = props;
  const { text, documents } = fields;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createDocument] = useMutation<Result, Variables>(MUTATION);

  const onChangeInput = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) =>
      onChange((s) => ({ ...s, text: value })),
    [onChange]
  );

  const changeFocus = () => setFocus((s) => !s);
  const handleClick = useCallback(() => fileInputRef.current?.click(), [fileInputRef]);

  // This clears the file input to allow to select the same file again.
  const onFileInputClick: React.MouseEventHandler<HTMLInputElement> = useCallback(
    ({ currentTarget }) => (currentTarget.value = ""),
    []
  );
  const onFileInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { files: fs } }) => {
      if (fs !== null) {
        if ((documents?.length || 0) + fs.length > maxDocuments) {
          return toast.warn(`Upload a maximum of ${maxDocuments} documents.`);
        }
        const requestSize = Object.values(fs).reduce((acc, curr) => acc + curr.size, 0);
        if (requestSize > utils.UPLOAD_MAX_SIZE) {
          const e = "Maximum file size is 16MB.";
          return toast.warn(e);
        }
        const newFiles = Object.values(fs);

        Promise.all(newFiles.map((upload) => createDocument({ variables: { input: { upload } } })))
          .then((res) => {
            const newDocs = res.reduce((acc, curr) => {
              if (curr.data?.payload?.document) {
                return [...acc, curr.data.payload.document];
              }
              return acc;
            }, [] as Document[]);

            onChange((s) => {
              // Removes duplicates.
              const withDups = s.documents ? [...s.documents, ...newDocs] : newDocs;
              const noDups = withDups.reduce((acc, curr) => {
                if (!acc.find((el) => el.id === curr.id)) {
                  return [...acc, curr];
                }
                return acc;
              }, [] as Document[]);
              return { ...s, documents: noDups };
            });
          })
          .catch((err: ApiError) => {
            toast.error(extractErrorMessages(err).join(", "));
          });
      }
    },
    [documents, maxDocuments, onChange, createDocument]
  );

  return (
    <div className="ui form" style={formStyle}>
      <FormGroup>
        <div className="inputStyle">
          <TextareaAutosize
            value={text || ""}
            minRows={rows}
            rows={rows}
            onChange={onChangeInput}
            maxLength={maxChars}
            placeholder={placeholder}
            name="question"
            onFocus={changeFocus}
            onBlur={changeFocus}
          />
          <div className={focus ? "textAreaBorder" : ""}>
            <div className="tooltip" onClick={handleClick}>
              <Image src={attachIcon} verticalAlign="middle" />
              <input
                ref={fileInputRef}
                className="display-none"
                type="file"
                name="question"
                onClick={onFileInputClick}
                onChange={onFileInputChange}
                multiple
              />
            </div>
          </div>
          <span className="hidden-input">
            <FormField control="input" error={error} />
          </span>
        </div>
      </FormGroup>
      {isDefined(maxChars) && text && text.length > 0 && (
        <InputFieldCharCount currentChars={text.length} maxChars={maxChars} />
      )}
      {documents && documents.length > 0 && (
        <div className="DocumentBadgeGroup">
          {documents.map(({ id, uploadedFile }) => {
            const onFileDelete = () => {
              if (documents) {
                const newDocs = documents.filter((d) => d.id !== id);
                onChange((s) => ({ ...s, documents: newDocs }));
              }
            };

            return (
              <DocumentBadge
                key={id}
                documentId={id}
                documentName={uploadedFile.filename}
                onDelete={onFileDelete}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
