import { useMutation } from "@apollo/client";
import dateFormat from "dateformat";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Image, Popup } from "semantic-ui-react";
import { toast } from "react-toastify";
import { MUTATION as unfollowMutation } from "../../../api/followers/unfollow";
import { Result as unfollowRes } from "../../../api/followers/unfollow";
import { Variables as unfollowVars } from "../../../api/followers/unfollow";
import { MUTATION as followMutation } from "../../../api/followers/follow";
import { Result as followRes } from "../../../api/followers/follow";
import { Variables as followVars } from "../../../api/followers/follow";
import followStar from "../../../assets/follow-star.svg";
import unfollowStar from "../../../assets/unfollow-star.svg";
import { ExpertModal } from "./ExpertModal";
import { Expert } from "../../../api/network/experts";

export interface ExpertCardProps {
  readonly expert: Expert;
}

export const ExpertCard = ({ expert }: ExpertCardProps) => {
  const { avatarUrl, canInvest, fullName } = expert;
  const { insertedAt, profile, followedBy, id } = expert;
  const [showModal, setShowModal] = useState(false);
  const [follow] = useMutation<followRes, followVars>(followMutation);
  const [unfollow] = useMutation<unfollowRes, unfollowVars>(unfollowMutation);
  const [followCD, setFollowCD] = useState<boolean>(false);

  useEffect(() => {
    if (followCD) {
      setTimeout(() => {
        setFollowCD(false);
      }, 600);
    }
  }, [followCD]);

  const displayModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const onLinkClick = useCallback((ev) => ev.stopPropagation(), []);

  const handleFollow = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (followCD) {
        return;
      }
      setFollowCD(true);
      const mutation = followedBy ? unfollow : follow;
      const toastMessage = `You have ${followedBy ? "unfollowed" : "followed"} ${fullName}.`;

      mutation({ variables: { input: { followableId: id } } })
        .then(() => {
          toast.dismiss();
          toast.success(toastMessage);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Something went wrong.");
          console.warn(err);
        });
    },
    [follow, unfollow, followedBy, followCD, fullName, id]
  );

  return (
    <>
      <div className="NetworkCard" onClick={displayModal}>
        <div className="NetworkCard-header">
          <Image className="AvatarPicture55" src={avatarUrl} circular />
          <div className="NetworkCard-header-labels">
            {canInvest && (
              <div className="NetworkCard-header-labels-investlabel">Ready to invest</div>
            )}
            <div className="NetworkCard-header-labels-star">
              <Popup
                content={"Follow"}
                trigger={
                  <Image
                    className="top-section-follow"
                    src={followedBy ? unfollowStar : followStar}
                    alt="star"
                    verticalAlign="middle"
                    onClick={handleFollow}
                    disabled={followCD}
                  />
                }
                position="top center"
              />
            </div>
          </div>
        </div>
        <div className="NetworkCard-name">{fullName}</div>
        <div className="NetworkCard-description">
          Joined on {dateFormat(insertedAt, "dd/mm/yy")}
        </div>
        <div className="NetworkCard-description">{profile.headline}</div>
        {profile.linkedinProfileUrl && (
          <div className="NetworkCard-links">
            <a
              className="NetworkCard-links-link"
              href={profile.linkedinProfileUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onLinkClick}
            >
              Linkedin
            </a>
          </div>
        )}
      </div>
      <ExpertModal expert={expert} showModal={showModal} closeModal={closeModal} />
    </>
  );
};
