import { useMemo, useState } from "react";
import { Statistic, Popup, Segment } from "semantic-ui-react";
import { ethers } from "ethersv5";
import { Linked } from "../../../../contexts/Chain/state";

interface Missing {
  readonly present: false;
}
interface Present {
  readonly present: true;
  readonly balance: string;
  readonly frozen: string;
  readonly totalBalance: string;
}
type State = Missing | Present;

const initialState: State = { present: false };

interface Props {
  readonly from: string;
  readonly chainState: Linked;
}

export const Balances = ({ from, chainState }: Props) => {
  const [state, setState] = useState<State>(initialState);

  // Memoized values.
  const token = useMemo(() => chainState.contracts.token, [chainState]);

  useMemo(() => {
    // Get all numbers in one go, and convert them to proper types.
    Promise.all([token.balanceOf(from), token.frozenOf(from)]).then(([sB, sF]) => {
      const [b, f] = [sB, sF].map((v) => ethers.utils.formatUnits(v, 6));
      const tB = ethers.utils.formatUnits(sB.toBigInt() + sF.toBigInt(), 6);
      setState({ present: true, balance: b, frozen: f, totalBalance: tB });
    });
  }, [from, token]);

  const [balance, frozen, totalBalance] = !state.present
    ? Object.keys(Array(3)).map(() => "N/A")
    : [state.balance, state.frozen, state.totalBalance];

  return (
    <Segment>
      <h2>Balances</h2>
      <Statistic.Group size="small" widths="3">
        <Popup
          position="top center"
          trigger={<Statistic label="Available Balance" value={balance} />}
          content="These are funds that are available to spend with other CV members."
        />
        <Popup
          position="top center"
          trigger={<Statistic label="In Transit" value={frozen} />}
          content="These are the funds that are pending transfer. They cannot be spent."
        />
        <Popup
          position="top center"
          trigger={<Statistic label="Current Balance" value={totalBalance} />}
          content={`This is your balance, including those funds currently in transit.
          If a startup exited now, the prorata of your proceeds would be calculated based on this number.`}
        />
      </Statistic.Group>
    </Segment>
  );
};
