import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Image, Modal } from "semantic-ui-react";
import expertIcon from "../assets/expert-circular-icon.svg";
import startupIcon from "../assets/startup-square-icon.svg";
import inviteMemberIcon from "../assets/invite-member-icon.svg";
import { Shortcuts as S } from "../routing";

export const CreateInviteModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <Modal
      className="CustomClose"
      closeIcon
      size="large"
      closeOnDimmerClick={true}
      open={isOpen}
      onClose={handleClose}
      trigger={
        <div className="LeftMenu-InviteSection">
          <Button className="LeftMenu-InviteSection-button" onClick={handleOpen}>
            Invite Member
          </Button>
          <Image
            className="LeftMenu-InviteSection-icon"
            src={inviteMemberIcon}
            onClick={handleOpen}
          />
        </div>
      }
    >
      <div className="CreateInviteModal">
        <div className="CreateInviteModal-left-panel">
          <Image src={expertIcon} />
          <div className="CreateInviteModal-title">Make a member nomination</div>
          <div>
            An expert member is an individual or an organisation that provides services to the
            startup portfolio, eg: Legal firm, PR firm, SW Development firm, etc. An expert may also
            be a potential co-investor with Consilience Ventures.
          </div>
          <br />
          <div>
            <b>Help build a united community of top industry experts.</b>
            <br />
            Experts enjoy working for startups, but also like getting paid their full rate. Startups
            need experts, but can only offer equity or reduced rates. CV brings experts and startups
            together via our shared currency, ultimately allowing experts to access greater
            liquidity and gain exposure to the upside of the full portfolio.
          </div>
          <br />
          <br />
          <Link to={S.nominateExpert.path}>
            <Button color="blue" onClick={handleClose}>
              Nominate Member
            </Button>
          </Link>
        </div>
        <div className="CreateInviteModal-right-panel">
          <Image src={startupIcon} />
          <div className="CreateInviteModal-title">Invite a startup for evaluation</div>
          <div>A startup is a potential investment for Consilience Ventures.</div>
          <br />
          <div>
            <b>
              Give exceptional entrepreneurs the resources and expertise needed to accelerate growth
              - on their own terms.
            </b>
            <br />
            We help founders by removing the need to spend scarce capital to buy services from
            domain experts. CVDS provides access to our network of curated marketing, business
            development, strategy, finance, legal, and technology professionals at the top of their
            game. We accelerate founders' speed to market, minimising dilution and increasing the
            pot of capital available for them to invest into their business.
          </div>
          <br />
          <br />
          <Link to={S.inviteStartup.path}>
            <Button color="blue" onClick={handleClose}>
              Invite Startup
            </Button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};
