import { gql } from "@apollo/client";
import { Identifiable } from "../types";
import { UserWithRoles } from "./UserRoles";

export const QUERY = gql`
  query UserRolesByIds($roleIds: [ID!]!) {
    nodes(ids: $roleIds) {
      ... on Role {
        id
        user {
          id
          fullName
          roles(first: 100) {
            edges {
              node {
                id
                type
                kind
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly roleIds: string[];
}

interface Node extends Identifiable {
  readonly user: UserWithRoles;
}

export interface Result {
  readonly nodes: ReadonlyArray<Node>;
}
