import classnames from "classnames";
import { omit } from "lodash";
import { CSSProperties } from "react";
import { connectField, filterDOMProps } from "uniforms";
import { RadioFieldProps } from "uniforms-semantic";

const extraLabelStyle: CSSProperties = {
  fontSize: "14px",
  lineHeight: "22px",
  color: "#4A4A4A",
  marginTop: "-5px",
};

interface Props extends RadioFieldProps {
  readonly [k: string]: unknown;
  readonly extraLabel?: string;
}

export const CustomRadioField = connectField<Props>(
  ({
    allowedValues,
    className,
    disabled,
    error,
    errorMessage,
    id,
    label,
    name,
    onChange,
    readOnly,
    required,
    showInlineError,
    transform,
    value,
    extraLabel,
    ...props
  }: Props) => (
    <div
      className={classnames(className, { disabled, error }, "grouped fields")}
      {...omit(filterDOMProps(props), ["checkboxes"])}
    >
      {label && (
        <div className={classnames({ required }, "field")}>
          <label>{label}</label>
          {extraLabel && <div style={extraLabelStyle}>{extraLabel}</div>}
        </div>
      )}

      {allowedValues?.map((item) => {
        const checked = item === value;
        const onItemChange = () => {
          if (!readOnly) {
            onChange(item);
          }
        };

        return (
          <div className="field" key={item}>
            <div className="ui radio checkbox">
              <input
                checked={checked}
                disabled={disabled}
                id={`${id}-${item}`}
                name={name}
                onChange={onItemChange}
                type="radio"
              />
              <label htmlFor={`${id}-${item}`}>{transform ? transform(item) : item}</label>
            </div>
          </div>
        );
      })}

      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage}</div>
      )}
    </div>
  ),
  { kind: "leaf" }
);
