import dateFormat from "dateformat";
import { useMemo } from "react";
import { Button } from "semantic-ui-react";
import { CBCMemberExpertNotVotedOnboard } from "../../../api/onboards/CBCMemberNotVotedExpertOnboards";
import { OnboardMigrator } from "../../../migrations/Onboards";
import { EntityType } from "../../../types/onboard";
import { readableBusinessCase } from "../../../schemas/invites/_types";
import { Shortcuts as S } from "../../../routing";
import { Link } from "react-router-dom";
import { UnstyledButton } from "../../UnstyledButton";

interface Props {
  readonly onboard: CBCMemberExpertNotVotedOnboard;
}

export const CBCMemberExpertOnboardCard = ({ onboard }: Props) => {
  const migratedOnboard = useMemo(() => {
    const { migrate } = new OnboardMigrator(onboard);
    return migrate();
  }, [onboard]);

  const { fullName, submittedAt, type } = migratedOnboard;

  const businessCase = useMemo(
    () => migratedOnboard.migratedForm?.data.businessCase,
    [migratedOnboard]
  );

  const linksTo = useMemo(() => {
    const id = onboard.id;
    const key = S.nomineeVotingExpert.queryVarNames.id;
    const search = new URLSearchParams({ [key]: id }).toString();

    return `${S.nomineeVotingExpert.path}?${search}`;
  }, [onboard]);

  const roleName = type === EntityType.Organisation ? "Organisation" : "Individual";
  return (
    <div className="CBCMemberExpertOnboardCard">
      <UnstyledButton as={Link} to={linksTo}>
        <div className="CBCMemberExpertOnboardCard-header">CBC VOTING</div>
        <div className="CBCMemberExpertOnboardCard-body">
          <div className="CBCMemberExpertOnboardCard-body-name">{fullName}</div>
          <div className="CBCMemberExpertOnboardCard-body-sub-name">
            {roleName}{" "}
            {businessCase ? <>&nbsp; | &nbsp;{readableBusinessCase(businessCase)}</> : ""}
          </div>
          <div className="CBCMemberExpertOnboardCard-body-bottom">
            <div className="CBCMemberExpertOnboardCard-body-bottom-label">
              Date submitted: {dateFormat(submittedAt, "dd/mm/yy")}
            </div>
            <Button color="purple" primary>
              Vote now
            </Button>
          </div>
        </div>
      </UnstyledButton>
    </div>
  );
};
