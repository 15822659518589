import { gql } from "@apollo/client";
import { TicketDeliverableState } from "../../../types/ticket";

export const PROGRESS_UPDATE_SEAL_FIELDS = gql`
  fragment ProgressUpdateSealFields on TickettingDeliverableProgressStatus {
    progressUpdateDate
    progressUpdateStatus
  }
`;

export interface ProgressUpdateSealFields {
  readonly progressUpdateDate: string;
  readonly progressUpdateStatus: TicketDeliverableState;
}
