import { useCallback, CSSProperties } from "react";
import { BoolField } from "uniforms-semantic";
import { Button, Image } from "semantic-ui-react";
import { toast } from "react-toastify";
import blueArrow from "../../../assets/blue-arrow.svg";
import { useApolloClient } from "@apollo/client";
import { perform as amendOnboard } from "../../../api/onboards/AmendOnboard";
import { noResultErrorFor } from "../../../types";
import { StartupSteps } from "../../../types/OnboardForm";
import { utils } from "../../../utils/utils";
import { bridge as TermsSchemaBridge } from "../schemas/TermsAndConditionsStartup";
import { OnboardFormProps } from "../../../components/pages/Dashboard/applications/application";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { useScrollToTopApi } from "../../../components/elements/ScrollToTop";

const justifiedText: CSSProperties = { textAlign: "justify" };

export const TermsAndConditionsStartup = ({ onboard }: OnboardFormProps) => {
  const client = useApolloClient();
  const onLinkClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);
  const { scrollToTop } = useScrollToTopApi();

  const onSubmit = useCallback(
    (ev) => {
      const schemaVersion = onboard.migratedForm?.schemaVersion;
      if (!schemaVersion) {
        return toast.error("Missing schema version.");
      }
      const data = JSON.stringify({ ...ev, currentStep: StartupSteps.PreSelectionData });
      amendOnboard(client, { onboardId: onboard.id, data, schemaVersion })
        .then((res) => {
          if (!res?.data?.payload?.onboard) {
            return Promise.reject(noResultErrorFor("AmendOnboard"));
          }
          scrollToTop();
        })
        .catch((err) => {
          console.warn(err);
          toast.error("Failed to amend onboard.");
        });
    },
    [client, onboard, scrollToTop]
  );

  return (
    <div className="WhyJoinForm">
      <div className="WhyJoinForm-top-container">
        <Image src={blueArrow} />
        <div className="WhyJoinForm-title">Why raise with Consilience Ventures?</div>
        <div className="WhyJoinForm-title-sub-text">
          We are dedicated to invest in and make market category leaders from ideation to exit. Our
          ecosystem enables you to work with a curated network of experts who are rewarded in
          proportion to your success.
        </div>
        <br />
        <div>
          <b>Benefits</b>
        </div>
        <br />
        <div className="WhyJoinForm-lists">
          <div className="WhyJoinForm-lists-list">
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>
                Easily access the 2 most important factors for your ability to scale; capital and
                expertise.
              </div>
            </div>
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>
                The value of CVDS is pegged to the portfolio's value. By raising in CVDS, you ensure
                that your purchasing power increases as the asset appreciates.
              </div>
            </div>
          </div>
          <div className="WhyJoinForm-lists-list">
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>
                You'll access all sorts of investors here; VCs, Angels, Syndicates, Family Offices -
                we are growing fast.
              </div>
            </div>
            <div className="WhyJoinForm-lists-item">
              <div className="WhyJoinForm-list-dot" />
              <div>
                Get the best value for your capital, and nail essential projects with experts who
                have a stake in your success.
              </div>
            </div>
          </div>
        </div>
      </div>
      <AutoForm
        schema={TermsSchemaBridge}
        model={onboard.form?.data}
        showInlineError
        onSubmit={onSubmit}
        className="WhyJoinForm-bottom-container"
      >
        <div>Terms and Conditions</div>
        <br />
        <BoolField
          name="agreesToTerms"
          label={
            <p style={justifiedText}>
              I/We hereby agree that my/our application for membership of the Consilience Ventures
              Platform is made on the basis of and, if accepted, I/we will be bound by the{" "}
              <a
                href={`${utils.apiHost}/static/files/cv-members-terms-and-conditions-191010.pdf`}
                download
                target="_blank"
                onClick={onLinkClick}
                rel="noopener noreferrer"
              >
                CV Membership Terms and Conditions
              </a>{" "}
              (which I have read and understood) as amended from time to time and which are
              available on this website.
            </p>
          }
          errorMessage="This field is mandatory"
        />
        <BoolField
          name="agreesToMemorandum"
          label={
            <p style={justifiedText}>
              I/We also confirm that I/we have read and understood the{" "}
              <a
                href={`${utils.apiHost}/static/files/cv-information-memorandum-191010.pdf`}
                download
                target="_blank"
                rel="noopener noreferrer"
                onClick={onLinkClick}
              >
                CV Information Memorandum
              </a>{" "}
              dated 18 September 2019 and that any CVDS I/we may acquire are governed by the terms
              of a Deed Poll issued by Consilience Ventures Limited dated 18 September 2019 (as
              amended from time to time and which is summarized in the Information Memorandum and
              available from Consilience Ventures Limited on request).
            </p>
          }
          errorMessage="This field is mandatory"
        />
        <div className="OnboardForm-bttn-wrapper">
          <Button type="submit" content="Continue" color="blue" />
        </div>
      </AutoForm>
    </div>
  );
};
