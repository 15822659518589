import { useCallback, useEffect, useMemo, useRef, useState, CSSProperties } from "react";
import { Button, Grid, Form, Segment, Divider, ButtonProps } from "semantic-ui-react";
import { TextField } from "uniforms-semantic";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import dateFormat from "dateformat";
import { MUTATION as SubmitMut } from "../../../api/onboards/SubmitOnboard";
import { Variables as SubmitVars, Result as SubmitRes } from "../../../api/onboards/SubmitOnboard";
import { MUTATION as AmendMut } from "../../../api/onboards/AmendOnboard";
import { Variables as AmendVars, Result as AmendRes } from "../../../api/onboards/AmendOnboard";
import { bridge as StartupPreselectionBridge } from "../schemas/StartupPreselection";
import { isDefined, Maybe, noResultErrorFor } from "../../../types";
import { FundraisingInfo } from "../../../types/OnboardForm";
import { PreselectionForm, PreselectionForm as StartupPreselectionSchema } from "../utils/_types";
import { AjvError, appendCustomAjvError } from "../../../utils/Ajv";
import { Context, DeepPartial, useForm } from "uniforms";
import { CustomTextField } from "../../CustomTextField";
import { CustomLongTextField } from "../../CustomLongTextField";
import { CustomUploadImageField } from "../../CustomUploadImageField";
import { CustomUploadField } from "../../CustomUploadField";
import { CustomDynamicTextField } from "../../CustomDynamicTextField";
import { CustomDropdownSelectionField } from "../../CustomDropdownSelectionField";
import { CustomDropdownFundRaising } from "../../CustomDropdownFundRaising";
import { CustomNumField } from "../../tickets/components/CustomNumField";
import { CustomRadioToggleField } from "../../CustomRadioToggleField";
import { DisplayIf } from "../../DisplayIf";
import { OnboardFormProps } from "../../../components/pages/Dashboard/applications/application";
import { CountriesList } from "../../../utils/CountriesList";
import { TagTree } from "../../../types/tags";
import { useCategoriesState } from "../../../contexts/Categories";
import { isSuccessState } from "../../../contexts/Generic";
import { NestedTagsField } from "../../NestedTagsField";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { TagNamespace } from "../../../types/labelQuery";
import { useScrollToTopApi } from "../../../components/elements/ScrollToTop";

const noMarginStyle: CSSProperties = { margin: "0" };

const fundraisingOptions = [
  { text: "No", value: FundraisingInfo.NOT_RAISING },
  { text: "Yes", value: FundraisingInfo.RAISING },
  { text: "I am looking for a bridge between rounds", value: FundraisingInfo.BRIDGE_ROUND },
];

const hasFixedValuationOptions = [
  { text: "No, it is open to negotiation.", value: false },
  { text: "Yes, it is fixed.", value: true },
];

const pitchDeckLabel = (
  <>
    Attach your pitch deck (PDF, 16MB max). If you don't have an existing pitch deck, please feel
    free to use our{" "}
    <a
      href="https://docs.google.com/presentation/d/1sZwHjfAJWv1UiLwRjRudcY-qeJ9yqghk3r6gNFH2Y0o/edit#slide=id.p"
      target="_blank"
      rel="noopener noreferrer"
    >
      template
    </a>
    .
  </>
);

export const StartupProfileForm = ({ onboard }: OnboardFormProps) => {
  const categoriesState = useCategoriesState();
  // State and Refs.
  const [needsScroll, setNeedsScroll] = useState(false);
  const hasPendingSubmitRef = useRef<boolean>(false);
  // Mutations.
  const [submitOnboard] = useMutation<SubmitRes, SubmitVars>(SubmitMut);
  const [amendOnboard] = useMutation<AmendRes, AmendVars>(AmendMut);
  const schemaVersion = onboard.migratedForm?.schemaVersion;
  const { scrollToTop } = useScrollToTopApi();

  useEffect(() => {
    if (hasPendingSubmitRef.current && needsScroll) {
      const firstError = document.getElementsByClassName("ui red basic pointing label")[0];
      if (firstError && firstError.parentElement) {
        firstError.parentElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }

      hasPendingSubmitRef.current = false;
      setNeedsScroll(false);
    }
  }, [needsScroll]);

  const [maturitiesTree, sectorsTree] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null];
    }

    return [categoriesState.result.maturitiesTree, categoriesState.result.sectorsTree];
  }, [categoriesState]);

  const maturitiesOptions = useMemo(() => {
    return generateMaturitiesOption(maturitiesTree?.children);
  }, [maturitiesTree]);

  const initialModel = useMemo(() => {
    return {
      ...onboard.form?.data,
      startupData: { ...onboard.form?.data.startupData, hasLeadInvestor: false },
    };
  }, [onboard]);

  const isFundRaising = useCallback(({ model }: Context<StartupPreselectionSchema>) => {
    return (
      model.startupData?.fundraisingInfo === FundraisingInfo.RAISING ||
      model.startupData?.fundraisingInfo === FundraisingInfo.BRIDGE_ROUND
    );
  }, []);

  const onValidate = useCallback(
    ({ startupData }: Partial<StartupPreselectionSchema>, mError: Maybe<AjvError>) => {
      let e = mError;
      startupData?.pitchHighlights.forEach((p) => {
        if (p.length === 0) {
          const path = "/startupData/pitchHighlights";
          e = appendCustomAjvError(e, true, path, "Should not be empty.");
        }
      });
      const hasErrors = isDefined(e) && e.details.length > 0;
      if (hasPendingSubmitRef.current && hasErrors) {
        setNeedsScroll(true);
        console.warn("startupData", startupData);
        console.warn("errors", e?.details);
      }
      return e;
    },
    []
  );
  const onSave = useCallback(
    (model: DeepPartial<PreselectionForm>) => {
      if (!schemaVersion) {
        return toast.error("Missing schema version.");
      }
      const data = JSON.stringify(model);
      amendOnboard({ variables: { input: { schemaVersion, onboardId: onboard.id, data } } })
        .then(() => toast.success("Changes saved."))
        .catch((err) => {
          console.warn(err);
          toast.error("Failed to save your onboard.");
        });
    },
    [onboard, schemaVersion, amendOnboard]
  );

  const onSubmitBttnClick = useCallback(() => (hasPendingSubmitRef.current = true), []);
  const onSubmit = useCallback(
    (ev) => {
      if (!schemaVersion) {
        return toast.error("Missing schema version.");
      }
      const data = JSON.stringify(ev);
      amendOnboard({ variables: { input: { schemaVersion, onboardId: onboard.id, data } } })
        .then(() => submitOnboard({ variables: { input: { onboardId: onboard.id } } }))
        .then((res) => {
          if (!res?.data?.payload?.onboard) {
            return Promise.reject(noResultErrorFor("AmendOnboard"));
          }
          scrollToTop();
        })
        .catch((err) => {
          console.warn(err);
          toast.error("Failed to submit onboard.");
        });
    },
    [onboard, schemaVersion, amendOnboard, submitOnboard, scrollToTop]
  );

  const saveSubmitButtons = (
    <span>
      <SaveButton type="button" color="blue" basic onSave={onSave}>
        <b>Save</b>
      </SaveButton>
      <Button type="submit" content="Submit" color="blue" onClick={onSubmitBttnClick} />
    </span>
  );

  return (
    <Segment className="OnboardForm">
      <AutoForm
        schema={StartupPreselectionBridge}
        model={initialModel}
        showInlineError
        onValidate={onValidate}
        onSubmit={onSubmit}
      >
        <Grid padded>
          <Grid.Row>
            <Grid.Column textAlign="justified">
              <div className="OnboardForm-titleWrapper">
                <div className="OnboardForm-titleWrapper-title">
                  Pre-selection Form
                  <div className="OnboardForm-titleWrapper-title-extra">
                    {dateFormat(onboard.amendedAt, '"Saved at: " HH : MM "on" dd / mm / yy')}
                  </div>
                </div>

                {saveSubmitButtons}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Divider style={noMarginStyle} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="justified">
              Thank you for your interest in Consilience Ventures. As part of our evaluation process
              we'd like to invite you to submit some initial information which will form your
              Startup Profile. Your Startup Profile will be the first point of engagement with our
              ecosystem, and will be used by our community of experts and investors to help
              determine whether your application will proceed to the next phase of evaluation. The
              pre-selection phase leverages the wisdom of the crowd within our network, and has a
              duration of up to 3 weeks. Based on the community's feedback, the review committee
              will take a decision on whether to continue the conversation on a deeper level in the
              following phase of evaluation, General Assessment. Should you have any questions
              please contact{" "}
              <a href="mailto:help@consilienceventures.com">help@consilienceventures.com</a>.
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid padded noValidate onSubmit={onSubmit}>
          {/* IDEA AND VISION */}
          <Grid.Row>
            <Grid.Column width="3">
              <h3>Company Information</h3>
            </Grid.Column>
            <Grid.Column width="13">
              <CustomTextField
                name="startupData.name"
                label="What is the name of your startup?"
                errorMessage="This field is mandatory and should be less than 50 characters."
              />
              <CustomUploadImageField
                name="startupData.logo"
                label={
                  "If your startup has a logo upload it below (png, jpg or jpeg up to 1MB maximum size)."
                }
                required={false}
              />
              <br />
              <CustomTextField
                name="startupData.companiesHouse"
                label="Provide a link to your page on Companies House."
                errorMessage="Must begin with https://"
                placeholder="https://www.example.com"
              />
              <CustomDropdownSelectionField
                name="startupData.headquarters"
                label="Supply the location of up to three of your company's headquarters."
                dropdownOptions={CountriesList}
                placeholder="Select location"
                errorMessage="This field is mandatory and should have between 1 and 3 headquarters."
                multiple
                search
              />
              <br />
              <CustomTextField
                name="links.website"
                label="Provide a link to your website."
                errorMessage="Must begin with https://"
                placeholder="https://www.example.com"
              />
              <Grid.Row>
                <Grid.Column>
                  <Form.Field label="Provide your social media links." />
                  <Form.Group widths="2">
                    <TextField
                      name="links.twitter"
                      label=""
                      iconLeft="twitter"
                      errorMessage="Must begin with https://"
                      placeholder="https://www.twitter.com"
                      maxLength={300}
                    />
                    <TextField
                      name="links.facebook"
                      label=""
                      iconLeft="facebook"
                      errorMessage="Must begin with https://"
                      placeholder="https://www.facebook.com"
                      maxLength={300}
                    />
                  </Form.Group>
                  <Form.Group widths="2">
                    <TextField
                      name="links.instagram"
                      label=""
                      iconLeft="instagram"
                      errorMessage="Must begin with https://"
                      placeholder="https://www.instagram.com"
                      maxLength={300}
                    />
                    <TextField
                      name="links.linkedin"
                      label=""
                      iconLeft="linkedin"
                      errorMessage="Must begin with https://"
                      placeholder="https://www.linkedin.com"
                      maxLength={300}
                    />
                  </Form.Group>
                </Grid.Column>
                <br />
                <CustomLongTextField
                  name="startupData.team"
                  label="Provide a high level description of your key team members."
                  errorMessage="This field is mandatory and should be less than 2500 characters."
                />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <div className="DashedLine" />
          <Grid.Column width="3">
            <h3>Idea and Vision</h3>
          </Grid.Column>
          <Grid.Column width="13">
            <CustomLongTextField
              name="startupData.summary"
              label="Provide a high level description of the nature of your business and venture."
              errorMessage="This field is mandatory and should be less than 2500 characters."
            />
            <CustomUploadField
              name="startupData.pitchDeck"
              label={pitchDeckLabel}
              maxCount={1}
              acceptedFiles={[".pdf"]}
              required={false}
            />

            <br />
            {maturitiesTree && (
              <CustomDropdownSelectionField
                name="startupData.maturity"
                label="How mature is your startup?"
                dropdownOptions={maturitiesOptions}
                placeholder="Select maturity level"
                errorMessage="This field is mandatory."
              />
            )}
            <br />
            {sectorsTree && (
              <NestedTagsField
                name="startupData.sectors"
                label="In what sector(s) do you operate in?"
                collapsedText="Sectors"
                placeholder="Search sectors"
                errorMessage="This field is mandatory."
                options={sectorsTree}
                namespace={TagNamespace.Sectors}
              />
            )}
            <br />
            <CustomLongTextField
              name="startupData.vision"
              label="Describe the vision your startup is aiming for."
              errorMessage="This field is mandatory and should be less than 2500 characters."
            />
            <CustomLongTextField
              name="startupData.usp"
              label="Tell us briefly about your USP and lines of defensibility."
              errorMessage="This field is mandatory and should be less than 2500 characters."
            />
            <CustomLongTextField
              name="startupData.roadmap"
              label="What are your major bottlenecks to growth over the next 6 months? What activities / milestones do you intend to focus on?"
              errorMessage="This field is mandatory and should be less than 2500 characters."
            />
            <CustomDynamicTextField
              name="startupData.pitchHighlights"
              label="Share up to three highlights from your elevator pitch."
              min={1}
              max={3}
              errorMessage="This field is mandatory and should have 1 to 3 items."
            />
          </Grid.Column>
          <div className="DashedLine" />
          <Grid.Column width="3">
            <h3>Fundraising and Valuation</h3>
          </Grid.Column>
          <Grid.Column width="13">
            <Form.Group widths="2">
              <CustomDropdownFundRaising
                name="startupData.fundraisingInfo"
                label="Are you currently engaged in a fundraising round?"
                placeholder="Select an option"
                dropdownOptions={fundraisingOptions}
                errorMessage="This field is mandatory."
              />
              <DisplayIf condition={isFundRaising}>
                <CustomNumField
                  name="startupData.amountRaised"
                  required
                  label="How much are you currently raising, in pounds?"
                  iconLeft="pound"
                  placeholder="Enter number"
                  errorMessage="This field is mandatory. "
                />
              </DisplayIf>
            </Form.Group>
            <br />
            <DisplayIf condition={isFundRaising}>
              <>
                <Form.Group widths="2">
                  <CustomNumField
                    name="startupData.equityOffered"
                    required
                    label="What percentage of equity are you raising against?"
                    iconLeft="percent"
                    placeholder="Enter number"
                    errorMessage="This field is mandatory and should be less than 100. "
                  />
                  <CustomRadioToggleField
                    name="startupData.hasLeadInvestor"
                    label="Do you currently have a lead investor?"
                    errorMessage="This field is mandatory."
                  />
                </Form.Group>
                <br />
              </>
            </DisplayIf>
            <Form.Group widths="2">
              <CustomNumField
                name="startupData.valuation"
                label="Provide your current pre-money valuation, in pounds (£)."
                iconLeft="pound"
                placeholder="Enter number"
                errorMessage="This field is mandatory. "
              />
              <CustomDropdownSelectionField
                name="startupData.hasFixedValuation"
                label="Is your current pre-money valuation fixed and final?"
                placeholder="Select an option"
                dropdownOptions={hasFixedValuationOptions}
                errorMessage="This field is mandatory."
              />
            </Form.Group>
            <br />
            <Form.Field
              label="
              The information you provide will be visible to our community of experts and
              investors. Their feedback on your application informs our Review Committees when
              determining whether to progress your application to General Assessment (the next stage
              of our process)."
            />
          </Grid.Column>
        </Grid>
        <br />
        <div className="OnboardForm-bttn-wrapper">{saveSubmitButtons}</div>
      </AutoForm>
    </Segment>
  );
};

interface SaveProps extends ButtonProps {
  readonly onSave: (m: DeepPartial<PreselectionForm>) => void;
}
const SaveButton = ({ onSave, ...props }: SaveProps) => {
  const { model } = useForm<PreselectionForm>();

  const onClick = useCallback(() => onSave(model), [model, onSave]);

  return <Button {...props} onClick={onClick} />;
};

export const generateMaturitiesOption = (maturities: readonly TagTree[] | undefined) => {
  if (maturities === undefined) {
    return {};
  }
  return maturities.map((s) => {
    return { text: s.description, value: s.label };
  });
};
