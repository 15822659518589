import { useCallback, useMemo } from "react";
import { useCategoriesState } from "../../contexts/Categories";
import { isSuccessState } from "../../contexts/Generic";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { NestedTagsField } from "../NestedTagsField";
import { bridge as ExpertTagsSchema } from "./ExpertTagsSchema";
import { LabelingOperation, NamespacedLabel } from "../../types/labelQuery";
import { TagBagScope, TagNamespace } from "../../types/labelQuery";
import { ExpertTagsData } from "./_types";
import { useMutation } from "@apollo/client";
import { MUTATION as UpdateTagsMut, Result as UpdateTagsRes } from "../../api/tags/ApplyLabels";
import { Variables as UpdateTagsVars } from "../../api/tags/ApplyLabels";
import { Role } from "../../types/role";
import { ApiError, extractErrorMessages, noResultErrorFor } from "../../types";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";

interface Props {
  readonly maturities: ReadonlyArray<string>;
  readonly sectors: ReadonlyArray<string>;
  readonly occupations: ReadonlyArray<string>;
  readonly skills: ReadonlyArray<string>;
  readonly role: Role;
}

export const ExpertTagsForm = (props: Props) => {
  const { maturities, occupations, sectors, skills, role } = props;
  const categoriesState = useCategoriesState();
  const [updateTags, { loading }] = useMutation<UpdateTagsRes, UpdateTagsVars>(UpdateTagsMut);

  const initialModel: ExpertTagsData = useMemo(() => {
    return { maturities, occupations, sectors, skills };
  }, [maturities, occupations, sectors, skills]);

  const [occupationTree, maturitiesTree, sectorsTree, skillsTree] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null, null, null];
    }

    return [
      categoriesState.result.occupationsTree,
      categoriesState.result.maturitiesTree,
      categoriesState.result.sectorsTree,
      categoriesState.result.skillsTree,
    ];
  }, [categoriesState]);

  const onSubmit = useCallback(
    (model: ExpertTagsData) => {
      const { maturities: mats, sectors: sect, occupations: ocps, skills: sks } = model;
      let labels: ReadonlyArray<NamespacedLabel> = [];
      labels = labels.concat(sect.map((label) => ({ ns: TagNamespace.Sectors, label })));
      labels = labels.concat(mats.map((label) => ({ ns: TagNamespace.Maturities, label })));
      labels = labels.concat(ocps.map((label) => ({ ns: TagNamespace.Occupations, label })));
      labels = labels.concat(sks.map((label) => ({ ns: TagNamespace.Skills, label })));

      updateTags({
        variables: {
          input: {
            op: LabelingOperation.Set,
            taggableId: role.id,
            scope: TagBagScope.Default,
            labels,
          },
        },
      })
        .then(({ data }) => {
          if (!data || !data.payload || !data.payload.role) {
            return Promise.reject(noResultErrorFor("Tag Role"));
          }
          toast.success("Changes saved.");
        })
        .catch((err: ApiError) => toast.error(extractErrorMessages(err).join(", ")));
    },
    [role.id, updateTags]
  );

  return (
    <AutoForm schema={ExpertTagsSchema} model={initialModel} onSubmit={onSubmit}>
      {maturitiesTree && (
        <NestedTagsField
          name="maturities"
          label="With what type of startups do you have the most experience working with?"
          collapsedText="Maturities"
          placeholder="Select maturities"
          errorMessage="This field is mandatory."
          showInlineError
          options={maturitiesTree}
          namespace={TagNamespace.Maturities}
        />
      )}
      <br />
      {occupationTree && (
        <NestedTagsField
          name="occupations"
          label="What is your occupation? You can select several from the list below."
          collapsedText="Occupations"
          placeholder="Search occupations"
          errorMessage="This field is mandatory."
          showInlineError
          options={occupationTree}
          namespace={TagNamespace.Occupations}
        />
      )}
      <br />
      {sectorsTree && (
        <NestedTagsField
          name="sectors"
          label="In which sectors do you have experience?"
          collapsedText="Sectors"
          placeholder="Search sectors"
          errorMessage="This field is mandatory."
          showInlineError
          options={sectorsTree}
          namespace={TagNamespace.Sectors}
        />
      )}
      <br />
      {skillsTree && (
        <NestedTagsField
          name="skills"
          label="What are your key skills?"
          collapsedText="Skills"
          placeholder="Search skills"
          errorMessage="This field is mandatory and should be less than 30."
          showInlineError
          options={skillsTree}
          namespace={TagNamespace.Skills}
        />
      )}
      <Button
        floated="right"
        content="Save Tags"
        color="blue"
        loading={loading}
        disabled={loading}
      />
      <br />
      <br />
    </AutoForm>
  );
};
