enum Pathnames {
  Network = "/directory",
  CommunityResources = "/community-resources",
  VerifyEmail = "/verify-email", // args: `token`
  InitiatePasswordReset = "/initiate-password-reset",
  ResetPassword = "/reset-password", // args: `token`
  ClaimInvite = "/claim-invite", // args: `id` (onboardId)
  NominateExpert = "/nominate-expert",
  InviteStartup = "/invite-startup",
  MattermostUserCreation = "/mattermost-user-creation", // args: `token`
  MyArea = "/my-area", // args: `tab` (values: "received", "sent", "my-profile")
  Application = "/application", // args: `id` (onboardId)
  Wallet = "/wallet", // args: `address` (ethAddress)
  Portfolio = "/portfolio",
  Preselection = "/pre-selection",
  GeneralAssessment = "/general-assessment",
  StartupDealflow = "/startup-dealflow",
  StartupDealflowViewBeingReviewed = "/startup-dealflow/being-reviewed",
  StartupDealflowViewPreselection = "/startup-dealflow/pre-selection",
  StartupDealflowViewGeneralAssessment = "/startup-dealflow/general-assessment",
  StartupDealflowViewUnsuccessful = "/startup-dealflow/unsuccessful",
  ExpertOnboarding = "/expert-onboarding",
  ExpertOnboardingViewAwaitingSeconder = "/expert-onboarding/awaiting-seconder",
  ExpertOnboardingViewMutualAssessment = "/expert-onboarding/mutual-assessment",
  ExpertOnboardingViewCBCVoting = "/expert-onboarding/cbc-voting",
  Startup = "/startup", // args: `id` (onboardId)
  Expert = "/expert", // args: `id` (onboardId)
  // Ticket routes.
  Marketplace = "/marketplace",
  MySprint = "/my-sprint", // args: `tab`, `filter`
  MyWork = "/my-work", // args: `tab`, `filter`
  Ticket = "/ticket", // args: `id` (tickettingTicketId)
  SubmitBid = "/ticket/submit-bid", // args: `id` (tickettingTicketId)
  ReviewPlan = "/ticket/review-plan", // args: `id` (tickettingTicketId)
  DraftTicket = "/draft-ticket", // args: `id` (tickettingTicketId)
  Bid = "/bid", // args: `id` (tickettingBidId)
  SubmitPlan = "/submit-plan", // args: `id` (tickettingTicketId)
  // Linkedin Callback
  LinkedInCallback = "/linkedin-callback",
  Invite = "/invite", // args: `id`

  // CBC member Routes
  NomineeVoting = "/nominee-voting",
  NomineeVotingExpert = "/nominee-voting/expert", // args: `id`, `role`

  // Admin routes.
  Administration = "/administration", // args: `tab` (values: "users", "applications")
  AdministrationUsers = "/administration/users",
  AdministrationUser = "/administration/users/user", // args: `id`, `role`
  AdministrationStartups = "/administration/startups",
  AdministrationStartup = "/administration/startups/startup", // args: `id`
  AdministrationExperts = "/administration/experts",
  AdministrationExpert = "/administration/experts/expert", // args: `id`
}

export const Routes = {
  network: {
    title: "Network",
    path: Pathnames.Network,
    queryVarNames: { tab: "tab", filter: "filter" },
  },
  communityResources: {
    title: "Community Resources",
    path: Pathnames.CommunityResources,
  },
  verifyEmail: {
    title: "Verify Email",
    path: Pathnames.VerifyEmail,
    queryVarNames: { token: "token" },
  },
  initiatePasswordReset: {
    title: "Initiate Password Reset",
    path: Pathnames.InitiatePasswordReset,
  },
  resetPassword: {
    title: "Reset Password",
    path: Pathnames.ResetPassword,
    queryVarNames: { token: "token" },
  },
  claimInvite: {
    title: "Invited",
    path: Pathnames.ClaimInvite,
    queryVarNames: { id: "id" },
  },
  nominateExpert: {
    title: "Expert Invite",
    path: Pathnames.NominateExpert,
  },
  inviteStartup: {
    title: "Invite Startup",
    path: Pathnames.InviteStartup,
  },
  mattermostUserCreation: {
    title: "Mattermost User Creation",
    path: Pathnames.MattermostUserCreation,
    queryVarNames: { token: "t" },
  },
  myArea: {
    title: "My Area",
    path: Pathnames.MyArea,
    queryVarNames: { tab: "tab" },
  },
  application: {
    title: "Application",
    path: Pathnames.Application,
    queryVarNames: { id: "id" },
  },
  wallet: {
    title: "Wallet",
    path: Pathnames.Wallet,
    queryVarNames: { address: "address" },
  },
  portfolio: {
    title: "Portfolio",
    path: Pathnames.Portfolio,
  },
  preselection: {
    title: "Pre-Selection",
    path: Pathnames.Preselection,
  },
  generalAssessment: {
    title: "General Assessment",
    path: Pathnames.GeneralAssessment,
  },
  startupDealflow: {
    title: "Startup Dealflow",
    path: Pathnames.StartupDealflow,
    children: {
      beingReviewed: {
        title: "Being reviewed ",
        path: Pathnames.StartupDealflowViewBeingReviewed,
      },
      preSelection: {
        title: "Pre-selection",
        path: Pathnames.StartupDealflowViewPreselection,
      },
      generalAssessment: {
        title: "General Assessment",
        path: Pathnames.StartupDealflowViewGeneralAssessment,
      },
      unsuccessfull: {
        title: "Unsuccesful",
        path: Pathnames.StartupDealflowViewUnsuccessful,
      },
    },
  },
  expertOnboarding: {
    title: "Expert Onboarding",
    path: Pathnames.ExpertOnboarding,
    children: {
      awaitingSeconder: {
        title: "Awaiting Seconder",
        path: Pathnames.ExpertOnboardingViewAwaitingSeconder,
      },
      mutualAssessment: {
        title: "Mutual Assessment",
        path: Pathnames.ExpertOnboardingViewMutualAssessment,
      },
      cbcVoting: {
        title: "CBC Voting",
        path: Pathnames.ExpertOnboardingViewCBCVoting,
      },
    },
  },
  startup: {
    title: "Startup",
    path: Pathnames.Startup,
    queryVarNames: { id: "id", tab: "tab", voteTab: "voteTab" },
  },
  expert: {
    title: "Expert",
    path: Pathnames.Expert,
    queryVarNames: { id: "id", tab: "tab", voteTab: "voteTab" },
  },
  mySprint: {
    title: "My Sprint",
    path: Pathnames.MySprint,
    queryVarNames: { tab: "tab", filter: "filter" },
  },
  draftTicket: {
    title: "Draft Ticket",
    path: Pathnames.DraftTicket,
    queryVarNames: { id: "id" },
  },
  ticket: {
    title: "Ticket",
    path: Pathnames.Ticket,
    queryVarNames: { id: "id", view: "view", del: "del" },
    children: {
      submitBid: {
        title: "Submit bid",
        path: Pathnames.SubmitBid,
        queryVarNames: { id: "id" },
      },
      reviewPlan: {
        title: "Review delivery plan",
        path: Pathnames.ReviewPlan,
        queryVarNames: { id: "id" },
      },
    },
  },
  submitPlan: {
    title: "Ticket plan",
    path: Pathnames.SubmitPlan,
    queryVarNames: { id: "id" },
  },
  myWork: {
    title: "My Work",
    path: Pathnames.MyWork,
    queryVarNames: { tab: "tab", filter: "filter" },
  },
  marketplace: {
    title: "Marketplace",
    path: Pathnames.Marketplace,
  },
  bid: {
    title: "Bid",
    path: Pathnames.Bid,
    queryVarNames: { id: "id" },
  },
  linkedInCallback: {
    title: "LinkedIn Callback",
    path: Pathnames.LinkedInCallback,
  },
  invite: {
    title: "Invite",
    path: Pathnames.Invite,
    queryVarNames: { id: "id" },
  },
  nomineeVoting: {
    title: "Nominee Voting",
    path: Pathnames.NomineeVoting,
    children: {
      expert: {
        title: "Expert",
        path: Pathnames.NomineeVotingExpert,
        queryVarNames: { id: "id", tab: "tab", voteTab: "voteTab" },
      },
    },
  },
  administration: {
    title: "Administration",
    path: Pathnames.Administration,
    queryVarNames: { tab: "tab" },
    children: {
      users: {
        title: "Users",
        path: Pathnames.AdministrationUsers,
        children: {
          user: {
            title: "User",
            path: Pathnames.AdministrationUser,
            queryVarNames: { id: "id", role: "role" },
          },
        },
      },
      startups: {
        title: "Startup Applications",
        path: Pathnames.AdministrationStartups,
        children: {
          startup: {
            title: "Startup",
            path: Pathnames.AdministrationStartup,
            queryVarNames: { id: "id" },
          },
        },
      },
      experts: {
        title: "Expert Applications",
        path: Pathnames.AdministrationExperts,
        children: {
          expert: {
            title: "Expert",
            path: Pathnames.AdministrationExpert,
            queryVarNames: { id: "id", tab: "tab" },
          },
        },
      },
    },
  },
};

export const Shortcuts = {
  network: Routes.network,
  communityResources: Routes.communityResources,
  myArea: Routes.myArea,
  claimInvite: Routes.claimInvite,
  nominateExpert: Routes.nominateExpert,
  inviteStartup: Routes.inviteStartup,
  mattermostUserCreation: Routes.mattermostUserCreation,
  application: Routes.application,
  preselection: Routes.preselection,
  portfolio: Routes.portfolio,
  generalAssessment: Routes.generalAssessment,
  startupDealflow: Routes.startupDealflow,
  startupDealflowBeingReviewed: Routes.startupDealflow.children.beingReviewed,
  startupDealflowPreSelection: Routes.startupDealflow.children.preSelection,
  startupDealflowGeneralAssessment: Routes.startupDealflow.children.generalAssessment,
  startupDealflowUnsuccessful: Routes.startupDealflow.children.unsuccessfull,
  expertOnboarding: Routes.expertOnboarding,
  expertOnboardingAwaitingSeconder: Routes.expertOnboarding.children.awaitingSeconder,
  expertOnboardingMutualAssessment: Routes.expertOnboarding.children.mutualAssessment,
  expertOnboardingCBCVoting: Routes.expertOnboarding.children.cbcVoting,
  startup: Routes.startup,
  expert: Routes.expert,
  wallet: Routes.wallet,
  linkedInCallback: Routes.linkedInCallback,
  nomineeVoting: Routes.nomineeVoting,
  nomineeVotingExpert: Routes.nomineeVoting.children.expert,
  administration: Routes.administration,
  administrationUsers: Routes.administration.children.users,
  administrationUser: Routes.administration.children.users.children.user,
  administrationStartups: Routes.administration.children.startups,
  administrationStartup: Routes.administration.children.startups.children.startup,
  administrationExperts: Routes.administration.children.experts,
  administrationExpert: Routes.administration.children.experts.children.expert,
  verifyEmail: Routes.verifyEmail,
  initiatePasswordReset: Routes.initiatePasswordReset,
  resetPassword: Routes.resetPassword,
  mySprint: Routes.mySprint,
  draftTicket: Routes.draftTicket,
  myWork: Routes.myWork,
  marketplace: Routes.marketplace,
  ticket: Routes.ticket,
  submitBid: Routes.ticket.children.submitBid,
  bid: Routes.bid,
  submitPlan: Routes.submitPlan,
  reviewPlan: Routes.ticket.children.reviewPlan,
  invite: Routes.invite,
};
