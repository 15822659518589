import React, { CSSProperties, useState } from "react";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Card, Button, Image } from "semantic-ui-react";
import mmLogo from "../../assets/mattermost-logo.png";

import { Result } from "../../api/InviteUserToMM";
import { MUTATION } from "../../api/InviteUserToMM";
import { ApiError } from "../../types";

const mmLogoStyle: CSSProperties = {
  width: "145px",
};

export const MattermostWall = () => {
  const [issueInviteUserToMM] = useMutation<Result>(MUTATION);

  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

  const onCreateAccount = useCallback(() => {
    issueInviteUserToMM()
      .then((res) => {
        if (!res.data?.payload?.success) {
          toast.error("Couldn't issue Mattermost token.");
          return;
        }
        toast.success(
          "An email invitation has been sent to your email address, please make sure to check the Spam folder."
        );
        setIsRequestSubmitted(true);
      })
      .catch((err: ApiError) => {
        toast.error(err.message);
      });
  }, [issueInviteUserToMM, setIsRequestSubmitted]);

  return (
    <Card className="MattermostWall">
      <div className="MattermostWall-header">
        <div className="MattermostWall-header-title">
          Connect to other members,
          <br /> and receive real-time alerts of ecosystem activity
        </div>
        <div className="MattermostWall-header-subtitle">
          Welcome to Consilience Ventures, we're excited to have you here! <br />
        </div>
      </div>
      <div className="MattermostWall-body">
        <div className="MattermostWall-body-logo">
          <Image src={mmLogo} style={mmLogoStyle} />
          Mattermost is our primary communication tool, it enables direct person to person
          messaging, and private and public channels. We use it for ecosystem announcements,
          portfolio company updates, and review committee investment decisions.
          <br />
          <a
            href="https://www.youtube.com/watch?v=FuDvrkrqRzg&ab_channel=Mattermost"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Mattermost
          </a>
        </div>
        <h5>What can you do on mattermost?</h5>
        <div className="MattermostWall-body-description">
          <p>
            You have a direct line to ecosystem news and opportunities via the following channels:
          </p>
          <p>
            <span>#Introductions</span> - Pop over to the #Introductions channel to tell other
            members a little bit about yourself, and to welcome others.
          </p>
          <p>
            <span>#Pre-Selection</span> - Receive updates on new startup applications in
            pre-selection. View opportunities to serve on expert and startup review committees in
            exchange for compensation in CVDS. (Deals that have progressed to General Assessment are
            reviewed separately in private channels).
          </p>
          <p>
            <span>#Townsquare</span> - Stay up to date on major ecosystem events and news.
          </p>
          <p>
            <span>#Resources</span> - Find useful resources about expert roles and ecosystem
            processes.
          </p>
          <p>
            <span>#Advice & Requests</span> - Share ideas with and seek help from other members.
          </p>
        </div>
        <br />
        <p>
          <b>To create an account, request a Mattermost invite token.</b>
        </p>
        <Button primary onClick={onCreateAccount} disabled={isRequestSubmitted}>
          Request invite token
        </Button>
      </div>
    </Card>
  );
};
