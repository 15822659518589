import { CSSProperties, forwardRef } from "react";
import { Divider, FormField, Grid, GridRow, Header, Modal } from "semantic-ui-react";
import { TicketPreferencesForm } from "../../../../schemas/preferences/TicketPreferencesForm";
import { TicketPreferencesSchema } from "../../../../schemas/preferences/_types";

const gridStyle: CSSProperties = { padding: "10px 30px" };
const dividerStyle: CSSProperties = { marginLeft: "0px", marginRight: "0px" };

interface Props {
  readonly expertName: string;
  readonly onGeneralClose: () => void;
  readonly onSubmitPreferences: (model: Partial<TicketPreferencesSchema>) => void;
}

export const PreferencesModal = forwardRef<Partial<TicketPreferencesSchema>, Props>(
  ({ onGeneralClose, onSubmitPreferences, expertName }, ref) => {
    return (
      <Modal
        className="CustomClose"
        onClose={onGeneralClose}
        open={true}
        size="tiny"
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Grid style={gridStyle}>
            <GridRow>
              <Header size="large">Collaboration preferences</Header>
              <FormField>Help {expertName} understand how best to work with you</FormField>
            </GridRow>
            <Divider style={dividerStyle} />
            <GridRow>
              <TicketPreferencesForm ref={ref} onSubmitPreferences={onSubmitPreferences} />
            </GridRow>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
);
