import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation CreateDiscussionThread($input: CreateDiscussionThreadInput!) {
    payload: createDiscussionThread(input: $input) {
      comment {
        id
        attachments(first: 2) {
          edges {
            node {
              downloadUrl
            }
          }
        }
      }
      thread {
        id
        private
        author {
          fullName
          id
        }
        firstComment {
          ... on DiscussionComment {
            id
            title
            body
            insertedAt
            attachments(first: 2) {
              edges {
                node {
                  id
                  downloadUrl
                  document {
                    id
                    uploadedFile {
                      id
                      filename
                    }
                  }
                }
              }
            }
          }
        }
        insertedAt
        updatedAt
        commentCount

        discussable {
          ... on TickettingTicket {
            id
          }
          ... on TickettingBid {
            id
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: CreateDiscussionThreadInput;
}

interface CreateDiscussionThreadInput {
  readonly commentInput: DiscussionCommentInput;
  readonly discussableId: string;
  readonly private: boolean;
  readonly topic: string;
  readonly documents?: ReadonlyArray<string>;
}

export interface Result {
  readonly payload?: CreateDiscussionThreadPayload;
}

interface CreateDiscussionThreadPayload {
  readonly comment: Identifiable;
  readonly thread: Identifiable;
}

export interface DiscussionCommentInput {
  readonly body: string;
  readonly ethSignature: string;
  readonly title: string;
  readonly transmittedAt: string;
}
