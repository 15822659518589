import { useQuery } from "@apollo/client";
import { TicketDeliverableCard } from "./TicketDeliverableCard";
import { QUERY, Result, Variables } from "../../../../api/tickets/GetWorkplaceDeliverables";
import { LoaderWithMargin } from "../../../Loader";
import { ErrorMessages } from "../../ErrorMessages";
import { extractErrorMessages, noResultErrorFor } from "../../../../types";
import { GalleryModalProvider } from "../../../../contexts/GalleryModal/Provider";
import { TicketDeliverableModalContent } from "./TicketDeliverableModalContent";

interface Props {
  readonly ticketId: string;
  readonly isTicketOwner: boolean;
}
export const TicketDeliverablesSection = ({ ticketId, isTicketOwner }: Props) => {
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { id: ticketId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.payload || !data.payload.deliverables) {
    return (
      <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Workplace Deliverables"))} />
    );
  }

  const deliverables = data.payload.deliverables;
  return (
    <>
      <div className="ComponentHeader">Deliverables</div>
      <GalleryModalProvider
        galeryLenght={deliverables.length}
        modalContent={
          <TicketDeliverableModalContent
            isTicketOwner={isTicketOwner}
            ownerFullName={data.payload.owner.fullName}
            deliverables={data.payload.deliverables}
          />
        }
      >
        <div className="DeliverableCardsWrap">
          {deliverables.map((d, i) => (
            <div key={d.id}>
              <TicketDeliverableCard
                key={d.id}
                cardIndex={i}
                isTicketOwner={isTicketOwner}
                deliverable={d}
              />
            </div>
          ))}
        </div>
      </GalleryModalProvider>
    </>
  );
};
