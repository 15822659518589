import { Maybe } from "./common";

export interface Connection<T> {
  readonly edges: Maybe<ReadonlyArray<Edge<T>>>;
  readonly pageInfo: PageInfo;
}

export interface Edge<T> {
  readonly cursor: string;
  readonly node: Maybe<T>;
}

export interface PageInfo {
  readonly endCursor?: string;
  readonly hasNextPage: boolean;
  readonly hasPreviousPage: boolean;
  readonly startCursor?: string;
}

export interface ConnectionVariables {
  readonly after?: string;
  readonly before?: string;
  readonly first?: number;
  readonly last?: number;
}

export interface Payload<T> {
  readonly result: Maybe<T>;
}

// Helper functions
export const nodesFromEdges = <T>(
  edges: Maybe<ReadonlyArray<Edge<T>>> | undefined
): ReadonlyArray<T> =>
  (edges || []).reduce((acc: ReadonlyArray<T>, { node }) => (node ? acc.concat(node) : acc), []);
