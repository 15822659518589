import { Message, MessageProps } from "semantic-ui-react";

interface Props extends MessageProps {
  readonly errors?: ReadonlyArray<string>;
}
export const ErrorMessages = ({ errors, ...messageProps }: Props) =>
  !errors || errors.length === 0 ? null : (
    <Message {...messageProps} error>
      <Message.List items={[...errors]} />
    </Message>
  );
