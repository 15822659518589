import { useCallback } from "react";
import { connectField, HTMLFieldProps } from "uniforms";
import { LongTextField } from "uniforms-semantic";
import { InputFieldCharCount } from "../components/elements/InputFieldCharCount";
import { isEmpty } from "../types";

interface Props extends HTMLFieldProps<string, HTMLDivElement> {
  readonly [k: string]: unknown;
  readonly showtextcount?: string;
}

export const CustomLongTextField = connectField((props: Props) => {
  const { value, maxLength, placeholder, errorMessage, showtextcount, onChange } = props;
  const pholder = maxLength && !placeholder ? `Enter up to ${maxLength} characters` : placeholder;

  // Custom on change to convert empty strings to undefined istead.
  const customOnChange = useCallback(
    (v?: string) => onChange(isEmpty(v) ? undefined : v),
    [onChange]
  );

  const exceedsMaxLenght = value && maxLength && value?.length > maxLength;
  let maxLenghtErrorMessage = "";

  if (exceedsMaxLenght) {
    maxLenghtErrorMessage = `${value.length - maxLength} characters over limit`;
  }

  return (
    <>
      <LongTextField
        {...props}
        name=""
        placeholder={pholder}
        maxLength={maxLength}
        onChange={customOnChange}
        errorMessage={exceedsMaxLenght ? maxLenghtErrorMessage : errorMessage}
      />
      {maxLength !== undefined && showtextcount === undefined && (
        <InputFieldCharCount showRedError maxChars={maxLength} currentChars={value?.length || 0} />
      )}
    </>
  );
});
