import { useCallback, useEffect, useMemo, useState } from "react";
import { DeepPartial } from "uniforms";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Grid, Image, Modal } from "semantic-ui-react";
import { useBreadcrumbApi } from "../../contexts/Breadcrumb";
import expertIcon from "../../assets/expert-circular-icon.svg";
import { EntityType, SentOnboard } from "../../types/onboard";
import { ExpertBaseInviteForm } from "../../schemas/invites/ExpertBaseInviteForm";
import { OrganisationBaseInviteForm } from "../../schemas/invites/OrganisationBaseInviteForm";
import { ExpertBaseInviteType } from "../../schemas/invites/ExpertBaseInviteSchema";
import { OrganisationBaseInviteType } from "../../schemas/invites/OrganisationBaseInviteSchema";
import { BusinessCases } from "../../schemas/invites/_types";
import { assertUnreachable, extractErrorMessages } from "../../types";
import { CoInvestorInviteForm } from "../../schemas/invites/CoInvestorInviteForm";
import { IntroducerInviteForm } from "../../schemas/invites/IntroducerInviteForm";
import { EarlyProfessionalOrProminentSkillsInviteForm } from "../../schemas/invites/EarlyProfessionalOrProminentSkillsInviteForm";
import { CoInvestorInviteType } from "../../schemas/invites/CoInvestorInviteSchema";
import { IntroducerInviteType } from "../../schemas/invites/IntroducerInviteSchema";
import { EarlyProfessionalOrProminentSkillsInviteType } from "../../schemas/invites/EarlyProfessionalOrProminentSkillsInviteSchema";
import { MUTATION, Variables, Result } from "../../api/onboards/IssueOnboard";
import { Shortcuts as S } from "../../routing";
import { Tabs } from "./MyArea";
import { useScrollToTopApi } from "../../components/elements/ScrollToTop";

type PageOneFormType = DeepPartial<ExpertBaseInviteType | OrganisationBaseInviteType>;
export type PageTwoFormType = DeepPartial<
  CoInvestorInviteType | IntroducerInviteType | EarlyProfessionalOrProminentSkillsInviteType
>;

enum Pages {
  One = "ONE",
  Two = "TWO",
}

export const NominateExpert = () => {
  const history = useHistory();
  const breadcrumbApi = useBreadcrumbApi();
  const [currentPage, setCurrentPage] = useState(Pages.One);
  const [pageOneForm, setPageOneForm] = useState<PageOneFormType>();
  const [pageTwoForm, setPageTwoForm] = useState<PageTwoFormType>();
  const [type, setType] = useState<EntityType | undefined>();
  const [submitResult, setSubmitResult] = useState<SentOnboard>();
  const [issueOnboard] = useMutation<Result, Variables>(MUTATION);
  const { scrollToTop } = useScrollToTopApi();

  useEffect(() => {
    breadcrumbApi.addFooter(
      <div className="NominateExpert-breadcrumb-footer">
        Upon receiving the nomination, the nominee will have 3 weeks to submit their application
      </div>
    );

    return () => breadcrumbApi.removeFooter();
  }, [breadcrumbApi]);

  const onIndividualClick = useCallback(() => setType(EntityType.Individual), []);
  const onOrganisationClick = useCallback(() => setType(EntityType.Organisation), []);

  const onPageOneSubmit = useCallback(
    (model: Required<PageOneFormType>) => {
      setCurrentPage(Pages.Two);
      setPageOneForm(model);
      scrollToTop();
      if (pageTwoForm && model.businessCase !== pageTwoForm.businessCase) {
        setPageTwoForm(undefined);
      }
    },
    [pageTwoForm, scrollToTop]
  );

  const onPageTwoBack = useCallback(
    (model: PageTwoFormType) => {
      setCurrentPage(Pages.One);
      setPageTwoForm(model);
      scrollToTop();
    },
    [scrollToTop]
  );

  const onPageTwoSubmit = useCallback(
    (model: PageTwoFormType) => {
      issueOnboard({
        variables: { input: { invitationForm: JSON.stringify({ ...pageOneForm, ...model }) } },
      })
        .then((res) => setSubmitResult(res.data?.payload?.onboard))
        .catch((err) => toast.error(extractErrorMessages(err).join(", ")));
    },
    [pageOneForm, issueOnboard]
  );

  const onSuccessModalClose = useCallback(() => {
    const search = new URLSearchParams();
    search.set(S.myArea.queryVarNames.tab, Tabs.Sent);
    history.push({ pathname: S.myArea.path, search: search.toString() });
  }, [history]);

  const form = useMemo(() => {
    if (!type) {
      return null;
    } else if (currentPage === Pages.One) {
      if (isExpertBaseInviteType(pageOneForm)) {
        return <ExpertBaseInviteForm initialModel={pageOneForm} onSubmit={onPageOneSubmit} />;
      } else if (isOrganisationBaseInviteType(pageOneForm)) {
        return <OrganisationBaseInviteForm initialModel={pageOneForm} onSubmit={onPageOneSubmit} />;
      } else {
        return type === EntityType.Individual ? (
          <ExpertBaseInviteForm onSubmit={onPageOneSubmit} />
        ) : (
          <OrganisationBaseInviteForm onSubmit={onPageOneSubmit} />
        );
      }
    } else if (currentPage === Pages.Two) {
      if (!pageOneForm || !pageOneForm.businessCase) {
        return null; // Not possible but needed for type checking.
      }

      let initialModel;
      switch (pageOneForm.businessCase) {
        case BusinessCases.CoInvestor:
          initialModel = isCoInvestorInviteType(pageTwoForm) ? pageTwoForm : undefined;
          return (
            <CoInvestorInviteForm
              initialModel={initialModel}
              onBack={onPageTwoBack}
              onSubmit={onPageTwoSubmit}
            />
          );
        case BusinessCases.Introducer:
          initialModel = isIntroducerInviteType(pageTwoForm) ? pageTwoForm : undefined;
          return (
            <IntroducerInviteForm
              initialModel={initialModel}
              onBack={onPageTwoBack}
              onSubmit={onPageTwoSubmit}
            />
          );
        case BusinessCases.EarlyProfessional:
        case BusinessCases.ProminentSkills:
          initialModel = isEarlyProfessionalOrProminentSkillsInviteType(pageTwoForm)
            ? pageTwoForm
            : { businessCase: pageOneForm.businessCase };

          return (
            <EarlyProfessionalOrProminentSkillsInviteForm
              initialModel={initialModel}
              onBack={onPageTwoBack}
              onSubmit={onPageTwoSubmit}
            />
          );
      }
      assertUnreachable(pageOneForm.businessCase);
    }
  }, [
    type,
    pageOneForm,
    pageTwoForm,
    currentPage,
    onPageOneSubmit,
    onPageTwoBack,
    onPageTwoSubmit,
  ]);

  return (
    <Grid className="NominateExpert">
      <Grid.Row columns="2">
        <Grid.Column width="10">
          <div className="NominateExpert-Form">
            <Grid>
              <Grid.Row>
                <Grid.Column className="NominateExpert-Form-Header">
                  <div className="NominateExpert-Form-Header-title">Invitation form</div>
                  <div className="NominateExpert-Form-Header-currPage">
                    Page {!pageOneForm ? 1 : 2}/2
                  </div>
                </Grid.Column>
              </Grid.Row>
              {currentPage === Pages.One && (
                <Grid.Row>
                  <Grid.Column className="NominateExpert-Form-EntityType">
                    <div>Is the expert you'd like to nominate an individual or organisation? *</div>
                    <div className="NominateExpert-Form-EntityType-buttons">
                      <Button
                        basic
                        className={type === EntityType.Individual ? "active" : ""}
                        type="button"
                        onClick={onIndividualClick}
                      >
                        Individual
                      </Button>
                      <Button
                        basic
                        className={type === EntityType.Organisation ? "active" : ""}
                        type="button"
                        onClick={onOrganisationClick}
                      >
                        Organisation
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}
              {!!type && (
                <Grid.Row>
                  <Grid.Column>{form}</Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </div>
        </Grid.Column>
        <Grid.Column width="1" />
        <Grid.Column width="5">
          <div className="NominateExpert-RightCol-header">
            <Image src={expertIcon} />
            <div>Expert Core Values</div>
          </div>
          <div className="NominateExpert-RightCol-title">Unified</div>
          <p className="NominateExpert-RightCol-paragraph">
            We're a community. We look out for each other and believe in working towards a common
            goal to achieve collective success.
          </p>
          <div className="NominateExpert-RightCol-title">Purposeful</div>
          <p className="NominateExpert-RightCol-paragraph">
            We have a relentless drive to learn, question and engage, because we want to make a
            valuable contribution to our ecosystem.
          </p>
          <div className="NominateExpert-RightCol-title">Bold</div>
          <p className="NominateExpert-RightCol-paragraph">
            We're not afraid to take intelligent risks and will gladly step out of our comfort zone
            to find innovative solutions to difficult challenges.
          </p>
          <div className="NominateExpert-RightCol-title">Agile</div>
          <p className="NominateExpert-RightCol-paragraph">
            We are inherently flexible in our approach. We move quickly to identify issues and adapt
            to suit the needs of portfolio companies and our ecosystem.
          </p>
          <div className="NominateExpert-RightCol-title">Resilient</div>
          <p className="NominateExpert-RightCol-paragraph">
            We have a 'test and learn' mindset, we're comfortable with change and we never lose our
            focus.
          </p>
          <div className="NominateExpert-RightCol-title">Authentic</div>
          <p className="NominateExpert-RightCol-paragraph">
            We deliver on our promises and take full accountability for our actions, both
            professionally and personally.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Modal
        className="CustomClose NominateExpert-modal"
        closeIcon
        open={!!submitResult}
        onClose={onSuccessModalClose}
        size="small"
      >
        <div className="NominateExpert-modal-title">Your nomination has been made</div>
        <p className="NominateExpert-modal-paragraph">
          {submitResult?.fullName || "The nominee"} has 3 weeks to accept the nomination and submit
          their application so that we can get to know them better. Upon receiving their
          application, a member of our team will reach out to provide guidance on next steps.
        </p>
        <Button
          className="NominateExpert-modal-button"
          primary
          content="Continue"
          onClick={onSuccessModalClose}
        />
      </Modal>
    </Grid>
  );
};

const isExpertBaseInviteType = (v: PageOneFormType | undefined): v is ExpertBaseInviteType =>
  v?.type === EntityType.Individual;

const isOrganisationBaseInviteType = (
  v: PageOneFormType | undefined
): v is OrganisationBaseInviteType => v?.type === EntityType.Organisation;

const isCoInvestorInviteType = (v: PageTwoFormType | undefined): v is CoInvestorInviteType =>
  v?.businessCase === BusinessCases.CoInvestor;

const isIntroducerInviteType = (v: PageTwoFormType | undefined): v is IntroducerInviteType =>
  v?.businessCase === BusinessCases.Introducer;

const isEarlyProfessionalOrProminentSkillsInviteType = (
  v: PageTwoFormType | undefined
): v is EarlyProfessionalOrProminentSkillsInviteType =>
  v?.businessCase === BusinessCases.EarlyProfessional ||
  v?.businessCase === BusinessCases.ProminentSkills;
