import { BusinessCases } from "../../../../../../schemas/invites/_types";
import { isDefined, isEnum } from "../../../../../../types";
import { AdminOnboard, isOrganisationOnboard } from "../../../../../../types/onboard";
import { isStartupOnboard, isIndividualOnboard } from "../../../../../../types/onboard";
import { ReceivedOnboard, OnboardStatus, OnboardState } from "../../../../../../types/onboard";
import { OnboardStep, StartupSteps } from "../../../../../../types/OnboardForm";
import { IndividualSteps, OrganisationSteps } from "../../../../../../types/OnboardForm";

export interface StepDetails {
  readonly title: string;
  readonly completed: boolean;
  readonly active: boolean;
  readonly disabled: boolean;
  readonly rejected: boolean;
}

// Since we only initialise the currentStep after amending the onboard for the first time,
// This function tell us which step we are and enforces the initial step,
// And prevents render issues when the users are viewing an onboard that is declined.
export const getCurrentOnboardStep = (onboard: ReceivedOnboard | AdminOnboard): OnboardStep => {
  const { form, acceptedAt } = onboard;
  if (!form) {
    return 0;
  }
  const { currentStep } = form.data;
  if (currentStep === undefined && isDefined(acceptedAt)) {
    return 1;
  } else if (currentStep === undefined && !isDefined(acceptedAt)) {
    return 0;
  } else {
    return currentStep || 1;
  }
};

export const stepDetails = (
  onboard: ReceivedOnboard | AdminOnboard,
  isActor: boolean
): ReadonlyArray<StepDetails> => {
  const { form } = onboard;
  if (!form) {
    console.warn("Missing onboard form.");
    return [];
  }

  const step = getCurrentOnboardStep(onboard);

  if (isStartupOnboard(onboard) && isEnum(step, StartupSteps)) {
    return stepsForStartup(step, onboard, isActor);
  } else if (isIndividualOnboard(onboard) && isEnum(step, IndividualSteps)) {
    return stepsForIndividual(step, onboard, isActor);
  } else if (isOrganisationOnboard(onboard) && isEnum(step, OrganisationSteps)) {
    return stepsForOrganisation(step, onboard, isActor);
  }
  // This clause matches when the onboard was already submitted, the number of steps decreased and
  // `onboard.currentStep` was not migrated (for example, `onboard.currentStep` === 3 and now there's
  // only 2 steps). On this scenario we will default to the current max step for each type.
  else if (onboard.state !== OnboardState.Pending && onboard.state !== OnboardState.InCompletion) {
    if (isStartupOnboard(onboard)) {
      return stepsForStartup(StartupSteps.PreSelectionData, onboard, isActor);
    } else if (isIndividualOnboard(onboard)) {
      return stepsForIndividual(IndividualSteps.ProfileData, onboard, isActor);
    } else if (isOrganisationOnboard(onboard)) {
      return stepsForOrganisation(OrganisationSteps.ProfileData, onboard, isActor);
    }
  }
  return [];
};

const stepsForIndividual = (
  currStep: IndividualSteps,
  onboard: ReceivedOnboard | AdminOnboard,
  isActor: boolean
) => {
  const { state, status, recipient, submittedAt, form } = onboard;
  const isRejected = status === OnboardStatus.Rejected || status === OnboardStatus.Declined;

  const init: StepDetails = {
    title: "Invite",
    completed: currStep > IndividualSteps.Init,
    active: currStep === IndividualSteps.Init && !isRejected,
    rejected: currStep === IndividualSteps.Init && isRejected,
    disabled: currStep < IndividualSteps.Init,
  };
  const terms: StepDetails = {
    title: "Why join?",
    completed: currStep > IndividualSteps.Terms,
    active: currStep === IndividualSteps.Terms && !isRejected,
    rejected: currStep === IndividualSteps.Terms && isRejected,
    disabled: currStep < IndividualSteps.Terms,
  };
  const kyc: StepDetails = {
    title: form?.data.businessCase === BusinessCases.CoInvestor ? "KYC / AML" : "KYC",
    completed: currStep > IndividualSteps.KYC,
    active: currStep === IndividualSteps.KYC && !isRejected,
    rejected: currStep === IndividualSteps.KYC && isRejected,
    disabled: currStep < IndividualSteps.KYC,
  };

  const profileData: StepDetails = {
    title: "Profile",
    completed: currStep >= IndividualSteps.ProfileData && isDefined(submittedAt),
    active: currStep === IndividualSteps.ProfileData && state === OnboardState.InCompletion,
    rejected: currStep === IndividualSteps.ProfileData && isRejected,
    disabled: currStep < IndividualSteps.ProfileData,
  };
  const mutualAssessment: StepDetails = {
    title: "Mutual Assessment",
    completed: state === OnboardState.AwaitingSignature || status === OnboardStatus.Finalised,
    active:
      state === OnboardState.AwaitingEnableVoting ||
      state === OnboardState.AwaitingSeconders ||
      state === OnboardState.InPreselection ||
      state === OnboardState.TimedOut ||
      state === OnboardState.UnderReview ||
      state === OnboardState.InMutualAssessment ||
      state === OnboardState.InCommitteeReview,
    rejected: isDefined(submittedAt) && isRejected,
    disabled:
      (state === OnboardState.Pending ||
        state === OnboardState.InCompletion ||
        state === OnboardState.Closed) &&
      !isDefined(submittedAt),
  };
  const sign: StepDetails = {
    title: "Sign Your Application",
    completed: status === OnboardStatus.Finalised,
    active: state === OnboardState.AwaitingSignature,
    rejected: isRejected,
    disabled: state !== OnboardState.AwaitingSignature && status !== OnboardStatus.Finalised,
  };
  const mattermost: StepDetails = {
    title: "Connect to Mattermost",
    completed: status === OnboardStatus.Finalised && !!recipient?.mmUserId,
    active: status === OnboardStatus.Finalised && !recipient?.mmUserId,
    rejected: isRejected,
    disabled: status !== OnboardStatus.Finalised,
  };
  const welcome: StepDetails = {
    title: "Welcome",
    completed: status === OnboardStatus.Finalised && !!recipient?.mmUserId && isActor,
    active: status === OnboardStatus.Finalised && !!recipient?.mmUserId && !isActor,
    rejected: isRejected,
    disabled: status !== OnboardStatus.Finalised || !recipient?.mmUserId,
  };

  return [init, terms, kyc, profileData, mutualAssessment, sign, mattermost, welcome];
};

const stepsForOrganisation = (
  currStep: OrganisationSteps,
  onboard: ReceivedOnboard | AdminOnboard,
  isActor: boolean
) => {
  const { state, status, recipient, submittedAt } = onboard;
  const isRejected = status === OnboardStatus.Rejected;

  const init: StepDetails = {
    title: "Invite",
    completed: currStep > OrganisationSteps.Init,
    active: currStep === OrganisationSteps.Init && !isRejected,
    rejected: currStep === OrganisationSteps.Init && isRejected,
    disabled: currStep < OrganisationSteps.Init,
  };
  const terms: StepDetails = {
    title: "Why join?",
    completed: currStep > OrganisationSteps.Terms,
    active: currStep === OrganisationSteps.Terms && !isRejected,
    rejected: currStep === OrganisationSteps.Terms && isRejected,
    disabled: currStep < OrganisationSteps.Terms,
  };
  const profileData: StepDetails = {
    title: "Profile",
    completed: currStep >= OrganisationSteps.ProfileData && isDefined(submittedAt),
    active: currStep === OrganisationSteps.ProfileData && state === OnboardState.InCompletion,
    rejected: currStep === OrganisationSteps.ProfileData && isRejected,
    disabled: currStep < OrganisationSteps.ProfileData,
  };
  const mutualAssessment: StepDetails = {
    title: "Mutual Assessment",
    completed: state === OnboardState.AwaitingSignature || status === OnboardStatus.Finalised,
    active:
      state === OnboardState.AwaitingEnableVoting ||
      state === OnboardState.AwaitingSeconders ||
      state === OnboardState.InPreselection ||
      state === OnboardState.TimedOut ||
      state === OnboardState.UnderReview ||
      state === OnboardState.InMutualAssessment ||
      state === OnboardState.InCommitteeReview,
    rejected: isDefined(submittedAt) && isRejected,
    disabled:
      (state === OnboardState.Pending ||
        state === OnboardState.InCompletion ||
        state === OnboardState.Closed) &&
      !isDefined(submittedAt),
  };
  const sign: StepDetails = {
    title: "Sign Your Application",
    completed: status === OnboardStatus.Finalised,
    active: state === OnboardState.AwaitingSignature,
    rejected: isRejected,
    disabled: state !== OnboardState.AwaitingSignature && status !== OnboardStatus.Finalised,
  };
  const mattermost: StepDetails = {
    title: "Connect to Mattermost",
    completed: status === OnboardStatus.Finalised && !!recipient?.mmUserId,
    active: status === OnboardStatus.Finalised && !recipient?.mmUserId,
    rejected: isRejected,
    disabled: status !== OnboardStatus.Finalised,
  };
  const welcome: StepDetails = {
    title: "Welcome",
    completed: status === OnboardStatus.Finalised && !!recipient?.mmUserId && isActor,
    active: status === OnboardStatus.Finalised && !!recipient?.mmUserId && !isActor,
    rejected: isRejected,
    disabled: status !== OnboardStatus.Finalised || !recipient?.mmUserId,
  };

  return [init, terms, profileData, mutualAssessment, sign, mattermost, welcome];
};

const stepsForStartup = (
  currStep: StartupSteps,
  onboard: ReceivedOnboard | AdminOnboard,
  isActor: boolean
) => {
  const { state, status, recipient, submittedAt } = onboard;
  const isRejected = status === OnboardStatus.Rejected || status === OnboardStatus.Declined;

  const init: StepDetails = {
    title: "Invite",
    completed: currStep > StartupSteps.Init,
    active: currStep === StartupSteps.Init && !isRejected,
    rejected: currStep === StartupSteps.Init && isRejected,
    disabled: currStep < StartupSteps.Init,
  };
  const terms: StepDetails = {
    title: "Why join?",
    completed: currStep > StartupSteps.Terms,
    active: currStep === StartupSteps.Terms && !isRejected,
    rejected: currStep === StartupSteps.Terms && isRejected,
    disabled: currStep < StartupSteps.Terms,
  };
  const preSelectionData: StepDetails = {
    title: "Pre-selection Form",
    completed: currStep >= StartupSteps.PreSelectionData && isDefined(submittedAt),
    active: currStep === StartupSteps.PreSelectionData && state === OnboardState.InCompletion,
    rejected: currStep === StartupSteps.PreSelectionData && isRejected,
    disabled: currStep < StartupSteps.PreSelectionData,
  };
  const upvoting: StepDetails = {
    title: "Community Feedback",
    completed: state === OnboardState.AwaitingSignature || status === OnboardStatus.Finalised,
    active:
      state === OnboardState.AwaitingEnableVoting ||
      state === OnboardState.InPreselection ||
      state === OnboardState.TimedOut ||
      state === OnboardState.UnderReview,
    rejected: isDefined(submittedAt) && isRejected,
    disabled:
      (state === OnboardState.Pending ||
        state === OnboardState.InCompletion ||
        state === OnboardState.Closed) &&
      !isDefined(submittedAt),
  };
  const sign: StepDetails = {
    title: "Sign Your Application",
    completed: status === OnboardStatus.Finalised,
    active: state === OnboardState.AwaitingSignature,
    rejected: isRejected,
    disabled: state !== OnboardState.AwaitingSignature && status !== OnboardStatus.Finalised,
  };
  const mattermost: StepDetails = {
    title: "Connect to Mattermost",
    completed: status === OnboardStatus.Finalised && !!recipient?.mmUserId,
    active: status === OnboardStatus.Finalised && !recipient?.mmUserId,
    rejected: isRejected,
    disabled: status !== OnboardStatus.Finalised,
  };
  const welcome: StepDetails = {
    title: "Welcome",
    completed: status === OnboardStatus.Finalised && !!recipient?.mmUserId && isActor,
    active: status === OnboardStatus.Finalised && !!recipient?.mmUserId && !isActor,
    rejected: isRejected,
    disabled: status !== OnboardStatus.Finalised || !recipient?.mmUserId,
  };

  return [init, terms, preSelectionData, upvoting, sign, mattermost, welcome];
};
