import { useRef, useEffect, MutableRefObject, useCallback } from "react";
import { dequal } from "dequal";

type DependencyList = ReadonlyArray<any>;

/**
 * `useCancelablePromises` returns a function (`makeCancelable`) that takes any promise and returns a new promise that
 * will only resolve or reject if the component that calls this hook is still mounted and, if given a dependency array,
 * if the dependency values when `makeCancelable` was called are still the same when the original promise resolves/rejects.
 *
 * Note that the returned function will persist for the full lifetime of the caller component to prevent unnecessary renders.
 *
 */
export const useCancelablePromises = (deps?: DependencyList) => {
  const isMounted = useRef(true);
  const depsRef: MutableRefObject<DependencyList> = useRef([]);

  if (deps && !dequal(deps, depsRef.current)) {
    depsRef.current = deps;
  }

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const makeCancelable = useCallback(<V>(promise: Promise<V>) => {
    const cancelable = new Promise((res: (value: V) => void, rej: (reason: any) => void) => {
      const closureDeps = depsRef.current;
      promise
        .then((value) => {
          const unchangedDeps = dequal(closureDeps, depsRef.current);

          if (isMounted.current && unchangedDeps) {
            res(value);
          }
        })
        .catch((err) => {
          const unchangedDeps = dequal(closureDeps, depsRef.current);

          if (isMounted.current && unchangedDeps) {
            rej(err);
          }
        });
    });

    return cancelable;
  }, []);

  return { makeCancelable };
};
