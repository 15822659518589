import { QueryLazyOptions } from "@apollo/client";
import { CSSProperties, useCallback } from "react";
import { useForm } from "uniforms";
import { PaginationLinks } from "../../elements/PaginationLinks";
import { Variables, Result } from "../../../api/admin/Users";

const paginationLinksStyle: CSSProperties = { marginTop: "40px" };

interface Props {
  readonly first: number;
  readonly data: Result | undefined;
  readonly users: (options?: QueryLazyOptions<Variables> | undefined) => void;
  readonly loading: boolean;
}

export const AdminUsersPaginationLinksField = ({ first, data, users, loading }: Props) => {
  const { model } = useForm();

  const firstOnClick = useCallback(() => {
    users({ variables: { first, ...model } });
  }, [first, model, users]);

  const previousOnClick = useCallback(() => {
    users({ variables: { last: first, before: data?.users?.pageInfo.startCursor, ...model } });
  }, [first, model, data, users]);

  const nextOnClick = useCallback(() => {
    users({ variables: { first, after: data?.users?.pageInfo.endCursor, ...model } });
  }, [first, model, data, users]);

  return (
    <PaginationLinks
      style={paginationLinksStyle}
      pageInfo={data?.users?.pageInfo}
      firstOnClick={firstOnClick}
      previousOnClick={previousOnClick}
      nextOnClick={nextOnClick}
      loading={loading}
    />
  );
};
