import { gql } from "@apollo/client";
import { WrappedUpload } from "../../types/wrappedUpload";
import { PROFILE_FRAGMENT } from "../_fragments/Profile";
import { OrganisationProfile } from "../../types/profile";

export const MUTATION = gql`
  mutation UpdateOrganisationProfile($input: UpdateOrganisationProfileInput!) {
    payload: updateOrganisationProfile(input: $input) {
      subject {
        ... on Role {
          id
          avatarUrl
          profile {
            ...Profile
          }
        }
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;

export interface OrganisationProfileInput {
  readonly avatar?: WrappedUpload;
  readonly headline?: string;
  readonly description?: string;
  readonly name?: string;
  readonly linkedinProfileUrl?: string;
}

interface UpdateOrganisationProfileInput {
  readonly subjectId: string;
  readonly profile: OrganisationProfileInput;
}

export interface Variables {
  readonly input: UpdateOrganisationProfileInput;
}

interface Subject {
  readonly id: string;
  readonly avatarUrl: string;
  readonly profile?: OrganisationProfile;
}

export interface Result {
  readonly payload?: { readonly subject: Subject };
}
