import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
import { Enrolled } from "../../../../contexts/Session/state";
import { EvaluateCompletionForm } from "../../../../schemas/ticketDashboard/EvaluateCompletionForm";

interface Props {
  readonly sessionState: Enrolled;
  readonly ticket: Ticket;
}

export const EvaluateCompletionOverlay = ({ sessionState, ticket }: Props) => {
  return (
    <div className="EvaluateCompletionOverlay">
      <div className="EvaluateCompletionOverlay-title">
        Evaluation of {ticket.ticketForm?.data.title}
      </div>
      <span>
        Provide an evaluation for the work delivered. Once submitted,{" "}
        {ticket.winningBid?.owner.fullName} will be able to see this evaluation.
      </span>
      <br />
      <br />
      <div className="EvaluateCompletionOverlay-info-label">Scale</div>
      <span>
        <b>1</b> Not at all&nbsp; · &nbsp;<b>2</b> Kind of&nbsp; · &nbsp;<b>3</b> Moderately&nbsp; ·
        &nbsp;<b>4</b> Very&nbsp; · &nbsp;<b>5</b> Completely
      </span>
      <br />
      <br />
      <EvaluateCompletionForm sessionState={sessionState} ticket={ticket} />
    </div>
  );
};
