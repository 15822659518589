import { OrganisationInviteType } from "../../components/pages/Invite/_types";
import { Migratable, Migrated, MigrateFunction } from "./../_helpers";
import { runMigrationDictionary, versionBump } from "./../_helpers";

export class OrganisationInviteMigrator<T extends Migratable<OrganisationInviteType>> {
  private instance: T;
  constructor(instance: T) {
    this.instance = instance;
  }

  migrate: MigrateFunction<T> = () => {
    const { invitationForm } = this.instance;

    if (!invitationForm || !invitationForm.schemaVersion || !invitationForm.data) {
      return this.instance as Migrated<T>;
    }

    const migratedForm = runMigrationDictionary<OrganisationInviteType>(
      invitationForm,
      ORGANISATION_INVITE_MIGRATION_DICTIONARY
    );

    return { ...this.instance, migratedForm } as Migrated<T>;
  };
}

// Note: the latest version should never be included here.
const ORGANISATION_INVITE_MIGRATION_DICTIONARY = {
  "0": versionBump,
  "1": versionBump,
  "2": versionBump,
};
