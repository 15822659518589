import { useCallback, useMemo, MouseEvent } from "react";
import { Button, Container } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { SentOnboard } from "../../../api/onboards/SentOnboard";
import { isSuccessState } from "../../../contexts/Generic";
import { useSettingsState } from "../../../contexts/Settings";
import { OnboardState } from "../../../types/onboard";
import { isExpertOnboard, isStartupOnboard } from "./_types";
import { MUTATION as CancelMut } from "../../../api/onboards/CancelOnboard";
import { Variables as CancelVars } from "../../../api/onboards/CancelOnboard";
import { Result as CancelRes } from "../../../api/onboards/CancelOnboard";
import { MUTATION as ResendMut } from "../../../api/onboards/ResendOnboardIssuanceEmail";
import { Variables as ResendVars } from "../../../api/onboards/ResendOnboardIssuanceEmail";
import { Result as ResendRes } from "../../../api/onboards/ResendOnboardIssuanceEmail";
import { Tabs as MyAreaTabs } from "../../pages/MyArea";
import { ApiError, extractErrorMessages, noResultErrorFor } from "../../../types";
import { Shortcuts as S } from "../../../routing";
import dateFormat from "dateformat";
import { utils } from "../../../utils/utils";

const tabVarNameMyArea = S.myArea.queryVarNames.tab;
const myAreaLink = S.myArea.path;

interface Props {
  readonly onboard: SentOnboard;
}

export const SentInviteBreadcrumb = ({ onboard }: Props) => {
  const settings = useSettingsState();

  const [cancelInvite, { loading: cancelLoading }] = useMutation<CancelRes, CancelVars>(CancelMut);
  const [resendInvite, { loading: resendLoading }] = useMutation<ResendRes, ResendVars>(ResendMut);
  const loading = cancelLoading || resendLoading;

  const { insertedAt, state, id } = onboard;

  const title = useMemo(() => {
    if (isStartupOnboard(onboard)) {
      return "Startup";
    } else if (isExpertOnboard(onboard)) {
      return "Expert";
    } else {
      return "Organisation";
    }
  }, [onboard]);

  const isPending = state === OnboardState.Pending;

  const expiresAt = useMemo(() => {
    if (!isSuccessState(settings)) {
      return;
    }
    return utils.dateOffset(insertedAt, settings.result.core.invitation_ttl);
  }, [insertedAt, settings]);

  const handleCancelInvite = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (loading) {
        return;
      }
      cancelInvite({ variables: { input: { onboardId: id } } })
        .then((res) => {
          if (!res.data || !res.data.payload) {
            return Promise.reject(noResultErrorFor("Cancel Invite"));
          }
          toast.success(`You canceled the invite.`);
          return Promise.resolve();
        })
        .catch((e: ApiError) => {
          toast.error(extractErrorMessages(e).join(", "));
        });
    },
    [cancelInvite, id, loading]
  );
  const handleResendInvite = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      resendInvite({ variables: { input: { onboardId: id } } })
        .then((res) => {
          if (!res.data || !res.data.payload || !res.data.payload.onboard) {
            return Promise.reject(noResultErrorFor("ResendInvite"));
          }
          toast.success("An email was sent to the recipient of this invite.");
          return Promise.resolve();
        })
        .catch((e: ApiError) => {
          toast.error(extractErrorMessages(e).join(", "));
        });
    },
    [resendInvite, id]
  );

  return (
    <div className="SentInviteBreadcrumb">
      <div>
        <Container
          as={Link}
          to={`${myAreaLink}?${tabVarNameMyArea}=${MyAreaTabs.Sent}`}
          className="SentInviteBreadcrumb-link"
        >
          <span> My Area. Sent invites</span>
        </Container>
      </div>
      <div className="SentInviteBreadcrumb-separator" />
      <div className="SentInviteBreadcrumb-wrapper">
        <div className="SentInviteBreadcrumb-title">{title} Invite</div>
        {isPending && (
          <div className="SentInviteBreadcrumb-link" onClick={handleCancelInvite}>
            Cancel Invite
          </div>
        )}
      </div>
      {isPending && expiresAt && (
        <div className="SentInviteBreadcrumb-wrapper">
          <div>
            <div className="SentInviteBreadcrumb-subtitle">Expires on</div>
            {dateFormat(expiresAt, "dd/mm/yy")}
          </div>
          <Button color="blue" loading={loading} onClick={handleResendInvite}>
            Resend Invite
          </Button>
        </div>
      )}
    </div>
  );
};
