import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation PublishDraftTicketBid($input: PublishDraftTicketBidInput!) {
    payload: publishDraftTicketBid(input: $input) {
      bid {
        id
      }
    }
  }
`;

export interface Variables {
  readonly input: PublishDraftTicketBidInput;
}

interface PublishDraftTicketBidInput {
  readonly draftId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: PublishDraftTicketBidPayload;
}

interface PublishDraftTicketBidPayload {
  readonly bid: Identifiable;
}
