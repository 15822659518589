import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketBidStatus, TicketBidState } from "../../types/bid";

export const MUTATION = gql`
  mutation requestTicketBidEvaluation($input: RequestTicketBidEvaluationInput!) {
    payload: requestTicketBidEvaluation(input: $input) {
      bid {
        id
        status
        state
        evaluationRequest {
          id
          bidEvaluationRequestedAt
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: RequestTicketBidEvaluationInput;
}

interface RequestTicketBidEvaluationInput {
  readonly bidId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: RequestTicketBidEvaluationPayload;
}

interface RequestTicketBidEvaluationPayload {
  readonly bid: {
    readonly status: TicketBidState;
    readonly state: TicketBidStatus;
    readonly evaluationRequest: { readonly bidEvaluationRequestedAt?: string } & Identifiable;
  } & Identifiable;
}
