import { gql } from "@apollo/client";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { ReceivedOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation SubmitOnboard($input: SubmitOnboardInput!) {
    payload: submitOnboard(input: $input) {
      onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

interface SubmitOnboardInput {
  readonly onboardId: string;
}
export interface Variables {
  readonly input: SubmitOnboardInput;
}

export interface Result {
  readonly payload?: { readonly onboard: ReceivedOnboard };
}
