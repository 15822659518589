import { useMemo } from "react";
import { Header, Divider } from "semantic-ui-react";
import { Enrolled } from "../../../../contexts/Session/state";
import { IndividualProfileForm } from "./IndividualProfileForm";
import { OrganisationProfileForm } from "./OrganisationProfileForm";
import { RoleTagsForm } from "./RoleTagsForm";
import { selectedRole } from "../../../../contexts/Session/helpers";
import { readableRoleType } from "../../../../types/role";
import { isIndividualProfile, isOrganisationProfile } from "../../../../types/profile";

interface Props {
  readonly sessionState: Enrolled;
}

export const RoleSection = ({ sessionState }: Props) => {
  const role = useMemo(
    () => selectedRole(sessionState.user.roles, sessionState.roleId),
    [sessionState]
  );

  return (
    <>
      <Header as="h2">
        {role.fullName}, {readableRoleType(role)}
      </Header>
      {isIndividualProfile(role.profile) && (
        <>
          <IndividualProfileForm subjectId={role.id} profile={role.profile} />
          <Divider hidden />
        </>
      )}
      {isOrganisationProfile(role.profile) && (
        <>
          <OrganisationProfileForm
            roleId={role.id}
            profile={role.profile}
            avatarURL={role.avatarUrl}
          />
          <Divider hidden />
        </>
      )}
      <RoleTagsForm role={role} />
    </>
  );
};
