import { isString } from "lodash";
import React, { useCallback } from "react";
import classNames from "classnames";
import { DropdownItemProps, DropdownProps, Form } from "semantic-ui-react";
import { connectField, HTMLFieldProps, useForm } from "uniforms";
import { PreselectionForm as StartupPreselectionSchema } from "../schemas/onboards/utils/_types";
import { FundraisingInfo } from "../types/OnboardForm";

interface Props extends HTMLFieldProps<string, HTMLElement> {
  readonly [k: string]: unknown;
  readonly dropdownOptions: DropdownItemProps[];
}
export const CustomDropdownFundRaising = connectField((props: Props) => {
  const { placeholder, onChange, required, dropdownOptions, error, className } = props;
  const { disabled, errorMessage, label } = props;
  const { onChange: changeModel } = useForm<StartupPreselectionSchema>();

  const customOnChange = useCallback(
    (_: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      if (value === FundraisingInfo.NOT_RAISING) {
        changeModel("startupData.amountRaised", undefined);
        changeModel("startupData.equityOffered", undefined);
        changeModel("startupData.hasLeadInvestor", undefined);
      }
      if (isString(value)) {
        return onChange(value);
      }
    },
    [onChange, changeModel]
  );

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <Form.Dropdown
        required={required}
        selection
        placeholder={placeholder}
        options={dropdownOptions}
        onChange={customOnChange}
      />
      <div>{!!error && <div className="ui red basic pointing label">{errorMessage}</div>}</div>
    </div>
  );
});
