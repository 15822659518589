// TODO: consider removing this class and use the generic states and reducer instead.
export interface Queryable {
  readonly busy?: boolean;
  readonly errors?: ReadonlyArray<string>;
}
type Reducer<S> = (s: S) => S;

export const setBusy = <S extends Queryable>(): Reducer<S> => s => ({
  ...s,
  busy: true,
  errors: undefined,
});

export const setIddle = <S extends Queryable>(): Reducer<S> => s => ({
  ...s,
  busy: false,
});

export const setQueryResult = <S extends Queryable, K extends keyof S>(
  key: K,
  value: S[K]
): Reducer<S> => s => ({ ...s, busy: false, [key]: value });
