import omit from "lodash/omit";
import classNames from "classnames";
import { RadioFieldProps } from "uniforms-semantic";
import { connectField, filterDOMProps } from "uniforms";

export const PreselectionRadioField = connectField(
  ({
    allowedValues,
    className,
    disabled,
    error,
    errorMessage,
    id,
    label,
    name,
    onChange,
    readOnly,
    required,
    showInlineError,
    transform,
    value,
    ...props
  }: RadioFieldProps) => {
    const cNames = classNames(
      className,
      { disabled, error },
      "grouped fields PreselectionRadioField"
    );
    return (
      <div className={cNames} {...omit(filterDOMProps(props), ["checkboxes"])}>
        {label && (
          <div className={classNames({ required }, "field")}>
            <label>{label}</label>
          </div>
        )}

        {allowedValues?.map((item: string) => {
          const change = () => {
            if (!readOnly) {
              onChange(item);
            }
          };
          const isSelected = value === item;

          return (
            <div className={`field${isSelected ? " selected" : ""}`} key={item}>
              <div className="ui radio checkbox">
                <input
                  checked={item === value}
                  disabled={disabled}
                  id={`${id}-${escape(item)}`}
                  name={name}
                  onChange={change}
                  type="radio"
                />

                <label htmlFor={`${id}-${escape(item)}`}>
                  {transform ? transform(item) : item}
                </label>
              </div>
            </div>
          );
        })}

        {!!(error && showInlineError) && (
          <div className="ui red basic pointing label">{errorMessage}</div>
        )}
      </div>
    );
  }
);
