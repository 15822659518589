import classnames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback } from "react";
import { CSSProperties } from "react";
import { Button, Image } from "semantic-ui-react";
import { connectField, filterDOMProps, joinName, useField } from "uniforms";
import { ListAddFieldProps } from "uniforms-semantic";
import plusIcon from "../assets/plus-sign-filled-circle.svg";

const buttonTextWrapperStyle: CSSProperties = { display: "inline-flex" };
const iconStyle: CSSProperties = { marginLeft: "5px" };

interface Props extends ListAddFieldProps {
  readonly [k: string]: unknown;
  readonly iconText: string;
}

export const CustomListAddField = connectField<Props>(
  ({ disabled, initialCount, name, readOnly, value, className, iconText, ...props }) => {
    const nameParts = joinName(null, name);
    const parentName = joinName(nameParts.slice(0, -1));
    const parent = useField<{ initialCount?: number; maxCount?: number }, unknown[]>(
      parentName,
      { initialCount },
      { absoluteName: true }
    )[0];

    const limitNotReached = !disabled && !(parent.maxCount! <= parent.value!.length);
    const c = classnames("ui", className, limitNotReached ? "link" : "disabled", "fitted add icon");

    const onAction = useCallback(
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (limitNotReached && !readOnly && (!("key" in event) || event.key === "Enter")) {
          parent.onChange(parent.value!.concat([cloneDeep(value)]));
        }
      },
      [limitNotReached, parent, readOnly, value]
    );

    return (
      <Button
        {...filterDOMProps(props)}
        className={c}
        onClick={onAction}
        onKeyDown={onAction}
        role="button"
        tabIndex={0}
        basic
        color="blue"
        type="button"
      >
        <span style={buttonTextWrapperStyle}>
          <b>{iconText}</b>
          <Image src={plusIcon} style={iconStyle} />
        </span>
      </Button>
    );
  },
  {
    initialValue: false,
    kind: "leaf",
  }
);
