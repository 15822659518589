import { gql } from "@apollo/client";
import { SeconderCandidacy } from "../../types/onboard";

export const MUTATION = gql`
  mutation ApplyAsSeconder($input: ApplyAsOnboardSeconderInput!, $roleId: ID!) {
    payload: applyAsOnboardSeconder(input: $input) {
      seconderCandidacy {
        id
        insertedAt
        requestedTraining
        onboard {
          id
          extraFields {
            ... on Expert {
              seconderCandidacy(seconderId: $roleId) {
                id
              }
            }
          }
        }
      }
    }
  }
`;

interface ApplyAsOnboardSeconderInput {
  readonly onboardId: string;
  readonly requestedTraining: boolean;
}

export interface Variables {
  readonly input: ApplyAsOnboardSeconderInput;
  readonly roleId: string;
}

export interface Result {
  readonly seconderCandidacy: SeconderCandidacy;
}
