import React from "react";
import { Popup } from "semantic-ui-react";
import { isDefined } from "../../../../types";
import { TickettingBidEvaluation } from "../../../../types/bid";
import dateFormat from "dateformat";

interface Props {
  readonly evaluation?: TickettingBidEvaluation;
}

export const Evaluation = ({ evaluation }: Props) => {
  if (!isDefined(evaluation) || !isDefined(evaluation.form)) {
    return null;
  }
  const { nonFunctionalRequirements, interactionWithBidder } = evaluation.form.data;
  const { pricingProposal, responseStructure, preparation } = evaluation.form.data;
  const { functionalRequirements, bidderCommunication } = evaluation.form.data;

  return (
    <div className="Evaluation">
      <div className="header">
        <div className="name">{evaluation.owner.fullName || "Unknown"}</div>
        <div className="date">{dateFormat(evaluation.insertedAt, "HH:MM | dd/mm/yy")}</div>
      </div>
      <div className="row">
        <div className="label">Overall, the bid was well prepared and of high quality.</div>
        <Popup
          content={popupContent}
          trigger={<div className="score">{preparation + 1}</div>}
          position="top center"
        />
      </div>
      <div className="row">
        <div className="label">
          The bid proposed a solution and approach that addressed all functional requirements.
        </div>
        <Popup
          content={popupContent}
          trigger={<div className="score">{functionalRequirements + 1}</div>}
          position="top center"
        />
      </div>
      <div className="row">
        <div className="label">
          The bid proposed a solution and approach that addressed all non-functional requirements.
        </div>
        <Popup
          content={popupContent}
          trigger={<div className="score">{nonFunctionalRequirements + 1}</div>}
          position="top center"
        />
      </div>
      <div className="row">
        <div className="label">This bid proposes a competitive pricing proposition. </div>
        <Popup
          content={popupContent}
          trigger={<div className="score">{pricingProposal + 1}</div>}
          position="top center"
        />
      </div>
      <div className="row">
        <div className="label">The experience of interacting with this bidder was positive.</div>
        <Popup
          content={popupContent}
          trigger={<div className="score">{interactionWithBidder + 1}</div>}
          position="top center"
        />
      </div>
      <div className="row">
        <div className="label">The bid response was well structured and comprehensive.</div>
        <Popup
          content={popupContent}
          trigger={<div className="score">{responseStructure + 1}</div>}
          position="top center"
        />
      </div>
      <div className="row">
        <div className="label">The bid/bidder's communication was incisive and informed.</div>
        <Popup
          content={popupContent}
          trigger={<div className="score">{bidderCommunication + 1}</div>}
          position="top center"
        />
      </div>
    </div>
  );
};

const popupContent = (
  <div className="popup-content">
    <div className="wrapper">
      <div className="score">1</div>
      Not at all
    </div>
    <div className="wrapper">
      <div className="score">2</div>
      Slightly
    </div>
    <div className="wrapper">
      <div className="score">3</div>
      Reasonably
    </div>
    <div className="wrapper">
      <div className="score">4</div>
      Very
    </div>
    <div className="wrapper">
      <div className="score">5</div>
      Completely
    </div>
  </div>
);
