import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { DiscussionComment } from "./DiscussionThreadsOnIsDiscussable";

export const QUERY = gql`
  query CommentsOnThread(
    $threadId: ID!
    $excludeFirst: Boolean!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    node: node(id: $threadId) {
      id
      ... on DiscussionThread {
        id
        private
        isLight
        comments(
          excludeFirst: $excludeFirst
          after: $after
          before: $before
          first: $first
          last: $last
        ) {
          edges {
            node {
              ... on DiscussionComment {
                id
                title
                body
                insertedAt
                transmittedAt
                author {
                  ...Author
                }
                attachments(first: 2) {
                  ...AttachmentsConnection
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  }
  fragment Author on Role {
    id
    fullName
    avatarUrl
  }
  fragment AttachmentsConnection on AttachmentConnection {
    edges {
      node {
        id
        downloadUrl
        document {
          id
          uploadedFile {
            id
            filename
          }
        }
      }
    }
  }
`;

export interface Variables extends ConnectionVariables {
  readonly threadId: string;
  readonly excludeFirst: boolean;
}

export interface Result {
  readonly node?: { readonly comments?: Connection<DiscussionComment> };
}
