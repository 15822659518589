import React, { useCallback } from "react";
import { bridge } from "./EvaluationSchema";
import { RadioField } from "uniforms-semantic";
import { Button, Container, FormField } from "semantic-ui-react";
import { EvaluationSchema, readableEvaluation } from "./_types";
import { Maybe } from "../../../types";
import { AjvError } from "../../../utils/Ajv";
import { SignatureButton } from "../../../components/elements/SignatureButton";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";

interface Props {
  readonly changeModal: (isBack: boolean) => void;
  readonly setForm: (value: React.SetStateAction<EvaluationSchema | undefined>) => void;
  readonly sign: (model: EvaluationSchema) => void;
  readonly form: EvaluationSchema | undefined;
}

export const EvaluationForm = ({ changeModal, setForm, sign, form }: Props) => {
  const onValidate = useCallback((_: EvaluationSchema, e: Maybe<AjvError>) => e, []);

  const onSubmit = useCallback(
    (model: EvaluationSchema) => {
      setForm(model);
      sign(model);
    },
    [setForm, sign]
  );

  const handleClick = useCallback(() => {
    changeModal(true);
  }, [changeModal]);

  return (
    <AutoForm
      schema={bridge}
      showInlineError
      onValidate={onValidate}
      onSubmit={onSubmit}
      model={form}
    >
      <FormField>Please assign a score to each question</FormField>
      <div className="EvaluationForm">
        <RadioField
          name="preparation"
          label="Overall, the bid was well prepared and of high quality."
          transform={readableEvaluation}
          errorMessage="This field is mandatory."
        />
        <RadioField
          name="functionalRequirements"
          label="The bid proposed a solution and approach that addressed all functional requirements."
          transform={readableEvaluation}
          errorMessage="This field is mandatory."
        />
        <RadioField
          name="nonFunctionalRequirements"
          label="The bid proposed a solution and approach that addressed all non-functional requirements."
          transform={readableEvaluation}
          errorMessage="This field is mandatory."
        />
        <RadioField
          name="pricingProposal"
          label="This bid proposes a competitive pricing proposition."
          transform={readableEvaluation}
          errorMessage="This field is mandatory."
        />
        <RadioField
          name="interactionWithBidder"
          label="The experience of interacting with this bidder was positive."
          transform={readableEvaluation}
          errorMessage="This field is mandatory."
        />
        <RadioField
          name="responseStructure"
          label="The bid response was well structured and comprehensive."
          transform={readableEvaluation}
          errorMessage="This field is mandatory."
        />
        <RadioField
          name="bidderCommunication"
          label="The bid/bidder's communication was incisive and informed."
          transform={readableEvaluation}
          errorMessage="This field is mandatory."
        />
        <Container>
          <Button type="button" onClick={handleClick}>
            Back
          </Button>
          <SignatureButton primary type="submit" floated="right" content="Next" />
        </Container>
      </div>
    </AutoForm>
  );
};
