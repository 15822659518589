import { useState, useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Maybe, Queryable, noResultErrorFor } from "../types";
import { extractErrorMessages, setQueryResult, setBusy } from "../types";
import { perform, RolesFilter } from "../api/Roles";
import { nodesFromEdges } from "../types/relay";
import { Role } from "../types/role";

interface State extends Queryable {
  readonly roles: Maybe<ReadonlyArray<Role>>;
}
const initialState: State = {
  roles: null,
};

const TypeaheadDelay = 420;
const MinQueryLength = 3;

export const useAddressBook = (search: string) => {
  const client = useApolloClient();
  const [state, setState] = useState(initialState);
  const { roles, busy, errors } = state;

  const debouncedPerform = useMemo(() => AwesomeDebouncePromise(perform, TypeaheadDelay), []);

  useEffect(() => {
    if (!search) {
      setState(s => ({ ...s, roles: null }));
    } else if (search.length >= MinQueryLength) {
      setState(setBusy());

      let filters: RolesFilter | undefined;
      if (search) {
        if (search.length === 42 && search.substr(0, 2) === "0x") {
          filters = { ethAddress: search };
        } else {
          filters = { fullName: search };
        }
      }

      debouncedPerform(client, { first: 20, ...filters })
        .then(res => {
          if (!res.data || !res.data.roles) {
            return Promise.reject(noResultErrorFor("Filtered Roles"));
          }
          const filteredRoles = nodesFromEdges(res.data.roles.edges);
          setState(s => ({ ...s, roles: filteredRoles, busy: false }));
          return Promise.resolve();
        })
        .catch(e => setState(setQueryResult("errors", extractErrorMessages(e))));
    }
  }, [search, client, debouncedPerform]);

  return { roles, errors, busy };
};
