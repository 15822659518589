import React, { useEffect, useState, useMemo } from "react";
import { useMutation } from "@apollo/client";
import dateFormat from "dateformat";
import { useCallback } from "react";
import { Image, Popup } from "semantic-ui-react";
import { toast } from "react-toastify";
import { MUTATION as unfollowMutation } from "../../../api/followers/unfollow";
import { Result as unfollowRes } from "../../../api/followers/unfollow";
import { Variables as unfollowVars } from "../../../api/followers/unfollow";
import { MUTATION as followMutation } from "../../../api/followers/follow";
import { Result as followRes } from "../../../api/followers/follow";
import { Variables as followVars } from "../../../api/followers/follow";
import followStar from "../../../assets/follow-star.svg";
import unfollowStar from "../../../assets/unfollow-star.svg";
import { CardLinks } from ".";
import { StartupModal } from "./StartupModal";
import { OnboardWithFormOnly, Startup } from "../../../api/network/startups";
import { Migrated } from "../../../migrations/_helpers";

interface StartupCardProps {
  readonly startup: Startup & { readonly onboard: Migrated<OnboardWithFormOnly> };
}

export const StartupCard = ({ startup }: StartupCardProps) => {
  const { id, avatarUrl, canInvest, fullName, insertedAt, profile, followedBy, onboard } = startup;
  const [showModal, setShowModal] = useState(false);
  const [follow] = useMutation<followRes, followVars>(followMutation);
  const [unfollow] = useMutation<unfollowRes, unfollowVars>(unfollowMutation);
  const [followCD, setFollowCD] = useState<boolean>(false);

  useEffect(() => {
    if (followCD) {
      setTimeout(() => {
        setFollowCD(false);
      }, 600);
    }
  }, [followCD]);

  const displayModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const onLinkClick = useCallback((ev) => {
    ev.stopPropagation();
  }, []);

  const handleFollow = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (followCD) {
        return;
      }

      setFollowCD(true);
      const mutation = followedBy ? unfollow : follow;
      const toastMessage = `You have ${followedBy ? "unfollowed" : "followed"} ${fullName}.`;

      mutation({ variables: { input: { followableId: id } } })
        .then(() => {
          toast.dismiss();
          toast.success(toastMessage);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Something went wrong.");
          console.warn(err);
        });
    },
    [follow, unfollow, followedBy, followCD, fullName, id]
  );

  const links = useMemo(() => {
    const tmpLinks: CardLinks[] = [];
    if (profile.linkedinProfileUrl) {
      tmpLinks.push({ tag: "Linkedin", url: profile.linkedinProfileUrl });
    }
    if (onboard?.form?.data.links?.website) {
      tmpLinks.push({ tag: "Website", url: onboard.form.data.links?.website });
    }
    if (onboard?.form?.data.startupData?.companiesHouse) {
      tmpLinks.push({
        tag: "Companies House",
        url: onboard?.form?.data.startupData?.companiesHouse,
      });
    }
    return tmpLinks;
  }, [profile, onboard]);

  return (
    <>
      <div className="NetworkCard" onClick={displayModal}>
        <div className="NetworkCard-header">
          <Image className="AvatarPicture55" floated="left" src={avatarUrl} circular />
          <div className="NetworkCard-header-labels">
            {canInvest && (
              <div className="NetworkCard-header-labels-investlabel">Ready to invest</div>
            )}
            <div className="NetworkCard-header-labels-star">
              <Popup
                content={"Follow"}
                trigger={
                  <Image
                    className="top-section-follow"
                    src={followedBy ? unfollowStar : followStar}
                    alt="star"
                    verticalAlign="middle"
                    onClick={handleFollow}
                    disabled={followCD}
                  />
                }
                position="top center"
              />
            </div>
          </div>
        </div>
        <div className="NetworkCard-name">{fullName}</div>
        <div className="NetworkCard-description">
          Joined on {dateFormat(insertedAt, "HH:MM | dd/mm/yy")}
        </div>
        {profile.headline && <div className="NetworkCard-description">{profile.headline}</div>}
        {links.length > 0 && (
          <div className="NetworkCard-links">
            {links.map((l, i) => (
              <React.Fragment key={`Link-${i}`}>
                {i !== 0 && <div className="NetworkCard-links-dot" />}
                <a
                  key={`${id}-${i}`}
                  className="NetworkCard-links-link"
                  href={l.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={onLinkClick}
                >
                  {l.tag}
                </a>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <StartupModal startup={startup} links={links} showModal={showModal} closeModal={closeModal} />
    </>
  );
};
