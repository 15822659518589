import React, { CSSProperties, useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Image, Popup } from "semantic-ui-react";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import publicIcon from "../../../../assets/public-icon.svg";
import privateIcon from "../../../../assets/private-icon.svg";
import { TextAreaWithUploads, Fields as TextAreaFields } from "../../TextAreaWithUploads";
import { CommentsCollapsible } from "./CommentsCollapsible";
import { DiscussionComment } from "../../../../api/tickets/DiscussionThreadsOnIsDiscussable";
import { DiscussionThread } from "../../../../api/tickets/DiscussionThreadsOnIsDiscussable";
import { MUTATION, Variables, Result } from "../../../../api/tickets/PostDiscussionComment";
import { nodesFromEdges } from "../../../../types/relay";
import { selectedRole } from "../../../../contexts/Session/helpers";
import { Enrolled } from "../../../../contexts/Session/state";
import { useChainApi, useChainState } from "../../../../contexts/Chain";
import { documentsToPlainText } from "../../../../types/OnboardForm";
import { Document } from "../../../../types/document";

const popupStyle: CSSProperties = { left: "-7px" };
type Errors = Partial<Record<keyof TextAreaFields, string | undefined>> | undefined;

interface Props {
  readonly question: DiscussionThread;
  readonly showPrivacyIcon: boolean;
  readonly sessionState: Enrolled;
  readonly discussableMessage?: string;
}

export const Question = ({
  question,
  showPrivacyIcon,
  sessionState,
  discussableMessage,
}: Props) => {
  const chainApi = useChainApi();
  const chainState = useChainState();
  const [postDiscussionComment] = useMutation<Result, Variables>(MUTATION);
  const [reply, setReply] = useState<TextAreaFields>({});
  const [newCommentId, setNewCommentId] = useState<string | undefined>();
  const [replyErrors, setReplyErrors] = useState<Errors>();
  const { id, author, insertedAt, firstComment } = question;
  const { avatarUrl } = author;
  const attachments = nodesFromEdges(firstComment.attachments.edges);
  // We need to discount the initial comment on the thread from the comment count.
  const commentCount = question.commentCount - 1;
  const date = dateFormat(insertedAt, "HH:MM:ss | dd/mm/yy");
  const isPublic = !question.private;
  const privacyTooltip = isPublic
    ? "This question is visible to all members who have permission to view this ticket."
    : "Only the ticket owner and original poster can view this question.";

  const onInputChange = useCallback((arg: React.SetStateAction<TextAreaFields>) => {
    setReply(arg);
    setReplyErrors(undefined);
  }, []);

  const onSubmit = useCallback(() => {
    if (reply?.text === undefined || reply.text.length < 5) {
      setReplyErrors((s) => ({ ...s, text: "Submit an answer with atleast 5 chars." }));
      return;
    }

    const now = new Date();
    const transmittedAt = now.toISOString();
    const owner = selectedRole(sessionState.user.roles, sessionState.roleId).fullName || "Unknown";
    const { chainId } = sessionState;
    const title = `Question from ${owner}`;
    const body = reply.text;
    const digest = commentPlainText(
      chainId,
      now,
      owner,
      title,
      body,
      discussableMessage || "",
      reply.documents || [],
      firstComment
    );

    chainApi
      .sign(chainState, digest)
      .then((ethSignature) => {
        const variables: Variables = {
          input: {
            threadId: id,
            commentInput: { body, ethSignature, title, transmittedAt },
            documents: reply.documents?.map((d) => d.id),
          },
        };
        return postDiscussionComment({ variables });
      })
      .then((r) => {
        setReply({});
        setNewCommentId(r.data?.payload?.comment.id);
        toast.success("Comment submitted.");
      })
      .catch((err) => {
        // Ignore the error thrown when the user rejects the transaction.
        if (err.message.includes("User denied message signature")) {
          return;
        }
        toast.error("Something went wrong.");
        console.warn(err);
      });
  }, [
    chainApi,
    chainState,
    id,
    postDiscussionComment,
    sessionState,
    reply,
    firstComment,
    discussableMessage,
  ]);

  return (
    <div className="Question">
      <div className="Question-header">
        <Image className="AvatarPicture" size="mini" src={avatarUrl} circular />
        <span className="header-name">{author.fullName}</span>&nbsp;
        <span className="header-date">{date}</span>&nbsp;&nbsp;
        {showPrivacyIcon && (
          <div className="header-visibility">
            <Popup
              style={popupStyle}
              content={privacyTooltip}
              trigger={<Image src={isPublic ? publicIcon : privateIcon} />}
              position="left center"
            />
          </div>
        )}
      </div>
      <div className="Question-body">{firstComment.body}</div>
      <CommentsCollapsible
        threadId={id}
        totalReplies={commentCount}
        attachments={attachments}
        newCommentId={newCommentId}
      />
      <div className="Question-divider" />
      <div className="Question-input-reply">
        <TextAreaWithUploads
          fields={reply}
          onChange={onInputChange}
          error={replyErrors?.text}
          maxChars={1000}
        />
        <Button className="Question-submit-bttn" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export const commentPlainText = (
  chainId: number,
  signedAt: Date,
  authorFullname: string,
  title: string,
  body: string,
  discussableMessage: string,
  docs: ReadonlyArray<Document>,
  prevComment?: DiscussionComment
): string =>
  `-- Discussion Comment Digest on ${chainId}\n` +
  `  Signed At: ${signedAt.toISOString().split(".")[0] + "Z"}\n` +
  `  Author: ${authorFullname}\n` +
  `  Title: ${title}\n` +
  `  Body: ${body}\n` +
  `  Documents: ${documentsToPlainText(docs)}\n` +
  `\n` +
  (prevComment
    ? `  Original Comment:\n` +
      `    Signed At: ${
        new Date(prevComment.transmittedAt || "").toISOString()?.split(".")[0] + "Z"
      }\n` +
      `    Author: ${prevComment.author.fullName}\n` +
      `    Title: ${prevComment.title}\n` +
      `    Body: ${prevComment.body}\n` +
      `\n`
    : "") +
  `${discussableMessage}`;
