import { useCallback, useMemo, CSSProperties } from "react";
import { useHistory } from "react-router-dom";
import { Image, Grid, Popup } from "semantic-ui-react";
import dateFormat from "dateformat";
import moment, { now } from "moment";
import blueChevron from "../../../../../assets/blue-chevron.svg";
import informationIcon from "../../../../../assets/icon-information.svg";
import { isOrganisationOnboard } from "../../../../../types/onboard";
import { OnboardState, readableTypeAndKind } from "../../../../../types/onboard";
import { OnboardStatus, ReceivedOnboard, isStartupOnboard } from "../../../../../types/onboard";
import { TextClamp } from "../../../../elements/TextClamp";
import { Shortcuts as S } from "../../../../../routing";
import { Tabs as MyAreaTabs } from "../../../MyArea";
import { useSettingsState } from "../../../../../contexts/Settings";
import { isSuccessState } from "../../../../../contexts/Generic";
import { getPreselectionClock } from "../../../StartupDealflow/StartupOnboardCard";
import { RoleIcon } from "../../../../../types/badges";

const flexStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
};
const alignSelfEndStyle: CSSProperties = { alignSelf: "end" };
const statusCircleStyle: CSSProperties = { marginLeft: "8px" };
const breakWordStyle: CSSProperties = { wordBreak: "break-word" };

interface Props {
  readonly onboard: ReceivedOnboard;
}
const tabVarNameMyArea = S.myArea.queryVarNames.tab;

export const OnboardHeader = ({ onboard }: Props) => {
  const history = useHistory();
  const settings = useSettingsState();
  const { sender, insertedAt, preselectionEndsAt, fullName } = onboard;
  const expiresAt = useMemo(
    () =>
      isSuccessState(settings)
        ? new Date(new Date(insertedAt).valueOf() + settings.result.core.invitation_ttl * 1000)
        : "",
    [insertedAt, settings]
  );

  const OnReceiveInvClick = useCallback(() => {
    history.push(`${S.myArea.path}?${tabVarNameMyArea}=${MyAreaTabs.Applications}`);
  }, [history]);

  const title = `Application for ${fullName || "Unknown"}`;

  const onboardImage = useMemo(() => {
    const { type, kind } = onboard;
    const src = onboard.form?.data.startupData?.logo || onboard.recipient?.avatarUrl;
    if (!!src) {
      return <Image src={src} className="OnboardHeader-image" />;
    }

    return <RoleIcon className="OnboardHeader-image" type={type} kind={kind} />;
  }, [onboard]);

  const dynamicColumn = useMemo(() => {
    const { state, ethAddress } = onboard;
    if (state === OnboardState.Pending || state === OnboardState.InCompletion) {
      return (
        <Grid.Column computer="2" tablet="3" only="computer tablet">
          <Grid.Row>
            <div className="OnboardHeader-description-small-label">Expires on</div>
          </Grid.Row>
          <Grid.Row>{dateFormat(expiresAt, "dd/mm/yy")}</Grid.Row>
        </Grid.Column>
      );
    } else if (isStartupOnboard(onboard) && state === OnboardState.InPreselection) {
      const daysLeft = moment(preselectionEndsAt).diff(moment(now()), "days");
      return (
        <Grid.Column computer="3" tablet="4" only="computer tablet">
          <Grid.Row>
            <div className="OnboardHeader-description-small-label">Pre-selection voting</div>
          </Grid.Row>
          <Grid.Row style={flexStyle}>
            {getPreselectionClock(daysLeft)}&nbsp;&nbsp;
            {daysLeft} {daysLeft === 1 ? "day" : "days"} left
          </Grid.Row>
        </Grid.Column>
      );
    } else if (ethAddress) {
      return (
        <Grid.Column computer="8" tablet="6" only="computer tablet">
          <Grid.Row>
            <div className="OnboardHeader-description-small-label">
              Signed wallet address{" "}
              <Popup
                position="top center"
                trigger={<Image style={statusCircleStyle} src={informationIcon} />}
                content="The public address for the digital wallet used to sign this application."
              />
            </div>
          </Grid.Row>
          <Grid.Row>{ethAddress}</Grid.Row>
        </Grid.Column>
      );
    }
    return null;
  }, [expiresAt, preselectionEndsAt, onboard]);

  return (
    <div className="OnboardHeader">
      <div className="OnboardHeader-breadcrumb" onClick={OnReceiveInvClick}>
        <Image src={blueChevron} />
        Applications
      </div>
      <Grid columns={16}>
        <Grid.Row>
          <Grid.Column only="computer tablet" width="2">
            {onboardImage}
          </Grid.Column>
          <Grid.Column computer="12" tablet="12" mobile="11">
            <Grid.Row>
              <div className="OnboardHeader-description-title">
                <TextClamp text={title} />
              </div>
            </Grid.Row>
            <Grid.Row className="OnboardHeader-description">
              <Grid>
                <Grid.Column computer="3" tablet="4" mobile="8">
                  <Grid.Row>
                    <div className="OnboardHeader-description-small-label">Referred by</div>
                  </Grid.Row>
                  <Grid.Row className="OnboardHeader-referrer-row">
                    <Image src={sender.avatarUrl} circular />
                    {onboard.sender.fullName}
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column
                  computer={isOrganisationOnboard(onboard) ? "3" : "2"}
                  tablet="3"
                  mobile="6"
                >
                  <Grid.Row>
                    <div className="OnboardHeader-description-small-label">Your Role</div>
                  </Grid.Row>
                  <Grid.Row style={breakWordStyle}>
                    {readableTypeAndKind(onboard.type, onboard.kind)}
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column computer="2" tablet="3" only="computer tablet">
                  <Grid.Row>
                    <div className="OnboardHeader-description-small-label">Received on</div>
                  </Grid.Row>
                  <Grid.Row>{dateFormat(insertedAt, "dd/mm/yy")}</Grid.Row>
                </Grid.Column>
                {dynamicColumn}
              </Grid>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column style={alignSelfEndStyle} computer="2" tablet="2" mobile="5">
            <Grid.Row className="OnboardHeader-description">
              <Grid>
                <Grid.Column width="16">
                  <Grid.Row>
                    <div className="OnboardHeader-description-small-label">Status</div>
                  </Grid.Row>
                  <Grid.Row>{generateStatusLabels(onboard)}</Grid.Row>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const generateStatusLabels = (onboard: ReceivedOnboard) => {
  const { status, state } = onboard;
  let label;
  let circleLabelJSX;
  if (state === OnboardState.Pending) {
    label = "Pending";
    circleLabelJSX = <div className="StatusCircle yellow" style={statusCircleStyle} />;
  } else if (state === OnboardState.InCompletion) {
    label = "Draft";
    circleLabelJSX = <div className="StatusCircle yellow" style={statusCircleStyle} />;
  } else if (state === OnboardState.AwaitingEnableVoting) {
    label = "Being reviewed";
    circleLabelJSX = <div className="StatusCircle grey" style={statusCircleStyle} />;
  } else if (
    state === OnboardState.AwaitingSeconders ||
    state === OnboardState.InPreselection ||
    state === OnboardState.TimedOut ||
    state === OnboardState.UnderReview ||
    state === OnboardState.InMutualAssessment ||
    state === OnboardState.InCommitteeReview
  ) {
    label = "In progress";
    circleLabelJSX = <div className="StatusCircle yellow" style={statusCircleStyle} />;
  } else if (status === OnboardStatus.Rejected) {
    label = "Rejected";
    circleLabelJSX = <div className="StatusCircle dark-red" style={statusCircleStyle} />;
  } else if (status === OnboardStatus.Declined) {
    label = "Declined";
    circleLabelJSX = <div className="StatusCircle dark-red" style={statusCircleStyle} />;
  } else if (status === OnboardStatus.Expired) {
    label = "Expired";
    circleLabelJSX = <div className="StatusCircle black" style={statusCircleStyle} />;
  } else if (status === OnboardStatus.Canceled) {
    label = "Canceled";
    circleLabelJSX = <div className="StatusCircle black" style={statusCircleStyle} />;
  } else {
    label = "Accepted";
    circleLabelJSX = <div className="StatusCircle green" style={statusCircleStyle} />;
  }

  return (
    <div style={flexStyle}>
      <TextClamp text={label} />
      {circleLabelJSX}
    </div>
  );
};
