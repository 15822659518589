import { useState, SyntheticEvent, useEffect, memo, useMemo } from "react";
import classnames from "classnames";
import { Form, FormDropdownProps, DropdownProps } from "semantic-ui-react";
import { DropdownOnSearchChangeData } from "semantic-ui-react";
import { utils } from "../../utils/utils";
import { useAddressBook } from "../../hooks/useAddressBook";
import { isEmpty } from "../../types";
import { RoleIcon } from "../../types/badges";
import { Role } from "../../types/role";
import { Enrolled } from "../../contexts/Session/state";
import { selectedRole as sessionSelectedRole } from "../../contexts/Session/helpers";

// Alias.
type DDChange = (ev: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
type DDSChange = (ev: SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => void;

interface State {
  readonly firstRun: boolean;
  readonly search: string;
  readonly selectedRole?: Role;
}

const buildInitialState = (r?: Role, search?: string): State => ({
  firstRun: true,
  search: search || "",
  selectedRole: r,
});

interface Props extends FormDropdownProps {
  readonly sessionState?: Enrolled;
  readonly onSelectedRoleChange: (selectedRole?: Role) => void;
  readonly defaultRole?: Role;
  readonly defaultSearch?: string;
  readonly viewOnly?: boolean;
}
export const AddressBookFormDropdown = memo((p: Props) => {
  const {
    onSelectedRoleChange,
    defaultRole,
    defaultSearch,
    viewOnly,
    sessionState,
    ...formDropdownProps
  } = p;
  const [{ firstRun, search, selectedRole }, setState] = useState(
    buildInitialState(defaultRole, defaultSearch)
  );
  const sessionRole: Role | undefined = useMemo(() => {
    if (sessionState) {
      return sessionSelectedRole(sessionState.user.roles, sessionState.roleId);
    }
  }, [sessionState]);
  const { roles: addressBookRoles, busy, errors } = useAddressBook(search);

  // This hook is needed to select a role on the first run when a defaultSearch is provided.
  useEffect(() => {
    if (!firstRun || selectedRole || !addressBookRoles) {
      return;
    }

    const formattedSearch = search.toLowerCase();
    const match = addressBookRoles?.find(
      (r) =>
        r.ethAddress.toLowerCase() === formattedSearch ||
        r.fullName?.toLowerCase() === formattedSearch
    );

    onSelectedRoleChange(match);
    setState((s) => ({ ...s, selectedRole: match, firstRun: false }));
  }, [firstRun, addressBookRoles, selectedRole, search, onSelectedRoleChange]);

  const handleChange: DDChange = (ev, { value }) => {
    if (typeof value === "string" && addressBookRoles) {
      if (isEmpty(value)) {
        onSelectedRoleChange(undefined);
        return setState((s) => ({ ...s, selectedRole: undefined, search: "" }));
      }
      const newRole = addressBookRoles.find((r) => r.id === value);
      onSelectedRoleChange(newRole);
      setState((s) => ({ ...s, selectedRole: newRole }));
    }
  };

  const handleSearchChange: DDSChange = (_, { searchQuery }) =>
    setState((s) => ({ ...s, search: searchQuery.trim() }));

  const options = useMemo(
    () =>
      addressBookRoles
        ?.filter((r) => r.ethAddress !== sessionRole?.ethAddress)
        .map((role) => {
          return {
            key: role.id,
            text: (
              <div className="AddressBookFormDropdown-option">
                <span className="icon-label">
                  {RoleIcon({ kind: role.kind, type: role.type })}
                  {role.fullName || "unknown"}
                </span>
                <span className="eth-address">{role.ethAddress}</span>
              </div>
            ),
            value: role.id,
          };
        }) || [],
    [addressBookRoles, sessionRole]
  );

  const inputText = selectedRole
    ? `${selectedRole?.fullName} (${selectedRole?.ethAddress.slice(0, 10)}...)`
    : "";

  return (
    <Form.Dropdown
      {...formDropdownProps}
      className={classnames(
        formDropdownProps.className,
        `AddressBookFormDropdown${viewOnly ? " opaque-disabled-field" : ""}`
      )}
      clearable
      search={utils.identity}
      placeholder={defaultSearch ? defaultSearch.slice(0, 16) + "..." : ""}
      options={options}
      error={errors ? errors.join(", ") : undefined}
      value={selectedRole && !errors ? selectedRole.id : ""}
      onChange={handleChange}
      floating
      text={inputText}
      onSearchChange={handleSearchChange}
      loading={busy}
      icon={viewOnly ? null : undefined}
      disabled={viewOnly}
    />
  );
});
