import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { BidSchema } from "./_types";
import { utils } from "../../utils/utils";
import { valuesOfNumericEnum } from "../../types";
import { YesNoAnswer } from "../_types";
import { TicketPaymentMethods } from "../tickets/_enums";
import { globalDefinitions } from "../_definitions";
import { schema as PreferencesSchema } from "../preferences/TicketPreferencesSchema";

export const schema: JSONSchemaType<BidSchema> = {
  title: "Submit a bid",
  type: "object",
  properties: {
    understandsRequirements: {
      type: "integer",
      enum: valuesOfNumericEnum(YesNoAnswer),
    },
    agreesToFramework: {
      type: "integer",
      enum: valuesOfNumericEnum(YesNoAnswer),
    },
    overview: {
      type: "string",
      maxLength: 300,
      pattern: "(.|s)*\\S(.|s)*",
    },
    interestReason: {
      type: "string",
      maxLength: 300,
      pattern: "(.|s)*\\S(.|s)*",
    },
    competencies: {
      type: "string",
      maxLength: 500,
      pattern: "(.|s)*\\S(.|s)*",
    },
    experiences: {
      type: "string",
      maxLength: 1000,
      pattern: "(.|s)*\\S(.|s)*",
    },
    keepUpStrategy: {
      type: "string",
      maxLength: 300,
    },
    relevantTools: {
      type: "string",
      maxLength: 300,
    },
    deliverableChanges: {
      type: "string",
      maxLength: 500,
    },
    KPIChanges: {
      type: "string",
      maxLength: 500,
    },
    engagementStrategy: {
      type: "string",
      maxLength: 500,
      pattern: "(.|s)*\\S(.|s)*",
    },
    performanceMonitoring: {
      type: "string",
      maxLength: 500,
      pattern: "(.|s)*\\S(.|s)*",
    },
    ticketSuccess: {
      type: "string",
      maxLength: 1000,
      pattern: "(.|s)*\\S(.|s)*",
    },
    ticketRisks: {
      type: "string",
      maxLength: 500,
    },
    backgroundInformation: {
      type: "string",
      maxLength: 300,
    },
    organisationSetup: {
      type: "string",
      maxLength: 500,
    },
    teamCVs: {
      type: "array",
      items: globalDefinitions.document,
      maxItems: 10,
    },
    teamManagementStrategy: {
      type: "string",
      maxLength: 500,
    },
    previousLessons: {
      type: "string",
      maxLength: 500,
    },
    additionalInfo: {
      type: "string",
      maxLength: 500,
    },
    additionalDocuments: {
      type: "array",
      items: globalDefinitions.document,
      maxItems: 3,
    },
    daysOfWork: {
      type: "number",
      minimum: 1,
      maximum: 360,
    },
    estimatedCost: {
      type: "number",
      minimum: 0,
    },
    paymentMethod: {
      type: "integer",
      enum: valuesOfNumericEnum(TicketPaymentMethods),
    },
    fiatPercentage: {
      type: "number",
      minimum: 1,
      maximum: 99,
    },
    cvdsPercentage: {
      type: "number",
      minimum: 1,
      maximum: 99,
    },
    computedFields: {
      type: "object",
      properties: {
        estimatedCostAsBigInt: {
          type: "string",
        },
      },
      required: ["estimatedCostAsBigInt"],
    },
    termsAgreement: {
      type: "boolean",
      enum: [true],
    },
    preferences: PreferencesSchema,
    consentToMattermostAlerts: { type: "boolean", enum: [true] },
    consentToShareEmail: { type: "boolean", enum: [true] },
  },
  required: [
    "understandsRequirements",
    "agreesToFramework",
    "overview",
    "competencies",
    "ticketSuccess",
    "daysOfWork",
    "estimatedCost",
    "paymentMethod",
    "computedFields",
    "termsAgreement",
    "preferences",
    "consentToMattermostAlerts",
    "consentToShareEmail",
  ],
  if: {
    properties: {
      paymentMethod: {
        type: "integer",
        const: TicketPaymentMethods.FiatAndCVDS,
      },
    },
  },
  then: {
    required: ["fiatPercentage", "cvdsPercentage"],
  },
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
