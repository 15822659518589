import { isBoolean, isNumber, isString } from "lodash";
import React, { CSSProperties, useCallback, useMemo } from "react";
import classNames from "classnames";
import { DropdownItemProps, DropdownProps, Form } from "semantic-ui-react";
import { connectField, HTMLFieldProps } from "uniforms";
import { isStringArray } from "../types";

const wrapperStyle: CSSProperties = { display: "flex", flexDirection: "column", width: "100%" };
const inputsStyle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const errorsStyle: CSSProperties = { textAlign: "center" };

interface Props extends HTMLFieldProps<string | string[] | number | boolean, HTMLElement> {
  readonly [k: string]: unknown;
  readonly dropdownOptions: DropdownItemProps[];
  readonly multiple?: boolean;
  readonly search?: boolean;
  readonly compact?: boolean;
  readonly noResultsMessage?: string;
  readonly labelOnLeft?: boolean;
  readonly dropdownStyle?: CSSProperties;
}
export const CustomDropdownSelectionField = connectField((props: Props) => {
  const { placeholder, onChange, required, dropdownOptions, error, className } = props;
  const { disabled, errorMessage, label, multiple = false, search = false, compact } = props;
  const { labelOnLeft = false, dropdownStyle, noResultsMessage } = props;

  // For multiple dropdowns we can't pass undefined, so we add a empty array as default.
  const value = useMemo(
    () => props.value || (multiple ? [] : props.value),
    [props.value, multiple]
  );

  const customOnChange = useCallback(
    (_: React.SyntheticEvent<HTMLElement, Event>, { value: v }: DropdownProps) => {
      if (multiple && isStringArray(v)) {
        onChange(v);
      }
      if (isString(v)) {
        return onChange(v);
      }
      if (isBoolean(v)) {
        return onChange(v);
      }
      if (isNumber(v)) {
        return onChange(v);
      }
    },
    [onChange, multiple]
  );

  const content = (
    <>
      {label && <label>{label}</label>}
      <Form.Dropdown
        style={dropdownStyle}
        required={required}
        selection
        search={search}
        multiple={multiple}
        value={value}
        placeholder={placeholder}
        options={dropdownOptions}
        onChange={customOnChange}
        compact={compact}
        noResultsMessage={noResultsMessage}
      />
    </>
  );

  return (
    <>
      {!labelOnLeft && (
        <div className={classNames(className, { disabled, error, required }, "field")}>
          {content}
          <div>{!!error && <div className="ui red basic pointing label">{errorMessage}</div>}</div>
        </div>
      )}
      {labelOnLeft && (
        <div style={wrapperStyle}>
          <div
            className={classNames(className, { disabled, error, required }, "field")}
            style={inputsStyle}
          >
            {content}
          </div>
          <div style={errorsStyle}>
            {!!error && <div className="ui red basic pointing label">{errorMessage}</div>}
          </div>
        </div>
      )}
    </>
  );
});
