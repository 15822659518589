import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation CreateMattermostAccount($input: CreateMattermostAccountInput!) {
    payload: createMattermostAccount(input: $input) {
      user {
        id
        mmUserId
      }
    }
  }
`;

interface CreateMattermostAccountInput {
  readonly invitationToken: string;
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly username: string;
}

export interface Variables {
  readonly input: CreateMattermostAccountInput;
}

export interface Result {
  readonly payload?: { readonly user: { readonly mmUserId?: string } & Identifiable };
}
