import React from "react";
import classnames from "classnames";
import { connectField, useForm, HTMLFieldProps } from "uniforms";

interface SimpleFormError {
  readonly dataPath?: string;
  readonly params?: { readonly missingProperty?: string };
}

interface Props extends HTMLFieldProps<string, HTMLDivElement> {
  readonly [k: string]: unknown;
  readonly hideIfFormHasOtherErrors?: boolean;
}

export const CustomErrorAggregator = connectField((props: Props) => {
  const { name, errorMessage, className, hideIfFormHasOtherErrors } = props;
  const errors = useForm()?.error?.details as ReadonlyArray<SimpleFormError> | undefined;
  const relatedErrors =
    errors?.filter(
      e => !!e.dataPath?.includes(name) || !!e.params?.missingProperty?.includes(name)
    ) || [];

  let displayError;
  if (hideIfFormHasOtherErrors) {
    displayError = relatedErrors.length > 0 && relatedErrors.length === errors?.length;
  } else {
    displayError = relatedErrors.length > 0;
  }

  return displayError ? (
    <div className={classnames("ui", className, "error message")}>
      <div className="header">{errorMessage}</div>
    </div>
  ) : null;
});
