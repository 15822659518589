import { TagTree, TagMapping } from "../../types/tags";
import { Action } from "./actions";
import { GenericState } from "../Generic/state";
import { genericReducer } from "../Generic/reducer";

// Actual state.
export interface Categories {
  readonly maturitiesTree: Readonly<TagTree>;
  readonly maturitiesMapping: Readonly<TagMapping>;
  readonly occupationsTree: Readonly<TagTree>;
  readonly occupationsMapping: Readonly<TagMapping>;
  readonly sectorsTree: Readonly<TagTree>;
  readonly sectorsMapping: Readonly<TagMapping>;
  readonly skillsTree: Readonly<TagTree>;
  readonly skillsMapping: Readonly<TagMapping>;
}

export type State = GenericState<Categories>;

export const reducer = (state: State, action: Action) => genericReducer(state, action);
