import { CSSProperties } from "react";
import { Icon } from "semantic-ui-react";
import { useField } from "uniforms";
import { PreselectionProposalChoice, readablePreselectionProposalChoice } from "./_types";

const rotatedStyle: CSSProperties = { transform: "rotate(90deg) scaleY(-1)" };

export const FormHeader = () => {
  const [{ value: voteChoice }] = useField<{}, PreselectionProposalChoice>("voteChoice", {});

  return (
    <>
      {voteChoice === PreselectionProposalChoice.Yes && (
        <div className="PreSelectionVoteForm-choice Yes">
          {readablePreselectionProposalChoice(PreselectionProposalChoice.Yes)}
          <Icon name="thumbs up" />
        </div>
      )}
      {voteChoice === PreselectionProposalChoice.No && (
        <div className="PreSelectionVoteForm-choice No">
          {readablePreselectionProposalChoice(PreselectionProposalChoice.No)}
          <Icon name="thumbs down" />
        </div>
      )}
      {voteChoice === PreselectionProposalChoice.Neutral && (
        <div className="PreSelectionVoteForm-choice Neutral">
          {readablePreselectionProposalChoice(PreselectionProposalChoice.Neutral)}
          <Icon name="thumbs down" style={rotatedStyle} />
        </div>
      )}
      <div className="PreSelectionVoteForm-title">Please complete the voting form</div>
    </>
  );
};
