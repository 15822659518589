import { gql } from "@apollo/client";
import { UPLOADED_FILE_FRAGMENT } from "./UploadedFile";
import { PAGEINFO_FRAGMENT } from "./PageInfo";

export const DOCUMENT_FRAGMENT = gql`
  fragment Document on Document {
    id
    hash
    uploadedFile {
      ...UploadedFile
    }
    user {
      id
    }
    downloadUrl
  }
  ${UPLOADED_FILE_FRAGMENT}
`;

export const DOCUMENT_CONNECTION_FRAGMENT = gql`
  fragment DocumentConnection on DocumentConnection {
    edges {
      cursor
      node {
        ...Document
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;
