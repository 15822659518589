import {
  useCallback,
  useState,
  CSSProperties,
  PropsWithChildren,
  MouseEvent,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { Button, Container, Image, Modal } from "semantic-ui-react";
import { isSuccessState } from "../../../contexts/Generic";
import { useSettingsState } from "../../../contexts/Settings";
import { Settings } from "../../../contexts/Settings/state";
import { EntityKind, EntityType, OnboardState } from "../../../types/onboard";
import { OnboardStatus, SentOnboard } from "../../../types/onboard";
import approvedIcon from "../../../assets/approved-icon.svg";
import { MUTATION as CancelMut } from "../../../api/onboards/CancelOnboard";
import { Variables as CancelVars } from "../../../api/onboards/CancelOnboard";
import { Result as CancelRes } from "../../../api/onboards/CancelOnboard";
import { MUTATION as ResendMut } from "../../../api/onboards/ResendOnboardIssuanceEmail";
import { Variables as ResendVars } from "../../../api/onboards/ResendOnboardIssuanceEmail";
import { Result as ResendRes } from "../../../api/onboards/ResendOnboardIssuanceEmail";
import { utils } from "../../../utils/utils";
import dateFormat from "dateformat";
import { ApiError, extractErrorMessages, noResultErrorFor } from "../../../types";
import { Shortcuts as S } from "../../../routing";
import { Link } from "react-router-dom";

const iconStyle: CSSProperties = {
  width: "16px",
  height: "16px",
  maxHeight: "16px",
  maxWidth: "16px",
};

const cardMarginStyle: CSSProperties = {
  marginTop: "8px",
};

interface Props {
  readonly onboard: SentOnboard;
}

export const SentInviteCard = ({ onboard }: Props) => {
  const settings = useSettingsState();
  const { fullName, recipientFullName, kind, type, state, id } = onboard;
  const [isOpen, setIsOpen] = useState(false);

  const [cancelInvite, { loading: cancelLoading }] = useMutation<CancelRes, CancelVars>(CancelMut);
  const [resendInvite, { loading: resendLoading }] = useMutation<ResendRes, ResendVars>(ResendMut);

  const loading = cancelLoading || resendLoading;
  const displayBttns = state === OnboardState.Pending;
  const recipientName = fullName || recipientFullName || "Unknown";

  const onGeneralClose = useCallback(() => setIsOpen(false), []);
  const onCancelClick = useCallback(() => setIsOpen(true), []);
  const handleCancelInvite = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      cancelInvite({ variables: { input: { onboardId: id } } })
        .then((res) => {
          if (!res.data || !res.data.payload) {
            return Promise.reject(noResultErrorFor("Cancel Invite"));
          }
          toast.success(`You canceled the invite to ${recipientName}.`);
          return Promise.resolve();
        })
        .catch((e: ApiError) => {
          toast.error(extractErrorMessages(e).join(", "));
        });
    },
    [cancelInvite, recipientName, id]
  );
  const handleResendInvite = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      resendInvite({ variables: { input: { onboardId: id } } })
        .then((res) => {
          if (!res.data || !res.data.payload || !res.data.payload.onboard) {
            return Promise.reject(noResultErrorFor("ResendInvite"));
          }
          toast.success("An email was sent to the recipient of this invite.");
          return Promise.resolve();
        })
        .catch((e: ApiError) => {
          toast.error(extractErrorMessages(e).join(", "));
        });
    },
    [resendInvite, id]
  );

  const CardWrapper = useCallback(
    ({ children }: PropsWithChildren<unknown>) => {
      const search = new URLSearchParams({ [S.invite.queryVarNames.id]: id }).toString();
      const linksTo = `${S.invite.path}?${search}`;
      return <Link to={linksTo}>{children}</Link>;
    },
    [id]
  );

  const roleTitle = useMemo(() => {
    if (kind === EntityKind.Startup) {
      return "a Startup";
    } else if (type === EntityType.Organisation) {
      return "an Expert Organisation";
    } else {
      return "an Expert";
    }
  }, [kind, type]);

  return (
    <div className="SentInviteCard">
      <CardWrapper>
        <div className="SentInviteCard-top-section">
          <div className="SentInviteCard-top-section-title" style={cardMarginStyle}>
            You invited {recipientName} to apply as {roleTitle}
          </div>
          {generateStatusLabels(onboard)}
        </div>
      </CardWrapper>
      <div className="ReceivedInviteCard-description">
        <CardWrapper>
          <div className="ReceivedInviteCard-description-info">
            <div className="ReceivedInviteCard-description-info-date">
              {isSuccessState(settings) && generateSentAndExpireDate(onboard, settings.result)}
            </div>
          </div>
        </CardWrapper>
        {displayBttns && (
          <div className="ReceivedInviteCard-description-bttn-wrapper">
            <Modal
              className="CustomClose"
              onClose={onGeneralClose}
              size="mini"
              open={isOpen}
              closeIcon
              closeOnDimmerClick={false}
              trigger={
                <Button basic color="blue" onClick={onCancelClick} loading={loading}>
                  Cancel
                </Button>
              }
            >
              <Modal.Header>You are about to cancel this invite</Modal.Header>
              <Modal.Content>
                Are you sure you'd like to do this?
                <br />
                <br />
                <Container>
                  <Button primary onClick={onGeneralClose} content="No" loading={loading} />
                  <Button
                    onClick={handleCancelInvite}
                    floated="right"
                    content="Yes"
                    loading={loading}
                  />
                </Container>
              </Modal.Content>
            </Modal>
            <Button color="blue" onClick={handleResendInvite} loading={loading}>
              Resend
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const generateStatusLabels = (onboard: SentOnboard) => {
  const { status, state, closedAt } = onboard;
  let dateLabel;
  let label;
  let circleLabelJSX;
  if (state === OnboardState.Pending) {
    label = "Pending";
    circleLabelJSX = <div className="StatusCircle yellow" />;
  } else if (status === OnboardStatus.Declined) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Declined";
    circleLabelJSX = <div className="StatusCircle dark-red" />;
  } else if (status === OnboardStatus.Expired) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Expired";
    circleLabelJSX = <div className="StatusCircle black" />;
  } else if (status === OnboardStatus.Canceled) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Canceled";
    circleLabelJSX = <div className="StatusCircle black" />;
  } else {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Accepted";
    circleLabelJSX = <Image src={approvedIcon} style={iconStyle} />;
  }
  const dateLabelJSX = (
    <div className="SentInviteCard-top-section-right-labels-date">{dateLabel}</div>
  );
  const labelJSX = <div className="SentInviteCard-top-section-right-labels-label">{label}</div>;

  return (
    <div className="SentInviteCard-top-section-right-labels">
      {dateLabelJSX}
      {labelJSX}
      {circleLabelJSX}
    </div>
  );
};

const generateSentAndExpireDate = ({ insertedAt, state }: SentOnboard, settings: Settings) => {
  const label = `Sent ${utils.around(insertedAt)}`;
  if (state !== OnboardState.Closed) {
    const s = settings.core;
    const expiresAt = new Date(new Date(insertedAt).valueOf() + s.invitation_ttl * 1000);
    return label + ` | Expires ${utils.around(expiresAt)}`;
  }
  return label;
};
