import { gql } from "@apollo/client";
import { MutualAssessment, PublicOnboardViewedByMember } from "../../types/onboard";
import { SeconderCandidacy } from "../../types/seconderCandidacy";
import { ADMIN_SECONDER_CANDIDACY_FRAGMENT } from "../_fragments/AdminSeconderCandidacy";
import { PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT } from "../_fragments/PublicOnboardViewedByMember";

export const QUERY = gql`
  query AdminExpertOnboard($onboardId: ID!, $roleId: ID!) {
    onboard: node(id: $onboardId) {
      ... on Onboard {
        extraFields {
          ... on Expert {
            volunteerSeconderCandidacies: seconderCandidacies(state: VOLUNTEERED) {
              ...AdminSeconderCandidacy
            }
            assignedSeconderCandidacies: seconderCandidacies(state: ASSIGNED) {
              ...AdminSeconderCandidacy
            }
            mutualAssessment(seconderId: $roleId) {
              id
              state
              transmittedAt
              form {
                id
                data
              }
              seconder {
                id
                fullName
                avatarUrl
              }
            }
            mutualAssessments {
              id
              state
              transmittedAt
              form {
                id
                data
              }
              seconder {
                id
                fullName
                avatarUrl
              }
            }
          }
        }
        ...PublicOnboardViewedByMember
      }
    }
  }
  ${ADMIN_SECONDER_CANDIDACY_FRAGMENT}
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT}
`;

export interface Variables {
  readonly roleId: string;
  readonly onboardId: string;
}

export interface AdminExpertOnboard extends Omit<PublicOnboardViewedByMember, "extraFields"> {
  readonly extraFields: {
    readonly volunteerSeconderCandidacies?: ReadonlyArray<SeconderCandidacy>;
    readonly assignedSeconderCandidacies?: ReadonlyArray<SeconderCandidacy>;
    readonly mutualAssessment?: MutualAssessment;
    readonly mutualAssessments?: ReadonlyArray<MutualAssessment>;
  };
}

export interface Result {
  readonly onboard?: AdminExpertOnboard;
}
