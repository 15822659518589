import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { Ballot } from "../../types/ballot";
import { BALLOT_CONNECTION_FRAGMENT } from "../_fragments/Ballot";
import { PROPOSAL_FRAGMENT } from "../_fragments/Proposal";
import { Proposal } from "../../types/proposal";
import { BallotSort } from "../../types/ballotsSort";
import { CBCExpertVoteChoice, CBCExpertVoteSchema } from "../../schemas/cbc/_types";

export const QUERY = gql`
  query CBCMemberBallots(
    $onboardId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $choice: String
    $sortBy: [BallotSort!]
  ) {
    node(id: $onboardId) {
      ... on Onboard {
        id
        committeeProposal: proposal(kind: "committee_review") {
          ...Proposal
          ballots(
            after: $after
            before: $before
            first: $first
            last: $last
            choice: $choice
            sortBy: $sortBy
          ) {
            ...BallotConnection
          }
        }
      }
    }
  }
  ${PROPOSAL_FRAGMENT}
  ${BALLOT_CONNECTION_FRAGMENT}
`;

export interface Variables extends ConnectionVariables {
  readonly onboardId: string;
  readonly choice?: CBCExpertVoteChoice;
  readonly sortBy?: BallotSort;
}

export interface ProposalWithBallots extends Proposal<CBCExpertVoteChoice> {
  readonly ballots?: Connection<Ballot<CBCExpertVoteChoice, CBCExpertVoteSchema>>;
}

export interface Result {
  readonly node?: { readonly committeeProposal?: ProposalWithBallots };
}
