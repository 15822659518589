import { useState, useCallback, useMemo } from "react";
import { Grid, Image } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import { readableAbstainReason, readableScore } from "../../../schemas/preselectionVote/_types";
import { isAbstainVoteSchema, isYesNoVoteSchema } from "../../../schemas/preselectionVote/_types";
import { PreselectionProposalChoice } from "../../../schemas/preselectionVote/_types";
import iconReply from "../../../assets/icon-reply-blue.svg";
import blueChevron from "../../../assets/blue-chevron.svg";
import { BallotWithAnonymizableProfile } from "../../../api/proposals/Ballots";
import { ScoreInfoPopup } from "./ScoreInfoPopup";
import { openModal } from "./PreselectionRepliesModal";
import { utils } from "../../../utils/utils";
import { Migrated } from "../../../migrations/_helpers";

interface Props {
  readonly startupName: string;
  readonly ballot: Migrated<BallotWithAnonymizableProfile<PreselectionProposalChoice>>;
  readonly isOwner: boolean;
}

export const VoteCollapsible = ({ startupName, ballot, isOwner }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { insertedAt, migratedForm, id: ballotId, anonymizableProfile } = ballot;
  const [isCollapsibleActive, setIsCollapsibleActive] = useState(false);
  const formData = migratedForm?.data;
  const { discussionThread } = ballot;
  const possessiveName = utils.withPossessiveApostrophe(startupName);

  const onCollapsibleClick = useCallback(() => setIsCollapsibleActive((s) => !s), []);

  const onOpenReplies = useCallback(
    (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      ev.stopPropagation();
      ev.preventDefault();
      openModal(ballotId, location.search, history);
    },
    [ballotId, history, location]
  );

  const collapsibleClassName = useMemo(() => {
    let className = "PreselectionVoteCollapsible";
    if (isCollapsibleActive) {
      className += " active";
    }
    if (isOwner) {
      className += " owner";
    }
    return className;
  }, [isCollapsibleActive, isOwner]);

  return (
    <>
      <Grid.Row className={collapsibleClassName} onClick={onCollapsibleClick}>
        <Grid.Column width="1" className="PreselectionVoteCollapsible-detailsCol">
          <Image src={blueChevron} />
        </Grid.Column>
        <Grid.Column width="4" className="PreselectionVoteCollapsible-memberCol">
          <div className="PreselectionVoteCollapsible-memberCol-name noWrap">
            {isOwner ? "(Me) " : ""}
            {anonymizableProfile.fullName}
          </div>
        </Grid.Column>
        <Grid.Column width="2" className={`PreselectionVoteCollapsible-dateCol noWrap`}>
          {dateFormat(insertedAt, `dd/mm/yy`)}
          <br />
          {dateFormat(insertedAt, `HH:MM"h"`)}
        </Grid.Column>
        <Grid.Column width="7" className="PreselectionVoteCollapsible-commentCol">
          <div>{formData?.comment || "n/a"}</div>
          {!isCollapsibleActive && <span>More details</span>}
        </Grid.Column>
        {discussionThread && (
          <Grid.Column
            width="2"
            className={`PreselectionVoteCollapsible-repliesCol${
              discussionThread.commentCount === 0 ? " no-replies" : ""
            } noWrap`}
          >
            <span onClick={onOpenReplies}>
              {discussionThread.commentCount}
              <Image src={iconReply} />
            </span>
          </Grid.Column>
        )}
      </Grid.Row>
      {isCollapsibleActive && (
        <Grid.Row>
          <Grid.Column className="PreselectionVoteCollapsible-dropdown">
            {formData && isAbstainVoteSchema(formData) && (
              <>
                <div className="PreselectionVoteCollapsible-dropdown-header">
                  WHY ARE YOU ABSTAINING?
                </div>
                <p>{readableAbstainReason(formData.reason)}</p>
              </>
            )}
            <div className="PreselectionVoteCollapsible-dropdown-header">ADDITIONAL COMMENTS</div>
            <p>{formData?.comment || "n/a"}</p>
            {formData && isYesNoVoteSchema(formData) && (
              <>
                <div className="PreselectionVoteCollapsible-dropdown-header">QUESTIONS</div>
                <div className="PreselectionVoteCollapsible-dropdown-subheader">Desirability</div>
                <div className="PreselectionVoteCollapsible-dropdown-question">
                  <div>{`Is ${startupName} solving a critical pain-point?`}</div>
                  <div className="PreselectionVoteCollapsible-dropdown-question-score">
                    {readableScore(formData.criticalPainPoint) || "n.a"}
                    <ScoreInfoPopup questionName="criticalPainPointScale" />
                  </div>
                </div>
                <div className="PreselectionVoteCollapsible-dropdown-question">
                  <div>{`How strong is ${possessiveName} traction so far?`}</div>
                  <div className="PreselectionVoteCollapsible-dropdown-question-score">
                    {readableScore(formData.traction) || "n.a"}
                    <ScoreInfoPopup questionName="tractionScale" />
                  </div>
                </div>

                <div className="PreselectionVoteCollapsible-dropdown-subheader">Feasibility</div>
                <div className="PreselectionVoteCollapsible-dropdown-question">
                  <div>{`How different is ${possessiveName} technology stack vs. the market (or anything you have encountered)?`}</div>
                  <div className="PreselectionVoteCollapsible-dropdown-question-score">
                    {readableScore(formData.marketDifferentiation) || "n.a"}
                    <ScoreInfoPopup questionName="marketDifferentiationScale" />
                  </div>
                </div>
                <div className="PreselectionVoteCollapsible-dropdown-question">
                  <div>{`Can ${startupName} productise it / make it scalable?`}</div>
                  <div className="PreselectionVoteCollapsible-dropdown-question-score">
                    {readableScore(formData.scalability) || "n.a"}
                    <ScoreInfoPopup questionName="scalabilityScale" />
                  </div>
                </div>
                <div className="PreselectionVoteCollapsible-dropdown-subheader">Viability</div>
                <div className="PreselectionVoteCollapsible-dropdown-question">
                  <div>{`Does ${startupName} understand the paying customer orc other key actors involved in the sale?`}</div>
                  <div className="PreselectionVoteCollapsible-dropdown-question-score">
                    {readableScore(formData.knowledgeOfCustomers) || "n.a"}
                    <ScoreInfoPopup questionName="knowledgeOfCustomersScale" />
                  </div>
                </div>
                <div className="PreselectionVoteCollapsible-dropdown-question">
                  <div>{`How well does ${startupName} understand their financial metrics (e.g. revenue growth, potential cost base, unit economics, etc.)?`}</div>
                  <div className="PreselectionVoteCollapsible-dropdown-question-score">
                    {readableScore(formData.knowledgeOfFinancialMetrics) || "n.a"}
                    <ScoreInfoPopup questionName="knowledgeOfFinancialMetricsScale" />
                  </div>
                </div>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};
