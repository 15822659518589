import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation UnFollow($input: UnfollowInput!) {
    payload: unfollow(input: $input) {
      follow {
        id
        followable {
          ... on TickettingTicket {
            id
            followedBy
          }
          ... on Role {
            id
            followedBy
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: UnfollowInput;
}

interface UnfollowInput {
  readonly followableId: string;
}

export interface Result {
  readonly payload?: UnfollowPayload;
}

interface UnfollowPayload {
  readonly follow: Identifiable;
  readonly followable: { readonly followedBy: boolean } & Identifiable;
}
