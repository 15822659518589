import { gql } from "@apollo/client";
import { Identifiable } from "../types";

export const MUTATION = gql`
  mutation CreateNewApplicationFeedback($input: CreateNewApplicationFeedbackInput!) {
    payload: createNewApplicationFeedback(input: $input) {
      applicationFeedback {
        id
      }
    }
  }
`;

interface CreateNewApplicationFeedbackInput {
  readonly captcha: string;
  readonly url: string;
  readonly score: number;
  readonly feedback: string;
  readonly chainProviderStatus?: string;
}

export interface Variables {
  readonly input: CreateNewApplicationFeedbackInput;
}

export interface Result {
  readonly payload?: { readonly applicationFeedback: Identifiable };
}
