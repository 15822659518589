import { gql } from "@apollo/client";
import { digestObj } from "../../types/OnboardForm";
import { ticketSubject } from "./TicketSeal";
import { BID_SEAL_FIELDS, BidSealFields } from "./_fragments/BidSealFields";

export const QUERY = gql`
  query BidSeal($id: ID!) {
    fields: node(id: $id) {
      ...BidSealFields
    }
  }
  ${BID_SEAL_FIELDS}
`;

export interface SealQueryResult {
  readonly fields: BidSealFields;
}

type BidSealActionType =
  | "publish"
  | "revert_to_draft"
  | "accept_offer"
  | "select_winner"
  | "reject"
  | "decline_offer";

export const bidEnvelope = (
  chainId: number,
  fields: BidSealFields,
  transmittedAt: Date,
  action: BidSealActionType,
  prevSignature: string | null = null
) => ({
  chain_id: chainId.toString(),
  header: "Ticketting Bid",
  meta: {
    action,
    previous_seal_signature: prevSignature,
  },
  subject: bidSubject(fields, transmittedAt),
});

export const bidSubject = (fields: BidSealFields, transmittedAt: Date): BidSealFields => ({
  ...fields,
  form: digestObj(fields.form, false) as any,
  ticket: ticketSubject(fields.ticket, new Date(fields.ticket.transmittedAt)),
  transmittedAt: transmittedAt.toISOString().split(".")[0] + "Z",
});
