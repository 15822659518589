import { useCallback } from "react";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { DeepPartial } from "uniforms";
import dateFormat from "dateformat";
import { Grid, Button, Divider } from "semantic-ui-react";
import { bridge } from "./DeliverablesPlanSchema";
import { QUERY as DraftQuery } from "../../api/tickets/DraftDeliverablesOnTicket";
import { Result as DraftRes } from "../../api/tickets/DraftDeliverablesOnTicket";
import { Variables as DraftVars } from "../../api/tickets/DraftDeliverablesOnTicket";
import { MUTATION as AmendMutation } from "../../api/tickets/AmendDraftTicketPlan";
import { Variables as AmendVars, Result as AmendRes } from "../../api/tickets/AmendDraftTicketPlan";
import { MUTATION as PublishMutation } from "../../api/tickets/PublishDraftTicketPlan";
import { Variables as PublishVars } from "../../api/tickets/PublishDraftTicketPlan";
import { Result as PublishRes } from "../../api/tickets/PublishDraftTicketPlan";
import { QUERY, SealQueryResult, ticketEnvelope } from "../../api/seals/TicketSeal";
import { Shortcuts as S } from "../../routing";
import { LoaderWithMargin } from "../../components/Loader";
import { ErrorMessages } from "../../components/elements/ErrorMessages";
import { ApiError, extractErrorMessages, isDefined, Maybe, noResultErrorFor } from "../../types";
import { DeliverablesPlanSchema } from "../../schemas/deliverablesPlan/_types";
import { TicketState } from "../../types/ticket";
import { CustomListField } from "../../schemas/CustomListField";
import { CustomListItemField } from "../../schemas/CustomListItemField";
import { CustomDeliverableField } from "./CustomDeliverableField";
import { SignatureButton } from "../../../src/components/elements/SignatureButton";
import { AjvError, appendCustomAjvError } from "../../utils/Ajv";
import { useConfirmationModalApi } from "../../contexts/ConfirmationModal";
import { YesNoAnswer } from "../_types";
import { Enrolled } from "../../contexts/Session/state";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { TicketSealFields } from "../../api/seals/_fragments/TicketSealFields";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { digestFormData } from "../../types/OnboardForm";
import { PlanFeedbackCard } from "./PlanFeedbackCard";
import { nodesFromEdges } from "../../types/relay";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";

const savedOnStyle: CSSProperties = { marginLeft: "15px" };

interface Props {
  readonly sessionState: Enrolled;
}

export const DeliverablesPlanForm = ({ sessionState }: Props) => {
  // Hooks.
  const history = useHistory();
  const location = useLocation();
  const chainApi = useChainApi();
  const chainState = useChainState();
  const ticketId = new URLSearchParams(location.search).get(S.ticket.queryVarNames.id) || "";
  const confirmationModalApi = useConfirmationModalApi();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();

  // Query and mutations.
  const { data, loading, error } = useQuery<DraftRes, DraftVars>(DraftQuery, {
    variables: { id: ticketId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const [amendPlan, { loading: amendLoading }] = useMutation<AmendRes, AmendVars>(AmendMutation);
  const [publishPlan, { loading: publishLoading }] = useMutation<PublishRes, PublishVars>(
    PublishMutation
  );

  // State and Refs.
  const [needsScroll, setNeedsScroll] = useState(false);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const hasPendingSubmitRef = useRef<boolean>(false);
  const currentModelRef = useRef<DeepPartial<DeliverablesPlanSchema>>({});
  const ticketTitle = data?.payload?.ticketForm?.data.title || "Unknow";
  const ticketOwner = data?.payload?.owner.fullName || "Unknow";
  const draftId = data?.payload?.draftPlan?.id;
  const isBttnLoading = amendLoading || publishLoading || sealFieldsLoading;

  // Hook to scroll if errors occur on submit.
  useEffect(() => {
    if (hasPendingSubmitRef.current && needsScroll) {
      const firstError = document.getElementsByClassName("ui red basic pointing label")[0];
      if (firstError && firstError.parentElement) {
        firstError.parentElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }

      hasPendingSubmitRef.current = false;
      setNeedsScroll(false);
    }
  }, [needsScroll]);

  // Hook to save the form if the user tries to navigate to another page.
  useEffect(() => {
    const continuePromise = async () => {
      const form = JSON.stringify(currentModelRef.current);
      if (!draftId) {
        return Promise.reject("Missing draft id");
      }
      return amendPlan({ variables: { input: { draftId, form } } })
        .then(() => {
          confirmationModalApi.setHasChanges(false);
          toast.success("Your draft plan was saved successfuly.");
        })
        .catch((err: ApiError) => toast.error(err.message));
    };

    confirmationModalApi.addMessage("Want to save your changes?");
    confirmationModalApi.setContinuePromise(continuePromise);

    return () => {
      confirmationModalApi.removeContinuePromise();
    };
  }, [confirmationModalApi, draftId, amendPlan]);

  // This hook stores the queried data on a ref as soon as it arrives.
  useEffect(() => {
    currentModelRef.current = data?.payload?.draftPlan?.form.data || {};
  }, [data]);

  const onChangeModel = useCallback(
    (m: DeepPartial<DeliverablesPlanSchema>) => {
      confirmationModalApi.setHasChanges(true);
      currentModelRef.current = m;
    },
    [confirmationModalApi]
  );

  const onValidate = useCallback(
    (m: DeepPartial<DeliverablesPlanSchema>, mError: Maybe<AjvError>) => {
      let e = mError;
      const devs = m.deliverables || [];
      const compensationTotal = devs.reduce(
        (acc, curr) =>
          ((curr?.associatePayment === YesNoAnswer.Yes && curr?.totalPayment) || 0) + acc,
        0
      );
      const isInvalidCompensation = compensationTotal > 100;

      m.deliverables?.forEach((_, i) => {
        const path = `/deliverables/${i}/totalPayment`;
        e = appendCustomAjvError(e, isInvalidCompensation, path, "Total should not exceed 100%");
      });

      const hasErrors = isDefined(e) && e.details.length > 0;
      if (hasPendingSubmitRef.current && hasErrors) {
        setNeedsScroll(true);
        console.warn("model", m);
        console.warn("errors", e?.details);
      }

      return e;
    },
    []
  );

  const onSave = useCallback(() => {
    const form = JSON.stringify(currentModelRef.current);
    if (!draftId) {
      return;
    }

    confirmationModalApi.setHasChanges(false);
    amendPlan({ variables: { input: { draftId, form } } })
      .then(() => toast.success("Your plan was saved successfuly."))
      .catch((err: ApiError) => {
        toast.error("Something went wrong.");
        console.warn(err.message);
      });
  }, [draftId, amendPlan, confirmationModalApi]);

  const onSubmitBttnClick = useCallback(() => (hasPendingSubmitRef.current = true), []);
  const onSubmit = useCallback(async () => {
    if (!draftId) {
      return;
    }

    const form = JSON.stringify(currentModelRef.current);
    await amendPlan({ variables: { input: { draftId, form } } });

    const transmittedAt = new Date();
    const { chainId } = sessionState;

    setSealFieldsLoading(true);
    const sealData = await sealFieldsQuery(QUERY, { id: ticketId });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const fields: TicketSealFields = {
      ...sealData.fields,
      planForm: { data: currentModelRef.current },
    };
    const prevSig = data?.payload?.seal?.signature;
    const envelope = ticketEnvelope(chainId, fields, transmittedAt, "publish_plan", prevSig);
    const text = digestFormData(envelope, true);

    chainApi
      .sign(chainState, text)
      .then((ethSignature) => {
        return publishPlan({ variables: { input: { draftId, ethSignature, transmittedAt } } });
      })
      .then(() => {
        const newPlanToast = `Delivery plan for ${ticketTitle} submitted to ${ticketOwner}`;
        const revisedPlanToast = `Plan submitted successfully to ${ticketOwner} for review.`;
        const isRevision = nodesFromEdges(data.payload?.planFeedback?.edges).length > 0;

        toast.success(isRevision ? revisedPlanToast : newPlanToast);
        confirmationModalApi.setHasChanges(false);
        const search = new URLSearchParams({
          [S.reviewPlan.queryVarNames.id]: ticketId,
        }).toString();
        history.replace({ pathname: S.reviewPlan.path, search });
      })
      .catch((err: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (err.message.includes("User denied message signature")) {
          return;
        }
        toast.error(err.message);
      });
  }, [
    sessionState,
    data,
    draftId,
    ticketTitle,
    ticketOwner,
    history,
    ticketId,
    chainApi,
    chainState,
    confirmationModalApi,
    amendPlan,
    publishPlan,
    sealFieldsQuery,
  ]);

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.payload || !data.payload.draftPlan) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Ticket"))} />;
  } else if (data.payload.state !== TicketState.Winner_proposal) {
    const search = new URLSearchParams({ [S.ticket.queryVarNames.id]: data.payload.id }).toString();
    history.replace({ pathname: S.ticket.path, search });
  }

  const { form: draftForm, insertedAt, updatedAt } = data.payload.draftPlan;

  return (
    <Grid>
      <Grid.Column width="12">
        <div className="WrapperSection">
          <div className="ComponentHeader">
            {ticketTitle}
            <div className="ComponentHeader-extra">
              Created on {dateFormat(insertedAt, "HH:MM | dd/mm/yy")}
              {insertedAt !== updatedAt && (
                <span style={savedOnStyle}>
                  Last saved on {dateFormat(updatedAt, "HH:MM | dd/mm/yy")}
                </span>
              )}
            </div>
          </div>
          <Divider />
          <AutoForm
            showInlineError
            schema={bridge}
            model={draftForm.data}
            onSubmit={onSubmit}
            onValidate={onValidate}
            onChangeModel={onChangeModel}
          >
            <CustomListField name="deliverables" label={null} addIconText="Add another">
              <CustomListItemField name="$">
                <CustomDeliverableField name="" />
              </CustomListItemField>
            </CustomListField>
            <br />
            <div>
              <Button type="button" color="blue" basic loading={isBttnLoading} onClick={onSave}>
                <b>Save</b>
              </Button>
              <SignatureButton
                floated="right"
                type="submit"
                content="Submit"
                loading={isBttnLoading}
                onClick={onSubmitBttnClick}
              />
            </div>
          </AutoForm>
        </div>
      </Grid.Column>
      <Grid.Column width="4">
        <PlanFeedbackCard ticketId={ticketId} />
      </Grid.Column>
    </Grid>
  );
};
