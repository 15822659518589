import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { Grid, GridRow, GridColumn } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { connectField, HTMLFieldProps } from "uniforms";
import { UploadDocs } from "../components/elements/tickets/UploadDocs";
import { MUTATION, Variables, Result } from "../api/documents/CreateDocument";
import { QUERY } from "../api/documents/DocumentsByIds";
import { Variables as DocsByIdsVars } from "../api/documents/DocumentsByIds";
import { Result as DocsByIdsRes } from "../api/documents/DocumentsByIds";
import { Document } from "../types/document";
import { FormDocument } from "./_types";

const defaultMaxDocs = 1;

interface Props extends HTMLFieldProps<FormDocument, HTMLInputElement> {
  readonly [k: string]: unknown;
  readonly acceptedFiles: string[];
}

export const CustomUploadField = connectField((props: Props) => {
  const { value, acceptedFiles, onChange } = props;
  const { label, className, disabled, required } = props;
  const { showInlineError, error, errorMessage } = props;

  // Query and mutation.
  const { data } = useQuery<DocsByIdsRes, DocsByIdsVars>(QUERY, {
    variables: { ids: value?.id ? [value.id] : [] },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [createDocument] = useMutation<Result, Variables>(MUTATION);

  const documents: ReadonlyArray<Document> | undefined = useMemo(() => {
    if (!data?.nodes || !data.nodes[0]) {
      return undefined;
    }
    return [data.nodes[0]];
  }, [data]);

  const onFilesChange = useCallback(
    (files?: File[]) => {
      if (!files || files.length === 0) {
        return;
      }
      const upload = files[0];
      let isValidType = false;
      acceptedFiles?.forEach((t) => {
        isValidType = isValidType || upload.type.includes(t.slice(1));
      });

      if (!isValidType) {
        toast.error("Invalid file type.");
        return onChange(undefined);
      }

      createDocument({ variables: { input: { upload } } })
        .then((result) => {
          if (!result.data || !result.data.payload) {
            return;
          }
          const { id, hash, uploadedFile } = result.data.payload.document;
          const newDoc: FormDocument = { id, hash, filename: uploadedFile.filename };
          onChange(newDoc);
        })
        .catch((e) => {
          toast.error("Failed to upload attachment.");
          console.warn(e);
        });
    },
    [acceptedFiles, onChange, createDocument]
  );

  const onDeleteDocument = useCallback(() => onChange(undefined), [onChange]);

  return (
    <Grid>
      <GridRow>
        <GridColumn width="16">
          <div className={classNames(className, { disabled, error, required }, "field")}>
            {label && <label>{label}</label>}
            <UploadDocs
              documents={documents}
              maxDocs={defaultMaxDocs}
              onFilesChange={onFilesChange}
              onDeleteDocument={onDeleteDocument}
              acceptedFiles={acceptedFiles}
            />
            <div>
              {!!(error && showInlineError) && (
                <div className="ui red basic pointing label">{errorMessage}</div>
              )}
            </div>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
});
