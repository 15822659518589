import { CSSProperties, useMemo } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { DeepPartial } from "uniforms";
import { Button, Image } from "semantic-ui-react";
import { VoteCounters } from "../../elements/votes/VoteCounters";
import { useCategoriesState } from "../../../contexts/Categories";
import { isSuccessState } from "../../../contexts/Generic";
import defaultLogo from "../../../assets/startup-square-icon.svg";
import greenClock from "../../../assets/green-clock.svg";
import yellowClock from "../../../assets/yellow-clock.svg";
import orangeClock from "../../../assets/orange-clock.svg";
import { formattedValuation } from "../../../utils/applications/Formatting";
import { OnboardState, OnboardStatus, PublicOnboardViewedByMember } from "../../../types/onboard";
import { FundraisingInfo, OnboardForm } from "../../../types/OnboardForm";
import { Shortcuts as S } from "../../../routing";
import { StartupData } from "../../../schemas/onboards/utils/_types";
import { assertUnreachable } from "../../../types";
import { countryMapping } from "../../../utils/CountriesList";
import { TextClamp } from "../../elements/TextClamp";
import { OnboardMigrator } from "../../../migrations/Onboards";

const displayRubyStyle: CSSProperties = { display: "flex", alignItems: "center" };
const colorBlackyStyle: CSSProperties = { color: "black" };

const millisInADay = 1000 * 60 * 60 * 24;

interface Props {
  readonly onboard: PublicOnboardViewedByMember;
}

const todayDate = new Date();
export const StartupOnboardCard = ({ onboard }: Props) => {
  const categoriesState = useCategoriesState();

  const { id, migratedForm, preselectionEndsAt, state } = useMemo(() => {
    const { migrate } = new OnboardMigrator(onboard);
    return migrate();
  }, [onboard]);
  const formData: DeepPartial<OnboardForm> | undefined = migratedForm?.data;

  const search = new URLSearchParams({ [S.startup.queryVarNames.id]: id }).toString();
  const linksTo = `${S.startup.path}?${search}`;

  if (!isSuccessState(categoriesState) || !formData || !formData.startupData) {
    return null;
  }

  const logoEdges = onboard.logoAttachment?.edges;
  const logo = logoEdges && logoEdges[0] ? logoEdges[0].node?.downloadUrl : undefined;
  const { startupData } = formData;
  const { name, valuation, maturity, sectors, headquarters: hqs } = startupData;
  const { sectorsMapping, maturitiesMapping } = categoriesState.result;

  const awaitingEnableVoting = state === OnboardState.AwaitingEnableVoting;

  const daysLeft = preselectionEndsAt
    ? (new Date(preselectionEndsAt).getTime() - todayDate.getTime()) / millisInADay
    : -1;

  return (
    <div className={`StartupDealflowCard`}>
      <Link to={linksTo}>
        {generateCardLabel(onboard)}
        <div className="StartupDealflowCard-header">
          <Image src={logo || defaultLogo} />
          <div className="StartupDealflowCard-header-title">
            <TextClamp text={name || "Unknown Startup"} />
            <div className="StartupDealflowCard-header-title-sub">
              {formattedValuation(valuation || null)} pre-money
            </div>
            {maturity && (
              <div className="StartupDealflowCard-header-title-sub">
                <TextClamp text={maturitiesMapping[maturity]} maxLines={2} />
              </div>
            )}
          </div>
        </div>
        {!awaitingEnableVoting && (
          <div className="StartupDealflowCard-body">
            {sectors && (
              <div className="StartupDealflowCard-body-list">
                Sector:{" "}
                <b>
                  <TextClamp text={sectors.map((s) => sectorsMapping[s]).join(", ")} />
                </b>
              </div>
            )}
            {startupData && (
              <div className="StartupDealflowCard-body-list">
                Raising: <b>{generateRaisingLabel(startupData)}</b>
              </div>
            )}
            <div className="StartupDealflowCard-body-list">
              Deal Champion: <b>{onboard.sender.fullName}</b>
            </div>
            {hqs && hqs.length > 0 && (
              <div className="StartupDealflowCard-body-list">
                Headquarters:{" "}
                <b>
                  <TextClamp text={hqs.map((hq) => countryMapping(hq)).join(", ")} />
                </b>
              </div>
            )}
            <div className="StartupDealflowCard-body-footer">
              {generateFooter(onboard, daysLeft)}
            </div>
          </div>
        )}
      </Link>
    </div>
  );
};

const generateFooter = (onboard: PublicOnboardViewedByMember, daysLeft: number) => {
  const { state, status, proposal, closedAt } = onboard;

  if (state === OnboardState.InPreselection) {
    if (proposal?.hasVoted) {
      return (
        <>
          <div className="StartupDealflowCard-body-footer-voted">
            <b>Voted</b> <VoteCounters tally={proposal.tally} />
          </div>
          {generateDaysLeftLabel(daysLeft)}
        </>
      );
    } else {
      return (
        <>
          <Button color="purple">Vote now</Button>
          {generateDaysLeftLabel(daysLeft)}
        </>
      );
    }
  } else if (state === OnboardState.TimedOut) {
    if (proposal?.hasVoted) {
      return (
        <>
          <div className="StartupDealflowCard-body-footer-voted">
            <b>Voted</b> <VoteCounters tally={proposal.tally} />
          </div>
          {generateDaysLeftLabel(daysLeft)}
        </>
      );
    } else {
      return (
        <>
          <span />
          {generateDaysLeftLabel(daysLeft)}
        </>
      );
    }
  } else if (state === OnboardState.UnderReview && proposal?.hasVoted) {
    return (
      <>
        <div className="StartupDealflowCard-body-footer-voted">
          <b>Voted</b> <VoteCounters tally={proposal.tally} />
        </div>
        {generateDaysLeftLabel(daysLeft)}
      </>
    );
  } else if (state === OnboardState.Closed && status === OnboardStatus.Rejected) {
    return (
      <div style={colorBlackyStyle}>
        <b>Rejected on </b> {dateFormat(closedAt, "dd/mm/yy")}
      </div>
    );
  }
  return <></>;
};

const generateCardLabel = (onboard: PublicOnboardViewedByMember) => {
  const { state, status } = onboard;
  let cName = "StartupDealflowCard-label";
  let label = "";

  if (state === OnboardState.AwaitingEnableVoting) {
    cName += " Being-reviewed";
    label = "waiting";
  } else if (state === OnboardState.InPreselection || state === OnboardState.TimedOut) {
    cName += " Pre-selection";
    label = "In pre-selection";
  } else if (state === OnboardState.UnderReview) {
    cName += " General-assessment";
    label = "In General Assessment";
  } else if (state === OnboardState.Closed && status === OnboardStatus.Rejected) {
    cName += " Unsuccessful";
    label = "Unsuccessful";
  }
  return <div className={cName}>{label}</div>;
};

const generateRaisingLabel = ({
  hasLeadInvestor,
  fundraisingInfo,
  amountRaised,
}: DeepPartial<StartupData>) => {
  switch (fundraisingInfo) {
    case FundraisingInfo.NOT_RAISING:
      return "Not raising now";
    case FundraisingInfo.BRIDGE_ROUND:
    case FundraisingInfo.RAISING:
      return `£${amountRaised?.toLocaleString()}
            ${hasLeadInvestor ? " (lead investor confirmed)" : ""}`;
    case undefined:
      return "Unknown";
  }
  assertUnreachable(fundraisingInfo);
};

export const getPreselectionClock = (daysLeft: number): JSX.Element | null => {
  if (daysLeft >= 11) {
    return <Image src={greenClock} />;
  } else if (daysLeft >= 4) {
    return <Image src={yellowClock} />;
  } else if (daysLeft >= 0) {
    return <Image src={orangeClock} />;
  } else {
    return null;
  }
};

const generateDaysLeftLabel = (daysLeft: number): JSX.Element => {
  let text: JSX.Element;

  if (daysLeft <= 0) {
    text = <>Voting closed</>;
  } else if (daysLeft <= 1) {
    text = <span className="StartupDealflowCard-body-footer-last-day">Last day to vote</span>;
  } else {
    text = <>{Math.floor(daysLeft)} days left</>;
  }

  return (
    <div style={displayRubyStyle}>
      {getPreselectionClock(daysLeft)}&nbsp;&nbsp;
      <b>{text}</b>
    </div>
  );
};
