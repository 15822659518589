import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { utils } from "../../../utils/utils";
import { Ethereum } from "../utils/_types";
import { globalDefinitions } from "../../_definitions";

export const schema: JSONSchemaType<Ethereum> = {
  type: "object",
  properties: {
    ethAddress: globalDefinitions.ethAddress,
  },
  required: ["ethAddress"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
