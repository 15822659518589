import { gql } from "@apollo/client";
import { JSONDOCUMENT_FRAGMENT } from "./JsonDocument";
import { PAGEINFO_FRAGMENT } from "./PageInfo";
import { PROPOSAL_FRAGMENT } from "./Proposal";
import { SEAL_FRAGMENT } from "./Seal";

export const PUBLIC_ONBOARD_FRAGMENT = gql`
  fragment PublicOnboard on Onboard {
    id
    kind
    type
    state
    status
    invitationReason
    fullName
    recipientFullName

    recipient {
      id
      avatarUrl
      profile {
        ... on IndividualProfile {
          id
          biography
        }
      }
    }
    sender {
      id
      fullName
      avatarUrl
    }

    insertedAt
    transmittedAt
    acceptedAt
    updatedAt
    amendedAt
    submittedAt
    preselectionEndsAt
    timedoutAt
    approvedAt
    closedAt

    logoAttachment: attachments(first: 1, scope: "logo") {
      edges {
        node {
          id
          downloadUrl
        }
      }
    }
    pitchDeckAttachment: attachments(first: 1, scope: "pitch-deck") {
      edges {
        node {
          id
          downloadUrl
        }
      }
    }
    form(migrate: false) {
      ...JsonDocument
    }
    seal {
      ...Seal
    }
    proposal(kind: "preselection") {
      ...Proposal
    }
  }
  ${JSONDOCUMENT_FRAGMENT}
  ${SEAL_FRAGMENT}
  ${PROPOSAL_FRAGMENT}
`;

export const RECEIVED_ONBOARD_FRAGMENT = gql`
  fragment ReceivedOnboard on Onboard {
    ...PublicOnboard
    recipientEmail
    ethAddress
    identityChecksStatus
    onfidoCheckId
    onfidoApplicantId

    recipient {
      mmUserId
    }
  }
  ${PUBLIC_ONBOARD_FRAGMENT}
`;

export const SENT_ONBOARD_FRAGMENT = gql`
  fragment SentOnboard on Onboard {
    id
    kind
    type
    state
    status
    invitationReason
    fullName
    recipientFullName

    recipient {
      id
      avatarUrl
      profile {
        ... on IndividualProfile {
          id
          biography
        }
      }
    }
    sender {
      id
      fullName
      avatarUrl
    }

    insertedAt
    transmittedAt
    acceptedAt
    updatedAt
    amendedAt
    submittedAt
    timedoutAt
    approvedAt
    closedAt

    recipientEmail
  }
`;

// CONNECTIONS.
export const SENT_ONBOARD_CONNECTION_FRAGMENT = gql`
  fragment SentOnboardConnection on OnboardConnection {
    edges {
      cursor
      node {
        ...SentOnboard
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${SENT_ONBOARD_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;

export const RECEIVED_ONBOARD_CONNECTION_FRAGMENT = gql`
  fragment ReceivedOnboardConnection on OnboardConnection {
    edges {
      cursor
      node {
        ...ReceivedOnboard
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;
