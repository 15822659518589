import { Typed } from "../../types";
import { ethers, providers } from "ethersv5";
import { utils } from "../../utils/utils";
import { Action, ActionTypes } from "./actions";
import { ContractContext as AccessContext } from "../../contracts/generated/Access";
import { ContractContext as RegisterContext } from "../../contracts/generated/Register";
import { ContractContext as RegistryContext } from "../../contracts/generated/Registry";
import { ContractContext as TokenContext } from "../../contracts/generated/Token";
import { ContractContext as TransactContext } from "../../contracts/generated/Transact";
import AccessAbi from "../../contracts/abi/Access.json";
import RegisterAbi from "../../contracts/abi/Register.json";
import RegistryAbi from "../../contracts/abi/Registry.json";
import TokenAbi from "../../contracts/abi/Token.json";
import TransactAbi from "../../contracts/abi/Transact.json";

// State types.
export enum StateTypes {
  None = "$chain/0/None",
  Connected = "$chain/1/Connected",
  Authorized = "$chain/2/Authorized",
  Linked = "$chain/3/Linked",
}

// Errors.
export enum ErrorType {
  NoEthereum,
  ProviderCall,
  InvalidNetwork,
  CalledWhileBusy,
  SameStateTransition,
}

export interface ChainStateError extends Typed<ErrorType> {
  readonly errorMessage?: string;
}

// Possible states.
export interface Base<T> extends Typed<T> {
  readonly busy: boolean;
  readonly lastError?: ChainStateError;
  readonly lastChangedAt: string;
}

// Contracts.
export interface Contracts {
  readonly access: AccessContext;
  readonly register: RegisterContext;
  readonly registry: RegistryContext;
  readonly token: TokenContext;
  readonly transact: TransactContext;
}

interface Conn<T> extends Base<T> {
  readonly chainId: number;
}
interface WithContracts {
  readonly contracts: Contracts;
}

export interface None extends Base<StateTypes.None> {}
export interface Connected extends Conn<StateTypes.Connected>, WithContracts {}
export interface Authorized extends Conn<StateTypes.Authorized>, WithContracts {}
export interface Linked extends Conn<StateTypes.Linked>, WithContracts {
  readonly account: string;
  readonly transferResult?: string;
}

// Our state union, visible to everyone.
export type State = None | Connected | Authorized | Linked;

export const createInitialState = (lastChangedAt: string): State => ({
  busy: false,
  lastChangedAt,
  type: StateTypes.None,
});

// Helper functions.
export const isNone = (s: State): s is None => s.type === StateTypes.None;
export const isConnected = (s: State): s is Connected => s.type === StateTypes.Connected;
export const isAuthorized = (s: State): s is Authorized => s.type === StateTypes.Authorized;
export const isLinked = (s: State): s is Linked => s.type === StateTypes.Linked;
export const isInitialized = (s: State): s is Connected | Authorized | Linked =>
  s.type > StateTypes.None;

export const isBusy = (s: State): boolean => s.busy;

export const extractChainErrorMessage = (error: Error) => {
  const match = error.message.match(/Error:(.*)((\.)|(^at))/);
  if (match && match[1]) {
    return match[1];
  }
  return `${error.message.slice(0, 200)}...`;
};

const allContracts = (p: providers.Web3Provider, s: providers.JsonRpcSigner): Contracts => {
  const manifest = utils.chainManifest;
  const access = new ethers.Contract(manifest.access.toString(), AccessAbi, p);
  const register = new ethers.Contract(manifest.register.toString(), RegisterAbi, p);
  const registry = new ethers.Contract(manifest.registry.toString(), RegistryAbi, p);
  const token = new ethers.Contract(manifest.token.toString(), TokenAbi, p);
  const transact = new ethers.Contract(manifest.transact.toString(), TransactAbi, p);
  return {
    access: access.connect(s) as unknown as AccessContext,
    register: register.connect(s) as unknown as RegisterContext,
    registry: registry.connect(s) as unknown as RegistryContext,
    token: token.connect(s) as unknown as TokenContext,
    transact: transact.connect(s) as unknown as TransactContext,
  };
};

export const reducer = (state: State, a: Action): State => {
  const lastChangedAt = Date.now().toString();
  switch (a.type) {
    case ActionTypes.SetBusy: {
      const lastError = a.flag ? undefined : state.lastError;
      return { ...state, busy: a.flag, lastError, lastChangedAt };
    }
    case ActionTypes.SetLastError: {
      return { ...state, lastError: a.lastError, busy: false, lastChangedAt };
    }
    case ActionTypes.SetConnected: {
      const { chainId, provider, signer } = a;
      const type = StateTypes.Connected;
      const contracts = allContracts(provider, signer);
      const busy = false;
      return { type, chainId, contracts, busy, lastChangedAt };
    }
    case ActionTypes.SetAuthorized: {
      if (!isConnected(state) && !isLinked(state)) {
        return state;
      }
      const type = StateTypes.Authorized;
      const { chainId, contracts } = state;
      const busy = false;
      return { type, chainId, contracts, busy, lastChangedAt };
    }
    case ActionTypes.SetLinked: {
      if (!isInitialized(state)) {
        return state;
      }
      const type = StateTypes.Linked;
      const { account } = a;
      const { chainId, contracts } = state;
      const busy = false;
      return { type, chainId, contracts, account, busy, lastChangedAt };
    }
    case ActionTypes.Clear: {
      const { lastError } = a;
      if (isInitialized(state)) {
        const type = StateTypes.Connected;
        const { chainId, contracts } = state;
        const busy = false;
        return { type, chainId, contracts, busy, lastError, lastChangedAt };
      }
      return { ...createInitialState(lastChangedAt), lastError };
    }
    default:
      console.warn("Unhandled action type.", a);
  }
  return state;
};
