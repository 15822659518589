import { useEffect, useMemo } from "react";
import { PublicOnboardViewedByMember } from "../../../types/onboard";
import { generateUUID } from "../../../utils/Numbers";
import { ExpertOnboardCard } from "./ExpertOnboardCard";
import userPlaceholder from "../../../assets/user-placeholder.svg";
import blueChevron from "../../../assets/blue-chevron.svg";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Shortcuts as S } from "../../../routing";

const linksToAwaitingSeconders = `${S.expertOnboardingAwaitingSeconder.path}`;
const linksToMutualAssessments = `${S.expertOnboardingMutualAssessment.path}`;
const linksToCBCVoting = `${S.expertOnboardingCBCVoting.path}`;

export enum ViewAllExpertType {
  AwaitingSeconders = "AWAITING_SECONDERS",
  SecondedOnboards = "SECONDED_ONBOARDS",
  CommitteeReview = "COMMITTEE_REVIEW",
}

interface Props {
  readonly onboards: ReadonlyArray<PublicOnboardViewedByMember>;
  readonly viewAllType: ViewAllExpertType;
}

export const ExpertOnboardsRowList = ({ onboards, viewAllType }: Props) => {
  const [firstCardId, containerId] = useMemo(() => [generateUUID(), generateUUID()], []);
  // Trick to display cards in first row only
  useEffect(() => {
    const listContainer = document.getElementById(containerId);
    const cardElement = document.getElementById(firstCardId);
    if (onboards.length <= 0 || !listContainer || !cardElement) {
      return;
    }
    listContainer.style.height = `${cardElement.clientHeight + 10}px`;
  }, [onboards, firstCardId, containerId]);

  const { step, title, viewAllLink, description, emptyListDescriptionText, emptyListTitleText } =
    useMemo(() => {
      if (viewAllType === ViewAllExpertType.AwaitingSeconders) {
        return {
          step: "STEP 1",
          title: "Seconder Opportunities",
          viewAllLink: linksToAwaitingSeconders,
          description:
            "View incoming expert nominees, and help expand our network by evaluating them as a Seconder.",
          emptyListTitleText: "No Seconder opportunities",
          emptyListDescriptionText:
            "New opportunities will be announced on our #Townsquare Mattermost channel",
        };
      } else if (viewAllType === ViewAllExpertType.SecondedOnboards) {
        return {
          step: "STEP 2",
          title: "Mutual Assessment",
          viewAllLink: linksToMutualAssessments,
          description:
            "The Mutual assessment (MA) is a chance for the nominee to discuss any questions they may have about CV directly with existing members. At the same time, our members will evaluate each application to ensure alignment with the CV ecosystem in terms of culture, skill set, and experience with innovation.",
          emptyListTitleText: "No assignments ",
          emptyListDescriptionText: "You are not currently assigned to any nominees",
        };
      } else {
        return {
          step: "STEP 3",
          title: "CBC Voting",
          viewAllLink: linksToCBCVoting,
          description:
            "Members of the Community Building Committee will vote on each application to ensure alignment with the CV ecosystem in terms of culture, skill set and experience with innovation. ",
          emptyListTitleText: "No active votes",
          emptyListDescriptionText:
            "Currently, there aren't any applicants in this stage of onboarding",
        };
      }
    }, [viewAllType]);

  const expertsList = useMemo(() => {
    if (onboards.length <= 0) {
      return (
        <div className="ExpertOnboarding-empty-list">
          <Image src={userPlaceholder} />
          <span className="ExpertOnboarding-empty-list-title">{emptyListTitleText}</span>
          <br />
          <span className="ExpertOnboarding-empty-list-description">
            {emptyListDescriptionText}
          </span>
        </div>
      );
    } else {
      return (
        <div className="ExpertOnboarding-step-right-list" id={containerId}>
          {onboards.map((o, idx) => (
            <ExpertOnboardCard onboard={o} key={o.id} id={idx === 0 ? firstCardId : undefined} />
          ))}
        </div>
      );
    }
  }, [emptyListDescriptionText, emptyListTitleText, containerId, firstCardId, onboards]);

  return (
    <>
      <div className="ExpertOnboarding-step">
        <div className="ExpertOnboarding-step-left">
          <div className="ExpertOnboarding-step-left-circle" />
          <div className="ExpertOnboarding-step-left-bar" />
        </div>
        <div className="ExpertOnboarding-step-right">
          <div className="ExpertOnboarding-step-right-wrap">
            <div>
              <span className="ExpertOnboarding-step-right-wrap-label">{step}</span>
              <span className="ExpertOnboarding-step-right-wrap-label secondary">{title}</span>
            </div>
            {onboards.length > 0 && (
              <b>
                <Link to={viewAllLink} className="ExpertOnboarding-link">
                  View all <Image src={blueChevron} />
                </Link>
              </b>
            )}
          </div>
          <span className="ExpertOnboarding-step-right-description">{description}</span>
          {expertsList}
        </div>
      </div>
    </>
  );
};
