import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import { Shortcuts as S } from "../../../routing";
import { Tabs } from "../Network";
import { QUERY, Variables, Result } from "../../../api/onboards/ExpertOnboarding";
import { EntityKind, EntityType, OnboardState } from "../../../types/onboard";
import { requireEnrolled, useSessionState } from "../../../contexts/Session";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { nodesFromEdges } from "../../../types/relay";
import { LoaderWithMargin } from "../../Loader";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { extractErrorMessages } from "../../../types";
import { ExpertOnboardsRowList, ViewAllExpertType } from "./ExpertOnboardsRowList";

const first = 12;
const search = new URLSearchParams({ [S.network.queryVarNames.tab]: Tabs.Experts }).toString();
const linksTo = `${S.network.path}?${search}`;

export const awatingSeconderStepStates = [OnboardState.AwaitingSeconders, OnboardState.TimedOut];
export const secondedOnboardsStepStates = [OnboardState.InMutualAssessment];
export const inCommitteeReviewStates = [OnboardState.InCommitteeReview];

export const ExpertOnboarding = () => {
  const sessionState = useSessionState();
  const { roleId } = requireEnrolled(sessionState);

  const queryVars: Variables = useMemo(() => {
    return {
      roleId,
      seconderId: roleId,
      first,
      type: [EntityType.Individual, EntityType.Organisation],
      kind: [EntityKind.Provider],
    };
  }, [roleId]);
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: queryVars,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const [awaitingSeconderOnboards, secondedOnboards, cbcVotingOnboards] = useMemo(
    () => [
      nodesFromEdges(data?.awaitingSeconders?.edges),
      nodesFromEdges(data?.secondedOnboards?.edges),
      nodesFromEdges(data?.cbcVotingOnboards?.edges),
    ],
    [data]
  );

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  }

  return (
    <div className="ExpertOnboarding">
      <div className="ExpertOnboarding-description">
        Help expand our ecosystem by participating in Expert Onboarding.
        <Link to={linksTo}> View Expert Network</Link>
      </div>
      <ExpertOnboardsRowList
        onboards={awaitingSeconderOnboards}
        viewAllType={ViewAllExpertType.AwaitingSeconders}
      />
      <ExpertOnboardsRowList
        onboards={secondedOnboards}
        viewAllType={ViewAllExpertType.SecondedOnboards}
      />
      <ExpertOnboardsRowList
        onboards={cbcVotingOnboards}
        viewAllType={ViewAllExpertType.CommitteeReview}
      />
      <div className="ExpertOnboarding-step">
        <div className="ExpertOnboarding-step-left">
          <div className="ExpertOnboarding-step-left-circle" />
          <div className="ExpertOnboarding-step-left-bar" />
        </div>
        <div className="ExpertOnboarding-step-right">
          <span className="ExpertOnboarding-step-right-wrap-label secondary">Members</span>
          <br />
          <span className="ExpertOnboarding-step-right-description">
            You can find a complete list of expert members in the Network section of the app.
          </span>
          <br />
          <br />
          <Button as={Link} to={linksTo} basic color="purple">
            Expert Network <Icon name="arrow right" />
          </Button>
        </div>
      </div>
    </div>
  );
};
