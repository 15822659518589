import React, { useState, ChangeEvent, useCallback } from "react";
import { Form, Button, Icon, Message } from "semantic-ui-react";
import { ErrorMessages } from "../elements/ErrorMessages";
import { isEmpty } from "../../types";
import { PasswordProgressBar } from "../elements/PasswordProgressBar";

export type FormFieldError =
  | { content: string; pointing?: boolean | "above" | "below" | "left" | "right" }
  | undefined;

enum FormIds {
  Password = "@PasswordForm/Password",
  PasswordConfirmation = "@PasswordForm/PasswordConfirmation",
}

export interface Fields {
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly remoteErrors?: string[];
}

type FieldErrors = Record<keyof Fields, FormFieldError>;
const initialErrors: Partial<FieldErrors> = {};

interface Props extends Fields {
  readonly loading: boolean;
  readonly onChange: React.Dispatch<React.SetStateAction<Fields>>;
  readonly onSubmit: () => void;
  readonly passwordLabel?: string;
  readonly buttonLabel?: string;
}

export const PasswordForm = (props: Props) => {
  const { password, passwordConfirmation, loading, remoteErrors } = props;
  const { passwordLabel, buttonLabel } = props;
  const { onChange, onSubmit } = props;
  const [showPwd, setShowPwd] = useState(false);
  const [showPwdConfirmation, setShowPwdConfirmation] = useState(false);
  const [errors, setErrors] = useState(initialErrors);

  const onTogglePwd = useCallback(() => setShowPwd((s) => !s), []);
  const onTogglePwdConf = useCallback(() => setShowPwdConfirmation((s) => !s), []);

  const onFieldChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      onChange(({ remoteErrors: _, ...s }) => ({ ...s, [name]: value }));
      setErrors((s) => ({ ...s, [name]: undefined }));
    },
    [onChange]
  );

  const onFormSubmit = useCallback(() => {
    let newErrors: Partial<FieldErrors> = {};
    if (isEmpty(password)) {
      newErrors = { ...newErrors, password: { content: "Required field" } };
    }
    if (isEmpty(passwordConfirmation)) {
      newErrors = { ...newErrors, passwordConfirmation: { content: "Required field" } };
    }
    if (Object.keys(newErrors).length === 0 && password !== passwordConfirmation) {
      newErrors = { ...newErrors, passwordConfirmation: { content: "Password doesn't match" } };
    }

    if (Object.keys(newErrors).length > 0) {
      return setErrors(newErrors);
    }

    onSubmit();
  }, [password, passwordConfirmation, onSubmit]);

  const pwdFieldType = showPwd ? "text" : "password";
  const pwdConfFieldType = showPwdConfirmation ? "text" : "password";
  const pwdIcon = <Icon link name={showPwd ? "eye" : "eye slash"} onClick={onTogglePwd} />;
  const pwdConfIcon = (
    <Icon link name={showPwdConfirmation ? "eye" : "eye slash"} onClick={onTogglePwdConf} />
  );

  return (
    <>
      <Message warning>
        <Message.Header>
          Password strength is critical to protecting your ecosystem assets and data. We recommend
          using a password manager such as Bitwarden, and setting a password that conforms to the
          following rules:
        </Message.Header>
        <ul>
          <li>At least 12 characters</li>
          <li>Must be Very Strong (see strength bar)</li>
          <li>Avoid obvious repetitions</li>
          <li>Mixed case</li>
          <li>Use numbers, and special characters</li>
          <li>We also check for permutations against hacking dictionaries</li>
        </ul>
      </Message>
      <Form onSubmit={onFormSubmit}>
        <Form.Input
          id={FormIds.Password}
          fluid
          maxLength="2048"
          icon={pwdIcon}
          iconPosition="left"
          label={passwordLabel || "Password"}
          type={pwdFieldType}
          name="password"
          value={password}
          disabled={loading}
          onChange={onFieldChange}
          error={errors.password}
        />
        <Form.Input
          id={FormIds.PasswordConfirmation}
          fluid
          maxLength="2048"
          icon={pwdConfIcon}
          iconPosition="left"
          label="Confirm password"
          type={pwdConfFieldType}
          name="passwordConfirmation"
          value={passwordConfirmation}
          disabled={loading}
          onChange={onFieldChange}
          error={errors.passwordConfirmation}
        />
        <PasswordProgressBar password={password} />
        <ErrorMessages errors={remoteErrors} />
        <Button fluid primary type="submit" loading={loading} disabled={loading}>
          {buttonLabel || "Confirm"}
        </Button>
      </Form>
    </>
  );
};
