import { Image, Popup } from "semantic-ui-react";
import infoIcon from "../../../assets/icon-information.svg";
import { assertUnreachable } from "../../../types";

type QuestionName =
  | "criticalPainPointScale"
  | "tractionScale"
  | "marketDifferentiationScale"
  | "scalabilityScale"
  | "knowledgeOfCustomersScale"
  | "knowledgeOfFinancialMetricsScale";

interface Props {
  readonly questionName: QuestionName;
}

export const ScoreInfoPopup = ({ questionName }: Props) => {
  return (
    <Popup
      trigger={<Image src={infoIcon} />}
      position="right center"
      content={contentFor(questionName)}
    />
  );
};

const contentFor = (questionName: QuestionName) => {
  switch (questionName) {
    case "criticalPainPointScale":
      return (
        <>
          Scale:
          <br />
          <b>1.</b> Pain-point difficult to understand, convoluted, little evidence.
          <br />
          <b>5.</b> Plenty of market evidence and industry testimonials (preferably, collected by
          the team).
        </>
      );
    case "tractionScale":
      return (
        <>
          Scale:
          <br />
          <b>1.</b> No revenue, no traction, no POC customers.
          <br />
          <b>5.</b> Three to five paying customer deals or POCs.
        </>
      );
    case "marketDifferentiationScale":
      return (
        <>
          Scale:
          <br />
          <b>1.</b> Any competent team can build a tech stack that delivers similar business
          benefits.
          <br />
          <b>5.</b> Impossible to reproduce or deliver the same benefit without it.
        </>
      );
    case "scalabilityScale":
      return (
        <>
          Scale:
          <br />
          <b>1.</b> Scale not feasible with their technology stack.
          <br />
          <b>5.</b> No technological limits to scale and customer acquisition.
        </>
      );
    case "knowledgeOfCustomersScale":
      return (
        <>
          Scale:
          <br />
          <b>1.</b> No qualitative or quantitative info on the paying actor.
          <br />
          <b>5.</b> If B2C: Clear, deep segmentation identifying the early adopters / evangelists.
          If B2B: Understanding of the difference between the User and Purse-holder, and their
          different priorities. Full marks for understanding motivation and factors affecting buying
          decisions.
        </>
      );
    case "knowledgeOfFinancialMetricsScale":
      return (
        <>
          Scale:
          <br />
          <b>1.</b> Little understanding of how to price the product or how much the product will
          cost to deliver to the customer.
          <br />
          <b>5.</b> Some basic unit-based costing in place inc. expenses, salaries etc. Maybe
          financial model, key financial or North Star Metric in place.
        </>
      );
  }
  assertUnreachable(questionName);
};
