import dateFormat from "dateformat";
import { CSSProperties, useEffect, useMemo } from "react";
import { Grid, Image } from "semantic-ui-react";
import { Ticket } from "../../../../api/tickets/GetTicketDetailsInfo";
import { useBreadcrumbApi } from "../../../../contexts/Breadcrumb";
import { Enrolled } from "../../../../contexts/Session/state";
import { nodesFromEdges } from "../../../../types/relay";
import { utils } from "../../../../utils/utils";
import { TicketDashboardBreadcrumb } from "./TicketDashboardBreadcrumb";
import { TicketDeliveredProgressSection } from "./TicketDeliveredProgressSection";

const marginStyle: CSSProperties = { paddingTop: "1rem" };

interface Props {
  readonly sessionState: Enrolled;
  readonly ticket: Ticket;
}

export const TicketEvaluation = ({ sessionState, ticket }: Props) => {
  const breadcrumbApi = useBreadcrumbApi();
  const roleId = sessionState.roleId;
  const { status, ticketForm, owner, feedbackEvaluations, winningBid } = ticket;
  const isTicketOwner = owner.id === roleId;

  useEffect(() => {
    if (!ticketForm) {
      return;
    }

    const customTitle = `${ticketForm.data.title} Evaluation`;

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(<TicketDashboardBreadcrumb ticket={ticket} />);

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [status, ticketForm, ticket, breadcrumbApi, sessionState]);

  // There can only be one evaluation
  const evaluation = useMemo(() => {
    if (
      !feedbackEvaluations ||
      !feedbackEvaluations.edges ||
      feedbackEvaluations.edges.length <= 0
    ) {
      return undefined;
    }
    return nodesFromEdges(feedbackEvaluations?.edges)[0];
  }, [feedbackEvaluations]);

  if (!evaluation) {
    return <>No evaluation</>;
  }

  return (
    <Grid columns="2">
      <div style={marginStyle} />
      <Grid.Row>
        <Grid.Column width="12">
          <div className="WrapperSection">
            <div className="TicketEvaluation">
              <div className="TicketEvaluation-header">
                <Image circular src={owner.avatarUrl} />
                <div className="TicketEvaluation-header-text">
                  <div className="TicketEvaluation-header-title">
                    {isTicketOwner
                      ? "Evaluation of ticket delivery"
                      : `Evaluation submitted by ${owner.fullName}`}
                  </div>
                  <div>
                    <b>on &nbsp;{dateFormat(evaluation.insertedAt, "dd / mm / yy")}</b>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="TicketEvaluation-small-text">Scale</div>
              <span>
                <b>1</b> Not at all&nbsp; · &nbsp;<b>2</b> Kind of&nbsp; · &nbsp;<b>3</b>{" "}
                Moderately&nbsp; · &nbsp;<b>4</b> Very&nbsp; · &nbsp;<b>5</b> Completely
              </span>
              <br />
              <br />
              <div className="TicketEvaluation-title">Deliverables</div>
              <br />
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <div className="TicketEvaluation-evaluation">
                      <div>Well prepared and of high quality</div>
                      <div>{evaluation.form.data.deliverablesQuality}</div>
                    </div>
                    <div className="TicketEvaluation-evaluation">
                      <div>Met all functional requirements</div>
                      <div>{evaluation.form.data.functionalRequirements}</div>
                    </div>
                    <div className="TicketEvaluation-evaluation">
                      <div>Addressed all non-functional requirements</div>
                      <div>{evaluation.form.data.nonFunctionalRequirements}</div>
                    </div>
                    <div className="TicketEvaluation-evaluation">
                      <div>Delivered within agreed timelines</div>
                      <div>{evaluation.form.data.agreedTimelines}</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={9}>
                    {evaluation.form.data.deliverablesAdditionalInfo && (
                      <div className="TicketEvaluation-notes">
                        <div>
                          <b>Additional notes</b>
                        </div>
                        <div>{evaluation.form.data.deliverablesAdditionalInfo}</div>
                      </div>
                    )}
                    {!evaluation.form.data.deliverablesAdditionalInfo && (
                      <div className="TicketEvaluation-no-notes">No additional notes</div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <div className="TicketEvaluation-title">
                {utils.withPossessiveApostrophe(winningBid?.owner.fullName || "Unknown")}{" "}
                performance
              </div>
              <br />
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <div className="TicketEvaluation-evaluation">
                      <div>Fluid, clear communication</div>
                      <div>{evaluation.form.data.communication}</div>
                    </div>
                    <div className="TicketEvaluation-evaluation">
                      <div>General responsiveness</div>
                      <div>{evaluation.form.data.responsiveness}</div>
                    </div>
                    <div className="TicketEvaluation-evaluation">
                      <div>Customer-centricity</div>
                      <div>{evaluation.form.data.customerCentricity}</div>
                    </div>
                    <div className="TicketEvaluation-evaluation">
                      <div>Problem solving/ingenuity</div>
                      <div>{evaluation.form.data.problemSolving}</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={9}>
                    {evaluation.form.data.expertAdditionalInfo && (
                      <div className="TicketEvaluation-notes">
                        <div>
                          <b>Additional notes</b>
                        </div>
                        <div>{evaluation.form.data.expertAdditionalInfo}</div>
                      </div>
                    )}
                    {!evaluation.form.data.expertAdditionalInfo && (
                      <div className="TicketEvaluation-no-notes">No additional notes</div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width="4">
          <div className="ComponentHeader">Progress</div>
          <TicketDeliveredProgressSection ticket={ticket} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
