import { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION, UserMessages } from "../../api/_subscriptions/UserMessages";
import { Variables } from "../../api/_subscriptions/UserMessages";
import { AtLeastPresent, isEnrolled } from "../../contexts/Session/state";

interface Props {
  readonly sessionState: AtLeastPresent;
}

export const UserSubscriptionListener = ({ sessionState }: Props) => {
  const userMessagesSub = useSubscription<UserMessages, Variables>(SUBSCRIPTION, {
    variables: {
      authorisation: {
        bearerToken: sessionState.token,
        roleId: isEnrolled(sessionState) ? sessionState.roleId : undefined,
      },
    },
  });
  const { data, loading, error } = userMessagesSub;

  useEffect(() => {
    if (error) {
      return console.warn("UserMessages subscription error:", JSON.stringify(error));
    } else if (loading || !data) {
      return;
    }

    if (data.userMessages.refresh) {
      return window.location.reload();
    }
  }, [data, loading, error]);

  return null;
};
