import { useMemo } from "react";
import { VoteBarOption } from "./utils";

interface Props {
  readonly votes: ReadonlyArray<VoteBarOption>;
}

export const VoteBars = ({ votes }: Props) => {
  const totalVotes = getTotalVotesCount(votes);
  const maxValue = getVotesMaxValue(votes);
  const dividend = maxValue / totalVotes;
  const barsComponent = useMemo(
    () => (
      <>
        {votes.map(({ color, count, label }) => {
          const barWidth = getVoteBarWidth(count, totalVotes, dividend);
          return (
            <div className="Bar-Row" key={`${label}-${color}`}>
              <div className="Bar" style={{ width: `${barWidth}%`, backgroundColor: `${color}` }} />
              <div className="Bar-Label" style={{ width: `${100 - barWidth}%` }}>
                {getVotePercentage(count, totalVotes)} &nbsp;{label}
              </div>
            </div>
          );
        })}
      </>
    ),
    [votes, dividend, totalVotes]
  );

  return <div className="VoteBars">{barsComponent}</div>;
};

const getVotePercentage = (voteCount: number, totalVotes: number) => {
  if (!totalVotes) {
    return 0 + "%";
  }
  return ((voteCount / totalVotes) * 100).toFixed(0) + "%";
};

const getTotalVotesCount = (votes: ReadonlyArray<VoteBarOption>) => {
  let votesCount = 0;
  votes.forEach(({ count }) => (votesCount += count));
  return votesCount;
};

const getVotesMaxValue = (votes: ReadonlyArray<VoteBarOption>) => {
  let max = 0;
  votes.forEach(({ count }) => (count > max ? (max = count) : null));
  return max;
};

const getVoteBarWidth = (voteCount: number, totalVotes: number, dividend: number) => {
  const minWidth = 10;
  if (!totalVotes || !dividend) {
    return minWidth;
  }
  return Math.max(((((voteCount || 0) / totalVotes) * 0.7) / dividend) * 100, minWidth);
};
