import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Variables, Result, QUERY } from "../../../api/onboards/SentOnboard";
import { Shortcuts as S } from "../../../routing";
import { extractErrorMessages, noResultErrorFor } from "../../../types";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { LoaderWithMargin } from "../../Loader";
import { StartupInviteDetails } from "./StartupInviteDetails";
import { ExpertInviteDetails } from "./ExpertInviteDetails";
import { OrganisationInviteDetails } from "./OrganisationInviteDetails";
import { isExpertOnboard, isOrganisationOnboard, isStartupOnboard } from "./_types";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { SentInviteBreadcrumb } from "./SentInviteBreadcrumb";

export const Invite = () => {
  const location = useLocation();
  const breadcrumbApi = useBreadcrumbApi();
  const inviteId = new URLSearchParams(location.search).get(S.invite.queryVarNames.id) || "";

  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { onboardId: inviteId },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!data || !data.onboard) {
      return;
    }

    const customTitle = `Sent Invite`;

    breadcrumbApi.addCustomTitle(customTitle);
    breadcrumbApi.addBody(<SentInviteBreadcrumb onboard={data.onboard} />);

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
    };
  }, [breadcrumbApi, data]);

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.onboard) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("InviteId Provided"))} />;
  }

  return (
    <>
      {isStartupOnboard(data.onboard) && <StartupInviteDetails onboard={data.onboard} />}
      {isExpertOnboard(data.onboard) && <ExpertInviteDetails onboard={data.onboard} />}
      {isOrganisationOnboard(data.onboard) && <OrganisationInviteDetails onboard={data.onboard} />}
    </>
  );
};
