import { gql } from "@apollo/client";
import { WrappedUpload } from "../../types/wrappedUpload";
import { PROFILE_FRAGMENT } from "../_fragments/Profile";
import { IndividualProfile } from "../../types/profile";

export const MUTATION = gql`
  mutation UpdateIndividualProfile($input: UpdateIndividualProfileInput!) {
    payload: updateIndividualProfile(input: $input) {
      subject {
        ... on User {
          id
          avatarUrl
          profile {
            ...Profile
          }
          # allows us to cache the profile of the individual role that was updated
          roles(first: 10) {
            edges {
              node {
                profile {
                  ...Profile
                }
              }
            }
          }
        }
        ... on Role {
          id
          avatarUrl
          profile {
            ...Profile
          }
        }
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;

export interface IndividualProfileInput {
  readonly avatar?: WrappedUpload;
  readonly headline?: string;
  readonly biography?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly linkedinProfileUrl?: string;
}

interface UpdateIndividualProfileInput {
  readonly subjectId: string;
  readonly profile: IndividualProfileInput;
}

export interface Variables {
  readonly input: UpdateIndividualProfileInput;
}

interface Subject {
  readonly id: string;
  readonly avatarUrl: string;
  readonly profile?: IndividualProfile;
}

export interface Result {
  readonly payload?: { readonly subject: Subject };
}
