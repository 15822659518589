import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "semantic-ui-react";
import { useChainApi, useChainState } from "../../../../../../contexts/Chain";
import { isSuccessState } from "../../../../../../contexts/Generic";
import { selectedRole } from "../../../../../../contexts/Session/helpers";
import { Enrolled } from "../../../../../../contexts/Session/state";
import { useSettingsState } from "../../../../../../contexts/Settings";
import { ApiError, isDefined } from "../../../../../../types";
import { ModalAcceptBid } from "./ModalAcceptBid";
import { ModalEvaluationForm } from "./ModalEvaluationForm";
import { EvaluationSchema } from "../../../../../../schemas/bids/evaluation/_types";
import { ModalTerms } from "./ModalTerms";
import { ModalBidAcceptOrReject } from "./ModalBidAcceptOrReject";
import { MUTATION as IssueBidEvalMut } from "../../../../../../api/tickets/IssueBidEvaluation";
import { Result as IssueBidEvalResult } from "../../../../../../api/tickets/IssueBidEvaluation";
import { Variables as IssueBidEvalVariables } from "../../../../../../api/tickets/IssueBidEvaluation";
import { MUTATION as RejectBidMut } from "../../../../../../api/tickets/RejectTicketBid";
import { Result as RejectBidRes } from "../../../../../../api/tickets/RejectTicketBid";
import { Variables as RejectBidVars } from "../../../../../../api/tickets/RejectTicketBid";
import { MUTATION, Result, Variables } from "../../../../../../api/tickets/SelectWinningTicketBid";
import { TickettingBidWithAttachments } from "../../../../../../api/tickets/TickettingBid";
import { QUERY as BidByIdQuery } from "../../../../../../api/tickets/TickettingBid";
import { ModalRejectBid } from "./ModalRejectBid";
import { TicketState } from "../../../../../../types/ticket";
import { TicketBidStatus } from "../../../../../../types/bid";
import { useSealFieldsQuery } from "../../../../../../hooks/useSealFieldsQuery";
import { QUERY, bidEnvelope } from "../../../../../../api/seals/BidSeal";
import { bidEvaluationEnvelope } from "../../../../../../api/seals/BidEvaluationSeal";
import { SealQueryResult as BidSealQueryResult } from "../../../../../../api/seals/BidSeal";
import { BidEvaluationSealFields } from "../../../../../../api/seals/_fragments/BidEvaluationFields";
import { digestFormData } from "../../../../../../types/OnboardForm";
import { nodesFromEdges } from "../../../../../../types/relay";
import { LoaderWithMargin } from "../../../../../Loader";

interface Props {
  readonly sessionState: Enrolled;
  readonly ticketState: TicketState;
  readonly bid: TickettingBidWithAttachments;
}

enum ModalStep {
  None,
  AcceptBid,
  EvaluationForm,
  Terms,
  Result,
  RejectBid,
  Loading,
}

export const Modals = ({ sessionState, bid, ticketState }: Props) => {
  const { id: bidId, owner, status, form, seal, tickettingBidEvaluations } = bid;
  // Hooks.
  const chainState = useChainState();
  const chainApi = useChainApi();
  const settings = useSettingsState();
  const bidSealFieldsQuery = useSealFieldsQuery<BidSealQueryResult>();

  // Mutations.
  const rejectData = useMutation<RejectBidRes, RejectBidVars>(RejectBidMut, {
    refetchQueries: [{ query: BidByIdQuery, variables: { bidId } }],
  });
  const [rejectBid, { loading: rejectBidLoading }] = rejectData;

  const issueBidEvalData = useMutation<IssueBidEvalResult, IssueBidEvalVariables>(IssueBidEvalMut, {
    refetchQueries: [{ query: BidByIdQuery, variables: { bidId } }],
  });
  const [issueBidEvaluation, { loading: issueBidEvalLoading }] = issueBidEvalData;

  const selectWinningBidData = useMutation<Result, Variables>(MUTATION);
  const [selectWinningBid, { loading: selectWinBidLoading }] = selectWinningBidData;

  // State and refs.
  const role = selectedRole(sessionState.user.roles, sessionState.roleId);
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.None);
  const [accepting, setAccepting] = useState(true);
  const [evaluationForm, setEvaluationForm] = useState<EvaluationSchema | undefined>();
  const hasEvalRef = useRef<boolean>(false);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const isLoading =
    rejectBidLoading || issueBidEvalLoading || selectWinBidLoading || sealFieldsLoading;

  const displayDecisionButtons =
    ticketState === TicketState.Winner_selection &&
    !(
      status === TicketBidStatus.Loser ||
      status === TicketBidStatus.VoidedExpertDeclined ||
      status === TicketBidStatus.VoidedTimedOut
    );

  useEffect(() => {
    const n = nodesFromEdges(tickettingBidEvaluations?.edges);
    if (!isDefined(n) || n.length === 0) {
      hasEvalRef.current = false;
    } else {
      hasEvalRef.current = true;
    }
  }, [tickettingBidEvaluations]);

  const onGeneralClose = useCallback(() => {
    setModalStep(ModalStep.None);
    setEvaluationForm(undefined);
  }, []);

  const AcceptClick = useCallback(() => setModalStep(ModalStep.AcceptBid), []);

  const RejectClick = useCallback(() => setModalStep(ModalStep.RejectBid), []);

  const changeModal = useCallback((isBack: boolean) => {
    setModalStep(
      (s) =>
        s +
        (isBack
          ? s - 1 === ModalStep.EvaluationForm && hasEvalRef.current
            ? -2
            : -1
          : s + 1 === ModalStep.EvaluationForm && hasEvalRef.current
          ? 2
          : 1)
    );
  }, []);

  const submitRejectBid = useCallback(async () => {
    if (!isSuccessState(settings) || form === undefined) {
      return null;
    }
    setAccepting(false);
    setModalStep(ModalStep.None);

    const transmittedAt = new Date();
    const chainId = parseInt(settings.result.eth.chain_id, 10);

    setSealFieldsLoading(true);
    const sealData = await bidSealFieldsQuery(QUERY, { id: bid.id });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const { fields } = sealData;
    const { signature } = bid.seal || {};
    const envelope = bidEnvelope(chainId, fields, transmittedAt, "reject", signature);
    const plainText = digestFormData(envelope, true);

    chainApi
      .sign(chainState, plainText)
      .then(async (ethSignature) => {
        setModalStep(ModalStep.Loading);
        return rejectBid({ variables: { input: { bidId, ethSignature, transmittedAt } } });
      })
      .then(() => {
        setModalStep(ModalStep.Result);
      })
      .catch((err: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (err.message.includes("User denied message signature")) {
          setModalStep(ModalStep.RejectBid);
          return;
        }
        setEvaluationForm(undefined);
        toast.error(err.message);
      });
  }, [chainApi, chainState, settings, bidId, rejectBid, form, bid, bidSealFieldsQuery]);

  const submitAcceptBid = useCallback(async () => {
    if (!isSuccessState(settings) || form === undefined) {
      return null;
    }
    setAccepting(true);
    setModalStep(ModalStep.None);

    const transmittedAt = new Date();
    const chainId = parseInt(settings.result.eth.chain_id, 10);

    setSealFieldsLoading(true);
    const sealData = await bidSealFieldsQuery(QUERY, { id: bid.id });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const { fields } = sealData;
    const { signature } = bid.seal || {};
    const envelope = bidEnvelope(chainId, fields, transmittedAt, "select_winner", signature);
    const plainText = digestFormData(envelope, true);

    chainApi
      .sign(chainState, plainText)
      .then(async (ethSignature) => {
        setModalStep(ModalStep.Loading);
        const variables = { input: { bidId: bid.id, ethSignature, transmittedAt } };
        return selectWinningBid({ variables });
      })
      .then(() => setModalStep(ModalStep.Result))
      .catch((err: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (err.message.includes("User denied message signature")) {
          setModalStep(ModalStep.Terms);
          return;
        }
        setModalStep(ModalStep.None);
        setEvaluationForm(undefined);
        toast.error(err.message);
      });
  }, [
    chainApi,
    chainState,
    settings,
    selectWinningBid,
    setModalStep,
    form,
    bid,
    bidSealFieldsQuery,
  ]);

  const submitEvaluation = useCallback(
    async (model: EvaluationSchema) => {
      if (!isSuccessState(settings) || !isDefined(model) || !seal) {
        return null;
      }
      setModalStep(ModalStep.None);
      const transmittedAt = new Date();
      const chainId = parseInt(settings.result.eth.chain_id, 10);

      setSealFieldsLoading(true);
      const sealData = await bidSealFieldsQuery(QUERY, { id: bidId });
      setSealFieldsLoading(false);

      if (!sealData) {
        return toast.error("Failed to fetch the data for the signature.");
      }

      const sForm = { data: model };
      const tAt = transmittedAt.toLocaleString();
      const { fields: bidFields } = sealData;
      const fields: BidEvaluationSealFields = { bid: bidFields, form: sForm, transmittedAt: tAt };
      const envelope = bidEvaluationEnvelope(chainId, fields, transmittedAt);
      const plainText = digestFormData(envelope, true);

      chainApi
        .sign(chainState, plainText)
        .then(async (ethSignature) => {
          setModalStep(ModalStep.Loading);
          const f = JSON.stringify(model);
          const input = { bidId, ethSignature, ownerId: role.id, form: f, transmittedAt };
          return issueBidEvaluation({ variables: { input } });
        })
        .then(() => setModalStep(ModalStep.Terms))
        .catch((err: ApiError) => {
          setModalStep(ModalStep.EvaluationForm);
          // Discard error message thrown when the user clicks cancel on the MM popup.
          if (err.message.includes("User denied message signature")) {
            return;
          }
          toast.error("Something went wrong.");
          console.warn(err);
        });
    },
    [issueBidEvaluation, settings, seal, bidId, chainApi, chainState, role, bidSealFieldsQuery]
  );

  return (
    <>
      {displayDecisionButtons && (
        <div>
          <Button
            basic
            color="blue"
            className="ActionBttn"
            loading={isLoading}
            onClick={RejectClick}
            content={<b>Decline</b>}
          />
          <Button
            color="blue"
            className="ActionBttn"
            loading={isLoading}
            onClick={AcceptClick}
            content={<b>Accept</b>}
            floated="right"
          />
        </div>
      )}
      {modalStep === ModalStep.AcceptBid && (
        <ModalAcceptBid
          changeModal={changeModal}
          onGeneralClose={onGeneralClose}
          hasEval={hasEvalRef.current}
        />
      )}
      {modalStep === ModalStep.EvaluationForm && (
        <ModalEvaluationForm
          changeModal={changeModal}
          onGeneralClose={onGeneralClose}
          setForm={setEvaluationForm}
          form={evaluationForm}
          sign={submitEvaluation}
        />
      )}
      {modalStep === ModalStep.Terms && (
        <ModalTerms
          changeModal={changeModal}
          onGeneralClose={onGeneralClose}
          sign={submitAcceptBid}
        />
      )}
      {modalStep === ModalStep.Result && (
        <ModalBidAcceptOrReject onGeneralClose={onGeneralClose} accepting={accepting} />
      )}
      {modalStep === ModalStep.RejectBid && (
        <ModalRejectBid
          bidOwner={owner.fullName}
          onGeneralClose={onGeneralClose}
          sign={submitRejectBid}
        />
      )}
      {modalStep === ModalStep.Loading && (
        <Modal basic open={true} size="mini" closeOnDimmerClick={false}>
          <LoaderWithMargin content="Loading" size="huge" />
        </Modal>
      )}
    </>
  );
};
