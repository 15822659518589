export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum BallotSortFields {
  INSERTED_AT = "INSERTED_AT",
  ROLE_ID = "ROLE_ID",
}

export interface MatchValue {
  id: string;
}

export interface BallotSort {
  readonly direction: SortDirection;
  readonly field: BallotSortFields;
  readonly match?: MatchValue;
}
