import { snakeCase } from "lodash";
import { DeepPartial } from "uniforms";
import { InitialProfileType } from "../schemas/onboards/schemas/InitialProfileSchema";
import { IndividualTermsData, StartupData } from "../schemas/onboards/utils/_types";
import { assertUnreachable } from "./common";
import { Document } from "./document";
import { EntityKind, EntityType } from "./onboard";

// Frontend-only. We save it to the form just to help keep track of the current step.
export enum IndividualSteps {
  Init,
  Terms,
  KYC,
  ProfileData,
}
export enum OrganisationSteps {
  Init,
  Terms,
  ProfileData,
}
export enum StartupSteps {
  Init,
  Terms,
  PreSelectionData,
}

export type OnboardStep = IndividualSteps | OrganisationSteps | StartupSteps;

export enum InvestorStatus {
  YES = "YES",
  NO = "NO",
  UNDECIDED = "UNDECIDED",
}

export enum FundraisingInfo {
  RAISING = "RAISING",
  NOT_RAISING = "NOT_RAISING",
  BRIDGE_ROUND = "BRIDGE_ROUND",
}

export enum OrganisationSize {
  Micro = "MICRO",
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE",
}

export interface ExpectedPriceRange {
  readonly minimum: number;
  readonly maximum: number;
}

export interface OnboardLinks {
  readonly website?: string;
  readonly linkedin?: string;
  readonly twitter?: string;
  readonly facebook?: string;
  readonly instagram?: string;
}

export type PartialOnboardForm = DeepPartial<OnboardForm>;

export interface OnboardForm extends Partial<InitialProfileType> {
  readonly currentStep: OnboardStep; // Frontend only.
  readonly entityKind: EntityKind;
  readonly isInvestor: InvestorStatus;
  readonly agreesToTerms: boolean;
  readonly agreesToMemorandum: boolean;
  readonly ethAddress: string;
  readonly links?: OnboardLinks;
  readonly startupData?: StartupData;
  readonly providerData?: InjectedProviderData;
}

// Fields injected by the backend.
interface InjectedProviderData extends IndividualTermsData {
  readonly entityType: EntityType;
  readonly individualData?: {
    readonly firstName?: string;
    readonly lastName?: string;
  };
  readonly organisationData?: {
    readonly name?: string;
  };
}

export const digestObj = (t: unknown, convertToSnakeCase: boolean = false): unknown[] | unknown => {
  if (Array.isArray(t)) {
    return t.map((el) => digestObj(el, convertToSnakeCase));
  } else if (typeof t === "object" && t !== null) {
    return Object.entries(t)
      .sort(([k1], [k2]) => (k1 > k2 ? 1 : -1))
      .map(([key, value]) => [
        convertToSnakeCase ? snakeCase(key) : key,
        digestObj(value, convertToSnakeCase),
      ]);
  }
  return t;
};

export const digestFormData = (p: unknown, convertToSnakeCase: boolean = false): string =>
  JSON.stringify(digestObj(p, convertToSnakeCase));

export const documentsToPlainText = (docs: ReadonlyArray<Document>): string => {
  let docsObject = {};
  docs?.forEach((d) => (docsObject = { ...docsObject, [d.id]: d.hash }));
  return digestFormData(docsObject);
};

export const readableOrganisationSize = (s: OrganisationSize): string => {
  switch (s) {
    case OrganisationSize.Micro:
      return "1 - 10 employees";
    case OrganisationSize.Small:
      return "11 - 50 employees";
    case OrganisationSize.Medium:
      return "51 - 200 employees";
    case OrganisationSize.Large:
      return "200 + employees";
  }
  assertUnreachable(s);
};
