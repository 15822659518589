import { useCallback } from "react";
import moment from "moment";
import { Button } from "semantic-ui-react";
import { Context, DeepPartial } from "uniforms";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";
import { CustomDropdownSelectionField } from "../../CustomDropdownSelectionField";
import { CustomTextField } from "../../CustomTextField";
import { DisplayIf } from "../../DisplayIf";
import { DatePickerField } from "../../tickets/components/DatePickerField";
import { bridge } from "../schemas/ProofOfAddress";
import { OnfidoCountries, onfidoCountriesOptions } from "../utils/OnfidoCountries";
import { usaStatesOptions } from "../utils/OnfidoCountries";
import { POASchema } from "../utils/_types";
import { isDefined, Maybe } from "../../../types";
import { AjvError } from "../../../utils/Ajv";

const MIN_DATE = moment(new Date()).subtract(1, "day").toDate();

export interface Props {
  readonly submitForm: (form: POASchema) => void;
  readonly loading: boolean;
}

export const AddressForm = ({ submitForm, loading }: Props) => {
  const onValidate = useCallback((_: DeepPartial<POASchema>, mError: Maybe<AjvError>) => {
    console.warn(mError);
    return mError;
  }, []);

  const onSubmit = useCallback(
    (model: POASchema) => submitForm(sanitizeModel(model)),
    [submitForm]
  );

  const statesCondition = useCallback(
    (c: Context<POASchema>) => c.model.country === OnfidoCountries.UnitedStatesofAmerica,
    []
  );
  return (
    <div className="WrapperSection">
      <div className="KYCForm-proof-of-address-title">Address and Date of Birth</div>
      <span>
        Please provide the following information as part of Know Your Customer (KYC):
        <br />
        <br />
      </span>
      <AutoForm
        className="KYCForm-proof-of-address"
        schema={bridge}
        showInlineError
        onValidate={onValidate}
        onSubmit={onSubmit}
      >
        <CustomTextField
          name="street"
          label="Street Name - the building name and number are not required"
          placeholder="Enter street name"
          errorMessage="This field is mandatory and should not be longer than 32 characters for UK addresses."
          showtextcount="false"
        />
        <CustomTextField
          name="town"
          placeholder="Enter town name"
          errorMessage="This field is mandatory."
          showtextcount="false"
        />
        <CustomDropdownSelectionField
          name="country"
          dropdownOptions={onfidoCountriesOptions}
          placeholder="Country"
          errorMessage="This field is mandatory."
        />
        <span>
          If you don't find your country on this list, please email{" "}
          <a href="mailto:help@consilienceventures.com">help@consilienceventures.com</a>
          <br />
          <br />
        </span>
        <DisplayIf condition={statesCondition}>
          <CustomDropdownSelectionField
            name="state"
            dropdownOptions={usaStatesOptions}
            placeholder="State"
            errorMessage="This field is mandatory."
            required
          />
        </DisplayIf>
        <CustomTextField
          name="postcode"
          label="Post Code"
          placeholder="Enter post code"
          errorMessage="This field is mandatory and must be a valid post code."
          showtextcount="false"
        />
        <DatePickerField
          name="dob"
          label="Date of Birth"
          format="YYYY-MM-DD"
          placeholder="yyyy-mm-dd"
          minDateToday={false}
          maxDate={MIN_DATE}
          errorMessage="This field is mandatory."
        />
        <br />
        <Button type="submit" color="blue" primary loading={loading} disabled={loading}>
          Submit
        </Button>
      </AutoForm>
    </div>
  );
};

const sanitizeModel = (model: POASchema) => {
  let newModel = model;
  if (model.country !== OnfidoCountries.UnitedStatesofAmerica && isDefined(model.state)) {
    const { state, ...m } = model;
    newModel = { ...m } as POASchema;
  }
  if (model.country === OnfidoCountries.UnitedKingdom) {
    newModel = { ...newModel, postcode: newModel.postcode.toUpperCase() };
  }
  return newModel;
};
