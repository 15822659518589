import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { TicketState, TicketStatus } from "../../types/ticket";
import { TicketBidState, TicketBidStatus } from "../../types/bid";

export const MUTATION = gql`
  mutation selectWinningBid($input: SelectWinningTicketBidInput!) {
    payload: selectWinningTicketBid(input: $input) {
      ticket {
        id
        state
        status
      }
      bid {
        id
        status
        state
        selectedWinnerAt
      }
    }
  }
`;

export interface Variables {
  readonly input: SelectWinningTicketBidInput;
}

interface SelectWinningTicketBidInput {
  readonly bidId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: SelectWinningTicketBidPayload;
}

interface SelectWinningTicketBidPayload {
  readonly ticket: { readonly state: TicketState; readonly status: TicketStatus } & Identifiable;
  readonly bid: {
    readonly state: TicketBidState;
    readonly status: TicketBidStatus;
    readonly selectedWinnerAt?: string;
  } & Identifiable;
}
