import { digestObj } from "../../types/OnboardForm";
import { bidSubject } from "./BidSeal";
import { BidEvaluationSealFields } from "./_fragments/BidEvaluationFields";

export const bidEvaluationEnvelope = (
  chainId: number,
  fields: BidEvaluationSealFields,
  transmittedAt: Date
) => ({
  chain_id: chainId.toString(),
  header: "Ticketting Bid Evaluation",
  meta: { action: "publish", previous_seal_signature: null },
  subject: bidEvaluationSubject(fields, transmittedAt),
});

const bidEvaluationSubject = (
  fields: BidEvaluationSealFields,
  transmittedAt: Date
): BidEvaluationSealFields => ({
  ...fields,
  form: digestObj(fields.form, false) as any,
  transmittedAt: transmittedAt.toISOString().split(".")[0] + "Z",
  bid: bidSubject(fields.bid, new Date(fields.bid.transmittedAt)),
});
