import { MouseEventHandler, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import { useBreadcrumbApi } from "../../../../../contexts/Breadcrumb";
import { Enrolled } from "../../../../../contexts/Session/state";
import { Shortcuts as S } from "../../../../../routing";
import { Ticket } from "../../../../../api/tickets/GetTicketDetailsInfo";
import { isEnum } from "../../../../../types";
import { TicketDetails as TicketDetailsComponent } from "./TicketDetails";
import { BidsSection } from "../../BidsSection";
import { DiscussableQuestions } from "../DiscussableQuestions";
import { TicketSummary } from "../../TicketSummary";
import { TicketState, TicketStatus } from "../../../../../types/ticket";
import { Button } from "semantic-ui-react";
import { getDraftPlanButtonText } from "../TicketCard";
import { TicketDashboardBreadcrumb } from "../TicketDashboardBreadcrumb";

const tabVarName = S.mySprint.queryVarNames.tab;
export enum Tabs {
  Detail = "detail",
  Questions = "questions",
  Bids = "bids",
}
export enum TabTitles {
  Detail = "Detail",
  Questions = "Questions",
  Bids = "Bids",
}

interface Props {
  readonly sessionState: Enrolled;
  readonly ticket: Ticket;
}

export const TicketDetailsWrapper = ({ sessionState, ticket }: Props) => {
  const breadcrumbApi = useBreadcrumbApi();
  const history = useHistory();
  const location = useLocation();
  const roleId = sessionState.roleId;
  const ticketId = new URLSearchParams(location.search).get(S.ticket.queryVarNames.id) || "";

  // Hooks and state
  const isOwner = ticket?.owner.id === roleId;
  const isWinningBidder = ticket?.winningBid?.owner.id === roleId;
  const hasBids = (ticket?.anyBid.edges?.length || []) > 0;
  const hasQuestions = (ticket?.anyThread.edges?.length || []) > 0;
  const activeTab = getActiveTab(hasBids, hasQuestions, location, ticket?.status);
  const handleViewPlan = useCallback(() => {
    const search = new URLSearchParams({ [S.reviewPlan.queryVarNames.id]: ticketId }).toString();
    history.push({ pathname: S.reviewPlan.path, search });
  }, [ticketId, history]);

  const handleCreatePlan = useCallback(() => {
    const search = new URLSearchParams({ [S.submitPlan.queryVarNames.id]: ticketId }).toString();
    history.push({ pathname: S.submitPlan.path, search });
  }, [ticketId, history]);

  // Add breadcrumbs.
  useEffect(() => {
    if (!ticket || !ticket.ticketForm) {
      return;
    }

    let body;
    if (ticket.status === TicketStatus.Executing) {
      body = <TicketDashboardBreadcrumb ticket={ticket} />;
      const customTitle = `${ticket.ticketForm.data.title} Details`;
      breadcrumbApi.addCustomTitle(customTitle);
      breadcrumbApi.addBody(body);
    } else {
      const customTitle = `${ticket.ticketForm.data.title} - ${
        activeTab === Tabs.Bids ? "Bids" : activeTab === Tabs.Questions ? "Questions" : "Details"
      }`;
      body = <h2>{ticket.ticketForm.data.title}</h2>;

      breadcrumbApi.addCustomTitle(customTitle);
      breadcrumbApi.addBody(body);

      const onTabChange: MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
        if (isEnum(currentTarget.id, Tabs) && currentTarget.id !== activeTab) {
          const params =
            new URLSearchParams({ [S.ticket.queryVarNames.id]: ticketId }).toString() +
            "&" +
            new URLSearchParams({ [tabVarName]: currentTarget.id });
          history.replace({ search: params.toString() });
        }
      };

      const myTicketsClassname = `panel${activeTab === Tabs.Detail ? " active" : ""}`;
      const newQuestionsClassname = `panel${activeTab === Tabs.Questions ? " active" : ""}`;
      const newBidsClassname = `panel${activeTab === Tabs.Bids ? " active" : ""}`;

      const breadcrumbFooter =
        hasBids || hasQuestions ? (
          <div className="Breadcrumb-children-wrapper">
            <div className="Panes">
              {isOwner && (
                <>
                  {hasBids && (
                    <div className={newBidsClassname} id={Tabs.Bids} onClick={onTabChange}>
                      {TabTitles.Bids}
                    </div>
                  )}
                  {hasQuestions && (
                    <div
                      className={newQuestionsClassname}
                      id={Tabs.Questions}
                      onClick={onTabChange}
                    >
                      {TabTitles.Questions}
                    </div>
                  )}
                  <div className={myTicketsClassname} id={Tabs.Detail} onClick={onTabChange}>
                    {TabTitles.Detail}
                  </div>
                </>
              )}
            </div>
            <div className="Actions">
              {isOwner && ticket.state === TicketState.Winner_plan_submitted && (
                <Button primary onClick={handleViewPlan}>
                  Review Plan
                </Button>
              )}
              {isWinningBidder && ticket.state === TicketState.Winner_plan_submitted && (
                <Button primary onClick={handleViewPlan}>
                  View Plan
                </Button>
              )}
              {isWinningBidder && ticket.state === TicketState.Winner_proposal && (
                <Button primary onClick={handleCreatePlan}>
                  {getDraftPlanButtonText(ticket.planFeedback, ticket.draftPlan)}
                </Button>
              )}
            </div>
          </div>
        ) : null;

      if (breadcrumbFooter) {
        breadcrumbApi.addFooter(breadcrumbFooter);
      }
    }
    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeBody();
      breadcrumbApi.removeFooter();
    };
  }, [
    sessionState,
    activeTab,
    breadcrumbApi,
    history,
    ticketId,
    hasQuestions,
    hasBids,
    isOwner,
    ticket,
    isWinningBidder,
    handleCreatePlan,
    handleViewPlan,
  ]);

  // Scroll to top
  useEffect(() => {
    const ele = document.getElementById("PageContentId");
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }, [activeTab]);

  return (
    <Grid>
      <GridRow>
        {isOwner && activeTab === Tabs.Questions && (
          <>
            <GridColumn width="10">
              <DiscussableQuestions
                discussableId={ticketId}
                showPrivacyIcon={true}
                sessionState={sessionState}
              />
            </GridColumn>
            <GridColumn width="6">
              <TicketSummary ticket={ticket} />
            </GridColumn>
          </>
        )}
        {isOwner && activeTab === Tabs.Bids && (
          <>
            <GridColumn width="10">
              <BidsSection ticketId={ticketId} title="Bids" />
            </GridColumn>
            <GridColumn width="6">
              <TicketSummary ticket={ticket} />
            </GridColumn>
          </>
        )}
        {(!isOwner || (isOwner && activeTab === Tabs.Detail)) && (
          <TicketDetailsComponent sessionState={sessionState} ticket={ticket} />
        )}
      </GridRow>
    </Grid>
  );
};

const getActiveTab = (
  hasBids: boolean,
  hasQuestions: boolean,
  location: any,
  status?: TicketStatus
) => {
  if (status === TicketStatus.Executing) {
    return Tabs.Detail;
  } else {
    return (
      (new URLSearchParams(location.search).get(tabVarName) as Tabs) ||
      (hasBids ? Tabs.Bids : hasQuestions ? Tabs.Questions : Tabs.Detail)
    );
  }
};
