import { gql } from "@apollo/client";
import { TickettingBid } from "../../types/bid";

export const MUTATION = gql`
  mutation IssueDraftTicketBid($input: IssueDraftTicketBidInput!) {
    payload: issueDraftTicketBid(input: $input) {
      bid {
        id
        state
        status
        owner {
          id
          fullName
        }
        publishedAt

        form(migrate: true) {
          id
          data
          schemaVersion
        }
        draft {
          id
          insertedAt
          form {
            id
            data
          }
        }
        ticket {
          id
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: IssueDraftTicketBidInput;
}

interface IssueDraftTicketBidInput {
  readonly ownerId: string;
  readonly ticketId: string;
  readonly form?: string;
}

export interface Result {
  readonly payload?: IssueDraftTicketBidPayload;
}

interface IssueDraftTicketBidPayload {
  readonly bid: TickettingBid;
}
