import { useState, useMemo, useCallback } from "react";
import { Button } from "semantic-ui-react";
import dateFormat from "dateformat";
import moment, { now } from "moment";
import { Link } from "react-router-dom";
import { assertUnreachable, isDefined } from "../../../../types";
import { EntityKind, OnboardStatus } from "../../../../types/onboard";
import { EntityType, readableOnboardStatusAndColor } from "../../../../types/onboard";
import { OnboardState } from "../../../../types/onboard";
import { getPreselectionClock } from "../../StartupDealflow/StartupOnboardCard";
import { Actions, AdminApplicationCardModal } from "./AdminApplicationCardModal";
import { OnboardMigrator } from "../../../../migrations/Onboards";
import { readableBusinessCase } from "../../../../schemas/invites/_types";
import { Shortcuts as S } from "../../../../routing";
import { AdminOnboardWithSeconderCandidacies } from "../../../../api/admin/AdminOnboards";
import { useSettingsState } from "../../../../contexts/Settings";
import { isSuccessState } from "../../../../contexts/Generic";
import { CBCMemberExpertOnboard } from "../../../../api/onboards/CBCMemberExpertOnboard";
import { countryMapping } from "../../../../utils/CountriesList";
import { ProposalWithHasVoted } from "../../../../types/proposal";
import { CBCExpertVoteChoice } from "../../../../schemas/cbc/_types";
import { UnstyledButton } from "../../../UnstyledButton";

interface Props {
  readonly onboard: AdminOnboardWithSeconderCandidacies | CBCMemberExpertOnboard;
  readonly isAdminView: boolean;
}
export const AdminApplicationCard = ({ onboard, isAdminView }: Props) => {
  const settingsState = useSettingsState();
  const [action, setAction] = useState<Actions>();

  const { color: statusColor, status: readableStatus } = useMemo(() => {
    return readableOnboardStatusAndColor(onboard);
  }, [onboard]);

  // Memoized variables and functions.
  const migratedOnboard = useMemo(() => {
    const { migrate } = new OnboardMigrator(onboard);
    return migrate();
  }, [onboard]);

  const { kind, type, state, fullName, migratedForm, extraFields, status } = useMemo(
    () => migratedOnboard,
    [migratedOnboard]
  );
  const businessCase = useMemo(() => migratedForm?.data.businessCase, [migratedForm]);
  const requiredSeconderCount = useMemo(() => {
    if (!isSuccessState(settingsState)) {
      return 2;
    }

    return settingsState.result.core.required_seconder_count;
  }, [settingsState]);

  const onRejectOnboard = useCallback(() => setAction(Actions.RejectOnboard), []);
  const onApproveOnboard = useCallback(() => setAction(Actions.ApproveOnboard), []);
  const onEnableVoting = useCallback(() => setAction(Actions.EnableVotingOnOnboard), []);
  const onMoveToUnderReview = useCallback(() => setAction(Actions.MoveToUnderReview), []);
  const linksTo = useMemo(() => {
    const id = onboard.id;
    const key = S.administrationExpert.queryVarNames.id;
    const search = new URLSearchParams({ [key]: id }).toString();

    if (isAdminView) {
      return `${S.administrationExpert.path}?${search}`;
    } else {
      return `${S.nomineeVotingExpert.path}?${search}`;
    }
  }, [onboard, isAdminView]);

  // Helper booleans.
  const isStartup = kind === EntityKind.Startup;
  const isProvider = kind === EntityKind.Provider;
  const canEnableVoting = isStartup && state === OnboardState.AwaitingEnableVoting;
  const canMoveToGA = isStartup && state === OnboardState.TimedOut;
  const canAccept =
    (isStartup && state === OnboardState.UnderReview) || state === OnboardState.InCommitteeReview;
  const isAwaitingSeconders = isProvider && state === OnboardState.AwaitingSeconders;
  const isPastAwaitingSeconders =
    isProvider && (state === OnboardState.InMutualAssessment || state === OnboardState.Closed);
  const canViewEvaluation =
    isProvider &&
    (state === OnboardState.InMutualAssessment ||
      state === OnboardState.InCommitteeReview ||
      state === OnboardState.Closed);
  const canReject =
    state === OnboardState.AwaitingSeconders ||
    state === OnboardState.AwaitingEnableVoting ||
    state === OnboardState.InPreselection ||
    state === OnboardState.TimedOut ||
    state === OnboardState.UnderReview ||
    state === OnboardState.InMutualAssessment ||
    state === OnboardState.InCommitteeReview;

  const statusClassName = `AdminApplications-card-content-lastColumn-label ${statusColor}`;
  const cardClassName = `AdminApplications-card ${
    state === OnboardState.AwaitingEnableVoting ? "transparent" : ""
  }
  ${isAdminView ? "" : " cbc-vote"}`;

  const roleName = isStartup
    ? "Startup"
    : type === EntityType.Organisation
    ? "Organisation"
    : "Individual";

  const timeStamp = statusTimeStamp(onboard);
  const seconderOne = extraFields.assignedSeconderCandidacies
    ? extraFields.assignedSeconderCandidacies[0]?.seconder.fullName || "Unknown"
    : "Unknown";
  const seconderTwo = extraFields.assignedSeconderCandidacies
    ? extraFields.assignedSeconderCandidacies[1]?.seconder.fullName || "Unknown"
    : "Unknown";
  const geographies = onboard.form?.data.experienceGeography;

  const cbcMemberLastColumn = useCallback(() => {
    if (!(state === OnboardState.InCommitteeReview) || isAdminView) {
      return <>{readableStatus}</>;
    }
    const { committeeProposal } = onboard as CBCMemberExpertOnboard;
    return generateCBCMemberLastColumn(committeeProposal, readableStatus, status);
  }, [onboard, isAdminView, state, status, readableStatus]);

  return (
    <UnstyledButton as={isAdminView ? null : Link} to={isAdminView ? undefined : linksTo}>
      <div className={cardClassName}>
        <div className={`AdminApplications-card-left-ribbon ${statusColor}`} />
        <div className="AdminApplications-card-content">
          <div className="AdminApplications-card-content-column">
            <div className="AdminApplications-card-content-column-description">
              <div className="AdminApplications-card-content-column-description-title">
                {fullName}
              </div>
              <div className="AdminApplications-card-content-column-description-info">
                {roleName}
                {businessCase ? <>&nbsp; | &nbsp;{readableBusinessCase(businessCase)}</> : ""}
              </div>
            </div>
          </div>

          {!!timeStamp && (
            <div className="AdminApplications-card-content-column">
              <div className="AdminApplications-card-content-column-description">
                <div className="AdminApplications-card-content-column-description-title" />
                <div className="AdminApplications-card-content-column-description-info">
                  {timeStamp}
                </div>
              </div>
            </div>
          )}

          {!isAdminView && (
            <div className="AdminApplications-card-content-column">
              <div className="AdminApplications-card-content-column-description">
                <div className="AdminApplications-card-content-column-description-info">
                  {geographies
                    ?.slice(0, 3)
                    .map((c) => countryMapping(c))
                    .join(", ")
                    .concat(geographies.length > 3 ? "..." : "")}
                </div>
              </div>
            </div>
          )}

          {isAdminView && isAwaitingSeconders && (
            <div className="AdminApplications-card-content-column">
              <div className="AdminApplications-card-content-column-description">
                <div className="AdminApplications-card-content-column-description-title" />
                <div className="AdminApplications-card-content-column-description-info">
                  Volunteers: {extraFields.volunteerSeconderCandidacies?.length || 0} <br />{" "}
                  Seconders: {extraFields.assignedSeconderCandidacies?.length || 0}/
                  {requiredSeconderCount}
                </div>
              </div>
            </div>
          )}

          {isAdminView && isPastAwaitingSeconders && (
            <div className="AdminApplications-card-content-column">
              <div className="AdminApplications-card-content-column-description">
                <div className="AdminApplications-card-content-column-description-title" />
                <div className="AdminApplications-card-content-column-description-info">
                  Seconder 1: {seconderOne} <br /> Seconder 2: {seconderTwo}
                </div>
              </div>
            </div>
          )}

          <div className="AdminApplications-card-content-lastColumn">
            {isAdminView && (
              <>
                <div className={statusClassName}>{readableStatus}</div>
                {canReject && (
                  <AdminApplicationCardModal
                    onboard={migratedOnboard}
                    action={action}
                    open={action === Actions.RejectOnboard}
                    setAction={setAction}
                  >
                    <Button basic color="purple" onClick={onRejectOnboard} content="Reject" />
                  </AdminApplicationCardModal>
                )}
                {canEnableVoting && (
                  <AdminApplicationCardModal
                    onboard={migratedOnboard}
                    action={action}
                    open={action === Actions.EnableVotingOnOnboard}
                    setAction={setAction}
                  >
                    <Button color="purple" onClick={onEnableVoting} content="Enable Voting" />
                  </AdminApplicationCardModal>
                )}
                {canMoveToGA && (
                  <AdminApplicationCardModal
                    onboard={migratedOnboard}
                    open={action === Actions.MoveToUnderReview}
                    action={action}
                    setAction={setAction}
                  >
                    <Button
                      color="purple"
                      onClick={onMoveToUnderReview}
                      content="Move to Under Review"
                    />
                  </AdminApplicationCardModal>
                )}
                {canAccept && (
                  <AdminApplicationCardModal
                    onboard={migratedOnboard}
                    open={action === Actions.ApproveOnboard}
                    action={action}
                    setAction={setAction}
                  >
                    <Button color="purple" onClick={onApproveOnboard} content="Accept as Member" />
                  </AdminApplicationCardModal>
                )}
                {isAwaitingSeconders && (
                  <Button as={Link} to={linksTo} color="purple" content="Manage" />
                )}
                {canViewEvaluation && (
                  <Button as={Link} to={linksTo} color="purple" content="View Evaluation" />
                )}
              </>
            )}
            {!isAdminView && (
              <>
                <div className={statusClassName}>{cbcMemberLastColumn()}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </UnstyledButton>
  );
};

const statusTimeStamp = (onboard: AdminOnboardWithSeconderCandidacies | CBCMemberExpertOnboard) => {
  const { status, state, closedAt, submittedAt, timedoutAt, acceptedAt } = onboard;
  switch (status) {
    case OnboardStatus.Abandoned:
      return `Abandoned on: ${dateFormat(closedAt, "dd/mm/yy")}`;
    case OnboardStatus.Approved:
    case OnboardStatus.Finalised:
      return `Accepted on: ${dateFormat(closedAt, "dd/mm/yy")}`;
    case OnboardStatus.Canceled:
      return `Canceled on: ${dateFormat(closedAt, "dd/mm/yy")}`;
    case OnboardStatus.Declined:
      return `Declined on: ${dateFormat(closedAt, "dd/mm/yy")}`;
    case OnboardStatus.Expired:
      return `Expired on: ${dateFormat(closedAt, "dd/mm/yy")}`;
    case OnboardStatus.Rejected:
      return `Rejected on: ${dateFormat(closedAt, "dd/mm/yy")}`;
    case OnboardStatus.Open:
    case OnboardStatus.UnderDiscussion:
      switch (state) {
        case OnboardState.Pending:
          return null;
        case OnboardState.InCompletion:
          return `Started on: ${dateFormat(acceptedAt, "dd/mm/yy")}`;
        case OnboardState.InPreselection:
          const daysLeft = moment(onboard.preselectionEndsAt).diff(moment(now()), "days");
          return (
            <div className="AdminApplications-card-content-column-description-info-timestamp">
              {getPreselectionClock(daysLeft)}&nbsp; {daysLeft} days left
            </div>
          );
        case OnboardState.TimedOut:
          return `Timed-out of Pre-selection on: ${dateFormat(timedoutAt, "dd/mm/yy")}`;
        case OnboardState.AwaitingEnableVoting:
        case OnboardState.AwaitingSeconders:
        case OnboardState.UnderReview:
        case OnboardState.InMutualAssessment:
        case OnboardState.InCommitteeReview:
        case OnboardState.AwaitingSignature:
        case OnboardState.Closed:
          return `Submited on: ${dateFormat(submittedAt, "dd/mm/yy")}`;
      }
      return assertUnreachable(state);
  }
  assertUnreachable(status);
};

const generateCBCMemberLastColumn = (
  proposal: ProposalWithHasVoted<CBCExpertVoteChoice> | undefined,
  readableStatus: string,
  status: OnboardStatus
) => {
  if (!proposal || !isDefined(proposal.hasVoted)) {
    return <>{readableStatus}</>;
  } else if (!proposal.hasVoted) {
    return (
      <div className="AdminApplications-card-content-lastColumn-CBC-content">
        {readableStatus}
        <Button color="purple" content="Vote Now" />
      </div>
    );
  } else {
    return (
      <div className="AdminApplications-card-content-lastColumn-CBC-content">
        <div>
          <b>VOTED</b>
          <br />
          {readableStatus}
          {status === OnboardStatus.UnderDiscussion ? " - Under discussion" : ""}
        </div>
      </div>
    );
  }
};
