import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useMetamaskEthereumProvider } from "./useMetamaskEthereumProvider";
import { utils } from "../utils/utils";

enum MetamaskErrorCode {
  RejectedRequest = 4001,
  UnknownChain = 4902,
}

export const useSwitchNetwork = () => {
  const { provider } = useMetamaskEthereumProvider();
  const [isLoading, setSetIsLoading] = useState(false);

  const onSwitchNetwork = useCallback(() => {
    if (!provider) {
      return toast.error("Missing ethereum provider");
    }

    const chainId = `0x${parseInt(utils.chainNetId, 10).toString(16)}`;
    const chainName = utils.chainName;
    const rpcUrls = [utils.chainRpcUrl];
    const nativeCurrency = { name: "Ether", symbol: "ETH", decimals: 18 };

    setSetIsLoading(true);
    provider
      .request({ method: "wallet_switchEthereumChain", params: [{ chainId }] })
      .catch((err: unknown) => {
        const code = (err as Record<string, unknown>)?.code;

        if (code === MetamaskErrorCode.RejectedRequest) {
          // User rejected the request.
        } else if (code === MetamaskErrorCode.UnknownChain) {
          // Indicates that the chain has not been added to MetaMask yet.
          return provider.request({
            method: "wallet_addEthereumChain",
            params: [{ chainId, chainName, rpcUrls, nativeCurrency }],
          });
        } else {
          toast.error(`Error switching network: ${JSON.stringify(err).slice(0, 256)}`);
          console.error(err);
        }
        // Only set loading to false on failure, because if we succeed switching the app will refresh the page.
        setSetIsLoading(false);
      })
      .catch((err: unknown) => {
        const code = (err as Record<string, unknown>)?.code;
        // Only set loading to false on failure, because if we succeed switching the app will refresh the page.
        setSetIsLoading(false);
        if (code === MetamaskErrorCode.RejectedRequest) {
          // User rejected the request.
          return;
        }

        toast.error(`Error adding network: ${JSON.stringify(err).slice(0, 256)}`);
        console.error(err);
      });
  }, [provider]);

  return { isLoading, onSwitchNetwork };
};
