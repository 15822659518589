import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { JsonDocumentVersioned } from "../../types/jsonDocument";
import { EntityKind, EntityType, OnboardState } from "../../types/onboard";

export const QUERY = gql`
  query SentOnboard($onboardId: ID!) {
    onboard: node(id: $onboardId) {
      ... on Onboard {
        id
        state
        kind
        type
        insertedAt
        sender {
          id
          fullName
        }
        invitationForm(migrate: false) {
          id
          data
          schemaVersion
        }
      }
    }
  }
`;

export interface Variables {
  readonly onboardId: string;
}

export interface Result {
  readonly onboard?: SentOnboard;
}

export interface SentOnboard extends Identifiable {
  readonly state: OnboardState;
  readonly type: EntityType;
  readonly kind: EntityKind;
  readonly insertedAt: string;
  readonly sender: { readonly fullName: string } & Identifiable;
  readonly invitationForm: JsonDocumentVersioned<unknown>;
}
