import { ErrorObject } from "ajv";
import { Maybe } from "../types";

export interface AjvError {
  readonly details: ErrorObject[];
}

export const appendCustomAjvError = (
  err: Maybe<AjvError>,
  condition: boolean,
  instancePath: string,
  message: string
): Maybe<AjvError> =>
  condition
    ? {
        ...err,
        details: [
          ...(err?.details || []),
          {
            keyword: "format",
            instancePath,
            schemaPath: "",
            params: { format: instancePath.split("/").pop() },
            message,
          },
        ],
      }
    : err;
