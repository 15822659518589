import { OnboardForm } from "../types/OnboardForm";
import { EntityKind } from "../types/onboard";
import { runMigrationDictionary, versionBump, Document } from "./_helpers";
import { Migratable, Migrated, MigrateFunction } from "./_helpers";
import { BusinessCases } from "../schemas/invites/_types";

export class OnboardMigrator<T extends Migratable<OnboardForm>> {
  private instance: T;
  constructor(instance: T) {
    this.instance = instance;
  }

  migrate: MigrateFunction<T> = () => {
    const { form } = this.instance;

    if (!form || !form.schemaVersion || !form.data) {
      return this.instance as Migrated<T>;
    }

    const migratedForm = runMigrationDictionary<OnboardForm>(form, ONBOARD_MIGRATION_DICTIONARY);

    return { ...this.instance, migratedForm } as Migrated<T>;
  };
}

const migrateToVersion4 = (form: Document<OnboardForm>): Document<OnboardForm> => {
  let migratedForm: Document<OnboardForm> = {
    ...form,
    schemaVersion: "4",
  };
  if (form.data.entityKind === EntityKind.Startup) {
    migratedForm = {
      ...migratedForm,
      data: {
        ...form.data,
        startupData: form.data.startupData
          ? { ...form.data.startupData, headquarters: form.data.startupData?.headquarters || [] }
          : undefined,
      },
    };
  }
  return migratedForm;
};

const migrateToVersion10 = (form: Document<OnboardForm>): Document<OnboardForm> => {
  let migratedForm: Document<OnboardForm> = {
    ...form,
    schemaVersion: "10",
  };

  if (form.data.entityKind === EntityKind.Provider) {
    migratedForm = {
      ...migratedForm,
      data: { ...form.data, businessCase: BusinessCases.ProminentSkills },
    };
  }
  return migratedForm;
};

// Note: the latest version should never be included here.
const ONBOARD_MIGRATION_DICTIONARY = {
  "0": versionBump,
  "1": versionBump,
  "2": versionBump,
  "3": migrateToVersion4,
  "4": versionBump,
  "5": versionBump,
  "6": versionBump,
  "7": versionBump,
  "8": versionBump,
  "9": migrateToVersion10,
  "10": versionBump,
};
