import React, { HTMLProps, useMemo } from "react";
import { filterDOMProps, useForm } from "uniforms";
import classnames from "classnames";
import { TicketSchema } from "../_types";
import { hasOwnProperty } from "../../../types";

export interface ErrorsFieldProps extends HTMLProps<HTMLDivElement> {
  readonly keywords: ReadonlyArray<string>;
}

export const CustomErrorFields = ({ className, keywords, ...props }: ErrorsFieldProps) => {
  const { error, schema } = useForm<TicketSchema>();

  const filteredErrors: unknown[] = useMemo(
    () =>
      error?.details?.filter((el: unknown) =>
        hasOwnProperty(el, "keyword") && typeof el.keyword === "string"
          ? keywords.includes(el.keyword)
          : false
      ),
    [error, keywords]
  );

  return !filteredErrors || filteredErrors.length === 0 ? null : (
    <div className={classnames("ui", className, "error message")} {...filterDOMProps(props)}>
      <ul className="list">
        {schema.getErrorMessages({ details: filteredErrors }).map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
};
