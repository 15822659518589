import { useCallback } from "react";
import dateFormat from "dateformat";
import { DeliverableType } from "../../../../api/tickets/GetWorkplaceDeliverables";
import { TicketDeliverableState } from "../../../../types/ticket";
import { useGalleryContext } from "../../../../contexts/GalleryModal";

interface Props {
  readonly deliverable: DeliverableType;
  readonly isTicketOwner: boolean;
  readonly cardIndex: number;
}

const ONE_DAY_MS = 86400000;

export const TicketDeliverableCard = (props: Props) => {
  const { showModal } = useGalleryContext();
  const { deliverable, isTicketOwner, cardIndex } = props;
  const { form, state } = deliverable;

  const handleClick = useCallback(() => showModal("" + cardIndex), [cardIndex, showModal]);

  return (
    <>
      <div className="DeliverableCard" onClick={handleClick}>
        <div className="DeliverableCard-top-section">
          <div className="DeliverableCard-top-section-title">{form.data.title}</div>
          {generateDeliverableState(state, isTicketOwner)}
        </div>
        {generateBottomSection(deliverable)}
      </div>
    </>
  );
};

const generateDeliverableState = (state: TicketDeliverableState, isTicketOwner: boolean) => {
  let label;
  let circleLabelJSX;
  if (state === TicketDeliverableState.Completed) {
    label = "Completed";
    circleLabelJSX = <div className="StatusCircle green" />;
  } else if (state === TicketDeliverableState.In_progress) {
    label = "In Progress";
    circleLabelJSX = <div className="StatusCircle yellow" />;
  } else if (state === TicketDeliverableState.Unstarted) {
    label = "Unstarted";
    circleLabelJSX = <div className="StatusCircle orange" />;
  }

  return (
    <div className="DeliverableCard-top-section-description">
      {circleLabelJSX}
      <div>{label}</div>
      {state !== TicketDeliverableState.Completed && !isTicketOwner && (
        <div className="DeliverableCard-link">Edit status</div>
      )}
    </div>
  );
};

const generateBottomSection = (deliverable: DeliverableType) => {
  const { dueDate, state, completedAt } = deliverable;
  let isOverdue;
  let dueD;
  if (state === TicketDeliverableState.Completed) {
    if (!completedAt) {
      return;
    }
    const completedD = new Date(completedAt);
    dueD = new Date(dueDate);
    isOverdue = completedD.getTime() - dueD.getTime() > ONE_DAY_MS;
    return (
      <div className={`DeliverableCard-bottom-section${isOverdue ? " warning" : ""}`}>
        <span>
          Completed on {dateFormat(completedAt, "dd/mm/yy")}
          {isOverdue ? ` (due to ${dateFormat(dueDate, "dd/mm/yy")})` : ""}
        </span>
      </div>
    );
  }
  const today = new Date();
  dueD = new Date(dueDate);
  isOverdue = today.getTime() - dueD.getTime() > ONE_DAY_MS;

  return (
    <div className={`DeliverableCard-bottom-section${isOverdue ? " warning" : ""}`}>
      <span>Due to {dateFormat(dueDate, "dd/mm/yy")}</span>
      {isOverdue && <span>OVERDUE</span>}
    </div>
  );
};
