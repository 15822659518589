import { gql } from "@apollo/client";
import { ProgressUpdateSealFields, PROGRESS_UPDATE_SEAL_FIELDS } from "./ProgressUpdateSealFields";

export const DELIVERABLE_SEAL_FIELDS = gql`
  fragment DeliverableSealFields on TickettingDeliverable {
    dueDate
    form(migrate: false) {
      data
    }
    percentagePayment
    progressUpdates {
      ...ProgressUpdateSealFields
    }
  }
  ${PROGRESS_UPDATE_SEAL_FIELDS}
`;

export interface DeliverableSealFields {
  readonly dueDate: string;
  readonly form: { readonly data: unknown };
  readonly percentagePayment: string;
  readonly progressUpdates: ReadonlyArray<ProgressUpdateSealFields>;
}
