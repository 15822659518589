import { gql } from "@apollo/client";
import { Connection } from "../../types/relay";
import { Identifiable } from "../../types";
import { EntityKind, EntityType } from "../../types/onboard";
import { Tag } from "../../types/labelQuery";
import { OnboardForm } from "../../types/OnboardForm";
import { JsonDocumentVersioned } from "../../types/jsonDocument";

export const QUERY = gql`
  query UserProfile($userId: ID!) {
    user: node(id: $userId) {
      ... on User {
        id
        insertedAt
        fullName
        avatarUrl
        roles(first: 100) {
          edges {
            node {
              id
              kind
              type
              fullName
              avatarUrl
              profile {
                ... on IndividualProfile {
                  id
                  headline
                  linkedinProfileUrl
                }
                ... on OrganisationProfile {
                  id
                  headline
                  linkedinProfileUrl
                }
              }
              onboard {
                id
                form(migrate: false) {
                  id
                  data
                  schemaVersion
                }
              }
              tags(scope: DEFAULT) {
                id
                assignable
                description
                label
                ns
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly userId: string;
}

interface Role extends Identifiable {
  readonly kind: EntityKind;
  readonly type: EntityType;
  readonly fullName: string;
  readonly avatarUrl: string;
  readonly profile: {
    readonly headline: string;
    readonly linkedinProfileUrl?: string;
  } & Identifiable;
  readonly onboard: {
    readonly form?: JsonDocumentVersioned<OnboardForm>;
  } & Identifiable;
  readonly tags?: ReadonlyArray<Tag>;
}

interface User extends Identifiable {
  readonly insertedAt: string;
  readonly fullName: string;
  readonly avatarUrl: string;
  readonly roles?: Connection<Role>;
}

export interface Result {
  readonly user?: User;
}
