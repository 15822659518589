import { gql } from "@apollo/client";

export const MUTATION = gql`
  mutation RequestOnfidoSDKToken($input: RequestIdentityCheckSdkTokenInput!) {
    payload: requestIdentityCheckSdkToken(input: $input) {
      result
    }
  }
`;

interface RequestIdentityCheckSdkTokenInput {
  readonly onboardId: string;
  readonly applicantAddress?: string;
  readonly applicantDob?: string;
}

interface RequestIdentityCheckSdkTokenPayload {
  readonly result?: string;
}

export interface Variables {
  readonly input: RequestIdentityCheckSdkTokenInput;
}

export interface Result {
  readonly payload: RequestIdentityCheckSdkTokenPayload;
}
