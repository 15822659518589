import { DeepPartial } from "uniforms";
import { Identifiable, assertUnreachable } from "../types/common";
import { Seal } from "./seal";
import { utils } from "../utils/utils";
import { Proposal, ProposalWithHasVoted } from "./proposal";
import { PreselectionProposalChoice } from "../schemas/preselectionVote/_types";
import { JsonDocumentVersioned } from "./jsonDocument";
import { OnboardForm } from "./OnboardForm";
import { SessionUser, GQLSessionUser, convertToSessionUser } from "./frontend_only/sessionUser";
import { Settings } from "../contexts/Settings/state";
import { Connection } from "./relay";
import { AdminOnboardWithSeconderCandidacies } from "../api/admin/AdminOnboards";
import { ExpertEvaluationSchema } from "../schemas/expertEvaluation/_types";
import { CBCExpertVoteChoice } from "../schemas/cbc/_types";

export interface PublicOnboard extends Identifiable {
  readonly kind: EntityKind;
  readonly type: EntityType;
  readonly state: OnboardState;
  readonly status: OnboardStatus;
  readonly invitationReason?: string;
  readonly fullName?: string;
  readonly recipientFullName?: string;

  readonly recipient: Recipient;
  readonly sender: {
    readonly fullName: string;
    readonly avatarUrl: string;
  } & Identifiable;

  readonly insertedAt: string;
  readonly transmittedAt?: string;
  readonly acceptedAt?: string;
  readonly updatedAt: string;
  readonly amendedAt?: string;
  readonly submittedAt?: string;
  readonly preselectionEndsAt?: string;
  readonly timedoutAt?: string;
  readonly approvedAt?: string;
  readonly closedAt?: string;

  readonly logoAttachment?: Connection<AttachmentWithDownloadUrl>;
  readonly pitchDeckAttachment?: Connection<AttachmentWithDownloadUrl>;
  readonly form?: JsonDocumentVersioned<DeepPartial<OnboardForm>>;
  readonly seal?: Seal;
  readonly proposal?: Proposal<PreselectionProposalChoice>;
  readonly extraFields: Record<string, unknown>;
}

export interface SeconderCandidacy extends Identifiable {
  readonly requestedTraining: boolean;
  readonly insertedAt: string;
  readonly onboard: Identifiable;
}

export interface SeconderCandidacies extends Identifiable {
  readonly seconder: {
    readonly fullName: string;
    readonly avatarUrl: string;
  } & Identifiable;
}
export interface MutualAssessment extends Identifiable {
  readonly state: MutualAssessmentsState;
  readonly transmittedAt?: string;
  readonly form?: JsonDocumentVersioned<DeepPartial<ExpertEvaluationSchema>>;
  readonly seconder: {
    readonly fullName: string;
    readonly avatarUrl: string;
  } & Identifiable;
}

export interface PublicOnboardViewedByMember extends Omit<PublicOnboard, "proposal"> {
  readonly proposal?: ProposalWithHasVoted<PreselectionProposalChoice>;
  readonly committeeProposal?: ProposalWithHasVoted<CBCExpertVoteChoice>;

  readonly extraFields: {
    readonly seconderCandidacy?: SeconderCandidacy;
    readonly assignedSeconderCandidacies?: ReadonlyArray<SeconderCandidacies>;
    readonly mutualAssessment?: MutualAssessment;
    readonly mutualAssessments?: ReadonlyArray<MutualAssessment>;
  };
}

export interface SentOnboard extends Identifiable {
  readonly kind: EntityKind;
  readonly type: EntityType;
  readonly state: OnboardState;
  readonly status: OnboardStatus;
  readonly invitationReason?: string;
  readonly fullName?: string;
  readonly recipientFullName?: string;

  readonly recipient: Recipient;
  readonly sender: {
    readonly fullName: string;
    readonly avatarUrl: string;
  } & Identifiable;

  readonly insertedAt: string;
  readonly transmittedAt?: string;
  readonly acceptedAt?: string;
  readonly updatedAt: string;
  readonly amendedAt?: string;
  readonly submittedAt?: string;
  readonly timedoutAt?: string;
  readonly approvedAt?: string;
  readonly closedAt?: string;

  readonly recipientEmail: string;
}

export interface ReceivedOnboard extends PublicOnboard {
  readonly ethAddress?: string;
  readonly recipientEmail: string;
  readonly recipient: Recipient & { readonly mmUserId: string };
  readonly identityChecksStatus?: string;
  readonly onfidoCheckId?: string;
  readonly onfidoApplicantId?: string;
}

export interface AdminOnboard extends Omit<ReceivedOnboard, "sender" | "recipient"> {
  readonly recipient?: SessionUser;
  readonly sender: SessionUser;
}

export interface GQLAdminOnboard extends Omit<ReceivedOnboard, "sender" | "recipient"> {
  readonly recipient?: GQLSessionUser;
  readonly sender: GQLSessionUser;
}

interface AttachmentWithDownloadUrl extends Identifiable {
  readonly downloadUrl?: string;
}

export interface Recipient extends Identifiable {
  readonly avatarUrl: string;
  readonly profile: { readonly biography?: string } & Identifiable;
}

export enum MutualAssessmentsState {
  Completed = "COMPLETED",
  Started = "STARTED",
}

export enum OnboardState {
  Closed = "CLOSED",
  InCompletion = "IN_COMPLETION",
  InMutualAssessment = "IN_MUTUAL_ASSESSMENT",
  InPreselection = "IN_PRESELECTION",
  InCommitteeReview = "IN_COMMITTEE_REVIEW",
  Pending = "PENDING",
  UnderReview = "UNDER_REVIEW",
  AwaitingSeconders = "AWAITING_SECONDERS",
  AwaitingSignature = "AWAITING_SIGNATURE",
  AwaitingEnableVoting = "AWAITING_ENABLE_VOTING",
  TimedOut = "TIMED_OUT",
}

export enum OnboardStatus {
  Abandoned = "ABANDONED",
  Approved = "APPROVED",
  Canceled = "CANCELED",
  Declined = "DECLINED",
  Expired = "EXPIRED",
  Open = "OPEN",
  Rejected = "REJECTED",
  Finalised = "FINALISED",
  UnderDiscussion = "UNDER_DISCUSSION",
}

export enum EntityKind {
  Provider = "PROVIDER",
  Startup = "STARTUP",
}

export enum EntityType {
  Individual = "INDIVIDUAL",
  Organisation = "ORGANISATION",
}

export enum OnboardAs {
  Recipient = "RECIPIENT",
  Sender = "SENDER",
}

type AnyOnboard =
  | ReceivedOnboard
  | SentOnboard
  | AdminOnboard
  | PublicOnboard
  | AdminOnboardWithSeconderCandidacies;

// Helper functions.
export const isStartupOnboard = (onboard: AnyOnboard) => onboard.kind === EntityKind.Startup;
export const isProviderOnboard = (onboard: AnyOnboard) => onboard.kind === EntityKind.Provider;
export const isIndividualOnboard = (onboard: AnyOnboard) => onboard.type === EntityType.Individual;
export const isOrganisationOnboard = (onboard: AnyOnboard) =>
  onboard.type === EntityType.Organisation;

export const isValidOnboardState = (v: unknown): v is OnboardState =>
  Object.values(OnboardState).some((state) => v === state);
export const isValidOnboardStatus = (v: unknown): v is OnboardStatus =>
  Object.values(OnboardStatus).some((status) => v === status);

export const isValidEntityKind = (v: unknown): v is EntityKind =>
  Object.values(EntityKind).some((kind) => v === kind);
export const isValidEntityType = (v: unknown): v is EntityType =>
  Object.values(EntityType).some((type) => v === type);

export const readableKind = (kind: EntityKind) => {
  switch (kind) {
    case EntityKind.Provider:
      return "Expert";
    case EntityKind.Startup:
      return "Startup";
  }
  assertUnreachable(kind);
};

export const readableType = (type: EntityType) => {
  switch (type) {
    case EntityType.Organisation:
      return "Organisation";
    case EntityType.Individual:
      return "Individual";
  }
  assertUnreachable(type);
};

export const readableTypeAndKind = (type: EntityType, kind: EntityKind) => {
  if (kind === EntityKind.Startup) {
    return readableKind(kind);
  } else if (type === EntityType.Individual) {
    return readableKind(kind);
  } else if (type === EntityType.Organisation) {
    return `${readableKind(kind)} ${readableType(type)}`;
  }

  return "Unknown";
};

export const readableOnboardType = ({ kind, type }: AnyOnboard) => readableTypeAndKind(type, kind);

export const readableOnboardRecipient = (o: ReceivedOnboard, fallback?: string) => {
  if (o.fullName) {
    return o.fullName;
  } else if (o.recipientFullName) {
    return o.recipientFullName;
  } else if (isStartupOnboard(o) && o.form?.data.startupData?.name) {
    return o.form.data.startupData.name;
  } else if (isIndividualOnboard(o) && o.form?.data.providerData?.individualData) {
    const { firstName, lastName } = o.form.data.providerData.individualData || {};
    return `${firstName}${lastName ? " " + lastName : ""}`;
  } else if (isOrganisationOnboard(o) && o.form?.data.providerData?.organisationData?.name) {
    return o.form.data.providerData.organisationData.name;
  } else if (fallback) {
    return fallback;
  } else {
    return "Unknown";
  }
};

interface StatusAndColor {
  readonly status: string;
  readonly color: string;
}

export const readableOnboardStatusAndColor = (onboard: AnyOnboard): StatusAndColor => {
  const { status, state } = onboard;
  switch (status) {
    case OnboardStatus.Abandoned:
      return { status: "Abandoned", color: "dark-red" };
    case OnboardStatus.Approved:
      return { status: "Member", color: "purple" };
    case OnboardStatus.Finalised:
      return { status: "Member", color: "purple" };
    case OnboardStatus.Canceled:
      return { status: "Canceled", color: "dark-red" };
    case OnboardStatus.Declined:
      return { status: "Declined", color: "dark-red" };
    case OnboardStatus.Expired:
      return { status: "Expired", color: "dark-red" };
    case OnboardStatus.Rejected:
      return { status: "Rejected", color: "dark-red" };
    case OnboardStatus.Open:
    case OnboardStatus.UnderDiscussion:
      switch (state) {
        case OnboardState.InCompletion:
          return { status: "In completion", color: "yellow" };
        case OnboardState.InCommitteeReview:
          return { status: "CBC Voting", color: "blue" };
        case OnboardState.AwaitingEnableVoting:
          return { status: "Being reviewed", color: "black" };
        case OnboardState.AwaitingSeconders:
          return { status: "Awaiting seconders", color: "yellow" };
        case OnboardState.InPreselection:
          return { status: "In pre-selection", color: "yellow" };
        case OnboardState.TimedOut:
          return { status: "Pending decision", color: "dark-red" };
        case OnboardState.InMutualAssessment:
          return { status: "In Mutual Assesment", color: "teal" };
        case OnboardState.UnderReview:
          return { status: "In general assessment", color: "teal" };
        case OnboardState.Pending:
          return { status: "Unstarted", color: "yellow" };
      }
      return { status: state, color: "black" };
  }
  assertUnreachable(status);
};
// TODO: Unused, Remove ?
export const readableOnboardDate = (onboard: AnyOnboard, settings: Settings) => {
  const { state, status, insertedAt } = onboard;

  const dates = [`Sent ${utils.around(insertedAt)}`];
  switch (status) {
    case OnboardStatus.Abandoned: {
      dates.push(`Abandoned ${utils.around(onboard.closedAt)}`);
      break;
    }
    case OnboardStatus.Approved: {
      dates.push(`Approved ${utils.around(onboard.approvedAt)}`);
      break;
    }
    case OnboardStatus.Finalised: {
      dates.push(`Finalised ${utils.around(onboard.closedAt)}`);
      break;
    }
    case OnboardStatus.Canceled: {
      dates.push(`Canceled ${utils.around(onboard.closedAt)}`);
      break;
    }
    case OnboardStatus.Declined: {
      dates.push(`Declined ${utils.around(onboard.closedAt)}`);
      break;
    }
    case OnboardStatus.Expired: {
      dates.push(`Expired ${utils.around(onboard.timedoutAt)}`);
      break;
    }
    case OnboardStatus.Open: {
      const s = settings.core;
      let expiresAt;
      let message;

      if (state === OnboardState.Pending) {
        expiresAt = new Date(new Date(onboard.insertedAt).valueOf() + s.invitation_ttl * 1000);
        message = `Expires ${utils.around(expiresAt)}`;
      } else if (state === OnboardState.InCompletion) {
        const acceptedAt = onboard.acceptedAt || "";
        expiresAt = new Date(new Date(acceptedAt).valueOf() + s.application_ttl * 1000);
        message = `Expires ${utils.around(expiresAt)}`;
      } else if (state === OnboardState.AwaitingEnableVoting) {
        message = "In Pre Selection";
      } else if (state === OnboardState.InPreselection) {
        const submittedAt = onboard.submittedAt || "";
        expiresAt = new Date(new Date(submittedAt).valueOf() + s.startup_preselection_ttl * 1000);
        message = `Pre Selection ends ${utils.around(expiresAt)}`;
      } else if (state === OnboardState.TimedOut) {
        message = "Timed Out";
      } else if (state === OnboardState.UnderReview) {
        message = "In General Assessment";
      }

      if (message) {
        dates.push(message);
      }
      break;
    }
    case OnboardStatus.Rejected: {
      dates.push(`Rejected ${utils.around(onboard.closedAt)}`);
      break;
    }
  }

  return dates.join(" | ");
};

export const convertToAdminOnboard = (o: GQLAdminOnboard): AdminOnboard => {
  const { recipient, sender, ...rest } = o;
  return {
    ...rest,
    sender: convertToSessionUser(sender),
    recipient: recipient ? convertToSessionUser(recipient) : undefined,
  };
};
