import { CSSProperties, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Button, Container, Modal } from "semantic-ui-react";
import { Shortcuts as S } from "../../../../../routing";
import { TicketState } from "../../../../../types/ticket";
import { useHistory } from "react-router-dom";
import { Enrolled } from "../../../../../contexts/Session/state";
import { Modals } from "./modals";
import { useMutation } from "@apollo/client";
import { MUTATION, Result, Variables } from "../../../../../api/tickets/RevertTicketBidToDraft";
import { ApiError } from "../../../../../types";
import { useChainApi, useChainState } from "../../../../../contexts/Chain";
import { TickettingBidWithAttachments } from "../../../../../api/tickets/TickettingBid";
import { useSealFieldsQuery } from "../../../../../hooks/useSealFieldsQuery";
import { QUERY, SealQueryResult, bidEnvelope } from "../../../../../api/seals/BidSeal";
import { digestFormData } from "../../../../../types/OnboardForm";

const bttnStyle: CSSProperties = { marginBottom: "20px" };
interface Props {
  readonly isOwner: boolean;
  readonly isTicketOwner: boolean;
  readonly ticketId: string;
  readonly bid: TickettingBidWithAttachments;
  readonly ticketState: TicketState;
  readonly sessionState: Enrolled;
}

export const ActionButtons = (props: Props) => {
  const { isOwner, isTicketOwner, ticketState, sessionState, bid } = props;
  // Hooks.
  const chainApi = useChainApi();
  const chainState = useChainState();
  const history = useHistory();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();

  // State.
  const bidId =
    new URLSearchParams(history.location.search).get(S.submitBid.queryVarNames.id) || "";
  const [open, setOpen] = useState(false);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);

  // Mutation.
  const [revertToDraft, { loading }] = useMutation<Result, Variables>(MUTATION);
  const isLoading = loading || sealFieldsLoading;

  const handleBack = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);

  const onUnpublish = useCallback(async () => {
    setOpen(false);
    const transmittedAt = new Date();
    const { chainId } = sessionState;

    setSealFieldsLoading(true);
    const sealData = await sealFieldsQuery(QUERY, { id: bid.id });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const { fields } = sealData;
    const { signature } = bid.seal || {};
    const envelope = bidEnvelope(chainId, fields, transmittedAt, "revert_to_draft", signature);
    const plainText = digestFormData(envelope, true);

    chainApi
      .sign(chainState, plainText)
      .then((ethSignature) =>
        revertToDraft({ variables: { input: { bidId, ethSignature, transmittedAt } } })
      )
      .then(() => {
        history.push({ pathname: S.myWork.path });
        toast.success("You have succesfuly unpublished the Bid.");
      })
      .catch((e: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (e.message.includes("User denied message signature")) {
          setOpen(true);
          return;
        }
        toast.error("Something went wrong.");
        console.warn(e);
      });
  }, [history, sealFieldsQuery, revertToDraft, bid, bidId, chainApi, chainState, sessionState]);

  return (
    <>
      {isOwner && ticketState === TicketState.In_bidding && (
        <Modal
          size="mini"
          open={open}
          onClose={handleBack}
          onOpen={handleOpen}
          className="CustomClose"
          closeOnDimmerClick={false}
          closeIcon
          trigger={
            <Button
              className="ActionBttn-widder"
              style={bttnStyle}
              primary
              floated="right"
              loading={isLoading}
              content={<b>Unpublish</b>}
            />
          }
        >
          <Modal.Header>Before you continue...</Modal.Header>
          <Modal.Content>
            Are you sure you want to unpublish this Bid?
            <br />
            <br />
            <Container>
              <Button onClick={handleBack}>Back</Button>
              <Button primary onClick={onUnpublish} loading={isLoading} floated="right">
                Yes
              </Button>
            </Container>
          </Modal.Content>
        </Modal>
      )}
      {isTicketOwner && <Modals sessionState={sessionState} ticketState={ticketState} bid={bid} />}
    </>
  );
};
