import { useMemo } from "react";
import classnames from "classnames";
import { connectField, filterDOMProps, HTMLFieldProps } from "uniforms";

interface Props extends HTMLFieldProps<boolean, HTMLDivElement> {
  readonly [key: string]: unknown;
  readonly inline?: boolean;
  readonly horizontal?: boolean;
  readonly showUndefinedOption?: boolean;
  readonly transform?: (value?: boolean) => string;
}

enum Options {
  Undefined = "UNDEFINED",
  True = "TRUE",
  False = "FALSE",
}

export const CustomBoolRadioField = connectField(
  ({
    className,
    disabled,
    error,
    errorMessage,
    id,
    label,
    name,
    readOnly,
    required,
    showInlineError,
    value,
    showUndefinedOption,
    inline,
    horizontal,
    onChange,
    transform,
    ...props
  }: Props) => {
    const allowedValues = useMemo(() => {
      const allOptions = Object.values<string>(Options);
      return showUndefinedOption ? allOptions : allOptions.filter((f) => f !== Options.Undefined);
    }, [showUndefinedOption]);

    const formattedValue =
      value === true ? Options.True : value === false ? Options.False : Options.Undefined;

    const customTransform = useMemo(() => {
      if (!transform) {
        return undefined;
      }

      return (v: string) => {
        if (v === Options.True) {
          return transform(true);
        } else if (v === Options.False) {
          return transform(false);
        }
        return transform(undefined);
      };
    }, [transform]);

    const fields = useMemo(
      () =>
        allowedValues.map((item) => {
          // Custom on change from strings to boolean.
          const customOnChange = () => {
            if (readOnly) {
              return;
            } else if (item === Options.True) {
              return onChange(true);
            } else if (item === Options.False) {
              return onChange(false);
            }
            onChange(undefined);
          };

          return (
            <div className="field" key={item}>
              <div className="ui radio checkbox">
                <input
                  checked={item === formattedValue}
                  disabled={disabled}
                  id={`${id}-${item}`}
                  name={name}
                  onChange={customOnChange}
                  type="radio"
                />
                <label htmlFor={`${id}-${item}`}>
                  {customTransform ? customTransform(item) : item}
                </label>
              </div>
            </div>
          );
        }),
      [allowedValues, disabled, formattedValue, id, name, readOnly, onChange, customTransform]
    );

    const cName = classnames(className, { disabled, error, inline, grouped: !inline }, "fields");

    return (
      <div className={cName} {...filterDOMProps(props)}>
        {label && (
          <div className={classnames({ required }, "field")}>
            <label>{label}</label>
          </div>
        )}
        {horizontal && <div className="HorizontalFields">{fields}</div>}
        {!horizontal && <>{fields}</>}
        {!!(error && showInlineError) && (
          <div className="ui red basic pointing label">{errorMessage}</div>
        )}
      </div>
    );
  }
);

export const readableCustomBoolRadioField = (v: boolean): string => {
  if (v) {
    return "Yes";
  } else {
    return "No";
  }
};
