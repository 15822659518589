import { useCallback, useMemo } from "react";
import { Dropdown, DropdownProps, Image } from "semantic-ui-react";
import { connectField, HTMLFieldProps } from "uniforms";
import blueTriangleUp from "../assets/icon-blue-triangle-up.svg";
import blueTriangleDown from "../assets/icon-blue-triangle-down.svg";
import grayTriangleUp from "../assets/icon-gray-triangle-up.svg";
import grayTriangleDown from "../assets/icon-gray-triangle-down.svg";
import { SuggestLabelsModal } from "../components/elements/SuggestLabelsModal";
import { TagNamespace } from "../types/labelQuery";

export interface Option {
  readonly key: string;
  readonly value: string;
  readonly text: string;
}

interface Props extends HTMLFieldProps<string, HTMLDivElement> {
  readonly [k: string]: unknown;
  readonly options: Option[];
  readonly tagNamespace: TagNamespace;
  readonly maxItems: number;
  readonly parentError: unknown;
  readonly moveItemUp: (originIndex: number) => void;
  readonly moveItemDown: (originIndex: number) => void;
}

export const CustomPriorityItemField = connectField((props: Props) => {
  const { value, options, onChange, error, parentError, showInlineError, errorMessage } = props;
  const { name, maxItems, moveItemUp, moveItemDown, tagNamespace } = props;
  const index: number = parseInt(name.split(".")[1], 10);

  const onItemChange = useCallback(
    (_ev: unknown, data: DropdownProps) => onChange(data.value?.toString() || ""),
    [onChange]
  );

  const onTopArrowClick = useCallback(() => moveItemUp(index), [index, moveItemUp]);
  const onBottomArrowClick = useCallback(() => moveItemDown(index), [index, moveItemDown]);

  const topArrow = useMemo(() => {
    if (index === 0 || !value) {
      return <Image src={grayTriangleUp} />;
    }
    return <Image src={blueTriangleUp} onClick={onTopArrowClick} />;
  }, [index, value, onTopArrowClick]);

  const bottomArrow = useMemo(() => {
    if (index === maxItems - 1 || !value) {
      return <Image src={grayTriangleDown} />;
    }
    return <Image src={blueTriangleDown} onClick={onBottomArrowClick} />;
  }, [index, value, maxItems, onBottomArrowClick]);

  const optionsWithSuggestion = useMemo(() => {
    return [
      ...options,
      { key: "suggest-tag", text: <SuggestLabelsModal namespace={tagNamespace} /> },
    ];
  }, [options, tagNamespace]);

  const noResultsMessage = useMemo(
    () => (
      <div>
        No results found...
        <br />
        <br />
        <SuggestLabelsModal namespace={tagNamespace} />
      </div>
    ),
    [tagNamespace]
  );

  return (
    <div className="CustomPriorityItemField">
      <div className="CustomPriorityItemField-Wrapper">
        <div className="CustomPriorityItemField-Wrapper-indexLabel">{index + 1}. </div>
        <div className="CustomPriorityItemField-Wrapper-arrowGroup">
          {topArrow}
          {bottomArrow}
        </div>
        <Dropdown
          fluid
          search
          selection
          value={value || ""}
          options={optionsWithSuggestion}
          onChange={onItemChange}
          placeholder="Search skill..."
          error={!!error || !!parentError}
          noResultsMessage={noResultsMessage}
        />
      </div>
      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage}</div>
      )}
    </div>
  );
});
