import { useMemo, useState, CSSProperties, SyntheticEvent } from "react";
import { ethers } from "ethersv5";
import { Table, Button, Dimmer, Segment } from "semantic-ui-react";
import { Popup, FormField, Dropdown, DropdownProps } from "semantic-ui-react";
import dateformat from "dateformat";
import { Enrolled } from "../../../../contexts/Session/state";
import { Linked } from "../../../../contexts/Chain/state";
import { ReverseAddress } from "../../../elements/ReverseAddress";
import { decimateTokenValue } from "../../../../contexts/Chain/helpers";
import { Order, OrderStatus } from "../../../../types/chain/order";
import { useCancelablePromises } from "../../../../hooks/useCancelablePromise";
import { ErrorMessages } from "../../../elements/ErrorMessages";
import { PaginationLinks } from "../../../elements/PaginationLinks";
import { PageInfo } from "../../../../types/relay";
import { requireIsActor } from "../../../../utils/Chain";

const RESULTS_PER_PAGE = 8;

enum SortOptions {
  NewFirst,
  OlderFirst,
}

const SORT_OPTIONS = [
  { key: 1, text: "Newest first", value: SortOptions.NewFirst },
  { key: 2, text: "Oldest first", value: SortOptions.OlderFirst },
];
const GENESIS_TIMESTAMP = Number(process.env.REACT_APP_GENESIS_TIMESTAMP || 0);
const BLOCK_FREQUENCY = Number(process.env.REACT_APP_BLOCK_FREQUENCY || 0);
const behalfStyle: CSSProperties = { color: "#4A4A4A" };
const sentFromStyle: CSSProperties = { display: "inline" };
const allowanceStyle: CSSProperties = { display: "flex", alignItems: "center" };
const bttnStyle: CSSProperties = { textAlign: "right", width: "100%" };

interface Loader<T> {
  readonly index: number;
  readonly resolution?: T;
}

// ´currentIndex´ helps doing pagination, it corresponds to the chain index
// (zero based) of the first order in the current table. The ordering is from
// the most recent order to the oldest.
interface State {
  readonly orders: ReadonlyArray<Loader<Order>>;
  readonly currentIndex: number;
  readonly sort: SortOptions;
  readonly orderCount?: number;
  readonly error?: string;
}
const initialState: State = { orders: [], currentIndex: 0, sort: SortOptions.NewFirst };

const BlankOrder: Order = {
  id: "",
  owner: ethers.constants.AddressZero,
  spender: ethers.constants.AddressZero,
  recipient: ethers.constants.AddressZero,
  reference: "",
  amount: BigInt(0),
  createdAt: BigInt(0),
  status: OrderStatus.Pending,
};

interface Props {
  readonly from: string;
  readonly sessionState: Enrolled;
  readonly chainState: Linked;
}

export const Transfers = ({ from, sessionState, chainState }: Props) => {
  const { makeCancelable } = useCancelablePromises([from]);
  const [{ orders, currentIndex, orderCount, sort, error }, setState] = useState(initialState);
  const { isGovernor: meIsGovernor } = sessionState.roleChainData;
  // Memoized values.
  const {
    contracts: { transact: transactContract, access: accessContract },
  } = useMemo(() => chainState, [chainState]);

  // Order count for `from` address.
  useMemo(() => {
    requireIsActor(accessContract, from)
      .then(() => makeCancelable(transactContract.orderCount(from)))
      .then((res) => {
        const count = Number(res);
        // Pagination starts on the most recent order (zero base index thus the minus 1).
        setState((s) => ({ ...s, orderCount: count, currentIndex: count - 1 }));
      })
      .catch((err) => {
        setState((s) => ({ ...s, error: "Unable to fetch the order count for this wallet." }));
        console.warn(err);
      });
  }, [from, accessContract, transactContract, makeCancelable]);

  // Process orders with pagination.
  useMemo(() => {
    if (orderCount === undefined) {
      return;
    }

    // The max number of orders that will be displayed on this page. Covers the scenario
    // where the last page as fewer results to display then `RESULTS_PER_PAGE`.
    const pageMaxOrders =
      sort === SortOptions.OlderFirst
        ? Math.min(orderCount - currentIndex, RESULTS_PER_PAGE)
        : Math.min(currentIndex + 1, RESULTS_PER_PAGE);
    const blankOrders = Array.from(Array(pageMaxOrders).keys()).map((index) => ({ index }));

    setState((s) => ({ ...s, orders: blankOrders }));

    const processOrder = (index: number) => {
      // Since `currentIndex` is in descending order and `index` range is [0, pageMaxOrders],
      // to convert them to a chain index we need to subtract `index` from `currentIndex`.
      const chainIndex =
        sort === SortOptions.OlderFirst ? currentIndex + index : currentIndex - index;

      makeCancelable(transactContract.orderByOwnerAndIndex(from, chainIndex)).then((o) => {
        if (!o) {
          return;
        }

        setState((s) => {
          const amount = o.amount.toBigInt();
          const createdAt =
            (o.createdAt.toBigInt() * BigInt(BLOCK_FREQUENCY) + BigInt(GENESIS_TIMESTAMP)) *
            BigInt(1000);
          const resolution: Order = { ...o, amount, createdAt, reference: o.ref };
          const updatedOrders = [
            ...s.orders.slice(0, index),
            { index, resolution },
            ...s.orders.slice(index + 1),
          ];
          return { ...s, orders: updatedOrders };
        });
      });
    };

    for (let i = 0; i < blankOrders.length; ++i) {
      processOrder(i);
    }
  }, [from, makeCancelable, transactContract, orderCount, currentIndex, sort]);

  const statusBuilder = useMemo(
    () => (order: Order | undefined) => {
      if (!order) {
        return null;
      }

      const { id, status } = order;
      if (status !== OrderStatus.Pending || !meIsGovernor) {
        return statusToLabel(status);
      }
      const approveOrder = () => transactContract.approve(id);
      const rejectOrder = () => transactContract.reject(id);

      return (
        <Button.Group size="mini">
          {statusToLabel(status)}
          <Popup trigger={<Button icon="check" onClick={approveOrder} />} content="Accept" />
          <Popup trigger={<Button icon="remove" onClick={rejectOrder} />} content="Reject" />
        </Button.Group>
      );
    },
    [meIsGovernor, transactContract]
  );

  return useMemo(() => {
    const rows = orders.map(({ resolution, index }) => {
      const order = resolution || BlankOrder;
      const loading = !resolution;
      const amount = loading ? "N/A" : decimateTokenValue(order.amount, 6, "");
      const createdAt = loading
        ? "N/A"
        : `${dateformat(new Date(Number(order.createdAt.toString())), "dd / mm / yyyy")}`;
      const status = loading ? "Loading" : statusBuilder(order);
      const isAllowance = order.owner !== order.spender;
      const incoming = order.recipient === from;
      let amountStyle = {};
      if (order.status === OrderStatus.Pending) {
        amountStyle = { ...amountStyle, color: "grey" };
      } else if (order.status === OrderStatus.Rejected) {
        amountStyle = { ...amountStyle, textDecoration: "line-through" };
      }

      return (
        <Dimmer.Dimmable
          as={Table.Row}
          active={loading}
          blurring
          key={order.id.length ? order.id : index}
        >
          <Table.Cell>
            <div style={sentFromStyle}>
              {isAllowance ? (
                <>
                  <ReverseAddress ethAddress={order.spender} />
                  <div style={allowanceStyle}>
                    <div style={behalfStyle}>on behalf of&nbsp;&nbsp;</div>
                    <ReverseAddress ethAddress={order.owner} />
                  </div>
                </>
              ) : (
                <ReverseAddress ethAddress={order.owner} />
              )}
            </div>
          </Table.Cell>
          <Table.Cell>
            <ReverseAddress ethAddress={order.recipient} />
          </Table.Cell>
          <Table.Cell>{order.reference}</Table.Cell>
          <Table.Cell style={amountStyle}>
            {incoming ? "+ " : "- "}
            {amount}
          </Table.Cell>
          <Table.Cell>{createdAt}</Table.Cell>
          <Table.Cell>{status}</Table.Cell>
        </Dimmer.Dimmable>
      );
    });

    const pageInfo: PageInfo = {
      hasPreviousPage:
        sort === SortOptions.OlderFirst
          ? currentIndex > 0
          : orderCount !== undefined && currentIndex < orderCount - 1,
      hasNextPage:
        sort === SortOptions.OlderFirst
          ? orderCount !== undefined && currentIndex + RESULTS_PER_PAGE <= orderCount - 1
          : currentIndex - RESULTS_PER_PAGE >= 0,
    };

    const onSortChange = (_: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      if (typeof value === "number" && orderCount) {
        if (value === SortOptions.OlderFirst) {
          setState((s) => ({ ...s, currentIndex: 0, sort: value }));
        } else if (value === SortOptions.NewFirst) {
          setState((s) => ({ ...s, currentIndex: orderCount - 1, sort: value }));
        }
      }
    };

    const nextOnClick = () =>
      sort === SortOptions.OlderFirst
        ? setState((s) => ({ ...s, currentIndex: s.currentIndex + RESULTS_PER_PAGE }))
        : setState((s) => ({ ...s, currentIndex: s.currentIndex - RESULTS_PER_PAGE }));

    const previousOnClick = () =>
      sort === SortOptions.OlderFirst
        ? setState((s) => ({ ...s, currentIndex: s.currentIndex - RESULTS_PER_PAGE }))
        : setState((s) => ({ ...s, currentIndex: s.currentIndex + RESULTS_PER_PAGE }));

    const lastOnClick = () => {
      if (orderCount === undefined) {
        return;
      }

      const index =
        orderCount % RESULTS_PER_PAGE === 0
          ? RESULTS_PER_PAGE - 1
          : (orderCount % RESULTS_PER_PAGE) - 1;
      sort === SortOptions.OlderFirst
        ? setState((s) => ({ ...s, currentIndex: orderCount - index - 1 }))
        : setState((s) => ({ ...s, currentIndex: index }));
    };

    const firstOnClick = () => {
      if (orderCount === undefined) {
        return;
      }
      sort === SortOptions.OlderFirst
        ? setState((s) => ({ ...s, currentIndex: 0 }))
        : setState((s) => ({ ...s, currentIndex: orderCount - 1 }));
    };

    return (
      <Segment>
        <h2>Transaction History</h2>
        <div className="sort-div">
          <FormField label={"Sort by: "} className="sort-label" />
          <Dropdown
            options={SORT_OPTIONS}
            selection
            defaultValue={sort || 0}
            className="sort-dropdown"
            onChange={onSortChange}
          />
        </div>
        {error && <ErrorMessages errors={[error]} />}
        {!error && (
          <>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Sent from</Table.HeaderCell>
                  <Table.HeaderCell>Recipient</Table.HeaderCell>
                  <Table.HeaderCell>Reference</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Date and Time</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {orders.length > 0 ? (
                  rows
                ) : (
                  <Table.Row>
                    <Table.Cell textAlign="center" colSpan="6">
                      You have no transactions yet.
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <PaginationLinks
              firstOnClick={firstOnClick}
              nextOnClick={nextOnClick}
              previousOnClick={previousOnClick}
              lastOnClick={lastOnClick}
              pageInfo={pageInfo}
              style={bttnStyle}
            />
          </>
        )}
      </Segment>
    );
  }, [orders, statusBuilder, currentIndex, orderCount, from, error, sort]);
};

const statusToLabel = (status: number) => {
  switch (status) {
    case OrderStatus.Pending:
      return "PENDING";
    case OrderStatus.Approved:
      return "APPROVED";
    case OrderStatus.Rejected:
      return "REJECTED";
    default:
      throw new Error(`Unknown order status ${status}`);
  }
};
