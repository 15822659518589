import GraphemeSplitter from "grapheme-splitter";

const splitter = new GraphemeSplitter();

export const MINIMUM_ENTROPY = 3.5;

export const shannonEntropy = (word: string): number =>
  calculateEntropy(
    word.length,
    calcGrapheneGroupsLenght(groupGraphenes(splitter.splitGraphemes(word)))
  );

export const groupGraphenes = (
  graphenes: ReadonlyArray<string>
): Map<string, ReadonlyArray<string>> => {
  const grapheneGroups = new Map<string, string[]>();

  graphenes.forEach(v => {
    const collection = grapheneGroups.get(v);
    grapheneGroups.set(v, collection ? [...collection, v] : [v]);
  });

  return new Map(Array.from(grapheneGroups.entries()).sort());
};

export const calcGrapheneGroupsLenght = (
  grapheneGroups: Map<string, ReadonlyArray<string>>
): ReadonlyArray<number> => Array.from(grapheneGroups.values()).map(group => group.length);

export const calculateEntropy = (wordLenght: number, groupLenghts: ReadonlyArray<number>): number =>
  groupLenghts.reduce((entropy, currGroupLenght) => {
    const freq = currGroupLenght / wordLenght;

    return entropy - freq * Math.log2(freq);
  }, 0);
