import { gql } from "@apollo/client";
import { MutualAssessment } from "../../types/onboard";
import { PublicOnboardViewedByMember } from "../../types/onboard";
import { SeconderCandidacy } from "../../types/seconderCandidacy";
import { CBC_MEMBER_SECONDER_CANDIDACY_FRAGMENT } from "../_fragments/CBCMemberSeconderCandidacy";
import { PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT } from "../_fragments/PublicOnboardViewedByMember";

export const QUERY = gql`
  query CBCMemberExpertOnboard($onboardId: ID!, $roleId: ID!) {
    onboard: node(id: $onboardId) {
      ... on Onboard {
        extraFields {
          ... on Expert {
            assignedSeconderCandidacies: seconderCandidacies(state: ASSIGNED) {
              ...CBCMemberSeconderCandidacy
            }
            mutualAssessments {
              id
              state
              transmittedAt
              form {
                id
                data
              }
              seconder {
                id
                fullName
                avatarUrl
              }
            }
          }
        }
        ...PublicOnboardViewedByMember
      }
    }
  }
  ${CBC_MEMBER_SECONDER_CANDIDACY_FRAGMENT}
  ${PUBLIC_ONBOARD_VIEWED_BY_MEMBER_FRAGMENT}
`;

export interface Variables {
  readonly onboardId: string;
  readonly roleId: string;
}

export interface Result {
  readonly onboard?: CBCMemberExpertOnboard;
}

export interface CBCMemberExpertOnboard extends Omit<PublicOnboardViewedByMember, "extraFields"> {
  readonly extraFields: {
    readonly seconderCandidacy: undefined;
    readonly volunteerSeconderCandidacies: undefined;
    readonly assignedSeconderCandidacies?: ReadonlyArray<SeconderCandidacy>;
    readonly mutualAssessments?: ReadonlyArray<MutualAssessment>;
  };
}
