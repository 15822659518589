import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { globalDefinitions } from "../_definitions";
import { ReviewCompletionSchema } from "./_types";

export const schema: JSONSchemaType<ReviewCompletionSchema> = {
  type: "object",
  properties: {
    deliverySuccess: {
      type: "boolean",
    },
    additionalInformation: {
      type: "string",
    },
    additionalDocuments: {
      type: "array",
      items: globalDefinitions.document,
      maxItems: 3,
    },
  },
  required: ["deliverySuccess"],
  if: {
    properties: {
      deliverySuccess: {
        type: "boolean",
        enum: [false],
      },
    },
  },
  then: {
    properties: {
      additionalInformation: {
        type: "string",
        maxLength: 1000,
        pattern: "(.|s)*\\S(.|s)*",
      },
    },
    required: ["additionalInformation"],
  },
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
