import { useReducer, useMemo, useEffect, PropsWithChildren } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { reducer, createInitialState } from "./state";
import { isPresent, isEnrolled } from "./state";
import { Context } from "./Context";
import { Actioner } from "./actions";
import { useChainState } from "../Chain";
import { isLinked, Linked } from "../Chain/state";
import { EnrollmentErrorType } from "../../types";
import { Role } from "../../types/role";
import { QUERY, Result } from "../../api/accounts/Me";
import { convertToSessionUser } from "../../types/frontend_only/sessionUser";
import { cleanLeftMenuState } from "./helpers";

const findRoleByChainAddress = (chainState: Linked, roles: ReadonlyArray<Role>): Role | undefined =>
  roles.find((r) => r.ethAddress === chainState.account);

export const SessionProvider = ({ children }: PropsWithChildren<{}>) => {
  const client = useApolloClient();
  const chainState = useChainState();
  const [state, dispatch] = useReducer(reducer, createInitialState());
  const [meQuery, { data, loading, error }] = useLazyQuery<Result>(QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const api = useMemo(() => new Actioner(dispatch, chainState), [dispatch, chainState]);

  // Runs once to check the localstorage value of leftmenu, sets it if its wrong or undefined
  useEffect(() => cleanLeftMenuState());

  // Re-runs the me query when the state needs hydration.
  useEffect(() => {
    if (state.needsHydration && !loading && (!error || error.message === "unauthorized")) {
      meQuery();
    }
  }, [state, loading, error, meQuery]);

  // Updates the state when results (data or errors) are returned.
  useEffect(() => {
    if (loading) {
      return;
    } else if (
      error &&
      (error.message === "GraphQL error: Denied" || error.message === "unauthorized")
    ) {
      api.clear();
    } else if (data) {
      const user = convertToSessionUser(data.user);
      api.updateUser(user);
    }
  }, [data, loading, error, api]);

  // This one handles changes to state or chain state when state is exactly Present.
  useEffect(() => {
    if (loading) {
      return;
    }

    // Chain session isn't linked to any address, bail.
    if (!isLinked(chainState)) {
      if (isEnrolled(state)) {
        api.clearEnrollment();
      }
      return;
    } else if (state.chainLastCheckedAt === chainState.lastChangedAt) {
      return;
    }

    // Handles Present -> Enrolled.
    if (isPresent(state)) {
      const matchingRole = findRoleByChainAddress(chainState, state.user.roles);
      // No match between Metamask and local roles.
      if (!matchingRole) {
        console.info("No matching role found.");
        return api.clearEnrollment({ type: EnrollmentErrorType.IdentityMismatch });
      }
      // A match was found and it's a different role ID than the one we're enrolling already.
      if (state.enrolling === matchingRole.id) {
        return;
      }
      // Everything is fine - simply start the enrolment.
      api.enrol(matchingRole.id);
    }

    // Handles Enrolled -> Present.
    else if (isEnrolled(state)) {
      const matchingRole = findRoleByChainAddress(chainState, state.user.roles);
      // No match between Metamask and local roles.
      if (!matchingRole || state.roleId !== matchingRole.id) {
        console.info("Switching state back to Present.");
        api.clearEnrollment();
      }
    }
  }, [chainState, api, state, loading]);

  const providerValue = useMemo(() => ({ state, api }), [state, api]);

  if (state.loggingOut) {
    client.clearStore();
    api.setCleanupDone();
  }

  return <Context.Provider value={providerValue} children={children} />;
};
