import { DeepPartial } from "uniforms";
import { JsonDocumentVersioned } from "../types/jsonDocument";

export type Document<T> = JsonDocumentVersioned<DeepPartial<T>>;

export interface Migratable<FormType> {
  readonly form?: Document<FormType>;
  readonly invitationForm?: Document<FormType>;
}

export type Migrated<M extends Migratable<unknown>> = M extends Migratable<infer FormType>
  ? M & { readonly migratedForm?: Document<FormType> }
  : never;

export type MigrateFunction<Instance extends Migratable<any> = Migratable<any>> =
  () => Migrated<Instance>;

type MigrationDictionary<T> = { [key: string]: (form: Document<T>) => Document<T> };

// This helper function guarantees the migrations always run in the right order.
export const runMigrationDictionary = <T>(
  doc: Document<T>,
  dictionary: MigrationDictionary<T>
): Document<T> =>
  Object.entries(dictionary)
    .sort(([k1], [k2]) => parseInt(k1, 10) - parseInt(k2, 10))
    .reduce((acc, [migrationNumber, migrateFunction]) => {
      // If the current document is already above the current migration number, don't migrate.
      if (parseInt(doc.schemaVersion, 10) > parseInt(migrationNumber, 10)) {
        return acc;
      }
      return migrateFunction(acc);
    }, doc);

export const versionBump = <T>(doc: Document<T>): Document<T> => {
  return { ...doc, schemaVersion: (parseInt(doc.schemaVersion, 10) + 1).toString() };
};
