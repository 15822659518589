import { gql } from "@apollo/client";
import { TicketSchema } from "../../schemas/tickets/_types";
import { Identifiable } from "../../types";
import { TickettingDraft } from "../../types/ticket";

export const MUTATION = gql`
  mutation IssueDraftTicket($input: IssueDraftTicketInput!) {
    payload: issueDraftTicket(input: $input) {
      ticket {
        id
      }
      draftTicket {
        id
        insertedAt
        form {
          id
          data
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: IssueDraftTicketInput;
}

interface IssueDraftTicketInput {
  readonly ownerId: string;
  readonly form?: string;
}

export interface Result {
  readonly payload?: IssueDraftTicketPayload;
}

interface IssueDraftTicketPayload {
  readonly ticket: Identifiable;
  readonly draftTicket: TickettingDraft<TicketSchema>;
}
