import React, { useEffect } from "react";
import { connectField, useForm } from "uniforms";
import { NumFieldProps } from "uniforms-semantic";
import { convertToChainBigInt } from "../../contexts/Chain/helpers";
import { isDefined } from "../../types";
import { CustomNumField } from "../tickets/components/CustomNumField";
import { BidSchema } from "./_types";

export const EstimatedCostField = connectField((props: NumFieldProps) => {
  const { value } = props;
  const { onChange: changeModel, model } = useForm<BidSchema>();
  const { estimatedCostAsBigInt } = model.computedFields || {};

  useEffect(() => {
    const latestEstimate = isDefined(value) ? convertToChainBigInt(value) : undefined;
    changeModel("computedFields.estimatedCostAsBigInt", latestEstimate?.toString());
  }, [value, estimatedCostAsBigInt, changeModel]);

  return <CustomNumField {...props} name="" />;
});
