import { useHistory } from "react-router-dom";
import { Button, Divider, Grid, Header, Image, Modal } from "semantic-ui-react";
import { Shortcuts as S } from "../../../../routing";
import ApprovedIcon from "../../../../assets/approved-icon.svg";
import { useCallback } from "react";

interface Props {
  readonly expertName: string;
  readonly onGeneralClose: () => void;
}

export const SuccessModal = ({ expertName, onGeneralClose }: Props) => {
  const history = useHistory();

  const handleClick = useCallback(() => history.push(S.mySprint.path), [history]);

  return (
    <Modal
      className="CustomClose"
      onClose={onGeneralClose}
      open={true}
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Grid centered>
          <Grid.Column textAlign="center">
            <Grid.Row>
              <Image size="small" src={ApprovedIcon} verticalAlign="middle" />
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Header size="large">{`${expertName} has been notified of your feedback`}</Header>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Button basic color="blue" onClick={handleClick}>
                <b>Go to My Sprint</b>
              </Button>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};
