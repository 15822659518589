import { DeepPartial } from "uniforms";
import { Maybe } from "../../types";
import { TagMapping } from "../../types/tags";
import { FundraisingInfo } from "../../types/OnboardForm";
import { StartupData } from "../../schemas/onboards/utils/_types";

export const formattedValuation = (value: Maybe<number>): string => {
  if (value === null) {
    return "Unknown";
  } else if (value / 1000000 >= 1) {
    const res = value / 1000000;
    const val = value % 1000000 === 0 ? res : parseFloat(res.toPrecision(3));
    return `£${val}M`;
  } else if (value / 1000 >= 1) {
    const res = value / 1000;
    const val = value % 1000 === 0 ? res : parseFloat(res.toPrecision(3));
    return `£${val}k`;
  } else {
    return `£${value.toString()}`;
  }
};

export const buildOfferParagraph = (
  data: DeepPartial<StartupData>,
  maturities: TagMapping,
  sectors: TagMapping,
  simplified: boolean
) => {
  const { fundraisingInfo, amountRaised, equityOffered, name } = data;
  const { hasFixedValuation, valuation, hasLeadInvestor, maturity } = data;

  let fundraisingInfoText = null;
  let equityText = null;
  let valuationText = null;
  let leadInvestorText = null;

  // FUNDRAISING INFO.
  // Startup is raising money.
  if (fundraisingInfo === FundraisingInfo.RAISING && amountRaised) {
    fundraisingInfoText = (
      <span>
        {name} is raising <b>£{amountRaised.toLocaleString()} </b>{" "}
      </span>
    );
    // Startup is bridging.
  } else if (fundraisingInfo === FundraisingInfo.BRIDGE_ROUND && amountRaised) {
    fundraisingInfoText = (
      <span>
        {name} is raising a bridge round of <b>£{amountRaised.toLocaleString()} </b>{" "}
      </span>
    );
    // Startup is not raising a round.
  } else {
    fundraisingInfoText = <span>{name} is not engaged in a round. </span>;
  }

  if (fundraisingInfo !== FundraisingInfo.NOT_RAISING) {
    // EQUITY INFO.
    equityText = equityOffered ? (
      <span>
        against <b> {equityOffered} % </b>{" "}
      </span>
    ) : null;

    // VALUATION INFO.
    if (valuation) {
      valuationText = hasFixedValuation ? (
        <span>
          at a fixed pre - money valuation of <b>£{valuation.toLocaleString()} </b>.{" "}
        </span>
      ) : (
        <span>
          at a pre - money valuation of <b>£{valuation.toLocaleString()} </b> that is open for
          negotiation.{" "}
        </span>
      );
    }

    // LEAD INVESTOR INFO.
    leadInvestorText = hasLeadInvestor ? <span>A lead investor has been confirmed. </span> : null;
  }

  // STAGE INFO.
  const stageInfo = maturity ? (
    <span>
      {name} is of maturity type {maturities[maturity] || "unknown"}{" "}
    </span>
  ) : null;

  // SECTOR INFO.
  const formattedSectors = Object.entries(sectors)
    .filter(([key]) => !!data.sectors?.includes(key))
    .map(([_, val]) => val)
    .join(", ");

  const sectorInfo = (
    <span>
      and operates in the following sectors(s): <b>{formattedSectors}</b>.
    </span>
  );

  if (simplified) {
    return (
      <p>
        {fundraisingInfoText}
        {equityText}
        {sectorInfo}
      </p>
    );
  }

  return (
    <p>
      {fundraisingInfoText}
      {equityText}
      {valuationText}
      {leadInvestorText}
      {stageInfo}
      {sectorInfo}
    </p>
  );
};

export const paragraphToHtml = (text: string | null | undefined): JSX.Element | null => {
  if (!text) {
    return null;
  }

  const paragraphs = text?.trimStart().trimEnd().split("\n");

  return (
    <span>
      {paragraphs.map((p, idx) => {
        const isLastParagraph = idx === paragraphs.length - 1;
        return (
          <span key={idx}>
            {p}
            {!isLastParagraph && <br />}
          </span>
        );
      })}
    </span>
  );
};
