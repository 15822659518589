import { gql } from "@apollo/client";
import { PlannedDeliverable } from "../../schemas/deliverablesPlan/_types";
import { Identifiable } from "../../types";
import { BaseTicketDeliverable, TicketDeliverableState } from "../../types/ticket";

export const QUERY = gql`
  query GetWorkplaceDeliverables($id: ID!) {
    payload: node(id: $id) {
      ... on TickettingTicket {
        id
        owner {
          id
          fullName
        }
        deliverables {
          id
          state
          dueDate
          completedAt
          startedAt
          progressUpdates {
            id
            progressUpdateDate
            progressUpdateStatus
          }
          form(migrate: true) {
            id
            data
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly id: string;
}

export interface Result {
  readonly payload?: DeliverablesPayload;
}

export interface DeliverablesPayload extends Identifiable {
  readonly deliverables: DeliverableType[];
  readonly owner: { readonly fullName: string } & Identifiable;
}
export interface DeliverableType extends BaseTicketDeliverable {
  readonly startedAt?: string;
  readonly completedAt?: string;
  readonly form: { readonly data: PlannedDeliverable } & Identifiable;
  readonly progressUpdates: DeliverableUpdates[];
}

export interface DeliverableUpdates extends Identifiable {
  readonly progressUpdateDate: string;
  readonly progressUpdateStatus: TicketDeliverableState;
}
