import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { DiscussionComment } from "./DiscussionThreadsOnIsDiscussable";

export const MUTATION = gql`
  mutation PostDiscussionComment($input: PostDiscussionCommentInput!) {
    payload: postDiscussionComment(input: $input) {
      comment {
        id
        title
        body
        insertedAt
        transmittedAt
        thread {
          id
          isLight
          commentCount
        }
        author {
          ...Author
        }
        attachments(first: 2) {
          ...AttachmentsConnection
        }
      }
    }
  }
  fragment Author on Role {
    id
    fullName
    avatarUrl
  }
  fragment AttachmentsConnection on AttachmentConnection {
    edges {
      node {
        id
        downloadUrl
        document {
          id
          uploadedFile {
            id
            filename
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: PostDiscussionCommentInput;
}

interface PostDiscussionCommentInput {
  readonly threadId: string;
  readonly commentInput: DiscussionCommentInput;
  readonly documents?: ReadonlyArray<string>;
}

interface DiscussionCommentInput {
  readonly body: string;
  readonly ethSignature: string;
  readonly title: string;
  readonly transmittedAt: string;
}

export interface Result {
  readonly payload?: PostDiscussionCommentPayload;
}

interface PostDiscussionCommentPayload {
  readonly thread: { readonly commentCount: number; readonly isLight: boolean } & Identifiable;
  readonly comment: DiscussionComment;
}
