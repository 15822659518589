import { gql } from "@apollo/client";
import { BidSchema } from "../../schemas/bids/_types";
import { TicketSchema } from "../../schemas/tickets/_types";
import { Identifiable } from "../../types";
import { JsonDocument } from "../../types/jsonDocument";
import { Connection } from "../../types/relay";
import { TicketState, TicketStatus } from "../../types/ticket";
import { TicketBidState, TicketBidStatus, TickettingBidEvaluation } from "../../types/bid";
import { OwnerWithAvatarField } from "./Tickets";

export const QUERY = gql`
  query TickettingBid($bidId: ID!) {
    bid: node(id: $bidId) {
      ... on TickettingBid {
        id
        owner {
          id
          fullName
        }
        state
        status
        publishedAt
        selectedWinnerAt
        rejectedAt
        voidedExpertDeclinedOfferAt
        voidedOfferTimeoutAt
        seal {
          message
          signature
        }
        form(migrate: true) {
          id
          data
          schemaVersion
        }
        ticket {
          id
          state
          status
          insertedAt
          transmittedAt
          owner {
            id
            fullName
            avatarUrl
          }
          ticketForm(migrate: true) {
            id
            data
            schemaVersion
          }
        }
        evaluationRequest {
          id
          bidEvaluationRequestedAt
        }
        tickettingBidEvaluations(first: 1) {
          edges {
            node {
              id
              insertedAt
              owner {
                id
                fullName
              }
              form {
                id
                data
              }
            }
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly bidId: string;
}

export interface Result {
  readonly bid?: TickettingBidWithAttachments;
}

export interface TickettingBidWithAttachments extends Identifiable {
  readonly owner: { readonly fullName: string } & Identifiable;
  readonly state: TicketBidState;
  readonly status: TicketBidStatus;
  readonly publishedAt?: string;
  readonly selectedWinnerAt?: string;
  readonly rejectedAt?: string;
  readonly voidedExpertDeclinedOfferAt?: string;
  readonly voidedOfferTimeoutAt?: string;
  readonly form?: JsonDocument<BidSchema>;
  readonly seal?: { readonly message: string; readonly signature: string };
  readonly evaluationRequest?: { readonly bidEvaluationRequestedAt?: string } & Identifiable;
  readonly ticket: {
    readonly state: TicketState;
    readonly status: TicketStatus;
    readonly transmittedAt?: string;
    readonly insertedAt: string;
    readonly owner: OwnerWithAvatarField;
    readonly ticketForm?: JsonDocument<TicketSchema>;
  } & Identifiable;
  readonly tickettingBidEvaluations?: Connection<TickettingBidEvaluation>;
}
