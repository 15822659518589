import { gql } from "@apollo/client";
import { SENT_ONBOARD_FRAGMENT } from "../_fragments/Onboard";
import { SentOnboard } from "../../types/onboard";

export const MUTATION = gql`
  mutation ResendOnboardIssuanceEmail($input: ResendOnboardIssuanceEmailInput!) {
    payload: resendOnboardIssuanceEmail(input: $input) {
      onboard {
        ...SentOnboard
      }
    }
  }
  ${SENT_ONBOARD_FRAGMENT}
`;

interface ResendOnboardIssuanceEmailInput {
  readonly onboardId: string;
}
export interface Variables {
  readonly input: ResendOnboardIssuanceEmailInput;
}

export interface Result {
  readonly payload?: { readonly onboard: SentOnboard };
}

// TODO: On onboard:
// - if issuanceEmailSentAt < 3600, then cant send email again (only for Pending onboards)
