import React, { useCallback, useState } from "react";
import { AccordionTitleProps, Button, Grid, Modal } from "semantic-ui-react";
import { connectField, HTMLFieldProps, useForm } from "uniforms";
import classNames from "classnames";
import { DeliverableSchema } from "../_types";
import { bridge } from "../TODeliverablesSchema";
import { DatePickerField } from "./DatePickerField";
import { CustomTextField } from "../../CustomTextField";
import { CustomLongTextField } from "../../CustomLongTextField";
import { DeliverableField } from "./DeliverableField";
import { capitalize, Maybe } from "../../../types";
import { AjvError, appendCustomAjvError } from "../../../utils/Ajv";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";

interface Props extends HTMLFieldProps<DeliverableSchema[], HTMLElement> {}

export const DeliverableListField = connectField((props: Props) => {
  const { label, className, disabled, required } = props;
  const { value, onChange, error, showInlineError, errorMessage } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const selectCollapsible = useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>, { index }: AccordionTitleProps) => {
      const i = index ? +index : 0;
      setActiveIndex(activeIndex === i ? -1 : i);
    },
    [activeIndex]
  );

  const deleteCollapsible = useCallback(
    (i: number) => {
      onChange(value ? [...value.slice(0, i), ...value.slice(i + 1)] : value, "deliverables");
      if (i === activeIndex) {
        setActiveIndex(-1);
      }
    },
    [value, activeIndex, onChange]
  );

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const onValidate = useCallback((model: Partial<DeliverableSchema>, mError: Maybe<AjvError>) => {
    let e = mError;
    const { dueDate } = model;

    if (dueDate) {
      const todayAsNumber = Date.parse(new Date().toString());
      const isInvalidDueDate = Date.parse(dueDate.toString()) - todayAsNumber < 0;
      e = appendCustomAjvError(e, isInvalidDueDate, "/dueDate", "Due date must be in the future");
    }

    if (e) {
      console.warn(e);
    }

    return e;
  }, []);

  const onSubmit = useCallback(
    (m) => {
      const formWithTrimmedTitle = { ...m, title: capitalize(m.title.trim()) };
      onChange(value ? [...value, formWithTrimmedTitle] : [formWithTrimmedTitle], "deliverables");
      setOpen(false);
    },
    [onChange, value]
  );

  return (
    <div className={classNames(className, { disabled, error, required }, "field")}>
      {label && <label>{label}</label>}
      <Grid>
        {value &&
          value.map((_, i) => (
            <Grid.Row key={i}>
              <Grid.Column>
                <DeliverableField
                  name={i.toString()}
                  index={i}
                  activeIndex={activeIndex}
                  isEdit={true}
                  selectDropdown={selectCollapsible}
                  deleteDropdown={deleteCollapsible}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        <Grid.Row>
          <Grid.Column>
            <Modal
              className="CustomClose"
              onClose={onClose}
              onOpen={onOpen}
              open={open}
              trigger={<Button primary type="button" content="Add deliverable" />}
              closeIcon
              closeOnDimmerClick={false}
            >
              <Modal.Content>
                <AutoForm
                  id="deliverable-form"
                  schema={bridge}
                  onValidate={onValidate}
                  onSubmit={onSubmit}
                  showInlineError
                >
                  <CustomTitle />
                  <CustomTextField
                    name="title"
                    label="Title of the deliverable"
                    placeholder="Enter up to 50 characters"
                    errorMessage="This field is mandatory and should be less than 50 characters."
                  />
                  <CustomLongTextField
                    name="description"
                    label="Describe the work package to be delivered."
                    placeholder="Enter up to 500 characters"
                    errorMessage="This field is mandatory and should be less than 500 characters."
                  />
                  <DatePickerField
                    name="dueDate"
                    label="By when would you like this work delivered?"
                    format="DD-MM-YYYY"
                    placeholder="dd-mm-yyyy"
                    errorMessage="This fied is required. You must select a due date in the future."
                  />
                  <Button type="button" secondary onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    form="deliverable-form"
                    primary
                    content="Add"
                    labelPosition="right"
                    icon="plus"
                  />
                </AutoForm>
              </Modal.Content>
            </Modal>
            {!!(error && showInlineError) && (
              <div className="ui red basic left pointing label">{errorMessage}</div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
});

const CustomTitle = () => {
  const { model } = useForm<DeliverableSchema>();
  return model.title ? <h1>{model.title}</h1> : null;
};
