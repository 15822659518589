import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { DeliverableSchema } from "./_types";
import { utils } from "../../utils/utils";

export const schema: JSONSchemaType<DeliverableSchema> = {
  type: "object",
  properties: {
    title: {
      type: "string",
      maxLength: 50,
      pattern: "(.|s)*\\S(.|s)*",
    },
    description: {
      type: "string",
      maxLength: 500,
      pattern: "(.|s)*\\S(.|s)*",
    },
    dueDate: {
      type: "string",
      format: "date",
      pattern: "(.|s)*\\S(.|s)*",
    },
  },
  required: ["title", "description", "dueDate"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
