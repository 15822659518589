import { gql } from "@apollo/client";
import { Document } from "../../types/document";

export const QUERY = gql`
  query DocumentsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Document {
        id
        hash
        uploadedFile {
          id
          filename
        }
      }
    }
  }
`;

export interface Variables {
  readonly ids: ReadonlyArray<string>;
}

export interface Result {
  readonly nodes?: ReadonlyArray<Document | undefined>;
}
