import { JSONSchemaType } from "ajv";
import { JSONSchemaBridge } from "uniforms-bridge-json-schema";
import { EntityKind, EntityType } from "../../types/onboard";
import { utils } from "../../utils/utils";
import { globalDefinitions } from "../_definitions";

const schema: JSONSchemaType<StartupInviteType> = {
  type: "object",
  properties: {
    type: { type: "string", enum: [EntityType.Organisation] },
    kind: { type: "string", enum: [EntityKind.Startup] },
    name: { ...globalDefinitions.nonEmptyString, minLength: 2, maxLength: 50 },
    recipientEmail: globalDefinitions.email,
    invitationReason: { ...globalDefinitions.nonEmptyString, minLength: 4, maxLength: 250 },
  },
  required: ["type", "kind", "name", "recipientEmail", "invitationReason"],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));

export interface StartupInviteType {
  readonly type: EntityType.Organisation;
  readonly kind: EntityKind.Startup;
  readonly name: string;
  readonly recipientEmail: string;
  readonly invitationReason: string;
}
