import { Typed } from "../../types";

export enum ActionTypes {
  Hide = "@overlay/Hide",
  Show = "@overlay/Show",
  Configure = "@overlay/Configure",
}
// Action instances.
export interface Hide extends Typed<ActionTypes.Hide> {}
export interface Show extends Typed<ActionTypes.Show> {
  readonly content: JSX.Element;
}
export interface Configure extends Typed<ActionTypes.Configure> {
  readonly isHideable: boolean;
  readonly isDimmable: boolean;
}

export type Action = Hide | Show | Configure;
