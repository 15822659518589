import { Children, ReactElement } from "react";
import { Context, useForm } from "uniforms";

interface DisplayIfProps<T> {
  children: ReactElement;
  condition(context: Context<T>): boolean;
}

// We have to ensure that there's only one child, because returning an array
// from a component is prohibited.
export function DisplayIf<T>({ children, condition }: DisplayIfProps<T>) {
  const form = useForm<T>();
  return condition(form) ? Children.only(children) : null;
}
