import { gql } from "@apollo/client";
import { PAGEINFO_FRAGMENT } from "./PageInfo";

export const BALLOT_FRAGMENT = gql`
  fragment Ballot on Ballot {
    id
    choice
    proposal {
      id
    }
    insertedAt
    form(migrate: false) {
      id
      data
      schemaVersion
    }
    discussionThread {
      id
      commentCount
    }
    role {
      id
      fullName
      avatarUrl
    }
  }
`;

export const BALLOT_CONNECTION_FRAGMENT = gql`
  fragment BallotConnection on BallotConnection {
    edges {
      cursor
      node {
        ...Ballot
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${BALLOT_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;

export const BALLOT_WITHOUT_DISSTHREAD_FRAGMENT = gql`
  fragment BallotWithoutDissThread on Ballot {
    id
    choice
    proposal {
      id
    }
    insertedAt
    form(migrate: false) {
      id
      data
      schemaVersion
    }
  }
`;

export const BALLOT_WITHOUT_DISSTHREAD_CONNECTION_FRAGMENT = gql`
  fragment BallotWithoutDissThreadConnection on BallotConnection {
    edges {
      cursor
      node {
        ...BallotWithoutDissThread
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${BALLOT_WITHOUT_DISSTHREAD_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;
