import { forwardRef, useCallback, useMemo } from "react";
import { Context } from "uniforms";
import { Maybe } from "../../types";
import { AjvError } from "../../utils/Ajv";
import { CustomLongTextField } from "../CustomLongTextField";
import { CheckboxListField } from "../tickets/components/CheckboxListField";
import { bridge } from "./TicketPreferencesSchema";
import { TicketPreferencesSchema } from "./_types";
import { CommunicationMethods, readableCommunicationMethods } from "./_enums";
import { DisplayIf } from "../DisplayIf";
import { CustomTextField } from "../CustomTextField";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";

interface Props {
  readonly onSubmitPreferences: (model: Partial<TicketPreferencesSchema>) => void;
}

export const TicketPreferencesForm = forwardRef<Partial<TicketPreferencesSchema>, Props>(
  ({ onSubmitPreferences }, ref) => {
    const onValidate = useCallback((_: TicketPreferencesSchema, e: Maybe<AjvError>) => e, []);
    const safeRef = useMemo(() => (ref && typeof ref !== "function" ? ref : undefined), [ref]);

    const onSubmit = useCallback(
      (model: TicketPreferencesSchema) => {
        if (safeRef) {
          safeRef.current = model;
        }
        onSubmitPreferences({
          ...model,
          communicationMethodOther: model.communicationMethods.includes(CommunicationMethods.Other)
            ? model.communicationMethodOther
            : undefined,
        });
      },
      [onSubmitPreferences, safeRef]
    );

    const checkCommunicationMethodOther = useCallback(
      ({ model }: Context<TicketPreferencesSchema>) => {
        return !(model.communicationMethods?.indexOf(CommunicationMethods.Other) === -1);
      },
      []
    );

    return (
      <AutoForm
        className="Preference-form"
        schema={bridge}
        model={safeRef?.current}
        onSubmit={onSubmit}
        showInlineError
        onValidate={onValidate}
      >
        <CheckboxListField
          name="communicationMethods"
          label="What's your preferred method of communication?"
          errorMessage="This field is mandatory."
          options={communicationMethodOptions}
        />
        <DisplayIf condition={checkCommunicationMethodOther}>
          <CustomTextField
            name="communicationMethodOther"
            label="Please specify other methods"
            errorMessage="This field is mandatory, please specify other methods."
          />
        </DisplayIf>
        <CustomLongTextField
          name="knowledgeAreas"
          label="Are there any additional notes you have for your Expert?"
        />
        <SignatureButton primary type="submit" floated="right" content="Submit" />
      </AutoForm>
    );
  }
);

export const communicationMethodOptions = Object.values(CommunicationMethods).map((v) => ({
  label: readableCommunicationMethods(v),
  value: v.toString(),
}));
