import React, { useCallback, CSSProperties, createRef } from "react";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { utils } from "../../../utils/utils";
import { Document } from "../../../types/document";
import { DocumentBadge } from "../DocumentBadge";

const marginBottomStyle: CSSProperties = { marginBottom: "10px" };

interface Props {
  readonly documents: ReadonlyArray<Document> | undefined;
  readonly maxDocs: number;
  readonly onFilesChange?: (f: File[] | undefined) => void;
  readonly onDeleteDocument?: (deletedDoc: Document) => void;
  readonly title?: string;
  readonly bttnStyle?: CSSProperties;
  readonly acceptedFiles?: string[];
}

export const UploadDocs = (props: Props) => {
  const { title, documents, maxDocs, bttnStyle, acceptedFiles } = props;
  const { onFilesChange, onDeleteDocument } = props;
  const hiddenFileInputRef = createRef<HTMLInputElement>();

  const onBttnClick = useCallback(() => hiddenFileInputRef.current?.click(), [hiddenFileInputRef]);

  const onFileInputChange = useCallback(
    ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      if (files !== null && onFilesChange) {
        if ((documents?.length || 0) + files.length > maxDocs) {
          return toast.warn(`Upload a maximum of ${maxDocs} documents.`);
        }
        const largestSize = Object.values(files).reduce(
          (acc, { size }) => (size > acc ? size : acc),
          0
        );
        if (largestSize > utils.UPLOAD_MAX_SIZE) {
          return toast.warn("Maximum file size is 16MB.");
        }
        onFilesChange(Object.values(files));
      }
    },
    [onFilesChange, maxDocs, documents]
  );

  return (
    <>
      {documents && documents.length > 0 && (
        <div className="DocumentBadgeGroup" style={marginBottomStyle}>
          {documents.map(({ id, uploadedFile }) => {
            const onDelete = () => {
              if (hiddenFileInputRef.current) {
                hiddenFileInputRef.current.value = "";
              }
              if (onDeleteDocument && documents) {
                const docToDelete = Object.values(documents).find((d) => id === d.id);
                if (docToDelete) {
                  onDeleteDocument(docToDelete);
                }
              }
            };
            return (
              <DocumentBadge
                key={id}
                documentId={id}
                documentName={uploadedFile.filename}
                onDelete={onDelete}
              />
            );
          })}
        </div>
      )}
      <Button type="button" basic color="teal" onClick={onBttnClick} style={bttnStyle}>
        <b>{title || "Upload"}</b>
      </Button>
      <input
        ref={hiddenFileInputRef}
        className="display-none"
        type="file"
        multiple={maxDocs > 1}
        onChange={onFileInputChange}
        accept={acceptedFiles?.join(", ")}
      />
    </>
  );
};
