import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Shortcuts as S } from "../../routing";
import { perform } from "../../api/onboards/ClaimOnboard";
import { OnboardState } from "../../types/onboard";
import { ApiError } from "../../types";

const idVarName = S.application.queryVarNames.id;

export const ClaimInvite = ({ history, location }: RouteComponentProps) => {
  const client = useApolloClient();
  const [isFirstRun, setIsFirstRun] = useState(true);
  const onboardId = new URLSearchParams(location.search).get(S.claimInvite.queryVarNames.id);

  if (!onboardId) {
    history.replace("/");
  } else if (isFirstRun) {
    setIsFirstRun(false);
    perform(client, { onboardId })
      .then((res) => {
        if (!res.data?.payload) {
          return Promise.reject(new Error("Missing onboard result."));
        } else if (res.data.payload.onboard.state === OnboardState.Pending) {
          toast.success("Invite claimed successfully.");
          return history.replace(S.myArea.path);
        } else {
          const id = res.data.payload.onboard.id;
          const search = new URLSearchParams({ [idVarName]: id }).toString();
          history.push({ pathname: S.application.path, search });
          return null;
        }
      })
      .catch((e: ApiError | Error) => {
        console.warn(JSON.stringify(e.message));
        toast.error("Failed to claim the invite.");
        history.replace(S.myArea.path);
        return null;
      });
  }
  return <Loader active inline="centered" />;
};
