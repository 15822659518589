import { CSSProperties, useCallback, useMemo, useState } from "react";
import { Button, Card, CardContent, CardHeader, Segment, Image } from "semantic-ui-react";
import { Icon, Modal, Container } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { TickettingBidWithAttachments } from "../../../../api/tickets/TickettingBid";
import { TicketBidStatus } from "../../../../types/bid";
import { Result, Variables, MUTATION } from "../../../../api/tickets/AcknowledgeTicketBid";
import { MUTATION as RequestMut } from "../../../../api/tickets/RequestBidEvaluation";
import { Result as RequestResult } from "../../../../api/tickets/RequestBidEvaluation";
import { Variables as RequestVars } from "../../../../api/tickets/RequestBidEvaluation";
import { Result as RejectResult } from "../../../../api/tickets/DeclineOfferAndVoidBid";
import { MUTATION as RejectMutation } from "../../../../api/tickets/DeclineOfferAndVoidBid";
import { Variables as RejectVars } from "../../../../api/tickets/DeclineOfferAndVoidBid";
import { ApiError } from "../../../../types";
import { Shortcuts as S } from "../../../../routing";
import { Enrolled } from "../../../../contexts/Session/state";
import { isSuccessState } from "../../../../contexts/Generic";
import { useSettingsState } from "../../../../contexts/Settings";
import { useChainApi, useChainState } from "../../../../contexts/Chain";
import { Tabs } from "../../../pages/MyWork";
import { useSealFieldsQuery } from "../../../../hooks/useSealFieldsQuery";
import { QUERY as BidSealQuery, bidEnvelope } from "../../../../api/seals/BidSeal";
import { SealQueryResult as BidSealQueryResult } from "../../../../api/seals/BidSeal";
import { bidEvaluationRequestEnvelope } from "../../../../api/seals/BidEvaluationRequestSeal";
import { BidEvaluationRequestSealFields } from "../../../../api/seals/_fragments/BidEvaluationRequestFields";
import { TicketState } from "../../../../types/ticket";
import { SignatureButton } from "../../SignatureButton";
import { digestFormData } from "../../../../types/OnboardForm";
import approvedLogo from "../../../../assets/approved-icon.svg";
import { nodesFromEdges } from "../../../../types/relay";
import { formatDate } from "./BidCard";
import { utils } from "../../../../utils/utils";

const cardStyle: CSSProperties = { minWidth: "244px", width: "244px", marginTop: "0px" };
const aprovedLogoStyle: CSSProperties = {
  maxHeight: "13px",
  marginRight: "5px",
  marginBottom: "2px",
};

interface Props {
  readonly bid: TickettingBidWithAttachments;
  readonly sessionState: Enrolled;
}

const today = new Date().getTime();

export const Outcome = ({ bid, sessionState }: Props) => {
  const roleId = sessionState.roleId;
  const { status, selectedWinnerAt, ticket, id: bidId, owner, form } = bid;
  const { rejectedAt, evaluationRequest, tickettingBidEvaluations } = bid;
  const { voidedExpertDeclinedOfferAt } = bid;

  // Hooks.
  const history = useHistory();
  const chainState = useChainState();
  const chainApi = useChainApi();
  const settings = useSettingsState();
  const bidSealFieldsQuery = useSealFieldsQuery<BidSealQueryResult>();

  // Mutations.
  const [acceptTerms, { loading: acceptTermsLoading }] = useMutation<Result, Variables>(MUTATION);
  const rejectTermsData = useMutation<RejectResult, RejectVars>(RejectMutation);
  const [rejectTerms, { loading: rejectTermsLoading }] = rejectTermsData;
  const requestEvalData = useMutation<RequestResult, RequestVars>(RequestMut);
  const [requestEvaluation, { loading: requestEvaluationLoading }] = requestEvalData;

  // State.
  const [modalStep, setModalStep] = useState(0);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const isLoading =
    sealFieldsLoading || acceptTermsLoading || rejectTermsLoading || requestEvaluationLoading;

  const isBidOwner = useMemo(() => owner.id === roleId, [owner, roleId]);
  const isTicketOwner = useMemo(() => ticket.owner.id === roleId, [ticket, roleId]);

  const isWinner =
    status === TicketBidStatus.SelectedWinner ||
    status === TicketBidStatus.SelectedWinnerAcknowledged ||
    status === TicketBidStatus.ConfirmedWinner;
  const isVoided =
    status === TicketBidStatus.VoidedExpertDeclined || status === TicketBidStatus.VoidedTimedOut;

  const displayAcceptTerms = isBidOwner && status === TicketBidStatus.SelectedWinner;
  const displayRequestEval = isBidOwner && status === TicketBidStatus.Loser;

  const inRequestEvalPeriod = useMemo(() => {
    if (!isSuccessState(settings) || rejectedAt === undefined) {
      return false;
    }
    const diffDate = (today - new Date(rejectedAt).getTime()) / 1000;
    return diffDate < settings.result.core.ticket_bid_evaluation_request_after_rejection_ttl;
  }, [rejectedAt, settings]);

  const evaluation = useMemo(
    () => nodesFromEdges(tickettingBidEvaluations?.edges),
    [tickettingBidEvaluations]
  );

  const handleGeneralClose = useCallback(() => setModalStep(0), []);
  const handleAcceptModal = useCallback(() => setModalStep(1), []);
  const handleRejectModal = useCallback(() => setModalStep(2), []);

  const handleRequestEval = useCallback(async () => {
    if (!isSuccessState(settings) || form === undefined || rejectedAt === undefined) {
      return null;
    }
    const transmittedAt = new Date();
    const { chainId } = sessionState;

    setSealFieldsLoading(true);
    const sealData = await bidSealFieldsQuery(BidSealQuery, { id: bidId });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const tAt = transmittedAt.toLocaleString();
    const fields: BidEvaluationRequestSealFields = { bid: sealData.fields, transmittedAt: tAt };
    const envelope = bidEvaluationRequestEnvelope(chainId, fields, transmittedAt);
    const plainText = digestFormData(envelope, true);

    chainApi
      .sign(chainState, plainText)
      .then((ethSignature) =>
        requestEvaluation({ variables: { input: { bidId, ethSignature, transmittedAt } } })
      )
      .then(() => toast.success("You have requested an evaluation."))
      .catch((e: ApiError) => {
        if (e.message.includes("User denied message signature")) {
          return;
        }
        toast.error("Something went wrong");
        console.warn(e);
      });
  }, [
    chainApi,
    chainState,
    form,
    bidId,
    rejectedAt,
    settings,
    sessionState,
    requestEvaluation,
    bidSealFieldsQuery,
  ]);

  const handleAcceptTerms = useCallback(async () => {
    if (!isSuccessState(settings) || form === undefined) {
      return null;
    }
    setModalStep(0);
    const transmittedAt = new Date();
    const { chainId } = sessionState;

    setSealFieldsLoading(true);
    const sealData = await bidSealFieldsQuery(BidSealQuery, { id: bid.id });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const { fields } = sealData;
    const { signature } = bid.seal || {};
    const envelope = bidEnvelope(chainId, fields, transmittedAt, "accept_offer", signature);
    const plainText = digestFormData(envelope, true);

    chainApi
      .sign(chainState, plainText)
      .then((ethSignature) =>
        acceptTerms({ variables: { input: { bidId, ethSignature, transmittedAt } } })
      )
      .then(() => {
        toast.success(
          `You have accepted the offer to work on ${
            ticket.ticketForm?.data.title || "this ticket"
          }.`
        );
        const varName = S.myWork.queryVarNames.tab;
        const search = new URLSearchParams({ [varName]: Tabs.MyProjects }).toString();
        history.push({ pathname: S.myWork.path, search });
      })
      .catch((e: ApiError) => {
        if (e.message.includes("User denied message signature")) {
          return setModalStep(1);
        }
        toast.error("Something went wrong.");
        console.warn(e);
      });
  }, [
    acceptTerms,
    sessionState,
    ticket,
    bidId,
    history,
    settings,
    chainApi,
    chainState,
    form,
    bid,
    bidSealFieldsQuery,
  ]);

  const handleRejectTerms = useCallback(async () => {
    if (!isSuccessState(settings) || form === undefined) {
      return null;
    }
    setModalStep(0);
    const transmittedAt = new Date();
    const { chainId } = sessionState;

    setSealFieldsLoading(true);
    const sealData = await bidSealFieldsQuery(BidSealQuery, { id: bid.id });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const { fields } = sealData;
    const { signature } = bid.seal || {};
    const envelope = bidEnvelope(chainId, fields, transmittedAt, "decline_offer", signature);
    const plainText = digestFormData(envelope, true);

    chainApi
      .sign(chainState, plainText)
      .then((ethSignature) =>
        rejectTerms({ variables: { input: { bidId, ethSignature, transmittedAt } } })
      )
      .then(() => toast.success("You have declined this offer."))
      .catch((e: ApiError) => {
        if (e.message.includes("User denied message signature")) {
          return setModalStep(2);
        }
        toast.error("Something went wrong.");
        console.warn(e);
      });
  }, [
    chainApi,
    chainState,
    bidId,
    rejectTerms,
    sessionState,
    settings,
    form,
    bid,
    bidSealFieldsQuery,
  ]);

  const displayOutcome =
    ticket.state !== TicketState.Draft &&
    ticket.state !== TicketState.In_bidding &&
    (status === TicketBidStatus.SelectedWinner ||
      status === TicketBidStatus.SelectedWinnerAcknowledged ||
      status === TicketBidStatus.ConfirmedWinner ||
      status === TicketBidStatus.Loser ||
      status === TicketBidStatus.VoidedExpertDeclined ||
      status === TicketBidStatus.VoidedTimedOut);

  if (!displayOutcome) {
    return null;
  }

  return (
    <Card as={Segment} style={cardStyle} className="BidOutcome">
      <CardContent className="BidOutcome-TopSection">
        <CardHeader className="BidOutcome-TopSection-header">Outcome</CardHeader>
        <Card.Description>
          <div
            className={`BidOutcome-TopSection-label ${
              isWinner ? "green" : isVoided ? "voided" : "red"
            }`}
          >
            {getStatusLabel(status, isTicketOwner)}
            {isWinner ? <Icon name="check" /> : null}
          </div>
          {`on ${dateFormat(
            isVoided ? voidedExpertDeclinedOfferAt : selectedWinnerAt || rejectedAt,
            "dd/mm/yy"
          )}`}
          {isVoided && getOutcomeVoidLabel(bid, isTicketOwner, isBidOwner)}
        </Card.Description>
      </CardContent>
      {displayAcceptTerms && (
        <CardContent className="BidOutcome-BottomSection">
          You have won the bid for {bid.ticket.ticketForm?.data.title || "Unknown"}. Do you accept
          to work on this ticket?
          <div className="BidOutcome-BottomSection-buttons">
            <Modal
              className="CustomClose"
              onClose={handleGeneralClose}
              open={modalStep === 1}
              size="mini"
              onOpen={handleAcceptModal}
              closeOnDimmerClick={false}
              closeIcon
              trigger={<SignatureButton primary content="Accept" />}
            >
              <Modal.Header>Do you accept to deliver this ticket?</Modal.Header>
              <Modal.Content>
                <p>Confirm your agreement to work on this ticket.</p>
                <Button onClick={handleGeneralClose}>Cancel</Button>
                <SignatureButton
                  primary
                  floated="right"
                  content="Accept"
                  loading={isLoading}
                  onClick={handleAcceptTerms}
                />
              </Modal.Content>
            </Modal>
            <Modal
              className="CustomClose"
              onClose={handleGeneralClose}
              open={modalStep === 2}
              size="mini"
              onOpen={handleRejectModal}
              closeOnDimmerClick={false}
              closeIcon
              trigger={<SignatureButton basic loading={isLoading} content="Decline" />}
            >
              <Modal.Header>You are declining this offer</Modal.Header>
              <Modal.Content>
                By declining this offer, you agree that your bid will be voided, and that no further
                engagement will take place between yourself and {ticket.owner.fullName} regarding
                this bid.
                <br />
                <br />
                <Container>
                  <Button onClick={handleGeneralClose}>Cancel</Button>
                  <Button primary loading={isLoading} onClick={handleRejectTerms} floated="right">
                    <Icon name="pencil" />
                    Agree
                  </Button>
                </Container>
              </Modal.Content>
            </Modal>
          </div>
        </CardContent>
      )}
      {evaluationRequest === null && displayRequestEval && !evaluation[0] && inRequestEvalPeriod && (
        <CardContent className="BidOutcome-BottomSection">
          <div className="BidOutcome-BottomSection-request-eval" onClick={handleRequestEval}>
            Request evaluation
          </div>
        </CardContent>
      )}
      {evaluationRequest !== null && displayRequestEval && (
        <CardContent className="BidOutcome-BottomSection">
          <div className="BidOutcome-BottomSection-requested-eval">
            <Image style={aprovedLogoStyle} src={approvedLogo} verticalAlign="middle" />
            {evaluation[0]
              ? `Evaluation provided on ${dateFormat(evaluation[0].insertedAt, "dd/mm/yy")}`
              : `Evaluation requested on 
            ${dateFormat(evaluationRequest?.bidEvaluationRequestedAt, "dd/mm/yy")}`}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

const getStatusLabel = (status: TicketBidStatus, isTicketOwner: boolean) => {
  switch (status) {
    case TicketBidStatus.SelectedWinner:
      return isTicketOwner ? "Offer extended" : "Offer received";
    case TicketBidStatus.SelectedWinnerAcknowledged:
    case TicketBidStatus.ConfirmedWinner:
      return "Confirmed";
    case TicketBidStatus.Loser:
      return "Declined";
    case TicketBidStatus.VoidedExpertDeclined:
    case TicketBidStatus.VoidedTimedOut:
      return "Voided";
  }
  // For now other bid status won't have any label.
  return null;
};

const getOutcomeVoidLabel = (
  bid: TickettingBidWithAttachments,
  isTicketOwner: boolean,
  isBidOwner: boolean
) => {
  const { selectedWinnerAt, voidedOfferTimeoutAt, voidedExpertDeclinedOfferAt } = bid;
  const { status, owner, ticket } = bid;
  let label = "";

  if (status === TicketBidStatus.VoidedExpertDeclined) {
    if (isTicketOwner) {
      label = `Your offer made on ${formatDate(selectedWinnerAt)} was declined by ${
        owner.fullName
      } on ${formatDate(voidedExpertDeclinedOfferAt)}. This bid is considered voided.`;
    }
    if (isBidOwner) {
      const date = formatDate(voidedExpertDeclinedOfferAt);
      const possessiveName = utils.withPossessiveApostrophe(ticket.owner.fullName);
      label = `On ${date}, you declined ${possessiveName} offer. This bid is considered voided.`;
    }
  }
  if (status === TicketBidStatus.VoidedTimedOut) {
    if (isTicketOwner) {
      label = `Your offer made on ${formatDate(selectedWinnerAt)} expired on ${formatDate(
        voidedOfferTimeoutAt
      )}. This bid is considered voided.`;
    }
    if (isBidOwner) {
      label = `The offer made by ${ticket.owner.fullName} on ${formatDate(
        selectedWinnerAt
      )} expired on ${formatDate(voidedOfferTimeoutAt)}. This bid is considered voided.`;
    }
  }
  return label ? <div className="BidOutcome-TopSection-note">{label}</div> : null;
};
