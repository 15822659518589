import React, { CSSProperties, FormEvent } from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { connectField, HTMLFieldProps } from "uniforms";

const breakAllStyle: CSSProperties = { wordBreak: "break-all" };

interface Props extends HTMLFieldProps<string | number, HTMLElement> {
  readonly [k: string]: unknown;
  readonly checked: boolean;
  readonly handleChange: (ev: FormEvent<HTMLInputElement>, p: CheckboxProps) => void;
}

export const CheckboxField = connectField((props: Props) => {
  const { label, value, handleChange, checked, disabled, style } = props;
  const { error, showInlineError, errorMessage } = props;

  return (
    <>
      <Checkbox
        label={<label style={breakAllStyle}>{label}</label>}
        style={style}
        value={value}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
      />
      {!!(error && showInlineError) && (
        <div className="ui red basic pointing label">{errorMessage}</div>
      )}
    </>
  );
});
