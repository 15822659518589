import { gql } from "@apollo/client";
import { TicketSealFields, TICKET_SEAL_FIELDS } from "./TicketSealFields";
import { TicketBidState, TicketBidStatus } from "../../../types/bid";

export const BID_SEAL_FIELDS = gql`
  fragment BidSealFields on TickettingBid {
    transmittedAt
    state
    status
    form(migrate: false) {
      data
    }
    ticket {
      ...TicketSealFields
    }
  }
  ${TICKET_SEAL_FIELDS}
`;

export interface BidSealFields {
  readonly transmittedAt: string;
  readonly state: TicketBidState;
  readonly status: TicketBidStatus;
  readonly form: { readonly data: unknown };
  readonly ticket: TicketSealFields;
}
