import { useMemo } from "react";
import { createRef, CSSProperties, memo, useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Context, DeepPartial } from "uniforms";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Header, Segment, Ref, Modal, Button } from "semantic-ui-react";
import { BoolField, RadioField } from "uniforms-semantic";
import { TicketSchema } from "./_types";
import { bridge } from "./TicketSchema";
import { ExpertAttributes, readableTicketAnswer, readableExpertAttributes } from "./_enums";
import { readableTicketPaymentMethods, TicketPaymentMethods } from "./_enums";
import { readableTicketDuration, readableTicketEngagementLevel } from "./_enums";
import { readableTicketExecutionDate, readableTicketImportance } from "./_enums";
import { DisplayIf } from "../DisplayIf";
import { DeliverableListField } from "./components/DeliverableListField";
import { NestedTagsField } from "../NestedTagsField";
import { CheckboxListField } from "./components/CheckboxListField";
import { ApiError, isDefined, Maybe } from "../../types";
import { AjvError, appendCustomAjvError } from "../../utils/Ajv";
import { QUERY as DraftQuery, Variables as DraftVariables } from "../../api/tickets/TicketById";
import { Result as DraftResult } from "../../api/tickets/TicketById";
import { QUERY as RoleTicketsQuery } from "../../api/tickets/RoleTickets";
import { Variables as RoleTicketsVariables } from "../../api/tickets/RoleTickets";
import { Result as RoleTicketsResult } from "../../api/tickets/RoleTickets";
import { FormLoader } from "../../components/elements/tickets/FormLoader";
import { ErrorMessages } from "../../components/elements/ErrorMessages";
import { Shortcuts as S } from "../../routing";
import { Result as AmendResult } from "../../api/tickets/AmendDraftTicket";
import { MUTATION as AmendMUTATION } from "../../api/tickets/AmendDraftTicket";
import { Variables as AmendVars } from "../../api/tickets/AmendDraftTicket";
import { MUTATION as PublishMUTATION } from "../../api/tickets/PublishDraftTicket";
import { Result as PublishResult } from "../../api/tickets/PublishDraftTicket";
import { Variables as PublishVars } from "../../api/tickets/PublishDraftTicket";
import { MUTATION as IssueDraftMut } from "../../api/tickets/IssueDraftTicket";
import { Result as IssueDraftRes } from "../../api/tickets/IssueDraftTicket";
import { Variables as IssueDraftVars } from "../../api/tickets/IssueDraftTicket";
import { Variables as DeleteVars } from "../../api/tickets/DeleteDraftTicket";
import { Result as DeleteResult } from "../../api/tickets/DeleteDraftTicket";
import { MUTATION as DeleteMutation } from "../../api/tickets/DeleteDraftTicket";
import { CustomErrorFields } from "./components/CustomErrorFields";
import { SaveButton } from "./components/SaveButton";
import { TicketPublishType, TicketState } from "../../types/ticket";
import { CustomLongTextField } from "../CustomLongTextField";
import { CustomTextField } from "../CustomTextField";
import { Title } from "./components/Title";
import { TicketSummaryField } from "./components/TicketSummaryField";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { InsufficientFundsErrorField } from "./components/InsufficientFundsErrorField";
import { hasValidDate } from "../../components/elements/tickets/collapsible/Deliverable";
import { Enrolled } from "../../contexts/Session/state";
import { digestFormData } from "../../types/OnboardForm";
import { useSettingsState } from "../../contexts/Settings";
import { isSuccessState } from "../../contexts/Generic";
import { CustomMultipleUploadField } from "../CustomMultipleUploadField";
import { TicketAs } from "../../api/tickets/RoleTickets";
import { nodesFromEdges } from "../../types/relay";
import { useCategoriesState } from "../../contexts/Categories";
import { CustomNumField } from "./components/CustomNumField";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { Visibility } from "./components/Visibility";
import { useConfirmationModalApi } from "../../contexts/ConfirmationModal";
import { TicketForSummary } from "../../components/elements/tickets/TicketSummary";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { QUERY, SealQueryResult, ticketEnvelope } from "../../api/seals/TicketSeal";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { TagNamespace } from "../../types/labelQuery";

const segmentStyle: CSSProperties = { minWidth: "320px", padding: "40px 30px" };

const first = 100;
const as = TicketAs.Owner;
const ticketPublishType: TicketPublishType = TicketPublishType.PublishPublicly;

// Custom errors. This messages are not seen by the users.
export enum CreateTicketErrors {
  RatesPerDay = "max rate per day must be higher than minimun",
  Percentage = "fiat and CVDS must add up to 100%",
  Funds = "not enough funds",
  DueDate = "invalid due date",
}

interface Props {
  readonly sessionState: Enrolled;
}

export const TicketForm = memo(({ sessionState }: Props) => {
  // Hooks.
  const history = useHistory();
  const location = useLocation();
  const chainState = useChainState();
  const chainApi = useChainApi();
  const settings = useSettingsState();
  const categoriesState = useCategoriesState();
  const confirmationModalApi = useConfirmationModalApi();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const ticketId = new URLSearchParams(location.search).get(S.draftTicket.queryVarNames.id);
  const { roleId } = sessionState;

  // State.
  const [balance, setBalance] = useState<number | undefined>();
  const [needsScroll, setNeedsScroll] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  // Refs.
  const contextRef = createRef<HTMLElement>();
  const initialModelRef = useRef<DeepPartial<TicketSchema>>({});
  const currentModelRef = useRef<DeepPartial<TicketSchema>>({});
  const hasPendingSubmitRef = useRef<boolean>(false);

  // Queries and query data.
  const [draftQuery, draftQueryRes] = useLazyQuery<DraftResult, DraftVariables>(DraftQuery, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const relatedTicketsQuery = useQuery<RoleTicketsResult, RoleTicketsVariables>(RoleTicketsQuery, {
    variables: { first, as, roleId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const error = draftQueryRes.error || relatedTicketsQuery.error;
  const draftId = draftQueryRes.data?.payload?.draft?.id;

  // Mutations.
  const [amendDraftTicket, amendData] = useMutation<AmendResult, AmendVars>(AmendMUTATION);
  const [publishDraftTicket, publishData] = useMutation<PublishResult, PublishVars>(
    PublishMUTATION
  );
  const [issueDraftTicket, issueData] = useMutation<IssueDraftRes, IssueDraftVars>(IssueDraftMut);
  const [deleteDraftTicket, deleteData] = useMutation<DeleteResult, DeleteVars>(DeleteMutation);
  const mutationLoading =
    amendData.loading ||
    publishData.loading ||
    issueData.loading ||
    sealFieldsLoading ||
    deleteData.loading;

  useEffect(() => {
    if (!ticketId) {
      return;
    }
    draftQuery({ variables: { ticketId } });
  }, [ticketId, draftQuery]);

  useEffect(() => {
    if (!draftQueryRes.data?.payload?.draft?.form) {
      return;
    }
    currentModelRef.current = draftQueryRes.data.payload.draft.form.data;
    initialModelRef.current = currentModelRef.current;
  }, [draftQueryRes]);

  useEffect(() => {
    const continuePromise = async () => {
      const form = JSON.stringify(currentModelRef.current);
      let promise: Promise<unknown>;

      if (!ticketId) {
        promise = issueDraftTicket({ variables: { input: { ownerId: roleId, form } } });
      } else if (!draftId || !currentModelRef.current) {
        return Promise.reject<unknown>("Missing draftId or model.");
      } else {
        promise = amendDraftTicket({ variables: { input: { draftId, form } } });
      }

      return promise
        .then(() => {
          confirmationModalApi.setHasChanges(false);
          toast.success("Your ticket was saved successfuly.");
        })
        .catch((err: ApiError) => toast.error(err.message));
    };

    confirmationModalApi.addMessage("Want to save your changes?");
    confirmationModalApi.setContinuePromise(continuePromise);

    return () => {
      confirmationModalApi.removeContinuePromise();
    };
  }, [confirmationModalApi, draftId, roleId, ticketId, amendDraftTicket, issueDraftTicket]);

  useEffect(() => {
    if (hasPendingSubmitRef.current && needsScroll) {
      const firstError = document.getElementsByClassName("ui red basic pointing label")[0];
      if (firstError && firstError.parentElement) {
        firstError.parentElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }

      hasPendingSubmitRef.current = false;
      setNeedsScroll(false);
    }
  }, [needsScroll]);

  useEffect(() => {
    if (chainState.busy || balance !== undefined) {
      return;
    }

    const onError = (e: Error) => toast.error(e.message);

    chainApi.getFractionalBalance(chainState, setBalance, onError);
  }, [chainApi, chainState, balance]);

  const initialTicketSummary = useMemo(() => {
    if (!draftQueryRes || !draftQueryRes.data || !draftQueryRes.data.payload) {
      const dummyData: TicketForSummary = {
        state: TicketState.Draft,
        owner: {
          fullName: sessionState.user.fullName || "unknown",
          avatarUrl: sessionState.user.avatarUrl,
        },
      };
      return dummyData;
    }
    return draftQueryRes.data.payload;
  }, [draftQueryRes, sessionState]);

  const [occupationTree, skillsTree] = useMemo(() => {
    if (!isSuccessState(categoriesState)) {
      return [null, null];
    }

    return [categoriesState.result.occupationsTree, categoriesState.result.skillsTree];
  }, [categoriesState]);

  const relatedTicketsOptions = useMemo(() => {
    return nodesFromEdges(relatedTicketsQuery.data?.role?.connection?.edges)
      .filter((t) => t.id !== ticketId)
      .map((t) => ({
        value: t.id,
        label: t.ticketForm?.data.title || t.draft?.form?.data.title || `Ticket ${t.id}`,
      }));
  }, [relatedTicketsQuery, ticketId]);

  const closeDeleteModal = useCallback(() => setIsDeleteOpen(false), []);
  const openDeleteModal = useCallback(() => setIsDeleteOpen(true), []);

  const hasRelatedTickets = useCallback(
    () => relatedTicketsOptions.length > 0,
    [relatedTicketsOptions]
  );

  const paymentMethodCondition = useCallback(
    (c: Context<TicketSchema>) => c.model.paymentMethod === TicketPaymentMethods.FiatAndCVDS,
    []
  );

  const onChangeModel = useCallback(
    (m: DeepPartial<TicketSchema>) => {
      // first stringify against m is because of undefined fields injected by uniforms
      // second is to validate if its a new ticket, because uniforms injects in m empty arrays at beginning
      // either case we want to add those to initialModelRef
      if (
        JSON.stringify(m) === JSON.stringify(initialModelRef.current) ||
        JSON.stringify(initialModelRef.current) === JSON.stringify({})
      ) {
        initialModelRef.current = m;
      }
      currentModelRef.current = m;
      confirmationModalApi.setHasChanges(currentModelRef.current !== initialModelRef.current);
    },
    [confirmationModalApi]
  );

  const onSave = useCallback(() => {
    const form = JSON.stringify(currentModelRef.current);
    let promise;

    if (!ticketId) {
      promise = issueDraftTicket({ variables: { input: { ownerId: roleId, form } } }).then(
        (res) => {
          if (!res.data || !res.data.payload) {
            return Promise.reject(new Error("Missing payload."));
          }
          const id = res.data.payload.ticket.id;
          const search = new URLSearchParams({ [S.draftTicket.queryVarNames.id]: id }).toString();
          history.replace({ pathname: S.draftTicket.path, search });
          return Promise.resolve(res);
        }
      );
    } else if (!draftId) {
      return console.warn("Impossible state, ticketId present but missing draftId.");
    } else {
      promise = amendDraftTicket({ variables: { input: { draftId, form } } });
    }

    confirmationModalApi.setHasChanges(false);
    promise
      .then(() => {
        toast.success("Your ticket was saved successfuly.");
      })
      .catch((err: ApiError) => {
        confirmationModalApi.setHasChanges(true);
        toast.error(err.message);
      });
  }, [
    ticketId,
    draftId,
    roleId,
    history,
    confirmationModalApi,
    issueDraftTicket,
    amendDraftTicket,
  ]);

  const onValidate = useCallback((model: Partial<TicketSchema>, mError: Maybe<AjvError>) => {
    let e = mError;
    const { minRatePerDay, maxRatePerDay, paymentMethod, fiatPercentage, cvdsPercentage } = model;
    const { deliverables } = model;

    if (isDefined(minRatePerDay) && isDefined(maxRatePerDay)) {
      const invalidRate = minRatePerDay > maxRatePerDay;
      e = appendCustomAjvError(e, invalidRate, "/maxRatePerDay", CreateTicketErrors.RatesPerDay);
    }

    if (isDefined(fiatPercentage) && isDefined(cvdsPercentage)) {
      const invalidSum =
        paymentMethod === TicketPaymentMethods.FiatAndCVDS &&
        fiatPercentage !== null &&
        cvdsPercentage !== null &&
        fiatPercentage + cvdsPercentage !== 100;
      e = appendCustomAjvError(e, invalidSum, "/fiatPercentage", CreateTicketErrors.Percentage);
      e = appendCustomAjvError(e, invalidSum, "/cvdsPercentage", CreateTicketErrors.Percentage);
    }

    deliverables?.forEach(
      (d, idx) =>
        (e = appendCustomAjvError(
          e,
          !hasValidDate(d),
          `/deliverables/${idx}`,
          CreateTicketErrors.DueDate
        ))
    );

    const hasErrors = isDefined(e) && e.details.length > 0;
    if (hasPendingSubmitRef.current && hasErrors) {
      setNeedsScroll(true);
      console.warn("model", model);
      console.warn("errors", e?.details);
    }

    return e;
  }, []);

  const onDelete = useCallback(() => {
    if (!draftId) {
      return;
    }
    deleteDraftTicket({ variables: { input: { draftId } } })
      .then(() => {
        confirmationModalApi.setHasChanges(false);
        toast.success("Your ticket was deleted successfuly.");
        history.replace({ pathname: S.mySprint.path });
      })
      .catch((err: ApiError) => {
        confirmationModalApi.setHasChanges(true);
        toast.error(err.message);
      });
  }, [confirmationModalApi, history, draftId, deleteDraftTicket]);

  const onSubmitBttnClick = useCallback(() => (hasPendingSubmitRef.current = true), []);
  const onSubmit = useCallback(async () => {
    if (!isSuccessState(settings)) {
      return null;
    }

    const form = JSON.stringify(currentModelRef.current);
    let tmpDraftId = draftId;
    let actualTicketId = ticketId;
    if (!actualTicketId) {
      await issueDraftTicket({ variables: { input: { ownerId: roleId, form } } })
        .then((res) => {
          if (!res.data || !res.data.payload) {
            return Promise.reject(new Error("Missing payload."));
          }
          confirmationModalApi.setHasChanges(false);
          tmpDraftId = res.data.payload.draftTicket.id;
          actualTicketId = res.data.payload.ticket.id;
          const search = new URLSearchParams({ [S.draftTicket.queryVarNames.id]: actualTicketId });
          history.replace({ pathname: S.draftTicket.path, search: search.toString() });
        })
        .catch(console.warn);
    } else if (!tmpDraftId) {
      return console.warn("Impossible state, ticketId present but missing draftId.");
    } else {
      await amendDraftTicket({ variables: { input: { draftId: tmpDraftId, form } } })
        .then(() => confirmationModalApi.setHasChanges(false))
        .catch(console.warn);
    }

    if (!tmpDraftId || !actualTicketId) {
      return console.warn("Can't submit the ticket, missing the ticketId or the draftId.");
    }

    const id = tmpDraftId;
    const transmittedAt = new Date();
    const { chainId } = sessionState;

    setSealFieldsLoading(true);
    const sealData = await sealFieldsQuery(QUERY, { id: actualTicketId });
    setSealFieldsLoading(false);

    if (!sealData) {
      return toast.error("Failed to fetch the data for the signature.");
    }

    const fields = { ...sealData.fields, ticketForm: { data: currentModelRef.current } };
    const prevSig = draftQueryRes.data?.payload?.seal?.signature;
    const envelope = ticketEnvelope(chainId, fields, transmittedAt, "publish", prevSig);
    const text = digestFormData(envelope, true);

    chainApi
      .sign(chainState, text)
      .then((ethSignature) => {
        const input = { draftId: id, ethSignature, ticketPublishType, transmittedAt };
        return publishDraftTicket({ variables: { input } });
      })
      .then(() => {
        toast.success("Your ticket was published successfuly.");
        confirmationModalApi.setHasChanges(false);
        history.push(S.mySprint.path);
      })
      .catch((err: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (err.message.includes("User denied message signature")) {
          return;
        }
        toast.error(err.message);
      });
  }, [
    chainApi,
    amendDraftTicket,
    roleId,
    ticketId,
    chainState,
    history,
    draftId,
    draftQueryRes,
    issueDraftTicket,
    publishDraftTicket,
    sealFieldsQuery,
    sessionState,
    settings,
    confirmationModalApi,
  ]);

  useEffect(() => {
    setTimeout(() => setFirstRun(false), 600);
  }, []);

  if (firstRun) {
    return (
      <Grid columns="16">
        <Grid.Column width="10">
          <FormLoader />
        </Grid.Column>
      </Grid>
    );
  } else if (error) {
    return <ErrorMessages errors={[error.message]} />;
  }

  return (
    <AutoForm
      id="create-ticket-form"
      schema={bridge}
      onChangeModel={onChangeModel}
      onSubmit={onSubmit}
      model={draftQueryRes.data?.payload?.draft?.form?.data}
      showInlineError
      onValidate={onValidate}
    >
      <Grid columns="2">
        <Grid.Row>
          <Grid.Column width="10">
            <Ref innerRef={contextRef}>
              <Segment style={segmentStyle}>
                <Title />
                <CustomTextField
                  name="title"
                  label="Title for your ticket"
                  errorMessage="This field is mandatory and should be less than 100 characters."
                />
                <Header size="huge">Description</Header>
                <CustomLongTextField
                  name="scope"
                  label="Describe the work required; define scope in terms of timing, resources and business areas involved; define any boundaries."
                  errorMessage="This field is mandatory and should be less than 1000 characters."
                />
                <RadioField
                  name="assurance"
                  label="Do you want assurance? Assurance provides Expert support drafting the ticket, evaluating bids and review of the final deliverable and costs 5% of the CVDS committed to the ticket."
                  errorMessage="This field is mandatory"
                  transform={readableTicketAnswer}
                />
                <Header size="huge">Objectives</Header>
                <RadioField
                  name="executionDate"
                  label="When would you like ticket delivery to begin?"
                  errorMessage="This field is mandatory."
                  transform={readableTicketExecutionDate}
                />
                <RadioField
                  name="importance"
                  label="How important is this ticket for your sprint roadmap?"
                  errorMessage="This field is mandatory."
                  transform={readableTicketImportance}
                />
                <CustomLongTextField
                  name="acceptanceCriteria"
                  label="Describe acceptance criteria for the ticket at a high level"
                  errorMessage="This field is mandatory and should be less than 1000 characters."
                />
                <Header size="huge">Deliverables</Header>
                <DeliverableListField
                  name="deliverables"
                  label="Define deliverables for your ticket."
                  errorMessage="This field is mandatory. You can create a maximum of 30 deliverables."
                />
                <Header size="huge">Audience</Header>
                {occupationTree && (
                  <NestedTagsField
                    name="occupations"
                    label="What kind of occupation(s) are you looking for?"
                    collapsedText="Occupations"
                    placeholder="Search occupations"
                    errorMessage="This field is mandatory."
                    options={occupationTree}
                    namespace={TagNamespace.Occupations}
                  />
                )}
                <RadioField
                  name="engagementLevel"
                  label="What level of engagement will you require?"
                  errorMessage="This field is mandatory."
                  transform={readableTicketEngagementLevel}
                />
                <RadioField
                  name="duration"
                  label="Over what time period do you expect the work to be delivered?"
                  errorMessage="This field is mandatory."
                  transform={readableTicketDuration}
                />
                {skillsTree && (
                  <NestedTagsField
                    name="skills"
                    label="What technical skills are required for this ticket?"
                    collapsedText="Technical skills"
                    placeholder="Search skills"
                    options={skillsTree}
                    namespace={TagNamespace.Skills}
                  />
                )}
                <CheckboxListField
                  name="expertAttributes"
                  label="What kind of attributes would support successful delivery of this ticket? (Select up to 5 attributes)"
                  errorMessage="This field is mandatory."
                  options={expertAttributesOptions}
                />
                <RadioField
                  name="isOpenToRemoteWork"
                  label="Are you open to working remotely?"
                  errorMessage="This field is mandatory."
                  transform={readableTicketAnswer}
                />
                <CustomLongTextField
                  name="skillsAdditionalInfo"
                  label="Provide any additional information about about the type of skills needed."
                  errorMessage="This field should be less than 300 characters."
                />
                <Header size="huge">Payment</Header>
                <p>
                  <b>
                    Note that VAT may be chargeable depending on the status of the provider and will
                    be added if appropriate.
                  </b>
                </p>
                <RadioField
                  name="paymentMethod"
                  label="How would you like to pay for this ticket?"
                  errorMessage="This field is mandatory."
                  transform={readableTicketPaymentMethods}
                />
                <DisplayIf condition={paymentMethodCondition}>
                  <section>
                    <p>
                      <b>
                        What proportion of the total delivery cost would you like to pay in CVDS vs
                        fiat?
                      </b>
                    </p>
                    <CustomNumField
                      name="fiatPercentage"
                      label="Fiat %"
                      placeholder="Enter number"
                      errorMessage="This field is mandatory, fiat % and CVDS % should sum to 100%."
                    />
                    <CustomNumField
                      name="cvdsPercentage"
                      label="CVDS %"
                      placeholder="Enter number"
                      errorMessage="This field is mandatory, fiat % and CVDS % should sum to 100%."
                    />
                  </section>
                </DisplayIf>
                <br />
                <CustomNumField
                  name="daysOfWork"
                  label="Estimated number of work days"
                  placeholder="Enter number"
                  errorMessage="This field is mandatory. Delivery for a ticket can last up to 360 days."
                />
                <p>
                  <b>What is your estimated minimum and maximum spend per day?</b>
                </p>
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <CustomNumField
                        name="minRatePerDay"
                        label="Minimum"
                        placeholder="Enter number"
                        errorMessage="This field is mandatory. The max rate must be equal to or more than the min rate."
                        showCVDSIcon={true}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <CustomNumField
                        name="maxRatePerDay"
                        label="Maximum"
                        placeholder="Enter number"
                        errorMessage="This field is mandatory. The max rate must be equal to or more than the min rate."
                        showCVDSIcon={true}
                      />
                      <InsufficientFundsErrorField />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <DisplayIf condition={hasRelatedTickets}>
                  <>
                    <Header size="huge">Related tickets</Header>
                    <CheckboxListField
                      name="relatedTickets"
                      label="Select all tickets which are related (ie. dependent, blocked by, etc) the ticket you are defining."
                      options={relatedTicketsOptions}
                    />
                  </>
                </DisplayIf>
                <Header size="huge">Terms</Header>
                <CustomMultipleUploadField
                  name="terms"
                  label="Supply contractual terms or licenses, non disclosure agreements, expected contract types and payment terms, or other with which the bidder would be expected to comply."
                  acceptedFiles={[".pdf"]}
                />
                <Header size="huge">Visibility</Header>
                <p>
                  <b>
                    Optionally filter out members whom you don't want to view this ticket. Without
                    filtering, your ticket will be published to all members of Consilience Ventures.
                  </b>
                </p>
                <Visibility
                  name="deniedMembers"
                  label="Select members you don't want to view this ticket"
                />
                <Header size="huge">Additional information</Header>
                <CustomLongTextField
                  name="additionalInformation"
                  label="Provide bidders with additional background information that would help the bidder understand the project's needs, including architectural diagrams, studies that have been performed, or a project plan."
                  errorMessage="This field should be less than 1000 characters."
                />
                <CustomMultipleUploadField
                  name="additionalDocuments"
                  label="Provide additional documents or further information (max 3 documents of any type)"
                  acceptedFiles={[".pdf"]}
                />
                <br />
                <BoolField
                  name="consentToMattermostAlerts"
                  label="I consent to receive Mattermost alerts related to my ticket."
                  errorMessage="This field is mandatory."
                />
                <BoolField
                  name="consentToShareEmail"
                  label="I consent to share my primary email address with members who bid on my ticket."
                  errorMessage="This field is mandatory."
                />
                <CustomErrorFields keywords={["additionalProperties"]} />
                <br />
                <SignatureButton
                  type="submit"
                  floated="right"
                  onClick={onSubmitBttnClick}
                  content="Publish"
                  loading={mutationLoading}
                />
                <br />
              </Segment>
            </Ref>
          </Grid.Column>
          <Grid.Column width="6" floated="left">
            <TicketSummaryField ticket={initialTicketSummary} />
            <div className="DraftOptions">
              <div className="DraftOptions-top-section">Draft</div>
              <div className="DraftOptions-bottom-section">
                {onSave && (
                  <SaveButton
                    type="button"
                    color="blue"
                    onClick={onSave}
                    content="Save"
                    loading={mutationLoading}
                  />
                )}
                {ticketId && !draftQueryRes.data?.payload?.hydratedAt && (
                  <Modal
                    className="CustomClose"
                    onClose={closeDeleteModal}
                    open={isDeleteOpen}
                    size="mini"
                    onOpen={openDeleteModal}
                    closeOnDimmerClick={false}
                    closeIcon
                    trigger={<div className="DraftOptions-bottom-section-link">Delete</div>}
                  >
                    <Modal.Header>Before you continue</Modal.Header>
                    <Modal.Content>
                      <p>Are you sure you want to delete this draft? </p>
                      <Button onClick={closeDeleteModal} loading={mutationLoading}>
                        No
                      </Button>
                      <Button
                        primary
                        floated="right"
                        content="Yes"
                        loading={mutationLoading}
                        onClick={onDelete}
                      />
                    </Modal.Content>
                  </Modal>
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AutoForm>
  );
});

const expertAttributesOptions = Object.values(ExpertAttributes).map((v) => ({
  label: readableExpertAttributes(v),
  value: v.toString(),
}));
