import { MouseEventHandler, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { Shortcuts as S } from "../../../routing";
import { assertUnreachable, isEnum } from "../../../types";
import { EntityKind } from "../../../types/onboard";
import { LinkedOrWall } from "../../elements/walls/chain";
import { EnrolledOrWall, EnrolledRoute } from "../../elements/walls/session";
import { AdminApplicationsTab } from "./AdminApplicationsTab";
import { AdminUsersTab } from "./AdminUsersTab";
import { AdminUserDetail } from "./AdminUserDetail";
import { AdminExpertDetail } from "./AdminExpertDetail";

export enum Tabs {
  Users = "users",
  Experts = "experts",
  Startups = "startups",
}

export const Administration = () => {
  const breadcrumbApi = useBreadcrumbApi();
  const history = useHistory();
  const { pathname } = useLocation();

  // The route `S.administration` is not accessible, it's used only to add breadcrumbs to all subroutes,
  // so this hook makes sure that if someone tries to reach it we redirect to a default, for now that's `Users`.
  useEffect(() => {
    if (pathname === S.administration.path) {
      history.replace({ pathname: S.administrationUsers.path });
    }
  }, [pathname, history]);

  const activeTab = useMemo(() => {
    switch (pathname) {
      case S.administrationUsers.path:
        return Tabs.Users;
      case S.administrationStartups.path:
        return Tabs.Startups;
      case S.administrationExperts.path:
        return Tabs.Experts;
    }
  }, [pathname]);

  // Add breadcrumbs.
  useEffect(() => {
    const onTabChange: MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
      if (isEnum(currentTarget.id, Tabs) && currentTarget.id !== activeTab) {
        const tab = currentTarget.id;
        switch (tab) {
          case Tabs.Users:
            return history.push({ pathname: S.administrationUsers.path });
          case Tabs.Startups:
            return history.push({ pathname: S.administrationStartups.path });
          case Tabs.Experts:
            return history.push({ pathname: S.administrationExperts.path });
        }
        assertUnreachable(tab);
      }
    };

    const usersClassname = `panel${activeTab === Tabs.Users ? " active" : ""}`;
    const applicationsClassname = `panel${activeTab === Tabs.Experts ? " active" : ""}`;
    const startupsClassname = `panel${activeTab === Tabs.Startups ? " active" : ""}`;

    const customTitle = `Administration - ${
      activeTab === Tabs.Users ? "Users" : activeTab === Tabs.Startups ? "Startups" : "Experts"
    }`;

    const breadcrumbFooter = (
      <div className="Breadcrumb-children-wrapper">
        <div className="Panes">
          <div className={usersClassname} id={Tabs.Users} onClick={onTabChange}>
            Users
          </div>
          <div className={startupsClassname} id={Tabs.Startups} onClick={onTabChange}>
            Startup Applications
          </div>
          <div className={applicationsClassname} id={Tabs.Experts} onClick={onTabChange}>
            Expert Applications
          </div>
        </div>
      </div>
    );

    // Only add breadcrumbs if there is one active tab, meaning we are in one of the 3 subroutes.
    if (!!activeTab) {
      breadcrumbApi.addCustomTitle(customTitle);
      breadcrumbApi.showBreadcrumbs(false);
      breadcrumbApi.addFooter(breadcrumbFooter);
    }
    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeFooter();
    };
  }, [activeTab, breadcrumbApi, history]);

  return (
    <>
      {/* Administration tabs. */}
      <EnrolledRoute exact path={S.administrationUsers.path}>
        <EnrolledOrWall>
          {() => <LinkedOrWall>{() => <AdminUsersTab />}</LinkedOrWall>}
        </EnrolledOrWall>
      </EnrolledRoute>
      <EnrolledRoute exact path={S.administrationStartups.path}>
        <EnrolledOrWall>
          {() => (
            <LinkedOrWall>{() => <AdminApplicationsTab kind={EntityKind.Startup} />}</LinkedOrWall>
          )}
        </EnrolledOrWall>
      </EnrolledRoute>
      <EnrolledRoute exact path={S.administrationExperts.path}>
        <EnrolledOrWall>
          {() => (
            <LinkedOrWall>{() => <AdminApplicationsTab kind={EntityKind.Provider} />}</LinkedOrWall>
          )}
        </EnrolledOrWall>
      </EnrolledRoute>

      {/* Administration details. */}
      <EnrolledRoute exact path={S.administrationUser.path}>
        <EnrolledOrWall>
          {() => <LinkedOrWall>{() => <AdminUserDetail />}</LinkedOrWall>}
        </EnrolledOrWall>
      </EnrolledRoute>

      <EnrolledRoute exact path={S.administrationExpert.path}>
        <EnrolledOrWall>
          {(ss) => <LinkedOrWall>{() => <AdminExpertDetail sessionState={ss} />}</LinkedOrWall>}
        </EnrolledOrWall>
      </EnrolledRoute>
    </>
  );
};
