import { PublicOnboard } from "../../types/onboard";
import { CustomDropdownSelectionField } from "../CustomDropdownSelectionField";
import { PreselectionEvaluationScoreOptions } from "./_types";

interface Props {
  readonly onboard: PublicOnboard;
}

export const ViabilityForm = ({ onboard }: Props) => {
  const name = onboard.fullName || "this startup";

  return (
    <>
      <div className="VotingSection-title">
        <div className="VotingSection-title-text">VIABILITY</div>
      </div>
      <div className="VotingSection-grid">
        <div className="VotingSection-row">
          <div>
            <p>
              <b>
                Does {name} understand the paying customer or other key actors involved in the sale?
              </b>
            </p>
            <p className="VotingSection-scale">
              Scale: 1. No qualitative or quantitative info on the paying actor. - 5. If B2C: Clear,
              deep segmentation identifying the early adopters / evangelists. If B2B: Understanding
              of the difference between the User and Purse-holder, and their different priorities.
              Full marks for understanding motivation and factors affecting buying decisions.
            </p>
          </div>
          <CustomDropdownSelectionField
            name="knowledgeOfCustomers"
            label={null}
            placeholder="-"
            search={false}
            multiple={false}
            compact={true}
            dropdownOptions={PreselectionEvaluationScoreOptions}
            errorMessage="This field is mandatory."
          />
        </div>

        <div className="VotingSection-row">
          <div>
            <p>
              <b>
                How well does {name} understand their financial metrics (e.g. revenue growth,
                potential cost base, unit economics, etc.)?
              </b>
            </p>
            <p className="VotingSection-scale">
              Scale: 1. Little understanding of how to price the product or how much the product
              will cost to deliver to the customer. - 5. Some basic unit-based costing in place inc.
              expenses, salaries etc. Maybe financial model, key financial or North Star Metric in
              place.
            </p>
          </div>
          <CustomDropdownSelectionField
            name="knowledgeOfFinancialMetrics"
            label={null}
            placeholder="-"
            search={false}
            multiple={false}
            compact={true}
            dropdownOptions={PreselectionEvaluationScoreOptions}
            errorMessage="This field is mandatory."
          />
        </div>
      </div>
    </>
  );
};
