import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { BaseTicketDeliverable, TicketDeliverableState } from "../../types/ticket";
import { DeliverableUpdates } from "./GetWorkplaceDeliverables";

export const MUTATION = gql`
  mutation UpdateDeliverableStatus($input: NewTickettingDeliverableProgressUpdateInput!) {
    payload: newTickettingDeliverableProgressUpdate(input: $input) {
      deliverable {
        id
        state
        dueDate
        completedAt
        startedAt
        progressUpdates {
          id
          progressUpdateDate
          progressUpdateStatus
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: NewTickettingDeliverableProgressUpdateInput;
}

interface NewTickettingDeliverableProgressUpdateInput {
  readonly deliverableId: string;
  readonly progressStatus: TicketDeliverableState;
}

export interface Result {
  readonly payload?: NewTickettingDeliverableProgressUpdatePayload;
}

interface NewTickettingDeliverableProgressUpdatePayload {
  readonly startedAt?: string;
  readonly completedAt?: string;
  readonly ticket: BaseTicketDeliverable;
  readonly progressUpdates: DeliverableUpdates[] & Identifiable;
}
