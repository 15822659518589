import { gql } from "@apollo/client";
import { PAGEINFO_FRAGMENT } from "./PageInfo";

export const PROPOSAL_FRAGMENT = gql`
  fragment Proposal on Proposal {
    id
    choices
    kind
    openUntil
    question
    sheepProtected
    tally {
      choice
      count
    }
    votable {
      ... on Onboard {
        id
      }
    }
  }
`;

export const PROPOSAL_CONNECTION_FRAGMENT = gql`
  fragment ProposalConnection on ProposalConnection {
    edges {
      cursor
      node {
        ...Proposal
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${PROPOSAL_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;
