import { Link } from "react-router-dom";
import { Shortcuts as S } from "../../../routing";
import { Tabs } from "../Network";

const search = new URLSearchParams({ [S.network.queryVarNames.tab]: Tabs.Experts }).toString();
const linksTo = `${S.network.path}?${search}`;

export const ExpertOnboardingBreadcrumb = () => {
  return (
    <div className="ExpertOnboarding-breadcrumb">
      <h2>Expert Onboarding</h2>
      <span>Help expand our ecosystem by participating in Expert Onboarding.</span>
      <br />
      <div className="ExpertOnboarding-breadcrumb-link">
        <Link to={linksTo}>View Expert Network</Link>
      </div>
    </div>
  );
};
