import { gql } from "@apollo/client";
import { Identifiable } from "../types";
import { EntityKind, EntityType } from "../types/onboard";
import { Connection } from "../types/relay";

export const QUERY = gql`
  query UserRoles($fulltext: String) {
    users(enroled: true, first: 100, fulltext: $fulltext) {
      edges {
        node {
          id
          fullName
          roles(first: 100) {
            edges {
              node {
                id
                type
                kind
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly fulltext: string;
}

export interface Role extends Identifiable {
  readonly type: EntityType;
  readonly kind: EntityKind;
  readonly fullName: string;
}

export interface UserWithRoles extends Identifiable {
  readonly fullName: string;
  readonly roles: Connection<Role>;
}

export interface Result {
  readonly users?: Connection<UserWithRoles>;
}
