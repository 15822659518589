import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { useOverlayApi } from "../../contexts/Overlay";
import { usePageContentProvider } from "../../contexts/PageContent/Provider";
import { utils } from "../../utils/utils";

interface ContextType {
  readonly scrollToTop: () => void;
  readonly scrollToTopError: () => void;
}
export const Context = createContext<ContextType>({} as ContextType);
export const useScrollToTopApi = () => useContext(Context);

const ScrollToTop = ({ children, location: { pathname } }: any) => {
  const overlayApi = useOverlayApi();
  const { pageContentId } = usePageContentProvider();

  const scrollToTop = useCallback(() => {
    const ele = document.getElementById(pageContentId);
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }, [pageContentId]);

  const scrollToTopError = useCallback(() => {
    utils.timer(150).then(() => {
      const firstError = document.getElementsByClassName("ui red basic pointing label")[0];
      const maxInputCharError = document.getElementsByClassName("InputFieldCharCount--maxed")[0];

      if (firstError && firstError.parentElement) {
        return firstError.parentElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
      } else if (maxInputCharError && maxInputCharError.parentElement) {
        return maxInputCharError.parentElement.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }
    });
  }, []);

  useEffect(() => {
    const ele = document.getElementById(pageContentId);
    if (ele) {
      ele.scrollTo(0, 0);
      overlayApi.hide();
    }
  }, [pathname, overlayApi, pageContentId]);

  const contextValue = useMemo(
    () => ({ scrollToTop, scrollToTopError }),
    [scrollToTop, scrollToTopError]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default withRouter(ScrollToTop);
