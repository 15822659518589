import { useMemo, useReducer, useEffect as useEffectDI, PropsWithChildren } from "react";
import { useApolloClient } from "@apollo/client";
import { provideDependencies } from "react-magnetic-di";
import { Context } from "./Context";
import { GenericStateTypes } from "../Generic/state";
import { State, reducer } from "./state";
import { ApiError, extractErrorMessages } from "../../types";
import { GenericActionTypes } from "../Generic/actions";
import { perform } from "../../api/Settings";

export const SettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const client = useApolloClient();
  const { initialState, useEffect } = SettingsProvider.dependencies();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: GenericActionTypes.Fetch });

    perform(client)
      .then((res) => dispatch({ type: GenericActionTypes.SetResult, result: res.data.settings }))
      .catch((err: ApiError) => {
        console.warn(err);
        dispatch({ type: GenericActionTypes.SetError, errors: extractErrorMessages(err) });
      });
  }, [client, dispatch]);

  const providerValue = useMemo(() => ({ state }), [state]);

  return <Context.Provider value={providerValue} children={children} />;
};

export interface Dependencies {
  readonly initialState: State;
  readonly useEffect: typeof useEffectDI;
}
SettingsProvider.dependencies = provideDependencies<Dependencies>({
  initialState: { type: GenericStateTypes.None },
  useEffect: useEffectDI,
});
