import { gql } from "@apollo/client";
import { PROFILE_FRAGMENT } from "./Profile";
import { PAGEINFO_FRAGMENT } from "./PageInfo";

export const ROLE_FRAGMENT = gql`
  fragment Role on Role {
    id
    canInvest
    canInvestUntil
    ethAddress
    extraAttributes
    fullName
    kind
    avatarUrl
    onboard {
      id
    }
    profile {
      ...Profile
    }
    type
    user {
      id
    }
    tags(scope: DEFAULT) {
      id
      assignable
      description
      label
      ns
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const ROLE_CONNECTION_FRAGMENT = gql`
  fragment RoleConnection on RoleConnection {
    edges {
      cursor
      node {
        ...Role
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${ROLE_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;
