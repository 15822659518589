import { useState, useCallback, memo } from "react";
import { useMutation } from "@apollo/client";
import { Grid, Form, Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { isEmpty, isString, ApiError, noResultErrorFor } from "../../../../types";
import { extractErrorMessages } from "../../../../types";
import { OrganisationProfileInput } from "../../../../api/accounts/UpdateOrganisationProfile";
import { MUTATION, Variables, Result } from "../../../../api/accounts/UpdateOrganisationProfile";
import { ImageUploader } from "../../../elements/ImageUploader";
import { OrganisationProfile } from "../../../../types/profile";
import { utils } from "../../../../utils/utils";

enum FormIds {
  LinkedIn = "@Profile/UserSection/LinkedIn",
  Name = "@Profile/RoleFields/Name",
  Headline = "@Profile/RoleFields/Headline",
}

interface State extends OrganisationProfileInput {
  readonly avatarUrl?: string;
}

const buildInitialState = (p: OrganisationProfile, avatarUrl: string): State => ({
  avatarUrl,
  linkedinProfileUrl: p.linkedinProfileUrl,
  headline: p.headline,
  name: p.name,
});

interface Props {
  readonly roleId: string;
  readonly profile: OrganisationProfile;
  readonly avatarURL: string;
}
export const OrganisationProfileForm = memo(({ roleId, profile, avatarURL }: Props) => {
  const [state, setState] = useState<State>(() => buildInitialState(profile, avatarURL));
  const { linkedinProfileUrl, headline, name, avatar, avatarUrl } = state;
  const [linkedinProfileUrlError, setLinkedinProfileUrlError] = useState<string>();
  const hasChanges =
    profile.linkedinProfileUrl !== linkedinProfileUrl ||
    profile.headline !== headline ||
    profile.name !== name ||
    !!avatar;

  const [updateProfile, { loading }] = useMutation<Result, Variables>(MUTATION);

  const onInputChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setState((s) => ({ ...s, [target.name]: isEmpty(target.value) ? undefined : target.value }));
  }, []);

  const onLinkedinProfileUrlChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setLinkedinProfileUrlError(undefined);
      setState((s) => ({ ...s, linkedinProfileUrl: value }));
    },
    []
  );

  const onFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? event.target.files[0] : undefined;
    if (!newFile) {
      return;
    } else if (!utils.isValidLogoType(newFile.type)) {
      return toast.error("Invalid file format. Accepted file types: PNG, JPEG and JPG.");
    } else if (newFile.size > utils.LOGO_MAX_SIZE) {
      return toast.error("File exceeds the size limit (1MB).");
    }

    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onloadend = () => {
      const result = reader.result;
      if (isString(result)) {
        setState((s) => ({ ...s, avatar: { upload: newFile }, avatarUrl: result }));
      }
    };
  }, []);

  const save = useCallback(() => {
    if (linkedinProfileUrl && !utils.LINKEDIN_REG_EXP.test(linkedinProfileUrl)) {
      setLinkedinProfileUrlError("Invalid URL.");
      return;
    }

    const variables: Variables = {
      input: { subjectId: roleId, profile: { avatar, name, headline, linkedinProfileUrl } },
    };

    updateProfile({ variables })
      .then((res) => {
        if (!res.data || !res.data.payload || !res.data.payload.subject.profile) {
          return Promise.reject(noResultErrorFor("Update Profile"));
        }
        const avtUrl = res.data.payload.subject.avatarUrl;
        const p = res.data.payload.subject.profile;
        setState(buildInitialState(p, avtUrl));
        toast.success("Changes saved.");
      })
      .catch((error: ApiError) => toast.error(extractErrorMessages(error).join()));
  }, [linkedinProfileUrl, avatar, headline, name, roleId, updateProfile]);

  return (
    <Form>
      <Grid columns="2">
        <Grid.Row columns="2">
          <Grid.Column width="4">
            <ImageUploader
              image={avatarUrl}
              accept={utils.VALID_LOGO_TYPES.join(", ")}
              onFileChange={onFileChange}
            />
          </Grid.Column>
          <Grid.Column width="12">
            <Form.Input
              id={FormIds.LinkedIn}
              name="linkedinProfileUrl"
              label="LinkedIn profile URL (must begin with https://):"
              placeholder="https://www.linkedin.com/in/jane-doe"
              value={linkedinProfileUrl || ""}
              onChange={onLinkedinProfileUrlChange}
              maxLength={100}
              error={linkedinProfileUrlError}
            />
            <Form.Input
              id={FormIds.Name}
              label="Name"
              name="name"
              placeholder="Role Name"
              value={name || ""}
              onChange={onInputChange}
              maxLength={255}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <Form.Input
              id={FormIds.Headline}
              label="1-liner"
              name="headline"
              placeholder="Describe your role in more detail"
              maxLength={50}
              value={headline || ""}
              onChange={onInputChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column textAlign="right">
            <Button primary disabled={loading || !hasChanges} loading={loading} onClick={save}>
              Save Details
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
});
