import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ApiError, extractErrorMessages, Maybe } from "../../types";
import { AjvError } from "../../utils/Ajv";
import { ExpertEvaluationSchema, ExpertEvaluationSchemaLabels as Labels } from "./_types";
import { bridge } from "./ExpertEvaluationSchema";
import { AnyAutoForm as AutoForm } from "../../types/uniforms";
import { SignatureButton } from "../../components/elements/SignatureButton";
import { CustomLongTextField } from "../CustomLongTextField";
import { CustomHorizontalRadioRaterField } from "../CustomHorizontalRadioRaterField";
import { CustomDynamicTextField } from "../CustomDynamicTextField";
import { useScrollToTopApi } from "../../components/elements/ScrollToTop";
import { MUTATION, Result, Variables } from "../../api/onboards/SubmitMAEvaluation";
import { useSealFieldsQuery } from "../../hooks/useSealFieldsQuery";
import { mutualAssessmentEnvelope } from "../../api/seals/MutualAssessmentSeal";
import { SealQueryResult, QUERY } from "../../api/seals/MutualAssessmentSeal";
import { Enrolled } from "../../contexts/Session/state";
import { toast } from "react-toastify";
import { digestFormData } from "../../types/OnboardForm";
import { AdminExpertOnboard } from "../../api/admin/AdminExpertOnboard";
import { PublicOnboardViewedByMember } from "../../types/onboard";
import { Migrated } from "../../migrations/_helpers";
import { MutualAssessmentSealFields } from "../../api/seals/_fragments/MutualAssessmentSealFields";
import { useChainApi, useChainState } from "../../contexts/Chain";
import { Button, Grid, Image, Modal } from "semantic-ui-react";
import { Tabs } from "../../components/pages/ExpertOnboarding/ExpertDetails/ExpertDetailsPublic";
import { Shortcuts as S } from "../../routing";
import approvedIcon from "../../assets/approved-icon.svg";

const tabVarName = S.expert.queryVarNames.tab;

interface Props {
  readonly onboard: Migrated<AdminExpertOnboard | PublicOnboardViewedByMember>;
  readonly sessionState: Enrolled;
  readonly refetchMainQuery: () => Promise<unknown>;
  readonly isMainLoading: boolean;
}

export const ExpertEvaluationForm = (props: Props) => {
  const { sessionState, onboard, refetchMainQuery, isMainLoading } = props;
  const chainApi = useChainApi();
  const chainState = useChainState();
  const { scrollToTopError, scrollToTop } = useScrollToTopApi();
  const history = useHistory();
  const [submitEvaluation, { loading }] = useMutation<Result, Variables>(MUTATION);
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const [sealFieldsLoading, setSealFieldsLoading] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const isLoading = sealFieldsLoading || loading || isMainLoading;

  const onOpen = useCallback(() => setShowCompleteModal(true), []);
  const onClose = useCallback(() => {
    refetchMainQuery().then(() => {
      scrollToTop();
      const newSearch = new URLSearchParams({ [S.expert.queryVarNames.id]: onboard.id });
      newSearch.set(tabVarName, Tabs.Evaluation);
      return history.replace({ search: newSearch.toString() });
    });
  }, [history, onboard, scrollToTop, refetchMainQuery]);

  const onValidate = useCallback((_: ExpertEvaluationSchema, e: Maybe<AjvError>) => {
    console.warn(e);
    return e;
  }, []);

  const onSubmit = useCallback(
    async (model: ExpertEvaluationSchema) => {
      const transmittedAt = new Date();
      const { chainId } = sessionState;
      const id = onboard.extraFields.mutualAssessment?.id;
      if (!id) {
        return;
      }

      setSealFieldsLoading(true);
      const sealData = await sealFieldsQuery(QUERY, { id });
      setSealFieldsLoading(false);

      if (!sealData) {
        return toast.error("Failed to fetch the data for the signature.");
      }
      const form = { data: model };

      const fields: MutualAssessmentSealFields = { ...sealData.fields, form };
      const envelope = mutualAssessmentEnvelope(chainId, fields, transmittedAt);
      const text = digestFormData(envelope, true);
      chainApi
        .sign(chainState, text)
        .then(async (ethSignature) => {
          return submitEvaluation({
            variables: {
              input: {
                form: JSON.stringify(model),
                mutualAssessmentId: id,
                ethSignature,
                transmittedAt,
              },
            },
          }).then(() => {
            onOpen();
          });
        })
        .catch((err: ApiError) => {
          // Discard error message thrown when the user clicks cancel on the MM popup.
          if (err.message.includes("User denied message signature")) {
            return;
          }
          toast.error(extractErrorMessages(err).join(", "));
        });
    },
    [sealFieldsQuery, submitEvaluation, onOpen, sessionState, onboard, chainApi, chainState]
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={12} mobile={16} tablet={16}>
          <div className="WrapperSection">
            <h2>
              <b>Evaluation form</b>
            </h2>
            <br />
            <div className="ExpertEvaluationForm">
              <Modal
                className="ExpertEvaluationForm-complete-modal CustomClose"
                closeIcon
                size={"small"}
                open={showCompleteModal}
                onClose={onClose}
              >
                <Image src={approvedIcon} />
                <div className="ExpertEvaluationForm-complete-modal-title">Thank you!</div>
                <p>
                  We really appreciate your time and effort in supporting the growth of our
                  community. This is crucial for the success of our portfolio companies and the
                  ecosystem as a whole.
                </p>
                <p>
                  Once both Seconder evaluations have been submitted, we'll forward the nominee's
                  details and your evaluation to members of the Community Building Committee for a
                  final vote on the outcome of this applicant.
                </p>
                <div className="ExpertEvaluationForm-complete-modal-bttns">
                  <Button color="blue" primary onClick={onClose} loading={isMainLoading}>
                    Okay
                  </Button>
                </div>
              </Modal>
              <AutoForm schema={bridge} showInlineError onValidate={onValidate} onSubmit={onSubmit}>
                <CustomLongTextField
                  name="expectedContribution"
                  label={Labels.expectedContribution}
                  errorMessage="This field is mandatory."
                />
                <CustomLongTextField
                  name="individualSkills"
                  label={Labels.individualSkills}
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="availableToParticipate"
                  label={Labels.availableToParticipate}
                  minValueLabel="No time to invest"
                  maxValueLabel="A few days a month with scope to increase as the platform grows in size"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="networkSynergies"
                  label={Labels.networkSynergies}
                  minValueLabel="They are a solo expert with few connections"
                  maxValueLabel="A super-connector"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="meetPortfolioNeeds"
                  label={Labels.meetPortfolioNeeds}
                  minValueLabel="They have experience in a very niche skill, and it's not clear what type/stage of startup might need this"
                  maxValueLabel="They have deep expertise in a skill that is critical for nearly every startup"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="tacitKnowledge"
                  label={Labels.tacitKnowledge}
                  minValueLabel="Their expertise is not particularly shareable or they don't seem willing to contribute"
                  maxValueLabel="They are already actively sharing knowledge within their networks and it is actionable"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="startupExperience"
                  label={Labels.startupExperience}
                  minValueLabel="They are not a referral and don't appear to be networked"
                  maxValueLabel="You 'knew' them before you met them OR they came highly recommended from several Members"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="ecosystemBalance"
                  label={Labels.ecosystemBalance}
                  minValueLabel="An 'Advisor' or 'Doer' with very little actual experience with startups"
                  maxValueLabel="Someone who has significant experience being both an 'Advisor' and 'Doer' for startups"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="priceRange"
                  label={Labels.priceRange}
                  minValueLabel="Unreasonably high prices"
                  maxValueLabel="Flexible and startup friendly pricing"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="geographicSynergy"
                  label={Labels.geographicSynergy}
                  minValueLabel="Limited to a region of a country"
                  maxValueLabel="Globally connected"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="startupKnowledge"
                  label={Labels.startupKnowledge}
                  minValueLabel="Theoretical knowledge only OR no meaningful experience working with founders"
                  maxValueLabel="Extensive (years) of experience and demonstrated success working directly with startups"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="tokenEconomicsKnowledge"
                  label={Labels.tokenEconomicsKnowledge}
                  minValueLabel="They are demonstrably afraid of / not interested in token-based compensation"
                  maxValueLabel="They ask informed questions"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="cvdsKnowledge"
                  label={Labels.cvdsKnowledge}
                  minValueLabel="They did not display any interest"
                  maxValueLabel="Highly interested"
                  errorMessage="This field is mandatory."
                />
                <CustomHorizontalRadioRaterField
                  name="expertScale"
                  label={Labels.expertScale}
                  minValueLabel=" They do not appear legit; I have serious concerns about their experience, knowledge, and or capability"
                  maxValueLabel="They seem awesome, I'd be happy to work alongside them"
                  errorMessage="This field is mandatory."
                />
                <CustomDynamicTextField
                  name="positiveStatements"
                  label={Labels.positiveStatements}
                  min={0}
                  max={3}
                  errorMessage="Submit up to 3 items."
                />
                <CustomDynamicTextField
                  name="negativeStatements"
                  label={Labels.negativeStatements}
                  min={0}
                  max={3}
                  errorMessage="Submit up to 3 items."
                />
                <CustomLongTextField
                  name="personalOpinion"
                  label={Labels.personalOpinion}
                  errorMessage="This field is mandatory."
                />
                <CustomLongTextField name="additionalInfo" label={Labels.additionalInfo} />
                <SignatureButton content="Submit" onClick={scrollToTopError} loading={isLoading} />
              </AutoForm>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
