import { CSSProperties, useMemo } from "react";
import { isProviderRole, isStartupRole, Role } from "../../../../types/role";
import { TagNamespace } from "../../../../types/labelQuery";
import { StartupTagsForm } from "../../../../schemas/profile/StartupTagsForm";
import { ExpertTagsForm } from "../../../../schemas/profile/ExpertTagsForm";

const divHeightStyle: CSSProperties = { height: "250px" };

interface TagsState {
  readonly maturities: ReadonlyArray<string>;
  readonly sectors: ReadonlyArray<string>;
  readonly occupations: ReadonlyArray<string>;
  readonly skills: ReadonlyArray<string>;
}

interface Props {
  readonly role: Role;
}
export const RoleTagsForm = ({ role }: Props) => {
  const tags = useMemo(() => buildInitialTags(role), [role]);
  const { maturities, sectors, occupations, skills } = tags;

  return (
    <>
      {isStartupRole(role) && (
        <StartupTagsForm maturity={maturities[0]} sectors={sectors} role={role} />
      )}
      {isProviderRole(role) && (
        <ExpertTagsForm
          maturities={maturities}
          occupations={occupations}
          sectors={sectors}
          skills={skills}
          role={role}
        />
      )}
      <div style={divHeightStyle} />
    </>
  );
};

const buildInitialTags = ({ tags }: Role): TagsState => ({
  maturities: tags.filter((t) => t.ns === TagNamespace.Maturities).map((t) => t.label),
  sectors: tags.filter((t) => t.ns === TagNamespace.Sectors).map((t) => t.label),
  occupations: tags.filter((t) => t.ns === TagNamespace.Occupations).map((t) => t.label),
  skills: tags.filter((t) => t.ns === TagNamespace.Skills).map((t) => t.label),
});
