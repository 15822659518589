import { gql } from "@apollo/client";
import { ReceivedOnboard } from "../../types/onboard";
import { RECEIVED_ONBOARD_FRAGMENT } from "../_fragments/Onboard";

export const QUERY = gql`
  query Onboard($onboardId: ID!) {
    onboard: node(id: $onboardId) {
      ... on Onboard {
        ...ReceivedOnboard
      }
    }
  }
  ${RECEIVED_ONBOARD_FRAGMENT}
`;

export interface Variables {
  readonly onboardId: string;
}

export interface Result {
  readonly onboard?: ReceivedOnboard;
}
