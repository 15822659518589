import { Grid, Header } from "semantic-ui-react";
import { connectField, Context, HTMLFieldProps } from "uniforms";
import { RadioField } from "uniforms-semantic";
import { CustomNumField } from "../../schemas/tickets/components/CustomNumField";
import { CustomTextField } from "../../schemas/CustomTextField";
import { CustomLongTextField } from "../../schemas/CustomLongTextField";
import { DatePickerField } from "../../schemas/tickets/components/DatePickerField";
import { readableYesNoAnswer, YesNoAnswer } from "../../schemas/_types";
import { DisplayIf } from "../../schemas/DisplayIf";
import { CustomListDelField } from "../../schemas/CustomListDelField";
import { DeliverablesPlanSchema, PlannedDeliverable } from "./_types";
import { CSSProperties, useCallback } from "react";

const headerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
};

interface Props extends HTMLFieldProps<PlannedDeliverable, HTMLDivElement> {}

export const CustomDeliverableField = connectField(({ value, name }: Props) => {
  const splittedName = name.split(".");
  const fieldIndex = parseInt(splittedName[splittedName.length - 1], 10);

  const title = value?.title || `Deliverable ${fieldIndex + 1}`;
  const checkAssociatePayment = useCallback(
    ({ model }: Context<DeliverablesPlanSchema>) =>
      !!model.deliverables && model.deliverables[fieldIndex]?.associatePayment === YesNoAnswer.Yes,
    [fieldIndex]
  );

  return (
    <Grid>
      <Grid.Column width="16">
        <div style={headerStyle}>
          <Header>{title}</Header>
          <CustomListDelField name="" />
        </div>
      </Grid.Column>
      <Grid.Column width="14">
        <CustomTextField name="title" placeholder="Enter up to 50 characters" />
        <DatePickerField name="dueDate" format="DD MM YYYY" placeholder="DD-MM-YYYY" />
        <CustomLongTextField name="description" placeholder="Enter up to 500 characters" />
        <RadioField
          name="associatePayment"
          label="Would you like to associate payment to this deliverable"
          transform={readableYesNoAnswer}
        />
        <DisplayIf condition={checkAssociatePayment}>
          <CustomNumField
            name="totalPayment"
            label="% of total payment"
            placeholder="Enter number"
            errorMessage="Enter the % of total payment you'd like to associate with this deliverable"
          />
        </DisplayIf>
      </Grid.Column>
    </Grid>
  );
});
