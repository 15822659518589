import { useCallback, useEffect, useMemo } from "react";
import { Segment } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { requireEnrolled, useSessionState } from "../../../contexts/Session";
import { QUERY, Variables, Result } from "../../../api/onboards/ViewAllOnboards";
import { Shortcuts as S } from "../../../routing";
import { EntityKind, EntityType, OnboardState, OnboardStatus } from "../../../types/onboard";
import { useQuery } from "@apollo/client";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { extractErrorMessages } from "../../../types";
import { LoaderWithMargin } from "../../Loader";
import { StartupOnboardCard } from "./StartupOnboardCard";
import { nodesFromEdges } from "../../../types/relay";
import { PageContentInfinityScroll } from "../../InfinityScrollWrapper";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { StartupDealflowBreadcrumb } from "./StartupDealflowBreadcrumb";
import { beingReviewedDescription, generalAssessmentDescription } from "./StartupOnboardsRowList";
import { preSelectionDescription, unsuccessfulDescription } from "./StartupOnboardsRowList";

const first = 12;

export const ViewAllStartupOnboards = () => {
  const location = useLocation();
  const sessionState = useSessionState();
  const breadcrumbApi = useBreadcrumbApi();

  const { roleId } = requireEnrolled(sessionState);

  const state: ReadonlyArray<OnboardState> = useMemo(() => {
    if (location.pathname === S.startupDealflowBeingReviewed.path) {
      return [OnboardState.AwaitingEnableVoting];
    } else if (location.pathname === S.startupDealflowPreSelection.path) {
      return [OnboardState.InPreselection, OnboardState.TimedOut];
    } else if (location.pathname === S.startupDealflowGeneralAssessment.path) {
      return [OnboardState.UnderReview];
    } else if (location.pathname === S.startupDealflowUnsuccessful.path) {
      return [OnboardState.Closed];
    } else {
      return [];
    }
  }, [location.pathname]);

  const status: ReadonlyArray<OnboardStatus> | undefined = useMemo(() => {
    if (location.pathname === S.startupDealflowUnsuccessful.path) {
      return [OnboardStatus.Rejected];
    } else {
      return undefined;
    }
  }, [location.pathname]);

  const queryVars: Variables = useMemo(() => {
    return {
      roleId,
      first,
      state,
      status,
      type: [EntityType.Organisation],
      kind: [EntityKind.Startup],
    };
  }, [roleId, state, status]);

  const queryData = useQuery<Result, Variables>(QUERY, {
    variables: queryVars,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-only",
  });
  const { data, loading, error, fetchMore } = queryData;
  const pageInfo = data?.onboards?.pageInfo;

  const cards = useMemo(
    () =>
      nodesFromEdges(data?.onboards?.edges).map((o) => (
        <StartupOnboardCard key={o.id} onboard={o} />
      )),
    [data]
  );
  const [title, description] = useMemo(() => {
    if (location.pathname === S.startupDealflowBeingReviewed.path) {
      return ["Being reviewed", beingReviewedDescription];
    } else if (location.pathname === S.startupDealflowPreSelection.path) {
      return ["Pre-selection", preSelectionDescription];
    } else if (location.pathname === S.startupDealflowGeneralAssessment.path) {
      return ["General Assessment", generalAssessmentDescription];
    } else if (location.pathname === S.startupDealflowUnsuccessful.path) {
      return ["Unsuccesful", unsuccessfulDescription];
    } else {
      return ["", ""];
    }
  }, [location.pathname]);

  useEffect(() => {
    breadcrumbApi.showBreadcrumbs(false);
    breadcrumbApi.addBody(<StartupDealflowBreadcrumb />);

    return () => {
      breadcrumbApi.showBreadcrumbs(true);
      breadcrumbApi.removeBody();
    };
  }, [breadcrumbApi]);

  const onNext = useCallback(() => {
    if (!fetchMore || !pageInfo?.hasNextPage) {
      return;
    }
    fetchMore({
      variables: { ...queryVars, after: pageInfo.endCursor },
      updateQuery: (prev, { fetchMoreResult: fetchRes }) => {
        if (!fetchRes) {
          return prev;
        } else if (!fetchRes.onboards || !fetchRes.onboards.pageInfo) {
          return fetchRes;
        }
        const prevEdges = prev.onboards?.edges || [];
        const newEdges = fetchRes.onboards.edges || [];
        const newOnboards = fetchRes.onboards;
        return {
          ...fetchRes,
          onboards: { ...newOnboards, edges: [...prevEdges, ...newEdges] },
        };
      },
    });
  }, [fetchMore, pageInfo, queryVars]);

  if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (loading && !data) {
    return (
      <div>
        <LoaderWithMargin />
      </div>
    );
  } else if (!data || !data.onboards) {
    return <>No data</>;
  } else if (cards.length <= 0) {
    return <Segment>No startups</Segment>;
  }

  return (
    <div className="StartupDealflow-step-right">
      <div className="StartupDealflow-step-right-wrap">
        <span className="StartupDealflow-step-right-wrap-label secondary">{title}</span>
      </div>
      <span className="StartupDealflow-step-right-description">{description}</span>
      <PageContentInfinityScroll
        dataLength={cards.length}
        next={onNext}
        hasMore={!!pageInfo?.hasNextPage}
        loader={loading ? <LoaderWithMargin /> : null}
      >
        <div className="StartupDealflow-step-right-list-infinite">{cards}</div>
      </PageContentInfinityScroll>
    </div>
  );
};
