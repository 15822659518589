import { gql } from "@apollo/client";
import { digestObj } from "../../types/OnboardForm";
import { BallotSealFields } from "./_fragments/BallotSealFields";
import { ProposalSealFields, PROPOSAL_SEAL_FIELDS } from "./_fragments/ProposalSealFields";

// A ballot seal query wouldn't make sense because we dont have the ballot id yet.
// However a proposal seal query is possible and required.
export const QUERY = gql`
  query ProposalSeal($id: ID!) {
    fields: node(id: $id) {
      ...ProposalSealFields
    }
  }
  ${PROPOSAL_SEAL_FIELDS}
`;

export interface SealQueryResult {
  readonly fields: ProposalSealFields;
}

export const ballotEnvelope = (chainId: number, fields: BallotSealFields, transmittedAt: Date) => ({
  chain_id: chainId.toString(),
  header: "Election Ballot",
  meta: { previous_seal_signature: null },
  subject: ballotSubject(fields, transmittedAt),
});

const ballotSubject = (fields: BallotSealFields, transmittedAt: Date): BallotSealFields => ({
  ...fields,
  form: digestObj(fields.form, false) as any,
  proposal: {
    ...fields.proposal,
    votable: {
      ...fields.proposal.votable,
      form: digestObj(fields.proposal.votable.form, false) as any,
    },
  },
  transmittedAt: transmittedAt.toISOString().split(".")[0] + "Z",
});
