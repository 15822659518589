import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { DeliverablesPlanSchema } from "../../schemas/deliverablesPlan/_types";
import { JsonDocumentVersioned } from "../../types/jsonDocument";

export const MUTATION = gql`
  mutation AmendDraftTicketPlan($input: AmendDraftTicketPlanInput!) {
    payload: amendDraftTicketPlan(input: $input) {
      ticket {
        id
        draftPlan {
          id
          insertedAt
          updatedAt
          form {
            id
            data
            schemaVersion
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly input: AmendDraftTicketPlanInput;
}

interface AmendDraftTicketPlanInput {
  readonly draftId: string;
  readonly form: string;
}

export interface Result {
  readonly payload?: AmendDraftTicketDeliverablePayload;
}

interface DraftPlan extends Identifiable {
  readonly insertedAt: string;
  readonly updatedAt: string;
  readonly form: JsonDocumentVersioned<DeliverablesPlanSchema>;
}

interface AmendDraftTicketDeliverablePayload {
  readonly ticket: {
    readonly draftPlan: DraftPlan;
  } & Identifiable;
}
