import { CSSProperties } from "react";
import { Button, ButtonProps, Image } from "semantic-ui-react";
import blueArrow from "../../assets/blue-chevron.svg";

const buttonStyle: CSSProperties = { display: "flex" };
const arrowStyle: CSSProperties = { alignSelf: "flex-end", marginLeft: "16px" };

export const ContinueButton = (props: ButtonProps) => (
  <Button {...props} type="submit" basic color="blue" style={buttonStyle}>
    Continue <Image src={blueArrow} style={arrowStyle} />
  </Button>
);
