import { gql } from "@apollo/client";
import { BidSchema } from "../../schemas/bids/_types";
import { TicketSchema } from "../../schemas/tickets/_types";
import { DeliverablesPlanSchema } from "../../schemas/deliverablesPlan/_types";
import { Identifiable } from "../../types";
import { JsonDocument } from "../../types/jsonDocument";
import { TicketState, TicketStatus } from "../../types/ticket";
import { Connection } from "../../types/relay";
import { PAGEINFO_FRAGMENT } from "../_fragments/PageInfo";

export const QUERY = gql`
  query DeliverablesOnTicket($id: ID!) {
    payload: node(id: $id) {
      ... on TickettingTicket {
        id
        state
        status
        owner {
          id
        }
        ticketForm(migrate: true) {
          id
          data
          schemaVersion
        }
        planForm(migrate: true) {
          id
          data
          schemaVersion
        }
        winningBid {
          id
          owner {
            id
            fullName
          }
          form(migrate: true) {
            id
            data
            schemaVersion
          }
        }

        planFeedback(first: 4) {
          edges {
            node {
              id
              feedback
              insertedAt
              owner {
                id
                fullName
                avatarUrl
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PAGEINFO_FRAGMENT}
`;

export interface Variables {
  readonly id: string;
}

export interface Result {
  readonly payload?: TicketPayload;
}

interface TicketPayload extends Identifiable {
  readonly state: TicketState;
  readonly status: TicketStatus;
  readonly owner: Identifiable;
  readonly planForm?: JsonDocument<DeliverablesPlanSchema>;
  readonly ticketForm?: JsonDocument<TicketSchema>;
  readonly winningBid?: WinningBid;
  readonly planFeedback?: Connection<PlanFeedback>;
}

interface WinningBid extends Identifiable {
  readonly owner: { readonly fullName: string } & Identifiable;
  readonly form?: JsonDocument<BidSchema>;
}

export interface PlanFeedback extends Identifiable {
  readonly feedback?: string;
  readonly insertedAt: Date;
  readonly owner: {
    readonly fullName: string;
    readonly avatarUrl: string;
  } & Identifiable;
}
