import { useCallback, useState, CSSProperties, MouseEvent, PropsWithChildren } from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { Button, Container, Image, Modal } from "semantic-ui-react";
import { Shortcuts as S } from "../../../routing";
import { isSuccessState } from "../../../contexts/Generic";
import { useSettingsState } from "../../../contexts/Settings";
import approvedIcon from "../../../assets/approved-icon.svg";
import { MUTATION, Result, Variables } from "../../../api/onboards/OpenOnboard";
import { isStartupOnboard, OnboardState, readableTypeAndKind } from "../../../types/onboard";
import { OnboardStatus, ReceivedOnboard } from "../../../types/onboard";
import { utils } from "../../../utils/utils";
import { ApiError, extractErrorMessages, noResultErrorFor } from "../../../types";
import { Settings } from "../../../contexts/Settings/state";
import { RoleIcon } from "../../../types/badges";

const iconStyle: CSSProperties = {
  width: "16px",
  height: "16px",
  maxHeight: "16px",
  maxWidth: "16px",
};

interface Props {
  readonly onboard: ReceivedOnboard;
}

export const ReceivedInviteCard = ({ onboard }: Props) => {
  const history = useHistory();
  const settings = useSettingsState();
  const { sender, state, fullName, type, kind } = onboard;
  const [isOpen, setIsOpen] = useState(false);

  const [openOnboard, { loading }] = useMutation<Result, Variables>(MUTATION);

  const displayBttns =
    state === OnboardState.Pending ||
    state === OnboardState.InCompletion ||
    state === OnboardState.AwaitingSignature;

  const search = new URLSearchParams({ [S.application.queryVarNames.id]: onboard.id }).toString();
  const linksTo = `${S.application.path}?${search}`;

  const onGeneralClose = useCallback(() => setIsOpen(false), []);
  const onDeclineClick = useCallback(() => setIsOpen(true), []);
  const handleRejectInvite = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();

      openOnboard({ variables: { input: { accept: false, onboardId: onboard.id } } })
        .then((res) => {
          if (!res.data || !res.data.payload || !res.data.payload.onboard) {
            return Promise.reject(noResultErrorFor("Decline Onboard"));
          }
          onGeneralClose();
        })
        .catch((e: ApiError) => {
          toast.error(extractErrorMessages(e).join(", "));
        });
    },
    [openOnboard, onboard, onGeneralClose]
  );

  const handleAcceptInvite = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      openOnboard({ variables: { input: { accept: true, onboardId: onboard.id } } })
        .then((r) => {
          if (!r.data || !r.data.payload || !r.data.payload.onboard) {
            return Promise.reject(noResultErrorFor("Accept Onboard"));
          }
          return history.push({ pathname: S.application.path, search });
        })
        .catch((e: ApiError) => {
          toast.error(extractErrorMessages(e).join(", "));
        });
    },
    [openOnboard, history, onboard, search]
  );

  const CardWrapper = useCallback(
    ({ children }: PropsWithChildren<unknown>) => {
      const isPending = onboard.state === OnboardState.Pending;
      return isPending ? <>{children}</> : <Link to={linksTo}>{children}</Link>;
    },
    [onboard.state, linksTo]
  );

  return (
    <div className="ReceivedInviteCard">
      <CardWrapper>
        <Image className="AvatarPicture" floated="left" src={sender.avatarUrl} circular />
        <div className="ReceivedInviteCard-top-section">
          <div className="ReceivedInviteCard-top-section-title">
            {readableTypeAndKind(type, kind)} role application for <b>{fullName || "you"}</b>
          </div>
          {generateStatusLabels(onboard)}
        </div>
        <div className="ReceivedInviteCard-description">
          <div className="ReceivedInviteCard-description-info">
            <div className="ReceivedInviteCard-description-info-role">
              {generateRoleLabel(onboard)}
            </div>
            <div className="ReceivedInviteCard-description-info-date">
              {isSuccessState(settings) && generateSentAndExpireDate(onboard, settings.result)}
            </div>
          </div>
          {displayBttns && (
            <div>
              {state === OnboardState.Pending && (
                <Modal
                  className="CustomClose"
                  onClose={onGeneralClose}
                  size="tiny"
                  open={isOpen}
                  closeIcon
                  closeOnDimmerClick={false}
                  trigger={
                    <Button basic color="red" onClick={onDeclineClick} loading={loading}>
                      Decline
                    </Button>
                  }
                >
                  <Modal.Header>You are about to decline this invite</Modal.Header>
                  <Modal.Content>
                    {sender.fullName} has sent you an invite to join Consilience Ventures as{" "}
                    {isStartupOnboard(onboard) ? "a Startup" : "an Expert"}. <br />
                    Are you sure you'd like to decline?
                    <br />
                    <br />
                    <Container>
                      <Button primary onClick={onGeneralClose} content="Cancel" loading={loading} />
                      <Button
                        onClick={handleRejectInvite}
                        floated="right"
                        content="Decline"
                        loading={loading}
                      />
                    </Container>
                  </Modal.Content>
                </Modal>
              )}
              <Button
                color="blue"
                // If the state is not pending this event will bubble up and end up on the Link above.
                onClick={state === OnboardState.Pending ? handleAcceptInvite : undefined}
                loading={loading}
              >
                {state === OnboardState.Pending && "Start Application"}
                {state === OnboardState.InCompletion && "Edit Application"}
                {state === OnboardState.AwaitingSignature && "Sign Application"}
              </Button>
            </div>
          )}
        </div>
      </CardWrapper>
    </div>
  );
};

const generateStatusLabels = (onboard: ReceivedOnboard) => {
  const { status, state, submittedAt, acceptedAt, closedAt } = onboard;
  let dateLabel;
  let label;
  let circleLabelJSX;
  if (state === OnboardState.Pending) {
    return null;
  } else if (state === OnboardState.InCompletion) {
    dateLabel = dateFormat(acceptedAt, "HH:MM | dd/mm/yy");
    label = "You accepted this invite";
    circleLabelJSX = <div className="StatusCircle yellow" />;
  } else if (status === OnboardStatus.Canceled) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Canceled";
    circleLabelJSX = <div className="StatusCircle black" />;
  } else if (status === OnboardStatus.Declined) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "You declined this invite";
    circleLabelJSX = <div className="StatusCircle dark-red" />;
  } else if (state === OnboardState.AwaitingEnableVoting) {
    label = "Being reviewed";
    circleLabelJSX = <div className="StatusCircle grey" />;
  } else if (
    state === OnboardState.InPreselection ||
    state === OnboardState.AwaitingSeconders ||
    state === OnboardState.TimedOut ||
    state === OnboardState.UnderReview ||
    state === OnboardState.InMutualAssessment ||
    state === OnboardState.InCommitteeReview
  ) {
    dateLabel = dateFormat(submittedAt, "HH:MM | dd/mm/yy");
    label = "Evaluation in progress";
    circleLabelJSX = <div className="StatusCircle yellow" />;
  } else if (status === OnboardStatus.Rejected) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Application rejected";
    circleLabelJSX = <div className="StatusCircle dark-red" />;
  } else if (status === OnboardStatus.Approved) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Accepted into the ecosystem";
    circleLabelJSX = <Image src={approvedIcon} style={iconStyle} />;
  } else if (status === OnboardStatus.Expired) {
    dateLabel = dateFormat(closedAt, "HH:MM | dd/mm/yy");
    label = "Expired";
    circleLabelJSX = <div className="StatusCircle black" />;
  } else {
    return null;
  }
  const dateLabelJSX = (
    <div className="ReceivedInviteCard-top-section-right-labels-date">{dateLabel}</div>
  );
  const labelJSX = <div className="ReceivedInviteCard-top-section-right-labels-label">{label}</div>;

  return (
    <div className="ReceivedInviteCard-top-section-right-labels">
      {dateLabelJSX}
      {labelJSX}
      {circleLabelJSX}
    </div>
  );
};

const generateSentAndExpireDate = ({ insertedAt, state }: ReceivedOnboard, settings: Settings) => {
  const label = `Invite received ${utils.around(insertedAt)}`;
  if (state === OnboardState.Pending) {
    const s = settings.core;
    const expiresAt = new Date(new Date(insertedAt).valueOf() + s.invitation_ttl * 1000);
    return label + ` | Expires ${utils.around(expiresAt)}`;
  }
  return label;
};

const generateRoleLabel = ({ type, kind }: ReceivedOnboard) => {
  return (
    <>
      <RoleIcon type={type} kind={kind} verticalAlign="middle" />
      <b>Your role: {readableTypeAndKind(type, kind)}</b>
    </>
  );
};
