import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { utils } from "../../../utils/utils";
import { Enrolled } from "../../../contexts/Session/state";
import { QUERY, Result, Variables } from "../../../api/tickets/DiscussionThreadsForRole";
import { LoaderWithMargin } from "../../Loader";
import { ErrorMessages } from "../../elements/ErrorMessages";
import { extractErrorMessages, noResultErrorFor } from "../../../types";
import { nodesFromEdges } from "../../../types/relay";
import { Question } from "../../elements/tickets/qna/Question";
import { DiscussionThread } from "../../../api/tickets/DiscussionThreadsOnIsDiscussable";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { TabTitles } from "./index";

interface Props {
  readonly sessionState: Enrolled;
}

export const NewQuestions = ({ sessionState }: Props) => {
  const breadcrumbApi = useBreadcrumbApi();
  const since = useMemo(() => utils.dateSince(2), []);
  const { data, loading, error } = useQuery<Result, Variables>(QUERY, {
    variables: { roleId: sessionState.roleId, since },
  });

  useEffect(() => {
    breadcrumbApi.addCustomTitle(TabTitles.NewQuestions);

    return () => breadcrumbApi.removeCustomTitle();
  }, [breadcrumbApi]);

  if (loading) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={extractErrorMessages(error)} />;
  } else if (!data || !data.node) {
    return <ErrorMessages errors={extractErrorMessages(noResultErrorFor("Bids Questions"))} />;
  }

  const discussables = nodesFromEdges(data.node.tickets?.edges);
  const questions = discussables.reduce(
    (acc, curr) => [
      ...acc,
      ...nodesFromEdges(curr.thread?.edges).map((t) => ({
        thread: t,
        message: curr.seal?.message,
      })),
    ],
    [] as ReadonlyArray<{ readonly thread: DiscussionThread; readonly message?: string }>
  );

  return (
    <>
      <div className="ComponentHeader">Questions</div>
      {questions.length === 0 && <>You have no new questions on your ticket(s).</>}
      {questions.map(({ thread, message }) => (
        <Question
          key={thread.id}
          question={thread}
          discussableMessage={message}
          showPrivacyIcon={true}
          sessionState={sessionState}
        />
      ))}
      <br />
    </>
  );
};
