import { StartupSteps } from "../../../types/OnboardForm";
import { TermsAndConditionsStartup } from "./TermsAndConditionsStartup";
import { StartupProfileForm } from "./StartupProfileForm";
import { OnboardFormProps } from "../../../components/pages/Dashboard/applications/application";

export const StartupForm = ({ onboard }: OnboardFormProps) => {
  if (!onboard.form || !onboard.form.data) {
    return <>No onboard form data available</>;
  }
  const { currentStep } = onboard.form.data;

  return (
    <>
      {(currentStep === undefined || currentStep === StartupSteps.Terms) && (
        <TermsAndConditionsStartup onboard={onboard} />
      )}
      {currentStep === StartupSteps.PreSelectionData && <StartupProfileForm onboard={onboard} />}
    </>
  );
};
