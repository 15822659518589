import React, { CSSProperties, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Card, Feed, Segment, Image, Divider, Icon } from "semantic-ui-react";
import { nodesFromEdges } from "../../types/relay";
import { QUERY, Variables, Result } from "../../api/tickets/DeliverablesPlanFeedback";
import { ErrorMessages } from "../../components/elements/ErrorMessages";
import { LoaderWithMargin } from "../../components/Loader";
import dateFormat from "dateformat";
import { useCallback } from "react";

const first: number = 4;
const cardStyle: CSSProperties = { minWidth: "242px", maxWidth: "242px" };
const boldStyle: CSSProperties = { fontWeight: 600 };
const noMarginStyle: CSSProperties = { marginRight: "0px", marginTop: "0px" };
const avatarStyle: CSSProperties = { width: "36px", height: "36px", maxWidth: "unset" };
const titleStyle: CSSProperties = {
  fontFamily: "Lato",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "22px",
  letterSpacing: "-0.5799999833106995px",
  textAlign: "left",
  color: "#5C5C5C",
  marginBottom: "26px",
};
const feedbackTextStyle: CSSProperties = {
  fontFamily: "Lato",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#5C5C5C",
  wordBreak: "break-all",
};
const viewMoreStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  color: "#0085ff",
};

interface Props {
  readonly ticketId: string;
}

export const PlanFeedbackCard = ({ ticketId }: Props) => {
  const { data, loading, error, fetchMore } = useQuery<Result, Variables>(QUERY, {
    variables: { id: ticketId, first },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    notifyOnNetworkStatusChange: true,
  });

  const { feedbackList, hasNextPage } = useMemo(() => {
    if (!data || !data.payload || !data.payload.planFeedback) {
      return { feedbackList: [], hasNextPage: false };
    }

    return {
      feedbackList: nodesFromEdges(data.payload.planFeedback.edges),
      hasNextPage: data.payload.planFeedback.pageInfo.hasNextPage,
    };
  }, [data]);

  const onViewMore = useCallback(() => {
    const after = data?.payload?.planFeedback?.pageInfo.endCursor || "abc";
    if (!hasNextPage || !after) {
      return;
    }

    fetchMore({ variables: { id: ticketId, first, after } })
      .then()
      .catch();
  }, [data, hasNextPage, ticketId, fetchMore]);

  if (loading && !data) {
    return <LoaderWithMargin />;
  } else if (error) {
    return <ErrorMessages errors={[error.message]} />;
  } else if (feedbackList.length === 0) {
    return null;
  }

  return (
    <Card as={Segment} className="RightMenu-tickets" style={cardStyle}>
      <Card.Content>
        <div style={titleStyle}>Feedback on this plan</div>
        <Feed>
          {feedbackList.map((v, idx) => (
            <React.Fragment key={v.id}>
              <Feed.Event>
                <Feed.Label style={noMarginStyle}>
                  <Image circular avatar src={v.owner.avatarUrl} style={avatarStyle} />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Date style={boldStyle}>{v.owner.fullName}</Feed.Date>
                  <Feed.Meta style={noMarginStyle}>
                    {dateFormat(v.insertedAt, "HH:MM | dd/mm/yy")}
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>
              <Feed.Extra style={feedbackTextStyle}>{v.feedback}</Feed.Extra>
              {idx < feedbackList.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Feed>
        {loading && <LoaderWithMargin />}
        {!loading && hasNextPage && (
          <div className="CursorOnHover" style={viewMoreStyle} onClick={onViewMore}>
            View more
            <Icon name="angle down" />
          </div>
        )}
      </Card.Content>
    </Card>
  );
};
