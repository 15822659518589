import classnames from "classnames";
import { omit } from "lodash";
import { ChangeEvent } from "react";
import { CSSProperties } from "react";
import { connectField, filterDOMProps } from "uniforms";
import { RadioFieldProps } from "uniforms-semantic";

const extraLabelStyle: CSSProperties = {
  fontSize: "14px",
  lineHeight: "22px",
  color: "#4A4A4A",
  marginTop: "-5px",
};

interface Props extends RadioFieldProps {
  readonly [k: string]: unknown;
  readonly extraLabel?: string;
  readonly minValueLabel?: string;
  readonly maxValueLabel?: string;
  readonly isFlat?: boolean;
}

export const CustomHorizontalRadioRaterField = connectField<Props>(
  ({
    allowedValues,
    className,
    disabled,
    error,
    errorMessage,
    id,
    label,
    name,
    onChange,
    readOnly,
    required,
    showInlineError,
    transform,
    value,
    extraLabel,
    minValueLabel,
    maxValueLabel,
    isFlat,
    ...props
  }: Props) => (
    <div
      className={classnames(className, { disabled, error }, "grouped fields")}
      {...omit(filterDOMProps(props), ["checkboxes"])}
    >
      {label && (
        <div className={classnames({ required }, "field")}>
          <label>{label}</label>
          {extraLabel && <div style={extraLabelStyle}>{extraLabel}</div>}
        </div>
      )}
      <div className="CustomHorizontalRadioRaterField">
        {minValueLabel && maxValueLabel && (
          <div className="CustomHorizontalRadioRaterField-rater">
            <div>1 = {minValueLabel}</div>
            <div>
              {allowedValues?.length} = {maxValueLabel}
            </div>
          </div>
        )}
        <div>
          <div className={`CustomHorizontalRadioRaterField-options${isFlat ? " flat" : ""}`}>
            {allowedValues?.map((item) => {
              const checked = item === value;
              const onItemChange = (ev: ChangeEvent<HTMLInputElement | EventTarget>) => {
                ev.stopPropagation();
                if (!readOnly) {
                  onChange(item);
                }
              };
              const itemLabel = transform ? transform(item) : item;

              return (
                <div className={`field${isFlat ? " flat" : ""}`} key={item} onClick={onItemChange}>
                  <label className={`${isFlat ? " flat" : ""}`}>
                    {checked ? <b>{itemLabel}</b> : itemLabel}
                  </label>
                  <input
                    className="ui radio checkbox"
                    checked={checked}
                    disabled={disabled}
                    id={`${id}-${item}`}
                    name={name}
                    onChange={onItemChange}
                    type="radio"
                  />
                </div>
              );
            })}
          </div>

          {!!(error && showInlineError) && (
            <div className="ui red basic pointing label">{errorMessage}</div>
          )}
        </div>
      </div>
    </div>
  ),
  { kind: "leaf" }
);
