import { gql } from "@apollo/client";
import { Connection, ConnectionVariables } from "../../types/relay";
import { Ballot } from "../../types/ballot";
import { BALLOT_CONNECTION_FRAGMENT } from "../_fragments/Ballot";
import { PROPOSAL_FRAGMENT } from "../_fragments/Proposal";
import { Proposal } from "../../types/proposal";
import { PreselectionProposalChoice } from "../../schemas/preselectionVote/_types";
import { PreselectionVoteSchema } from "../../schemas/preselectionVote/_types";
import { BallotSort } from "../../types/ballotsSort";

export const QUERY = gql`
  query Ballots(
    $onboardId: ID!
    $kind: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $choice: String
    $sortBy: [BallotSort!]
  ) {
    node(id: $onboardId) {
      ... on Onboard {
        id
        proposal(kind: $kind) {
          ...Proposal
          ballots(
            after: $after
            before: $before
            first: $first
            last: $last
            choice: $choice
            sortBy: $sortBy
          ) {
            edges {
              node {
                anonymizableProfile {
                  ... on AnonymousProfile {
                    id
                    fullName
                  }
                  ... on IndividualProfile {
                    id
                    fullName
                  }
                  ... on OrganisationProfile {
                    id
                    fullName
                  }
                }
              }
            }
            ...BallotConnection
          }
        }
      }
    }
  }
  ${PROPOSAL_FRAGMENT}
  ${BALLOT_CONNECTION_FRAGMENT}
`;

export interface Variables extends ConnectionVariables {
  readonly onboardId: string;
  readonly kind: string;
  readonly choice?: PreselectionProposalChoice;
  readonly sortBy?: BallotSort;
}

export interface BallotWithAnonymizableProfile<C> extends Ballot<C, PreselectionVoteSchema> {
  readonly anonymizableProfile: { readonly fullName: string };
}

export interface ProposalWithBallots<C> extends Proposal<C> {
  readonly ballots?: Connection<BallotWithAnonymizableProfile<C>>;
}

export interface Result<C> {
  readonly node?: { readonly proposal?: ProposalWithBallots<C> };
}
