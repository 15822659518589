import React, { CSSProperties } from "react";
import { Modal } from "semantic-ui-react";
import { EvaluationForm } from "../../../../../../schemas/bids/evaluation/EvaluationForm";
import { EvaluationSchema } from "../../../../../../schemas/bids/evaluation/_types";

const modalStyle: CSSProperties = { maxWidth: "610px" };

interface Props {
  readonly onGeneralClose: () => void;
  readonly changeModal: (isBack: boolean) => void;
  readonly setForm: (value: React.SetStateAction<EvaluationSchema | undefined>) => void;
  readonly form: EvaluationSchema | undefined;
  readonly sign: (model: EvaluationSchema) => void;
}

export const ModalEvaluationForm = (props: Props) => {
  const { onGeneralClose, changeModal, setForm, sign, form } = props;
  return (
    <Modal
      className="CustomClose"
      onClose={onGeneralClose}
      open={true}
      style={modalStyle}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>Bid evaluation form</Modal.Header>
      <Modal.Content>
        <EvaluationForm setForm={setForm} changeModal={changeModal} form={form} sign={sign} />
      </Modal.Content>
    </Modal>
  );
};
