type Resolve = (value: HTMLImageElement) => void;
type Reject = (error: Error) => void;

const defaultFileType = ["image/jpg", "image/png", "image/jpeg"];

export const getImageFromFile = (f: File) => {
  const fr = new FileReader();

  return new Promise((resolve: Resolve, reject: Reject) => {
    if (!defaultFileType.includes(f.type)) {
      return reject(
        new Error(
          "Expected an image file of type " +
            defaultFileType.map((s) => s.replace("image/", ".")).join(", ")
        )
      );
    }

    fr.onerror = () => {
      fr.abort();
      reject(new Error("Problem parsing input file."));
    };

    fr.onload = () => {
      const img = new Image();

      img.onload = () => {
        return resolve(img);
      };

      if (fr.result) {
        img.src =
          typeof fr.result === "string"
            ? fr.result
            : String.fromCharCode.apply(null, Array.from(new Uint16Array(fr.result))); // is the data URL because called with readAsDataURL
      } else {
        return reject(new Error("Missing result from File Reader."));
      }
    };

    fr.readAsDataURL(f);
  });
};
