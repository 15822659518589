export interface Order {
  readonly id: string;
  readonly owner: string;
  readonly spender: string;
  readonly reference: string;
  readonly recipient: string;
  readonly amount: bigint;
  readonly createdAt: bigint;
  readonly status: OrderStatus;
}

export enum OrderStatus {
  Pending,
  Approved,
  Rejected,
}
