import { SentOnboard } from "../../../api/onboards/SentOnboard";
import { CoInvestorInviteType } from "../../../schemas/invites/CoInvestorInviteSchema";
import { EarlyProfessionalOrProminentSkillsInviteType } from "../../../schemas/invites/EarlyProfessionalOrProminentSkillsInviteSchema";
import { ExpertBaseInviteType } from "../../../schemas/invites/ExpertBaseInviteSchema";
import { IntroducerInviteType } from "../../../schemas/invites/IntroducerInviteSchema";
import { OrganisationBaseInviteType } from "../../../schemas/invites/OrganisationBaseInviteSchema";
import { StartupInviteType } from "../../../schemas/invites/StartupInviteSchema";
import { JsonDocumentVersioned } from "../../../types/jsonDocument";
import { EntityKind, EntityType } from "../../../types/onboard";

export interface ExpertInviteType
  extends ExpertBaseInviteType,
    Omit<CoInvestorInviteType, "businessCase">,
    Omit<EarlyProfessionalOrProminentSkillsInviteType, "businessCase">,
    Omit<IntroducerInviteType, "businessCase"> {}

export interface OrganisationInviteType
  extends OrganisationBaseInviteType,
    Omit<CoInvestorInviteType, "businessCase">,
    Omit<EarlyProfessionalOrProminentSkillsInviteType, "businessCase">,
    Omit<IntroducerInviteType, "businessCase"> {}

export interface SentExpertOnboard extends SentOnboard {
  readonly invitationForm: JsonDocumentVersioned<ExpertInviteType | Partial<ExpertInviteType>>;
}

export interface SentOrganisationOnboard extends SentOnboard {
  readonly invitationForm: JsonDocumentVersioned<
    OrganisationInviteType | Partial<OrganisationInviteType>
  >;
}

export interface SentStartupOnboard extends SentOnboard {
  readonly invitationForm: JsonDocumentVersioned<StartupInviteType | Partial<StartupInviteType>>;
}

export const isStartupOnboard = (data: SentOnboard): data is SentStartupOnboard =>
  data.kind === EntityKind.Startup;

export const isExpertOnboard = (data: SentOnboard): data is SentExpertOnboard =>
  data.kind === EntityKind.Provider && data.type === EntityType.Individual;

export const isOrganisationOnboard = (data: SentOnboard): data is SentOrganisationOnboard =>
  data.kind === EntityKind.Provider && data.type === EntityType.Organisation;
