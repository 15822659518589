import { useEffect } from "react";
import dateFormat from "dateformat";
import { Divider, Grid } from "semantic-ui-react";
import { useBreadcrumbApi } from "../../../contexts/Breadcrumb";
import { readableBusinessCase } from "../../../schemas/invites/_types";
import { readableType } from "../../../types/onboard";
import { readableOnboardStatusAndColor } from "../../../types/onboard";
import { Migrated } from "../../../migrations/_helpers";
import { Shortcuts as S } from "../../../routing";
import { TagDisplayer } from "../../elements/TagDisplayer";
import { TagNamespace } from "../../../types/labelQuery";
import { AdminExpertOnboard } from "../../../api/admin/AdminExpertOnboard";

export const useAdminExpertDetailsBreadcrumb = (onboard?: Migrated<AdminExpertOnboard>) => {
  const breadcrumbApi = useBreadcrumbApi();
  const businessCase = onboard?.migratedForm?.data.businessCase;

  useEffect(() => {
    breadcrumbApi.addCustomTitle(`Administration - ${onboard?.fullName || "Expert"}`);

    breadcrumbApi.showBreadcrumbs(true);
    breadcrumbApi.addCrumb({
      path: S.administrationExpert.path,
      title: onboard ? `${onboard.fullName}` : "Expert",
    });

    if (!onboard) {
      return;
    }

    const { status, color } = readableOnboardStatusAndColor(onboard);
    breadcrumbApi.addCrumbsExtra(
      <div className="AdminExpertDetailsBreadcrumb-Status">
        <span className="AdminExpertDetailsBreadcrumb-Status-label">Status</span> {status}
        <div className={`StatusCircle ${color}`} />
      </div>
    );

    breadcrumbApi.addHeader(<Divider clearing />);

    const bodyTitle = `Manage Seconders for${onboard ? `: ${onboard.fullName}` : " this Expert"}`;
    breadcrumbApi.addBody(
      <>
        <h2>{bodyTitle}</h2>
        <Grid columns="16">
          <Grid.Column width="5">
            <Grid.Row className="AdminExpertDetailsBreadcrumb-title">Expert type</Grid.Row>
            <Grid.Row className="AdminExpertDetailsBreadcrumb-details">
              {readableType(onboard.type)}
              {businessCase && ` | ${readableBusinessCase(businessCase)}`}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width="3">
            <Grid.Row className="AdminExpertDetailsBreadcrumb-title">Date submitted</Grid.Row>
            <Grid.Row className="AdminExpertDetailsBreadcrumb-details">
              {dateFormat(onboard.submittedAt, "dd/mm/yy")}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width="8">
            <Grid.Row className="AdminExpertDetailsBreadcrumb-title">Sector</Grid.Row>
            <Grid.Row className="AdminExpertDetailsBreadcrumb-details">
              <TagDisplayer
                tags={onboard.migratedForm?.data.sectors}
                namespace={TagNamespace.Sectors}
                cap={2}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </>
    );

    return () => {
      breadcrumbApi.removeCustomTitle();
      breadcrumbApi.removeCrumbsExtra();
      breadcrumbApi.removeHeader();
      breadcrumbApi.removeBody();
    };
  }, [onboard, businessCase, breadcrumbApi]);
};
