import { useCallback, useMemo, useState, useEffect } from "react";
import { Segment, Divider, Image } from "semantic-ui-react";
import { HiddenField } from "uniforms-semantic";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { bridge as EthereumSchemaBridge } from "../schemas/Ethereum";
import { ApiError, extractErrorMessages, isDefined, noResultErrorFor } from "../../../types";
import { SignatureButton } from "../../../components/elements/SignatureButton";
import { useChainState, needsChainWall, useChainApi } from "../../../contexts/Chain";
import { isLinked } from "../../../contexts/Chain/state";
import { OnboardFormProps } from "../../../components/pages/Dashboard/applications/application";
import { EthereumWall } from "../../../components/walls/EthereumWall";
import { MUTATION, Variables, Result } from "../../../api/onboards/FinaliseOnboard";
import { QUERY as MeQuery } from "../../../api/accounts/Me";
import { Shortcuts as S } from "../../../routing";
import { Tabs as MyAreaTabs } from "../../../components/pages/MyArea";
import acceptedOnboardIcon from "../../../assets/accepted-onboard-icon.svg";
import { useSealFieldsQuery } from "../../../hooks/useSealFieldsQuery";
import { QUERY, SealQueryResult, onboardDigest } from "../../../api/seals/OnboardSeal";
import { OnboardSealFields } from "../../../api/seals/_fragments/OnboardSealFields";
import { LoaderWithMargin } from "../../../components/Loader";
import { AnyAutoForm as AutoForm } from "../../../types/uniforms";

export const EthereumForm = ({ onboard }: OnboardFormProps) => {
  const chainState = useChainState();
  const chainApi = useChainApi();
  const [loading, setLoading] = useState(false);
  const [sealData, setSealData] = useState<OnboardSealFields>();
  const sealFieldsQuery = useSealFieldsQuery<SealQueryResult>();
  const [finaliseOnboard] = useMutation<Result, Variables>(MUTATION, {
    refetchQueries: [{ query: MeQuery }],
  });

  useEffect(() => {
    setLoading(true);
    sealFieldsQuery(QUERY, { id: onboard.id })
      .then((res) => setSealData(res?.fields))
      .catch(() => toast.error("Failed to fetch the data for the signature."))
      .finally(() => setLoading(false));
  }, [onboard, sealFieldsQuery]);

  const ethAddress = useMemo(
    () => (isLinked(chainState) ? chainState.account : undefined),
    [chainState]
  );

  const onSubmit = useCallback(async () => {
    if (needsChainWall(chainState) || !ethAddress || !onboard.form || !onboard.form.data) {
      return;
    } else if (!sealData) {
      return;
    }

    const onboardId = onboard.id;

    const transmittedAt = new Date();
    const formWithEthAddress = { data: { ...(sealData.form.data as object), ethAddress } };
    const digest = onboardDigest({ ...sealData, form: formWithEthAddress }, chainState.chainId);

    chainApi
      .sign(chainState, digest)
      .then(async (ethSignature) => {
        const vars: Variables = { input: { ethSignature, onboardId, transmittedAt, ethAddress } };
        return finaliseOnboard({ variables: vars });
      })
      .then((res) => {
        if (!res.data || !res.data.payload) {
          return Promise.reject(noResultErrorFor("SubmitOnboard"));
        }
      })
      .catch((err: ApiError) => {
        // Discard error message thrown when the user clicks cancel on the MM popup.
        if (err.message.includes("User denied message signature")) {
          return;
        }
        const extractedErrors = extractErrorMessages(err);
        const gqlError = err.graphQLErrors.find((el) => el.message === "Invalid form data.");
        let e: string | undefined;

        // Check for known error messages to make them more user friendly.
        if (extractedErrors.includes("eth_address is already in use")) {
          e =
            "A role using this ethereum address already exists in the system. Please make sure you chose a different Ethereum account to associate with this new role.";
        } else if (extractedErrors.includes("signature does not match")) {
          e = "Signatures did not match.";
        } else if (gqlError) {
          const traceRef = gqlError.extensions?.validation_trace_reference;

          e = `An internal error occured while validating your form. Please contact our technical support bugs@consilienceventures.com${
            traceRef ? `, and mention the following trace ID: ${traceRef}` : ""
          }. Thank you for your understanding.`;
        }

        toast.error(isDefined(e) ? e : "Something went wrong.");
      });
  }, [chainState, chainApi, ethAddress, sealData, onboard, finaliseOnboard]);

  if (needsChainWall(chainState) || !isLinked(chainState)) {
    return <EthereumWall />;
  }

  const searchParams = new URLSearchParams({
    [S.myArea.queryVarNames.tab]: MyAreaTabs.Profile,
  }).toString();
  const myAreaLink = `${S.myArea.path}?${searchParams}`;

  return (
    <Segment className="OnboardForm EthereumForm">
      <AutoForm
        schema={EthereumSchemaBridge}
        model={onboard.form?.data}
        showInlineError
        onSubmit={onSubmit}
      >
        <HiddenField name="ethAddress" value={ethAddress} />
        <div className="EthereumForm-titleWrapper">
          <Image src={acceptedOnboardIcon} />
          <p>
            <b>You have been accepted!</b>
          </p>
          <p>Please, sign your application to continue</p>
        </div>
        <p className="EthereumForm-explanation-paragraph">
          We ask you to provide a digital signature on your application details as the last step
          before entering the ecosystem. The below is a raw version of your submitted application
          formatted to be easily ingested by our system. Once you gain full access to the platform
          you'll be able to edit some of this information in <Link to={myAreaLink}>Profile</Link>.
        </p>
        <Divider />
        <div className="EthereumForm-wallet-row">
          <div>
            <p>
              <b>Selected wallet address:</b>
            </p>
            {ethAddress}
          </div>
          <SignatureButton
            type="submit"
            content="Sign Application"
            color="blue"
            loading={loading}
            disabled={loading}
          />
        </div>
        <div className="EthereumForm-preWrapper">
          {loading && <LoaderWithMargin />}
          {sealData && <pre>{JSON.stringify(sealData.form.data, null, 2)}</pre>}
        </div>
      </AutoForm>
    </Segment>
  );
};
