import { gql } from "@apollo/client";
import { Identifiable } from "../../types";
import { Connection } from "../../types/relay";
import { OwnerWithAvatarField } from "./Tickets";

export const QUERY = gql`
  query DiscussionThreadsOnIsDiscussable($discussableId: ID!) {
    node: node(id: $discussableId) {
      id
      ... on IsSealable {
        seal {
          message
        }
      }

      ... on IsDiscussable {
        thread: discussionThreads(topic: "general", first: 10) {
          edges {
            node {
              id
              isLight
              private
              author {
                ...Author
              }

              firstComment {
                ... on DiscussionComment {
                  id
                  title
                  body
                  insertedAt
                  transmittedAt
                  author {
                    ...Author
                  }
                  attachments(first: 2) {
                    ...AttachmentsConnection
                  }
                }
              }

              insertedAt
              updatedAt
              commentCount
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
  }
  fragment Author on Role {
    id
    fullName
    avatarUrl
  }
  fragment AttachmentsConnection on AttachmentConnection {
    edges {
      node {
        id
        downloadUrl
        document {
          id
          uploadedFile {
            id
            filename
          }
        }
      }
    }
  }
`;

export interface Variables {
  readonly discussableId: string;
}

export interface Result {
  readonly node?: DiscusableWithSealAndThread;
}

export interface DiscusableWithSealAndThread extends Identifiable {
  readonly seal?: { readonly message: string };
  readonly thread?: Connection<DiscussionThread>;
}

export interface DiscussionThread extends Identifiable {
  readonly author: OwnerWithAvatarField;
  readonly isLight: boolean;
  readonly private: boolean;
  readonly insertedAt: string;
  readonly updatedAt: string;
  readonly commentCount: number;
  readonly firstComment: DiscussionComment;
}

export interface DiscussionComment extends Identifiable {
  readonly title: string;
  readonly body: string;
  readonly attachments: Connection<Attachment>;
  readonly insertedAt: string;
  readonly transmittedAt?: string;
  readonly author: OwnerWithAvatarField;
}

export interface Attachment extends Identifiable {
  readonly downloadUrl?: string;
  readonly document: {
    readonly uploadedFile: {
      readonly filename: string;
    };
  } & Identifiable;
}
