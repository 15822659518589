import { JSONSchemaType } from "ajv";
import JSONSchemaBridge from "uniforms-bridge-json-schema";
import { utils } from "../../utils/utils";
import { AbstainReason, PreselectionProposalChoice } from "./_types";
import { PreselectionVoteSchema, DueDiligenceAreas, PreselectionEvaluationScore } from "./_types";

export const schema: JSONSchemaType<PreselectionVoteSchema> = {
  type: "object",
  properties: {
    comment: { type: "string", maxLength: 1000 },
    isReviewCommitteeVolunteer: {
      type: "boolean",
    },
    dueDiligenceAreas: {
      type: "array",
      items: { type: "string" },
    },
    voteChoice: {
      type: "string",
      enum: Object.values(PreselectionProposalChoice) as (
        | PreselectionProposalChoice.Yes
        | PreselectionProposalChoice.No
      )[], // Uniforms is tripping on this complex type.
    },
    criticalPainPoint: {
      type: "string",
      enum: Object.values(PreselectionEvaluationScore),
    },
    traction: { type: "string", enum: Object.values(PreselectionEvaluationScore) },
    marketDifferentiation: {
      type: "string",
      enum: Object.values(PreselectionEvaluationScore),
    },
    scalability: { type: "string", enum: Object.values(PreselectionEvaluationScore) },
    knowledgeOfCustomers: {
      type: "string",
      enum: Object.values(PreselectionEvaluationScore),
    },
    knowledgeOfFinancialMetrics: {
      type: "string",
      enum: Object.values(PreselectionEvaluationScore),
    },
    reason: { type: "string", enum: Object.values(AbstainReason) },
  },
  required: ["isReviewCommitteeVolunteer", "voteChoice"],

  allOf: [
    {
      if: {
        properties: {
          isReviewCommitteeVolunteer: { type: "boolean", enum: [true] },
        },
      },
      then: {
        properties: {
          dueDiligenceAreas: {
            type: "array",
            items: { type: "string", enum: Object.values(DueDiligenceAreas) },
            minItems: 1,
          },
        },
        required: ["dueDiligenceAreas"],
      },
    },
    {
      if: {
        properties: {
          voteChoice: {
            type: "string",
            enum: [PreselectionProposalChoice.Yes, PreselectionProposalChoice.No],
          },
        },
      },
      then: {
        required: [
          "criticalPainPoint",
          "traction",
          "marketDifferentiation",
          "scalability",
          "knowledgeOfCustomers",
          "knowledgeOfFinancialMetrics",
        ],
      },
      else: {
        required: ["reason"],
      },
    },
  ],
};

export const bridge = new JSONSchemaBridge(schema, utils.createValidator(schema));
