export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum RoleSortFields {
  NAME = "FULL_NAME",
  INSERTED_AT = "INSERTED_AT",
}

export interface RoleSort {
  readonly direction: SortDirection;
  readonly field: RoleSortFields;
}
