import { useMemo } from "react";
import { Divider, Grid, Image } from "semantic-ui-react";
import { AdminExpertOnboard } from "../../../api/admin/AdminExpertOnboard";
import { Migrated } from "../../../migrations/_helpers";
import { SeconderRow } from "./SeconderRow";
import eyeIcon from "../../../assets/icon-open-eye.svg";
import userIcon from "../../../assets/user-placeholder.svg";
import { useSettingsState } from "../../../contexts/Settings";
import { isSuccessState } from "../../../contexts/Generic";

interface Props {
  readonly onboard: Migrated<AdminExpertOnboard>;
}
export const SeconderVolunteersTab = ({ onboard: { extraFields } }: Props) => {
  const settingsState = useSettingsState();
  const assignedSeconderCandidacies = extraFields.assignedSeconderCandidacies || [];
  const volunteerSeconderCandidacies = extraFields.volunteerSeconderCandidacies || [];
  const requiredSeconderCount = useMemo(() => {
    if (!isSuccessState(settingsState)) {
      return 2;
    }

    return settingsState.result.core.required_seconder_count;
  }, [settingsState]);

  return (
    <Grid className="AdminExpertDetailsTab">
      <Grid.Column>
        <div className="WrapperSection">
          <Grid.Row>
            <Grid.Column className="AdminExpertDetailsTab-title">
              Assigned Seconders <span className="AdminExpertDetailsTab-title-dot">·</span>
              {assignedSeconderCandidacies.length}/{requiredSeconderCount}
              <Divider />
            </Grid.Column>
          </Grid.Row>

          {assignedSeconderCandidacies.length === 0 && (
            <>
              <Grid.Row className="AdminExpertDetailsTab-NoAssignedSeconders">
                <Image src={eyeIcon} />
                <div className="AdminExpertDetailsTab-NoAssignedSeconders-description">
                  To progress this application to the Mutual Assessment stage, you must assign{" "}
                  {requiredSeconderCount} Seconders
                </div>
              </Grid.Row>
              <Grid.Row>
                <Divider hidden />
              </Grid.Row>
            </>
          )}

          {assignedSeconderCandidacies.map((c) => (
            <Grid.Row key={c.id}>
              <SeconderRow
                seconderCandidacy={c}
                assignedSecondersCount={assignedSeconderCandidacies.length}
                isAdminView
              />
              <Divider />
            </Grid.Row>
          ))}

          <Grid.Row className="AdminExpertDetailsTab-title">
            Volunteers<span className="AdminExpertDetailsTab-title-dot">·</span>
            {volunteerSeconderCandidacies.length}
            <Divider hidden={volunteerSeconderCandidacies.length === 0} />
          </Grid.Row>

          {volunteerSeconderCandidacies.length === 0 && (
            <Grid.Row className="AdminExpertDetailsTab-NoVolunteersSeconders">
              <Image circular src={userIcon} />
              <div className="AdminExpertDetailsTab-NoVolunteersSeconders-description">
                No volunteers yet
              </div>
            </Grid.Row>
          )}

          {volunteerSeconderCandidacies.map((c) => (
            <Grid.Row key={c.id}>
              <SeconderRow
                seconderCandidacy={c}
                assignedSecondersCount={assignedSeconderCandidacies.length}
                isAdminView
              />
              <Divider />
            </Grid.Row>
          ))}
        </div>
      </Grid.Column>
    </Grid>
  );
};
