import { DeepPartial } from "uniforms";
import { convertToChainBigInt } from "../../contexts/Chain/helpers";
import { isDefined } from "../../types";
import { FormDocument } from "../_types";
import { TicketAnswer, TicketImportance, TicketPaymentMethods } from "./_enums";
import { TicketDuration, TicketEngagementLevel, TicketExecutionDate } from "./_enums";

export interface DeliverableSchema {
  readonly title: string; // 50 chars max.
  readonly description: string; // 500 chars max.
  readonly dueDate: string; // "date" format (eg.: `2018-11-13`)
}

const deliverablesKeys = ["title", "description", "dueDate"];

export const isKeyOfDeliverables = (s: unknown): s is keyof DeliverableSchema =>
  typeof s === "string" && deliverablesKeys.includes(s);

export interface ShortTicketSchema {
  readonly title: string; // max 100 chars.
  // Objectives.
  readonly executionDate: TicketExecutionDate;
  readonly importance: TicketImportance;
}

// Notes: optional fields should be null instead of undefined because of the ajv typing for the schema.
export interface TicketSchema extends ShortTicketSchema {
  // Related tickets.
  readonly relatedTickets: string[] | null;
  // Terms.
  readonly terms: FormDocument[] | null;
  // Payment.
  readonly paymentMethod: TicketPaymentMethods;
  readonly fiatPercentage: number | null; // Required if paymentMethod === TicketPaymentMethods.FiatAndCVDS
  readonly cvdsPercentage: number | null; // Required if paymentMethod === TicketPaymentMethods.FiatAndCVDS
  readonly daysOfWork: number; // max value 360
  readonly minRatePerDay: number | null;
  readonly maxRatePerDay: number | null;
  // Deliverables.
  readonly deliverables: DeliverableSchema[]; // min lenght 1, max lenght 30
  // Objectives.
  readonly acceptanceCriteria: string; // max 1000 chars.
  // Visibility.
  readonly deniedMembers: string[] | null; // regex for role IDs
  // Constraints.
  readonly scope: string; // max 1000 chars
  readonly assurance: TicketAnswer;
  // Additional Information.
  readonly additionalInformation: string | null; // max 1000 chars
  readonly additionalDocuments: FormDocument[] | null;
  // Audience.
  readonly occupations: string[]; // min lenght 1, values should be a list of tags ("01" etc)
  readonly skills: string[] | null; // min lenght 1, values should be a list of tags ("01" etc)
  readonly expertAttributes: string[]; // min lenght 1, max lenght 5, values should be a list of tags ("01" etc)
  readonly engagementLevel: TicketEngagementLevel;
  readonly duration: TicketDuration;
  readonly isOpenToRemoteWork: TicketAnswer;
  readonly skillsAdditionalInfo: string | null; // max 300 chars.
  // Consent.
  readonly consentToMattermostAlerts: boolean;
  readonly consentToShareEmail: boolean;
}

export interface CostFields {
  readonly minRawCost?: string;
  readonly maxRawCost?: string;
  readonly minAssuranceCost?: string;
  readonly maxAssuranceCost?: string;
  readonly minFinalCost?: string;
  readonly maxFinalCost?: string;
}

export const computeCostFields = (model?: DeepPartial<TicketSchema>): CostFields => {
  const { assurance, minRatePerDay, daysOfWork, maxRatePerDay } = model || {};
  const assuranceMultiplier =
    assurance === TicketAnswer.Yes ? convertToChainBigInt(5) : convertToChainBigInt(0);
  const divisor = convertToChainBigInt(100);

  const minRaw =
    minRatePerDay && daysOfWork
      ? convertToChainBigInt(Math.round(minRatePerDay * daysOfWork))
      : undefined;
  const maxRaw =
    maxRatePerDay && daysOfWork
      ? convertToChainBigInt(Math.round(maxRatePerDay * daysOfWork))
      : undefined;
  const minAssurance =
    isDefined(minRaw) && isDefined(assuranceMultiplier) && isDefined(divisor)
      ? (minRaw * assuranceMultiplier) / divisor
      : undefined;
  const maxAssurance =
    isDefined(maxRaw) && isDefined(assuranceMultiplier) && isDefined(divisor)
      ? (maxRaw * assuranceMultiplier) / divisor
      : undefined;
  const minFinal = isDefined(minRaw) && isDefined(minAssurance) ? minRaw + minAssurance : undefined;
  const maxFinal = isDefined(maxRaw) && isDefined(maxAssurance) ? maxRaw + maxAssurance : undefined;

  return {
    minRawCost: minRaw?.toString(),
    maxRawCost: maxRaw?.toString(),
    minAssuranceCost: minAssurance?.toString(),
    maxAssuranceCost: maxAssurance?.toString(),
    minFinalCost: minFinal?.toString(),
    maxFinalCost: maxFinal?.toString(),
  };
};
