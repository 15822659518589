import React, { useCallback } from "react";
import dateFormat from "dateformat";
import { Icon, Popup } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Shortcuts as S } from "../../../../routing";
import { readableTicketImportance } from "../../../../schemas/tickets/_enums";
import { TickettingDraft } from "../../../../types/ticket";
import { TicketSchema } from "../../../../schemas/tickets/_types";
import { isDefined } from "../../../../types/common";
import { TextClamp } from "../../TextClamp";

interface Props {
  readonly ticketId: string;
  readonly draftTicket: TickettingDraft<Partial<TicketSchema>>;
}

export const TicketDraftCard = ({ draftTicket, ticketId }: Props) => {
  const history = useHistory();
  const { form, insertedAt } = draftTicket;

  const onEdit = useCallback(() => {
    const search = new URLSearchParams({ [S.draftTicket.queryVarNames.id]: ticketId }).toString();
    history.push({ pathname: S.draftTicket.path, search });
  }, [history, ticketId]);

  return (
    <div className="TicketsCard draft">
      <div className="top-section">
        <div className="top-section-title" onClick={onEdit}>
          <TextClamp text={form?.data.title || "Untitled"} maxLines={2} />
        </div>
        <div className="top-section-labels">
          <div className="top-section-labels-date">
            {dateFormat(insertedAt, "HH:MM | dd/mm/yy")}
          </div>
          <div className="StatusLabel gray">Draft</div>
          {isDefined(form) && isDefined(form.data.importance) && (
            <div>
              <Popup
                position="top center"
                size="small"
                content="Level of priority"
                trigger={
                  <div className="priority-label">
                    {readableTicketImportance(form.data.importance)}
                  </div>
                }
              />
            </div>
          )}
          <div className="top-section-labels-edit" onClick={onEdit}>
            <Icon name="edit" />
            Edit
          </div>
        </div>
      </div>
    </div>
  );
};
