import { useCallback, useMemo, useState } from "react";
import { Modal, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import preAppImage from "../../assets/pre-app-background.svg";
import { LS_KEYS, setLocalStorageItem } from "../../contexts/Session/state";
import { getLocalStorageItem, isAtLeastPresent } from "../../contexts/Session/state";
import { useSessionState } from "../../contexts/Session";

const n1PlatformLinkTo = {
  pathname:
    "https://kevinmonserrat.medium.com/whats-next-for-the-venture-capital-industry-4576514cc308",
};
const acceptanceCritLinkTo = {
  pathname:
    "https://www.linkedin.com/pulse/consilience-ventures-investment-thesis-phase-1-kevin-monserrat/",
};
const expertiseLinkTo = {
  pathname:
    "https://www.linkedin.com/pulse/expertise-new-currency-driving-startup-economy-kevin-monserrat/",
};

export const PreApplicationModal = () => {
  const sessionState = useSessionState();
  const [isOpen, setIsOpen] = useState(true);
  const [showModal, onboard] = useMemo(() => {
    if (!isAtLeastPresent(sessionState)) {
      return [false, undefined];
    } else if (getLocalStorageItem(LS_KEYS.WelcomeUser) === "true") {
      return [false, undefined];
    }
    return [
      sessionState.user.roles.length <= 0 && sessionState.user.myApplications.length > 0,
      sessionState.user.myApplications[0],
    ];
  }, [sessionState]);
  const handleContinue = useCallback(() => {
    setIsOpen(false);
    setLocalStorageItem(LS_KEYS.WelcomeUser, "true");
  }, []);

  return (
    <>
      {showModal && isOpen && (
        <Modal open={isOpen} size="large" onClose={handleContinue} closeOnDimmerClick={false}>
          <div className="PreApplicationModal">
            <div className="PreApplicationModal-left-container">
              <div className="PreApplicationModal-left-container-img-wrapper">
                <Image src={preAppImage} />
                <div className="PreApplicationModal-left-container-img-wrapper-avatar">
                  <Image src={onboard?.sender.avatarUrl} circular />
                </div>
              </div>
              <div className="PreApplicationModal-left-container-title">Welcome!</div>
              <span>
                {onboard?.sender.fullName || "Unknown"}
                <br />
                has referred you to join Consilience Ventures
              </span>
            </div>
            <div className="PreApplicationModal-right-container">
              <div className="PreApplicationModal-right-container-title">
                Your registration is complete!
                <br />
                What to expect next..
              </div>
              <p>
                You are about to join Consilience Ventures on our mission to{" "}
                <Link to={n1PlatformLinkTo} target="_blank">
                  create the #1 platform for startup growth.
                </Link>
              </p>
              <p>
                <b>
                  In the next few steps, you'll be able to accept your invitation to join
                  Consilience Ventures, and complete the application form.
                </b>
              </p>
              <p>
                Applying as a startup? Here are a few tips:
                <br />
                <Link to={acceptanceCritLinkTo} target="_blank">
                  · Review our acceptance criteria
                </Link>
                <br /> · Be as clear as possible about your strengths and differentiators
                <br /> · Provide supporting evidence for your key growth assumptions
                <br /> · Ensure that you understand the nature of Consilience Ventures' offering,
                and how we differ from traditional investors - talk to{" "}
                {onboard?.sender.fullName || "Unknown"} if you want to dig in further!
              </p>
              <p>
                Applying as an expert?{" "}
                <Link to={expertiseLinkTo} target="_blank">
                  Expertise is the new currency,
                </Link>{" "}
                and we are careful to ensure that as we scale, we protect the core values, level of
                experience, and startup and technology capabilities that differentiate our community
                members.
              </p>
              <p>
                Due to the volume of applicants, applications which are incomplete, ambiguous,
                and/or poorly substantiated will be rejected.
              </p>
              <p>
                Consilience Ventures is a pioneer when it comes to aligning the interests of
                portfolio companies, experts, and investors and we look forward to welcoming the
                best into our network and creating remarkable outcomes for all.{" "}
              </p>
              <Button color="blue" content={"Continue"} onClick={handleContinue} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
