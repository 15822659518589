import { gql } from "@apollo/client";
import { Identifiable } from "../../types";

export const MUTATION = gql`
  mutation revertTicketBidToDraft($input: RevertTickettingBidToDraftInput!) {
    payload: revertTickettingBidToDraft(input: $input) {
      bid {
        id
      }
    }
  }
`;

export interface Variables {
  readonly input: RevertTickettingBidToDraftInput;
}

interface RevertTickettingBidToDraftInput {
  readonly bidId: string;
  readonly ethSignature: string;
  readonly transmittedAt: Date;
}

export interface Result {
  readonly payload?: RevertTickettingBidToDraftPayload;
}

interface RevertTickettingBidToDraftPayload {
  readonly bid: Identifiable;
}
