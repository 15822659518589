import { Dispatch } from "react";
import { Typed } from "../../types/common";
import { SessionUser } from "../../types/frontend_only/sessionUser";
import { EnrollmentError, EnrollmentErrorType } from "../../types";
import { SetFlagsRequest } from "../../contracts/generated/Access";
import { State as ChainState, isLinked } from "../Chain/state";

// Action types.
export enum ActionTypes {
  Clear = "@session/Clear",
  SetCleanupDone = "@session/SetCleanupDone",
  SetLinkedinSessionId = "@session/SetLinkedinSessionId",
  SetToken = "@session/SetToken",
  SetPresent = "@session/SetPresent",
  Enrol = "@session/Enrol",
  SetEnrolled = "@session/SetEnrolled",
  UpdateUser = "@session/UpdateUser",
  ClearEnrolment = "@session/ClearEnrolment",
}
// Action instances.
export interface Clear extends Typed<ActionTypes.Clear> { }
export interface SetCleanupDone extends Typed<ActionTypes.SetCleanupDone> { }
export interface SetLinkedinSessionId extends Typed<ActionTypes.SetLinkedinSessionId> {
  readonly linkedinSessionId: string;
  readonly needsHydration: boolean;
}
export interface SetToken extends Typed<ActionTypes.SetToken> {
  readonly token: string;
  readonly needsHydration: boolean;
}
export interface SetPresent extends Typed<ActionTypes.SetPresent> {
  readonly user: SessionUser;
  readonly fishy: boolean;
  readonly needsHydration: boolean;
}
export interface Enrol extends Typed<ActionTypes.Enrol> {
  readonly roleId: string;
}
export interface SetEnrolled extends Typed<ActionTypes.SetEnrolled> {
  readonly roleId: string;
  readonly roleChainData: SetFlagsRequest;
  readonly chainId: number;
}
export interface UpdateUser extends Typed<ActionTypes.UpdateUser> {
  readonly user: SessionUser;
}
export interface ClearEnrolment extends Typed<ActionTypes.ClearEnrolment> {
  readonly enrolmentError?: EnrollmentError;
  readonly chainLastCheckedAt: string;
}

export type Action =
  | Clear
  | SetCleanupDone
  | SetLinkedinSessionId
  | SetToken
  | SetPresent
  | Enrol
  | SetEnrolled
  | UpdateUser
  | ClearEnrolment;

export class Actioner {
  private readonly dispatch: Dispatch<Action>;
  private readonly chainState: ChainState;

  constructor(dispatch: Dispatch<Action>, chainState: ChainState) {
    this.dispatch = dispatch;
    this.chainState = chainState;
  }

  clear = () => this.dispatch({ type: ActionTypes.Clear });

  setCleanupDone = () => this.dispatch({ type: ActionTypes.SetCleanupDone });

  // setLinkedinSessionId = (linkedinSessionId: string) =>
  //   this.dispatch({ type: ActionTypes.SetLinkedinSessionId, linkedinSessionId });

  setToken = (token: string) =>
    this.dispatch({ type: ActionTypes.SetToken, token, needsHydration: true });

  setFishyUser = (user: SessionUser) =>
    this.dispatch({ type: ActionTypes.SetPresent, user, fishy: true, needsHydration: true });

  setPresent = (user: SessionUser) =>
    this.dispatch({ type: ActionTypes.SetPresent, fishy: false, user, needsHydration: false });

  enrol = (roleId: string) => {
    this.dispatch({ type: ActionTypes.Enrol, roleId });
    this.hydrateRoleFlags(roleId);
  };

  updateUser = (user: SessionUser) => this.dispatch({ type: ActionTypes.UpdateUser, user });
  clearEnrollment = (enrolmentError?: EnrollmentError) => {
    const chainLastCheckedAt = this.chainState.lastChangedAt;
    this.dispatch({ type: ActionTypes.ClearEnrolment, enrolmentError, chainLastCheckedAt });
  };

  hydrateRoleFlags = (roleId: string) => {
    const { chainState } = this;
    if (!isLinked(chainState)) {
      throw new Error("Impossible state transition detected.");
    }
    const { account, contracts, chainId } = chainState;

    contracts.access
      .flags(account.toString())
      .then((roleChainData) => {
        const { isActor, isGovernor, isIssuer } = roleChainData;
        if (!isActor && !isGovernor && !isIssuer) {
          return this.clearEnrollment({ type: EnrollmentErrorType.PartiallyVerified });
        }
        this.dispatch({ type: ActionTypes.SetEnrolled, roleId, roleChainData, chainId });
      })
      .catch((e) => {
        const message = e.toString();

        this.clearEnrollment({ type: EnrollmentErrorType.FailedContractCall, message });
        console.error(e);
      });
  };
}
