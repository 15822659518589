import { gql } from "@apollo/client";

export const PAGEINFO_FRAGMENT = gql`
  fragment PageInfo on PageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;
