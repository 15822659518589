import { Typed } from "../../types";

// Generic pattern for state.
export enum GenericStateTypes {
  None = "$generic/0/None",
  Fetching = "$generic/1/Fetching",
  Success = "$generic/2/Success",
  Failure = "$generic/2/Failure",
}

export interface None extends Typed<GenericStateTypes.None> {}
export interface Fetching extends Typed<GenericStateTypes.Fetching> {}
export interface Success<T> extends Typed<GenericStateTypes.Success> {
  readonly result: T;
}
export interface Failure extends Typed<GenericStateTypes.Failure> {
  readonly errors: ReadonlyArray<string>;
}

export type GenericState<T> = None | Fetching | Success<T> | Failure;

// Helpers.
export const isNoneState = <T>(state: GenericState<T>): state is None =>
  state.type === GenericStateTypes.None;
export const isFetchingState = <T>(state: GenericState<T>): state is Fetching =>
  state.type === GenericStateTypes.Fetching;
export const isSuccessState = <T>(state: GenericState<T>): state is Success<T> =>
  state.type === GenericStateTypes.Success;
export const isFailureState = <T>(state: GenericState<T>): state is Failure =>
  state.type === GenericStateTypes.Failure;
